export const seoMap =
    [
        {
            "path": "/en/rent/apartments-for-rent-in-dubai",
            "homepageSection": "Top Searches in Dubai",
            "homePageLink": "Apartments for Rent in Dubai",
            "businessType": "rent",
            "propertyType": "APT",
            "category": 0,
            "location": "dubai",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Apartments to rent in Dubai | 3129 Apartments for rent in Dubai UAE",
            "numberProperties": 1678,
            "h1": "Apartments for rent in Dubai UAE",
            "metadescription": "Might it be said that you are searching a property for rent in Dubai? Observe an apartments or villas or commercial space of your decision, we offer a wide scope of residential properties. Reach us Now !",
            "descriptionH1": "Apartments to Rent in Dubai UAE. Rent Residential Properties in UAE : 1 Bedroom, Flats, Duplexes, Lofts, Penthouses, Studios, Full Floors…",
            "keywords": "apartments to rent in Dubai, apartments for rent in business bay, apartments for rent in Dubai land, house for rent in Dubai, apartment for rent in downtown Dubai, apartments to rent in Dubai marina"
        },
        {
            "path": "/en/rent/1-bedroom-apartment-for-rent-in-dubai",
            "homepageSection": "Top Searches in Dubai",
            "homePageLink": "1 Bedroom Apartment for Rent in Dubai",
            "businessType": "rent",
            "propertyType": "APT",
            "category": 0,
            "location": "dubai",
            "bedrooms": 1,
            "minPrice": null,
            "maxPrice": "",
            "title": "1 Bedroom Apartment for Rent in Dubai | zeekeez.com",
            "numberProperties": 1678,
            "h1": "1-Bedroom Apartments for rent in Dubai UAE",
            "metadescription": "1 Bedrooms Apartments to Rent in Dubai UAE. Rent Residential Properties in UAE : Flats, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Full Floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/apartments-for-rent-in-business-bay",
            "homepageSection": "Top Searches in Dubai",
            "homePageLink": "Apartments for Rent in Business Bay",
            "businessType": "rent",
            "propertyType": "APT",
            "category": 0,
            "location": "dubai-business-bay",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Apartments Flats for Rent in Business Bay | zeekeez.com",
            "numberProperties": 140,
            "h1": "Apartments for rent in Business Bay Dubai UAE",
            "metadescription": "Apartments to Rent in Business Bay Dubai UAE. Rent Residential Properties in UAE : Flats, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Full Floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/apartments-for-rent-in-palm-jumeirah",
            "homepageSection": "Top Searches in Dubai",
            "homePageLink": "Apartments for Rent in Palm jumeirah",
            "businessType": "rent",
            "propertyType": "APT",
            "category": 0,
            "location": "dubai-palm-jumeirah",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Apartments Flats for Rent in Palm Jumeirah| zeekeez.com",
            "numberProperties": 68,
            "h1": "Apartments for rent in Palm Jumeirah Dubai UAE",
            "metadescription": "Apartments to Rent in Palm Jumeirah Dubai UAE. Rent Residential Properties in UAE : Flats, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Beach view…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/apartments-for-sale-in-dubai",
            "homepageSection": "Top Searches in Dubai",
            "homePageLink": "Apartments for Sale in Dubai",
            "businessType": "sale",
            "propertyType": "APT",
            "category": 0,
            "location": "dubai",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Apartments for sale in Dubai | 7004 Apartments for sale in Dubai UAE| Zeekeez",
            "numberProperties": 3556,
            "h1": "Apartments for sale in Dubai UAE",
            "metadescription": "Luxury apartments for sale in Dubai. Fully furnished handover soon 1,2,3,4 bedroom apartments, penthouse, studio, duplex, villas. Kindly get in touch with us.",
            "descriptionH1": "",
            "keywords": "apartments for sale in Dubai silicon oasis, apartments for sale in Dubai, apartments for sale in Dubai hills estate"
        },
        {
            "path": "/en/sale/apartments-for-sale-in-business-bay",
            "homepageSection": "Top Searches in Dubai",
            "homePageLink": "Apartments for Sale in Business Bay",
            "businessType": "sale",
            "propertyType": "APT",
            "category": 0,
            "location": "dubai-business-bay",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Apartments Flats for Sale in Business Bay | zeekeez.com",
            "numberProperties": 307,
            "h1": "Apartments for sale in Business Bay Dubai UAE",
            "metadescription": "Apartments for Sale in Business Bay Dubai UAE. Buy Sell Residential Properties in UAE : 1 Bedroom, Flats, Duplexes, Lofts, Penthouses, Studios, Full Floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/apartments-for-sale-in-palm-jumeirah",
            "homepageSection": "Top Searches in Dubai",
            "homePageLink": "Apartments for Sale in Palm jumeirah",
            "businessType": "sale",
            "propertyType": "APT",
            "category": 0,
            "location": "dubai-palm-jumeirah",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Apartments Flats for Sale in Palm Jumeirah| zeekeez.com",
            "numberProperties": 255,
            "h1": "Apartments for sale in Palm Jumeirah Dubai UAE",
            "metadescription": "Apartments for Sale in Palm Jumeirah Dubai UAE. Buy Sell Residential Properties in UAE : Flats, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Full Floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/villas-for-rent-in-dubai",
            "homepageSection": "Top Searches in Dubai",
            "homePageLink": "Villas for Rent in Dubai",
            "businessType": "rent",
            "propertyType": "VIL",
            "category": 0,
            "location": "dubai",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Villas for Rent in Dubai | zeekeez.com",
            "numberProperties": 219,
            "h1": "Villas for rent in Dubai UAE",
            "metadescription": "Villas to Rent in Dubai UAE. Rent Residential Properties in UAE : Villas, Houses, Homes, Townhouses…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/villas-for-rent-in-palm-jumeirah",
            "homepageSection": "Top Searches in Dubai",
            "homePageLink": "Villas for Rent in Palm jumeirah",
            "businessType": "rent",
            "propertyType": "VIL",
            "category": 0,
            "location": "dubai-palm-jumeirah",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Villas for Rent in Palm Jumeirah| zeekeez.com",
            "numberProperties": 4,
            "h1": "Villas for rent in Palm Jumeirah Dubai UAE",
            "metadescription": "Villas to Rent in Palm Jumeirah Dubai UAE. Rent Residential Properties in UAE : Villas, Houses, Homes, Townhouses, Beach views, Private pool…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/villas-for-sale-in-dubai",
            "homepageSection": "Top Searches in Dubai",
            "homePageLink": "Villas for Sale in Dubai",
            "businessType": "sale",
            "propertyType": "VIL",
            "category": 0,
            "location": "dubai",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Villas for Sale in Dubai | zeekeez.com",
            "numberProperties": 219,
            "h1": "Villas for sale in Dubai UAE",
            "metadescription": "Villas for Sale in Dubai UAE. Buy Sell Residential Properties in UAE : Villas, Houses, Homes, Townhouses…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/villas-for-sale-in-palm-jumeirah",
            "homepageSection": "Top Searches in Dubai",
            "homePageLink": "Villas for Sale in Palm jumeirah",
            "businessType": "sale",
            "propertyType": "VIL",
            "category": 0,
            "location": "dubai-palm-jumeirah",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Villas for Sale in Palm Jumeirah| zeekeez.com",
            "numberProperties": 15,
            "h1": "Villas for sale in Palm Jumeirah Dubai UAE",
            "metadescription": "Villas for Sale in Palm Jumeirah Dubai UAE. Buy Sell Residential Properties in UAE : Villas, Houses, Homes, Townhouses, Beach views, Private pool…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/houses-for-rent-in-dubai",
            "homepageSection": "Top Searches in Dubai",
            "homePageLink": "Houses for Rent in Dubai",
            "businessType": "rent",
            "propertyType": "VIL",
            "category": 0,
            "location": "dubai",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Houses for rent in Dubai cheap | Houses for rent in Dubai UAE",
            "numberProperties": 219,
            "h1": "Houses for rent in Dubai UAE",
            "metadescription": "Reasonable property accessible in Dubai at staggering costs. Look at Now. Multiple options is available here houses, apartments, villas, flats, townhouses, beach views.",
            "descriptionH1": "",
            "keywords": "Houses for rent in Dubai Cheap, townhomes for rent in Dubai, villas to rent in Sharjah, apartment for rent in Abu Dhabi"
        },
        {
            "path": "/en/rent/houses-for-rent-in-palm-jumeirah",
            "homepageSection": "Top Searches in Dubai",
            "homePageLink": "Houses for Rent in Palm jumeirah",
            "businessType": "rent",
            "propertyType": "VIL",
            "category": 0,
            "location": "dubai-palm-jumeirah",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Houses for Rent in Palm Jumeirah| zeekeez.com",
            "numberProperties": 4,
            "h1": "Houses for rent in Palm Jumeirah Dubai UAE",
            "metadescription": "Houses to Rent in Palm Jumeirah Dubai UAE. Rent Residential Properties in UAE : Villas, Houses, Homes, Townhouses, Beach views…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/houses-for-sale-in-dubai",
            "homepageSection": "Top Searches in Dubai",
            "homePageLink": "Houses for Sale in Dubai",
            "businessType": "sale",
            "propertyType": "VIL",
            "category": 0,
            "location": "dubai",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Houses for Sale in Dubai | zeekeez.com",
            "numberProperties": 219,
            "h1": "Houses for sale in Dubai UAE",
            "metadescription": "Houses for Sale in Dubai UAE. Buy Sell Residential Properties in UAE : Villas, Houses, Homes, Townhouses, Beach views…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/houses-for-sale-in-palm-jumeirah",
            "homepageSection": "Top Searches in Dubai",
            "homePageLink": "House for Sale in Palm jumeirah",
            "businessType": "sale",
            "propertyType": "VIL",
            "category": 0,
            "location": "dubai-palm-jumeirah",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Houses for Sale in Palm Jumeirah| zeekeez.com",
            "numberProperties": 15,
            "h1": "Houses for sale in Palm Jumeirah Dubai UAE",
            "metadescription": "Houses for Sale in Palm Jumeirah Dubai UAE. Buy Sell Residential Properties in UAE : Villas, Houses, Homes, Townhouses, Beach views…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/apartments-for-rent-in-abu-dhabi",
            "homepageSection": "Popular areas in Abu Dhabi",
            "homePageLink": "Apartment for Rent in Abu Dhabi",
            "businessType": "rent",
            "propertyType": "APT",
            "category": 0,
            "location": "abu-dhabi",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Apartments Flats for Rent in Abu Dhabi | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Apartments for rent in Abu Dhabi UAE",
            "metadescription": "Apartments to Rent in Abu Dhabi UAE. Rent Residential Properties in UAE : Flats, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Full Floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/apartments-for-sale-in-abu-dhabi",
            "homepageSection": "Popular areas in Abu Dhabi",
            "homePageLink": "Apartment for Sale in Abu Dhabi",
            "businessType": "sale",
            "propertyType": "APT",
            "category": 0,
            "location": "abu-dhabi",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Apartments Flats for Sale in Abu Dhabi | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Apartments for sale in Abu Dhabi UAE",
            "metadescription": "Apartments for Sale in Abu Dhabi UAE. Buy Sell Residential Properties in UAE : Flats, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Full Floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/villas-for-rent-in-abu-dhabi",
            "homepageSection": "Popular areas in Abu Dhabi",
            "homePageLink": "Villas for Rent in Abu Dhabi",
            "businessType": "rent",
            "propertyType": "VIL",
            "category": 0,
            "location": "abu-dhabi",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Villas for Rent in Abu Dhabi | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Villas for rent in Abu Dhabi UAE",
            "metadescription": "Villas to Rent in Abu Dhabi UAE. Rent Residential Properties in UAE :Villas, Houses, Homes, Townhouses, Beach views, Private pool…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/villas-for-sale-in-abu-dhabi",
            "homepageSection": "Popular areas in Abu Dhabi",
            "homePageLink": "Villas for Sale in Abu Dhabi",
            "businessType": "sale",
            "propertyType": "VIL",
            "category": 0,
            "location": "abu-dhabi",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Villas for Sale in Abu Dhabi | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Villas for sale in Abu Dhabi UAE",
            "metadescription": "Villas for Sale in Abu Dhabi UAE. Buy Sell Residential Properties in UAE : Villas, Houses, Homes, Townhouses, Beach views, Private pool…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/properties-for-rent-in-dubai",
            "homepageSection": "Trending areas in Dubai",
            "homePageLink": "Properties for Rent in Dubai",
            "businessType": "rent",
            "propertyType": "",
            "category": null,
            "location": "dubai",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Properties for Rent in Dubai | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Properties for rent in Dubai UAE",
            "metadescription": "Properties to Rent in Dubai UAE. Rent Residential Properties in UAE : Apartments, Villas, Flats, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Full Floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/properties-for-sale-in-dubai",
            "homepageSection": "Trending areas in Dubai",
            "homePageLink": "Properties for Sale in Dubai",
            "businessType": "sale",
            "propertyType": "",
            "category": 0,
            "location": "dubai",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Properties for Sale in Dubai | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Properties for sale in Dubai",
            "metadescription": "Properties for Sale in Dubai UAE. Buy Sell Residential Properties in UAE : Apartments, Villas, Flats, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Full Floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/apartments-for-rent-in-dubai-marina",
            "homepageSection": "Trending areas in Dubai",
            "homePageLink": "Apartment for Rent in Dubai Marina",
            "businessType": "rent",
            "propertyType": "APT",
            "category": 0,
            "location": "dubai-dubai-marina",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Apartments Flats for Rent in Dubai Marina | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Apartments for rent in Dubai Marina UAE",
            "metadescription": "Apartments to Rent in Dubai Marina UAE. Rent Residential Properties in UAE : Flats, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Sea view…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/apartments-for-rent-in-downtown-dubai",
            "homepageSection": "Trending areas in Dubai",
            "homePageLink": "Apartments for Rent in Downtown Dubai",
            "businessType": "rent",
            "propertyType": "APT",
            "category": 0,
            "location": "dubai-downtown-dubai",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Apartments Flats for Rent in Downtown Dubai | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Apartments for rent in Downtown Dubai UAE",
            "metadescription": "Apartments to Rent in Downtown Dubai UAE. Rent Residential Properties in UAE : Flats, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Burj view…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/apartments-for-sale-in-dubai-marina",
            "homepageSection": "Trending areas in Dubai",
            "homePageLink": "Apartment for Sale in Dubai Marina",
            "businessType": "sale",
            "propertyType": "APT",
            "category": 0,
            "location": "dubai-dubai-marina",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Apartments Flats for Sale in Dubai Marina | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Apartments for sale in Dubai Marina UAE",
            "metadescription": "Apartments for Sale in Dubai Marina UAE. Buy Sell Residential Properties in UAE : Flats, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Full Floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/apartments-for-sale-in-downtown-dubai",
            "homepageSection": "Trending areas in Dubai",
            "homePageLink": "Apartment for Sale in Downtown Dubai",
            "businessType": "sale",
            "propertyType": "APT",
            "category": 0,
            "location": "dubai-downtown-dubai",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Apartments Flats for Sale in Downtown Dubai | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Apartments for sale in Downtown Dubai UAE",
            "metadescription": "Apartments for Sale in Downtown Dubai UAE. Buy Sell Residential Properties in UAE : Flats, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Full Floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/properties-for-rent-in-uae",
            "homepageSection": "Popular searches in UAE",
            "homePageLink": "Properties for Rent in UAE",
            "businessType": "rent",
            "propertyType": "",
            "category": null,
            "location": "",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Properties for Rent in UAE | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Properties for rent in UAE",
            "metadescription": "Properties to Rent in UAE. Rent Residential Properties in UAE : Apartments, Villas, Flats, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Full Floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/properties-for-sale-in-uae",
            "homepageSection": "Popular searches in UAE",
            "homePageLink": "Properties for Sale in UAE",
            "businessType": "sale",
            "propertyType": "",
            "category": null,
            "location": "",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Properties for Sale in UAE | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Properties for sale in UAE",
            "metadescription": "Properties for Sale in UAE. Buy Sell Residential Properties in UAE : Apartments, Villas, Flats, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Full Floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/apartments-for-rent-in-uae",
            "homepageSection": "Popular searches in UAE",
            "homePageLink": "Apartment for Rent in UAE",
            "businessType": "rent",
            "propertyType": "APT",
            "category": 0,
            "location": "",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Apartments Flats for Rent in UAE | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Apartments for rent in UAE",
            "metadescription": "Apartments to Rent in UAE. Rent Residential Properties in UAE : Flats, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Full Floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/apartments-for-sale-in-uae",
            "homepageSection": "Popular searches in UAE",
            "homePageLink": "Apartment for Sale in UAE",
            "businessType": "sale",
            "propertyType": "APT",
            "category": 0,
            "location": "",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Apartments Flats for Sale in UAE | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Apartments for sale in UAE",
            "metadescription": "Apartments for Sale in UAE. Buy Sell Residential Properties in UAE : Flats, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Full Floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/villas-for-rent-in-uae",
            "homepageSection": "Popular searches in UAE",
            "homePageLink": "Villas for Rent in UAE",
            "businessType": "rent",
            "propertyType": "VIL",
            "category": 0,
            "location": "",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Villas for rent in UAE | Villa for rent in Dubai |Properties find at Zeekeez",
            "numberProperties": 4266,
            "h1": "Villas for rent in UAE",
            "metadescription": "4266 Villas for rent in UAE on zeekeez.com. Spacious units, fully upgraded, affordable ready to move easy payment options. Now find world class properties here. ",
            "descriptionH1": "",
            "keywords": "villa for rent in Dubai, villa for rent in the palm Jumeirah, villas to rent in Abu Dhabi, villas for rent in UAE, villas for rent in Abu Dhabi, villas for rent in Dubai investment park"
        },
        {
            "path": "/en/sale/villas-for-sale-in-uae",
            "homepageSection": "Popular searches in UAE",
            "homePageLink": "Villas for Sale in UAE",
            "businessType": "sale",
            "propertyType": "VIL",
            "category": 0,
            "location": "",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Villas for Sale in UAE | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Villas for sale in UAE",
            "metadescription": "Villas for Sale in UAE. Buy Sell Residential Properties in UAE : Villas, Houses, Homes, Townhouses, Beach views, Private pool…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/lands-for-rent-in-uae",
            "homepageSection": "Popular searches in UAE",
            "homePageLink": "Land for Rent in UAE",
            "businessType": "rent",
            "propertyType": "LAN",
            "category": 1,
            "location": "",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Lands for Rent in UAE | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Lands for rent in UAE",
            "metadescription": "Lands to Rent in UAE. Rent Residential Properties in UAE : Villas, Houses, Homes, Townhouses, Beach views, Land…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/lands-for-sale-in-uae",
            "homepageSection": "Popular searches in UAE",
            "homePageLink": "Land for Sale in UAE",
            "businessType": "sale",
            "propertyType": "LAN",
            "category": 1,
            "location": "",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Lands for Sale in UAE | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Lands for sale in UAE",
            "metadescription": "Lands for Sale in UAE. Buy Sell Residential Properties in UAE : Flats, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Land…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/studios-for-rent-in-uae",
            "homepageSection": "Popular searches in UAE",
            "homePageLink": "Studio for Rent in UAE",
            "businessType": "rent",
            "propertyType": "",
            "category": null,
            "location": "",
            "bedrooms": 0,
            "minPrice": null,
            "maxPrice": "",
            "title": "Studios for Rent in UAE | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Studio for rent in UAE",
            "metadescription": "Studios to Rent in UAE. Rent Residential Properties in UAE : 1 Bedroom, Flats, Duplexes, Lofts, Penthouses, Studios...",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/studios-for-sale-in-uae",
            "homepageSection": "Popular searches in UAE",
            "homePageLink": "Studio for Sale in UAE",
            "businessType": "sale",
            "propertyType": "",
            "category": null,
            "location": "",
            "bedrooms": 0,
            "minPrice": null,
            "maxPrice": "",
            "title": "Studios for Sale in UAE | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Studio for sale in UAE",
            "metadescription": "Studiosfor Sale in UAE. Buy Sell Residential Properties in UAE : 1 Bedroom, Flats, Duplexes, Lofts, Penthouses, Studios…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/houses-for-rent-in-uae",
            "homepageSection": "Popular searches in UAE",
            "homePageLink": "House for Rent in UAE",
            "businessType": "rent",
            "propertyType": "VIL",
            "category": 0,
            "location": "",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Houses for Rent in UAE | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Houses for rent in UAE",
            "metadescription": "Houses to Rent in UAE. Rent Residential Properties in UAE : Villas, Houses, Homes, Townhouses, Beach views, Private pool…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/houses-for-sale-in-uae",
            "homepageSection": "Popular searches in UAE",
            "homePageLink": "House for Sale in UAE",
            "businessType": "sale",
            "propertyType": "VIL",
            "category": 0,
            "location": "",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Houses for Sale in UAE | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Houses for sale in UAE",
            "metadescription": "Houses for Sale in UAE. Buy Sell Residential Properties in UAE : Villas, Houses, Homes, Townhouses, Beach views, Private pool…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/luxuries-properties-for-rent-in-uae",
            "homepageSection": "Popular searches in UAE",
            "homePageLink": "Luxury Properties for Rent in UAE",
            "businessType": "rent",
            "propertyType": "",
            "category": null,
            "location": "",
            "bedrooms": null,
            "minPrice": 500000,
            "maxPrice": "Any",
            "title": "Luxury Properties for Rent in UAE | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Luxury Properties for rent in UAE",
            "metadescription": "Luxury Properties to Rent in UAE. Rent Residential Properties in UAE : Apartments, Flats, Duplexes, Lofts, Penthouses, Studios, Villas, Full Floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/luxuries-properties-for-sale-in-uae",
            "homepageSection": "Popular searches in UAE",
            "homePageLink": "Luxury Properties for Sale in UAE",
            "businessType": "sale",
            "propertyType": "",
            "category": null,
            "location": "",
            "bedrooms": null,
            "minPrice": 10000000,
            "maxPrice": "Any",
            "title": "Luxury Properties for Sale in UAE | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Luxury Properties for sale in UAE",
            "metadescription": "Luxury Properties for sale in UAE. Buy Sell Commercial Properties in UAE : Apartments, Flats, Duplexes, Lofts, Penthouses, Studios, Villas, Full Floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/commercials-for-rent-in-uae",
            "homepageSection": "Popular searches in UAE",
            "homePageLink": "Commercial for Rent in UAE",
            "businessType": "rent",
            "propertyType": "WAR",
            "category": 1,
            "location": "",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Commercial for Rent in UAE | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Commercial for rent in UAE",
            "metadescription": "Commercial to Rent in UAE. Rent Commercial Properties in UAE : Offices, Stores, Retails, Warehouses, Full floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/commercials-for-sale-in-uae",
            "homepageSection": "Popular searches in UAE",
            "homePageLink": "Commercial for Sale in UAE",
            "businessType": "sale",
            "propertyType": "WAR",
            "category": 1,
            "location": "",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Commercial for Sale in UAE | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Commercial for sale in UAE",
            "metadescription": "Commercial for Sale in UAE. Buy Sell Commercial Properties in UAE : Offices, Stores, Retails, Warehouses, Full floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/property-for-rent-in-uae",
            "homepageSection": "Popular searches in UAE",
            "homePageLink": "Property for Rent in UAE",
            "businessType": "rent",
            "propertyType": "",
            "category": null,
            "location": "",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Property for Rent in UAE | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Property for rent in UAE",
            "metadescription": "Property to Rent in UAE. Rent Residential Properties in UAE : Apartments, Flats, Villas, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Full Floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/property-for-sale-in-uae",
            "homepageSection": "Popular searches in UAE",
            "homePageLink": "Property for Sale in UAE",
            "businessType": "sale",
            "propertyType": "",
            "category": null,
            "location": "",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Property for Sale in UAE | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Property for sale in UAE",
            "metadescription": "Property for Sale in UAE. Buy Sell Residential Properties in UAE : Apartments, Flats, Villas, Duplexes, Lofts, Penthouses, Studios, 1 Bedroom, Full Floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/stores-for-rent-in-dubai",
            "homepageSection": "Top Searches Commercial in Dubai",
            "homePageLink": "Studio for Rent in Dubai",
            "businessType": "rent",
            "propertyType": "",
            "category": null,
            "location": "dubai",
            "bedrooms": 0,
            "minPrice": null,
            "maxPrice": "",
            "title": "Studio for rent in Dubai monthly | 3129 Stores for rent in Dubai UAE |Zeekeez.com",
            "numberProperties": 3129,
            "h1": "Stores for rent in Dubai UAE",
            "metadescription": "See Studio Apartments for rent in Dubai monthly with zeekeez.com browse from our 3129 properties. Well maintained, brand new, bright and spacious, close to metro.",
            "descriptionH1": "",
            "keywords": "properties for rent in Dubai, apartment to rent in Dubai marina, studio for rent in Dubai monthly, apartment to rent in Dubai marina"
        },
        {
            "path": "/en/sale/stores-for-sale-in-dubai",
            "homepageSection": "Top Searches Commercial in Dubai",
            "homePageLink": "Studio for Sale in Dubai",
            "businessType": "sale",
            "propertyType": "",
            "category": null,
            "location": "dubai",
            "bedrooms": 0,
            "minPrice": null,
            "maxPrice": "",
            "title": "Stores for Sale in Dubai | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Stores for sale in Dubai UAE",
            "metadescription": "Stores for Sale in Dubai UAE. Buy Sell Commercial Properties in Dubai : Stores, Retails, popup stores…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/rent/commercials-for-rent-in-dubai",
            "homepageSection": "Top Searches Commercial in Dubai",
            "homePageLink": "Commercial for Rent in Dubai",
            "businessType": "rent",
            "propertyType": "WAR",
            "category": 1,
            "location": "dubai",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Commercial Properties for Rent in Dubai | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Commercial for rent in Dubai UAE",
            "metadescription": "Commercial to Rent in Dubai UAE. Rent Commercial Properties in Dubai : Offices, Stores, Retails, Warehouses, Full floors…",
            "descriptionH1": "",
            "keywords": ""
        },
        {
            "path": "/en/sale/commercials-for-sale-in-dubai",
            "homepageSection": "Top Searches Commercial in Dubai",
            "homePageLink": "Commercial for Sale in Dubai",
            "businessType": "sale",
            "propertyType": "WAR",
            "category": 1,
            "location": "dubai",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Cheapest Commercial property for sale in Dubai | Properties for sale in the UAE ",
            "numberProperties": 1678,
            "h1": "Commercial for sale in Dubai UAE",
            "metadescription": "Check commercial properties for sale in Dubai UAE. Use our property finder portal zeekeez.com and select best office for your teams. Verified properties, brand new, best location, ready to use.",
            "descriptionH1": "",
            "keywords": "Commercial property for sale in Dubai, properties for sale in the UAE ,apartments for sale in Dubai hills estate"
        },
        {
            "path": "/en/rent/offices-for-rent-in-dubai",
            "homepageSection": "Top Searches Commercial in Dubai",
            "homePageLink": "Office for Rent in Dubai",
            "businessType": "rent",
            "propertyType": "OFF",
            "category": 1,
            "location": "dubai",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Affordable Office on rent in Dubai, UAE |Office space for rent in Dubai-Zeekeez",
            "numberProperties": 3129,
            "h1": "Offices for rent in Dubai UAE",
            "metadescription": "3129 Offices for rent in Dubai UAE, Completely outfitted adjusted workplaces are accessible for rent in Dubai. Book your office for rent in Dubai outfitted with all cutting edge office conveniences.",
            "descriptionH1": "",
            "keywords": "Offices for rent in Dubai UAE, office space for rent in Dubai, office on rent in Dubai"
        },
        {
            "path": "/en/sale/offices-for-sale-in-dubai",
            "homepageSection": "Top Searches Commercial in Dubai",
            "homePageLink": "Office for Sale in Dubai",
            "businessType": "sale",
            "propertyType": "OFF",
            "category": 1,
            "location": "dubai",
            "bedrooms": null,
            "minPrice": null,
            "maxPrice": "",
            "title": "Offices for Sale in Dubai | zeekeez.com",
            "numberProperties": 1678,
            "h1": "Offices for sale in Dubai UAE",
            "metadescription": "Offices for Sale in Dubai UAE. Buy Sell Commercial Properties in Dubai : Office, Full Floor, Full Building...",
            "descriptionH1": "",
            "keywords": ""
        }
    ]