import React, { useState, useEffect } from 'react';
import { convert } from 'url-slug';
import _debounce from 'lodash/debounce';

import './LocationForm.scss';

import {
  MyPZTextField,
  MyPZButton,
  MyPZSelect,
} from '@mypz/react-kit';
import { adminListLocations } from '../../../api/locations/locationsApi';

const initialData = {
  parentId: 1,
  mainLocationId: 0,
  path: "1.123",
  country: "uae",
  name: "Jumeirah Beach Residence",
  nameAr: "...",
  fullPathName: "UAE, Dubai, Jumeirah Beach Residence",
  abbreviation: "JBR",
  geom: "",
  slug: "jumeirah-beach-residence",
  status: "draft",
  weight: 0,
};

const statusOptions = [
  {
    text: "Draft (not visible by users and need to be managed)",
    value: "draft",
  },
  {
    text: "Ignored (not visible by users and managed)",
    value: "ignored",
  },
  {
    text: "Published (visible by users)",
    value: "published",
  },
];

const LocationForm = (props) => {
  const { location, isProcessing, onSubmit, isUpdate } = props;
  const [formData, setFormData] = useState(initialData);
  const [mainLocationSearch, setMainLocationSearch] = useState(null);
  const [mainLocations, setMainLocations] = useState([]);

  const debouncedSetLocationFilterList = _debounce(async (filter) => {
    const locations = await adminListLocations(filter);
    setMainLocations(locations.locations);
  }, 200);

  useEffect(() => {
    debouncedSetLocationFilterList({
      name: mainLocationSearch,
      mainLocationId: 0,
    });
    return () => {
      debouncedSetLocationFilterList.cancel();
    };
  }, [mainLocationSearch]);

  const handleInputChange = (key) => (e) => {
    const newFormData = { ...formData, [key]: e.target.value };
    if (key === 'name' || key === 'slug') {
      newFormData.slug = convert(e.target.value);
    }

    if (key === 'parentId' || key === 'weight') {
      newFormData[key] = parseInt(e.target.value);
    }

    setFormData(newFormData);
  };

  useEffect(() => {
    if (location) {
      setFormData({ ...initialData, ...location });
    }
  }, [location]);

  const isValidForm = () => {
    return !!formData.name && !!formData.path && !!formData.fullPathName && !!formData.slug;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);

    if (!isValidForm()) {
      return;
    }

    onSubmit(formData);
  };

  const renderRemoveMainLocationButton = () => {
    if (!formData.mainLocationName) {
      return <div>
      <MyPZButton onClick={(e) => {
        e.preventDefault();
        setFormData({
          ...formData,
          mainLocationId: 0,
          mainLocationName: "Main Location",
        })
      }}>
        Set as a Main Location
      </MyPZButton>
    </div>;
    }

    return <div>
      <MyPZButton onClick={(e) => {
        e.preventDefault();
        setFormData({
          ...formData,
          mainLocationId: null,
          mainLocationName: undefined,
        })
      }}>
        Remove
      </MyPZButton>
    </div>;
  };

  const renderMainLocationOptions = () => {
    return <div className="form-location__main-location-options">
      {
        mainLocations.map((ml) => {
          if (ml.slug === formData.slug) {
            return;
          }

          return <div className="form-location__main-location-option">
            <MyPZButton
              key={ml.id}
              onClick={(e) => {
                e.preventDefault();
                setFormData({
                  ...formData,
                  mainLocationId: ml.id,
                  mainLocationName: ml.name,
                })
              }}
            >
              {ml.name}
            </MyPZButton>
          </div>;
        })
      }
    </div>;
  };

  const renderSearchableUnder = () => {
    if (formData.mainLocationId === 0) {
      return <span className="form-location__main-location-searchable-main">"{formData.name}"</span>;
    }

    if (formData.mainLocationId) {
      return <span className="form-location__main-location-searchable-child">"{formData.mainLocationName}"</span>;
    }

    return <span className="form-location__main-location-searchable-none">Not Searchable</span>;
  };

  const renderMainLocation = () => {
    if (!formData) {
      return;
    }

    return <div className="form-location__main-location">
      <input value={formData.mainLocationId} hidden />

      <div className="form-location__main-location-selected">
        <label>Location searchable:</label>
        {renderSearchableUnder()}
        {renderRemoveMainLocationButton()}
      </div>

      <div className="form-location__main-location-search">
        <label>Search Main Location:</label>
        <input
          type="text"
          onChange={async (e) => {
            setMainLocationSearch(e.target.value);
          }}
          value={mainLocationSearch}
        />
        {renderMainLocationOptions()}
      </div>
    </div>;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-location__field">
        {renderMainLocation()}
      </div>
      <div className="form-location__field">
        <MyPZTextField disabled={isProcessing} type="number" label="Parent ID" placeholder="123" onChange={handleInputChange('parentId')} value={formData.parentId} />
      </div>
      <div className="form-location__field">
        <MyPZTextField disabled={isProcessing} type="text" label="Path" placeholder="123.456.789" onChange={handleInputChange('path')} value={formData.path} />
      </div>
      <div className="form-location__field">
        <MyPZTextField disabled={isProcessing} type="text" label="Country" placeholder="uae" onChange={handleInputChange('country')} value={formData.country} />
      </div>
      <div className="form-location__field">
        <MyPZTextField disabled={isProcessing} type="text" label="Name" placeholder="Jumeirah Beach Residence" onChange={handleInputChange('name')} value={formData.name} />
      </div>
      <div className="form-location__field">
        <MyPZTextField disabled={isProcessing} type="text" label="Slug" placeholder="jumeirah-beach-residence" onChange={handleInputChange('slug')} value={formData.slug} />
      </div>
      <div className="form-location__field">
        <MyPZTextField disabled={isProcessing} type="text" label="Name Arabic" placeholder="..." onChange={handleInputChange('nameAr')} value={formData.nameAr} />
      </div>
      <div className="form-location__field">
        <MyPZTextField disabled={isProcessing} type="text" label="Full Path Name" placeholder="UAE, Dubai, Jumeirah Beach Residence" onChange={handleInputChange('fullPathName')} value={formData.fullPathName} />
      </div>
      <div className="form-location__field">
        <MyPZTextField disabled={isProcessing} type="text" label="Abbreviation" placeholder="JBR" onChange={handleInputChange('abbreviation')} value={formData.abbreviation} />
      </div>
      <div className="form-location__field">
        <MyPZTextField disabled={isProcessing} type="number" label="Weight (Smaller is first)" placeholder="123" onChange={handleInputChange('weight')} value={formData.weight} />
      </div>
      <div className="form-location__field">
        <MyPZSelect options={statusOptions} value={formData.status} onChange={handleInputChange('status')} label="Status" />
      </div>

      <MyPZButton disabled={isProcessing || !isValidForm()} onClick={handleSubmit}>{isUpdate ? 'Update' : 'Create'}</MyPZButton>
    </form>
  );
}

export default LocationForm;
