import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './UpdateAdPage.scss';

import MyPZContainer from '@mypz/react-kit/components/container/MyPZContainer';
import MyPZAlert from '@mypz/react-kit/components/alert/MyPZAlert';
import AdForm from '../../../components/form/ad/AdForm';

import { showAd, updateAd } from '../../../api/ads/adsApi';

const UpdateAdPage = () => {
  const history = useNavigate();
  const { id } = useParams();

  const [isSuccess, setIsSuccess] = useState(false);
  const [errorAlert, setErrorAlert] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const [adData, setAdData] = useState(null);

  useEffect(() => {
    const callApi = async () => {
      try {
        const adResult = await showAd(id);
        setAdData(adResult.ad);
      } catch (error) {
        console.log('ad get error:', error.message);
        setErrorAlert(error.message);
        history('/ads/create');
      }
    };
    callApi();
  }, [id, history]);

  const handleSubmit = async (formData) => {
    setIsProcessing(true);
    try {
      delete formData.imageId;
      await updateAd(id, formData);
      setIsSuccess(true);
    } catch (e) {
      console.log('ad update error:', e.message);
      setErrorAlert(e.message);
    }
    setIsProcessing(false);
  };

  const renderAlert = () => {
    if (isSuccess) {
      return (<MyPZAlert>Ad Updated</MyPZAlert>);
    }

    if (errorAlert) {
      return (<MyPZAlert type="error">{errorAlert}</MyPZAlert>);
    }

    return null;
  };

  return (
    <div className="page-update-ad">
      <MyPZContainer>
        {renderAlert()}
        <h1>Update Ad</h1>
        <AdForm ad={adData} isProcessing={isProcessing} onSubmit={handleSubmit} isUpdate />
      </MyPZContainer>
    </div>
  );
}

export default UpdateAdPage;
