import { post, get, put, deleteRequest } from "../baseApi";

export const createAd = async (params) => {
  const res = await post("/ads/v1/superAdmin/ads", params);
  return res;
};

export const updateAd = async (slug, params) => {
  const res = await put(`/ads/v1/superAdmin/ads/${slug}`, params);
  return res;
};

export const showAd = async (slug) => {
  const res = await get(`/ads/v1/superAdmin/ads/ad/${slug}`);
  return res;
};

export const deleteAd = async (slug) => {
  const res = await deleteRequest(`/ads/v1/superAdmin/ads/${slug}`);
  return res;
};

export const adminListAds = async (params) => {
  const res = await get(`/ads/v1/superAdmin/ads`, params);
  return res;
};
