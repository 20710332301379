import React from "react";

const SearchInput = ({ handleChange, title }) => {
  return (
    <input
      className="form-control"
      type="search"
      placeholder="Search by title"
      aria-label="Search"
      value={title}
      onChange={handleChange}
    />
  );
};

export default SearchInput;
