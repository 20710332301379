/* eslint-disable */
export const locationList = [
    {
        n: "Umm Al Quwain",
        s: "umm-al-quwain"
    },
    {
        n: "Abu Dhabi",
        s: "abu-dhabi"
    },
    {
        n: "Asma Majid Al Futtaim Building",
        s: "asma-majid-al-futtaim-building"
    },
    {
        n: "Al Arjan Building",
        s: "al-arjan-building"
    },
    {
        n: "Precinct Towers",
        s: "precinct-towers"
    },
    {
        n: "Fujairah",
        s: "fujairah"
    },
    {
        n: "Dubai",
        s: "dubai"
    },
    {
        n: "Ras Al Khaimah",
        s: "ras-al-khaimah"
    },
    {
        n: "Umm Al Thuoob",
        s: "umm-al-quwain-umm-al-thuoob"
    },
    {
        n: "Al Riqqa",
        s: "umm-al-quwain-al-riqqa"
    },
    {
        n: "Al Ramla",
        s: "umm-al-quwain-al-ramla"
    },
    {
        n: "RAMLAH",
        s: "umm-al-quwain-al-ramla"
    },
    {
        n: "Al Kaber",
        s: "umm-al-quwain-al-kaber"
    },
    {
        n: "Al Humra",
        s: "umm-al-quwain-al-humra"
    },
    {
        n: "Al Surra",
        s: "umm-al-quwain-al-surra"
    },
    {
        n: "Tourist Club Area",
        s: "abu-dhabi-tourist-club-area"
    },
    {
        n: "Al Shawamekh",
        s: "abu-dhabi-al-shawamekh"
    },
    {
        n: "Ajman",
        s: "ajman"
    },
    {
        n: "Al Maidan",
        s: "umm-al-quwain-al-maidan"
    },
    {
        n: "Khor Al Beidah",
        s: "umm-al-quwain-khor-al-beidah"
    },
    {
        n: "Old Industrial Area",
        s: "umm-al-quwain-old-industrial-area"
    },
    {
        n: "Al Nahyan Camp",
        s: "abu-dhabi-al-nahyan-camp"
    },
    {
        n: "Al Qurm",
        s: "abu-dhabi-al-qurm"
    },
    {
        n: "Al Markaziyah",
        s: "abu-dhabi-al-markaziyah"
    },
    {
        n: "Al Rahba",
        s: "abu-dhabi-al-rahba"
    },
    {
        n: "Al Wahda",
        s: "abu-dhabi-al-wahda"
    },
    {
        n: "Lulu Island",
        s: "abu-dhabi-lulu-island"
    },
    {
        n: "Zayed Military City",
        s: "abu-dhabi-zayed-military-city"
    },
    {
        n: "Capital Centre",
        s: "abu-dhabi-capital-centre"
    },
    {
        n: "Yas Island",
        s: "abu-dhabi-yas-island"
    },
    {
        n: "Al Raha Golf Gardens",
        s: "abu-dhabi-al-raha-golf-gardens"
    },
    {
        n: "Liwa",
        s: "abu-dhabi-liwa"
    }, {
        n: "Sharjah",
        s: "sharjah"
    },
    {
        n: "Umm Al Quwain Marina",
        s: "umm-al-quwain-umm-al-quwain-marina"
    },
    {
        n: "Al Haditha",
        s: "umm-al-quwain-al-haditha"
    },
    {
        n: "Al Salamah",
        s: "umm-al-quwain-al-salamah"
    },
    {
        n: "Umm Dir",
        s: "umm-al-quwain-umm-dir"
    },
    {
        n: "Al Dar Al Baida'a",
        s: "umm-al-quwain-al-dar-al-baida-a"
    },
    {
        n: "Al Salam City",
        s: "umm-al-quwain-al-salam-city"
    },
    {
        n: "Al Madar",
        s: "umm-al-quwain-al-madar"
    },
    {
        n: "Al Bahia",
        s: "abu-dhabi-al-bahia"
    },
    {
        n: "Al Khalidiya",
        s: "abu-dhabi-al-khalidiya"
    },
    {
        n: "Al Bateen",
        s: "abu-dhabi-al-bateen"
    },
    {
        n: "Al Raha Gardens",
        s: "abu-dhabi-al-raha-gardens"
    },
    {
        n: "Baniyas",
        s: "abu-dhabi-baniyas"
    },
    {
        n: "Hydra Village",
        s: "abu-dhabi-hydra-village"
    },
    {
        n: "Mohamed Bin Zayed City",
        s: "abu-dhabi-mohamed-bin-zayed-city"
    },
    {
        n: "Al Shahama",
        s: "abu-dhabi-al-shahama"
    },
    {
        n: "White Bay",
        s: "umm-al-quwain-white-bay"
    },
    {
        n: "Old Town Area",
        s: "umm-al-quwain-old-town-area"
    },
    {
        n: "Al Maryah Island",
        s: "abu-dhabi-al-maryah-island"
    },
    {
        n: "Al Dhafrah",
        s: "abu-dhabi-al-dhafrah"
    },
    {
        n: "Al Manhal",
        s: "abu-dhabi-al-manhal"
    },
    {
        n: "Al Raha Beach",
        s: "abu-dhabi-al-raha-beach"
    },
    {
        n: "City Downtown",
        s: "abu-dhabi-city-downtown"
    },
    {
        n: "Khalifa City",
        s: "abu-dhabi-khalifa-city"
    },
    {
        n: "Mussafah",
        s: "abu-dhabi-mussafah"
    },
    {
        n: "Defense Road",
        s: "abu-dhabi-defense-road"
    },
    {
        n: "Al Jubail Island",
        s: "abu-dhabi-al-jubail-island"
    },
    {
        n: "Al Maffraq",
        s: "abu-dhabi-al-maffraq"
    },
    {
        n: "Al Mushrif",
        s: "abu-dhabi-al-mushrif"
    },
    {
        n: "Al Wathba",
        s: "abu-dhabi-al-wathba"
    },
    {
        n: "Al Maryah",
        s: "abu-dhabi-al-maryah"
    },
    {
        n: "Marina Village",
        s: "abu-dhabi-marina-village"
    },
    {
        n: "Sas Al Nakheel",
        s: "abu-dhabi-sas-al-nakheel"
    },
    {
        n: "Saadiyat Island",
        s: "abu-dhabi-saadiyat-island"
    },
    {
        n: "Al Aman",
        s: "abu-dhabi-al-aman"
    },
    {
        n: "Al Gurm West",
        s: "abu-dhabi-al-gurm-west"
    },
    {
        n: "Al Rayhan",
        s: "abu-dhabi-al-rayhan"
    },
    {
        n: "Airport Road",
        s: "abu-dhabi-airport-road"
    },
    {
        n: "Building Materials City",
        s: "abu-dhabi-building-materials-city"
    },
    {
        n: "Al Samha",
        s: "abu-dhabi-al-samha"
    },
    {
        n: "Al Reem Island",
        s: "abu-dhabi-al-reem-island"
    },
    {
        n: "Madinat Zayed",
        s: "abu-dhabi-madinat-zayed"
    },
    {
        n: "Abu Dhabi Gate City",
        s: "abu-dhabi-abu-dhabi-gate-city"
    },
    {
        n: "Al Ruwais",
        s: "abu-dhabi-al-ruwais"
    },
    {
        n: "Al Khatim",
        s: "abu-dhabi-al-khatim"
    },
    {
        n: "Nareel Island",
        s: "abu-dhabi-nareel-island"
    },
    {
        n: "Ghantoot",
        s: "abu-dhabi-ghantoot"
    },
    {
        n: "Mina Zayed",
        s: "abu-dhabi-mina-zayed"
    },
    {
        n: "Grand Mosque District",
        s: "abu-dhabi-grand-mosque-district"
    },
    {
        n: "Al Ghadeer",
        s: "abu-dhabi-al-ghadeer"
    },
    {
        n: "Happiness Island",
        s: "abu-dhabi-happiness-island"
    },
    {
        n: "Umm Al Nar",
        s: "abu-dhabi-umm-al-nar"
    },
    {
        n: "Al Shalila",
        s: "abu-dhabi-al-shalila"
    },
    {
        n: "Warsan Estate",
        s: "dubai-al-aweer-warsan-estate"
    },
    {
        n: "Hamda 1 Building",
        s: "dubai-al-badaa-hamda-1-building"
    },
    {
        n: "TIME Topaz Hotel Apartments",
        s: "dubai-al-barsha-time-topaz-hotel-apartments"
    },
    {
        n: "Montrell",
        s: "dubai-al-furjan-montrell"
    },
    {
        n: "Ritz Residence",
        s: "dubai-al-furjan-ritz-residence"
    },
    {
        n: "Micasa Avenue",
        s: "dubai-al-furjan-micasa-avenue"
    },
    {
        n: "North Village",
        s: "dubai-al-furjan-north-village"
    },
    {
        n: "AZIZI Pearl",
        s: "dubai-al-furjan-azizi-pearl"
    },
    {
        n: "West Village",
        s: "dubai-al-furjan-west-village"
    },
    {
        n: "East 40",
        s: "dubai-al-furjan-east-40"
    },
    {
        n: "Phase 1",
        s: "dubai-al-furjan-phase-1"
    },
    {
        n: "Jaf8",
        s: "dubai-al-jafiliya-jaf8"
    },
    {
        n: "Al Tibbiya",
        s: "abu-dhabi-al-tibbiya"
    },
    {
        n: "Al Manaseer",
        s: "abu-dhabi-al-manaseer"
    },
    {
        n: "Al Karama",
        s: "abu-dhabi-al-karama"
    },
    {
        n: "Rawdhat Abu Dhabi",
        s: "abu-dhabi-rawdhat-abu-dhabi"
    },
    {
        n: "Between Two Bridges",
        s: "abu-dhabi-between-two-bridges"
    },
    {
        n: "Corniche Road",
        s: "abu-dhabi-corniche-road"
    },
    {
        n: "Al Salam Street",
        s: "abu-dhabi-al-salam-street"
    },
    {
        n: "salam st.",
        s: "abu-dhabi-al-salam-street"
    },
    {
        n: "Al Shamkha South",
        s: "abu-dhabi-al-shamkha-south"
    },
    {
        n: "Desert Village",
        s: "abu-dhabi-desert-village"
    },
    {
        n: "The Marina",
        s: "abu-dhabi-the-marina"
    },
    {
        n: "Ajman Downtown",
        s: "ajman-ajman-downtown"
    },
    {
        n: "Al Alia",
        s: "ajman-al-alia"
    },
    {
        n: "Manama",
        s: "ajman-manama"
    },
    {
        n: "Al Rawda",
        s: "ajman-al-rawda"
    },
    {
        n: "Ajman Uptown",
        s: "ajman-ajman-uptown"
    },
    {
        n: "Sheikh Maktoum Bin Rashid Street",
        s: "ajman-sheikh-maktoum-bin-rashid-street"
    },
    {
        n: "Al Zorah",
        s: "ajman-al-zorah"
    },
    {
        n: "Ain Ajman",
        s: "ajman-ain-ajman"
    },
    {
        n: "Sheikh Khalifa Bin Zayed Street",
        s: "ajman-sheikh-khalifa-bin-zayed-street"
    },
    {
        n: "Al Shuwaihean",
        s: "sharjah-al-shuwaihean"
    },
    {
        n: "Al Sila'a",
        s: "abu-dhabi-al-sila-a"
    },
    {
        n: "Khalifa Street",
        s: "abu-dhabi-khalifa-street"
    }, {
        n: "Hamdan Street",
        s: "abu-dhabi-hamdan-street"
    }, {
        n: "Al Maqtaa",
        s: "abu-dhabi-al-maqtaa"
    }, {
        n: "Mafraq Industrial Area",
        s: "abu-dhabi-mafraq-industrial-area"
    }, {
        n: "Hameem",
        s: "abu-dhabi-hameem"
    }, {
        n: "Al Zahraa",
        s: "abu-dhabi-al-zahraa"
    }, {
        n: "Al Nahda",
        s: "abu-dhabi-al-nahda"
    }, {
        n: "Shakhbout City",
        s: "abu-dhabi-shakhbout-city"
    }, {
        n: "Al Nahyan",
        s: "abu-dhabi-al-nahyan"
    }, {
        n: "Al Amerah",
        s: "ajman-al-amerah"
    }, {
        n: "Emirates City",
        s: "ajman-emirates-city"
    }, {
        n: "Al Bustan",
        s: "ajman-al-bustan"
    }, {
        n: "Al Ittihad Village",
        s: "ajman-al-ittihad-village"
    }, {
        n: "Garden City",
        s: "ajman-garden-city"
    }, {
        n: "Park View City",
        s: "ajman-park-view-city"
    }, {
        n: "Sharjah Industrial Area",
        s: "sharjah-sharjah-industrial-area"
    }, {
        n: "Al Hudayriat Island",
        s: "abu-dhabi-al-hudayriat-island"
    }, {
        n: "Eastern Road",
        s: "abu-dhabi-eastern-road"
    }, {
        n: "Muroor Area",
        s: "abu-dhabi-muroor-area"
    }, {
        n: "Jawazat Street",
        s: "abu-dhabi-jawazat-street"
    }, {
        n: "Dalma Island",
        s: "abu-dhabi-dalma-island"
    }, {
        n: "Al Ittihad Road",
        s: "abu-dhabi-al-ittihad-road"
    }, {
        n: "Ajman Industrial Area",
        s: "ajman-ajman-industrial-area"
    }, {
        n: "Marmooka City",
        s: "ajman-marmooka-city"
    }, {
        n: "Musheiref",
        s: "ajman-musheiref"
    }, {
        n: "Al Ameera Village",
        s: "ajman-al-ameera-village"
    }, {
        n: "Al Yasmeen",
        s: "ajman-al-yasmeen"
    }, {
        n: "Al Sawan",
        s: "ajman-al-sawan"
    }, {
        n: "Al Majaz",
        s: "sharjah-al-majaz"
    }, {
        n: "Al Fayha",
        s: "sharjah-al-fayha"
    }, {
        n: "Al Suyoh",
        s: "sharjah-al-suyoh"
    }, {
        n: "Al Zubair",
        s: "sharjah-al-zubair"
    }, {
        n: "Aljada",
        s: "sharjah-aljada"
    }, {
        n: "Al Nasreya",
        s: "sharjah-al-nasreya"
    }, {
        n: "Al Madam",
        s: "sharjah-al-madam"
    }, {
        n: "Al Ghafeyah area",
        s: "sharjah-al-ghafeyah-area"
    }, {
        n: "Al Qarain",
        s: "sharjah-al-qarain"
    }, {
        n: "Rolla Area",
        s: "sharjah-rolla-area"
    }, {
        n: "Al Suyoh Suburb",
        s: "sharjah-al-suyoh-suburb"
    }, {
        n: "Al Nabba",
        s: "sharjah-al-nabba"
    }, {
        n: "Al Dhaid",
        s: "sharjah-al-dhaid"
    }, {
        n: "Sheikh Hamad Bin Abdullah St.",
        s: "fujairah-sheikh-hamad-bin-abdullah-st"
    }, {
        n: "Al Shamkha",
        s: "abu-dhabi-al-shamkha"
    }, {
        n: "Al Zaab",
        s: "abu-dhabi-al-zaab"
    }, {
        n: "Masdar City",
        s: "abu-dhabi-masdar-city"
    }, {
        n: "Al Mwaihat",
        s: "ajman-al-mwaihat"
    }, {
        n: "Al Rumaila",
        s: "ajman-al-rumaila"
    }, {
        n: "Romila",
        s: "ajman-al-rumaila"
    }, {
        n: "Al Zahraa",
        s: "ajman-al-zahraa"
    }, {
        n: "Liwara 1",
        s: "ajman-liwara-1"
    }, {
        n: "Awali City",
        s: "ajman-awali-city"
    }, {
        n: "Al Naemiyah",
        s: "ajman-al-naemiyah"
    }, {
        n: "Naemiya, Naemia",
        s: "ajman-al-naemiyah"
    }, {
        n: "Ajman Meadows",
        s: "ajman-ajman-meadows"
    }, {
        n: "Al Khan",
        s: "sharjah-al-khan"
    }, {
        n: "Al Taawun",
        s: "sharjah-al-taawun"
    }, {
        n: "Al Rahmaniya",
        s: "sharjah-al-rahmaniya"
    }, {
        n: "Al Heerah",
        s: "sharjah-al-heerah"
    }, {
        n: "Maryam Island",
        s: "sharjah-maryam-island"
    }, {
        n: "Muwaileh",
        s: "sharjah-muwaileh"
    }, {
        n: "Al Khaldeia Area",
        s: "sharjah-al-khaldeia-area"
    }, {
        n: "Al Qasemiya",
        s: "sharjah-al-qasemiya"
    }, {
        n: "Sharjah Waterfront City",
        s: "sharjah-sharjah-waterfront-city"
    }, {
        n: "Wasit",
        s: "sharjah-wasit"
    }, {
        n: "Dibba Al Fujairah",
        s: "fujairah-dibba-al-fujairah"
    }, {
        n: "Al Reef",
        s: "abu-dhabi-al-reef"
    }, {
        n: "Al Najda Street",
        s: "abu-dhabi-al-najda-street"
    }, {
        n: "Electra Street",
        s: "abu-dhabi-electra-street"
    }, {
        n: "Al Rawdah",
        s: "abu-dhabi-al-rawdah"
    }, {
        n: "Al Owan",
        s: "ajman-al-owan"
    }, {
        n: "Al Rashidiya",
        s: "ajman-al-rashidiya"
    }, {
        n: "Al Raqaib",
        s: "ajman-al-raqaib"
    }, {
        n: "Al Humaid City",
        s: "ajman-al-humaid-city"
    }, {
        n: "Green City",
        s: "ajman-green-city"
    }, {
        n: "Corniche Ajman",
        s: "ajman-corniche-ajman"
    }, {
        n: "Masfoot",
        s: "ajman-masfoot"
    }, {
        n: "Al Hamidiya",
        s: "ajman-al-hamidiya"
    }, {
        n: "Al Musalla",
        s: "sharjah-al-musalla"
    }, {
        n: "Al Mamzar - Sharjah",
        s: "sharjah-al-mamzar-sharjah"
    }, {
        n: "Al Garayen",
        s: "sharjah-al-garayen"
    }, {
        n: "Al Tayy Suburb",
        s: "sharjah-al-tayy-suburb"
    }, {
        n: "Al Riqqa",
        s: "sharjah-al-riqqa"
    }, {
        n: "Al Nujoom Islands",
        s: "sharjah-al-nujoom-islands"
    }, {
        n: "Al Turfa",
        s: "ras-al-khaimah-al-turfa"
    }, {
        n: "Ajman Corniche Road",
        s: "ajman-ajman-corniche-road"
    }, {
        n: "Al Nahda",
        s: "sharjah-al-nahda"
    }, {
        n: "Al Mujarrah",
        s: "sharjah-al-mujarrah"
    }, {
        n: "Al Sharq",
        s: "sharjah-al-sharq"
    }, {
        n: "Al Sajaa",
        s: "sharjah-al-sajaa"
    }, {
        n: "Umm Khanoor",
        s: "sharjah-umm-khanoor"
    }, {
        n: "Al Jurainah",
        s: "sharjah-al-jurainah"
    }, {
        n: "Hamriyah Free Zone",
        s: "sharjah-hamriyah-free-zone"
    }, {
        n: "Al Muntazah",
        s: "sharjah-al-muntazah"
    }, {
        n: "Al Ettihad Street",
        s: "sharjah-al-ettihad-street"
    }, {
        n: "Al Bataeh",
        s: "sharjah-al-bataeh"
    }, {
        n: "Al Gulayaa",
        s: "sharjah-al-gulayaa"
    }, {
        n: "Sharm",
        s: "fujairah-sharm"
    }, {
        n: "Thouban",
        s: "fujairah-thouban"
    }, {
        n: "Al Barari",
        s: "dubai-al-barari"
    }, {
        n: "Al Furjan",
        s: "dubai-al-furjan"
    }, {
        n: "Al Gharb",
        s: "sharjah-al-gharb"
    }, {
        n: "Al Wahda",
        s: "sharjah-al-wahda"
    }, {
        n: "Um Altaraffa",
        s: "sharjah-um-altaraffa"
    }, {
        n: "Al Badie",
        s: "sharjah-al-badie"
    }, {
        n: "Al Nouf",
        s: "sharjah-al-nouf"
    }, {
        n: "Al Shahba",
        s: "sharjah-al-shahba"
    }, {
        n: "Tilal City",
        s: "sharjah-tilal-city"
    }, {
        n: "Cornich Al Buhaira",
        s: "sharjah-cornich-al-buhaira"
    }, {
        n: "Jwezaa",
        s: "sharjah-jwezaa"
    }, {
        n: "Halwan",
        s: "sharjah-halwan"
    }, {
        n: "Al Butina",
        s: "sharjah-al-butina"
    }, {
        n: "Maysaloon",
        s: "sharjah-maysaloon"
    }, {
        n: "Sakamkam",
        s: "fujairah-sakamkam"
    }, {
        n: "Al Barsha",
        s: "dubai-al-barsha"
    }, {
        n: "Al Qusais",
        s: "dubai-al-qusais"
    }, {
        n: "Al Twar",
        s: "dubai-al-twar"
    }, {
        n: "Deira",
        s: "dubai-deira"
    }, {
        n: "Dubai Investment Park",
        s: "dubai-dubai-investment-park"
    }, {
        n: "DIP",
        s: "dubai-dubai-investment-park"
    }, {
        n: "Umm Suqeim",
        s: "dubai-umm-suqeim"
    }, {
        n: "Muwaileh Commercial",
        s: "sharjah-muwaileh-commercial"
    }, {
        n: "Sharjah Airport Freezone (SAIF)",
        s: "sharjah-sharjah-airport-freezone-saif"
    }, {
        n: "Al Qasba",
        s: "sharjah-al-qasba"
    }, {
        n: "Abu shagara",
        s: "sharjah-abu-shagara"
    }, {
        n: "Al Aweer",
        s: "dubai-al-aweer"
    }, {
        n: "Al Khawaneej",
        s: "dubai-al-khawaneej"
    }, {
        n: "Al Wasl",
        s: "dubai-al-wasl"
    }, {
        n: "Business Bay",
        s: "dubai-business-bay"
    }, {
        n: "Downtown Dubai",
        s: "dubai-downtown-dubai"
    }, {
        n: "Dubai Land",
        s: "dubai-dubai-land"
    }, {
        n: "Dubai Lifestyle City",
        s: "dubai-dubai-lifestyle-city"
    }, {
        n: "Green Community",
        s: "dubai-green-community"
    }, {
        n: "Jumeirah Beach Residence",
        s: "dubai-jumeirah-beach-residence"
    }, {
        n: "JBR",
        s: "dubai-jumeirah-beach-residence"
    }, {
        n: "Remraam",
        s: "dubai-remraam"
    }, {
        n: "Mirdif",
        s: "dubai-mirdif"
    }, {
        n: "The Lakes",
        s: "dubai-the-lakes"
    }, {
        n: "Corniche Al Fujairah",
        s: "fujairah-corniche-al-fujairah"
    }, {
        n: "Al Badaa",
        s: "dubai-al-badaa"
    }, {
        n: "Al Quoz",
        s: "dubai-al-quoz"
    }, {
        n: "Al Satwa",
        s: "dubai-al-satwa"
    }, {
        n: "Culture Village",
        s: "dubai-culture-village"
    }, {
        n: "Dubai Festival City",
        s: "dubai-dubai-festival-city"
    }, {
        n: "Dubai Media City",
        s: "dubai-dubai-media-city"
    }, {
        n: "DMC",
        s: "dubai-dubai-media-city"
    }, {
        n: "Dubai Sports City",
        s: "dubai-dubai-sports-city"
    }, {
        n: "Dubai Production City (IMPZ)",
        s: "dubai-dubai-production-city-impz"
    }, {
        n: "Jumeirah Heights",
        s: "dubai-jumeirah-heights"
    }, {
        n: "Jumeirah Village Circle",
        s: "dubai-jumeirah-village-circle"
    }, {
        n: "JVC",
        s: "dubai-jumeirah-village-circle"
    }, {
        n: "Motor City",
        s: "dubai-motor-city"
    }, {
        n: "The Views",
        s: "dubai-the-views"
    }, {
        n: "Dubai Design District",
        s: "dubai-dubai-design-district"
    }, {
        n: "Umm Al Quwain Marina Villas",
        s: "umm-al-quwain-umm-al-quwain-marina-umm-al-quwain-marina-villas"
    }, {
        n: "Ain Al Faydah",
        s: "al-ain-ain-al-faydah"
    }, {
        n: "Al Qurm Gardens",
        s: "abu-dhabi-al-qurm-al-qurm-gardens"
    }, {
        n: "Al Jafiliya",
        s: "dubai-al-jafiliya"
    }, {
        n: "Al Safa",
        s: "dubai-al-safa"
    }, {
        n: "Arabian Ranches",
        s: "dubai-arabian-ranches"
    }, {
        n: "Discovery Gardens",
        s: "dubai-discovery-gardens"
    }, {
        n: "Dubai Silicon Oasis",
        s: "dubai-dubai-silicon-oasis"
    }, {
        n: "DSO",
        s: "dubai-dubai-silicon-oasis"
    }, {
        n: "Greens",
        s: "dubai-greens"
    }, {
        n: "Jebel Ali",
        s: "dubai-jebel-ali"
    }, {
        n: "Jumeirah Park",
        s: "dubai-jumeirah-park"
    }, {
        n: "Maritime City",
        s: "dubai-maritime-city"
    }, {
        n: "Barsha Heights (Tecom)",
        s: "dubai-barsha-heights-tecom"
    }, {
        n: "The Gardens",
        s: "dubai-the-gardens"
    }, {
        n: "Zabeel",
        s: "dubai-zabeel"
    }, {
        n: "Palm Jebel Ali",
        s: "dubai-palm-jebel-ali"
    }, {
        n: "The Sustainable City",
        s: "dubai-the-sustainable-city"
    }, {
        n: "Al Muhaisnah",
        s: "dubai-al-muhaisnah"
    }, {
        n: "Al Warqa'a",
        s: "dubai-al-warqa-a"
    }, {
        n: "DIFC",
        s: "dubai-difc"
    }, {
        n: "Downtown Jebel Ali",
        s: "dubai-downtown-jebel-ali"
    }, {
        n: "Dubai Marina",
        s: "dubai-dubai-marina"
    }, {
        n: "wasl gate",
        s: "dubai-wasl-gate"
    }, {
        n: "Jumeirah Lake Towers",
        s: "dubai-jumeirah-lake-towers"
    }, {
        n: "JLT",
        s: "dubai-jumeirah-lake-towers"
    }, {
        n: "Mudon",
        s: "dubai-mudon"
    }, {
        n: "Nadd Al Sheba",
        s: "dubai-nadd-al-sheba"
    }, {
        n: "Ras Al Khor",
        s: "dubai-ras-al-khor"
    }, {
        n: "Dubai Studio City",
        s: "dubai-dubai-studio-city"
    }, {
        n: "DSC",
        s: "dubai-dubai-studio-city"
    }, {
        n: "Wadi Al Amardi",
        s: "dubai-wadi-al-amardi"
    }, {
        n: "Majan",
        s: "dubai-majan"
    }, {
        n: "International City",
        s: "dubai-international-city"
    }, {
        n: "Dubai Outsource Zone",
        s: "dubai-dubai-outsource-zone"
    }, {
        n: "Jumeirah Village Triangle",
        s: "dubai-jumeirah-village-triangle"
    }, {
        n: "JVT",
        s: "dubai-jumeirah-village-triangle"
    }, {
        n: "Nadd Al Hammar",
        s: "dubai-nadd-al-hammar"
    }, {
        n: "Umm Ramool",
        s: "dubai-umm-ramool"
    }, {
        n: "Mohammed Bin Rashid City",
        s: "dubai-mohammed-bin-rashid-city"
    }, {
        n: "MBR",
        s: "dubai-mohammed-bin-rashid-city"
    }, {
        n: "Golf City",
        s: "dubai-golf-city"
    }, {
        n: "DAMAC Hills (Akoya by DAMAC)",
        s: "dubai-damac-hills-akoya-by-damac"
    }, {
        n: "DuBiotech",
        s: "dubai-du-biotech"
    }, {
        n: "Dubai Hills Estate",
        s: "dubai-dubai-hills-estate"
    }, {
        n: "Meydan",
        s: "dubai-meydan"
    }, {
        n: "Dubai Residence Complex",
        s: "dubai-dubai-residence-complex"
    }, {
        n: "Al Kifaf",
        s: "dubai-al-kifaf"
    }, {
        n: "Al Mina",
        s: "dubai-al-mina"
    }, {
        n: "Rapunzel",
        s: "dubai-living-legends-rapunzel"
    }, {
        n: "Aladdin",
        s: "dubai-living-legends-aladdin"
    }, {
        n: "Al Shaya Building",
        s: "dubai-karama-al-shaya-building"
    }, {
        n: "Al Shindagah",
        s: "dubai-al-shindagah"
    }, {
        n: "Karama",
        s: "dubai-karama"
    }, {
        n: "Akoya",
        s: "dubai-akoya"
    }, {
        n: "Forbidden City",
        s: "dubai-forbidden-city"
    }, {
        n: "Al Warsan",
        s: "dubai-al-warsan"
    }, {
        n: "Al Manara",
        s: "dubai-al-manara"
    }, {
        n: "Al Jaddaf",
        s: "dubai-al-jaddaf"
    }, {
        n: "Eye Park",
        s: "dubai-eye-park"
    }, {
        n: "Emirates Hills",
        s: "dubai-emirates-hills"
    }, {
        n: "Dubai Science Park",
        s: "dubai-dubai-science-park"
    }, {
        n: "Palm Jumeirah",
        s: "dubai-palm-jumeirah"
    }, {
        n: "The Palm Jumeirah",
        s: "dubai-palm-jumeirah"
    }, {
        n: "The Villa",
        s: "dubai-the-villa"
    }, {
        n: "City Walk",
        s: "dubai-city-walk"
    }, {
        n: "Corniche Al Qurm",
        s: "abu-dhabi-al-qurm-corniche-al-qurm"
    }, {
        n: "The Crystal Tower",
        s: "abu-dhabi-al-khalidiya-the-crystal-tower"
    }, {
        n: "Al Saada Street",
        s: "abu-dhabi-al-mushrif-al-saada-street"
    }, {
        n: "Al Ward",
        s: "abu-dhabi-al-raha-gardens-al-ward"
    }, {
        n: "Arabian Ranches 2",
        s: "dubai-arabian-ranches-2"
    }, {
        n: "Living Legends",
        s: "dubai-living-legends"
    }, {
        n: "Al Mizhar",
        s: "dubai-al-mizhar"
    }, {
        n: "Jumeirah Golf Estates",
        s: "dubai-jumeirah-golf-estates"
    }, {
        n: "Bluewaters",
        s: "dubai-bluewaters"
    }, {
        n: "Mizin",
        s: "dubai-mizin"
    }, {
        n: "Dubai Airport Freezone (DAFZA)",
        s: "dubai-dubai-airport-freezone-dafza"
    }, {
        n: "Tilal Al Ghaf",
        s: "dubai-tilal-al-ghaf"
    }, {
        n: "Al Nahda",
        s: "dubai-al-nahda"
    }, {
        n: "Arjan",
        s: "dubai-arjan"
    }, {
        n: "Mina Rashid",
        s: "dubai-mina-rashid"
    }, {
        n: "Dubai Harbour",
        s: "dubai-dubai-harbour"
    }, {
        n: "Sidroh",
        s: "ras-al-khaimah-sidroh"
    }, {
        n: "Al Maskan",
        s: "dubai-karama-al-maskan"
    }, {
        n: "Al Hashimi Building",
        s: "dubai-karama-al-hashimi-building"
    }, {
        n: "wasl Opal",
        s: "dubai-karama-wasl-opal"
    }, {
        n: "wasl Duet",
        s: "dubai-karama-wasl-duet"
    }, {
        n: "Al Telal 7",
        s: "dubai-karama-al-telal-7"
    }, {
        n: "City Tower 2 Karama",
        s: "dubai-karama-city-tower-2-karama"
    }, {
        n: "NKH",
        s: "dubai-karama-nkh"
    }, {
        n: "wasl Ruby",
        s: "dubai-karama-wasl-ruby"
    }, {
        n: "Khalid Building",
        s: "dubai-karama-khalid-building"
    }, {
        n: "Al Rimmal Residences",
        s: "dubai-al-jaddaf-al-rimmal-residences"
    }, {
        n: "Lilac Residence",
        s: "dubai-al-jaddaf-lilac-residence"
    }, {
        n: "Building A",
        s: "dubai-green-community-garden-west-apartments-building-a"
    }, {
        n: "Afnan 3",
        s: "dubai-dubai-production-city-impz-midtown-afnan-3"
    }, {
        n: "Al Ramth 49",
        s: "dubai-remraam-al-ramth-al-ramth-49"
    }, {
        n: "Al Ain",
        s: "al-ain"
    }, {
        n: "The World Islands",
        s: "dubai-the-world-islands"
    }, {
        n: "Jumeirah",
        s: "dubai-jumeirah"
    }, {
        n: "Rukan",
        s: "dubai-rukan"
    }, {
        n: "Dragon City",
        s: "dubai-dragon-city"
    }, {
        n: "Bawadi",
        s: "dubai-bawadi"
    }, {
        n: "Dubai Healthcare City 2",
        s: "dubai-dubai-healthcare-city-2"
    }, {
        n: "Arabian Ranches 3",
        s: "dubai-arabian-ranches-3"
    }, {
        n: "Mohammad Bin Rashid Gardens",
        s: "dubai-mohammad-bin-rashid-gardens"
    }, {
        n: "World Trade Center",
        s: "dubai-world-trade-center"
    }, {
        n: "Wadi Al Safa 2",
        s: "dubai-wadi-al-safa-2"
    }, {
        n: "The Valley",
        s: "dubai-the-valley"
    }, {
        n: "The Hills",
        s: "dubai-the-hills"
    }, {
        n: "Sheikh Zayed Road",
        s: "dubai-sheikh-zayed-road"
    }, {
        n: "SZR",
        s: "dubai-sheikh-zayed-road"
    }, {
        n: "Khatt",
        s: "ras-al-khaimah-khatt"
    }, {
        n: "MBK Al Qurm Compound",
        s: "abu-dhabi-al-qurm-mbk-al-qurm-compound"
    }, {
        n: "Lehweih Community",
        s: "abu-dhabi-al-raha-gardens-lehweih-community"
    }, {
        n: "HM Tower",
        s: "abu-dhabi-airport-road-hm-tower"
    }, {
        n: "Dubai Promenade",
        s: "dubai-dubai-promenade"
    }, {
        n: "Dubai Waterfront",
        s: "dubai-dubai-waterfront"
    }, {
        n: "Technology Park",
        s: "dubai-technology-park"
    }, {
        n: "Pacific Village",
        s: "dubai-pacific-village"
    }, {
        n: "The Ridge",
        s: "dubai-the-ridge"
    }, {
        n: "Jumeirah Islands",
        s: "dubai-jumeirah-islands"
    }, {
        n: "Dubai Healthcare City",
        s: "dubai-dubai-healthcare-city"
    }, {
        n: "DHCC",
        s: "dubai-dubai-healthcare-city"
    }, {
        n: "Al Hudaibah",
        s: "ras-al-khaimah-al-hudaibah"
    }, {
        n: "Al Hamra Village",
        s: "ras-al-khaimah-al-hamra-village"
    }, {
        n: "Khuzam",
        s: "ras-al-khaimah-khuzam"
    }, {
        n: "Yasmin Village",
        s: "ras-al-khaimah-yasmin-village"
    }, {
        n: "City Downtown",
        s: "ras-al-khaimah-city-downtown"
    }, {
        n: "Chain Tower",
        s: "abu-dhabi-muroor-area-chain-tower"
    }, {
        n: "Goldan Tower",
        s: "abu-dhabi-muroor-area-goldan-tower"
    }, {
        n: "Al Diar Palm Hotel Apartments",
        s: "abu-dhabi-muroor-area-al-diar-palm-hotel-apartments"
    }, {
        n: "Al Bateen Airport",
        s: "abu-dhabi-muroor-area-al-bateen-airport"
    }, {
        n: "Reem Investments HQ",
        s: "abu-dhabi-muroor-area-reem-investments-hq"
    }, {
        n: "C60",
        s: "abu-dhabi-muroor-area-c60"
    }, {
        n: "Sola Tower",
        s: "abu-dhabi-al-najda-street-sola-tower"
    }, {
        n: "The Roots Akoya Oxygen",
        s: "dubai-the-roots-akoya-oxygen"
    }, {
        n: "Al Garhoud",
        s: "dubai-al-garhoud"
    }, {
        n: "The Springs",
        s: "dubai-the-springs"
    }, {
        n: "Hatta",
        s: "dubai-hatta"
    }, {
        n: "Dubai Industrial Park",
        s: "dubai-dubai-industrial-park"
    }, {
        n: "Umm Al Sheif",
        s: "dubai-umm-al-sheif"
    }, {
        n: "Ras Al Khaimah Creek",
        s: "ras-al-khaimah-ras-al-khaimah-creek"
    }, {
        n: "The Old Market",
        s: "ras-al-khaimah-the-old-market"
    }, {
        n: "Al Mamourah",
        s: "ras-al-khaimah-al-mamourah"
    }, {
        n: "Cornich Ras Al Khaima",
        s: "ras-al-khaimah-cornich-ras-al-khaima"
    }, {
        n: "Ras Al Khaimah Waterfront",
        s: "ras-al-khaimah-ras-al-khaimah-waterfront"
    }, {
        n: "Al Fargo Building",
        s: "abu-dhabi-tourist-club-area-al-fargo-building"
    }, {
        n: "Sedar Building",
        s: "abu-dhabi-tourist-club-area-sedar-building"
    }, {
        n: "Al Aryam Tower",
        s: "abu-dhabi-tourist-club-area-al-aryam-tower"
    }, {
        n: "International Tower",
        s: "abu-dhabi-capital-centre-international-tower"
    }, {
        n: "Capital Gate",
        s: "abu-dhabi-capital-centre-capital-gate"
    }, {
        n: "Garden View Tower",
        s: "abu-dhabi-khalifa-street-garden-view-tower"
    }, {
        n: "Al Raas",
        s: "umm-al-quwain-al-raas"
    }, {
        n: "Emirates Modern Industrial",
        s: "umm-al-quwain-emirates-modern-industrial"
    }, {
        n: "Falaj Al Moalla",
        s: "umm-al-quwain-falaj-al-moalla"
    }, {
        n: "Al Sufouh",
        s: "dubai-al-sufouh"
    }, {
        n: "Town Square",
        s: "dubai-town-square"
    }, {
        n: "Dafan Al Nakheel",
        s: "ras-al-khaimah-dafan-al-nakheel"
    }, {
        n: "Al Nakheel",
        s: "ras-al-khaimah-al-nakheel"
    }, {
        n: "Al Kali Building",
        s: "abu-dhabi-tourist-club-area-al-kali-building"
    }, {
        n: "Al Ramhan Tower",
        s: "abu-dhabi-tourist-club-area-al-ramhan-tower"
    }, {
        n: "Beach Rotana",
        s: "abu-dhabi-tourist-club-area-beach-rotana"
    }, {
        n: "Bay View",
        s: "abu-dhabi-tourist-club-area-bay-view"
    }, {
        n: "Al Badaa",
        s: "abu-dhabi-al-badaa"
    }, {
        n: "Al Mirfa",
        s: "abu-dhabi-al-mirfa"
    }, {
        n: "Al Riffa",
        s: "ras-al-khaimah-al-riffa"
    }, {
        n: "Seih Al Ghubb",
        s: "ras-al-khaimah-seih-al-ghubb"
    }, {
        n: "Dafan Al Khor",
        s: "ras-al-khaimah-dafan-al-khor"
    }, {
        n: "Ras al Khaimah Gateway",
        s: "ras-al-khaimah-ras-al-khaimah-gateway"
    }, {
        n: "Dana Island",
        s: "abu-dhabi-dana-island"
    }, {
        n: "Al Baraha",
        s: "abu-dhabi-al-baraha"
    }, {
        n: "Al Fahid",
        s: "abu-dhabi-al-fahid"
    }, {
        n: "Seih Al Bana",
        s: "ras-al-khaimah-seih-al-bana"
    }, {
        n: "Al Uraibi",
        s: "ras-al-khaimah-al-uraibi"
    }, {
        n: "Sheikh Mohammed Bin Zayed Road",
        s: "ras-al-khaimah-sheikh-mohammed-bin-zayed-road"
    }, {
        n: "Al Mairid",
        s: "ras-al-khaimah-al-mairid"
    }, {
        n: "Khalifa Residential Complex B",
        s: "abu-dhabi-tourist-club-area-khalifa-residential-complex-b"
    }, {
        n: "Al Falah City",
        s: "abu-dhabi-al-falah-city"
    }, {
        n: "Al Tai",
        s: "sharjah-al-tai"
    }, {
        n: "Downtown Fujairah",
        s: "fujairah-downtown-fujairah"
    }, {
        n: "Academic City",
        s: "dubai-academic-city"
    }, {
        n: "Dubai Creek Harbour (The Lagoons)",
        s: "dubai-dubai-creek-harbour-the-lagoons"
    }, {
        n: "City of Arabia",
        s: "dubai-city-of-arabia"
    }, {
        n: "RAK FTZ",
        s: "ras-al-khaimah-rak-ftz"
    }, {
        n: "Al Juwais",
        s: "ras-al-khaimah-al-juwais"
    }, {
        n: "Dahan",
        s: "ras-al-khaimah-dahan"
    }, {
        n: "Al Qusaidat",
        s: "ras-al-khaimah-al-qusaidat"
    }, {
        n: "Al Manaseer",
        s: "al-ain-al-manaseer"
    }, {
        n: "Al Shuibah",
        s: "al-ain-al-shuibah"
    }, {
        n: "Wahat AlZaweya",
        s: "al-ain-wahat-al-zaweya"
    }, {
        n: "Al Yahar",
        s: "al-ain-al-yahar"
    }, {
        n: "Bahkeet Tower",
        s: "abu-dhabi-tourist-club-area-bahkeet-tower"
    }, {
        n: "Maha Center",
        s: "abu-dhabi-tourist-club-area-maha-center"
    }, {
        n: "Eastern Mangroves Complex",
        s: "abu-dhabi-eastern-road-eastern-mangroves-complex"
    }, {
        n: "Al Mina",
        s: "abu-dhabi-al-mina"
    }, {
        n: "Al Zahya",
        s: "ajman-al-zahya"
    }, {
        n: "Sifuni",
        s: "ras-al-khaimah-sifuni"
    }, {
        n: "Al Kharran",
        s: "ras-al-khaimah-al-kharran"
    }, {
        n: "Al Jazirah Al Hamra",
        s: "ras-al-khaimah-al-jazirah-al-hamra"
    }, {
        n: "Fotouh Al Khair",
        s: "abu-dhabi-airport-road-fotouh-al-khair"
    }, {
        n: "Jasmine Tower",
        s: "abu-dhabi-airport-road-jasmine-tower"
    }, {
        n: "Nurai Island",
        s: "abu-dhabi-nurai-island"
    }, {
        n: "Al Gurm",
        s: "abu-dhabi-al-gurm"
    }, {
        n: "Al Dhait",
        s: "ras-al-khaimah-al-dhait"
    }, {
        n: "Al Seer",
        s: "ras-al-khaimah-al-seer"
    }, {
        n: "New Emi State Tower",
        s: "abu-dhabi-airport-road-new-emi-state-tower"
    }, {
        n: "Hadef Jawaan Al Dhahiry Building",
        s: "abu-dhabi-muroor-area-hadef-jawaan-al-dhahiry-building"
    }, {
        n: "Zayed Sports City",
        s: "abu-dhabi-zayed-sports-city"
    }, {
        n: "Al Madina Al Riadeya",
        s: "abu-dhabi-zayed-sports-city"
    }, {
        n: "Danet Abu Dhabi",
        s: "abu-dhabi-danet-abu-dhabi"
    }, {
        n: "Global Village",
        s: "dubai-global-village"
    }, {
        n: "Dubai Internet City",
        s: "dubai-dubai-internet-city"
    }, {
        n: "Al Sinaiya",
        s: "al-ain-al-sinaiya"
    }, {
        n: "Al Jimi",
        s: "al-ain-al-jimi"
    }, {
        n: "Al Jaheli",
        s: "al-ain-al-jaheli"
    }, {
        n: "Al Markhaniya",
        s: "al-ain-al-markhaniya"
    }, {
        n: "Al Muwahie",
        s: "al-ain-al-muwahie"
    }, {
        n: "Al Foah",
        s: "al-ain-al-foah"
    }, {
        n: "Al Zahiyah",
        s: "abu-dhabi-al-zahiyah"
    }, {
        n: "New industrial area",
        s: "ajman-new-industrial-area"
    }, {
        n: "Al Rashidiya",
        s: "dubai-al-rashidiya"
    }, {
        n: "Falcon City of Wonders",
        s: "dubai-falcon-city-of-wonders"
    }, {
        n: "Reem",
        s: "dubai-reem"
    }, {
        n: "Bur Dubai",
        s: "dubai-bur-dubai"
    }, {
        n: "Wadi Al Safa 3",
        s: "dubai-wadi-al-safa-3"
    }, {
        n: "Al Helio",
        s: "ajman-al-helio"
    }, {
        n: "Al Hilal City",
        s: "fujairah-al-hilal-city"
    }, {
        n: "Meadows",
        s: "dubai-meadows"
    }, {
        n: "Al Lisaili",
        s: "dubai-al-lisaili"
    }, {
        n: "Old Town",
        s: "dubai-old-town"
    }, {
        n: "Al Khail Heights",
        s: "dubai-al-khail-heights"
    }, {
        n: "Dubai South (Dubai World Central)",
        s: "dubai-dubai-south-dubai-world-central"
    }, {
        n: "Mughaidir",
        s: "sharjah-mughaidir"
    }, {
        n: "Khor Fakkan",
        s: "fujairah-khor-fakkan"
    }, {
        n: "Mushrif Park",
        s: "dubai-mushrif-park"
    }, {
        n: "Emirates Golf Club",
        s: "dubai-emirates-golf-club"
    }, {
        n: "Dubai Pearl",
        s: "dubai-dubai-pearl"
    }, {
        n: "The Pearl",
        s: "dubai-dubai-pearl"
    }, {
        n: "Oud Al Muteena",
        s: "dubai-oud-al-muteena"
    }, {
        n: "Al Towayya",
        s: "al-ain-al-towayya"
    }, {
        n: "Hamsah B",
        s: "dubai-karama-hamsah-b"
    }, {
        n: "Umm Hurair",
        s: "dubai-umm-hurair"
    }, {
        n: "Murqquab",
        s: "dubai-murqquab"
    }, {
        n: "Serena",
        s: "dubai-serena"
    }, {
        n: "Magellan",
        s: "umm-al-quwain-umm-al-quwain-marina-magellan"
    }, {
        n: "Falaj Hazzaa",
        s: "al-ain-falaj-hazzaa"
    }, {
        n: "Tawam",
        s: "al-ain-tawam"
    }, {
        n: "Al Muwaiji",
        s: "al-ain-al-muwaiji"
    }, {
        n: "Al Tawiya",
        s: "al-ain-al-tawiya"
    }, {
        n: "Al Telah",
        s: "al-ain-al-telah"
    }, {
        n: "Khaldiya",
        s: "al-ain-khaldiya"
    }, {
        n: "Al Khabisi",
        s: "al-ain-al-khabisi"
    }, {
        n: "Amwaj Resort",
        s: "umm-al-quwain-white-bay-amwaj-resort"
    }, {
        n: "OPAL Tower",
        s: "abu-dhabi-al-nahyan-camp-opal-tower"
    }, {
        n: "Al Diar Sawa Hotel Apartments",
        s: "abu-dhabi-al-nahyan-camp-al-diar-sawa-hotel-apartments"
    }, {
        n: "Mohammed Othman",
        s: "abu-dhabi-al-nahyan-camp-mohammed-othman"
    }, {
        n: "Al Sarooj",
        s: "al-ain-al-sarooj"
    }, {
        n: "Al Hili",
        s: "al-ain-al-hili"
    }, {
        n: "Al Ain Industrial Area",
        s: "al-ain-al-ain-industrial-area"
    }, {
        n: "Al Murabaa",
        s: "al-ain-al-murabaa"
    }, {
        n: "Al Dhahrah",
        s: "al-ain-al-dhahrah"
    }, {
        n: "Al Faqa’a",
        s: "al-ain-al-faqa-a"
    }, {
        n: "Al Faqaa",
        s: "al-ain-al-faqa-a"
    }, {
        n: "Bida Bin Ammar",
        s: "al-ain-bida-bin-ammar"
    }, {
        n: "Al Rawdah Al Sharqiyah",
        s: "al-ain-al-rawdah-al-sharqiyah"
    }, {
        n: "Asharej",
        s: "al-ain-asharej"
    }, {
        n: "Al Ramla 1",
        s: "umm-al-quwain-al-ramla-al-ramla-1"
    }, {
        n: "Al Humra 2",
        s: "umm-al-quwain-al-humra-al-humra-2"
    }, {
        n: "Al Rawda",
        s: "umm-al-quwain-al-salam-city-al-rawda"
    }, {
        n: "Shamal Julphar",
        s: "ras-al-khaimah-shamal-julphar"
    }, {
        n: "Al Masoodi",
        s: "al-ain-al-masoodi"
    }, {
        n: "Al Buraymi",
        s: "al-ain-al-buraymi"
    }, {
        n: "Al Mutarad",
        s: "al-ain-al-mutarad"
    }, {
        n: "Mutarad",
        s: "al-ain-al-mutarad"
    }, {
        n: "Al Neyadat",
        s: "al-ain-al-neyadat"
    }, {
        n: "Al Niyadat",
        s: "al-ain-al-niyadat"
    }, {
        n: "Al Oyoun Village",
        s: "al-ain-al-oyoun-village"
    }, {
        n: "Sweihan",
        s: "al-ain-sweihan"
    }, {
        n: "Al Bateen",
        s: "al-ain-al-bateen"
    }, {
        n: "Industrial Area 1",
        s: "umm-al-quwain-emirates-modern-industrial-industrial-area-1"
    }, {
        n: "Al Humra 3",
        s: "umm-al-quwain-al-humra-al-humra-3"
    }, {
        n: "Old Bazaar",
        s: "umm-al-quwain-old-town-area-old-bazaar"
    }, {
        n: "Nasser Al Khaili Tower",
        s: "abu-dhabi-al-dhafrah-nasser-al-khaili-tower"
    }, {
        n: "Al Qurm Mangroves",
        s: "abu-dhabi-al-qurm-al-qurm-mangroves"
    }, {
        n: "Al Qurm Street",
        s: "abu-dhabi-al-qurm-al-qurm-street"
    }, {
        n: "Al Marjan Island",
        s: "ras-al-khaimah-al-marjan-island"
    }, {
        n: "Al Rams",
        s: "ras-al-khaimah-al-rams"
    }, {
        n: "Al Qurm",
        s: "ras-al-khaimah-al-qurm"
    }, {
        n: "Seih Al Harf",
        s: "ras-al-khaimah-seih-al-harf"
    }, {
        n: "Farms Area",
        s: "umm-al-quwain-al-kaber-farms-area"
    }, {
        n: "Al Madar 2",
        s: "umm-al-quwain-al-madar-al-madar-2"
    }, {
        n: "Al Rumaithy Building",
        s: "abu-dhabi-al-nahyan-camp-al-rumaithy-building"
    }, {
        n: "Khalifa Kait Al Qubaisi",
        s: "abu-dhabi-al-nahyan-camp-khalifa-kait-al-qubaisi"
    }, {
        n: "Al Dhafrah 2",
        s: "abu-dhabi-al-dhafrah-al-dhafrah-2"
    }, {
        n: "Mina Al Arab",
        s: "ras-al-khaimah-mina-al-arab"
    }, {
        n: "Sheikh Muhammad Bin Salem Road",
        s: "ras-al-khaimah-sheikh-muhammad-bin-salem-road"
    }, {
        n: "Seih Al Uraibi",
        s: "ras-al-khaimah-seih-al-uraibi"
    }, {
        n: "Al Mataf",
        s: "ras-al-khaimah-al-mataf"
    }, {
        n: "Al Ramla 2",
        s: "umm-al-quwain-al-ramla-al-ramla-2"
    }, {
        n: "Al Humra 1",
        s: "umm-al-quwain-al-humra-al-humra-1"
    }, {
        n: "Al Naseem",
        s: "umm-al-quwain-al-salam-city-al-naseem"
    }, {
        n: "Al Maryah Vista",
        s: "abu-dhabi-al-maryah-island-al-maryah-vista"
    }, {
        n: "Al Markaz Industrial Development",
        s: "abu-dhabi-al-dhafrah-al-markaz-industrial-development"
    }, {
        n: "Four Seasons Hotel",
        s: "abu-dhabi-al-maryah-island-four-seasons-hotel"
    }, {
        n: "Hazaa Bin Zayed the First Street",
        s: "abu-dhabi-al-nahyan-camp-hazaa-bin-zayed-the-first-street"
    }, {
        n: "Dusit Thani Complex",
        s: "abu-dhabi-al-nahyan-camp-dusit-thani-complex"
    }, {
        n: "Blue Towers",
        s: "abu-dhabi-al-dhafrah-blue-towers"
    }, {
        n: "Saraya Islands",
        s: "ras-al-khaimah-saraya-islands"
    }, {
        n: "Al Gaz Tower",
        s: "abu-dhabi-al-dhafrah-al-gaz-tower"
    }, {
        n: "Al Seef Residences",
        s: "abu-dhabi-al-qurm-al-seef-residences"
    }, {
        n: "Amwaj Tower",
        s: "abu-dhabi-al-khalidiya-amwaj-tower"
    }, {
        n: "Khalidiya Twin Towers",
        s: "abu-dhabi-al-khalidiya-khalidiya-twin-towers"
    }, {
        n: "Mubarak Bin Mohammed Street",
        s: "abu-dhabi-al-khalidiya-mubarak-bin-mohammed-street"
    }, {
        n: "Al Khubairah Tower",
        s: "abu-dhabi-al-khalidiya-al-khubairah-tower"
    }, {
        n: "Hilton Baynouna",
        s: "abu-dhabi-al-khalidiya-hilton-baynouna"
    }, {
        n: "Al Muhairy Centre",
        s: "abu-dhabi-al-khalidiya-al-muhairy-centre"
    }, {
        n: "Zayed the First Street",
        s: "abu-dhabi-al-khalidiya-zayed-the-first-street"
    }, {
        n: "Khalidiya Towers",
        s: "abu-dhabi-al-khalidiya-khalidiya-towers"
    }, {
        n: "Al Ettihad Towers",
        s: "abu-dhabi-al-bateen-al-ettihad-towers"
    }, {
        n: "Al Marasy",
        s: "abu-dhabi-al-bateen-al-marasy"
    }, {
        n: "Obaid 33 Building",
        s: "abu-dhabi-al-markaziyah-obaid-33-building"
    }, {
        n: "Bin Majid Tower",
        s: "abu-dhabi-al-mina-bin-majid-tower"
    }, {
        n: "C45",
        s: "abu-dhabi-muroor-area-c45"
    }, {
        n: "Al Istiqlal Street",
        s: "abu-dhabi-al-khalidiya-al-istiqlal-street"
    }, {
        n: "Amana Tower",
        s: "abu-dhabi-al-khalidiya-amana-tower"
    }, {
        n: "Ganadah Tower",
        s: "abu-dhabi-al-khalidiya-ganadah-tower"
    }, {
        n: "Kamala Tower",
        s: "abu-dhabi-al-khalidiya-kamala-tower"
    }, {
        n: "Shining Towers",
        s: "abu-dhabi-al-khalidiya-shining-towers"
    }, {
        n: "Al Khaleej Al Arabi Street",
        s: "abu-dhabi-al-bateen-al-khaleej-al-arabi-street"
    }, {
        n: "Al Yasat Compound",
        s: "abu-dhabi-al-mushrif-al-yasat-compound"
    }, {
        n: "Khannour Community",
        s: "abu-dhabi-al-raha-gardens-khannour-community"
    }, {
        n: "Hemaim Community",
        s: "abu-dhabi-al-raha-gardens-hemaim-community"
    }, {
        n: "Sidra Community",
        s: "abu-dhabi-al-raha-gardens-sidra-community"
    }, {
        n: "Al Dana",
        s: "abu-dhabi-al-raha-beach-al-dana"
    }, {
        n: "Al Thurayya",
        s: "abu-dhabi-al-raha-beach-al-thurayya"
    }, {
        n: "VERA Building",
        s: "abu-dhabi-al-raha-beach-vera-building"
    }, {
        n: "Al Hazem Tower",
        s: "abu-dhabi-al-nahyan-al-hazem-tower"
    }, {
        n: "Khalidiya Village",
        s: "abu-dhabi-al-khalidiya-khalidiya-village"
    }, {
        n: "Al Sawari Tower",
        s: "abu-dhabi-al-khalidiya-al-sawari-tower"
    }, {
        n: "Al Eqabia",
        s: "abu-dhabi-al-khalidiya-al-eqabia"
    }, {
        n: "Sheleila Tower",
        s: "abu-dhabi-al-khalidiya-sheleila-tower"
    }, {
        n: "ZADCO Complex",
        s: "abu-dhabi-al-khalidiya-zadco-complex"
    }, {
        n: "Al Hana Tower",
        s: "abu-dhabi-al-khalidiya-al-hana-tower"
    }, {
        n: "Saed Ahmed Juma Iesa Al Mazroui",
        s: "abu-dhabi-al-khalidiya-saed-ahmed-juma-iesa-al-mazroui"
    }, {
        n: "Aseel Tower",
        s: "abu-dhabi-al-bateen-aseel-tower"
    }, {
        n: "Royal M Hotel & Resort",
        s: "abu-dhabi-al-bateen-royal-m-hotel-resort"
    }, {
        n: "Mubarak Bin Mohammed Street",
        s: "abu-dhabi-al-manhal-mubarak-bin-mohammed-street"
    }, {
        n: "Silver Wave Tower",
        s: "abu-dhabi-al-mina-silver-wave-tower"
    }, {
        n: "Palm Oasis",
        s: "abu-dhabi-al-mushrif-palm-oasis"
    }, {
        n: "Al Mushrif Villas",
        s: "abu-dhabi-al-mushrif-al-mushrif-villas"
    }, {
        n: "Delma Street",
        s: "abu-dhabi-al-mushrif-delma-street"
    }, {
        n: "Muzera Community",
        s: "abu-dhabi-al-raha-gardens-muzera-community"
    }, {
        n: "Yasmin Community",
        s: "abu-dhabi-al-raha-gardens-yasmin-community"
    }, {
        n: "Al Lissaily",
        s: "abu-dhabi-al-raha-beach-al-lissaily"
    }, {
        n: "Awqaf Tower",
        s: "abu-dhabi-al-khalidiya-awqaf-tower"
    }, {
        n: "Crescent Towers",
        s: "abu-dhabi-al-khalidiya-crescent-towers"
    }, {
        n: "Al Ahlia tower",
        s: "abu-dhabi-al-khalidiya-al-ahlia-tower"
    }, {
        n: "Manara Tower",
        s: "abu-dhabi-al-khalidiya-manara-tower"
    }, {
        n: "Al Nakheel Tower",
        s: "abu-dhabi-al-khalidiya-al-nakheel-tower"
    }, {
        n: "Khalidiya Street",
        s: "abu-dhabi-al-khalidiya-khalidiya-street"
    }, {
        n: "Cornich Al Khalidiya",
        s: "abu-dhabi-al-khalidiya-cornich-al-khalidiya"
    }, {
        n: "Al Bateen Wharf",
        s: "abu-dhabi-al-bateen-al-bateen-wharf"
    }, {
        n: "Baynunah Street",
        s: "abu-dhabi-al-bateen-baynunah-street"
    }, {
        n: "Al Markaziyah West",
        s: "abu-dhabi-al-markaziyah-al-markaziyah-west"
    }, {
        n: "Al Mina Tower",
        s: "abu-dhabi-al-mina-al-mina-tower"
    }, {
        n: "Kamal Jamal Musal",
        s: "abu-dhabi-al-mushrif-kamal-jamal-musal"
    }, {
        n: "Al Dhafrah Street",
        s: "abu-dhabi-al-mushrif-al-dhafrah-street"
    }, {
        n: "Samra Community",
        s: "abu-dhabi-al-raha-gardens-samra-community"
    }, {
        n: "Qattouf Community",
        s: "abu-dhabi-al-raha-gardens-qattouf-community"
    }, {
        n: "Al Bandar",
        s: "abu-dhabi-al-raha-beach-al-bandar"
    }, {
        n: "Al Shaleela",
        s: "abu-dhabi-al-raha-beach-al-shaleela"
    }, {
        n: "Amwaj 1 Apartments",
        s: "abu-dhabi-al-raha-beach-amwaj-1-apartments"
    }, {
        n: "Riman Tower",
        s: "abu-dhabi-al-raha-beach-riman-tower"
    }, {
        n: "The Golden Tower",
        s: "abu-dhabi-al-raha-beach-the-golden-tower"
    }, {
        n: "Manazel Al Reef 2",
        s: "abu-dhabi-al-samha-manazel-al-reef-2"
    }, {
        n: "Makeen Tower",
        s: "abu-dhabi-tourist-club-area-makeen-tower"
    }, {
        n: "Al Waha Tower",
        s: "abu-dhabi-al-khalidiya-al-waha-tower"
    }, {
        n: "Mohammed Jaber Rashed Al Hamli",
        s: "abu-dhabi-al-khalidiya-mohammed-jaber-rashed-al-hamli"
    }, {
        n: "20 Villas Project",
        s: "abu-dhabi-al-khalidiya-20-villas-project"
    }, {
        n: "United Square",
        s: "abu-dhabi-al-khalidiya-united-square"
    }, {
        n: "Park Tower",
        s: "abu-dhabi-al-khalidiya-park-tower"
    }, {
        n: "Abu Dhabi Marina",
        s: "abu-dhabi-al-bateen-abu-dhabi-marina"
    }, {
        n: "Al Bateen Complex",
        s: "abu-dhabi-al-bateen-al-bateen-complex"
    }, {
        n: "Six Towers Complex Al Bateen",
        s: "abu-dhabi-al-bateen-six-towers-complex-al-bateen"
    }, {
        n: "Burj Al Arab",
        s: "abu-dhabi-al-markaziyah-burj-al-arab"
    }, {
        n: "Al Maha Complex",
        s: "abu-dhabi-al-mushrif-al-maha-complex"
    }, {
        n: "Mubarak Mohammed Sift Al Khaily",
        s: "abu-dhabi-al-mushrif-mubarak-mohammed-sift-al-khaily"
    }, {
        n: "Mushrif Park",
        s: "abu-dhabi-al-mushrif-mushrif-park"
    }, {
        n: "Al Tharwaniyah Community",
        s: "abu-dhabi-al-raha-gardens-al-tharwaniyah-community"
    }, {
        n: "Al Mariah Community",
        s: "abu-dhabi-al-raha-gardens-al-mariah-community"
    }, {
        n: "Al Muneera Island",
        s: "abu-dhabi-al-raha-beach-al-muneera-island"
    }, {
        n: "Al Rumaila",
        s: "abu-dhabi-al-raha-beach-al-rumaila"
    }, {
        n: "Khor Al Raha",
        s: "abu-dhabi-al-raha-beach-khor-al-raha"
    }, {
        n: "Jamam Residence",
        s: "abu-dhabi-al-raha-beach-jamam-residence"
    }, {
        n: "Al Raha Lofts",
        s: "abu-dhabi-al-raha-beach-al-raha-lofts"
    }, {
        n: "Al Masaood Tower",
        s: "abu-dhabi-al-najda-street-al-masaood-tower"
    }, {
        n: "Al Muneera",
        s: "abu-dhabi-al-raha-beach-al-muneera"
    }, {
        n: "Al Razeen",
        s: "abu-dhabi-al-raha-beach-al-razeen"
    }, {
        n: "Canal View Building",
        s: "abu-dhabi-al-raha-beach-canal-view-building"
    }, {
        n: "Al Falah Street",
        s: "abu-dhabi-city-downtown-al-falah-street"
    }, {
        n: "Shams Abu Dhabi",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi"
    }, {
        n: "Marina Rise Tower",
        s: "abu-dhabi-al-reem-island-marina-rise-tower"
    }, {
        n: "Juman Tower",
        s: "abu-dhabi-al-reem-island-juman-tower"
    }, {
        n: "Zone 3",
        s: "abu-dhabi-hydra-village-zone-3"
    }, {
        n: "Zone 5",
        s: "abu-dhabi-hydra-village-zone-5"
    }, {
        n: "Complex 17",
        s: "abu-dhabi-khalifa-city-complex-17"
    }, {
        n: "18 Villas Complex",
        s: "abu-dhabi-khalifa-city-18-villas-complex"
    }, {
        n: "Complex 14",
        s: "abu-dhabi-khalifa-city-complex-14"
    }, {
        n: "Marabe Al Dhafra",
        s: "abu-dhabi-madinat-zayed-marabe-al-dhafra"
    }, {
        n: "SHB Building",
        s: "abu-dhabi-mohamed-bin-zayed-city-shb-building"
    }, {
        n: "Mohamed Bin Zayed City Villas",
        s: "abu-dhabi-mohamed-bin-zayed-city-mohamed-bin-zayed-city-villas"
    }, {
        n: "Mohammed Villas 6",
        s: "abu-dhabi-mohamed-bin-zayed-city-mohammed-villas-6"
    }, {
        n: "Shabia",
        s: "abu-dhabi-mussafah-shabia"
    }, {
        n: "Wintech International",
        s: "abu-dhabi-mussafah-wintech-international"
    }, {
        n: "Beachfront Residences",
        s: "abu-dhabi-nurai-island-beachfront-residences"
    }, {
        n: "Officers City",
        s: "abu-dhabi-abu-dhabi-gate-city-officers-city"
    }, {
        n: "Khalifa Residential Complex A",
        s: "abu-dhabi-tourist-club-area-khalifa-residential-complex-a"
    }, {
        n: "Al Zahiya",
        s: "abu-dhabi-al-raha-beach-al-zahiya"
    }, {
        n: "Mira Residences",
        s: "abu-dhabi-al-raha-beach-mira-residences"
    }, {
        n: "Khalifa Industrial Zone Abu Dhabi",
        s: "abu-dhabi-al-samha-khalifa-industrial-zone-abu-dhabi"
    }, {
        n: "Baniyas West",
        s: "abu-dhabi-baniyas-baniyas-west"
    }, {
        n: "Al Rawda Arjaan By Rotana",
        s: "abu-dhabi-al-wahda-al-rawda-arjaan-by-rotana"
    }, {
        n: "Onyx Tower",
        s: "abu-dhabi-al-reem-island-onyx-tower"
    }, {
        n: "Dynasty Tower",
        s: "abu-dhabi-al-reem-island-dynasty-tower"
    }, {
        n: "Yasmina Residence",
        s: "abu-dhabi-al-reem-island-yasmina-residence"
    }, {
        n: "Zone 1",
        s: "abu-dhabi-hydra-village-zone-1"
    }, {
        n: "Hydra Premier Tower",
        s: "abu-dhabi-hydra-village-hydra-premier-tower"
    }, {
        n: "Zone 9",
        s: "abu-dhabi-hydra-village-zone-9"
    }, {
        n: "Khalifa City C",
        s: "abu-dhabi-khalifa-city-khalifa-city-c"
    }, {
        n: "Villa Compound",
        s: "abu-dhabi-khalifa-city-villa-compound"
    }, {
        n: "Al Rayyana",
        s: "abu-dhabi-khalifa-city-al-rayyana"
    }, {
        n: "Hamel al Ghaith Waqf Building",
        s: "abu-dhabi-madinat-zayed-hamel-al-ghaith-waqf-building"
    }, {
        n: "Royal Marina Villas",
        s: "abu-dhabi-marina-village-royal-marina-villas"
    }, {
        n: "Sahara Complex",
        s: "abu-dhabi-mohamed-bin-zayed-city-sahara-complex"
    }, {
        n: "C120 Building",
        s: "abu-dhabi-mohamed-bin-zayed-city-c120-building"
    }, {
        n: "Mohamed Bin Zayed Centre",
        s: "abu-dhabi-mohamed-bin-zayed-city-mohamed-bin-zayed-centre"
    }, {
        n: "Mussafah Industrial Area",
        s: "abu-dhabi-mussafah-mussafah-industrial-area"
    }, {
        n: "Beachfront Residence",
        s: "abu-dhabi-nurai-island-beachfront-residence"
    }, {
        n: "Sorouh Compound",
        s: "abu-dhabi-sas-al-nakheel-sorouh-compound"
    }, {
        n: "Sheikh Omar Bin Zayed Tower",
        s: "abu-dhabi-tourist-club-area-sheikh-omar-bin-zayed-tower"
    }, {
        n: "Azzam One Residence",
        s: "abu-dhabi-al-raha-beach-azzam-one-residence"
    }, {
        n: "Al Wathba Tower",
        s: "abu-dhabi-al-wathba-al-wathba-tower"
    }, {
        n: "Defense Road",
        s: "abu-dhabi-city-downtown-defense-road"
    }, {
        n: "Al Wahda Street",
        s: "abu-dhabi-al-wahda-al-wahda-street"
    }, {
        n: "Hydra Platinum Tower",
        s: "abu-dhabi-al-reem-island-hydra-platinum-tower"
    }, {
        n: "Al Odaid Beach Residences",
        s: "abu-dhabi-al-reem-island-al-odaid-beach-residences"
    }, {
        n: "Al Noor Tower",
        s: "abu-dhabi-al-reem-island-al-noor-tower"
    }, {
        n: "Sowwah Square",
        s: "abu-dhabi-al-maryah-sowwah-square"
    }, {
        n: "Zone 7",
        s: "abu-dhabi-hydra-village-zone-7"
    }, {
        n: "Al Forsan Village",
        s: "abu-dhabi-khalifa-city-al-forsan-village"
    }, {
        n: "Khalifa City B",
        s: "abu-dhabi-khalifa-city-khalifa-city-b"
    }, {
        n: "Complex 3",
        s: "abu-dhabi-khalifa-city-complex-3"
    }, {
        n: "Desert Towers",
        s: "abu-dhabi-mohamed-bin-zayed-city-desert-towers"
    }, {
        n: "Mayzad Village",
        s: "abu-dhabi-mohamed-bin-zayed-city-mayzad-village"
    }, {
        n: "Saeed Lebaj Mariam Building",
        s: "abu-dhabi-mohamed-bin-zayed-city-saeed-lebaj-mariam-building"
    }, {
        n: "Al Maqtaa Commercial Complex",
        s: "abu-dhabi-mussafah-al-maqtaa-commercial-complex"
    }, {
        n: "Mussafah Gardens",
        s: "abu-dhabi-mussafah-mussafah-gardens"
    }, {
        n: "Nurai Resort",
        s: "abu-dhabi-nurai-island-nurai-resort"
    }, {
        n: "Seashore",
        s: "abu-dhabi-abu-dhabi-gate-city-seashore"
    }, {
        n: "Al Firdous Street",
        s: "abu-dhabi-tourist-club-area-al-firdous-street"
    }, {
        n: "Falahi Tower",
        s: "abu-dhabi-tourist-club-area-falahi-tower"
    }, {
        n: "Sahara Hotel Apartment 4",
        s: "abu-dhabi-tourist-club-area-sahara-hotel-apartment-4"
    }, {
        n: "Zig Zag Building",
        s: "abu-dhabi-tourist-club-area-zig-zag-building"
    }, {
        n: "Alqubaisi Building",
        s: "abu-dhabi-tourist-club-area-alqubaisi-building"
    }, {
        n: "Arzana Tower",
        s: "abu-dhabi-electra-street-arzana-tower"
    }, {
        n: "Baniyas East",
        s: "abu-dhabi-baniyas-baniyas-east"
    }, {
        n: "CBD (Central Business District)",
        s: "abu-dhabi-city-downtown-cbd-central-business-district"
    }, {
        n: "Building C59 ICT",
        s: "abu-dhabi-al-wahda-building-c59-ict"
    }, {
        n: "Tamouh",
        s: "abu-dhabi-al-reem-island-tamouh"
    }, {
        n: "First Gulf Bank Tower",
        s: "abu-dhabi-al-reem-island-first-gulf-bank-tower"
    }, {
        n: "Hydra Avenue Hotel Apartments",
        s: "abu-dhabi-al-reem-island-hydra-avenue-hotel-apartments"
    }, {
        n: "Hydra Heights",
        s: "abu-dhabi-al-reem-island-hydra-heights"
    }, {
        n: "Makers District",
        s: "abu-dhabi-al-reem-island-makers-district"
    }, {
        n: "Maryah Plaza",
        s: "abu-dhabi-al-maryah-maryah-plaza"
    }, {
        n: "Zone 8",
        s: "abu-dhabi-hydra-village-zone-8"
    }, {
        n: "Zone 6",
        s: "abu-dhabi-hydra-village-zone-6"
    }, {
        n: "Al Wahda City Towers",
        s: "abu-dhabi-al-wahda-al-wahda-city-towers"
    }, {
        n: "Marina Square",
        s: "abu-dhabi-al-reem-island-marina-square"
    }, {
        n: "Canal Residence",
        s: "abu-dhabi-al-reem-island-canal-residence"
    }, {
        n: "Falconcrest Tower",
        s: "abu-dhabi-al-reem-island-falconcrest-tower"
    }, {
        n: "Sky Garden Residence",
        s: "abu-dhabi-al-reem-island-sky-garden-residence"
    }, {
        n: "Addax Park Tower",
        s: "abu-dhabi-al-reem-island-addax-park-tower"
    }, {
        n: "Zone 2",
        s: "abu-dhabi-hydra-village-zone-2"
    }, {
        n: "Hydra Pavilion Tower",
        s: "abu-dhabi-hydra-village-hydra-pavilion-tower"
    }, {
        n: "Zone 4",
        s: "abu-dhabi-hydra-village-zone-4"
    }, {
        n: "Golf Gardens",
        s: "abu-dhabi-khalifa-city-golf-gardens"
    }, {
        n: "Complex 8",
        s: "abu-dhabi-khalifa-city-complex-8"
    }, {
        n: "Complex 16",
        s: "abu-dhabi-khalifa-city-complex-16"
    }, {
        n: "Liwa Oasis compound",
        s: "abu-dhabi-khalifa-city-liwa-oasis-compound"
    }, {
        n: "Bainouna",
        s: "abu-dhabi-madinat-zayed-bainouna"
    }, {
        n: "Mazyad Mall",
        s: "abu-dhabi-mohamed-bin-zayed-city-mazyad-mall"
    }, {
        n: "Mohammed Villas 24",
        s: "abu-dhabi-mohamed-bin-zayed-city-mohammed-villas-24"
    }, {
        n: "C250 Building",
        s: "abu-dhabi-mohamed-bin-zayed-city-c250-building"
    }, {
        n: "C233 Building",
        s: "abu-dhabi-mohamed-bin-zayed-city-c233-building"
    }, {
        n: "Abu Dhabi Business Hub",
        s: "abu-dhabi-mussafah-abu-dhabi-business-hub"
    }, {
        n: "ICAD - Industrial City Of Abu Dhabi",
        s: "abu-dhabi-mussafah-icad-industrial-city-of-abu-dhabi"
    }, {
        n: "Water Villas",
        s: "abu-dhabi-nurai-island-water-villas"
    }, {
        n: "Mangrove Village",
        s: "abu-dhabi-abu-dhabi-gate-city-mangrove-village"
    }, {
        n: "Abu Dhabi Golf Club",
        s: "abu-dhabi-sas-al-nakheel-abu-dhabi-golf-club"
    }, {
        n: "Abu Dhabi Trade Towers",
        s: "abu-dhabi-tourist-club-area-abu-dhabi-trade-towers"
    }, {
        n: "Al Ryami Tower",
        s: "abu-dhabi-tourist-club-area-al-ryami-tower"
    }, {
        n: "34 Villas Project",
        s: "abu-dhabi-khalifa-city-34-villas-project"
    }, {
        n: "Al Merief",
        s: "abu-dhabi-khalifa-city-al-merief"
    }, {
        n: "Muna Bulooki 4 Villas",
        s: "abu-dhabi-khalifa-city-muna-bulooki-4-villas"
    }, {
        n: "City Mall",
        s: "abu-dhabi-madinat-zayed-city-mall"
    }, {
        n: "Prestige Towers",
        s: "abu-dhabi-mohamed-bin-zayed-city-prestige-towers"
    }, {
        n: "C205 Building",
        s: "abu-dhabi-mohamed-bin-zayed-city-c205-building"
    }, {
        n: "Prestige Tower",
        s: "abu-dhabi-mussafah-prestige-tower"
    }, {
        n: "Sunset Villas",
        s: "abu-dhabi-nurai-island-sunset-villas"
    }, {
        n: "Shiekh Suroor Building",
        s: "abu-dhabi-tourist-club-area-shiekh-suroor-building"
    }, {
        n: "Noura Al Futtaim Building",
        s: "abu-dhabi-tourist-club-area-noura-al-futtaim-building"
    }, {
        n: "SHKA 705 Building",
        s: "abu-dhabi-tourist-club-area-shka-705-building"
    }, {
        n: "Vision Hotel Apartments",
        s: "abu-dhabi-tourist-club-area-vision-hotel-apartments"
    }, {
        n: "Al Salam Tower",
        s: "abu-dhabi-tourist-club-area-al-salam-tower"
    }, {
        n: "Al Darmaki 1928 Building",
        s: "abu-dhabi-zayed-sports-city-al-darmaki-1928-building"
    }, {
        n: "Saadiyat Promenade",
        s: "abu-dhabi-saadiyat-island-saadiyat-promenade"
    }, {
        n: "Saadiyat Resort",
        s: "abu-dhabi-saadiyat-island-saadiyat-resort"
    }, {
        n: "Saadiyat Beach",
        s: "abu-dhabi-saadiyat-island-saadiyat-beach"
    }, {
        n: "Waters Edge",
        s: "abu-dhabi-yas-island-waters-edge"
    }, {
        n: "Yas Acres",
        s: "abu-dhabi-yas-island-yas-acres"
    }, {
        n: "Narjis",
        s: "abu-dhabi-al-raha-golf-gardens-narjis"
    }, {
        n: "Rihan Heights",
        s: "abu-dhabi-grand-mosque-district-rihan-heights"
    }, {
        n: "Al Waha",
        s: "abu-dhabi-al-ghadeer-al-waha"
    }, {
        n: "Marriott Executive Apartments Downtown Abu Dhabi",
        s: "abu-dhabi-al-tibbiya-marriott-executive-apartments-downtown-abu-dhabi"
    }, {
        n: "Al Rayhan Villas",
        s: "abu-dhabi-al-rayhan-al-rayhan-villas"
    }, {
        n: "Lulu Towers",
        s: "abu-dhabi-khalifa-street-lulu-towers"
    }, {
        n: "The Blue Tower",
        s: "abu-dhabi-khalifa-street-the-blue-tower"
    }, {
        n: "Zayed Military City Towers",
        s: "abu-dhabi-zayed-military-city-zayed-military-city-towers"
    }, {
        n: "Al Badie Tower",
        s: "abu-dhabi-capital-centre-al-badie-tower"
    }, {
        n: "Al Saadiyat Avenue",
        s: "abu-dhabi-saadiyat-island-al-saadiyat-avenue"
    }, {
        n: "Soho Square",
        s: "abu-dhabi-saadiyat-island-soho-square"
    }, {
        n: "staybridge suites",
        s: "abu-dhabi-yas-island-staybridge-suites"
    }, {
        n: "Yas Bay",
        s: "abu-dhabi-yas-island-yas-bay"
    }, {
        n: "Al Khaleej Village",
        s: "abu-dhabi-al-ghadeer-al-khaleej-village"
    }, {
        n: "Al Buhayra Village",
        s: "abu-dhabi-al-ghadeer-al-buhayra-village"
    }, {
        n: "South Hudayriat",
        s: "abu-dhabi-al-hudayriat-island-south-hudayriat"
    }, {
        n: "Manaseer Building 90",
        s: "abu-dhabi-al-manaseer-manaseer-building-90"
    }, {
        n: "Tawam Tower",
        s: "abu-dhabi-khalifa-street-tawam-tower"
    }, {
        n: "Emerald Tower",
        s: "abu-dhabi-khalifa-street-emerald-tower"
    }, {
        n: "East Corniche road",
        s: "abu-dhabi-eastern-road-east-corniche-road"
    }, {
        n: "Universal Hospital Building",
        s: "abu-dhabi-airport-road-universal-hospital-building"
    }, {
        n: "Saeed Mubarak Al Mansoori Building",
        s: "abu-dhabi-airport-road-saeed-mubarak-al-mansoori-building"
    }, {
        n: "Soud Salem Al Hajri",
        s: "abu-dhabi-airport-road-soud-salem-al-hajri"
    }, {
        n: "Al Hashimi Tower",
        s: "abu-dhabi-airport-road-al-hashimi-tower"
    }, {
        n: "Al Musalla Area",
        s: "abu-dhabi-al-karama-al-musalla-area"
    }, {
        n: "Business Center Tower",
        s: "abu-dhabi-hamdan-street-business-center-tower"
    }, {
        n: "Emirates Tower",
        s: "abu-dhabi-hamdan-street-emirates-tower"
    }, {
        n: "Al Darmaki Building 1162",
        s: "abu-dhabi-hamdan-street-al-darmaki-building-1162"
    }, {
        n: "Al Jazeera Towers",
        s: "abu-dhabi-hamdan-street-al-jazeera-towers"
    }, {
        n: "Al Jazira Arena",
        s: "abu-dhabi-muroor-area-al-jazira-arena"
    }, {
        n: "Habib Bank Tower",
        s: "abu-dhabi-muroor-area-habib-bank-tower"
    }, {
        n: "Faisal Al Qassimi Building",
        s: "abu-dhabi-muroor-area-faisal-al-qassimi-building"
    }, {
        n: "AD One Tower",
        s: "abu-dhabi-capital-centre-ad-one-tower"
    }, {
        n: "Saadiyat Noon",
        s: "abu-dhabi-saadiyat-island-saadiyat-noon"
    }, {
        n: "Saadiyat Reserve",
        s: "abu-dhabi-saadiyat-island-saadiyat-reserve"
    }, {
        n: "Ansam",
        s: "abu-dhabi-yas-island-ansam"
    }, {
        n: "Khuzama",
        s: "abu-dhabi-al-raha-golf-gardens-khuzama"
    }, {
        n: "Desert Village",
        s: "abu-dhabi-grand-mosque-district-desert-village"
    }, {
        n: "Al Baraha Village",
        s: "abu-dhabi-al-ghadeer-al-baraha-village"
    }, {
        n: "Al Khubaira Village",
        s: "abu-dhabi-al-ghadeer-al-khubaira-village"
    }, {
        n: "North Hudayriat",
        s: "abu-dhabi-al-hudayriat-island-north-hudayriat"
    }, {
        n: "Al Neem Tower",
        s: "abu-dhabi-khalifa-street-al-neem-tower"
    }, {
        n: "Al Noor Tower",
        s: "abu-dhabi-khalifa-street-al-noor-tower"
    }, {
        n: "Lafzaeyya Tower",
        s: "abu-dhabi-khalifa-street-lafzaeyya-tower"
    }, {
        n: "Al Muzoon District",
        s: "abu-dhabi-eastern-road-al-muzoon-district"
    }, {
        n: "Jannah Place Abu Dhabi",
        s: "abu-dhabi-airport-road-jannah-place-abu-dhabi"
    }, {
        n: "Saadiyat Lagoons",
        s: "abu-dhabi-saadiyat-island-saadiyat-lagoons"
    }, {
        n: "Park View",
        s: "abu-dhabi-saadiyat-island-park-view"
    }, {
        n: "West Yas",
        s: "abu-dhabi-yas-island-west-yas"
    }, {
        n: "Lailak",
        s: "abu-dhabi-al-raha-golf-gardens-lailak"
    }, {
        n: "Orchid",
        s: "abu-dhabi-al-raha-golf-gardens-orchid"
    }, {
        n: "Al Sabeel Building",
        s: "abu-dhabi-al-ghadeer-al-sabeel-building"
    }, {
        n: "Bloom Central",
        s: "abu-dhabi-al-tibbiya-bloom-central"
    }, {
        n: "Manaseer Building 53",
        s: "abu-dhabi-al-manaseer-manaseer-building-53"
    }, {
        n: "UBL Tower",
        s: "abu-dhabi-khalifa-street-ubl-tower"
    }, {
        n: "Pearl Time Residence",
        s: "abu-dhabi-khalifa-street-pearl-time-residence"
    }, {
        n: "Zahra Ahmed Sayed Al Khamzi Building",
        s: "abu-dhabi-airport-road-zahra-ahmed-sayed-al-khamzi-building"
    }, {
        n: "Al Taghreed Tower",
        s: "abu-dhabi-airport-road-al-taghreed-tower"
    }, {
        n: "Mashoui Al Qubaisi",
        s: "abu-dhabi-airport-road-mashoui-al-qubaisi"
    }, {
        n: "Hassan Ibrahim Al Fardan",
        s: "abu-dhabi-airport-road-hassan-ibrahim-al-fardan"
    }, {
        n: "Al Karam Building",
        s: "abu-dhabi-al-karama-al-karam-building"
    }, {
        n: "Sheikh Nahyan Bin Zayed Tower",
        s: "abu-dhabi-airport-road-sheikh-nahyan-bin-zayed-tower"
    }, {
        n: "Crescent Tower",
        s: "abu-dhabi-hamdan-street-crescent-tower"
    }, {
        n: "Dalma Residence",
        s: "abu-dhabi-hamdan-street-dalma-residence"
    }, {
        n: "Golden Falcon Tower",
        s: "abu-dhabi-hamdan-street-golden-falcon-tower"
    }, {
        n: "Liwa Centre Towers",
        s: "abu-dhabi-hamdan-street-liwa-centre-towers"
    }, {
        n: "Ben Karm Tower",
        s: "abu-dhabi-hamdan-street-ben-karm-tower"
    }, {
        n: "Al Masood Tower",
        s: "abu-dhabi-hamdan-street-al-masood-tower"
    }, {
        n: "East Corniche road",
        s: "abu-dhabi-hamdan-street-east-corniche-road"
    }, {
        n: "Twin Tower",
        s: "abu-dhabi-muroor-area-twin-tower"
    }, {
        n: "Marina Al Saadiyat",
        s: "abu-dhabi-saadiyat-island-marina-al-saadiyat"
    }, {
        n: "Mayan",
        s: "abu-dhabi-yas-island-mayan"
    }, {
        n: "Gardenia",
        s: "abu-dhabi-al-raha-golf-gardens-gardenia"
    }, {
        n: "Jouri",
        s: "abu-dhabi-al-raha-golf-gardens-jouri"
    }, {
        n: "Liwa Village",
        s: "abu-dhabi-al-ghadeer-liwa-village"
    }, {
        n: "Falaj Village",
        s: "abu-dhabi-al-ghadeer-falaj-village"
    }, {
        n: "Al Baya",
        s: "abu-dhabi-al-sila-a-al-baya"
    }, {
        n: "Khalifa Bin Shakhbout Street",
        s: "abu-dhabi-al-manaseer-khalifa-bin-shakhbout-street"
    }, {
        n: "Galaxy tower",
        s: "abu-dhabi-khalifa-street-galaxy-tower"
    }, {
        n: "Al Khaili Tower",
        s: "abu-dhabi-khalifa-street-al-khaili-tower"
    }, {
        n: "Al Reehan",
        s: "abu-dhabi-eastern-road-al-reehan"
    }, {
        n: "Al Odaid Office Tower",
        s: "abu-dhabi-airport-road-al-odaid-office-tower"
    }, {
        n: "Al Dhabi Building",
        s: "abu-dhabi-airport-road-al-dhabi-building"
    }, {
        n: "Rawda Building",
        s: "abu-dhabi-airport-road-rawda-building"
    }, {
        n: "Marks and Spencer Building",
        s: "abu-dhabi-airport-road-marks-and-spencer-building"
    }, {
        n: "Abu Dhabi Airport Logistics Park",
        s: "abu-dhabi-airport-road-abu-dhabi-airport-logistics-park"
    }, {
        n: "2nd Street",
        s: "abu-dhabi-airport-road-2nd-street"
    }, {
        n: "Al Yasat Compound",
        s: "abu-dhabi-al-karama-al-yasat-compound"
    }, {
        n: "Al Ghaith Tower",
        s: "abu-dhabi-hamdan-street-al-ghaith-tower"
    }, {
        n: "Al Shafi Tower",
        s: "abu-dhabi-hamdan-street-al-shafi-tower"
    }, {
        n: "Vision Downtown",
        s: "abu-dhabi-hamdan-street-vision-downtown"
    }, {
        n: "Vision Links Hotel Apartment",
        s: "abu-dhabi-muroor-area-vision-links-hotel-apartment"
    }, {
        n: "Al Falah Tower",
        s: "abu-dhabi-muroor-area-al-falah-tower"
    }, {
        n: "Fuala Building",
        s: "abu-dhabi-muroor-area-fuala-building"
    }, {
        n: "Al Saada Tower",
        s: "abu-dhabi-muroor-area-al-saada-tower"
    }, {
        n: "Barclays Tower A",
        s: "abu-dhabi-muroor-area-barclays-tower-a"
    }, {
        n: "C57",
        s: "abu-dhabi-muroor-area-c57"
    }, {
        n: "Naser Al Junaibi Building",
        s: "abu-dhabi-muroor-area-naser-al-junaibi-building"
    }, {
        n: "Balfar Property Building",
        s: "abu-dhabi-muroor-area-balfar-property-building"
    }, {
        n: "C47",
        s: "abu-dhabi-muroor-area-c47"
    }, {
        n: "RDK Residential Complex",
        s: "abu-dhabi-rawdhat-abu-dhabi-rdk-residential-complex"
    }, {
        n: "Eastern Mangroves Promenade",
        s: "abu-dhabi-eastern-road-eastern-mangroves-promenade"
    }, {
        n: "Owaida Tower",
        s: "abu-dhabi-airport-road-owaida-tower"
    }, {
        n: "Diplomatic Area",
        s: "abu-dhabi-airport-road-diplomatic-area"
    }, {
        n: "Rawdhat",
        s: "abu-dhabi-airport-road-rawdhat"
    }, {
        n: "Al Fardan Building",
        s: "abu-dhabi-airport-road-al-fardan-building"
    }, {
        n: "Al Manhal Tower",
        s: "abu-dhabi-airport-road-al-manhal-tower"
    }, {
        n: "Al Maharba",
        s: "abu-dhabi-al-karama-al-maharba"
    }, {
        n: "Crystal Tower",
        s: "abu-dhabi-hamdan-street-crystal-tower"
    }, {
        n: "Royal Tower",
        s: "abu-dhabi-hamdan-street-royal-tower"
    }, {
        n: "Al Saman Tower",
        s: "abu-dhabi-hamdan-street-al-saman-tower"
    }, {
        n: "Guardian Tower",
        s: "abu-dhabi-muroor-area-guardian-tower"
    }, {
        n: "Al Muroor Tower",
        s: "abu-dhabi-muroor-area-al-muroor-tower"
    }, {
        n: "Building C59 ICT",
        s: "abu-dhabi-muroor-area-building-c59-ict"
    }, {
        n: "Mafco Building",
        s: "abu-dhabi-muroor-area-mafco-building"
    }, {
        n: "Al Nedal Tower",
        s: "abu-dhabi-muroor-area-al-nedal-tower"
    }, {
        n: "AD1 Building",
        s: "abu-dhabi-muroor-area-ad1-building"
    }, {
        n: "Al Minhali Tower",
        s: "abu-dhabi-muroor-area-al-minhali-tower"
    }, {
        n: "Amena 15 Building",
        s: "abu-dhabi-muroor-area-amena-15-building"
    }, {
        n: "Al Jazeera Sports and Cultural Club",
        s: "abu-dhabi-muroor-area-al-jazeera-sports-and-cultural-club"
    }, {
        n: "Danat Towers",
        s: "abu-dhabi-muroor-area-danat-towers"
    }, {
        n: "Emirates Compound",
        s: "abu-dhabi-muroor-area-emirates-compound"
    }, {
        n: "Dusit Thani",
        s: "abu-dhabi-muroor-area-dusit-thani"
    }, {
        n: "Khamis Butti Ghanem Saeed Al Rumaithy Building",
        s: "abu-dhabi-muroor-area-khamis-butti-ghanem-saeed-al-rumaithy-building"
    }, {
        n: "Al Maqtaa village",
        s: "abu-dhabi-al-maqtaa-al-maqtaa-village"
    }, {
        n: "Liwa Tower",
        s: "abu-dhabi-jawazat-street-liwa-tower"
    }, {
        n: "Al Reem Tower",
        s: "abu-dhabi-corniche-road-al-reem-tower"
    }, {
        n: "Baynuna Tower 1",
        s: "abu-dhabi-corniche-road-baynuna-tower-1"
    }, {
        n: "Oryx Tower",
        s: "abu-dhabi-corniche-road-oryx-tower"
    }, {
        n: "Sheikh Ahmed Bin Mubarak Building",
        s: "abu-dhabi-corniche-road-sheikh-ahmed-bin-mubarak-building"
    }, {
        n: "Wave tower",
        s: "abu-dhabi-corniche-road-wave-tower"
    }, {
        n: "Bay Tower",
        s: "abu-dhabi-corniche-road-bay-tower"
    }, {
        n: "Huda Alansary Building",
        s: "abu-dhabi-al-salam-street-huda-alansary-building"
    }, {
        n: "Al Baheya Building",
        s: "abu-dhabi-al-salam-street-al-baheya-building"
    }, {
        n: "Al Gazelle Residence",
        s: "abu-dhabi-al-salam-street-al-gazelle-residence"
    }, {
        n: "Burj Al Jewn",
        s: "abu-dhabi-danet-abu-dhabi-burj-al-jewn"
    }, {
        n: "Al Murjan Tower",
        s: "abu-dhabi-danet-abu-dhabi-al-murjan-tower"
    }, {
        n: "Dar Al Salam Building",
        s: "abu-dhabi-al-zahiyah-dar-al-salam-building"
    }, {
        n: "SH- 20",
        s: "abu-dhabi-al-shamkha-sh-20"
    }, {
        n: "Alreeman",
        s: "abu-dhabi-al-shamkha-alreeman"
    }, {
        n: "Jumeira Tower",
        s: "abu-dhabi-al-najda-street-jumeira-tower"
    }, {
        n: "Burj Al Nakheel",
        s: "abu-dhabi-al-najda-street-burj-al-nakheel"
    }, {
        n: "Zaab 2",
        s: "abu-dhabi-al-zaab-zaab-2"
    }, {
        n: "Abu Dhabi Unversity Tower",
        s: "abu-dhabi-muroor-area-abu-dhabi-unversity-tower"
    }, {
        n: "Fawwas Building",
        s: "abu-dhabi-muroor-area-fawwas-building"
    }, {
        n: "Sheikh Rashid Bin Saeed Street",
        s: "abu-dhabi-rawdhat-abu-dhabi-sheikh-rashid-bin-saeed-street"
    }, {
        n: "Khaleefa Bin Al Jesrain Villas",
        s: "abu-dhabi-al-maqtaa-khaleefa-bin-al-jesrain-villas"
    }, {
        n: "Al Mazroui Tower",
        s: "abu-dhabi-jawazat-street-al-mazroui-tower"
    }, {
        n: "Binal Jesrain",
        s: "abu-dhabi-between-two-bridges-binal-jesrain"
    }, {
        n: "BMC 7",
        s: "abu-dhabi-building-materials-city-bmc-7"
    }, {
        n: "Grand Hyatt Abu Dhabi Hotel & Residences Emirates Pearl",
        s: "abu-dhabi-corniche-road-grand-hyatt-abu-dhabi-hotel-residences-emirates-pearl"
    }, {
        n: "Royal Marina Villas",
        s: "abu-dhabi-corniche-road-royal-marina-villas"
    }, {
        n: "Golden Beach Tower",
        s: "abu-dhabi-corniche-road-golden-beach-tower"
    }, {
        n: "Clock Tower",
        s: "abu-dhabi-corniche-road-clock-tower"
    }, {
        n: "Al Jowhara Tower",
        s: "abu-dhabi-corniche-road-al-jowhara-tower"
    }, {
        n: "Al Nahda Tower",
        s: "abu-dhabi-corniche-road-al-nahda-tower"
    }, {
        n: "Al Souq Tower",
        s: "abu-dhabi-corniche-road-al-souq-tower"
    }, {
        n: "AL Diar Towers",
        s: "abu-dhabi-corniche-road-al-diar-towers"
    }, {
        n: "Hameem Residential City",
        s: "abu-dhabi-hameem-hameem-residential-city"
    }, {
        n: "Salam HQ",
        s: "abu-dhabi-al-salam-street-salam-hq"
    }, {
        n: "Andalus Al Seef Resort and Spa",
        s: "abu-dhabi-al-salam-street-andalus-al-seef-resort-and-spa"
    }, {
        n: "SH- 2",
        s: "abu-dhabi-al-shamkha-sh-2"
    }, {
        n: "SH- 3",
        s: "abu-dhabi-al-shamkha-sh-3"
    }, {
        n: "SH- 1",
        s: "abu-dhabi-al-shamkha-sh-1"
    }, {
        n: "Urban Oasis Compound",
        s: "abu-dhabi-between-two-bridges-urban-oasis-compound"
    }, {
        n: "Commercial District",
        s: "abu-dhabi-building-materials-city-commercial-district"
    }, {
        n: "Nation Towers",
        s: "abu-dhabi-corniche-road-nation-towers"
    }, {
        n: "Al Sahel Towers",
        s: "abu-dhabi-corniche-road-al-sahel-towers"
    }, {
        n: "Landmark Tower",
        s: "abu-dhabi-corniche-road-landmark-tower"
    }, {
        n: "Silver Tower",
        s: "abu-dhabi-corniche-road-silver-tower"
    }, {
        n: "Bloom Gardens",
        s: "abu-dhabi-al-salam-street-bloom-gardens"
    }, {
        n: "Corniche Plaza Building",
        s: "abu-dhabi-al-salam-street-corniche-plaza-building"
    }, {
        n: "Blumont Capital Hotel",
        s: "abu-dhabi-danet-abu-dhabi-blumont-capital-hotel"
    }, {
        n: "The View",
        s: "abu-dhabi-danet-abu-dhabi-the-view"
    }, {
        n: "Al Marsa Villas",
        s: "abu-dhabi-al-fahid-al-marsa-villas"
    }, {
        n: "Al Shaya Tower",
        s: "abu-dhabi-al-zahiyah-al-shaya-tower"
    }, {
        n: "SH- 16",
        s: "abu-dhabi-al-shamkha-sh-16"
    }, {
        n: "SH- 28",
        s: "abu-dhabi-al-shamkha-sh-28"
    }, {
        n: "SH- 26",
        s: "abu-dhabi-al-shamkha-sh-26"
    }, {
        n: "Zakher MAAM Residence",
        s: "abu-dhabi-al-najda-street-zakher-maam-residence"
    }, {
        n: "Al Khazna Tower",
        s: "abu-dhabi-al-najda-street-al-khazna-tower"
    }, {
        n: "Bin Arar Building",
        s: "abu-dhabi-al-najda-street-bin-arar-building"
    }, {
        n: "Business Towers",
        s: "abu-dhabi-electra-street-business-towers"
    }, {
        n: "Al Reem Plaza",
        s: "abu-dhabi-electra-street-al-reem-plaza"
    }, {
        n: "New Shahama",
        s: "abu-dhabi-al-shahama-new-shahama"
    }, {
        n: "Rafiqa Al Qubaisi Building",
        s: "abu-dhabi-defense-road-rafiqa-al-qubaisi-building"
    }, {
        n: "BMC 16",
        s: "abu-dhabi-building-materials-city-bmc-16"
    }, {
        n: "3 Sails Tower",
        s: "abu-dhabi-corniche-road-3-sails-tower"
    }, {
        n: "Corniche Residence",
        s: "abu-dhabi-corniche-road-corniche-residence"
    }, {
        n: "Dar Al Salam Building",
        s: "abu-dhabi-corniche-road-dar-al-salam-building"
    }, {
        n: "Al Falah Tower",
        s: "abu-dhabi-corniche-road-al-falah-tower"
    }, {
        n: "Al Ain Palace Villas",
        s: "abu-dhabi-corniche-road-al-ain-palace-villas"
    }, {
        n: "Al Reef Tower",
        s: "abu-dhabi-corniche-road-al-reef-tower"
    }, {
        n: "Bel Ghailam Tower",
        s: "abu-dhabi-corniche-road-bel-ghailam-tower"
    }, {
        n: "Burj Mohammed Bin Rashid at WTC",
        s: "abu-dhabi-corniche-road-burj-mohammed-bin-rashid-at-wtc"
    }, {
        n: "Lake View Tower",
        s: "abu-dhabi-corniche-road-lake-view-tower"
    }, {
        n: "Nowailey Building",
        s: "abu-dhabi-al-salam-street-nowailey-building"
    }, {
        n: "Mansour Tower",
        s: "abu-dhabi-al-salam-street-mansour-tower"
    }, {
        n: "Al Ferdous Tower",
        s: "abu-dhabi-al-salam-street-al-ferdous-tower"
    }, {
        n: "Darwish Tower",
        s: "abu-dhabi-danet-abu-dhabi-darwish-tower"
    }, {
        n: "Burj Al Yaqout",
        s: "abu-dhabi-danet-abu-dhabi-burj-al-yaqout"
    }, {
        n: "Al Dana Tower",
        s: "abu-dhabi-danet-abu-dhabi-al-dana-tower"
    }, {
        n: "Al Gurm Resort",
        s: "abu-dhabi-al-gurm-al-gurm-resort"
    }, {
        n: "Nadi Siyahi Building",
        s: "abu-dhabi-al-zahiyah-nadi-siyahi-building"
    }, {
        n: "SH- 24",
        s: "abu-dhabi-al-shamkha-sh-24"
    }, {
        n: "Morjan Tower",
        s: "abu-dhabi-jawazat-street-morjan-tower"
    }, {
        n: "Fairmont Villas",
        s: "abu-dhabi-between-two-bridges-fairmont-villas"
    }, {
        n: "Residential District",
        s: "abu-dhabi-building-materials-city-residential-district"
    }, {
        n: "Etihad Towers",
        s: "abu-dhabi-corniche-road-etihad-towers"
    }, {
        n: "Alia Tower",
        s: "abu-dhabi-corniche-road-alia-tower"
    }, {
        n: "Saraya",
        s: "abu-dhabi-corniche-road-saraya"
    }, {
        n: "Baynuna Tower 2",
        s: "abu-dhabi-corniche-road-baynuna-tower-2"
    }, {
        n: "Corniche Tower",
        s: "abu-dhabi-corniche-road-corniche-tower"
    }, {
        n: "Corniche Plaza",
        s: "abu-dhabi-corniche-road-corniche-plaza"
    }, {
        n: "Meera MAAM Residence",
        s: "abu-dhabi-corniche-road-meera-maam-residence"
    }, {
        n: "Al Jazeera Tower",
        s: "abu-dhabi-corniche-road-al-jazeera-tower"
    }, {
        n: "SS Building",
        s: "abu-dhabi-al-salam-street-ss-building"
    }, {
        n: "Al Qurm Building",
        s: "abu-dhabi-al-salam-street-al-qurm-building"
    }, {
        n: "Sorouh Tower",
        s: "abu-dhabi-danet-abu-dhabi-sorouh-tower"
    }, {
        n: "Al Nasr Tower",
        s: "abu-dhabi-danet-abu-dhabi-al-nasr-tower"
    }, {
        n: "Guardian Towers",
        s: "abu-dhabi-danet-abu-dhabi-guardian-towers"
    }, {
        n: "Al Reef Downtown",
        s: "abu-dhabi-al-reef-al-reef-downtown"
    }, {
        n: "SH- 6",
        s: "abu-dhabi-al-shamkha-sh-6"
    }, {
        n: "SH- 21",
        s: "abu-dhabi-al-shamkha-sh-21"
    }, {
        n: "SH- 19",
        s: "abu-dhabi-al-shamkha-sh-19"
    }, {
        n: "Al Falahi Tower",
        s: "abu-dhabi-danet-abu-dhabi-al-falahi-tower"
    }, {
        n: "Al Rayan Tower",
        s: "abu-dhabi-danet-abu-dhabi-al-rayan-tower"
    }, {
        n: "Al Marsa Residences",
        s: "abu-dhabi-al-fahid-al-marsa-residences"
    }, {
        n: "Al Reef Villas",
        s: "abu-dhabi-al-reef-al-reef-villas"
    }, {
        n: "SH- 12",
        s: "abu-dhabi-al-shamkha-sh-12"
    }, {
        n: "SH- 23",
        s: "abu-dhabi-al-shamkha-sh-23"
    }, {
        n: "SH- 17",
        s: "abu-dhabi-al-shamkha-sh-17"
    }, {
        n: "Dhafir Tower",
        s: "abu-dhabi-al-najda-street-dhafir-tower"
    }, {
        n: "Al Yasat Tower",
        s: "abu-dhabi-al-najda-street-al-yasat-tower"
    }, {
        n: "Abu Dhabi Plaza Tower",
        s: "abu-dhabi-al-najda-street-abu-dhabi-plaza-tower"
    }, {
        n: "Saadiyat Tower",
        s: "abu-dhabi-electra-street-saadiyat-tower"
    }, {
        n: "Electra Tower",
        s: "abu-dhabi-electra-street-electra-tower"
    }, {
        n: "Fairmont Marina Residences",
        s: "abu-dhabi-the-marina-fairmont-marina-residences"
    }, {
        n: "Leonardo Residences",
        s: "abu-dhabi-masdar-city-leonardo-residences"
    }, {
        n: "Falcon Towers",
        s: "ajman-ajman-downtown-falcon-towers"
    }, {
        n: "Times Square",
        s: "ajman-ajman-downtown-times-square"
    }, {
        n: "Al Otaiba Tower",
        s: "abu-dhabi-electra-street-al-otaiba-tower"
    }, {
        n: "Pearl Tower",
        s: "abu-dhabi-electra-street-pearl-tower"
    }, {
        n: "Sama Tower",
        s: "abu-dhabi-electra-street-sama-tower"
    }, {
        n: "Hussain Al Muallah Tower",
        s: "abu-dhabi-al-nahyan-hussain-al-muallah-tower"
    }, {
        n: "Al Rashidiya",
        s: "ajman-ajman-downtown-al-rashidiya"
    }, {
        n: "Al Khor Towers",
        s: "ajman-ajman-downtown-al-khor-towers"
    }, {
        n: "Ajman Pearl Towers",
        s: "ajman-ajman-downtown-ajman-pearl-towers"
    }, {
        n: "Al Mwaihat 3",
        s: "ajman-al-mwaihat-al-mwaihat-3"
    }, {
        n: "Lake Signature",
        s: "ajman-emirates-city-lake-signature"
    }, {
        n: "Chapal the Glory",
        s: "ajman-emirates-city-chapal-the-glory"
    }, {
        n: "Paradise Lakes Towers",
        s: "ajman-emirates-city-paradise-lakes-towers"
    }, {
        n: "Altitude Tower",
        s: "ajman-emirates-city-altitude-tower"
    }, {
        n: "Fifth avenue Ajman",
        s: "ajman-emirates-city-fifth-avenue-ajman"
    }, {
        n: "Al Shalal Tower",
        s: "ajman-emirates-city-al-shalal-tower"
    }, {
        n: "Goldcrest Dreams",
        s: "ajman-emirates-city-goldcrest-dreams"
    }, {
        n: "Sahara Tower",
        s: "ajman-emirates-city-sahara-tower"
    }, {
        n: "Orbit Tower",
        s: "ajman-emirates-city-orbit-tower"
    }, {
        n: "Al Rashidiya 1",
        s: "ajman-al-rashidiya-al-rashidiya-1"
    }, {
        n: "Al Shaali Tower",
        s: "ajman-al-rumaila-al-shaali-tower"
    }, {
        n: "Al Rawda 2",
        s: "ajman-al-rawda-al-rawda-2"
    }, {
        n: "Samaa Tower",
        s: "ajman-marmooka-city-samaa-tower"
    }, {
        n: "DIO Twin Tower",
        s: "ajman-marmooka-city-dio-twin-tower"
    }, {
        n: "Tulip Residence",
        s: "ajman-marmooka-city-tulip-residence"
    }, {
        n: "Tessco Building",
        s: "abu-dhabi-electra-street-tessco-building"
    }, {
        n: "Marina Sunset Bay",
        s: "abu-dhabi-the-marina-marina-sunset-bay"
    }, {
        n: "Siemens Building",
        s: "abu-dhabi-masdar-city-siemens-building"
    }, {
        n: "Ajman One",
        s: "ajman-ajman-downtown-ajman-one"
    }, {
        n: "Horizon Towers",
        s: "ajman-ajman-downtown-horizon-towers"
    }, {
        n: "Smart Tower 1",
        s: "ajman-al-amerah-smart-tower-1"
    }, {
        n: "Al Nakheel",
        s: "ajman-ajman-downtown-al-nakheel"
    }, {
        n: "Al Mwaihat 2",
        s: "ajman-al-mwaihat-al-mwaihat-2"
    }, {
        n: "Kahraman Tower",
        s: "ajman-emirates-city-kahraman-tower"
    }, {
        n: "Al Hambra Towers",
        s: "ajman-emirates-city-al-hambra-towers"
    }, {
        n: "Lavender Tower",
        s: "ajman-emirates-city-lavender-tower"
    }, {
        n: "Emirates Lake Towers",
        s: "ajman-emirates-city-emirates-lake-towers"
    }, {
        n: "Shami Twin Towers",
        s: "ajman-emirates-city-shami-twin-towers"
    }, {
        n: "Green Lake Towers",
        s: "ajman-emirates-city-green-lake-towers"
    }, {
        n: "Paradise Lakes",
        s: "ajman-emirates-city-paradise-lakes"
    }, {
        n: "Golf Tower",
        s: "ajman-emirates-city-golf-tower"
    }, {
        n: "Al Rashidiya 3",
        s: "ajman-al-rashidiya-al-rashidiya-3"
    }, {
        n: "Ajman Industrial 1",
        s: "ajman-ajman-industrial-area-ajman-industrial-1"
    }, {
        n: "Al Rawda 1",
        s: "ajman-al-rawda-al-rawda-1"
    }, {
        n: "Monarch Tower",
        s: "ajman-marmooka-city-monarch-tower"
    }, {
        n: "Downtown Avenue",
        s: "ajman-marmooka-city-downtown-avenue"
    }, {
        n: "Konig Tower",
        s: "ajman-marmooka-city-konig-tower"
    }, {
        n: "Al Hleio",
        s: "ajman-ajman-uptown-al-hleio"
    }, {
        n: "Royal Lake View",
        s: "ajman-al-humaid-city-royal-lake-view"
    }, {
        n: "Infinity Tower",
        s: "ajman-al-humaid-city-infinity-tower"
    }, {
        n: "Savannah Heights",
        s: "ajman-al-humaid-city-savannah-heights"
    }, {
        n: "Jasmine Towers",
        s: "ajman-garden-city-jasmine-towers"
    }, {
        n: "Atrium 360",
        s: "ajman-ajman-downtown-atrium-360"
    }, {
        n: "Al Ghoroub Tower",
        s: "ajman-al-alia-al-ghoroub-tower"
    }, {
        n: "Tawakal Twin Towers",
        s: "ajman-emirates-city-tawakal-twin-towers"
    }, {
        n: "Crystal Residency",
        s: "ajman-emirates-city-crystal-residency"
    }, {
        n: "Lilies Tower",
        s: "ajman-emirates-city-lilies-tower"
    }, {
        n: "Emirates Pearls",
        s: "ajman-emirates-city-emirates-pearls"
    }, {
        n: "Chapal The Harmony",
        s: "ajman-emirates-city-chapal-the-harmony"
    }, {
        n: "Pearl Tower",
        s: "ajman-emirates-city-pearl-tower"
    }, {
        n: "Chocolate Tower",
        s: "ajman-emirates-city-chocolate-tower"
    }, {
        n: "Chapal The Presidency",
        s: "ajman-emirates-city-chapal-the-presidency"
    }, {
        n: "Music Tower",
        s: "ajman-emirates-city-music-tower"
    }, {
        n: "Cornish Tower",
        s: "ajman-al-rumaila-cornish-tower"
    }, {
        n: "Orient Towers",
        s: "ajman-al-bustan-orient-towers"
    }, {
        n: "Al Raqaib 2",
        s: "ajman-al-raqaib-al-raqaib-2"
    }, {
        n: "Global City Tower",
        s: "ajman-marmooka-city-global-city-tower"
    }, {
        n: "Jatropha",
        s: "ajman-ajman-uptown-jatropha"
    }, {
        n: "Highfield Towers",
        s: "ajman-al-humaid-city-highfield-towers"
    }, {
        n: "Springfield",
        s: "ajman-al-humaid-city-springfield"
    }, {
        n: "Al Hadeel Tower",
        s: "ajman-al-humaid-city-al-hadeel-tower"
    }, {
        n: "Conquer Tower",
        s: "ajman-sheikh-maktoum-bin-rashid-street-conquer-tower"
    }, {
        n: "Al Rashidiya Towers",
        s: "ajman-ajman-downtown-al-rashidiya-towers"
    }, {
        n: "Ajman Hills",
        s: "ajman-al-alia-ajman-hills"
    }, {
        n: "M R Tower",
        s: "ajman-emirates-city-m-r-tower"
    }, {
        n: "Al Ajyaal Residency",
        s: "ajman-emirates-city-al-ajyaal-residency"
    }, {
        n: "Unique Tower",
        s: "ajman-emirates-city-unique-tower"
    }, {
        n: "Royal Oasis",
        s: "ajman-emirates-city-royal-oasis"
    }, {
        n: "Rockland Residence",
        s: "ajman-emirates-city-rockland-residence"
    }, {
        n: "Venice Tower",
        s: "ajman-emirates-city-venice-tower"
    }, {
        n: "Chapal the Legacy",
        s: "ajman-emirates-city-chapal-the-legacy"
    }, {
        n: "Fortune Residency",
        s: "ajman-emirates-city-fortune-residency"
    }, {
        n: "ETA Star",
        s: "ajman-emirates-city-eta-star"
    }, {
        n: "Al Rashidiya 2",
        s: "ajman-al-rashidiya-al-rashidiya-2"
    }, {
        n: "Al Rawda 3",
        s: "ajman-al-rawda-al-rawda-3"
    }, {
        n: "Arc Tower",
        s: "ajman-marmooka-city-arc-tower"
    }, {
        n: "Shami Garden Towers",
        s: "ajman-marmooka-city-shami-garden-towers"
    }, {
        n: "Ajman Boulevard",
        s: "ajman-ajman-uptown-ajman-boulevard"
    }, {
        n: "Tricon Lake Front",
        s: "ajman-al-humaid-city-tricon-lake-front"
    }, {
        n: "Al Hilal Tower",
        s: "ajman-al-humaid-city-al-hilal-tower"
    }, {
        n: "Mandarin Towers",
        s: "ajman-garden-city-mandarin-towers"
    }, {
        n: "Meeza",
        s: "ajman-green-city-meeza"
    }, {
        n: "Park View Tower",
        s: "ajman-park-view-city-park-view-tower"
    }, {
        n: "Peninsula",
        s: "ajman-al-zorah-peninsula"
    }, {
        n: "Rifa 2",
        s: "ajman-al-naemiyah-rifa-2"
    }, {
        n: "Moosani Tower",
        s: "ajman-al-naemiyah-moosani-tower"
    }, {
        n: "Ain Ajman Tower",
        s: "ajman-ain-ajman-ain-ajman-tower"
    }, {
        n: "Masfoot 11",
        s: "ajman-masfoot-masfoot-11"
    }, {
        n: "Masfoot 5",
        s: "ajman-masfoot-masfoot-5"
    }, {
        n: "Masfoot 12",
        s: "ajman-masfoot-masfoot-12"
    }, {
        n: "Sonya Tower",
        s: "ajman-sheikh-khalifa-bin-zayed-street-sonya-tower"
    }, {
        n: "Ajman Corniche Residences",
        s: "ajman-ajman-corniche-road-ajman-corniche-residences"
    }, {
        n: "Al Suyoh 6",
        s: "sharjah-al-suyoh-al-suyoh-6"
    }, {
        n: "Ajman Global City",
        s: "ajman-al-alia-ajman-global-city"
    }, {
        n: "Al Mwaihat 1",
        s: "ajman-al-mwaihat-al-mwaihat-1"
    }, {
        n: "Lake View Towers",
        s: "ajman-emirates-city-lake-view-towers"
    }, {
        n: "Burj Al Furqan",
        s: "ajman-emirates-city-burj-al-furqan"
    }, {
        n: "Crown Residencia",
        s: "ajman-emirates-city-crown-residencia"
    }, {
        n: "Money Tower",
        s: "ajman-emirates-city-money-tower"
    }, {
        n: "Majestic Tower C3",
        s: "ajman-emirates-city-majestic-tower-c3"
    }, {
        n: "Crimson Court",
        s: "ajman-emirates-city-crimson-court"
    }, {
        n: "Rainbow Towers",
        s: "ajman-emirates-city-rainbow-towers"
    }, {
        n: "Eye Tower",
        s: "ajman-emirates-city-eye-tower"
    }, {
        n: "Fayrooz Tower",
        s: "ajman-emirates-city-fayrooz-tower"
    }, {
        n: "Al Azza Building",
        s: "ajman-al-rumaila-al-azza-building"
    }, {
        n: "Ajman Industrial 2",
        s: "ajman-ajman-industrial-area-ajman-industrial-2"
    }, {
        n: "Al Raqaib 1",
        s: "ajman-al-raqaib-al-raqaib-1"
    }, {
        n: "Burj Regent",
        s: "ajman-marmooka-city-burj-regent"
    }, {
        n: "Sat Lake Residency",
        s: "ajman-marmooka-city-sat-lake-residency"
    }, {
        n: "Emerald Vista",
        s: "ajman-marmooka-city-emerald-vista"
    }, {
        n: "Ajman Uptown Villas",
        s: "ajman-ajman-uptown-ajman-uptown-villas"
    }, {
        n: "Calthorpe Tower",
        s: "ajman-al-humaid-city-calthorpe-tower"
    }, {
        n: "Blossom Towers",
        s: "ajman-al-humaid-city-blossom-towers"
    }, {
        n: "Global Pearl Residence",
        s: "ajman-al-humaid-city-global-pearl-residence"
    }, {
        n: "Almond Tower",
        s: "ajman-garden-city-almond-tower"
    }, {
        n: "ICT Towers",
        s: "ajman-park-view-city-ict-towers"
    }, {
        n: "Beachfront",
        s: "ajman-al-zorah-beachfront"
    }, {
        n: "City Tower",
        s: "ajman-al-naemiyah-city-tower"
    }, {
        n: "Al Maha Village",
        s: "ajman-al-zahya-al-maha-village"
    }, {
        n: "Masfoot 14",
        s: "ajman-masfoot-masfoot-14"
    }, {
        n: "Masfoot 13",
        s: "ajman-masfoot-masfoot-13"
    }, {
        n: "Masfoot 6",
        s: "ajman-masfoot-masfoot-6"
    }, {
        n: "Century Towers",
        s: "ajman-park-view-city-century-towers"
    }, {
        n: "Creekside",
        s: "ajman-al-zorah-creekside"
    }, {
        n: "Rifa 1",
        s: "ajman-al-naemiyah-rifa-1"
    }, {
        n: "Al Naemiya Towers",
        s: "ajman-al-naemiyah-al-naemiya-towers"
    }, {
        n: "Al Zaheya Gardens",
        s: "ajman-al-zahya-al-zaheya-gardens"
    }, {
        n: "Masfoot 2",
        s: "ajman-masfoot-masfoot-2"
    }, {
        n: "Masfoot 15",
        s: "ajman-masfoot-masfoot-15"
    }, {
        n: "Masfoot 1",
        s: "ajman-masfoot-masfoot-1"
    }, {
        n: "Al Hamidiya 1",
        s: "ajman-al-hamidiya-al-hamidiya-1"
    }, {
        n: "Future tower 2",
        s: "sharjah-al-khan-future-tower-2"
    }, {
        n: "Future tower",
        s: "sharjah-al-khan-future-tower"
    }, {
        n: "Sarab Tower",
        s: "sharjah-al-khan-sarab-tower"
    }, {
        n: "Al Majaz 3",
        s: "sharjah-al-majaz-al-majaz-3"
    }, {
        n: "Al Majaz Tower",
        s: "sharjah-al-majaz-al-majaz-tower"
    }, {
        n: "Samaya Hotel Apartments",
        s: "sharjah-al-nahda-samaya-hotel-apartments"
    }, {
        n: "Moon Towers",
        s: "sharjah-al-nahda-moon-towers"
    }, {
        n: "Suroor 445",
        s: "sharjah-al-nahda-suroor-445"
    }, {
        n: "Taliatela Street",
        s: "sharjah-al-nahda-taliatela-street"
    }, {
        n: "Street 20",
        s: "sharjah-al-nahda-street-20"
    }, {
        n: "Industrial Area 1",
        s: "sharjah-sharjah-industrial-area-industrial-area-1"
    }, {
        n: "Industrial Area 3",
        s: "sharjah-sharjah-industrial-area-industrial-area-3"
    }, {
        n: "Industrial Area 13",
        s: "sharjah-sharjah-industrial-area-industrial-area-13"
    }, {
        n: "Al Thuraya Tower",
        s: "sharjah-al-taawun-al-thuraya-tower"
    }, {
        n: "Zakhir Towers",
        s: "sharjah-al-taawun-zakhir-towers"
    }, {
        n: "Al Rahmaniya 2",
        s: "sharjah-al-rahmaniya-al-rahmaniya-2"
    }, {
        n: "Al Rahmaniya 6",
        s: "sharjah-al-rahmaniya-al-rahmaniya-6"
    }, {
        n: "Al Rahmaniya 7",
        s: "sharjah-al-rahmaniya-al-rahmaniya-7"
    }, {
        n: "Al Suyoh 7",
        s: "sharjah-al-suyoh-al-suyoh-7"
    }, {
        n: "Al Suyoh 13",
        s: "sharjah-al-suyoh-al-suyoh-13"
    }, {
        n: "Al Suyoh 18",
        s: "sharjah-al-suyoh-al-suyoh-18"
    }, {
        n: "Al Naimiya",
        s: "ajman-al-naemiyah-al-naimiya"
    }, {
        n: "Al Khaled Tower",
        s: "ajman-al-naemiyah-al-khaled-tower"
    }, {
        n: "Al Anwar Tower",
        s: "ajman-al-naemiyah-al-anwar-tower"
    }, {
        n: "Masfoot 4",
        s: "ajman-masfoot-masfoot-4"
    }, {
        n: "Masfoot 3",
        s: "ajman-masfoot-masfoot-3"
    }, {
        n: "Masfoot 9",
        s: "ajman-masfoot-masfoot-9"
    }, {
        n: "Al Yasmeen 1",
        s: "ajman-al-yasmeen-al-yasmeen-1"
    }, {
        n: "The Black Square",
        s: "ajman-sheikh-khalifa-bin-zayed-street-the-black-square"
    }, {
        n: "Ajman One Towers",
        s: "ajman-al-sawan-ajman-one-towers"
    }, {
        n: "Al Marwa Towers",
        s: "sharjah-al-khan-al-marwa-towers"
    }, {
        n: "SG Al Khan Building",
        s: "sharjah-al-khan-sg-al-khan-building"
    }, {
        n: "Future Tower 3",
        s: "sharjah-al-khan-future-tower-3"
    }, {
        n: "Al Hafeet Tower",
        s: "sharjah-al-khan-al-hafeet-tower"
    }, {
        n: "Al Ikhlas Tower",
        s: "sharjah-al-khan-al-ikhlas-tower"
    }, {
        n: "Al Majaz 1",
        s: "sharjah-al-majaz-al-majaz-1"
    }, {
        n: "Compas Tower",
        s: "sharjah-al-majaz-compas-tower"
    }, {
        n: "Saeed Al Ghafli Building",
        s: "sharjah-al-majaz-saeed-al-ghafli-building"
    }, {
        n: "Al Rayyan Complex",
        s: "sharjah-al-nahda-al-rayyan-complex"
    }, {
        n: "Lootah Tower",
        s: "sharjah-al-nahda-lootah-tower"
    }, {
        n: "Manazil Tower 4",
        s: "sharjah-al-nahda-manazil-tower-4"
    }, {
        n: "Orchid Tower",
        s: "sharjah-al-nahda-orchid-tower"
    }, {
        n: "Sahara Healthcare City",
        s: "sharjah-al-nahda-sahara-healthcare-city"
    }, {
        n: "Sharjah Gate",
        s: "sharjah-al-nahda-sharjah-gate"
    }, {
        n: "Street 64",
        s: "sharjah-al-nahda-street-64"
    }, {
        n: "Industrial Area 15",
        s: "sharjah-sharjah-industrial-area-industrial-area-15"
    }, {
        n: "Industrial Area 12",
        s: "sharjah-sharjah-industrial-area-industrial-area-12"
    }, {
        n: "Industrial Area 7",
        s: "sharjah-sharjah-industrial-area-industrial-area-7"
    }, {
        n: "Aida Tower 1",
        s: "ajman-al-naemiyah-aida-tower-1"
    }, {
        n: "Ajman Twins",
        s: "ajman-al-naemiyah-ajman-twins"
    }, {
        n: "Gold Crest Smart Tower",
        s: "ajman-ain-ajman-gold-crest-smart-tower"
    }, {
        n: "Masfoot 10",
        s: "ajman-masfoot-masfoot-10"
    }, {
        n: "Masfoot 8",
        s: "ajman-masfoot-masfoot-8"
    }, {
        n: "Masfoot 7",
        s: "ajman-masfoot-masfoot-7"
    }, {
        n: "Al Shorafa Complex",
        s: "ajman-sheikh-khalifa-bin-zayed-street-al-shorafa-complex"
    }, {
        n: "Tiger 4 by ASAS",
        s: "sharjah-al-khan-tiger-4-by-asas"
    }, {
        n: "Al Taawoon Towers",
        s: "sharjah-al-khan-al-taawoon-towers"
    }, {
        n: "Robot Park Tower",
        s: "sharjah-al-khan-robot-park-tower"
    }, {
        n: "Al Safyia Building",
        s: "sharjah-al-majaz-al-safyia-building"
    }, {
        n: "SG Al Majaz Building",
        s: "sharjah-al-majaz-sg-al-majaz-building"
    }, {
        n: "Eissal Al Youssifi Towers",
        s: "sharjah-al-majaz-eissal-al-youssifi-towers"
    }, {
        n: "Golden Sands Tower",
        s: "sharjah-al-nahda-golden-sands-tower"
    }, {
        n: "Al Ahlam Tower",
        s: "sharjah-al-nahda-al-ahlam-tower"
    }, {
        n: "Smart Tower 2",
        s: "sharjah-al-nahda-smart-tower-2"
    }, {
        n: "Al Nadha Residential Complex",
        s: "sharjah-al-nahda-al-nadha-residential-complex"
    }, {
        n: "Al Nadha JAM Residential Complex",
        s: "sharjah-al-nahda-al-nadha-jam-residential-complex"
    }, {
        n: "Al Shaiba Building 167",
        s: "sharjah-al-nahda-al-shaiba-building-167"
    }, {
        n: "Industrial Area 17",
        s: "sharjah-sharjah-industrial-area-industrial-area-17"
    }, {
        n: "Maliha",
        s: "sharjah-sharjah-industrial-area-maliha"
    }, {
        n: "Al Suyoh 8",
        s: "sharjah-al-suyoh-al-suyoh-8"
    }, {
        n: "Al Hamidiya 2",
        s: "ajman-al-hamidiya-al-hamidiya-2"
    }, {
        n: "Al Ateek Tower 1",
        s: "sharjah-al-shuwaihean-al-ateek-tower-1"
    }, {
        n: "SBS Al Khan",
        s: "sharjah-al-khan-sbs-al-khan"
    }, {
        n: "Victoria Tower",
        s: "sharjah-al-khan-victoria-tower"
    }, {
        n: "Rose Tower",
        s: "sharjah-al-khan-rose-tower"
    }, {
        n: "Al Khan Corniche",
        s: "sharjah-al-khan-al-khan-corniche"
    }, {
        n: "Capital Tower",
        s: "sharjah-al-majaz-capital-tower"
    }, {
        n: "Al Yasmin Tower",
        s: "sharjah-al-majaz-al-yasmin-tower"
    }, {
        n: "Palm Towers",
        s: "sharjah-al-majaz-palm-towers"
    }, {
        n: "Al Durrah Tower",
        s: "sharjah-al-majaz-al-durrah-tower"
    }, {
        n: "Al Muhannad Tower",
        s: "sharjah-al-majaz-al-muhannad-tower"
    }, {
        n: "Al Fajir Tower",
        s: "sharjah-al-nahda-al-fajir-tower"
    }, {
        n: "Al Nahda Complex",
        s: "sharjah-al-nahda-al-nahda-complex"
    }, {
        n: "Suroor 262",
        s: "sharjah-al-nahda-suroor-262"
    }, {
        n: "Al Nada Tower",
        s: "sharjah-al-nahda-al-nada-tower"
    }, {
        n: "Sahara Complex",
        s: "sharjah-al-nahda-sahara-complex"
    }, {
        n: "Bukhara Street",
        s: "sharjah-al-nahda-bukhara-street"
    }, {
        n: "Art Tower",
        s: "sharjah-al-nahda-art-tower"
    }, {
        n: "Industrial Area 11",
        s: "sharjah-sharjah-industrial-area-industrial-area-11"
    }, {
        n: "Industrial Area 6",
        s: "sharjah-sharjah-industrial-area-industrial-area-6"
    }, {
        n: "Industrial Area 2",
        s: "sharjah-sharjah-industrial-area-industrial-area-2"
    }, {
        n: "Teachers Residential Complex",
        s: "sharjah-sharjah-industrial-area-teachers-residential-complex"
    }, {
        n: "Industrial Area 10",
        s: "sharjah-sharjah-industrial-area-industrial-area-10"
    }, {
        n: "Industrial Area 18",
        s: "sharjah-sharjah-industrial-area-industrial-area-18"
    }, {
        n: "Al Suyoh 3",
        s: "sharjah-al-suyoh-al-suyoh-3"
    }, {
        n: "Suroor Towers",
        s: "sharjah-al-khan-suroor-towers"
    }, {
        n: "Pearl Tower",
        s: "sharjah-al-khan-pearl-tower"
    }, {
        n: "Al Khan 5 building",
        s: "sharjah-al-khan-al-khan-5-building"
    }, {
        n: "Al Bandary Twin Towers",
        s: "sharjah-al-khan-al-bandary-twin-towers"
    }, {
        n: "Al Majaz 2",
        s: "sharjah-al-majaz-al-majaz-2"
    }, {
        n: "Emirates Sail Tower",
        s: "sharjah-al-majaz-emirates-sail-tower"
    }, {
        n: "Jawahar Tower",
        s: "sharjah-al-majaz-jawahar-tower"
    }, {
        n: "Jamal Abdul Nasser Street",
        s: "sharjah-al-majaz-jamal-abdul-nasser-street"
    }, {
        n: "Aliya Tower",
        s: "sharjah-al-nahda-aliya-tower"
    }, {
        n: "Suroor 258",
        s: "sharjah-al-nahda-suroor-258"
    }, {
        n: "Suroor 443",
        s: "sharjah-al-nahda-suroor-443"
    }, {
        n: "Al Zain Tower",
        s: "sharjah-al-nahda-al-zain-tower"
    }, {
        n: "Gulf Pearl Tower",
        s: "sharjah-al-nahda-gulf-pearl-tower"
    }, {
        n: "Al Shaiba Building 334",
        s: "sharjah-al-nahda-al-shaiba-building-334"
    }, {
        n: "Al Shaiba Building 183",
        s: "sharjah-al-nahda-al-shaiba-building-183"
    }, {
        n: "Al Saja'a S",
        s: "sharjah-sharjah-industrial-area-al-saja-a-s"
    }, {
        n: "Industrial Area 5",
        s: "sharjah-sharjah-industrial-area-industrial-area-5"
    }, {
        n: "Industrial Area 9",
        s: "sharjah-sharjah-industrial-area-industrial-area-9"
    }, {
        n: "Al Hanoo",
        s: "sharjah-sharjah-industrial-area-al-hanoo"
    }, {
        n: "Al Arqoub",
        s: "sharjah-sharjah-industrial-area-al-arqoub"
    }, {
        n: "Industrial Area 8",
        s: "sharjah-sharjah-industrial-area-industrial-area-8"
    }, {
        n: "Umm Al Qura Tower",
        s: "sharjah-al-mamzar-sharjah-umm-al-qura-tower"
    }, {
        n: "Al Taawun Street",
        s: "sharjah-al-taawun-al-taawun-street"
    }, {
        n: "Al Shaiba Building A",
        s: "sharjah-al-taawun-al-shaiba-building-a"
    }, {
        n: "Al Tala'a",
        s: "sharjah-mughaidir-al-tala-a"
    }, {
        n: "Al Rahmaniya 1",
        s: "sharjah-al-rahmaniya-al-rahmaniya-1"
    }, {
        n: "Al Rahmaniya 5",
        s: "sharjah-al-rahmaniya-al-rahmaniya-5"
    }, {
        n: "Al Saja'a",
        s: "sharjah-sharjah-industrial-area-al-saja-a"
    }, {
        n: "La Plage Tower",
        s: "sharjah-al-mamzar-sharjah-la-plage-tower"
    }, {
        n: "Saeed Al Alami Building",
        s: "sharjah-al-taawun-saeed-al-alami-building"
    }, {
        n: "City Gate Tower",
        s: "sharjah-al-taawun-city-gate-tower"
    }, {
        n: "Al Rifa'a",
        s: "sharjah-mughaidir-al-rifa-a"
    }, {
        n: "Al Darari",
        s: "sharjah-mughaidir-al-darari"
    }, {
        n: "Al Khezamia",
        s: "sharjah-mughaidir-al-khezamia"
    }, {
        n: "Al Tarfa",
        s: "sharjah-mughaidir-al-tarfa"
    }, {
        n: "Al Rahmaniya 3",
        s: "sharjah-al-rahmaniya-al-rahmaniya-3"
    }, {
        n: "Al Rahmaniya 4",
        s: "sharjah-al-rahmaniya-al-rahmaniya-4"
    }, {
        n: "Mazaira",
        s: "sharjah-al-rahmaniya-mazaira"
    }, {
        n: "Seventh Heaven",
        s: "dubai-al-barari-seventh-heaven"
    }, {
        n: "Manazil Tower 3",
        s: "sharjah-al-mamzar-sharjah-manazil-tower-3"
    }, {
        n: "New Al Taawun Road",
        s: "sharjah-al-taawun-new-al-taawun-road"
    }, {
        n: "Bin Ham Towers",
        s: "sharjah-al-taawun-bin-ham-towers"
    }, {
        n: "Al Shahba",
        s: "sharjah-mughaidir-al-shahba"
    }, {
        n: "Al Rahmaniya 8",
        s: "sharjah-al-rahmaniya-al-rahmaniya-8"
    }, {
        n: "Al Suyoh 10",
        s: "sharjah-al-suyoh-al-suyoh-10"
    }, {
        n: "Al Suyoh 15",
        s: "sharjah-al-suyoh-al-suyoh-15"
    }, {
        n: "Al Suyoh 16",
        s: "sharjah-al-suyoh-al-suyoh-16"
    }, {
        n: "Al Qulaya'ah",
        s: "sharjah-al-sharq-al-qulaya-ah"
    }, {
        n: "Um Altaraffa",
        s: "sharjah-al-gharb-um-altaraffa"
    }, {
        n: "Al Rifa'ah",
        s: "sharjah-al-heerah-al-rifa-ah"
    }, {
        n: "Al Hazana",
        s: "sharjah-al-riqqa-al-hazana"
    }, {
        n: "Al Sabkha",
        s: "sharjah-al-riqqa-al-sabkha"
    }, {
        n: "MISK Apartments",
        s: "sharjah-aljada-misk-apartments"
    }, {
        n: "Al Nujoom Islands",
        s: "sharjah-al-nujoom-islands-al-nujoom-islands"
    }, {
        n: "Al Nouf 3",
        s: "sharjah-al-nouf-al-nouf-3"
    }, {
        n: "SG Muwaileh Building",
        s: "sharjah-muwaileh-sg-muwaileh-building"
    }, {
        n: "Azure Beach Residence",
        s: "sharjah-maryam-island-azure-beach-residence"
    }, {
        n: "Al Jurainah 1",
        s: "sharjah-al-jurainah-al-jurainah-1"
    }, {
        n: "Tilal City A",
        s: "sharjah-tilal-city-tilal-city-a"
    }, {
        n: "Al Qarain 1",
        s: "sharjah-al-qarain-al-qarain-1"
    }, {
        n: "Manazil Tower 1",
        s: "sharjah-al-qasemiya-manazil-tower-1"
    }, {
        n: "Al Ateek Tower 2",
        s: "sharjah-al-qasemiya-al-ateek-tower-2"
    }, {
        n: "Al Mahatta",
        s: "sharjah-al-qasemiya-al-mahatta"
    }, {
        n: "Budaniq",
        s: "sharjah-al-qasemiya-budaniq"
    }, {
        n: "AlFalah",
        s: "sharjah-muwaileh-commercial-al-falah"
    }, {
        n: "Rolla Square",
        s: "sharjah-rolla-area-rolla-square"
    }, {
        n: "Dasman",
        s: "sharjah-halwan-dasman"
    }, {
        n: "Al Butina B",
        s: "sharjah-al-butina-al-butina-b"
    }, {
        n: "Green Emirates",
        s: "dubai-business-bay-green-emirates"
    }, {
        n: "Al Suyoh 9",
        s: "sharjah-al-suyoh-al-suyoh-9"
    }, {
        n: "Al Suyoh 5",
        s: "sharjah-al-suyoh-al-suyoh-5"
    }, {
        n: "Al Suyoh 20",
        s: "sharjah-al-suyoh-al-suyoh-20"
    }, {
        n: "Al Suyoh 11",
        s: "sharjah-al-suyoh-al-suyoh-11"
    }, {
        n: "Al Suyoh 2",
        s: "sharjah-al-suyoh-al-suyoh-2"
    }, {
        n: "Al Naseriya",
        s: "sharjah-al-sharq-al-naseriya"
    }, {
        n: "Al Mujarrah",
        s: "sharjah-al-sharq-al-mujarrah"
    }, {
        n: "Al Mareija",
        s: "sharjah-al-gharb-al-mareija"
    }, {
        n: "Al Nekhailat",
        s: "sharjah-al-heerah-al-nekhailat"
    }, {
        n: "Al Fisht",
        s: "sharjah-al-heerah-al-fisht"
    }, {
        n: "Capital Tower",
        s: "sharjah-al-wahda-capital-tower"
    }, {
        n: "Al Falaj",
        s: "sharjah-al-riqqa-al-falaj"
    }, {
        n: "Al Amal Building",
        s: "sharjah-um-altaraffa-al-amal-building"
    }, {
        n: "Barashi",
        s: "sharjah-al-badie-barashi"
    }, {
        n: "Al Nouf 4",
        s: "sharjah-al-nouf-al-nouf-4"
    }, {
        n: "Al Wadi Building",
        s: "sharjah-muwaileh-al-wadi-building"
    }, {
        n: "Al Hilal Residential Tower",
        s: "fujairah-al-hilal-city-al-hilal-residential-tower"
    }, {
        n: "Desert Leaf",
        s: "dubai-al-barari-desert-leaf"
    }, {
        n: "Al Suyoh 12",
        s: "sharjah-al-suyoh-al-suyoh-12"
    }, {
        n: "Al Suyoh 19",
        s: "sharjah-al-suyoh-al-suyoh-19"
    }, {
        n: "Al Suyoh 17",
        s: "sharjah-al-suyoh-al-suyoh-17"
    }, {
        n: "Al Naba'ah",
        s: "sharjah-al-sharq-al-naba-ah"
    }, {
        n: "Al Ghuair",
        s: "sharjah-al-gharb-al-ghuair"
    }, {
        n: "Tiger Tower",
        s: "sharjah-al-gharb-tiger-tower"
    }, {
        n: "Al Qadsiya",
        s: "sharjah-al-heerah-al-qadsiya"
    }, {
        n: "Areej Apartments",
        s: "sharjah-aljada-areej-apartments"
    }, {
        n: "Hoshi",
        s: "sharjah-al-badie-hoshi"
    }, {
        n: "Al Nouf 1",
        s: "sharjah-al-nouf-al-nouf-1"
    }, {
        n: "Suroor 511",
        s: "sharjah-muwaileh-suroor-511"
    }, {
        n: "Fire Station Road",
        s: "sharjah-muwaileh-fire-station-road"
    }, {
        n: "Wahat Al Tai",
        s: "sharjah-al-tai-wahat-al-tai"
    }, {
        n: "Tilal City B",
        s: "sharjah-tilal-city-tilal-city-b"
    }, {
        n: "Al Qarain 2",
        s: "sharjah-al-qarain-al-qarain-2"
    }, {
        n: "Mega Mall",
        s: "sharjah-al-qasemiya-mega-mall"
    }, {
        n: "Al Marzouqi Towers",
        s: "sharjah-al-qasemiya-al-marzouqi-towers"
    }, {
        n: "Al Ghubaiba",
        s: "sharjah-halwan-al-ghubaiba"
    }, {
        n: "Victoria Tower",
        s: "sharjah-al-gulayaa-victoria-tower"
    }, {
        n: "Muwafja",
        s: "sharjah-wasit-muwafja"
    }, {
        n: "Budaniq Building",
        s: "sharjah-abu-shagara-budaniq-building"
    }, {
        n: "Abu Shagara building",
        s: "sharjah-abu-shagara-abu-shagara-building"
    }, {
        n: "The Reserve",
        s: "dubai-al-barari-the-reserve"
    }, {
        n: "Al S­­harqi Street",
        s: "fujairah-sheikh-hamad-bin-abdullah-st-al-s-harqi-street"
    }, {
        n: "Block 5",
        s: "dubai-academic-city-block-5"
    }, {
        n: "Block 8",
        s: "dubai-academic-city-block-8"
    }, {
        n: "Al Suyoh 14",
        s: "sharjah-al-suyoh-al-suyoh-14"
    }, {
        n: "Maysaloon",
        s: "sharjah-al-sharq-maysaloon"
    }, {
        n: "Bu Tina",
        s: "sharjah-al-sharq-bu-tina"
    }, {
        n: "Al Amal Building",
        s: "sharjah-al-gharb-al-amal-building"
    }, {
        n: "Al Mirgab",
        s: "sharjah-al-heerah-al-mirgab"
    }, {
        n: "Al Jazzat",
        s: "sharjah-al-riqqa-al-jazzat"
    }, {
        n: "Al Falah",
        s: "sharjah-al-badie-al-falah"
    }, {
        n: "Blue Bay",
        s: "sharjah-al-nujoom-islands-blue-bay"
    }, {
        n: "Suroor 298",
        s: "sharjah-muwaileh-suroor-298"
    }, {
        n: "Muwaileh 3 Building",
        s: "sharjah-muwaileh-muwaileh-3-building"
    }, {
        n: "Sapphire Beach Residence",
        s: "sharjah-maryam-island-sapphire-beach-residence"
    }, {
        n: "The Residence",
        s: "sharjah-al-khaldeia-area-the-residence"
    }, {
        n: "Tilal City D",
        s: "sharjah-tilal-city-tilal-city-d"
    }, {
        n: "Al Qarain 4",
        s: "sharjah-al-qarain-al-qarain-4"
    }, {
        n: "Al Soor",
        s: "sharjah-al-qasemiya-al-soor"
    }, {
        n: "Al Yarmouk",
        s: "sharjah-al-qasemiya-al-yarmouk"
    }, {
        n: "Sunlight Tower",
        s: "sharjah-al-qasemiya-sunlight-tower"
    }, {
        n: "Al Nad",
        s: "sharjah-al-qasemiya-al-nad"
    }, {
        n: "The Square 1",
        s: "sharjah-muwaileh-commercial-the-square-1"
    }, {
        n: "iMall",
        s: "sharjah-al-ettihad-street-i-mall"
    }, {
        n: "Al Abar",
        s: "sharjah-halwan-al-abar"
    }, {
        n: "Al Butina 9 Building",
        s: "sharjah-al-butina-al-butina-9-building"
    }, {
        n: "Al Sweihat",
        s: "sharjah-wasit-al-sweihat"
    }, {
        n: "Zayd Bin Aslam Street",
        s: "sharjah-abu-shagara-zayd-bin-aslam-street"
    }, {
        n: "SBS B2 Building",
        s: "sharjah-abu-shagara-sbs-b2-building"
    }, {
        n: "Al Hilal Villas",
        s: "fujairah-al-hilal-city-al-hilal-villas"
    }, {
        n: "Al Barari Villas",
        s: "dubai-al-barari-al-barari-villas"
    }, {
        n: "Al Ghafia",
        s: "sharjah-al-riqqa-al-ghafia"
    }, {
        n: "Al Azra",
        s: "sharjah-al-riqqa-al-azra"
    }, {
        n: "Anber Townhouses",
        s: "sharjah-aljada-anber-townhouses"
    }, {
        n: "The Grand Avenue",
        s: "sharjah-al-nasreya-the-grand-avenue"
    }, {
        n: "Al Nouf 2",
        s: "sharjah-al-nouf-al-nouf-2"
    }, {
        n: "Muwaileh 29 Building",
        s: "sharjah-muwaileh-muwaileh-29-building"
    }, {
        n: "Al Jurainah 2",
        s: "sharjah-al-jurainah-al-jurainah-2"
    }, {
        n: "Basateen Al Tai",
        s: "sharjah-al-tai-basateen-al-tai"
    }, {
        n: "Tilal City E",
        s: "sharjah-tilal-city-tilal-city-e"
    }, {
        n: "Al Qarain 3",
        s: "sharjah-al-qarain-al-qarain-3"
    }, {
        n: "Ammar Bin Yasir Street",
        s: "sharjah-al-qasemiya-ammar-bin-yasir-street"
    }, {
        n: "Kuwaiti Building",
        s: "sharjah-al-qasemiya-kuwaiti-building"
    }, {
        n: "Al Shiba Building 239 A",
        s: "sharjah-al-qasemiya-al-shiba-building-239-a"
    }, {
        n: "Al Zahia",
        s: "sharjah-muwaileh-commercial-al-zahia"
    }, {
        n: "Al Marwa Towers",
        s: "sharjah-cornich-al-buhaira-al-marwa-towers"
    }, {
        n: "Al Ramla",
        s: "sharjah-halwan-al-ramla"
    }, {
        n: "Al Shahid Tower",
        s: "sharjah-al-qasba-al-shahid-tower"
    }, {
        n: "Al Yash",
        s: "sharjah-wasit-al-yash"
    }, {
        n: "Al Ramtha",
        s: "sharjah-wasit-al-ramtha"
    }, {
        n: "SBS B1 Building",
        s: "sharjah-abu-shagara-sbs-b1-building"
    }, {
        n: "Sama 2",
        s: "sharjah-abu-shagara-sama-2"
    }, {
        n: "Al Fanar Towers",
        s: "fujairah-downtown-fujairah-al-fanar-towers"
    }, {
        n: "Silk Leaf",
        s: "dubai-al-barari-silk-leaf"
    }, {
        n: "The Nest",
        s: "dubai-al-barari-the-nest"
    }, {
        n: "Block 3",
        s: "dubai-academic-city-block-3"
    }, {
        n: "Cyan Beach Residence",
        s: "sharjah-maryam-island-cyan-beach-residence"
    }, {
        n: "Nasma Residence",
        s: "sharjah-al-tai-nasma-residence"
    }, {
        n: "Tilal City C",
        s: "sharjah-tilal-city-tilal-city-c"
    }, {
        n: "Al Qarain 5",
        s: "sharjah-al-qarain-al-qarain-5"
    }, {
        n: "Al Jubail",
        s: "sharjah-al-qasemiya-al-jubail"
    }, {
        n: "Al Shiba Building 239",
        s: "sharjah-al-qasemiya-al-shiba-building-239"
    }, {
        n: "Lotus Tower",
        s: "sharjah-al-qasemiya-lotus-tower"
    }, {
        n: "Al Dewan building",
        s: "sharjah-al-qasemiya-al-dewan-building"
    }, {
        n: "Al Hoor Building",
        s: "sharjah-muwaileh-commercial-al-hoor-building"
    }, {
        n: "Samnan",
        s: "sharjah-halwan-samnan"
    }, {
        n: "Queen Tower",
        s: "sharjah-al-qasba-queen-tower"
    }, {
        n: "Sohool Building",
        s: "sharjah-al-butina-sohool-building"
    }, {
        n: "Al Naeem Building",
        s: "sharjah-abu-shagara-al-naeem-building"
    }, {
        n: "SBS B3 Building",
        s: "sharjah-abu-shagara-sbs-b3-building"
    }, {
        n: "The Address Fujairah Resort + Spa",
        s: "fujairah-sharm-the-address-fujairah-resort-spa"
    }, {
        n: "Al Zahia Building",
        s: "sharjah-abu-shagara-al-zahia-building"
    }, {
        n: "Al Hilal Business Tower",
        s: "fujairah-al-hilal-city-al-hilal-business-tower"
    }, {
        n: "Jasmine Leaf",
        s: "dubai-al-barari-jasmine-leaf"
    }, {
        n: "The Neighbourhood",
        s: "dubai-al-barari-the-neighbourhood"
    }, {
        n: "KSK Homes Residence & Hotel",
        s: "dubai-academic-city-ksk-homes-residence-hotel"
    }, {
        n: "Block 9",
        s: "dubai-academic-city-block-9"
    }, {
        n: "Hamda 2 Building",
        s: "dubai-al-badaa-hamda-2-building"
    }, {
        n: "Hamda 5 Building",
        s: "dubai-al-badaa-hamda-5-building"
    }, {
        n: "Arenco Villas 12",
        s: "dubai-al-badaa-arenco-villas-12"
    }, {
        n: "Al Barsha 1",
        s: "dubai-al-barsha-al-barsha-1"
    }, {
        n: "Ashjar",
        s: "dubai-al-barari-ashjar"
    }, {
        n: "Block 6",
        s: "dubai-academic-city-block-6"
    }, {
        n: "Al Badaa Street",
        s: "dubai-al-badaa-al-badaa-street"
    }, {
        n: "Villa Lantana",
        s: "dubai-al-barsha-villa-lantana"
    }, {
        n: "Al Barsha South",
        s: "dubai-al-barsha-al-barsha-south"
    }, {
        n: "The Dreamz",
        s: "dubai-al-furjan-the-dreamz"
    }, {
        n: "Freej Residence",
        s: "dubai-al-furjan-freej-residence"
    }, {
        n: "South Village",
        s: "dubai-al-furjan-south-village"
    }, {
        n: "Village Center",
        s: "dubai-al-furjan-village-center"
    }, {
        n: "Azizi Star",
        s: "dubai-al-furjan-azizi-star"
    }, {
        n: "Maria Tower",
        s: "dubai-al-furjan-maria-tower"
    }, {
        n: "Al Maskan 2",
        s: "dubai-al-jafiliya-al-maskan-2"
    }, {
        n: "Al Masood Building",
        s: "dubai-al-jafiliya-al-masood-building"
    }, {
        n: "Al Quoz 2",
        s: "dubai-al-quoz-al-quoz-2"
    }, {
        n: "Al Qusais Residential Area",
        s: "dubai-al-qusais-al-qusais-residential-area"
    }, {
        n: "Al Safa 2",
        s: "dubai-al-safa-al-safa-2"
    }, {
        n: "Hilal Bin Tarraf Building",
        s: "dubai-al-wasl-hilal-bin-tarraf-building"
    }, {
        n: "Block 7",
        s: "dubai-academic-city-block-7"
    }, {
        n: "Al Aweer Village",
        s: "dubai-al-aweer-al-aweer-village"
    }, {
        n: "Al Badaa Villas",
        s: "dubai-al-badaa-al-badaa-villas"
    }, {
        n: "wasl 90",
        s: "dubai-al-badaa-wasl-90"
    }, {
        n: "Aparthotel Adagio Premium Dubai Al Barsha",
        s: "dubai-al-barsha-aparthotel-adagio-premium-dubai-al-barsha"
    }, {
        n: "Glamz",
        s: "dubai-al-furjan-glamz"
    }, {
        n: "Avenue Residence",
        s: "dubai-al-furjan-avenue-residence"
    }, {
        n: "Shaista Azizi",
        s: "dubai-al-furjan-shaista-azizi"
    }, {
        n: "East Village",
        s: "dubai-al-furjan-east-village"
    }, {
        n: "Starz by Danube",
        s: "dubai-al-furjan-starz-by-danube"
    }, {
        n: "Azizi Plaza",
        s: "dubai-al-furjan-azizi-plaza"
    }, {
        n: "Topaz Avenue",
        s: "dubai-al-furjan-topaz-avenue"
    }, {
        n: "Al Jafiliya Villas",
        s: "dubai-al-jafiliya-al-jafiliya-villas"
    }, {
        n: "Al Khawaneej 2",
        s: "dubai-al-khawaneej-al-khawaneej-2"
    }, {
        n: "Al Quoz 3",
        s: "dubai-al-quoz-al-quoz-3"
    }, {
        n: "AQU",
        s: "dubai-al-qusais-aqu"
    }, {
        n: "The Galleria Villas",
        s: "dubai-al-wasl-the-galleria-villas"
    }, {
        n: "Al Twar 1",
        s: "dubai-al-twar-al-twar-1"
    }, {
        n: "Al Barsha 2",
        s: "dubai-al-barsha-al-barsha-2"
    }, {
        n: "AZIZI Berton",
        s: "dubai-al-furjan-azizi-berton"
    }, {
        n: "Al Burooj Residence V",
        s: "dubai-al-furjan-al-burooj-residence-v"
    }, {
        n: "Azizi Residence",
        s: "dubai-al-furjan-azizi-residence"
    }, {
        n: "Al Fouad Building",
        s: "dubai-al-furjan-al-fouad-building"
    }, {
        n: "Murano Residences",
        s: "dubai-al-furjan-murano-residences"
    }, {
        n: "Phase 3",
        s: "dubai-al-furjan-phase-3"
    }, {
        n: "Phase 2",
        s: "dubai-al-furjan-phase-2"
    }, {
        n: "Al Hana Centre",
        s: "dubai-al-jafiliya-al-hana-centre"
    }, {
        n: "Al Maskan",
        s: "dubai-al-jafiliya-al-maskan"
    }, {
        n: "Al Quoz 1",
        s: "dubai-al-quoz-al-quoz-1"
    }, {
        n: "wasl square",
        s: "dubai-al-safa-wasl-square"
    }, {
        n: "Al Hudaiba",
        s: "dubai-al-satwa-al-hudaiba"
    }, {
        n: "Iris Crystal",
        s: "dubai-business-bay-iris-crystal"
    }, {
        n: "Victoria Residency",
        s: "dubai-al-furjan-victoria-residency"
    }, {
        n: "Eight at Al Furjan",
        s: "dubai-al-furjan-eight-at-al-furjan"
    }, {
        n: "Samia Azizi",
        s: "dubai-al-furjan-samia-azizi"
    }, {
        n: "Soraya 2",
        s: "dubai-al-furjan-soraya-2"
    }, {
        n: "AZIZI Roy Mediterranean",
        s: "dubai-al-furjan-azizi-roy-mediterranean"
    }, {
        n: "Al Majid Building",
        s: "dubai-al-jafiliya-al-majid-building"
    }, {
        n: "Al Joud Centre",
        s: "dubai-al-quoz-al-joud-centre"
    }, {
        n: "Al Qusais Road",
        s: "dubai-al-qusais-al-qusais-road"
    }, {
        n: "Al Wasl Road",
        s: "dubai-al-wasl-al-wasl-road"
    }, {
        n: "Al Diyafah",
        s: "dubai-al-satwa-al-diyafah"
    }, {
        n: "Al Twar 3",
        s: "dubai-al-twar-al-twar-3"
    }, {
        n: "Al Warqa'a 5",
        s: "dubai-al-warqa-a-al-warqa-a-5"
    }, {
        n: "Hattan",
        s: "dubai-arabian-ranches-hattan"
    }, {
        n: "Golf Homes",
        s: "dubai-arabian-ranches-golf-homes"
    }, {
        n: "J ONE",
        s: "dubai-business-bay-j-one"
    }, {
        n: "Pro Point Business Centre",
        s: "dubai-business-bay-pro-point-business-centre"
    }, {
        n: "Millennium Tower (Bright Start Tower)",
        s: "dubai-business-bay-millennium-tower-bright-start-tower"
    }, {
        n: "Dolphin Tower",
        s: "dubai-business-bay-dolphin-tower"
    }, {
        n: "Global Bay View",
        s: "dubai-business-bay-global-bay-view"
    }, {
        n: "Al Quoz 4",
        s: "dubai-al-quoz-al-quoz-4"
    }, {
        n: "Al Sarraf Residence",
        s: "dubai-al-qusais-al-sarraf-residence"
    }, {
        n: "Al Wasl  Building",
        s: "dubai-al-wasl-al-wasl-building"
    }, {
        n: "Satwa Road",
        s: "dubai-al-satwa-satwa-road"
    }, {
        n: "Al Warqa'a 2",
        s: "dubai-al-warqa-a-al-warqa-a-2"
    }, {
        n: "Alvorada",
        s: "dubai-arabian-ranches-alvorada"
    }, {
        n: "Savannah",
        s: "dubai-arabian-ranches-savannah"
    }, {
        n: "Mirador La Coleccion",
        s: "dubai-arabian-ranches-mirador-la-coleccion"
    }, {
        n: "Bay Avenue Mall",
        s: "dubai-business-bay-bay-avenue-mall"
    }, {
        n: "Santeville",
        s: "dubai-business-bay-santeville"
    }, {
        n: "Ubora Towers",
        s: "dubai-business-bay-ubora-towers"
    }, {
        n: "Symphony Tower",
        s: "dubai-business-bay-symphony-tower"
    }, {
        n: "Business Tower",
        s: "dubai-business-bay-business-tower"
    }, {
        n: "The Citadel Tower",
        s: "dubai-business-bay-the-citadel-tower"
    }, {
        n: "The Metropolis",
        s: "dubai-business-bay-the-metropolis"
    }, {
        n: "XL Tower",
        s: "dubai-business-bay-xl-tower"
    }, {
        n: "Noor Tower",
        s: "dubai-business-bay-noor-tower"
    }, {
        n: "AG Tower",
        s: "dubai-business-bay-ag-tower"
    }, {
        n: "Mayfair Tower",
        s: "dubai-business-bay-mayfair-tower"
    }, {
        n: "DAMAC Maison Privé",
        s: "dubai-business-bay-damac-maison-prive"
    }, {
        n: "Al Warqa'a 1",
        s: "dubai-al-warqa-a-al-warqa-a-1"
    }, {
        n: "Alma",
        s: "dubai-arabian-ranches-alma"
    }, {
        n: "Mirador",
        s: "dubai-arabian-ranches-mirador"
    }, {
        n: "Aseel",
        s: "dubai-arabian-ranches-aseel"
    }, {
        n: "One by Omniyat",
        s: "dubai-business-bay-one-by-omniyat"
    }, {
        n: "The Forum",
        s: "dubai-business-bay-the-forum"
    }, {
        n: "Empire Heights",
        s: "dubai-business-bay-empire-heights"
    }, {
        n: "The Court Tower",
        s: "dubai-business-bay-the-court-tower"
    }, {
        n: "Hamilton Tower",
        s: "dubai-business-bay-hamilton-tower"
    }, {
        n: "Blue Bay Tower",
        s: "dubai-business-bay-blue-bay-tower"
    }, {
        n: "Park Lane Tower",
        s: "dubai-business-bay-park-lane-tower"
    }, {
        n: "Vision Tower",
        s: "dubai-business-bay-vision-tower"
    }, {
        n: "Bay's Edge",
        s: "dubai-business-bay-bay-s-edge"
    }, {
        n: "Bays Edge",
        s: "dubai-business-bay-bay-s-edge"
    }, {
        n: "Boraq Tower",
        s: "dubai-business-bay-boraq-tower"
    }, {
        n: "Fortune Avenue",
        s: "dubai-business-bay-fortune-avenue"
    }, {
        n: "I Pad",
        s: "dubai-business-bay-i-pad"
    }, {
        n: "Oval Tower",
        s: "dubai-business-bay-oval-tower"
    }, {
        n: "Elite Tower",
        s: "dubai-business-bay-elite-tower"
    }, {
        n: "DAMAC Maison Canal Views",
        s: "dubai-business-bay-damac-maison-canal-views"
    }, {
        n: "Al Reem",
        s: "dubai-arabian-ranches-al-reem"
    }, {
        n: "La Avenida",
        s: "dubai-arabian-ranches-la-avenida"
    }, {
        n: "Palmera",
        s: "dubai-arabian-ranches-palmera"
    }, {
        n: "Capital Bay",
        s: "dubai-business-bay-capital-bay"
    }, {
        n: "Fortune Bravo",
        s: "dubai-business-bay-fortune-bravo"
    }, {
        n: "The Atria",
        s: "dubai-business-bay-the-atria"
    }, {
        n: "Elite Downtown Residence",
        s: "dubai-business-bay-elite-downtown-residence"
    }, {
        n: "Lake Central",
        s: "dubai-business-bay-lake-central"
    }, {
        n: "Al Shafar Tower",
        s: "dubai-business-bay-al-shafar-tower"
    }, {
        n: "DAMAC Majestine",
        s: "dubai-business-bay-damac-majestine"
    }, {
        n: "West Bay Tower",
        s: "dubai-business-bay-west-bay-tower"
    }, {
        n: "The Skyscraper",
        s: "dubai-business-bay-the-skyscraper"
    }, {
        n: "Executive Hotel & Office Tower",
        s: "dubai-business-bay-executive-hotel-office-tower"
    }, {
        n: "Iris Ora",
        s: "dubai-business-bay-iris-ora"
    }, {
        n: "Haz Tower",
        s: "dubai-business-bay-haz-tower"
    }, {
        n: "Safeer Towers",
        s: "dubai-business-bay-safeer-towers"
    }, {
        n: "Mayfair Residency",
        s: "dubai-business-bay-mayfair-residency"
    }, {
        n: "B2B Tower",
        s: "dubai-business-bay-b2b-tower"
    }, {
        n: "Bareeq Tower",
        s: "dubai-business-bay-bareeq-tower"
    }, {
        n: "The Sky Villa",
        s: "dubai-business-bay-the-sky-villa"
    }, {
        n: "Oxford Tower",
        s: "dubai-business-bay-oxford-tower"
    }, {
        n: "Boris Becker Business Tower",
        s: "dubai-business-bay-boris-becker-business-tower"
    }, {
        n: "Volante",
        s: "dubai-business-bay-volante"
    }, {
        n: "M Hotel Downtown by Millennium",
        s: "dubai-business-bay-m-hotel-downtown-by-millennium"
    }, {
        n: "Niki Lauda Twin Towers",
        s: "dubai-business-bay-niki-lauda-twin-towers"
    }, {
        n: "Dorchester Collection Dubai",
        s: "dubai-business-bay-dorchester-collection-dubai"
    }, {
        n: "Al Abraj street",
        s: "dubai-business-bay-al-abraj-street"
    }, {
        n: "Al Mahra",
        s: "dubai-arabian-ranches-al-mahra"
    }, {
        n: "Terra Nova",
        s: "dubai-arabian-ranches-terra-nova"
    }, {
        n: "Polo Homes",
        s: "dubai-arabian-ranches-polo-homes"
    }, {
        n: "Al Habtoor City",
        s: "dubai-business-bay-al-habtoor-city"
    }, {
        n: "Kempinski Residences",
        s: "dubai-business-bay-kempinski-residences"
    }, {
        n: "DAMAC Towers by Paramount",
        s: "dubai-business-bay-damac-towers-by-paramount"
    }, {
        n: "Fairview Residency",
        s: "dubai-business-bay-fairview-residency"
    }, {
        n: "The Prime Tower",
        s: "dubai-business-bay-the-prime-tower"
    }, {
        n: "Burj Al Alam (Fortune 101)",
        s: "dubai-business-bay-burj-al-alam-fortune-101"
    }, {
        n: "Grosvenor Office Tower",
        s: "dubai-business-bay-grosvenor-office-tower"
    }, {
        n: "Marquise Square Tower",
        s: "dubai-business-bay-marquise-square-tower"
    }, {
        n: "The Exchange",
        s: "dubai-business-bay-the-exchange"
    }, {
        n: "Lotus",
        s: "dubai-business-bay-lotus"
    }, {
        n: "DAMAC Maison De Ville Breeze",
        s: "dubai-business-bay-damac-maison-de-ville-breeze"
    }, {
        n: "Tasameem Towers",
        s: "dubai-business-bay-tasameem-towers"
    }, {
        n: "Hydra Twin Towers",
        s: "dubai-business-bay-hydra-twin-towers"
    }, {
        n: "Jumeirah Wave Business Tower",
        s: "dubai-business-bay-jumeirah-wave-business-tower"
    }, {
        n: "JW Marriott Marquis Hotel",
        s: "dubai-business-bay-jw-marriott-marquis-hotel"
    }, {
        n: "Fortune Bay",
        s: "dubai-business-bay-fortune-bay"
    }, {
        n: "Avanti",
        s: "dubai-business-bay-avanti"
    }, {
        n: "Signature Towers",
        s: "dubai-business-bay-signature-towers"
    }, {
        n: "Iris Bay",
        s: "dubai-business-bay-iris-bay"
    }, {
        n: "Clover Bay Tower",
        s: "dubai-business-bay-clover-bay-tower"
    }, {
        n: "Fifty One Tower",
        s: "dubai-business-bay-fifty-one-tower"
    }, {
        n: "Desert Dream Tower",
        s: "dubai-business-bay-desert-dream-tower"
    }, {
        n: "The Residences at Business Central",
        s: "dubai-business-bay-the-residences-at-business-central"
    }, {
        n: "Bay Square",
        s: "dubai-business-bay-bay-square"
    }, {
        n: "Moon Tower",
        s: "dubai-business-bay-moon-tower"
    }, {
        n: "The Court",
        s: "dubai-business-bay-the-court"
    }, {
        n: "O14",
        s: "dubai-business-bay-o14"
    }, {
        n: "Clayton Residency",
        s: "dubai-business-bay-clayton-residency"
    }, {
        n: "The Oberoi Business Bay",
        s: "dubai-business-bay-the-oberoi-business-bay"
    }, {
        n: "Oberoi Hotel",
        s: "dubai-business-bay-oberoi-hotel"
    }, {
        n: "The Oberoi Centre",
        s: "dubai-business-bay-the-oberoi-centre"
    }, {
        n: "Vera Residences",
        s: "dubai-business-bay-vera-residences"
    }, {
        n: "Silver Bay Tower",
        s: "dubai-business-bay-silver-bay-tower"
    }, {
        n: "Burj Pacific",
        s: "dubai-business-bay-burj-pacific"
    }, {
        n: "I.B. Tower",
        s: "dubai-business-bay-i-b-tower"
    }, {
        n: "DEC Business Tower",
        s: "dubai-business-bay-dec-business-tower"
    }, {
        n: "Operaview",
        s: "dubai-business-bay-operaview"
    }, {
        n: "Nadra Tower",
        s: "dubai-business-bay-nadra-tower"
    }, {
        n: "Starhill Towers & Gallery",
        s: "dubai-business-bay-starhill-towers-gallery"
    }, {
        n: "West Wharf",
        s: "dubai-business-bay-west-wharf"
    }, {
        n: "Polaris Tower",
        s: "dubai-business-bay-polaris-tower"
    }, {
        n: "Silver Star Tower",
        s: "dubai-business-bay-silver-star-tower"
    }, {
        n: "Newport Tower",
        s: "dubai-business-bay-newport-tower"
    }, {
        n: "The Oberoi",
        s: "dubai-business-bay-the-oberoi"
    }, {
        n: "Crystal Tower",
        s: "dubai-business-bay-crystal-tower"
    }, {
        n: "Vezul Residence",
        s: "dubai-business-bay-vezul-residence"
    }, {
        n: "Sky Bay Hotel",
        s: "dubai-business-bay-sky-bay-hotel"
    }, {
        n: "Elite Business Bay Residence",
        s: "dubai-business-bay-elite-business-bay-residence"
    }, {
        n: "Merano Tower",
        s: "dubai-business-bay-merano-tower"
    }, {
        n: "SLS Dubai Hotel & Residences",
        s: "dubai-business-bay-sls-dubai-hotel-residences"
    }, {
        n: "Paramount Tower Hotel & Residences",
        s: "dubai-business-bay-paramount-tower-hotel-residences"
    }, {
        n: "The Prism",
        s: "dubai-business-bay-the-prism"
    }, {
        n: "Canada Business Centre Tower",
        s: "dubai-business-bay-canada-business-centre-tower"
    }, {
        n: "Polygon Tower",
        s: "dubai-business-bay-polygon-tower"
    }, {
        n: "The Peninsula",
        s: "dubai-business-bay-the-peninsula"
    }, {
        n: "International Business Tower",
        s: "dubai-business-bay-international-business-tower"
    }, {
        n: "Westburry Square",
        s: "dubai-business-bay-westburry-square"
    }, {
        n: "DAMAC Maison The Vogue",
        s: "dubai-business-bay-damac-maison-the-vogue"
    }, {
        n: "Sami Business Tower (Q Sami Tower)",
        s: "dubai-business-bay-sami-business-tower-q-sami-tower"
    }, {
        n: "Zada Tower",
        s: "dubai-business-bay-zada-tower"
    }, {
        n: "Victory Bay Tower",
        s: "dubai-business-bay-victory-bay-tower"
    }, {
        n: "I love Florence Tower",
        s: "dubai-business-bay-i-love-florence-tower"
    }, {
        n: "Damac Maison Cour Jardin",
        s: "dubai-business-bay-damac-maison-cour-jardin"
    }, {
        n: "The Bay View",
        s: "dubai-business-bay-the-bay-view"
    }, {
        n: "Opal Tower",
        s: "dubai-business-bay-opal-tower"
    }, {
        n: "Residence 22",
        s: "dubai-business-bay-residence-22"
    }, {
        n: "Bristol Towers",
        s: "dubai-business-bay-bristol-towers"
    }, {
        n: "Centre Boulevard",
        s: "dubai-business-bay-centre-boulevard"
    }, {
        n: "The Sterling",
        s: "dubai-business-bay-the-sterling"
    }, {
        n: "Coral Tower",
        s: "dubai-business-bay-coral-tower"
    }, {
        n: "Silver Tower",
        s: "dubai-business-bay-silver-tower"
    }, {
        n: "Lily Residence",
        s: "dubai-dubai-investment-park-lily-residence"
    }, {
        n: "Rawasi Tower",
        s: "dubai-business-bay-rawasi-tower"
    }, {
        n: "MAG 318",
        s: "dubai-business-bay-mag-318"
    }, {
        n: "Bayz by Danube",
        s: "dubai-business-bay-bayz-by-danube"
    }, {
        n: "SOL Bay",
        s: "dubai-business-bay-sol-bay"
    }, {
        n: "Capital Golden Tower",
        s: "dubai-business-bay-capital-golden-tower"
    }, {
        n: "Belhabb Tower",
        s: "dubai-business-bay-belhabb-tower"
    }, {
        n: "Executive Towers",
        s: "dubai-business-bay-executive-towers"
    }, {
        n: "Tamani Art Tower",
        s: "dubai-business-bay-tamani-art-tower"
    }, {
        n: "The Regal Tower",
        s: "dubai-business-bay-the-regal-tower"
    }, {
        n: "Platinum Tower",
        s: "dubai-business-bay-platinum-tower"
    }, {
        n: "The Binary Tower",
        s: "dubai-business-bay-the-binary-tower"
    }, {
        n: "Marasi Business Bay",
        s: "dubai-business-bay-marasi-business-bay"
    }, {
        n: "Bayswater",
        s: "dubai-business-bay-bayswater"
    }, {
        n: "MBK Tower",
        s: "dubai-business-bay-mbk-tower"
    }, {
        n: "Ontario Tower",
        s: "dubai-business-bay-ontario-tower"
    }, {
        n: "Windsor Manor",
        s: "dubai-business-bay-windsor-manor"
    }, {
        n: "D1 Tower",
        s: "dubai-culture-village-d1-tower"
    }, {
        n: "Yuvi Residence",
        s: "dubai-culture-village-yuvi-residence"
    }, {
        n: "Cascade Manor",
        s: "dubai-culture-village-cascade-manor"
    }, {
        n: "Al Narah Apartments",
        s: "dubai-dubai-investment-park-al-narah-apartments"
    }, {
        n: "Porsche Design Towers",
        s: "dubai-business-bay-porsche-design-towers"
    }, {
        n: "Sobha Sapphire",
        s: "dubai-business-bay-sobha-sapphire"
    }, {
        n: "Residence 110",
        s: "dubai-business-bay-residence-110"
    }, {
        n: "Al Manara Tower",
        s: "dubai-business-bay-al-manara-tower"
    }, {
        n: "Scala Tower",
        s: "dubai-business-bay-scala-tower"
    }, {
        n: "Corporate Bay",
        s: "dubai-business-bay-corporate-bay"
    }, {
        n: "Al Batha Tower",
        s: "dubai-business-bay-al-batha-tower"
    }, {
        n: "The Bay Gate",
        s: "dubai-business-bay-the-bay-gate"
    }, {
        n: "Taj Dubai",
        s: "dubai-business-bay-taj-dubai"
    }, {
        n: "Sky Towers",
        s: "dubai-business-bay-sky-towers"
    }, {
        n: "The Pearl",
        s: "dubai-culture-village-the-pearl"
    }, {
        n: "The Estate Tower",
        s: "dubai-culture-village-the-estate-tower"
    }, {
        n: "Babil",
        s: "dubai-culture-village-babil"
    }, {
        n: "Nur Tower",
        s: "dubai-culture-village-nur-tower"
    }, {
        n: "La Mer",
        s: "dubai-culture-village-la-mer"
    }, {
        n: "Abu Baker Al Siddique Road",
        s: "dubai-deira-abu-baker-al-siddique-road"
    }, {
        n: "Riggat Al Buteen",
        s: "dubai-deira-riggat-al-buteen"
    }, {
        n: "Hor Al Anz",
        s: "dubai-deira-hor-al-anz"
    }, {
        n: "Badri Building",
        s: "dubai-deira-badri-building"
    }, {
        n: "Churchill Towers",
        s: "dubai-business-bay-churchill-towers"
    }, {
        n: "The Pad",
        s: "dubai-business-bay-the-pad"
    }, {
        n: "Executive Bay",
        s: "dubai-business-bay-executive-bay"
    }, {
        n: "Al Tayer Tower",
        s: "dubai-business-bay-al-tayer-tower"
    }, {
        n: "Park Central",
        s: "dubai-business-bay-park-central"
    }, {
        n: "Iris Amber",
        s: "dubai-culture-village-iris-amber"
    }, {
        n: "Dilan Tower",
        s: "dubai-culture-village-dilan-tower"
    }, {
        n: "Lotus Tower",
        s: "dubai-culture-village-lotus-tower"
    }, {
        n: "Yas",
        s: "dubai-culture-village-yas"
    }, {
        n: "Al Riqqa",
        s: "dubai-deira-al-riqqa"
    }, {
        n: "Salah Al Din Street",
        s: "dubai-deira-salah-al-din-street"
    }, {
        n: "Port Saeed",
        s: "dubai-deira-port-saeed"
    }, {
        n: "Avari Dubai Hotel",
        s: "dubai-deira-avari-dubai-hotel"
    }, {
        n: "Al Qayada Buiding",
        s: "dubai-deira-al-qayada-buiding"
    }, {
        n: "Baniyas Road",
        s: "dubai-deira-baniyas-road"
    }, {
        n: "Emaar Towers",
        s: "dubai-deira-emaar-towers"
    }, {
        n: "Maktoum Road",
        s: "dubai-deira-maktoum-road"
    }, {
        n: "Edge Creekside Hotel",
        s: "dubai-deira-edge-creekside-hotel"
    }, {
        n: "Escape Tower",
        s: "dubai-business-bay-escape-tower"
    }, {
        n: "Aykon City",
        s: "dubai-business-bay-aykon-city"
    }, {
        n: "Falcon Tower",
        s: "dubai-business-bay-falcon-tower"
    }, {
        n: "Art XV",
        s: "dubai-business-bay-art-xv"
    }, {
        n: "Executive Suites",
        s: "dubai-business-bay-executive-suites"
    }, {
        n: "Santeview",
        s: "dubai-culture-village-santeview"
    }, {
        n: "Niloofar Tower",
        s: "dubai-culture-village-niloofar-tower"
    }, {
        n: "Podium Tower",
        s: "dubai-culture-village-podium-tower"
    }, {
        n: "Nastaran",
        s: "dubai-culture-village-nastaran"
    }, {
        n: "Dubai Wharf Tower 2",
        s: "dubai-culture-village-dubai-wharf-tower-2"
    }, {
        n: "Al Ras",
        s: "dubai-deira-al-ras"
    }, {
        n: "Baniyas Square",
        s: "dubai-deira-baniyas-square"
    }, {
        n: "Al Murar",
        s: "dubai-deira-al-murar"
    }, {
        n: "Deira Island",
        s: "dubai-deira-deira-island"
    }, {
        n: "ICD Brookfield Place",
        s: "dubai-difc-icd-brookfield-place"
    }, {
        n: "Liberty House",
        s: "dubai-difc-liberty-house"
    }, {
        n: "Mogul Cluster",
        s: "dubai-discovery-gardens-mogul-cluster"
    }, {
        n: "Mövenpick Hotel Apartments",
        s: "dubai-downtown-dubai-movenpick-hotel-apartments"
    }, {
        n: "Paris Group Warehouse",
        s: "dubai-dubai-investment-park-paris-group-warehouse"
    }, {
        n: "Rhodi Residence",
        s: "dubai-culture-village-rhodi-residence"
    }, {
        n: "Cascade Ville",
        s: "dubai-culture-village-cascade-ville"
    }, {
        n: "Abu Hail",
        s: "dubai-deira-abu-hail"
    }, {
        n: "Nad Shamma",
        s: "dubai-deira-nad-shamma"
    }, {
        n: "Omar Bin Al Khattab Road",
        s: "dubai-deira-omar-bin-al-khattab-road"
    }, {
        n: "Hyatt Place Dubai Baniyas Square",
        s: "dubai-deira-hyatt-place-dubai-baniyas-square"
    }, {
        n: "International Airport Area",
        s: "dubai-deira-international-airport-area"
    }, {
        n: "Hyatt Place Dubai Al Rigga",
        s: "dubai-deira-hyatt-place-dubai-al-rigga"
    }, {
        n: "Park Towers",
        s: "dubai-difc-park-towers"
    }, {
        n: "Providence Tower",
        s: "dubai-difc-providence-tower"
    }, {
        n: "Gate Boulevard",
        s: "dubai-difc-gate-boulevard"
    }, {
        n: "Gate Village",
        s: "dubai-difc-gate-village"
    }, {
        n: "Central Park Tower",
        s: "dubai-difc-central-park-tower"
    }, {
        n: "Mesoamerican",
        s: "dubai-discovery-gardens-mesoamerican"
    }, {
        n: "Mediterranean Cluster",
        s: "dubai-discovery-gardens-mediterranean-cluster"
    }, {
        n: "HSBC Tower",
        s: "dubai-downtown-dubai-hsbc-tower"
    }, {
        n: "Al Badia Residences",
        s: "dubai-dubai-festival-city-al-badia-residences"
    }, {
        n: "Riah Towers",
        s: "dubai-culture-village-riah-towers"
    }, {
        n: "Al Khabisi",
        s: "dubai-deira-al-khabisi"
    }, {
        n: "Al Baraha",
        s: "dubai-deira-al-baraha"
    }, {
        n: "Corniche Deira",
        s: "dubai-deira-corniche-deira"
    }, {
        n: "Al Sabkha",
        s: "dubai-deira-al-sabkha"
    }, {
        n: "Pullman Dubai Creek City Centre Residences",
        s: "dubai-deira-pullman-dubai-creek-city-centre-residences"
    }, {
        n: "Al Nakhal Road",
        s: "dubai-deira-al-nakhal-road"
    }, {
        n: "ACICO Business Park",
        s: "dubai-deira-acico-business-park"
    }, {
        n: "Al Muteena",
        s: "dubai-deira-al-muteena"
    }, {
        n: "The Gate",
        s: "dubai-difc-the-gate"
    }, {
        n: "Lighthouse Tower",
        s: "dubai-difc-lighthouse-tower"
    }, {
        n: "DIFC Tower",
        s: "dubai-difc-difc-tower"
    }, {
        n: "Index Tower",
        s: "dubai-difc-index-tower"
    }, {
        n: "Terra Del Sol",
        s: "dubai-discovery-gardens-terra-del-sol"
    }, {
        n: "Zen Cluster",
        s: "dubai-discovery-gardens-zen-cluster"
    }, {
        n: "Dubai Lagoon",
        s: "dubai-dubai-investment-park-dubai-lagoon"
    }, {
        n: "Ewan Residences",
        s: "dubai-dubai-investment-park-ewan-residences"
    }, {
        n: "The Gate Precinct",
        s: "dubai-difc-the-gate-precinct"
    }, {
        n: "Currency House",
        s: "dubai-difc-currency-house"
    }, {
        n: "Limestone House",
        s: "dubai-difc-limestone-house"
    }, {
        n: "Rosewood Tower",
        s: "dubai-difc-rosewood-tower"
    }, {
        n: "Sky Gardens",
        s: "dubai-difc-sky-gardens"
    }, {
        n: "Ibn Battuta Gate",
        s: "dubai-discovery-gardens-ibn-battuta-gate"
    }, {
        n: "Cactus",
        s: "dubai-discovery-gardens-cactus"
    }, {
        n: "Bahwan Tower Downtown",
        s: "dubai-downtown-dubai-bahwan-tower-downtown"
    }, {
        n: "Burj Views",
        s: "dubai-downtown-dubai-burj-views"
    }, {
        n: "Standpoint Towers",
        s: "dubai-downtown-dubai-standpoint-towers"
    }, {
        n: "The Address Downtown Hotel",
        s: "dubai-downtown-dubai-the-address-downtown-hotel"
    }, {
        n: "Al Saaha",
        s: "dubai-downtown-dubai-al-saaha"
    }, {
        n: "Imperial Avenue",
        s: "dubai-downtown-dubai-imperial-avenue"
    }, {
        n: "Vida Residence Downtown",
        s: "dubai-downtown-dubai-vida-residence-downtown"
    }, {
        n: "Upper Crest",
        s: "dubai-downtown-dubai-upper-crest"
    }, {
        n: "Boulevard Point",
        s: "dubai-downtown-dubai-boulevard-point"
    }, {
        n: "29 Burj Boulevard",
        s: "dubai-downtown-dubai-29-burj-boulevard"
    }, {
        n: "Mohammad Bin Rashid Boulevard",
        s: "dubai-downtown-dubai-mohammad-bin-rashid-boulevard"
    }, {
        n: "Burj Crown",
        s: "dubai-downtown-dubai-burj-crown"
    }, {
        n: "Festival Tower",
        s: "dubai-dubai-festival-city-festival-tower"
    }, {
        n: "Al Waha Community",
        s: "dubai-dubai-festival-city-al-waha-community"
    }, {
        n: "Al Badia Hillside Village",
        s: "dubai-dubai-festival-city-al-badia-hillside-village"
    }, {
        n: "European Business Park",
        s: "dubai-dubai-investment-park-european-business-park"
    }, {
        n: "Telal's Tower",
        s: "dubai-dubai-media-city-telal-s-tower"
    }, {
        n: "Burj Daman",
        s: "dubai-difc-burj-daman"
    }, {
        n: "Discovery Gardens Pavilion",
        s: "dubai-discovery-gardens-discovery-gardens-pavilion"
    }, {
        n: "Contemporary Cluster",
        s: "dubai-discovery-gardens-contemporary-cluster"
    }, {
        n: "Forte",
        s: "dubai-downtown-dubai-forte"
    }, {
        n: "The Grand Boulevard",
        s: "dubai-downtown-dubai-the-grand-boulevard"
    }, {
        n: "Burj Royale",
        s: "dubai-downtown-dubai-burj-royale"
    }, {
        n: "Bellevue Towers",
        s: "dubai-downtown-dubai-bellevue-towers"
    }, {
        n: "The Address Dubai Mall",
        s: "dubai-downtown-dubai-the-address-dubai-mall"
    }, {
        n: "The Address Sky View Towers",
        s: "dubai-downtown-dubai-the-address-sky-view-towers"
    }, {
        n: "The Address The BLVD",
        s: "dubai-downtown-dubai-the-address-the-blvd"
    }, {
        n: "Damac Maison The Distinction",
        s: "dubai-downtown-dubai-damac-maison-the-distinction"
    }, {
        n: "Langham Place Downtown Dubai",
        s: "dubai-downtown-dubai-langham-place-downtown-dubai"
    }, {
        n: "Opera District",
        s: "dubai-downtown-dubai-opera-district"
    }, {
        n: "The Mansion",
        s: "dubai-downtown-dubai-the-mansion"
    }, {
        n: "The Lofts",
        s: "dubai-downtown-dubai-the-lofts"
    }, {
        n: "Vida Residences Dubai Mall",
        s: "dubai-downtown-dubai-vida-residences-dubai-mall"
    }, {
        n: "The Address Residences Dubai Opera",
        s: "dubai-downtown-dubai-the-address-residences-dubai-opera"
    }, {
        n: "Downtown Views",
        s: "dubai-downtown-dubai-downtown-views"
    }, {
        n: "Sky Business Centre",
        s: "dubai-dubai-festival-city-sky-business-centre"
    }, {
        n: "Intercontinental Residence Suites",
        s: "dubai-dubai-festival-city-intercontinental-residence-suites"
    }, {
        n: "Regent Business Park",
        s: "dubai-dubai-investment-park-regent-business-park"
    }, {
        n: "The Royal Estates",
        s: "dubai-dubai-investment-park-the-royal-estates"
    }, {
        n: "The Palisades",
        s: "dubai-dubai-investment-park-the-palisades"
    }, {
        n: "Bayan Business Center",
        s: "dubai-dubai-investment-park-bayan-business-center"
    }, {
        n: "FNC Complex Warehouse 1",
        s: "dubai-dubai-investment-park-fnc-complex-warehouse-1"
    }, {
        n: "Rowan Residence",
        s: "dubai-dubai-investment-park-rowan-residence"
    }, {
        n: "Arenco Apartments",
        s: "dubai-dubai-investment-park-arenco-apartments"
    }, {
        n: "Maisan Residence",
        s: "dubai-dubai-investment-park-maisan-residence"
    }, {
        n: "Noor Tower",
        s: "dubai-downtown-jebel-ali-noor-tower"
    }, {
        n: "World Wide Tower",
        s: "dubai-downtown-jebel-ali-world-wide-tower"
    }, {
        n: "Amir Tower",
        s: "dubai-downtown-jebel-ali-amir-tower"
    }, {
        n: "Image Residences",
        s: "dubai-downtown-jebel-ali-image-residences"
    }, {
        n: "Claren Towers",
        s: "dubai-downtown-dubai-claren-towers"
    }, {
        n: "BLVD Crescent",
        s: "dubai-downtown-dubai-blvd-crescent"
    }, {
        n: "Elite Downtown",
        s: "dubai-downtown-dubai-elite-downtown"
    }, {
        n: "Burj Al Nujoom",
        s: "dubai-downtown-dubai-burj-al-nujoom"
    }, {
        n: "Boulevard Plaza Towers",
        s: "dubai-downtown-dubai-boulevard-plaza-towers"
    }, {
        n: "DT1",
        s: "dubai-downtown-dubai-dt1"
    }, {
        n: "RP Heights",
        s: "dubai-downtown-dubai-rp-heights"
    }, {
        n: "Burj Vista",
        s: "dubai-downtown-dubai-burj-vista"
    }, {
        n: "Roda Al Murooj",
        s: "dubai-downtown-dubai-roda-al-murooj"
    }, {
        n: "The Address BLVD Sky Collection",
        s: "dubai-downtown-dubai-the-address-blvd-sky-collection"
    }, {
        n: "Downtown Views II",
        s: "dubai-downtown-dubai-downtown-views-ii"
    }, {
        n: "Four Seasons",
        s: "dubai-dubai-festival-city-four-seasons"
    }, {
        n: "Marsa Plaza",
        s: "dubai-dubai-festival-city-marsa-plaza"
    }, {
        n: "Al Sondos Apartments",
        s: "dubai-dubai-investment-park-al-sondos-apartments"
    }, {
        n: "Ritaj Tower",
        s: "dubai-dubai-investment-park-ritaj-tower"
    }, {
        n: "Schon Business Park",
        s: "dubai-dubai-investment-park-schon-business-park"
    }, {
        n: "Winterberry Residence",
        s: "dubai-dubai-investment-park-winterberry-residence"
    }, {
        n: "Gateways Apartments",
        s: "dubai-dubai-investment-park-gateways-apartments"
    }, {
        n: "The Centurion Residences",
        s: "dubai-dubai-investment-park-the-centurion-residences"
    }, {
        n: "Phase 2",
        s: "dubai-dubai-investment-park-phase-2"
    }, {
        n: "Creek Edge",
        s: "dubai-dubai-creek-harbour-the-lagoons-creek-edge"
    }, {
        n: "Madinat Jumeirah Living",
        s: "dubai-umm-suqeim-madinat-jumeirah-living"
    }, {
        n: "Cartel 222",
        s: "dubai-dubai-silicon-oasis-cartel-222"
    }, {
        n: "South Ridge",
        s: "dubai-downtown-dubai-south-ridge"
    }, {
        n: "Standard Chartered bank",
        s: "dubai-downtown-dubai-standard-chartered-bank"
    }, {
        n: "Boulevard Central Towers",
        s: "dubai-downtown-dubai-boulevard-central-towers"
    }, {
        n: "BLVD Heights",
        s: "dubai-downtown-dubai-blvd-heights"
    }, {
        n: "The Address Residence Fountain Views",
        s: "dubai-downtown-dubai-the-address-residence-fountain-views"
    }, {
        n: "Mada Residences by ARTAR",
        s: "dubai-downtown-dubai-mada-residences-by-artar"
    }, {
        n: "Mon Reve",
        s: "dubai-downtown-dubai-mon-reve"
    }, {
        n: "Festival Water Centre",
        s: "dubai-dubai-festival-city-festival-water-centre"
    }, {
        n: "Holiday Inn Dubai Festival City",
        s: "dubai-dubai-festival-city-holiday-inn-dubai-festival-city"
    }, {
        n: "Dunes Village",
        s: "dubai-dubai-investment-park-dunes-village"
    }, {
        n: "CEO Building",
        s: "dubai-dubai-investment-park-ceo-building"
    }, {
        n: "Falcon House",
        s: "dubai-dubai-investment-park-falcon-house"
    }, {
        n: "Al Manara Building",
        s: "dubai-dubai-investment-park-al-manara-building"
    }, {
        n: "UniEstate Mansion",
        s: "dubai-dubai-investment-park-uni-estate-mansion"
    }, {
        n: "Darwish Bin Ahmed Building",
        s: "dubai-dubai-investment-park-darwish-bin-ahmed-building"
    }, {
        n: "Phase 1",
        s: "dubai-dubai-investment-park-phase-1"
    }, {
        n: "Schon Suites",
        s: "dubai-downtown-jebel-ali-schon-suites"
    }, {
        n: "Azizi Aura",
        s: "dubai-downtown-jebel-ali-azizi-aura"
    }, {
        n: "Parkside Towers",
        s: "dubai-downtown-jebel-ali-parkside-towers"
    }, {
        n: "Salar Tower",
        s: "dubai-downtown-jebel-ali-salar-tower"
    }, {
        n: "The Galleries",
        s: "dubai-downtown-jebel-ali-the-galleries"
    }, {
        n: "Creek Palace",
        s: "dubai-dubai-creek-harbour-the-lagoons-creek-palace"
    }, {
        n: "Dubai Creek Residence Tower 2 North",
        s: "dubai-dubai-creek-harbour-the-lagoons-dubai-creek-residence-tower-2-north"
    }, {
        n: "Al Attar Skyspiral",
        s: "dubai-dubai-media-city-al-attar-skyspiral"
    }, {
        n: "Ava Residences",
        s: "dubai-dubai-investment-park-ava-residences"
    }, {
        n: "Al Haseen Residences",
        s: "dubai-dubai-investment-park-al-haseen-residences"
    }, {
        n: "Golden Sands DIP",
        s: "dubai-dubai-investment-park-golden-sands-dip"
    }, {
        n: "Suburbia",
        s: "dubai-downtown-jebel-ali-suburbia"
    }, {
        n: "Alfred Tower",
        s: "dubai-downtown-jebel-ali-alfred-tower"
    }, {
        n: "Prime Business Avenue",
        s: "dubai-downtown-jebel-ali-prime-business-avenue"
    }, {
        n: "Schon residences",
        s: "dubai-downtown-jebel-ali-schon-residences"
    }, {
        n: "The Grand",
        s: "dubai-dubai-creek-harbour-the-lagoons-the-grand"
    }, {
        n: "Harbour Views 1",
        s: "dubai-dubai-creek-harbour-the-lagoons-harbour-views-1"
    }, {
        n: "17 Icon Bay",
        s: "dubai-dubai-creek-harbour-the-lagoons-17-icon-bay"
    }, {
        n: "Address Harbour Point",
        s: "dubai-dubai-creek-harbour-the-lagoons-address-harbour-point"
    }, {
        n: "Dubai Creek Residence Tower 1 South",
        s: "dubai-dubai-creek-harbour-the-lagoons-dubai-creek-residence-tower-1-south"
    }, {
        n: "Creek Gate",
        s: "dubai-dubai-creek-harbour-the-lagoons-creek-gate"
    }, {
        n: "Al Waha Villas",
        s: "dubai-dubai-land-al-waha-villas"
    }, {
        n: "Villanova",
        s: "dubai-dubai-land-villanova"
    }, {
        n: "Bellagio Tower",
        s: "dubai-dubai-land-bellagio-tower"
    }, {
        n: "Nuaimi Residence",
        s: "dubai-dubai-land-nuaimi-residence"
    }, {
        n: "Maya 2",
        s: "dubai-dubai-land-maya-2"
    }, {
        n: "Knowledge Village",
        s: "dubai-dubai-media-city-knowledge-village"
    }, {
        n: "Mazoon House",
        s: "dubai-dubai-media-city-mazoon-house"
    }, {
        n: "Smart Heights",
        s: "dubai-dubai-media-city-smart-heights"
    }, {
        n: "Dalma Tower",
        s: "dubai-dubai-media-city-dalma-tower"
    }, {
        n: "Adel Al Hussaini Building",
        s: "dubai-dubai-media-city-adel-al-hussaini-building"
    }, {
        n: "Al Meera Complex",
        s: "dubai-dubai-media-city-al-meera-complex"
    }, {
        n: "Al Atina",
        s: "dubai-dubai-marina-al-atina"
    }, {
        n: "SOL Star",
        s: "dubai-dubai-investment-park-sol-star"
    }, {
        n: "Sienna Square",
        s: "dubai-downtown-jebel-ali-sienna-square"
    }, {
        n: "Deyaar Park",
        s: "dubai-downtown-jebel-ali-deyaar-park"
    }, {
        n: "Creekside 18",
        s: "dubai-dubai-creek-harbour-the-lagoons-creekside-18"
    }, {
        n: "Creek Heights",
        s: "dubai-dubai-creek-harbour-the-lagoons-creek-heights"
    }, {
        n: "Island Park 1",
        s: "dubai-dubai-creek-harbour-the-lagoons-island-park-1"
    }, {
        n: "Dubai Creek Residence Tower 1 North",
        s: "dubai-dubai-creek-harbour-the-lagoons-dubai-creek-residence-tower-1-north"
    }, {
        n: "Harbour Gate",
        s: "dubai-dubai-creek-harbour-the-lagoons-harbour-gate"
    }, {
        n: "Abidos Hotel Apartment",
        s: "dubai-dubai-land-abidos-hotel-apartment"
    }, {
        n: "Sanali Quantum",
        s: "dubai-dubai-land-sanali-quantum"
    }, {
        n: "Dubailand Oasis",
        s: "dubai-dubai-land-dubailand-oasis"
    }, {
        n: "Maysan Towers",
        s: "dubai-dubai-land-maysan-towers"
    }, {
        n: "Arjaan by Rotana",
        s: "dubai-dubai-media-city-arjaan-by-rotana"
    }, {
        n: "Rocks Villas",
        s: "dubai-dubai-media-city-rocks-villas"
    }, {
        n: "Two Seasons Hotel & Apartments",
        s: "dubai-dubai-media-city-two-seasons-hotel-apartments"
    }, {
        n: "Office Park",
        s: "dubai-dubai-media-city-office-park"
    }, {
        n: "GBS Building",
        s: "dubai-dubai-media-city-gbs-building"
    }, {
        n: "Al Hoton Building",
        s: "dubai-dubai-media-city-al-hoton-building"
    }, {
        n: "The LOFT Offices",
        s: "dubai-dubai-media-city-the-loft-offices"
    }, {
        n: "Sands Abir",
        s: "dubai-dubai-media-city-sands-abir"
    }, {
        n: "Al Sufouh Towers",
        s: "dubai-dubai-media-city-al-sufouh-towers"
    }, {
        n: "Umm Suqeim 3",
        s: "dubai-umm-suqeim-umm-suqeim-3"
    }, {
        n: "Capital Square",
        s: "dubai-downtown-jebel-ali-capital-square"
    }, {
        n: "KPM Twin Towers",
        s: "dubai-downtown-jebel-ali-kpm-twin-towers"
    }, {
        n: "Deyaar Business Park",
        s: "dubai-downtown-jebel-ali-deyaar-business-park"
    }, {
        n: "The Signet",
        s: "dubai-downtown-jebel-ali-the-signet"
    }, {
        n: "The cove",
        s: "dubai-dubai-creek-harbour-the-lagoons-the-cove"
    }, {
        n: "Dubai Creek Residence Tower 3 North",
        s: "dubai-dubai-creek-harbour-the-lagoons-dubai-creek-residence-tower-3-north"
    }, {
        n: "Dubai Creek Residence Tower 3 South",
        s: "dubai-dubai-creek-harbour-the-lagoons-dubai-creek-residence-tower-3-south"
    }, {
        n: "Creek Rise",
        s: "dubai-dubai-creek-harbour-the-lagoons-creek-rise"
    }, {
        n: "Skycourts Towers",
        s: "dubai-dubai-land-skycourts-towers"
    }, {
        n: "Liwan",
        s: "dubai-dubai-land-liwan"
    }, {
        n: "Sanali Flamingo",
        s: "dubai-dubai-land-sanali-flamingo"
    }, {
        n: "Windsor Residence",
        s: "dubai-dubai-land-windsor-residence"
    }, {
        n: "Rahaba Residences",
        s: "dubai-dubai-land-rahaba-residences"
    }, {
        n: "Zee Tower",
        s: "dubai-dubai-media-city-zee-tower"
    }, {
        n: "Sidra Tower",
        s: "dubai-dubai-media-city-sidra-tower"
    }, {
        n: "Arjaan Office Tower",
        s: "dubai-dubai-media-city-arjaan-office-tower"
    }, {
        n: "Mohammad Bin Hassan Al Muhana Bldg.",
        s: "dubai-dubai-media-city-mohammad-bin-hassan-al-muhana-bldg"
    }, {
        n: "Concord Tower",
        s: "dubai-dubai-media-city-concord-tower"
    }, {
        n: "Al Kazim Towers",
        s: "dubai-dubai-media-city-al-kazim-towers"
    }, {
        n: "Umm Suqeim 4",
        s: "dubai-umm-suqeim-umm-suqeim-4"
    }, {
        n: "Creek Horizon",
        s: "dubai-dubai-creek-harbour-the-lagoons-creek-horizon"
    }, {
        n: "Dubai Creek Residence Tower 2 South",
        s: "dubai-dubai-creek-harbour-the-lagoons-dubai-creek-residence-tower-2-south"
    }, {
        n: "Creek Beach",
        s: "dubai-dubai-creek-harbour-the-lagoons-creek-beach"
    }, {
        n: "Berlin Business Tower",
        s: "dubai-dubai-land-berlin-business-tower"
    }, {
        n: "Al Habtoor Polo Resort and Club - The Residences",
        s: "dubai-dubai-land-al-habtoor-polo-resort-and-club-the-residences"
    }, {
        n: "Cherrywoods",
        s: "dubai-dubai-land-cherrywoods"
    }, {
        n: "The Palmarosa",
        s: "dubai-dubai-land-the-palmarosa"
    }, {
        n: "Queue Point",
        s: "dubai-dubai-land-queue-point"
    }, {
        n: "Cordoba Residence",
        s: "dubai-dubai-media-city-cordoba-residence"
    }, {
        n: "Dubai Jewel Tower",
        s: "dubai-dubai-media-city-dubai-jewel-tower"
    }, {
        n: "Arenco Tower",
        s: "dubai-dubai-media-city-arenco-tower"
    }, {
        n: "The Icon",
        s: "dubai-dubai-media-city-the-icon"
    }, {
        n: "Tebyan Clarity Tower",
        s: "dubai-dubai-media-city-tebyan-clarity-tower"
    }, {
        n: "Wali Tower",
        s: "dubai-dubai-media-city-wali-tower"
    }, {
        n: "Business Central",
        s: "dubai-dubai-media-city-business-central"
    }, {
        n: "Khalid Salem Ahmed Basuliman Building",
        s: "dubai-dubai-media-city-khalid-salem-ahmed-basuliman-building"
    }, {
        n: "Omnix North Tower",
        s: "dubai-dubai-media-city-omnix-north-tower"
    }, {
        n: "Umm Suqeim Road",
        s: "dubai-umm-suqeim-umm-suqeim-road"
    }, {
        n: "Orra Harbour Residences and Hotel Apartments",
        s: "dubai-dubai-marina-orra-harbour-residences-and-hotel-apartments"
    }, {
        n: "The Jewels",
        s: "dubai-dubai-marina-the-jewels"
    }, {
        n: "DEC Towers",
        s: "dubai-dubai-marina-dec-towers"
    }, {
        n: "Sondos DLR 1",
        s: "dubai-dubai-land-sondos-dlr-1"
    }, {
        n: "Sanali Iconic",
        s: "dubai-dubai-land-sanali-iconic"
    }, {
        n: "Al Ruwayyah",
        s: "dubai-dubai-land-al-ruwayyah"
    }, {
        n: "Maya 3",
        s: "dubai-dubai-land-maya-3"
    }, {
        n: "Al Salam Grand Hotel Apartments",
        s: "dubai-dubai-media-city-al-salam-grand-hotel-apartments"
    }, {
        n: "Shatha Tower",
        s: "dubai-dubai-media-city-shatha-tower"
    }, {
        n: "Media one Tower",
        s: "dubai-dubai-media-city-media-one-tower"
    }, {
        n: "Atlas Business Centre",
        s: "dubai-dubai-media-city-atlas-business-centre"
    }, {
        n: "Omnix South Tower",
        s: "dubai-dubai-media-city-omnix-south-tower"
    }, {
        n: "Rashid Mohammed Al Mazroui Tower",
        s: "dubai-dubai-media-city-rashid-mohammed-al-mazroui-tower"
    }, {
        n: "ARY Digital Tower",
        s: "dubai-dubai-media-city-ary-digital-tower"
    }, {
        n: "Umm Suqeim 2",
        s: "dubai-umm-suqeim-umm-suqeim-2"
    }, {
        n: "Jumeirah Hills - The Palaces",
        s: "dubai-umm-suqeim-jumeirah-hills-the-palaces"
    }, {
        n: "TFG Marina Hotel",
        s: "dubai-dubai-marina-tfg-marina-hotel"
    }, {
        n: "The Waves",
        s: "dubai-dubai-marina-the-waves"
    }, {
        n: "Al Majara",
        s: "dubai-dubai-marina-al-majara"
    }, {
        n: "Oceanic",
        s: "dubai-dubai-marina-oceanic"
    }, {
        n: "The Radisson Blu Residence Dubai Marina",
        s: "dubai-dubai-marina-the-radisson-blu-residence-dubai-marina"
    }, {
        n: "Ary Marina View Tower",
        s: "dubai-dubai-marina-ary-marina-view-tower"
    }, {
        n: "Elite Residence",
        s: "dubai-dubai-marina-elite-residence"
    }, {
        n: "Horizon Tower",
        s: "dubai-dubai-marina-horizon-tower"
    }, {
        n: "La Riviera",
        s: "dubai-dubai-marina-la-riviera"
    }, {
        n: "Al Mass Villas",
        s: "dubai-dubai-marina-al-mass-villas"
    }, {
        n: "Sparkle Towers",
        s: "dubai-dubai-marina-sparkle-towers"
    }, {
        n: "Amber Residency",
        s: "dubai-umm-suqeim-amber-residency"
    }, {
        n: "The One Dubai Marina",
        s: "dubai-dubai-marina-the-one-dubai-marina"
    }, {
        n: "InterContinental Dubai Marina",
        s: "dubai-dubai-marina-inter-continental-dubai-marina"
    }, {
        n: "Dubai Marina Walk",
        s: "dubai-dubai-marina-dubai-marina-walk"
    }, {
        n: "Westside Marina",
        s: "dubai-dubai-marina-westside-marina"
    }, {
        n: "Dorra Bay",
        s: "dubai-dubai-marina-dorra-bay"
    }, {
        n: "Marina Suites",
        s: "dubai-dubai-marina-marina-suites"
    }, {
        n: "The Zen Tower",
        s: "dubai-dubai-marina-the-zen-tower"
    }, {
        n: "Trident Grand Residence",
        s: "dubai-dubai-marina-trident-grand-residence"
    }, {
        n: "Pier 7",
        s: "dubai-dubai-marina-pier-7"
    }, {
        n: "5242",
        s: "dubai-dubai-marina-5242"
    }, {
        n: "Grosvenor House",
        s: "dubai-dubai-marina-grosvenor-house"
    }, {
        n: "The Light House",
        s: "dubai-dubai-marina-the-light-house"
    }, {
        n: "Marina View",
        s: "dubai-dubai-marina-marina-view"
    }, {
        n: "JAM Marina Residence",
        s: "dubai-dubai-marina-jam-marina-residence"
    }, {
        n: "Le Grande Community Mall",
        s: "dubai-dubai-marina-le-grande-community-mall"
    }, {
        n: "Gargash Tower",
        s: "dubai-dubai-marina-gargash-tower"
    }, {
        n: "Marina Tower",
        s: "dubai-dubai-marina-marina-tower"
    }, {
        n: "Opal Tower Marina",
        s: "dubai-dubai-marina-opal-tower-marina"
    }, {
        n: "Panoramic Tower",
        s: "dubai-dubai-marina-panoramic-tower"
    }, {
        n: "Al Habtoor Business Tower",
        s: "dubai-dubai-marina-al-habtoor-business-tower"
    }, {
        n: "Marina Park",
        s: "dubai-dubai-marina-marina-park"
    }, {
        n: "Silverene",
        s: "dubai-dubai-marina-silverene"
    }, {
        n: "SAAM Vega",
        s: "dubai-falcon-city-of-wonders-saam-vega"
    }, {
        n: "Bay Central",
        s: "dubai-dubai-marina-bay-central"
    }, {
        n: "Dream Towers",
        s: "dubai-dubai-marina-dream-towers"
    }, {
        n: "23 Marina",
        s: "dubai-dubai-marina-23-marina"
    }, {
        n: "Emirates Crown",
        s: "dubai-dubai-marina-emirates-crown"
    }, {
        n: "KG Tower",
        s: "dubai-dubai-marina-kg-tower"
    }, {
        n: "Pier 8",
        s: "dubai-dubai-marina-pier-8"
    }, {
        n: "Zumurud Tower",
        s: "dubai-dubai-marina-zumurud-tower"
    }, {
        n: "Jannah Place Dubai Marina",
        s: "dubai-dubai-marina-jannah-place-dubai-marina"
    }, {
        n: "Marina Quays",
        s: "dubai-dubai-marina-marina-quays"
    }, {
        n: "Pier 24",
        s: "dubai-dubai-marina-pier-24"
    }, {
        n: "Stella Maris",
        s: "dubai-dubai-marina-stella-maris"
    }, {
        n: "Al Zarooni Building",
        s: "dubai-dubai-marina-al-zarooni-building"
    }, {
        n: "Al Marjan Villas",
        s: "dubai-dubai-marina-al-marjan-villas"
    }, {
        n: "The Address Dubai Marina",
        s: "dubai-dubai-marina-the-address-dubai-marina"
    }, {
        n: "KPM Tower",
        s: "dubai-dubai-marina-kpm-tower"
    }, {
        n: "Al Fardan Tower",
        s: "dubai-dubai-marina-al-fardan-tower"
    }, {
        n: "Emaar 6 Towers",
        s: "dubai-dubai-marina-emaar-6-towers"
    }, {
        n: "Marina Terrace",
        s: "dubai-dubai-marina-marina-terrace"
    }, {
        n: "Marina Arcade Tower",
        s: "dubai-dubai-marina-marina-arcade-tower"
    }, {
        n: "Shahla Tower",
        s: "dubai-dubai-marina-shahla-tower"
    }, {
        n: "West Avenue Tower",
        s: "dubai-dubai-marina-west-avenue-tower"
    }, {
        n: "Marina Gate",
        s: "dubai-dubai-marina-marina-gate"
    }, {
        n: "Dream Tower",
        s: "dubai-dubai-marina-dream-tower"
    }, {
        n: "Manchester Tower",
        s: "dubai-dubai-marina-manchester-tower"
    }, {
        n: "Marina Mansions",
        s: "dubai-dubai-marina-marina-mansions"
    }, {
        n: "Dusit Residence Dubai Marina",
        s: "dubai-dubai-marina-dusit-residence-dubai-marina"
    }, {
        n: "Marina Promenade",
        s: "dubai-dubai-marina-marina-promenade"
    }, {
        n: "Al Seef Tower",
        s: "dubai-dubai-marina-al-seef-tower"
    }, {
        n: "Sulafa Tower",
        s: "dubai-dubai-marina-sulafa-tower"
    }, {
        n: "Marina 101",
        s: "dubai-dubai-marina-marina-101"
    }, {
        n: "The Torch",
        s: "dubai-dubai-marina-the-torch"
    }, {
        n: "Al Husn Marina",
        s: "dubai-dubai-marina-al-husn-marina"
    }, {
        n: "Supreme Residency",
        s: "dubai-dubai-marina-supreme-residency"
    }, {
        n: "Casa Del Mar",
        s: "dubai-dubai-marina-casa-del-mar"
    }, {
        n: "Marina Plaza",
        s: "dubai-dubai-marina-marina-plaza"
    }, {
        n: "Casa De Sol",
        s: "dubai-dubai-marina-casa-de-sol"
    }, {
        n: "ARJ Tower",
        s: "dubai-dubai-marina-arj-tower"
    }, {
        n: "Damac Residenze",
        s: "dubai-dubai-marina-damac-residenze"
    }, {
        n: "Marina Diamonds",
        s: "dubai-dubai-marina-marina-diamonds"
    }, {
        n: "Cascades Tower",
        s: "dubai-dubai-marina-cascades-tower"
    }, {
        n: "Pacific Tower",
        s: "dubai-dubai-marina-pacific-tower"
    }, {
        n: "Jannah Marina Bay Suites",
        s: "dubai-dubai-marina-jannah-marina-bay-suites"
    }, {
        n: "Ocean Heights",
        s: "dubai-dubai-marina-ocean-heights"
    }, {
        n: "La Residencia Del Mar",
        s: "dubai-dubai-marina-la-residencia-del-mar"
    }, {
        n: "Le Reve",
        s: "dubai-dubai-marina-le-reve"
    }, {
        n: "Marina Pearl",
        s: "dubai-dubai-marina-marina-pearl"
    }, {
        n: "Escan Tower",
        s: "dubai-dubai-marina-escan-tower"
    }, {
        n: "Roshana Tower",
        s: "dubai-dubai-marina-roshana-tower"
    }, {
        n: "Marina Crown",
        s: "dubai-dubai-marina-marina-crown"
    }, {
        n: "Coral Residence",
        s: "dubai-dubai-silicon-oasis-coral-residence"
    }, {
        n: "German Business Park",
        s: "dubai-dubai-silicon-oasis-german-business-park"
    }, {
        n: "Marina Residence",
        s: "dubai-dubai-marina-marina-residence"
    }, {
        n: "Sukoon Tower",
        s: "dubai-dubai-marina-sukoon-tower"
    }, {
        n: "Azure",
        s: "dubai-dubai-marina-azure"
    }, {
        n: "Skyview Tower",
        s: "dubai-dubai-marina-skyview-tower"
    }, {
        n: "Iris Blue",
        s: "dubai-dubai-marina-iris-blue"
    }, {
        n: "Marina Sail",
        s: "dubai-dubai-marina-marina-sail"
    }, {
        n: "The Summit",
        s: "dubai-dubai-marina-the-summit"
    }, {
        n: "Time Place Tower",
        s: "dubai-dubai-marina-time-place-tower"
    }, {
        n: "Orra Marina",
        s: "dubai-dubai-marina-orra-marina"
    }, {
        n: "Trident Waterfront",
        s: "dubai-dubai-marina-trident-waterfront"
    }, {
        n: "Lootah Complex",
        s: "dubai-dubai-marina-lootah-complex"
    }, {
        n: "Ciel Tower",
        s: "dubai-dubai-marina-ciel-tower"
    }, {
        n: "Safeer Tower",
        s: "dubai-dubai-marina-safeer-tower"
    }, {
        n: "Supreme Tower",
        s: "dubai-dubai-marina-supreme-tower"
    }, {
        n: "Marina Star",
        s: "dubai-dubai-marina-marina-star"
    }, {
        n: "Extreme Waterfront Offices",
        s: "dubai-dubai-marina-extreme-waterfront-offices"
    }, {
        n: "Pearl Marina Hotel Apartments",
        s: "dubai-dubai-marina-pearl-marina-hotel-apartments"
    }, {
        n: "The Point",
        s: "dubai-dubai-marina-the-point"
    }, {
        n: "Marina Pinnacle",
        s: "dubai-dubai-marina-marina-pinnacle"
    }, {
        n: "Cayan Tower",
        s: "dubai-dubai-marina-cayan-tower"
    }, {
        n: "Wyndham Dubai Marina",
        s: "dubai-dubai-marina-wyndham-dubai-marina"
    }, {
        n: "Continental Tower",
        s: "dubai-dubai-marina-continental-tower"
    }, {
        n: "Al Habtoor Tower",
        s: "dubai-dubai-marina-al-habtoor-tower"
    }, {
        n: "Al Fairooz Villas",
        s: "dubai-dubai-marina-al-fairooz-villas"
    }, {
        n: "Eden Blue Tower",
        s: "dubai-dubai-marina-eden-blue-tower"
    }, {
        n: "Trident Bayside",
        s: "dubai-dubai-marina-trident-bayside"
    }, {
        n: "Ariyana Tower",
        s: "dubai-dubai-marina-ariyana-tower"
    }, {
        n: "LIV Residence",
        s: "dubai-dubai-marina-liv-residence"
    }, {
        n: "Marina 106",
        s: "dubai-dubai-marina-marina-106"
    }, {
        n: "My Tower",
        s: "dubai-dubai-marina-my-tower"
    }, {
        n: "Harbour Residence",
        s: "dubai-dubai-marina-harbour-residence"
    }, {
        n: "Marriott Harbour Hotel and Suites",
        s: "dubai-dubai-marina-marriott-harbour-hotel-and-suites"
    }, {
        n: "Al Areifi Marina Tower",
        s: "dubai-dubai-marina-al-areifi-marina-tower"
    }, {
        n: "Signature Hotel Apartments & Spa Marina",
        s: "dubai-dubai-marina-signature-hotel-apartments-spa-marina"
    }, {
        n: "Barcelo Residences",
        s: "dubai-dubai-marina-barcelo-residences"
    }, {
        n: "Princess Tower",
        s: "dubai-dubai-marina-princess-tower"
    }, {
        n: "Marina Heights",
        s: "dubai-dubai-marina-marina-heights"
    }, {
        n: "Botanica Tower",
        s: "dubai-dubai-marina-botanica-tower"
    }, {
        n: "Bunyan Tower",
        s: "dubai-dubai-marina-bunyan-tower"
    }, {
        n: "Pentominium",
        s: "dubai-dubai-marina-pentominium"
    }, {
        n: "Damac Heights",
        s: "dubai-dubai-marina-damac-heights"
    }, {
        n: "Al Mesk Villas",
        s: "dubai-dubai-marina-al-mesk-villas"
    }, {
        n: "Al Sahab",
        s: "dubai-dubai-marina-al-sahab"
    }, {
        n: "Al Dua'a Marina Tower",
        s: "dubai-dubai-marina-al-dua-a-marina-tower"
    }, {
        n: "Al Shebani Residence",
        s: "dubai-dubai-marina-al-shebani-residence"
    }, {
        n: "Apricot",
        s: "dubai-dubai-silicon-oasis-apricot"
    }, {
        n: "Ruby Residence",
        s: "dubai-dubai-silicon-oasis-ruby-residence"
    }, {
        n: "Al Nayli Building",
        s: "dubai-dubai-silicon-oasis-al-nayli-building"
    }, {
        n: "The Belvedere",
        s: "dubai-dubai-marina-the-belvedere"
    }, {
        n: "Al Anbar Villas",
        s: "dubai-dubai-marina-al-anbar-villas"
    }, {
        n: "Vida Residences Dubai Marina",
        s: "dubai-dubai-marina-vida-residences-dubai-marina"
    }, {
        n: "No.9",
        s: "dubai-dubai-marina-no-9"
    }, {
        n: "Studio One",
        s: "dubai-dubai-marina-studio-one"
    }, {
        n: "Silicon Gates",
        s: "dubai-dubai-silicon-oasis-silicon-gates"
    }, {
        n: "Jade Residence",
        s: "dubai-dubai-silicon-oasis-jade-residence"
    }, {
        n: "Le Presidium",
        s: "dubai-dubai-silicon-oasis-le-presidium"
    }, {
        n: "Al Hathboor DSO",
        s: "dubai-dubai-silicon-oasis-al-hathboor-dso"
    }, {
        n: "Topaz Premium Residences",
        s: "dubai-dubai-silicon-oasis-topaz-premium-residences"
    }, {
        n: "Cordoba Palace",
        s: "dubai-dubai-silicon-oasis-cordoba-palace"
    }, {
        n: "Lynx Residence",
        s: "dubai-dubai-silicon-oasis-lynx-residence"
    }, {
        n: "SIT Tower",
        s: "dubai-dubai-silicon-oasis-sit-tower"
    }, {
        n: "La Vista Residence",
        s: "dubai-dubai-silicon-oasis-la-vista-residence"
    }, {
        n: "Topaz Residences 3",
        s: "dubai-dubai-silicon-oasis-topaz-residences-3"
    }, {
        n: "SP Oasis",
        s: "dubai-dubai-silicon-oasis-sp-oasis"
    }, {
        n: "Ocean Residencia",
        s: "dubai-dubai-silicon-oasis-ocean-residencia"
    }, {
        n: "Palace Towers",
        s: "dubai-dubai-silicon-oasis-palace-towers"
    }, {
        n: "City Tower 1 DSO",
        s: "dubai-dubai-silicon-oasis-city-tower-1-dso"
    }, {
        n: "Narcissus Building",
        s: "dubai-dubai-silicon-oasis-narcissus-building"
    }, {
        n: "The Icon",
        s: "dubai-dubai-silicon-oasis-the-icon"
    }, {
        n: "Donna Towers",
        s: "dubai-dubai-silicon-oasis-donna-towers"
    }, {
        n: "Binghatti Gardens",
        s: "dubai-dubai-silicon-oasis-binghatti-gardens"
    }, {
        n: "ASB Tower",
        s: "dubai-dubai-silicon-oasis-asb-tower"
    }, {
        n: "API Silicon Residency",
        s: "dubai-dubai-silicon-oasis-api-silicon-residency"
    }, {
        n: "Binghatti Sapphires",
        s: "dubai-dubai-silicon-oasis-binghatti-sapphires"
    }, {
        n: "Sunrise Building",
        s: "dubai-dubai-silicon-oasis-sunrise-building"
    }, {
        n: "Al Hikma Residence",
        s: "dubai-dubai-silicon-oasis-al-hikma-residence"
    }, {
        n: "Nibras Oasis 1",
        s: "dubai-dubai-silicon-oasis-nibras-oasis-1"
    }, {
        n: "The Dunes",
        s: "dubai-dubai-silicon-oasis-the-dunes"
    }, {
        n: "Arabian Gates",
        s: "dubai-dubai-silicon-oasis-arabian-gates"
    }, {
        n: "Silicon Star",
        s: "dubai-dubai-silicon-oasis-silicon-star"
    }, {
        n: "Uniestate Millennium Tower",
        s: "dubai-dubai-silicon-oasis-uniestate-millennium-tower"
    }, {
        n: "Sevanam Crown",
        s: "dubai-dubai-silicon-oasis-sevanam-crown"
    }, {
        n: "Suntech Tower",
        s: "dubai-dubai-silicon-oasis-suntech-tower"
    }, {
        n: "The Blue Oasis",
        s: "dubai-dubai-silicon-oasis-the-blue-oasis"
    }, {
        n: "Binghatti Platinum",
        s: "dubai-dubai-silicon-oasis-binghatti-platinum"
    }, {
        n: "Al Liwan Building",
        s: "dubai-dubai-silicon-oasis-al-liwan-building"
    }, {
        n: "Al Thuraya Building",
        s: "dubai-dubai-silicon-oasis-al-thuraya-building"
    }, {
        n: "Al Jawhara",
        s: "dubai-dubai-silicon-oasis-al-jawhara"
    }, {
        n: "Venezia Residence",
        s: "dubai-dubai-silicon-oasis-venezia-residence"
    }, {
        n: "Silicon Park",
        s: "dubai-dubai-silicon-oasis-silicon-park"
    }, {
        n: "National Bonds Oasis",
        s: "dubai-dubai-silicon-oasis-national-bonds-oasis"
    }, {
        n: "Binghatti Views",
        s: "dubai-dubai-silicon-oasis-binghatti-views"
    }, {
        n: "Nibras Oasis 2",
        s: "dubai-dubai-silicon-oasis-nibras-oasis-2"
    }, {
        n: "Cedre Villas",
        s: "dubai-dubai-silicon-oasis-cedre-villas"
    }, {
        n: "Al Asmawi",
        s: "dubai-dubai-silicon-oasis-al-asmawi"
    }, {
        n: "Silicon Avenue",
        s: "dubai-dubai-silicon-oasis-silicon-avenue"
    }, {
        n: "Sapphire Oasis",
        s: "dubai-dubai-silicon-oasis-sapphire-oasis"
    }, {
        n: "Emerald Residence",
        s: "dubai-dubai-silicon-oasis-emerald-residence"
    }, {
        n: "Toscana",
        s: "dubai-dubai-lifestyle-city-toscana"
    }, {
        n: "Elite Sports Residence",
        s: "dubai-dubai-sports-city-elite-sports-residence"
    }, {
        n: "Chess Tower",
        s: "dubai-dubai-sports-city-chess-tower"
    }, {
        n: "Soccer Tower",
        s: "dubai-dubai-sports-city-soccer-tower"
    }, {
        n: "Nova Tower",
        s: "dubai-dubai-silicon-oasis-nova-tower"
    }, {
        n: "Binghatti Stars",
        s: "dubai-dubai-silicon-oasis-binghatti-stars"
    }, {
        n: "Saima Heights",
        s: "dubai-dubai-silicon-oasis-saima-heights"
    }, {
        n: "Spring Oasis",
        s: "dubai-dubai-silicon-oasis-spring-oasis"
    }, {
        n: "Polo",
        s: "dubai-dubai-silicon-oasis-polo"
    }, {
        n: "Sunshine Residence",
        s: "dubai-dubai-silicon-oasis-sunshine-residence"
    }, {
        n: "DHP Residency",
        s: "dubai-dubai-silicon-oasis-dhp-residency"
    }, {
        n: "Oasis High Park",
        s: "dubai-dubai-silicon-oasis-oasis-high-park"
    }, {
        n: "Topaz Residences",
        s: "dubai-dubai-silicon-oasis-topaz-residences"
    }, {
        n: "Platinum Residence 2",
        s: "dubai-dubai-silicon-oasis-platinum-residence-2"
    }, {
        n: "Al Sondos Oasis",
        s: "dubai-dubai-silicon-oasis-al-sondos-oasis"
    }, {
        n: "ART X",
        s: "dubai-dubai-silicon-oasis-art-x"
    }, {
        n: "Imperial Residence",
        s: "dubai-dubai-silicon-oasis-imperial-residence"
    }, {
        n: "Sapphire Residence",
        s: "dubai-dubai-silicon-oasis-sapphire-residence"
    }, {
        n: "ART IX",
        s: "dubai-dubai-silicon-oasis-art-ix"
    }, {
        n: "Tizzano",
        s: "dubai-dubai-lifestyle-city-tizzano"
    }, {
        n: "UniEstate Sports Tower",
        s: "dubai-dubai-sports-city-uni-estate-sports-tower"
    }, {
        n: "Coral International Hotel Apartment",
        s: "dubai-dubai-sports-city-coral-international-hotel-apartment"
    }, {
        n: "The Cube",
        s: "dubai-dubai-sports-city-the-cube"
    }, {
        n: "Bermuda Views",
        s: "dubai-dubai-sports-city-bermuda-views"
    }, {
        n: "Profile Residence",
        s: "dubai-dubai-sports-city-profile-residence"
    }, {
        n: "Sports One Tower",
        s: "dubai-dubai-sports-city-sports-one-tower"
    }, {
        n: "Chapal The Glory",
        s: "dubai-dubai-sports-city-chapal-the-glory"
    }, {
        n: "V2",
        s: "dubai-dubai-sports-city-v2"
    }, {
        n: "Canal Residence",
        s: "dubai-dubai-sports-city-canal-residence"
    }, {
        n: "The Destiny Tower 1",
        s: "dubai-dubai-sports-city-the-destiny-tower-1"
    }, {
        n: "Limelight Twin Towers",
        s: "dubai-dubai-sports-city-limelight-twin-towers"
    }, {
        n: "Royal Residence",
        s: "dubai-dubai-sports-city-royal-residence"
    }, {
        n: "The Matrix",
        s: "dubai-dubai-sports-city-the-matrix"
    }, {
        n: "Tennis Tower",
        s: "dubai-dubai-sports-city-tennis-tower"
    }, {
        n: "Almasah Tower",
        s: "dubai-falcon-city-of-wonders-almasah-tower"
    }, {
        n: "Le Solarium",
        s: "dubai-dubai-silicon-oasis-le-solarium"
    }, {
        n: "Al Falak Residence",
        s: "dubai-dubai-silicon-oasis-al-falak-residence"
    }, {
        n: "Semmer Villas",
        s: "dubai-dubai-silicon-oasis-semmer-villas"
    }, {
        n: "Binghatti Crystals",
        s: "dubai-dubai-silicon-oasis-binghatti-crystals"
    }, {
        n: "Iliyaa 3",
        s: "dubai-dubai-silicon-oasis-iliyaa-3"
    }, {
        n: "Silicon Heights",
        s: "dubai-dubai-silicon-oasis-silicon-heights"
    }, {
        n: "Binghatti Horizons",
        s: "dubai-dubai-silicon-oasis-binghatti-horizons"
    }, {
        n: "Silicon Residence 2",
        s: "dubai-dubai-silicon-oasis-silicon-residence-2"
    }, {
        n: "Palacio Tower",
        s: "dubai-dubai-silicon-oasis-palacio-tower"
    }, {
        n: "Cambridge Business Centre",
        s: "dubai-dubai-silicon-oasis-cambridge-business-centre"
    }, {
        n: "IT Plaza",
        s: "dubai-dubai-silicon-oasis-it-plaza"
    }, {
        n: "Amna Building",
        s: "dubai-dubai-silicon-oasis-amna-building"
    }, {
        n: "Arabian Gate",
        s: "dubai-dubai-silicon-oasis-arabian-gate"
    }, {
        n: "Eagle Heights",
        s: "dubai-dubai-sports-city-eagle-heights"
    }, {
        n: "Frankfurt Tower",
        s: "dubai-dubai-sports-city-frankfurt-tower"
    }, {
        n: "Hamza Tower",
        s: "dubai-dubai-sports-city-hamza-tower"
    }, {
        n: "The Medalist",
        s: "dubai-dubai-sports-city-the-medalist"
    }, {
        n: "Gateway Tower",
        s: "dubai-dubai-sports-city-gateway-tower"
    }, {
        n: "Spanish Tower",
        s: "dubai-dubai-sports-city-spanish-tower"
    }, {
        n: "Links View",
        s: "dubai-dubai-sports-city-links-view"
    }, {
        n: "Stadium Point",
        s: "dubai-dubai-sports-city-stadium-point"
    }, {
        n: "The Destiny Tower 2",
        s: "dubai-dubai-sports-city-the-destiny-tower-2"
    }, {
        n: "Golden Tulip Hotel Apartment",
        s: "dubai-dubai-sports-city-golden-tulip-hotel-apartment"
    }, {
        n: "Rufi Park View",
        s: "dubai-dubai-sports-city-rufi-park-view"
    }, {
        n: "Zenith Towers",
        s: "dubai-dubai-sports-city-zenith-towers"
    }, {
        n: "Wimbledon Tower",
        s: "dubai-dubai-sports-city-wimbledon-tower"
    }, {
        n: "Al Telal 10",
        s: "dubai-dubai-silicon-oasis-al-telal-10"
    }, {
        n: "Mirage Residence",
        s: "dubai-dubai-silicon-oasis-mirage-residence"
    }, {
        n: "Axis Residence",
        s: "dubai-dubai-silicon-oasis-axis-residence"
    }, {
        n: "Altia Residence",
        s: "dubai-dubai-silicon-oasis-altia-residence"
    }, {
        n: "Binghatti Diamonds",
        s: "dubai-dubai-silicon-oasis-binghatti-diamonds"
    }, {
        n: "Silicon Oasis Headquarters",
        s: "dubai-dubai-silicon-oasis-silicon-oasis-headquarters"
    }, {
        n: "Arabian Heights",
        s: "dubai-dubai-silicon-oasis-arabian-heights"
    }, {
        n: "Park Terrace",
        s: "dubai-dubai-silicon-oasis-park-terrace"
    }, {
        n: "The LYNX",
        s: "dubai-dubai-silicon-oasis-the-lynx"
    }, {
        n: "Azure Residences",
        s: "dubai-dubai-silicon-oasis-azure-residences"
    }, {
        n: "Hera Tower",
        s: "dubai-dubai-sports-city-hera-tower"
    }, {
        n: "Cricket Tower",
        s: "dubai-dubai-sports-city-cricket-tower"
    }, {
        n: "Golf Tower",
        s: "dubai-dubai-sports-city-golf-tower"
    }, {
        n: "Arena Mall",
        s: "dubai-dubai-sports-city-arena-mall"
    }, {
        n: "Olympic Park Towers",
        s: "dubai-dubai-sports-city-olympic-park-towers"
    }, {
        n: "Bangash Residence",
        s: "dubai-dubai-sports-city-bangash-residence"
    }, {
        n: "Global Golf Residences 2",
        s: "dubai-dubai-sports-city-global-golf-residences-2"
    }, {
        n: "The Bridge",
        s: "dubai-dubai-sports-city-the-bridge"
    }, {
        n: "Red Residency",
        s: "dubai-dubai-sports-city-red-residency"
    }, {
        n: "Grand Horizon",
        s: "dubai-dubai-sports-city-grand-horizon"
    }, {
        n: "Najma Towers",
        s: "dubai-dubai-sports-city-najma-towers"
    }, {
        n: "Champions Towers",
        s: "dubai-dubai-sports-city-champions-towers"
    }, {
        n: "The Spirit",
        s: "dubai-dubai-sports-city-the-spirit"
    }, {
        n: "Ice Hockey",
        s: "dubai-dubai-sports-city-ice-hockey"
    }, {
        n: "Oasis Tower 2",
        s: "dubai-dubai-sports-city-oasis-tower-2"
    }, {
        n: "Town Of Venice",
        s: "dubai-falcon-city-of-wonders-town-of-venice"
    }, {
        n: "1 JBR",
        s: "dubai-jumeirah-beach-residence-1-jbr"
    }, {
        n: "City Oasis",
        s: "dubai-dubai-silicon-oasis-city-oasis"
    }, {
        n: "University View",
        s: "dubai-dubai-silicon-oasis-university-view"
    }, {
        n: "Zarooni Building",
        s: "dubai-dubai-silicon-oasis-zarooni-building"
    }, {
        n: "Axis silver 1",
        s: "dubai-dubai-silicon-oasis-axis-silver-1"
    }, {
        n: "Binghatti Apartments",
        s: "dubai-dubai-silicon-oasis-binghatti-apartments"
    }, {
        n: "The White Palace",
        s: "dubai-dubai-silicon-oasis-the-white-palace"
    }, {
        n: "Binghatti Pearls",
        s: "dubai-dubai-silicon-oasis-binghatti-pearls"
    }, {
        n: "S-Residence",
        s: "dubai-dubai-silicon-oasis-s-residence"
    }, {
        n: "Ribbon Of Light building",
        s: "dubai-dubai-silicon-oasis-ribbon-of-light-building"
    }, {
        n: "Silicon Arch",
        s: "dubai-dubai-silicon-oasis-silicon-arch"
    }, {
        n: "Sienna",
        s: "dubai-dubai-lifestyle-city-sienna"
    }, {
        n: "Eden Garden",
        s: "dubai-dubai-sports-city-eden-garden"
    }, {
        n: "Century Tower",
        s: "dubai-dubai-sports-city-century-tower"
    }, {
        n: "Oasis Tower 1",
        s: "dubai-dubai-sports-city-oasis-tower-1"
    }, {
        n: "Hub-Golf Towers",
        s: "dubai-dubai-sports-city-hub-golf-towers"
    }, {
        n: "Rufy Residency",
        s: "dubai-dubai-sports-city-rufy-residency"
    }, {
        n: "Park View",
        s: "dubai-dubai-sports-city-park-view"
    }, {
        n: "Kensington Royal",
        s: "dubai-dubai-sports-city-kensington-royal"
    }, {
        n: "Treppan Hotel & Suites by Fakhruddin",
        s: "dubai-dubai-sports-city-treppan-hotel-suites-by-fakhruddin"
    }, {
        n: "Prime villa",
        s: "dubai-dubai-sports-city-prime-villa"
    }, {
        n: "Giovanni Boutique Suites",
        s: "dubai-dubai-sports-city-giovanni-boutique-suites"
    }, {
        n: "JS Tower",
        s: "dubai-dubai-sports-city-js-tower"
    }, {
        n: "Bloomingdale",
        s: "dubai-dubai-sports-city-bloomingdale"
    }, {
        n: "Victory Heights",
        s: "dubai-dubai-sports-city-victory-heights"
    }, {
        n: "The Arena Apartments",
        s: "dubai-dubai-sports-city-the-arena-apartments"
    }, {
        n: "German Sports Towers",
        s: "dubai-dubai-sports-city-german-sports-towers"
    }, {
        n: "Shami Sports Tower",
        s: "dubai-dubai-sports-city-shami-sports-tower"
    }, {
        n: "Golf View Residence",
        s: "dubai-dubai-sports-city-golf-view-residence"
    }, {
        n: "Universal Tower",
        s: "dubai-dubai-sports-city-universal-tower"
    }, {
        n: "Twin Towers",
        s: "dubai-dubai-sports-city-twin-towers"
    }, {
        n: "The Diamond",
        s: "dubai-dubai-sports-city-the-diamond"
    }, {
        n: "Dubai Taj Mahal",
        s: "dubai-falcon-city-of-wonders-dubai-taj-mahal"
    }, {
        n: "Western Residence South",
        s: "dubai-falcon-city-of-wonders-western-residence-south"
    }, {
        n: "Al Nakheel",
        s: "dubai-greens-al-nakheel"
    }, {
        n: "Green Community West",
        s: "dubai-green-community-green-community-west"
    }, {
        n: "Garden East Apartments",
        s: "dubai-green-community-garden-east-apartments"
    }, {
        n: "Lakeside Residence",
        s: "dubai-dubai-production-city-impz-lakeside-residence"
    }, {
        n: "Hotel IMPZ-027",
        s: "dubai-dubai-production-city-impz-hotel-impz-027"
    }, {
        n: "Zaki Building",
        s: "dubai-dubai-production-city-impz-zaki-building"
    }, {
        n: "Qasr Sabah",
        s: "dubai-dubai-production-city-impz-qasr-sabah"
    }, {
        n: "SOL Golf Views",
        s: "dubai-dubai-production-city-impz-sol-golf-views"
    }, {
        n: "Persia Cluster",
        s: "dubai-international-city-persia-cluster"
    }, {
        n: "Universal Apartments",
        s: "dubai-international-city-universal-apartments"
    }, {
        n: "Dragon Views",
        s: "dubai-international-city-dragon-views"
    }, {
        n: "Italy Cluster",
        s: "dubai-international-city-italy-cluster"
    }, {
        n: "Western Residence North",
        s: "dubai-falcon-city-of-wonders-western-residence-north"
    }, {
        n: "Eastern Residences",
        s: "dubai-falcon-city-of-wonders-eastern-residences"
    }, {
        n: "Al Thayyal",
        s: "dubai-greens-al-thayyal"
    }, {
        n: "Al Ghaf",
        s: "dubai-greens-al-ghaf"
    }, {
        n: "The Onyx Towers",
        s: "dubai-greens-the-onyx-towers"
    }, {
        n: "Bungalow Area",
        s: "dubai-green-community-bungalow-area"
    }, {
        n: "Garden West Apartments",
        s: "dubai-green-community-garden-west-apartments"
    }, {
        n: "Ghaya Grand Hotel",
        s: "dubai-dubai-production-city-impz-ghaya-grand-hotel"
    }, {
        n: "Ramada Residence",
        s: "dubai-dubai-production-city-impz-ramada-residence"
    }, {
        n: "MJM Residence",
        s: "dubai-dubai-production-city-impz-mjm-residence"
    }, {
        n: "Hotel IMPZ-026",
        s: "dubai-dubai-production-city-impz-hotel-impz-026"
    }, {
        n: "France Cluster",
        s: "dubai-international-city-france-cluster"
    }, {
        n: "Riviera Lake View",
        s: "dubai-international-city-riviera-lake-view"
    }, {
        n: "Al Dana",
        s: "dubai-international-city-al-dana"
    }, {
        n: "CBD (Central Business District)",
        s: "dubai-international-city-cbd-central-business-district"
    }, {
        n: "Global Green View",
        s: "dubai-international-city-global-green-view"
    }, {
        n: "Oxford Residence 2",
        s: "dubai-jumeirah-village-circle-oxford-residence-2"
    }, {
        n: "Al Samar",
        s: "dubai-greens-al-samar"
    }, {
        n: "Al Ghozlan",
        s: "dubai-greens-al-ghozlan"
    }, {
        n: "Courtyard by Marriott",
        s: "dubai-green-community-courtyard-by-marriott"
    }, {
        n: "Oakwood Residency",
        s: "dubai-dubai-production-city-impz-oakwood-residency"
    }, {
        n: "Ikarus Tower",
        s: "dubai-dubai-production-city-impz-ikarus-tower"
    }, {
        n: "JIL Building",
        s: "dubai-dubai-production-city-impz-jil-building"
    }, {
        n: "Centrium Towers",
        s: "dubai-dubai-production-city-impz-centrium-towers"
    }, {
        n: "Indigo Towers",
        s: "dubai-international-city-indigo-towers"
    }, {
        n: "Prime Residency",
        s: "dubai-international-city-prime-residency"
    }, {
        n: "Lawnz by Danube",
        s: "dubai-international-city-lawnz-by-danube"
    }, {
        n: "Golden Homes 2",
        s: "dubai-international-city-golden-homes-2"
    }, {
        n: "Global Garden View",
        s: "dubai-international-city-global-garden-view"
    }, {
        n: "Jebel Ali Port",
        s: "dubai-jebel-ali-jebel-ali-port"
    }, {
        n: "SKAI Residency",
        s: "dubai-greens-skai-residency"
    }, {
        n: "Al Arta",
        s: "dubai-greens-al-arta"
    }, {
        n: "Terrace Apartments",
        s: "dubai-green-community-terrace-apartments"
    }, {
        n: "Beirut Towers",
        s: "dubai-dubai-production-city-impz-beirut-towers"
    }, {
        n: "Midtown",
        s: "dubai-dubai-production-city-impz-midtown"
    }, {
        n: "Ivory Tower",
        s: "dubai-dubai-production-city-impz-ivory-tower"
    }, {
        n: "Dana Gardens",
        s: "dubai-dubai-production-city-impz-dana-gardens"
    }, {
        n: "The Crescent",
        s: "dubai-dubai-production-city-impz-the-crescent"
    }, {
        n: "HZ Tower",
        s: "dubai-international-city-hz-tower"
    }, {
        n: "Toronto Tower",
        s: "dubai-international-city-toronto-tower"
    }, {
        n: "Vancouver Tower",
        s: "dubai-international-city-vancouver-tower"
    }, {
        n: "Emirates Cluster",
        s: "dubai-international-city-emirates-cluster"
    }, {
        n: "Russia Cluster",
        s: "dubai-international-city-russia-cluster"
    }, {
        n: "Jebel Ali Industrial",
        s: "dubai-jebel-ali-jebel-ali-industrial"
    }, {
        n: "Amwaj",
        s: "dubai-jumeirah-beach-residence-amwaj"
    }, {
        n: "Rimal",
        s: "dubai-jumeirah-beach-residence-rimal"
    }, {
        n: "Al Dhafra",
        s: "dubai-greens-al-dhafra"
    }, {
        n: "Al Sidir",
        s: "dubai-greens-al-sidir"
    }, {
        n: "Lake Apartments",
        s: "dubai-green-community-lake-apartments"
    }, {
        n: "Lago Vista",
        s: "dubai-dubai-production-city-impz-lago-vista"
    }, {
        n: "The Centrium",
        s: "dubai-dubai-production-city-impz-the-centrium"
    }, {
        n: "Jamal Building",
        s: "dubai-dubai-production-city-impz-jamal-building"
    }, {
        n: "Al Shaiba Tower",
        s: "dubai-dubai-production-city-impz-al-shaiba-tower"
    }, {
        n: "Nasser Building",
        s: "dubai-dubai-production-city-impz-nasser-building"
    }, {
        n: "Morocco Cluster",
        s: "dubai-international-city-morocco-cluster"
    }, {
        n: "Riviera Dreams",
        s: "dubai-international-city-riviera-dreams"
    }, {
        n: "Riviera Residence",
        s: "dubai-international-city-riviera-residence"
    }, {
        n: "Al Jawzaa",
        s: "dubai-international-city-al-jawzaa"
    }, {
        n: "Greece Cluster",
        s: "dubai-international-city-greece-cluster"
    }, {
        n: "England Cluster",
        s: "dubai-international-city-england-cluster"
    }, {
        n: "Gardenia Townhomes",
        s: "dubai-wasl-gate-gardenia-townhomes"
    }, {
        n: "Azizi Aura",
        s: "dubai-jebel-ali-azizi-aura"
    }, {
        n: "Bahar",
        s: "dubai-jumeirah-beach-residence-bahar"
    }, {
        n: "Shams",
        s: "dubai-jumeirah-beach-residence-shams"
    }, {
        n: "Cluster D",
        s: "dubai-jumeirah-heights-cluster-d"
    }, {
        n: "The Nook",
        s: "dubai-wasl-gate-the-nook"
    }, {
        n: "Al Fattan Marine Towers",
        s: "dubai-jumeirah-beach-residence-al-fattan-marine-towers"
    }, {
        n: "Al Fattan Crystal Towers",
        s: "dubai-jumeirah-beach-residence-al-fattan-crystal-towers"
    }, {
        n: "Sadaf",
        s: "dubai-jumeirah-beach-residence-sadaf"
    }, {
        n: "Loft Cluster",
        s: "dubai-jumeirah-heights-loft-cluster"
    }, {
        n: "Cluster E",
        s: "dubai-jumeirah-heights-cluster-e"
    }, {
        n: "Saba Towers",
        s: "dubai-jumeirah-lake-towers-saba-towers"
    }, {
        n: "Se7en City JLT",
        s: "dubai-jumeirah-lake-towers-se7en-city-jlt"
    }, {
        n: "Green Lake Towers",
        s: "dubai-jumeirah-lake-towers-green-lake-towers"
    }, {
        n: "Lake Almas West",
        s: "dubai-jumeirah-lake-towers-lake-almas-west"
    }, {
        n: "Legacy Nova Villas",
        s: "dubai-jumeirah-park-legacy-nova-villas"
    }, {
        n: "Al Thamam",
        s: "dubai-remraam-al-thamam"
    }, {
        n: "Burj Al Fara'a",
        s: "dubai-jumeirah-village-circle-burj-al-fara-a"
    }, {
        n: "Judi",
        s: "dubai-jumeirah-village-circle-judi"
    }, {
        n: "Nakheel Villas",
        s: "dubai-jumeirah-village-circle-nakheel-villas"
    }, {
        n: "Iris Park",
        s: "dubai-jumeirah-village-circle-iris-park"
    }, {
        n: "Jeewar",
        s: "dubai-jumeirah-village-circle-jeewar"
    }, {
        n: "Rose Garden",
        s: "dubai-jumeirah-village-circle-rose-garden"
    }, {
        n: "Artistic Heights",
        s: "dubai-jumeirah-village-circle-artistic-heights"
    }, {
        n: "Haven Villas at the Sanctuary",
        s: "dubai-jumeirah-village-circle-haven-villas-at-the-sanctuary"
    }, {
        n: "Cartel 112",
        s: "dubai-jumeirah-village-circle-cartel-112"
    }, {
        n: "Desert Rose",
        s: "dubai-jumeirah-village-circle-desert-rose"
    }, {
        n: "Al Amir Residence",
        s: "dubai-jumeirah-village-circle-al-amir-residence"
    }, {
        n: "The Wave Business Towers",
        s: "dubai-jumeirah-village-circle-the-wave-business-towers"
    }, {
        n: "Jouri Residence 5",
        s: "dubai-jumeirah-village-circle-jouri-residence-5"
    }, {
        n: "Tuscan Residences",
        s: "dubai-jumeirah-village-circle-tuscan-residences"
    }, {
        n: "Jebel Ali Hills",
        s: "dubai-jebel-ali-jebel-ali-hills"
    }, {
        n: "The Walk",
        s: "dubai-jumeirah-beach-residence-the-walk"
    }, {
        n: "The Address Jumeirah Resort and Spa",
        s: "dubai-jumeirah-beach-residence-the-address-jumeirah-resort-and-spa"
    }, {
        n: "Al Shaiba Building",
        s: "dubai-dubai-outsource-zone-al-shaiba-building"
    }, {
        n: "Fancy Rose",
        s: "dubai-jumeirah-lake-towers-fancy-rose"
    }, {
        n: "Jewellery and Gemplex",
        s: "dubai-jumeirah-lake-towers-jewellery-and-gemplex"
    }, {
        n: "Jumeirah Park Homes",
        s: "dubai-jumeirah-park-jumeirah-park-homes"
    }, {
        n: "Silver Heights",
        s: "dubai-jumeirah-village-circle-silver-heights"
    }, {
        n: "Masaar Residence",
        s: "dubai-jumeirah-village-circle-masaar-residence"
    }, {
        n: "Mulberry Mansion",
        s: "dubai-jumeirah-village-circle-mulberry-mansion"
    }, {
        n: "Oakville",
        s: "dubai-jumeirah-village-circle-oakville"
    }, {
        n: "Sandoval Lane",
        s: "dubai-jumeirah-village-circle-sandoval-lane"
    }, {
        n: "Abjar Tower",
        s: "dubai-jumeirah-village-circle-abjar-tower"
    }, {
        n: "Sandoval Gardens",
        s: "dubai-jumeirah-village-circle-sandoval-gardens"
    }, {
        n: "Somerset Mews",
        s: "dubai-jumeirah-village-circle-somerset-mews"
    }, {
        n: "Aurion Residence",
        s: "dubai-jumeirah-village-circle-aurion-residence"
    }, {
        n: "U tower",
        s: "dubai-jumeirah-village-circle-u-tower"
    }, {
        n: "Sydney Tower",
        s: "dubai-jumeirah-village-circle-sydney-tower"
    }, {
        n: "Bachour Villas",
        s: "dubai-jumeirah-village-circle-bachour-villas"
    }, {
        n: "Park Vista",
        s: "dubai-jumeirah-village-circle-park-vista"
    }, {
        n: "Royal JVC Building",
        s: "dubai-jumeirah-village-circle-royal-jvc-building"
    }, {
        n: "Sariin",
        s: "dubai-jumeirah-village-circle-sariin"
    }, {
        n: "District 6",
        s: "dubai-jumeirah-village-circle-district-6"
    }, {
        n: "West End Tower",
        s: "dubai-jumeirah-village-circle-west-end-tower"
    }, {
        n: "Murjan",
        s: "dubai-jumeirah-beach-residence-murjan"
    }, {
        n: "La Vie",
        s: "dubai-jumeirah-beach-residence-la-vie"
    }, {
        n: "Delta Hotels By Marriott Jumeirah Beach",
        s: "dubai-jumeirah-beach-residence-delta-hotels-by-marriott-jumeirah-beach"
    }, {
        n: "Cluster A",
        s: "dubai-jumeirah-heights-cluster-a"
    }, {
        n: "The Frond",
        s: "dubai-jumeirah-heights-the-frond"
    }, {
        n: "One JLT",
        s: "dubai-jumeirah-lake-towers-one-jlt"
    }, {
        n: "The Residences JLT",
        s: "dubai-jumeirah-lake-towers-the-residences-jlt"
    }, {
        n: "Lake Elucio",
        s: "dubai-jumeirah-lake-towers-lake-elucio"
    }, {
        n: "Mazaya Business Avenue",
        s: "dubai-jumeirah-lake-towers-mazaya-business-avenue"
    }, {
        n: "Regional",
        s: "dubai-jumeirah-park-regional"
    }, {
        n: "Las Casas",
        s: "dubai-jumeirah-village-circle-las-casas"
    }, {
        n: "District 12K",
        s: "dubai-jumeirah-village-circle-district-12k"
    }, {
        n: "Plaza Residences",
        s: "dubai-jumeirah-village-circle-plaza-residences"
    }, {
        n: "Belgravia Heights 1",
        s: "dubai-jumeirah-village-circle-belgravia-heights-1"
    }, {
        n: "Jouri Residence 4",
        s: "dubai-jumeirah-village-circle-jouri-residence-4"
    }, {
        n: "Dorna Tower",
        s: "dubai-jumeirah-village-circle-dorna-tower"
    }, {
        n: "Maple",
        s: "dubai-jumeirah-village-circle-maple"
    }, {
        n: "Villa Myra",
        s: "dubai-jumeirah-village-circle-villa-myra"
    }, {
        n: "Laya Mansion",
        s: "dubai-jumeirah-village-circle-laya-mansion"
    }, {
        n: "Aria",
        s: "dubai-jumeirah-village-circle-aria"
    }, {
        n: "Habitat",
        s: "dubai-jumeirah-village-circle-habitat"
    }, {
        n: "Golden Homes Building",
        s: "dubai-jumeirah-village-circle-golden-homes-building"
    }, {
        n: "Quattro",
        s: "dubai-jumeirah-village-circle-quattro"
    }, {
        n: "Chaimaa Premiere",
        s: "dubai-jumeirah-village-circle-chaimaa-premiere"
    }, {
        n: "National Bonds Residence",
        s: "dubai-jumeirah-village-circle-national-bonds-residence"
    }, {
        n: "Cluster F",
        s: "dubai-jumeirah-heights-cluster-f"
    }, {
        n: "Cluster B",
        s: "dubai-jumeirah-heights-cluster-b"
    }, {
        n: "Banyan Tree Residences Hillside Dubai",
        s: "dubai-jumeirah-lake-towers-banyan-tree-residences-hillside-dubai"
    }, {
        n: "Jumeirah Bay Towers",
        s: "dubai-jumeirah-lake-towers-jumeirah-bay-towers"
    }, {
        n: "Al Seef  Towers",
        s: "dubai-jumeirah-lake-towers-al-seef-towers"
    }, {
        n: "Legacy",
        s: "dubai-jumeirah-park-legacy"
    }, {
        n: "Al Ramth",
        s: "dubai-remraam-al-ramth"
    }, {
        n: "NAIA Vantage",
        s: "dubai-jumeirah-village-circle-naia-vantage"
    }, {
        n: "Cadi Residences",
        s: "dubai-jumeirah-village-circle-cadi-residences"
    }, {
        n: "District 12",
        s: "dubai-jumeirah-village-circle-district-12"
    }, {
        n: "Sandhurst House",
        s: "dubai-jumeirah-village-circle-sandhurst-house"
    }, {
        n: "La Residencia Del Sol",
        s: "dubai-jumeirah-village-circle-la-residencia-del-sol"
    }, {
        n: "Amina Residence 2",
        s: "dubai-jumeirah-village-circle-amina-residence-2"
    }, {
        n: "Le Grand Chateau",
        s: "dubai-jumeirah-village-circle-le-grand-chateau"
    }, {
        n: "Alfa Residence",
        s: "dubai-jumeirah-village-circle-alfa-residence"
    }, {
        n: "Jumeirah Suites",
        s: "dubai-jumeirah-village-circle-jumeirah-suites"
    }, {
        n: "Rose 10",
        s: "dubai-jumeirah-village-circle-rose-10"
    }, {
        n: "The Icon Casa",
        s: "dubai-jumeirah-village-circle-the-icon-casa"
    }, {
        n: "Villa Pera",
        s: "dubai-jumeirah-village-circle-villa-pera"
    }, {
        n: "Rufi Lake View",
        s: "dubai-jumeirah-village-circle-rufi-lake-view"
    }, {
        n: "Red Diamond",
        s: "dubai-jumeirah-lake-towers-red-diamond"
    }, {
        n: "MBL Residences",
        s: "dubai-jumeirah-lake-towers-mbl-residences"
    }, {
        n: "iGo 101 Towers",
        s: "dubai-jumeirah-lake-towers-i-go-101-towers"
    }, {
        n: "Royal Park",
        s: "dubai-jumeirah-park-royal-park"
    }, {
        n: "Mediterranean",
        s: "dubai-jumeirah-park-mediterranean"
    }, {
        n: "Crystal Residence",
        s: "dubai-jumeirah-village-circle-crystal-residence"
    }, {
        n: "Oxford",
        s: "dubai-jumeirah-village-circle-oxford"
    }, {
        n: "Emirates Gardens 2",
        s: "dubai-jumeirah-village-circle-emirates-gardens-2"
    }, {
        n: "District 12V",
        s: "dubai-jumeirah-village-circle-district-12v"
    }, {
        n: "Shamal Terraces",
        s: "dubai-jumeirah-village-circle-shamal-terraces"
    }, {
        n: "Arabian Tower",
        s: "dubai-jumeirah-village-circle-arabian-tower"
    }, {
        n: "Baynonah Business Centre",
        s: "dubai-jumeirah-village-circle-baynonah-business-centre"
    }, {
        n: "Les Maisonettes",
        s: "dubai-jumeirah-village-circle-les-maisonettes"
    }, {
        n: "Orchid Park",
        s: "dubai-jumeirah-village-circle-orchid-park"
    }, {
        n: "Belgravia",
        s: "dubai-jumeirah-village-circle-belgravia"
    }, {
        n: "The One at Jumeirah Village Circle",
        s: "dubai-jumeirah-village-circle-the-one-at-jumeirah-village-circle"
    }, {
        n: "Al Hassani",
        s: "dubai-jumeirah-village-circle-al-hassani"
    }, {
        n: "Marwa Homes 2",
        s: "dubai-jumeirah-village-circle-marwa-homes-2"
    }, {
        n: "Gardenia Residency",
        s: "dubai-jumeirah-village-circle-gardenia-residency"
    }, {
        n: "Diamond Arch",
        s: "dubai-jumeirah-village-circle-diamond-arch"
    }, {
        n: "Westar Constellation",
        s: "dubai-jumeirah-village-circle-westar-constellation"
    }, {
        n: "Rotating Tower",
        s: "dubai-jumeirah-village-circle-rotating-tower"
    }, {
        n: "District 16L",
        s: "dubai-jumeirah-village-circle-district-16l"
    }, {
        n: "Taraf 1 Residence",
        s: "dubai-jumeirah-village-circle-taraf-1-residence"
    }, {
        n: "Adore",
        s: "dubai-jumeirah-village-circle-adore"
    }, {
        n: "Monte Carlo Residence",
        s: "dubai-jumeirah-village-circle-monte-carlo-residence"
    }, {
        n: "Regina Tower",
        s: "dubai-jumeirah-village-circle-regina-tower"
    }, {
        n: "Sobha City",
        s: "dubai-nadd-al-sheba-sobha-city"
    }, {
        n: "Al Muhra",
        s: "dubai-jumeirah-village-circle-al-muhra"
    }, {
        n: "Metropolis Lofts",
        s: "dubai-jumeirah-village-circle-metropolis-lofts"
    }, {
        n: "Artistic Villas",
        s: "dubai-jumeirah-village-circle-artistic-villas"
    }, {
        n: "Les Castelets",
        s: "dubai-jumeirah-village-circle-les-castelets"
    }, {
        n: "Zaya Hameni",
        s: "dubai-jumeirah-village-circle-zaya-hameni"
    }, {
        n: "Serena Residence",
        s: "dubai-jumeirah-village-circle-serena-residence"
    }, {
        n: "Lucky 1 Residence",
        s: "dubai-jumeirah-village-circle-lucky-1-residence"
    }, {
        n: "District 16A",
        s: "dubai-jumeirah-village-circle-district-16a"
    }, {
        n: "Green Valley Tower",
        s: "dubai-jumeirah-village-circle-green-valley-tower"
    }, {
        n: "Oasis Flex",
        s: "dubai-jumeirah-village-circle-oasis-flex"
    }, {
        n: "The Lawns",
        s: "dubai-jumeirah-village-circle-the-lawns"
    }, {
        n: "Shamal Waves",
        s: "dubai-jumeirah-village-circle-shamal-waves"
    }, {
        n: "Park Corner",
        s: "dubai-jumeirah-village-circle-park-corner"
    }, {
        n: "District 10",
        s: "dubai-jumeirah-village-circle-district-10"
    }, {
        n: "The Dorna Tower",
        s: "dubai-jumeirah-village-circle-the-dorna-tower"
    }, {
        n: "ACES Chateau",
        s: "dubai-jumeirah-village-circle-aces-chateau"
    }, {
        n: "Signature Villas XII",
        s: "dubai-jumeirah-village-circle-signature-villas-xii"
    }, {
        n: "District 16M",
        s: "dubai-jumeirah-village-circle-district-16m"
    }, {
        n: "District 15",
        s: "dubai-jumeirah-village-circle-district-15"
    }, {
        n: "Prime Business Centre",
        s: "dubai-jumeirah-village-circle-prime-business-centre"
    }, {
        n: "Palace Estates",
        s: "dubai-jumeirah-village-circle-palace-estates"
    }, {
        n: "Signature Livings",
        s: "dubai-jumeirah-village-circle-signature-livings"
    }, {
        n: "District 16E",
        s: "dubai-jumeirah-village-circle-district-16e"
    }, {
        n: "Goldenwoods Villas",
        s: "dubai-jumeirah-village-circle-goldenwoods-villas"
    }, {
        n: "District 13",
        s: "dubai-jumeirah-village-circle-district-13"
    }, {
        n: "Golden Heights",
        s: "dubai-jumeirah-village-circle-golden-heights"
    }, {
        n: "Bloom Heights",
        s: "dubai-jumeirah-village-circle-bloom-heights"
    }, {
        n: "Oxford Residence",
        s: "dubai-jumeirah-village-circle-oxford-residence"
    }, {
        n: "Park Villas",
        s: "dubai-jumeirah-village-circle-park-villas"
    }, {
        n: "Eaton Place",
        s: "dubai-jumeirah-village-circle-eaton-place"
    }, {
        n: "Pacific Wave Residence",
        s: "dubai-jumeirah-village-circle-pacific-wave-residence"
    }, {
        n: "Parkvale Residences",
        s: "dubai-jumeirah-village-circle-parkvale-residences"
    }, {
        n: "Dar Al Jawhara",
        s: "dubai-jumeirah-village-circle-dar-al-jawhara"
    }, {
        n: "Living Garden 2",
        s: "dubai-jumeirah-village-circle-living-garden-2"
    }, {
        n: "District 18",
        s: "dubai-jumeirah-village-circle-district-18"
    }, {
        n: "Centre Court",
        s: "dubai-jumeirah-village-circle-centre-court"
    }, {
        n: "10C Building",
        s: "dubai-jumeirah-village-circle-10c-building"
    }, {
        n: "Belgravia Square",
        s: "dubai-jumeirah-village-circle-belgravia-square"
    }, {
        n: "Massar Building",
        s: "dubai-jumeirah-village-circle-massar-building"
    }, {
        n: "Viceroy JV",
        s: "dubai-jumeirah-village-circle-viceroy-jv"
    }, {
        n: "Erantis",
        s: "dubai-jumeirah-village-circle-erantis"
    }, {
        n: "District 12S",
        s: "dubai-jumeirah-village-circle-district-12s"
    }, {
        n: "O2 Tower",
        s: "dubai-jumeirah-village-circle-o2-tower"
    }, {
        n: "Rufi Downtown",
        s: "dubai-jumeirah-village-circle-rufi-downtown"
    }, {
        n: "District 14",
        s: "dubai-jumeirah-village-circle-district-14"
    }, {
        n: "The Square Tower",
        s: "dubai-jumeirah-village-circle-the-square-tower"
    }, {
        n: "Avalon Tower",
        s: "dubai-jumeirah-village-circle-avalon-tower"
    }, {
        n: "Pulse Smart Residence",
        s: "dubai-jumeirah-village-circle-pulse-smart-residence"
    }, {
        n: "District 13H",
        s: "dubai-jumeirah-village-circle-district-13h"
    }, {
        n: "Divine homes",
        s: "dubai-jumeirah-village-circle-divine-homes"
    }, {
        n: "Al Zubaidi Residence",
        s: "dubai-jumeirah-village-circle-al-zubaidi-residence"
    }, {
        n: "Westar Casablanca",
        s: "dubai-jumeirah-village-circle-westar-casablanca"
    }, {
        n: "District 16C",
        s: "dubai-jumeirah-village-circle-district-16c"
    }, {
        n: "Maimoon Twin Towers",
        s: "dubai-jumeirah-village-circle-maimoon-twin-towers"
    }, {
        n: "Jouri Residence 6",
        s: "dubai-jumeirah-village-circle-jouri-residence-6"
    }, {
        n: "Pantheon Elysee",
        s: "dubai-jumeirah-village-circle-pantheon-elysee"
    }, {
        n: "UniEstate Prime Tower",
        s: "dubai-jumeirah-village-circle-uni-estate-prime-tower"
    }, {
        n: "Mulberry Park",
        s: "dubai-jumeirah-village-circle-mulberry-park"
    }, {
        n: "La Riviera Apartments",
        s: "dubai-jumeirah-village-circle-la-riviera-apartments"
    }, {
        n: "Platinum Residence",
        s: "dubai-jumeirah-village-circle-platinum-residence"
    }, {
        n: "Garden Heights",
        s: "dubai-jumeirah-village-circle-garden-heights"
    }, {
        n: "Hyati Avenue",
        s: "dubai-jumeirah-village-circle-hyati-avenue"
    }, {
        n: "District 4",
        s: "dubai-jumeirah-village-circle-district-4"
    }, {
        n: "R&R Tower",
        s: "dubai-jumeirah-village-circle-r-r-tower"
    }, {
        n: "Orchidea Building",
        s: "dubai-jumeirah-village-circle-orchidea-building"
    }, {
        n: "AKA Residence",
        s: "dubai-jumeirah-village-circle-aka-residence"
    }, {
        n: "Casa Royale",
        s: "dubai-jumeirah-village-circle-casa-royale"
    }, {
        n: "Oudah Tower",
        s: "dubai-jumeirah-village-circle-oudah-tower"
    }, {
        n: "Lotus Park",
        s: "dubai-jumeirah-village-circle-lotus-park"
    }, {
        n: "District 11D",
        s: "dubai-jumeirah-village-circle-district-11d"
    }, {
        n: "Jouri Residence 3",
        s: "dubai-jumeirah-village-circle-jouri-residence-3"
    }, {
        n: "Hyati Residences",
        s: "dubai-jumeirah-village-circle-hyati-residences"
    }, {
        n: "Westar Vista",
        s: "dubai-jumeirah-village-circle-westar-vista"
    }, {
        n: "Westar Corona",
        s: "dubai-jumeirah-village-circle-westar-corona"
    }, {
        n: "Al Manara Tower - JVC",
        s: "dubai-jumeirah-village-circle-al-manara-tower-jvc"
    }, {
        n: "Emerald JVC",
        s: "dubai-jumeirah-village-circle-emerald-jvc"
    }, {
        n: "Cappadocia",
        s: "dubai-jumeirah-village-circle-cappadocia"
    }, {
        n: "Alcove",
        s: "dubai-jumeirah-village-circle-alcove"
    }, {
        n: "Excellence Residence",
        s: "dubai-jumeirah-village-circle-excellence-residence"
    }, {
        n: "Evershine 1",
        s: "dubai-jumeirah-village-circle-evershine-1"
    }, {
        n: "Pisa Residence Tower",
        s: "dubai-jumeirah-village-circle-pisa-residence-tower"
    }, {
        n: "Tasmeer Residence",
        s: "dubai-jumeirah-village-circle-tasmeer-residence"
    }, {
        n: "Tower 108",
        s: "dubai-jumeirah-village-circle-tower-108"
    }, {
        n: "Hameni Residence",
        s: "dubai-jumeirah-village-circle-hameni-residence"
    }, {
        n: "Laya Residences",
        s: "dubai-jumeirah-village-circle-laya-residences"
    }, {
        n: "Rigel",
        s: "dubai-jumeirah-village-circle-rigel"
    }, {
        n: "The Residence 4",
        s: "dubai-jumeirah-village-circle-the-residence-4"
    }, {
        n: "Pantheon Boulevard",
        s: "dubai-jumeirah-village-circle-pantheon-boulevard"
    }, {
        n: "Pomona Residence",
        s: "dubai-jumeirah-village-triangle-pomona-residence"
    }, {
        n: "Kensington Manor",
        s: "dubai-jumeirah-village-circle-kensington-manor"
    }, {
        n: "Mar Residences",
        s: "dubai-jumeirah-village-circle-mar-residences"
    }, {
        n: "District 12X",
        s: "dubai-jumeirah-village-circle-district-12x"
    }, {
        n: "Eden Residence",
        s: "dubai-jumeirah-village-circle-eden-residence"
    }, {
        n: "Noora Residence",
        s: "dubai-jumeirah-village-circle-noora-residence"
    }, {
        n: "The Plaza",
        s: "dubai-jumeirah-village-circle-the-plaza"
    }, {
        n: "Botanica",
        s: "dubai-jumeirah-village-circle-botanica"
    }, {
        n: "Eclipse Tower",
        s: "dubai-jumeirah-village-circle-eclipse-tower"
    }, {
        n: "Beverly Residence",
        s: "dubai-jumeirah-village-circle-beverly-residence"
    }, {
        n: "Dezire Residences",
        s: "dubai-jumeirah-village-circle-dezire-residences"
    }, {
        n: "District 16J",
        s: "dubai-jumeirah-village-circle-district-16j"
    }, {
        n: "Heilbronn Villas",
        s: "dubai-jumeirah-village-circle-heilbronn-villas"
    }, {
        n: "Grand Paradise",
        s: "dubai-jumeirah-village-circle-grand-paradise"
    }, {
        n: "Jouri Residence 1",
        s: "dubai-jumeirah-village-circle-jouri-residence-1"
    }, {
        n: "Roxana Residences",
        s: "dubai-jumeirah-village-circle-roxana-residences"
    }, {
        n: "Al Sima Tower",
        s: "dubai-jumeirah-village-circle-al-sima-tower"
    }, {
        n: "MILANO by Giovanni Botique Suites",
        s: "dubai-jumeirah-village-circle-milano-by-giovanni-botique-suites"
    }, {
        n: "Belgravia Heights 2",
        s: "dubai-jumeirah-village-circle-belgravia-heights-2"
    }, {
        n: "Joya Verde Residences",
        s: "dubai-jumeirah-village-circle-joya-verde-residences"
    }, {
        n: "Jouri Residence 2",
        s: "dubai-jumeirah-village-circle-jouri-residence-2"
    }, {
        n: "District 12H",
        s: "dubai-jumeirah-village-circle-district-12h"
    }, {
        n: "Jehaan Residences",
        s: "dubai-jumeirah-village-circle-jehaan-residences"
    }, {
        n: "The Ghaf Tree",
        s: "dubai-jumeirah-village-circle-the-ghaf-tree"
    }, {
        n: "FIVE at Jumeirah Village Circle",
        s: "dubai-jumeirah-village-circle-five-at-jumeirah-village-circle"
    }, {
        n: "Shamal Residences",
        s: "dubai-jumeirah-village-circle-shamal-residences"
    }, {
        n: "Bloom Towers",
        s: "dubai-jumeirah-village-circle-bloom-towers"
    }, {
        n: "SPICA Residential",
        s: "dubai-jumeirah-village-circle-spica-residential"
    }, {
        n: "District 11",
        s: "dubai-jumeirah-village-circle-district-11"
    }, {
        n: "Mediterranean Townhouse",
        s: "dubai-jumeirah-village-triangle-mediterranean-townhouse"
    }, {
        n: "Sunset Gardens",
        s: "dubai-jumeirah-village-circle-sunset-gardens"
    }, {
        n: "Mirage Residence",
        s: "dubai-jumeirah-village-circle-mirage-residence"
    }, {
        n: "Al Waleed Building",
        s: "dubai-jumeirah-village-circle-al-waleed-building"
    }, {
        n: "Hanover Square",
        s: "dubai-jumeirah-village-circle-hanover-square"
    }, {
        n: "Haven Villas 1 at the Sanctuary",
        s: "dubai-jumeirah-village-circle-haven-villas-1-at-the-sanctuary"
    }, {
        n: "SBL Building 401",
        s: "dubai-jumeirah-village-circle-sbl-building-401"
    }, {
        n: "District 12U",
        s: "dubai-jumeirah-village-circle-district-12u"
    }, {
        n: "Tulip Park",
        s: "dubai-jumeirah-village-circle-tulip-park"
    }, {
        n: "Dusit Princess Rijas",
        s: "dubai-jumeirah-village-circle-dusit-princess-rijas"
    }, {
        n: "Nakheel Townhouses",
        s: "dubai-jumeirah-village-circle-nakheel-townhouses"
    }, {
        n: "Ashai Tower",
        s: "dubai-jumeirah-village-circle-ashai-tower"
    }, {
        n: "District 16",
        s: "dubai-jumeirah-village-circle-district-16"
    }, {
        n: "Lilac Park",
        s: "dubai-jumeirah-village-circle-lilac-park"
    }, {
        n: "District 12T",
        s: "dubai-jumeirah-village-circle-district-12t"
    }, {
        n: "Serenity Lakes",
        s: "dubai-jumeirah-village-circle-serenity-lakes"
    }, {
        n: "Chaimaa Avenue Residences",
        s: "dubai-jumeirah-village-circle-chaimaa-avenue-residences"
    }, {
        n: "Mirabella",
        s: "dubai-jumeirah-village-circle-mirabella"
    }, {
        n: "Dune Residency",
        s: "dubai-jumeirah-village-circle-dune-residency"
    }, {
        n: "Park Square",
        s: "dubai-jumeirah-village-circle-park-square"
    }, {
        n: "Reliance Towers",
        s: "dubai-jumeirah-village-circle-reliance-towers"
    }, {
        n: "Sobha Daffodil",
        s: "dubai-jumeirah-village-circle-sobha-daffodil"
    }, {
        n: "Al Khail Plaza",
        s: "dubai-jumeirah-village-triangle-al-khail-plaza"
    }, {
        n: "District 2",
        s: "dubai-jumeirah-village-triangle-district-2"
    }, {
        n: "District 2E",
        s: "dubai-jumeirah-village-triangle-district-2e"
    }, {
        n: "District 8Q",
        s: "dubai-jumeirah-village-triangle-district-8q"
    }, {
        n: "District 2G",
        s: "dubai-jumeirah-village-triangle-district-2g"
    }, {
        n: "District 1B",
        s: "dubai-jumeirah-village-triangle-district-1b"
    }, {
        n: "District 9H",
        s: "dubai-jumeirah-village-triangle-district-9h"
    }, {
        n: "Flamingo Building",
        s: "dubai-jumeirah-village-circle-flamingo-building"
    }, {
        n: "Park View Tower",
        s: "dubai-jumeirah-village-circle-park-view-tower"
    }, {
        n: "South Residences",
        s: "dubai-jumeirah-village-circle-south-residences"
    }, {
        n: "The Manhattan Tower",
        s: "dubai-jumeirah-village-circle-the-manhattan-tower"
    }, {
        n: "Sahara",
        s: "dubai-jumeirah-village-circle-sahara"
    }, {
        n: "District 3C",
        s: "dubai-jumeirah-village-triangle-district-3c"
    }, {
        n: "District 7G",
        s: "dubai-jumeirah-village-triangle-district-7g"
    }, {
        n: "District 12",
        s: "dubai-jumeirah-village-triangle-district-12"
    }, {
        n: "District 3D",
        s: "dubai-jumeirah-village-triangle-district-3d"
    }, {
        n: "District 3A",
        s: "dubai-jumeirah-village-triangle-district-3a"
    }, {
        n: "District 4H",
        s: "dubai-jumeirah-village-triangle-district-4h"
    }, {
        n: "District 8J",
        s: "dubai-jumeirah-village-triangle-district-8j"
    }, {
        n: "Green Park",
        s: "dubai-jumeirah-village-triangle-green-park"
    }, {
        n: "District 5D",
        s: "dubai-jumeirah-village-triangle-district-5d"
    }, {
        n: "District 4F",
        s: "dubai-jumeirah-village-triangle-district-4f"
    }, {
        n: "District 8N",
        s: "dubai-jumeirah-village-triangle-district-8n"
    }, {
        n: "Magnolia Residence",
        s: "dubai-jumeirah-village-triangle-magnolia-residence"
    }, {
        n: "The One at Jumeirah Village Triangle",
        s: "dubai-jumeirah-village-triangle-the-one-at-jumeirah-village-triangle"
    }, {
        n: "Terhab Residences",
        s: "dubai-jumeirah-village-triangle-terhab-residences"
    }, {
        n: "Pacific Residencia",
        s: "dubai-jumeirah-village-triangle-pacific-residencia"
    }, {
        n: "BQ2 Residence",
        s: "dubai-jumeirah-village-triangle-bq2-residence"
    }, {
        n: "Al Manara",
        s: "dubai-jumeirah-village-triangle-al-manara"
    }, {
        n: "Naseem",
        s: "dubai-mudon-naseem"
    }, {
        n: "Dunes Tower",
        s: "dubai-jumeirah-village-circle-dunes-tower"
    }, {
        n: "Saleh Bin Lahej Building",
        s: "dubai-jumeirah-village-circle-saleh-bin-lahej-building"
    }, {
        n: "Sobo Residence",
        s: "dubai-jumeirah-village-circle-sobo-residence"
    }, {
        n: "City Apartments",
        s: "dubai-jumeirah-village-circle-city-apartments"
    }, {
        n: "District 19",
        s: "dubai-jumeirah-village-circle-district-19"
    }, {
        n: "Westar Terrace Garden",
        s: "dubai-jumeirah-village-circle-westar-terrace-garden"
    }, {
        n: "District 5G",
        s: "dubai-jumeirah-village-triangle-district-5g"
    }, {
        n: "Point Residencia",
        s: "dubai-jumeirah-village-triangle-point-residencia"
    }, {
        n: "District 9D",
        s: "dubai-jumeirah-village-triangle-district-9d"
    }, {
        n: "District 4A",
        s: "dubai-jumeirah-village-triangle-district-4a"
    }, {
        n: "District 8K",
        s: "dubai-jumeirah-village-triangle-district-8k"
    }, {
        n: "District 8R",
        s: "dubai-jumeirah-village-triangle-district-8r"
    }, {
        n: "District 1I",
        s: "dubai-jumeirah-village-triangle-district-1i"
    }, {
        n: "District 8C",
        s: "dubai-jumeirah-village-triangle-district-8c"
    }, {
        n: "District 9I",
        s: "dubai-jumeirah-village-triangle-district-9i"
    }, {
        n: "District 9B",
        s: "dubai-jumeirah-village-triangle-district-9b"
    }, {
        n: "District 8U",
        s: "dubai-jumeirah-village-triangle-district-8u"
    }, {
        n: "Al Burooj Residence 2",
        s: "dubai-jumeirah-village-triangle-al-burooj-residence-2"
    }, {
        n: "Arabian Villas",
        s: "dubai-jumeirah-village-triangle-arabian-villas"
    }, {
        n: "District 8B",
        s: "dubai-jumeirah-village-triangle-district-8b"
    }, {
        n: "District 8I",
        s: "dubai-jumeirah-village-triangle-district-8i"
    }, {
        n: "District 1A",
        s: "dubai-jumeirah-village-triangle-district-1a"
    }, {
        n: "Al Burooj Residence 4",
        s: "dubai-jumeirah-village-triangle-al-burooj-residence-4"
    }, {
        n: "District 9C",
        s: "dubai-jumeirah-village-triangle-district-9c"
    }, {
        n: "District 2C",
        s: "dubai-jumeirah-village-triangle-district-2c"
    }, {
        n: "Al Jawhara Residences",
        s: "dubai-jumeirah-village-triangle-al-jawhara-residences"
    }, {
        n: "Pacific Edmonton",
        s: "dubai-jumeirah-village-triangle-pacific-edmonton"
    }, {
        n: "District 9E",
        s: "dubai-jumeirah-village-triangle-district-9e"
    }, {
        n: "La Residence",
        s: "dubai-jumeirah-village-triangle-la-residence"
    }, {
        n: "Arabella Townhouses",
        s: "dubai-mudon-arabella-townhouses"
    }, {
        n: "ANWA",
        s: "dubai-maritime-city-anwa"
    }, {
        n: "La Riviera Estate",
        s: "dubai-jumeirah-village-circle-la-riviera-estate"
    }, {
        n: "Fortunato",
        s: "dubai-jumeirah-village-circle-fortunato"
    }, {
        n: "Regent Court",
        s: "dubai-jumeirah-village-circle-regent-court"
    }, {
        n: "Diamond Views",
        s: "dubai-jumeirah-village-circle-diamond-views"
    }, {
        n: "7 West Residences",
        s: "dubai-jumeirah-village-circle-7-west-residences"
    }, {
        n: "Dana Tower",
        s: "dubai-jumeirah-village-circle-dana-tower"
    }, {
        n: "District 8H",
        s: "dubai-jumeirah-village-triangle-district-8h"
    }, {
        n: "District 9K",
        s: "dubai-jumeirah-village-triangle-district-9k"
    }, {
        n: "District 4E",
        s: "dubai-jumeirah-village-triangle-district-4e"
    }, {
        n: "District 5A",
        s: "dubai-jumeirah-village-triangle-district-5a"
    }, {
        n: "District 8S",
        s: "dubai-jumeirah-village-triangle-district-8s"
    }, {
        n: "District 7F",
        s: "dubai-jumeirah-village-triangle-district-7f"
    }, {
        n: "District 8D",
        s: "dubai-jumeirah-village-triangle-district-8d"
    }, {
        n: "District 1C",
        s: "dubai-jumeirah-village-triangle-district-1c"
    }, {
        n: "District 7E",
        s: "dubai-jumeirah-village-triangle-district-7e"
    }, {
        n: "Al Burooj Residence 1",
        s: "dubai-jumeirah-village-triangle-al-burooj-residence-1"
    }, {
        n: "District 7C",
        s: "dubai-jumeirah-village-triangle-district-7c"
    }, {
        n: "District 5F",
        s: "dubai-jumeirah-village-triangle-district-5f"
    }, {
        n: "District 9G",
        s: "dubai-jumeirah-village-triangle-district-9g"
    }, {
        n: "District 7B",
        s: "dubai-jumeirah-village-triangle-district-7b"
    }, {
        n: "District 5B",
        s: "dubai-jumeirah-village-triangle-district-5b"
    }, {
        n: "Oasis Residence 2",
        s: "dubai-jumeirah-village-triangle-oasis-residence-2"
    }, {
        n: "District 8F",
        s: "dubai-jumeirah-village-triangle-district-8f"
    }, {
        n: "Plazzo Residence",
        s: "dubai-jumeirah-village-triangle-plazzo-residence"
    }, {
        n: "Noor Apartment 1",
        s: "dubai-jumeirah-village-triangle-noor-apartment-1"
    }, {
        n: "Park View Residence",
        s: "dubai-jumeirah-village-circle-park-view-residence"
    }, {
        n: "Astoria Residence",
        s: "dubai-jumeirah-village-circle-astoria-residence"
    }, {
        n: "District 9F",
        s: "dubai-jumeirah-village-triangle-district-9f"
    }, {
        n: "Silver Stallion Towers",
        s: "dubai-jumeirah-village-triangle-silver-stallion-towers"
    }, {
        n: "District 4C",
        s: "dubai-jumeirah-village-triangle-district-4c"
    }, {
        n: "Park Inn by Radisson JVT",
        s: "dubai-jumeirah-village-triangle-park-inn-by-radisson-jvt"
    }, {
        n: "District 9J",
        s: "dubai-jumeirah-village-triangle-district-9j"
    }, {
        n: "District 8A",
        s: "dubai-jumeirah-village-triangle-district-8a"
    }, {
        n: "Park One",
        s: "dubai-jumeirah-village-triangle-park-one"
    }, {
        n: "District 9O",
        s: "dubai-jumeirah-village-triangle-district-9o"
    }, {
        n: "El Matador Tower",
        s: "dubai-jumeirah-village-triangle-el-matador-tower"
    }, {
        n: "District 4D",
        s: "dubai-jumeirah-village-triangle-district-4d"
    }, {
        n: "District 8T",
        s: "dubai-jumeirah-village-triangle-district-8t"
    }, {
        n: "District 8V",
        s: "dubai-jumeirah-village-triangle-district-8v"
    }, {
        n: "District 1J",
        s: "dubai-jumeirah-village-triangle-district-1j"
    }, {
        n: "Al Burooj Residence 3",
        s: "dubai-jumeirah-village-triangle-al-burooj-residence-3"
    }, {
        n: "District 3B",
        s: "dubai-jumeirah-village-triangle-district-3b"
    }, {
        n: "District 8L",
        s: "dubai-jumeirah-village-triangle-district-8l"
    }, {
        n: "District 4G",
        s: "dubai-jumeirah-village-triangle-district-4g"
    }, {
        n: "District 7A",
        s: "dubai-jumeirah-village-triangle-district-7a"
    }, {
        n: "Fairway Heights",
        s: "dubai-jumeirah-village-triangle-fairway-heights"
    }, {
        n: "Golden Wood Views",
        s: "dubai-jumeirah-village-triangle-golden-wood-views"
    }, {
        n: "Nour Bay Tower",
        s: "dubai-jumeirah-village-triangle-nour-bay-tower"
    }, {
        n: "District 9L",
        s: "dubai-jumeirah-village-triangle-district-9l"
    }, {
        n: "Mudon Views",
        s: "dubai-mudon-mudon-views"
    }, {
        n: "Harbour Residences",
        s: "dubai-maritime-city-harbour-residences"
    }, {
        n: "Sherlock House",
        s: "dubai-motor-city-sherlock-house"
    }, {
        n: "Bennett House",
        s: "dubai-motor-city-bennett-house"
    }, {
        n: "Marlowe House",
        s: "dubai-motor-city-marlowe-house"
    }, {
        n: "Easton Court",
        s: "dubai-motor-city-easton-court"
    }, {
        n: "District 8O",
        s: "dubai-jumeirah-village-triangle-district-8o"
    }, {
        n: "Mediterranean Villas",
        s: "dubai-jumeirah-village-triangle-mediterranean-villas"
    }, {
        n: "District 5C",
        s: "dubai-jumeirah-village-triangle-district-5c"
    }, {
        n: "District 9M",
        s: "dubai-jumeirah-village-triangle-district-9m"
    }, {
        n: "District 8P",
        s: "dubai-jumeirah-village-triangle-district-8p"
    }, {
        n: "District 5E",
        s: "dubai-jumeirah-village-triangle-district-5e"
    }, {
        n: "District 8E",
        s: "dubai-jumeirah-village-triangle-district-8e"
    }, {
        n: "District 8G",
        s: "dubai-jumeirah-village-triangle-district-8g"
    }, {
        n: "Al Burooj Residence VII",
        s: "dubai-jumeirah-village-triangle-al-burooj-residence-vii"
    }, {
        n: "District 7D",
        s: "dubai-jumeirah-village-triangle-district-7d"
    }, {
        n: "District 8M",
        s: "dubai-jumeirah-village-triangle-district-8m"
    }, {
        n: "District 2D",
        s: "dubai-jumeirah-village-triangle-district-2d"
    }, {
        n: "District 2B",
        s: "dubai-jumeirah-village-triangle-district-2b"
    }, {
        n: "District 2F",
        s: "dubai-jumeirah-village-triangle-district-2f"
    }, {
        n: "District 4B",
        s: "dubai-jumeirah-village-triangle-district-4b"
    }, {
        n: "District 9N",
        s: "dubai-jumeirah-village-triangle-district-9n"
    }, {
        n: "The Imperial Residence",
        s: "dubai-jumeirah-village-triangle-the-imperial-residence"
    }, {
        n: "Al Salam",
        s: "dubai-mudon-al-salam"
    }, {
        n: "Jumeirah Waterfront",
        s: "dubai-maritime-city-jumeirah-waterfront"
    }, {
        n: "Regent House",
        s: "dubai-motor-city-regent-house"
    }, {
        n: "Weston Court",
        s: "dubai-motor-city-weston-court"
    }, {
        n: "Detroit House",
        s: "dubai-motor-city-detroit-house"
    }, {
        n: "Barton House",
        s: "dubai-motor-city-barton-house"
    }, {
        n: "Nad Al Sheba 1",
        s: "dubai-nadd-al-sheba-nad-al-sheba-1"
    }, {
        n: "Saleh Bin Lahej Tower",
        s: "dubai-barsha-heights-tecom-saleh-bin-lahej-tower"
    }, {
        n: "The Belvedere Residences",
        s: "dubai-barsha-heights-tecom-the-belvedere-residences"
    }, {
        n: "Foxhill",
        s: "dubai-motor-city-foxhill"
    }, {
        n: "Widcombe House",
        s: "dubai-motor-city-widcombe-house"
    }, {
        n: "Oia Residence",
        s: "dubai-motor-city-oia-residence"
    }, {
        n: "Sherlock Circus",
        s: "dubai-motor-city-sherlock-circus"
    }, {
        n: "Oasis Residence",
        s: "dubai-barsha-heights-tecom-oasis-residence"
    }, {
        n: "Grand Belle Vue Hotel Apartment",
        s: "dubai-barsha-heights-tecom-grand-belle-vue-hotel-apartment"
    }, {
        n: "Vista Tower",
        s: "dubai-barsha-heights-tecom-vista-tower"
    }, {
        n: "Stella",
        s: "dubai-barsha-heights-tecom-stella"
    }, {
        n: "Awari Building",
        s: "dubai-barsha-heights-tecom-awari-building"
    }, {
        n: "Tameem House",
        s: "dubai-barsha-heights-tecom-tameem-house"
    }, {
        n: "Thuraya Communications Tower",
        s: "dubai-barsha-heights-tecom-thuraya-communications-tower"
    }, {
        n: "Nimir Building",
        s: "dubai-barsha-heights-tecom-nimir-building"
    }, {
        n: "Grosvenor Business Tower",
        s: "dubai-barsha-heights-tecom-grosvenor-business-tower"
    }, {
        n: "Crown Residence",
        s: "dubai-barsha-heights-tecom-crown-residence"
    }, {
        n: "Silicon plaza",
        s: "dubai-barsha-heights-tecom-silicon-plaza"
    }, {
        n: "Media Rotana Hotel Towers",
        s: "dubai-barsha-heights-tecom-media-rotana-hotel-towers"
    }, {
        n: "Ramee Guestline Hotel Apartments",
        s: "dubai-barsha-heights-tecom-ramee-guestline-hotel-apartments"
    }, {
        n: "Al Badi Complex",
        s: "dubai-mirdif-al-badi-complex"
    }, {
        n: "Mirdiff 44 Villas",
        s: "dubai-mirdif-mirdiff-44-villas"
    }, {
        n: "Uptown Motorcity",
        s: "dubai-motor-city-uptown-motorcity"
    }, {
        n: "Eden Apartments",
        s: "dubai-motor-city-eden-apartments"
    }, {
        n: "Abbey Crescent",
        s: "dubai-motor-city-abbey-crescent"
    }, {
        n: "Nad Al Sheba 4",
        s: "dubai-nadd-al-sheba-nad-al-sheba-4"
    }, {
        n: "Icon Tower",
        s: "dubai-barsha-heights-tecom-icon-tower"
    }, {
        n: "Al Warsan Building",
        s: "dubai-barsha-heights-tecom-al-warsan-building"
    }, {
        n: "Class Hotel Apartments",
        s: "dubai-barsha-heights-tecom-class-hotel-apartments"
    }, {
        n: "Executive Heights",
        s: "dubai-barsha-heights-tecom-executive-heights"
    }, {
        n: "Arabian Oryx House",
        s: "dubai-barsha-heights-tecom-arabian-oryx-house"
    }, {
        n: "Al Hawai Residence",
        s: "dubai-barsha-heights-tecom-al-hawai-residence"
    }, {
        n: "Al Thurayya Tower",
        s: "dubai-barsha-heights-tecom-al-thurayya-tower"
    }, {
        n: "Moosawi Building Tecom",
        s: "dubai-barsha-heights-tecom-moosawi-building-tecom"
    }, {
        n: "Al Ameri Tower",
        s: "dubai-barsha-heights-tecom-al-ameri-tower"
    }, {
        n: "Art 8",
        s: "dubai-barsha-heights-tecom-art-8"
    }, {
        n: "Green View",
        s: "dubai-barsha-heights-tecom-green-view"
    }, {
        n: "National Bonds Plaza",
        s: "dubai-barsha-heights-tecom-national-bonds-plaza"
    }, {
        n: "Art 2",
        s: "dubai-barsha-heights-tecom-art-2"
    }, {
        n: "Sky Central Hotel",
        s: "dubai-barsha-heights-tecom-sky-central-hotel"
    }, {
        n: "Millennium Towers Residence",
        s: "dubai-barsha-heights-tecom-millennium-towers-residence"
    }, {
        n: "Mercure Dubai Barsha Heights Hotel Suites & Apartments",
        s: "dubai-barsha-heights-tecom-mercure-dubai-barsha-heights-hotel-suites-apartments"
    }, {
        n: "Al Fahad Towers",
        s: "dubai-barsha-heights-tecom-al-fahad-towers"
    }, {
        n: "Green Community Motor City",
        s: "dubai-motor-city-green-community-motor-city"
    }, {
        n: "Control Tower",
        s: "dubai-motor-city-control-tower"
    }, {
        n: "Rabdan Building",
        s: "dubai-motor-city-rabdan-building"
    }, {
        n: "Claverton House",
        s: "dubai-motor-city-claverton-house"
    }, {
        n: "Nad Al Sheba 2",
        s: "dubai-nadd-al-sheba-nad-al-sheba-2"
    }, {
        n: "Nad Al Sheba 3",
        s: "dubai-nadd-al-sheba-nad-al-sheba-3"
    }, {
        n: "Al Shafar Tower",
        s: "dubai-barsha-heights-tecom-al-shafar-tower"
    }, {
        n: "Al Hassani Tower",
        s: "dubai-barsha-heights-tecom-al-hassani-tower"
    }, {
        n: "Millennium Place Barsha Heights Hotel",
        s: "dubai-barsha-heights-tecom-millennium-place-barsha-heights-hotel"
    }, {
        n: "Al Shaiba Towers",
        s: "dubai-barsha-heights-tecom-al-shaiba-towers"
    }, {
        n: "Smart Heights",
        s: "dubai-barsha-heights-tecom-smart-heights"
    }, {
        n: "Capitol Tower",
        s: "dubai-barsha-heights-tecom-capitol-tower"
    }, {
        n: "First Central Hotel Apartments",
        s: "dubai-barsha-heights-tecom-first-central-hotel-apartments"
    }, {
        n: "Al Thuraya Towers",
        s: "dubai-barsha-heights-tecom-al-thuraya-towers"
    }, {
        n: "Sheikha Noor Tower",
        s: "dubai-barsha-heights-tecom-sheikha-noor-tower"
    }, {
        n: "Damac Executive Heights",
        s: "dubai-barsha-heights-tecom-damac-executive-heights"
    }, {
        n: "Crystal Blue Tower",
        s: "dubai-barsha-heights-tecom-crystal-blue-tower"
    }, {
        n: "InterCity Hotel Apartments",
        s: "dubai-barsha-heights-tecom-inter-city-hotel-apartments"
    }, {
        n: "Somewhere Hotel",
        s: "dubai-barsha-heights-tecom-somewhere-hotel"
    }, {
        n: "API Residency",
        s: "dubai-barsha-heights-tecom-api-residency"
    }, {
        n: "Almeer Building",
        s: "dubai-barsha-heights-tecom-almeer-building"
    }, {
        n: "Elite Towers",
        s: "dubai-city-of-arabia-elite-towers"
    }, {
        n: "Cayan Business Center",
        s: "dubai-barsha-heights-tecom-cayan-business-center"
    }, {
        n: "Wind West Home",
        s: "dubai-barsha-heights-tecom-wind-west-home"
    }, {
        n: "Elegance House",
        s: "dubai-barsha-heights-tecom-elegance-house"
    }, {
        n: "Tecom Two Towers",
        s: "dubai-barsha-heights-tecom-tecom-two-towers"
    }, {
        n: "Liwa Heights Tower",
        s: "dubai-barsha-heights-tecom-liwa-heights-tower"
    }, {
        n: "Al Noor Towers",
        s: "dubai-barsha-heights-tecom-al-noor-towers"
    }, {
        n: "Oak Time Residence",
        s: "dubai-barsha-heights-tecom-oak-time-residence"
    }, {
        n: "Layia Oak Hotel & suites",
        s: "dubai-barsha-heights-tecom-layia-oak-hotel-suites"
    }, {
        n: "Tecom Villas Complex",
        s: "dubai-barsha-heights-tecom-tecom-villas-complex"
    }, {
        n: "Art 12",
        s: "dubai-barsha-heights-tecom-art-12"
    }, {
        n: "Grand Central Hotel",
        s: "dubai-barsha-heights-tecom-grand-central-hotel"
    }, {
        n: "TIME Crystal Hotel Apartments",
        s: "dubai-barsha-heights-tecom-time-crystal-hotel-apartments"
    }, {
        n: "Time Oak Hotel",
        s: "dubai-barsha-heights-tecom-time-oak-hotel"
    }, {
        n: "Art Towers",
        s: "dubai-barsha-heights-tecom-art-towers"
    }, {
        n: "Rania Residence",
        s: "dubai-barsha-heights-tecom-rania-residence"
    }, {
        n: "Gulf Oasis Hotel Apartment",
        s: "dubai-barsha-heights-tecom-gulf-oasis-hotel-apartment"
    }, {
        n: "Rosemont Hotel & Residence",
        s: "dubai-barsha-heights-tecom-rosemont-hotel-residence"
    }, {
        n: "The One Tower",
        s: "dubai-barsha-heights-tecom-the-one-tower"
    }, {
        n: "Mirdif Hills",
        s: "dubai-mirdif-mirdif-hills"
    }, {
        n: "Mirdif Villas",
        s: "dubai-mirdif-mirdif-villas"
    }, {
        n: "District 7",
        s: "dubai-mohammed-bin-rashid-city-district-7"
    }, {
        n: "Madison Residency",
        s: "dubai-barsha-heights-tecom-madison-residency"
    }, {
        n: "Al Shahd Tower",
        s: "dubai-barsha-heights-tecom-al-shahd-tower"
    }, {
        n: "Boutique 7 Hotel Apartments",
        s: "dubai-barsha-heights-tecom-boutique-7-hotel-apartments"
    }, {
        n: "Euro Tower",
        s: "dubai-barsha-heights-tecom-euro-tower"
    }, {
        n: "Al Khatoom",
        s: "dubai-barsha-heights-tecom-al-khatoom"
    }, {
        n: "Yassat Gloria Hotel Apartments",
        s: "dubai-barsha-heights-tecom-yassat-gloria-hotel-apartments"
    }, {
        n: "Grand Midwest Reve Hotel Apartments",
        s: "dubai-barsha-heights-tecom-grand-midwest-reve-hotel-apartments"
    }, {
        n: "Rose 6",
        s: "dubai-barsha-heights-tecom-rose-6"
    }, {
        n: "Al Asmawi Building",
        s: "dubai-barsha-heights-tecom-al-asmawi-building"
    }, {
        n: "Two Towers",
        s: "dubai-barsha-heights-tecom-two-towers"
    }, {
        n: "Citadines Metro Central Hotel Apartments",
        s: "dubai-barsha-heights-tecom-citadines-metro-central-hotel-apartments"
    }, {
        n: "Grand Millennium Hotel",
        s: "dubai-barsha-heights-tecom-grand-millennium-hotel"
    }, {
        n: "Mashael",
        s: "dubai-barsha-heights-tecom-mashael"
    }, {
        n: "Gargash 16 Villas",
        s: "dubai-mirdif-gargash-16-villas"
    }, {
        n: "Central Building",
        s: "dubai-mirdif-central-building"
    }, {
        n: "Meadows 3",
        s: "dubai-meadows-meadows-3"
    }, {
        n: "Ras Al Khor Industrial",
        s: "dubai-ras-al-khor-ras-al-khor-industrial"
    }, {
        n: "Arno",
        s: "dubai-the-views-arno"
    }, {
        n: "Turia",
        s: "dubai-the-views-turia"
    }, {
        n: "Al Badiya",
        s: "dubai-mirdif-al-badiya"
    }, {
        n: "Meadows 7",
        s: "dubai-meadows-meadows-7"
    }, {
        n: "Meadows 2",
        s: "dubai-meadows-meadows-2"
    }, {
        n: "Nadd Al Hammar Villas",
        s: "dubai-nadd-al-hammar-nadd-al-hammar-villas"
    }, {
        n: "Amaya Plaza",
        s: "dubai-nadd-al-hammar-amaya-plaza"
    }, {
        n: "Gemini Splendor",
        s: "dubai-mohammed-bin-rashid-city-gemini-splendor"
    }, {
        n: "Noah's Ark Tower",
        s: "dubai-palm-jebel-ali-noah-s-ark-tower"
    }, {
        n: "Zulal",
        s: "dubai-the-lakes-zulal"
    }, {
        n: "The Windsor",
        s: "dubai-city-of-arabia-the-windsor"
    }, {
        n: "G Office Tower",
        s: "dubai-city-of-arabia-g-office-tower"
    }, {
        n: "Sherena Residence",
        s: "dubai-majan-sherena-residence"
    }, {
        n: "Zen by Indigo",
        s: "dubai-golf-city-zen-by-indigo"
    }, {
        n: "Paradise View 1",
        s: "dubai-majan-paradise-view-1"
    }, {
        n: "Mushraif",
        s: "dubai-mirdif-mushraif"
    }, {
        n: "Meadows 4",
        s: "dubai-meadows-meadows-4"
    }, {
        n: "Al Khair Building",
        s: "dubai-nadd-al-hammar-al-khair-building"
    }, {
        n: "SAW",
        s: "dubai-ras-al-khor-saw"
    }, {
        n: "Mosela",
        s: "dubai-the-views-mosela"
    }, {
        n: "Golf Villas",
        s: "dubai-the-views-golf-villas"
    }, {
        n: "The Fairways",
        s: "dubai-the-views-the-fairways"
    }, {
        n: "Glitz",
        s: "dubai-dubai-studio-city-glitz"
    }, {
        n: "The Hive",
        s: "dubai-dubai-studio-city-the-hive"
    }, {
        n: "Zabeel 2",
        s: "dubai-zabeel-zabeel-2"
    }, {
        n: "Wilton Park Residences",
        s: "dubai-mohammed-bin-rashid-city-wilton-park-residences"
    }, {
        n: "District One",
        s: "dubai-mohammed-bin-rashid-city-district-one"
    }, {
        n: "Meadows 9",
        s: "dubai-meadows-meadows-9"
    }, {
        n: "Mohammad Saleh Mohammad Bin Lahej Building",
        s: "dubai-nadd-al-hammar-mohammad-saleh-mohammad-bin-lahej-building"
    }, {
        n: "The Views 2",
        s: "dubai-the-views-the-views-2"
    }, {
        n: "The Views 1",
        s: "dubai-the-views-the-views-1"
    }, {
        n: "The Links",
        s: "dubai-the-views-the-links"
    }, {
        n: "Una",
        s: "dubai-the-views-una"
    }, {
        n: "Commercial Building 1",
        s: "dubai-dubai-studio-city-commercial-building-1"
    }, {
        n: "Studio 101",
        s: "dubai-dubai-studio-city-studio-101"
    }, {
        n: "Vida Za'abeel",
        s: "dubai-zabeel-vida-za-abeel"
    }, {
        n: "Wilton Terraces 1",
        s: "dubai-mohammed-bin-rashid-city-wilton-terraces-1"
    }, {
        n: "The Royal Gardens",
        s: "dubai-mohammed-bin-rashid-city-the-royal-gardens"
    }, {
        n: "Sobha Hartland",
        s: "dubai-mohammed-bin-rashid-city-sobha-hartland"
    }, {
        n: "The Cove",
        s: "dubai-palm-jebel-ali-the-cove"
    }, {
        n: "Blue Moon Tower",
        s: "dubai-palm-jebel-ali-blue-moon-tower"
    }, {
        n: "Palm Springs",
        s: "dubai-palm-jebel-ali-palm-springs"
    }, {
        n: "Forat",
        s: "dubai-the-lakes-forat"
    }, {
        n: "MAG 230",
        s: "dubai-city-of-arabia-mag-230"
    }, {
        n: "Wadi Walk",
        s: "dubai-city-of-arabia-wadi-walk"
    }, {
        n: "Wings of Arabia",
        s: "dubai-city-of-arabia-wings-of-arabia"
    }, {
        n: "Bashayer 3",
        s: "dubai-nadd-al-hammar-bashayer-3"
    }, {
        n: "Golf Towers",
        s: "dubai-the-views-golf-towers"
    }, {
        n: "Tanaro",
        s: "dubai-the-views-tanaro"
    }, {
        n: "Panorama at the Views",
        s: "dubai-the-views-panorama-at-the-views"
    }, {
        n: "Al Khazan Building",
        s: "dubai-umm-ramool-al-khazan-building"
    }, {
        n: "Samana Golf Avenue",
        s: "dubai-dubai-studio-city-samana-golf-avenue"
    }, {
        n: "Azizi Mirage 1",
        s: "dubai-dubai-studio-city-azizi-mirage-1"
    }, {
        n: "Zabeel 1",
        s: "dubai-zabeel-zabeel-1"
    }, {
        n: "Wilton Terraces 2",
        s: "dubai-mohammed-bin-rashid-city-wilton-terraces-2"
    }, {
        n: "The Residences at District One",
        s: "dubai-mohammed-bin-rashid-city-the-residences-at-district-one"
    }, {
        n: "District 11",
        s: "dubai-mohammed-bin-rashid-city-district-11"
    }, {
        n: "Signature Villas",
        s: "dubai-palm-jebel-ali-signature-villas"
    }, {
        n: "Waterfront",
        s: "dubai-palm-jebel-ali-waterfront"
    }, {
        n: "Water Homes",
        s: "dubai-palm-jebel-ali-water-homes"
    }, {
        n: "Deema",
        s: "dubai-the-lakes-deema"
    }, {
        n: "Hattan",
        s: "dubai-the-lakes-hattan"
    }, {
        n: "Wadi Tower",
        s: "dubai-city-of-arabia-wadi-tower"
    }, {
        n: "Travo",
        s: "dubai-the-views-travo"
    }, {
        n: "Canal Villas",
        s: "dubai-the-views-canal-villas"
    }, {
        n: "RAS",
        s: "dubai-umm-ramool-ras"
    }, {
        n: "Al Fattan Sky Towers",
        s: "dubai-umm-ramool-al-fattan-sky-towers"
    }, {
        n: "Commercial Building 2",
        s: "dubai-dubai-studio-city-commercial-building-2"
    }, {
        n: "Olivara Residences",
        s: "dubai-dubai-studio-city-olivara-residences"
    }, {
        n: "The Azizi Fontanne",
        s: "dubai-palm-jebel-ali-the-azizi-fontanne"
    }, {
        n: "Azizi Crystal",
        s: "dubai-palm-jebel-ali-azizi-crystal"
    }, {
        n: "Azizi Diamond",
        s: "dubai-palm-jebel-ali-azizi-diamond"
    }, {
        n: "Ghadeer",
        s: "dubai-the-lakes-ghadeer"
    }, {
        n: "Mag 220",
        s: "dubai-city-of-arabia-mag-220"
    }, {
        n: "Avenue 353",
        s: "dubai-city-of-arabia-avenue-353"
    }, {
        n: "I and M Tower",
        s: "dubai-city-of-arabia-i-and-m-tower"
    }, {
        n: "Al Rabia Tower",
        s: "dubai-majan-al-rabia-tower"
    }, {
        n: "Mezen 2",
        s: "dubai-majan-mezen-2"
    }, {
        n: "Mezen 3",
        s: "dubai-majan-mezen-3"
    }, {
        n: "Sooma Residence",
        s: "dubai-majan-sooma-residence"
    }, {
        n: "Arabic Villas",
        s: "dubai-mohammed-bin-rashid-city-arabic-villas"
    }, {
        n: "Mediterranean Villas",
        s: "dubai-mohammed-bin-rashid-city-mediterranean-villas"
    }, {
        n: "Azizi Platine",
        s: "dubai-palm-jebel-ali-azizi-platine"
    }, {
        n: "Nathalie Tower",
        s: "dubai-palm-jebel-ali-nathalie-tower"
    }, {
        n: "Garden Homes",
        s: "dubai-palm-jebel-ali-garden-homes"
    }, {
        n: "Maeen",
        s: "dubai-the-lakes-maeen"
    }, {
        n: "Metro Tower",
        s: "dubai-city-of-arabia-metro-tower"
    }, {
        n: "Madison Residences",
        s: "dubai-majan-madison-residences"
    }, {
        n: "Majan One Residences",
        s: "dubai-majan-majan-one-residences"
    }, {
        n: "Mira Oasis",
        s: "dubai-reem-mira-oasis"
    }, {
        n: "Claret",
        s: "dubai-akoya-claret"
    }, {
        n: "Mimosa",
        s: "dubai-akoya-mimosa"
    }, {
        n: "Ettore 971 Bugatti Style Apartments",
        s: "dubai-akoya-ettore-971-bugatti-style-apartments"
    }, {
        n: "The Woods",
        s: "dubai-akoya-the-woods"
    }, {
        n: "Primrose",
        s: "dubai-akoya-primrose"
    }, {
        n: "Ettore 971 Bugatti Style Villas",
        s: "dubai-akoya-ettore-971-bugatti-style-villas"
    }, {
        n: "Pacifica",
        s: "dubai-akoya-pacifica"
    }, {
        n: "Mira",
        s: "dubai-reem-mira"
    }, {
        n: "Avencia",
        s: "dubai-akoya-avencia"
    }, {
        n: "Akoya Selfie",
        s: "dubai-akoya-akoya-selfie"
    }, {
        n: "Paloverde",
        s: "dubai-akoya-paloverde"
    }, {
        n: "Akoya Play",
        s: "dubai-akoya-akoya-play"
    }, {
        n: "Odora",
        s: "dubai-akoya-odora"
    }, {
        n: "Adria Villas",
        s: "dubai-akoya-adria-villas"
    }, {
        n: "Aster",
        s: "dubai-akoya-aster"
    }, {
        n: "Trinity",
        s: "dubai-damac-hills-akoya-by-damac-trinity"
    }, {
        n: "Golf Town",
        s: "dubai-damac-hills-akoya-by-damac-golf-town"
    }, {
        n: "Whitefield",
        s: "dubai-damac-hills-akoya-by-damac-whitefield"
    }, {
        n: "Topanga",
        s: "dubai-damac-hills-akoya-by-damac-topanga"
    }, {
        n: "Golf Promenade",
        s: "dubai-damac-hills-akoya-by-damac-golf-promenade"
    }, {
        n: "Al Muhaisnah 1",
        s: "dubai-al-muhaisnah-al-muhaisnah-1"
    }, {
        n: "Mankhool",
        s: "dubai-bur-dubai-mankhool"
    }, {
        n: "Khalid Bin Al Waleed Road",
        s: "dubai-bur-dubai-khalid-bin-al-waleed-road"
    }, {
        n: "Khalifa Building",
        s: "dubai-al-lisaili-khalifa-building"
    }, {
        n: "Karawan",
        s: "dubai-forbidden-city-karawan"
    }, {
        n: "Club Villas at Dubai Hills",
        s: "dubai-dubai-hills-estate-club-villas-at-dubai-hills"
    }, {
        n: "Emerald Hills",
        s: "dubai-dubai-hills-estate-emerald-hills"
    }, {
        n: "Majestic Vistas",
        s: "dubai-dubai-hills-estate-majestic-vistas"
    }, {
        n: "Aquilegia",
        s: "dubai-akoya-aquilegia"
    }, {
        n: "Basswood",
        s: "dubai-akoya-basswood"
    }, {
        n: "Fiora Golf Verde",
        s: "dubai-akoya-fiora-golf-verde"
    }, {
        n: "Amazonia",
        s: "dubai-akoya-amazonia"
    }, {
        n: "Sanctnary",
        s: "dubai-akoya-sanctnary"
    }, {
        n: "Nova Hotel Villas",
        s: "dubai-akoya-nova-hotel-villas"
    }, {
        n: "Silver Springs",
        s: "dubai-damac-hills-akoya-by-damac-silver-springs"
    }, {
        n: "Akoya Imagine",
        s: "dubai-damac-hills-akoya-by-damac-akoya-imagine"
    }, {
        n: "Phoenix",
        s: "dubai-damac-hills-akoya-by-damac-phoenix"
    }, {
        n: "Akoya Fresh",
        s: "dubai-damac-hills-akoya-by-damac-akoya-fresh"
    }, {
        n: "Trump PRVT",
        s: "dubai-damac-hills-akoya-by-damac-trump-prvt"
    }, {
        n: "The Park Villas",
        s: "dubai-damac-hills-akoya-by-damac-the-park-villas"
    }, {
        n: "The Turf",
        s: "dubai-damac-hills-akoya-by-damac-the-turf"
    }, {
        n: "NAIA Golf Promenade",
        s: "dubai-damac-hills-akoya-by-damac-naia-golf-promenade"
    }, {
        n: "Golf Vista",
        s: "dubai-damac-hills-akoya-by-damac-golf-vista"
    }, {
        n: "Emirates Golf Club Residences",
        s: "dubai-emirates-golf-club-emirates-golf-club-residences"
    }, {
        n: "Sonapur",
        s: "dubai-al-muhaisnah-sonapur"
    }, {
        n: "Viridis Residence and Hotel Apartments",
        s: "dubai-akoya-viridis-residence-and-hotel-apartments"
    }, {
        n: "Aknan Villas",
        s: "dubai-akoya-aknan-villas"
    }, {
        n: "Vista Lux",
        s: "dubai-akoya-vista-lux"
    }, {
        n: "The Rainforest",
        s: "dubai-akoya-the-rainforest"
    }, {
        n: "Vardon",
        s: "dubai-akoya-vardon"
    }, {
        n: "Sycamore",
        s: "dubai-akoya-sycamore"
    }, {
        n: "Kensington Boutique Villas",
        s: "dubai-akoya-kensington-boutique-villas"
    }, {
        n: "The Field",
        s: "dubai-damac-hills-akoya-by-damac-the-field"
    }, {
        n: "Rochester",
        s: "dubai-damac-hills-akoya-by-damac-rochester"
    }, {
        n: "Golf Panorama",
        s: "dubai-damac-hills-akoya-by-damac-golf-panorama"
    }, {
        n: "Trevi",
        s: "dubai-damac-hills-akoya-by-damac-trevi"
    }, {
        n: "The Drive",
        s: "dubai-damac-hills-akoya-by-damac-the-drive"
    }, {
        n: "Golf Vita",
        s: "dubai-damac-hills-akoya-by-damac-golf-vita"
    }, {
        n: "NAIA Golf Terrace at Akoya",
        s: "dubai-damac-hills-akoya-by-damac-naia-golf-terrace-at-akoya"
    }, {
        n: "Golf Horizon",
        s: "dubai-damac-hills-akoya-by-damac-golf-horizon"
    }, {
        n: "Al Muhaisnah 2",
        s: "dubai-al-muhaisnah-al-muhaisnah-2"
    }, {
        n: "Mankhool Road",
        s: "dubai-bur-dubai-mankhool-road"
    }, {
        n: "BBZ",
        s: "dubai-bur-dubai-bbz"
    }, {
        n: "Al Hamriya Building",
        s: "dubai-bur-dubai-al-hamriya-building"
    }, {
        n: "Mirar Residences",
        s: "dubai-du-biotech-mirar-residences"
    }, {
        n: "Primerose",
        s: "dubai-akoya-primerose"
    }, {
        n: "Zinnia",
        s: "dubai-akoya-zinnia"
    }, {
        n: "Amargo",
        s: "dubai-akoya-amargo"
    }, {
        n: "Amora in Golf Verde",
        s: "dubai-akoya-amora-in-golf-verde"
    }, {
        n: "Coursetia",
        s: "dubai-akoya-coursetia"
    }, {
        n: "Juniper",
        s: "dubai-akoya-juniper"
    }, {
        n: "Akoya Drive",
        s: "dubai-damac-hills-akoya-by-damac-akoya-drive"
    }, {
        n: "Orchid",
        s: "dubai-damac-hills-akoya-by-damac-orchid"
    }, {
        n: "Longview",
        s: "dubai-damac-hills-akoya-by-damac-longview"
    }, {
        n: "Artesia",
        s: "dubai-damac-hills-akoya-by-damac-artesia"
    }, {
        n: "Akoya Park",
        s: "dubai-damac-hills-akoya-by-damac-akoya-park"
    }, {
        n: "Rockwood",
        s: "dubai-damac-hills-akoya-by-damac-rockwood"
    }, {
        n: "Loreto",
        s: "dubai-damac-hills-akoya-by-damac-loreto"
    }, {
        n: "Al Muhaisnah 3",
        s: "dubai-al-muhaisnah-al-muhaisnah-3"
    }, {
        n: "FTC",
        s: "dubai-bur-dubai-ftc"
    }, {
        n: "Al Ghubaiba Area",
        s: "dubai-bur-dubai-al-ghubaiba-area"
    }, {
        n: "Khalid Al Attar Residence",
        s: "dubai-bur-dubai-khalid-al-attar-residence"
    }, {
        n: "Sea View Residence",
        s: "dubai-bur-dubai-sea-view-residence"
    }, {
        n: "NCC Harmony Twin Tower",
        s: "dubai-du-biotech-ncc-harmony-twin-tower"
    }, {
        n: "Pearl Residences",
        s: "dubai-dubai-pearl-pearl-residences"
    }, {
        n: "Tillali",
        s: "dubai-forbidden-city-tillali"
    }, {
        n: "Jasmine",
        s: "dubai-damac-hills-akoya-by-damac-jasmine"
    }, {
        n: "Queens Meadow",
        s: "dubai-damac-hills-akoya-by-damac-queens-meadow"
    }, {
        n: "Radisson Dubai DAMAC Hills",
        s: "dubai-damac-hills-akoya-by-damac-radisson-dubai-damac-hills"
    }, {
        n: "Calero",
        s: "dubai-damac-hills-akoya-by-damac-calero"
    }, {
        n: "DAMAC Villas by Paramount Hotels and Resorts",
        s: "dubai-damac-hills-akoya-by-damac-damac-villas-by-paramount-hotels-and-resorts"
    }, {
        n: "Golf Veduta Hotel Apartments",
        s: "dubai-damac-hills-akoya-by-damac-golf-veduta-hotel-apartments"
    }, {
        n: "Picadilly Green",
        s: "dubai-damac-hills-akoya-by-damac-picadilly-green"
    }, {
        n: "Al Muhaisnah 4",
        s: "dubai-al-muhaisnah-al-muhaisnah-4"
    }, {
        n: "Oud Metha",
        s: "dubai-bur-dubai-oud-metha"
    }, {
        n: "Al Souk Al Kabeer",
        s: "dubai-bur-dubai-al-souk-al-kabeer"
    }, {
        n: "MBK",
        s: "dubai-bur-dubai-mbk"
    }, {
        n: "Sallal",
        s: "dubai-forbidden-city-sallal"
    }, {
        n: "Golf Place",
        s: "dubai-dubai-hills-estate-golf-place"
    }, {
        n: "Hills Business Park",
        s: "dubai-dubai-hills-estate-hills-business-park"
    }, {
        n: "Park Heights",
        s: "dubai-dubai-hills-estate-park-heights"
    }, {
        n: "Lebanon Island",
        s: "dubai-the-world-islands-lebanon-island"
    }, {
        n: "Sao Paolo",
        s: "dubai-the-world-islands-sao-paolo"
    }, {
        n: "S S S A M Building",
        s: "dubai-meydan-s-s-s-a-m-building"
    }, {
        n: "Shakespeare",
        s: "dubai-living-legends-shakespeare"
    }, {
        n: "Snow White",
        s: "dubai-living-legends-snow-white"
    }, {
        n: "PMS",
        s: "dubai-bur-dubai-pms"
    }, {
        n: "Montrose",
        s: "dubai-du-biotech-montrose"
    }, {
        n: "Rashid Building",
        s: "dubai-al-lisaili-rashid-building"
    }, {
        n: "Ghawway",
        s: "dubai-forbidden-city-ghawway"
    }, {
        n: "Socio Tower",
        s: "dubai-dubai-hills-estate-socio-tower"
    }, {
        n: "Golf Grove",
        s: "dubai-dubai-hills-estate-golf-grove"
    }, {
        n: "Park Point",
        s: "dubai-dubai-hills-estate-park-point"
    }, {
        n: "Yansoon",
        s: "dubai-old-town-yansoon"
    }, {
        n: "Kamoon",
        s: "dubai-old-town-kamoon"
    }, {
        n: "Sweden",
        s: "dubai-the-world-islands-sweden"
    }, {
        n: "Perseus - Rostov Island",
        s: "dubai-the-world-islands-perseus-rostov-island"
    }, {
        n: "Al Warsan 1",
        s: "dubai-al-warsan-al-warsan-1"
    }, {
        n: "Taupe Residences",
        s: "dubai-meydan-taupe-residences"
    }, {
        n: "Meydan Avenue",
        s: "dubai-meydan-meydan-avenue"
    }, {
        n: "Casa",
        s: "dubai-arabian-ranches-2-casa"
    }, {
        n: "Rosa",
        s: "dubai-arabian-ranches-2-rosa"
    }, {
        n: "La Mer",
        s: "dubai-jumeirah-la-mer"
    }, {
        n: "Umm Hurair 2",
        s: "dubai-umm-hurair-umm-hurair-2"
    }, {
        n: "4Direction Residence 1",
        s: "dubai-dubai-residence-complex-4direction-residence-1"
    }, {
        n: "Safeer Residence 3",
        s: "dubai-dubai-residence-complex-safeer-residence-3"
    }, {
        n: "Etlala Residence",
        s: "dubai-dubai-residence-complex-etlala-residence"
    }, {
        n: "Al Awazi Residences",
        s: "dubai-dubai-residence-complex-al-awazi-residences"
    }, {
        n: "The Gate Residence 1",
        s: "dubai-dubai-residence-complex-the-gate-residence-1"
    }, {
        n: "Marco Polo",
        s: "dubai-living-legends-marco-polo"
    }, {
        n: "Queen Sheba",
        s: "dubai-living-legends-queen-sheba"
    }, {
        n: "Hud Hud",
        s: "dubai-forbidden-city-hud-hud"
    }, {
        n: "Park Heights 2",
        s: "dubai-dubai-hills-estate-park-heights-2"
    }, {
        n: "Sidra Villas",
        s: "dubai-dubai-hills-estate-sidra-villas"
    }, {
        n: "Parkway Vistas",
        s: "dubai-dubai-hills-estate-parkway-vistas"
    }, {
        n: "Reehan",
        s: "dubai-old-town-reehan"
    }, {
        n: "Zanzebeel",
        s: "dubai-old-town-zanzebeel"
    }, {
        n: "Oqyana",
        s: "dubai-the-world-islands-oqyana"
    }, {
        n: "Asia",
        s: "dubai-the-world-islands-asia"
    }, {
        n: "Al Warsan 2",
        s: "dubai-al-warsan-al-warsan-2"
    }, {
        n: "The Derby Residences",
        s: "dubai-meydan-the-derby-residences"
    }, {
        n: "Reem Community",
        s: "dubai-arabian-ranches-2-reem-community"
    }, {
        n: "Samara",
        s: "dubai-arabian-ranches-2-samara"
    }, {
        n: "Sinbad",
        s: "dubai-living-legends-sinbad"
    }, {
        n: "Ali Baba",
        s: "dubai-living-legends-ali-baba"
    }, {
        n: "Maple at Dubai Hills Estate",
        s: "dubai-dubai-hills-estate-maple-at-dubai-hills-estate"
    }, {
        n: "Miska",
        s: "dubai-old-town-miska"
    }, {
        n: "Europe",
        s: "dubai-the-world-islands-europe"
    }, {
        n: "Coral Island",
        s: "dubai-the-world-islands-coral-island"
    }, {
        n: "Al Warsan 4",
        s: "dubai-al-warsan-al-warsan-4"
    }, {
        n: "Meydan Gated Community",
        s: "dubai-meydan-meydan-gated-community"
    }, {
        n: "Meydan One",
        s: "dubai-meydan-meydan-one"
    }, {
        n: "Palma",
        s: "dubai-arabian-ranches-2-palma"
    }, {
        n: "Yasmin",
        s: "dubai-arabian-ranches-2-yasmin"
    }, {
        n: "Jumeirah 3",
        s: "dubai-jumeirah-jumeirah-3"
    }, {
        n: "Jumeirah 2",
        s: "dubai-jumeirah-jumeirah-2"
    }, {
        n: "Sondos Sage",
        s: "dubai-dubai-residence-complex-sondos-sage"
    }, {
        n: "Phoenix Tower",
        s: "dubai-dubai-residence-complex-phoenix-tower"
    }, {
        n: "Cassia Park",
        s: "dubai-dubai-residence-complex-cassia-park"
    }, {
        n: "Sondos Lilac",
        s: "dubai-dubai-residence-complex-sondos-lilac"
    }, {
        n: "Goshi Residence",
        s: "dubai-dubai-residence-complex-goshi-residence"
    }, {
        n: "Residence One",
        s: "dubai-dubai-residence-complex-residence-one"
    }, {
        n: "Napoleon",
        s: "dubai-living-legends-napoleon"
    }, {
        n: "Collective",
        s: "dubai-dubai-hills-estate-collective"
    }, {
        n: "Zaafaran",
        s: "dubai-old-town-zaafaran"
    }, {
        n: "The Heart of Europe",
        s: "dubai-the-world-islands-the-heart-of-europe"
    }, {
        n: "The Floating Seahorse",
        s: "dubai-the-world-islands-the-floating-seahorse"
    }, {
        n: "Al Warsan 3",
        s: "dubai-al-warsan-al-warsan-3"
    }, {
        n: "Meydan Racecourse Villas",
        s: "dubai-meydan-meydan-racecourse-villas"
    }, {
        n: "Camelia",
        s: "dubai-arabian-ranches-2-camelia"
    }, {
        n: "Rasha",
        s: "dubai-arabian-ranches-2-rasha"
    }, {
        n: "Pearl Jumeirah",
        s: "dubai-jumeirah-pearl-jumeirah"
    }, {
        n: "Durar 1",
        s: "dubai-dubai-residence-complex-durar-1"
    }, {
        n: "Gala Residence 1",
        s: "dubai-dubai-residence-complex-gala-residence-1"
    }, {
        n: "Sondos Rosa",
        s: "dubai-dubai-residence-complex-sondos-rosa"
    }, {
        n: "Kappa Acca 2",
        s: "dubai-dubai-residence-complex-kappa-acca-2"
    }, {
        n: "Hercules",
        s: "dubai-living-legends-hercules"
    }, {
        n: "Caesar",
        s: "dubai-living-legends-caesar"
    }, {
        n: "C Villas",
        s: "dubai-living-legends-c-villas"
    }, {
        n: "wasl Topaz",
        s: "dubai-karama-wasl-topaz"
    }, {
        n: "URJ",
        s: "dubai-karama-urj"
    }, {
        n: "Al Attar Shopping Center",
        s: "dubai-karama-al-attar-shopping-center"
    }, {
        n: "Al Kifaf Commercial Building",
        s: "dubai-karama-al-kifaf-commercial-building"
    }, {
        n: "Hamsah A",
        s: "dubai-karama-hamsah-a"
    }, {
        n: "Al Kifaf Apartments",
        s: "dubai-karama-al-kifaf-apartments"
    }, {
        n: "Sultana Building",
        s: "dubai-karama-sultana-building"
    }, {
        n: "wasl Ivory",
        s: "dubai-karama-wasl-ivory"
    }, {
        n: "Carrera Building",
        s: "dubai-karama-carrera-building"
    }, {
        n: "Lila",
        s: "dubai-arabian-ranches-2-lila"
    }, {
        n: "Azalea",
        s: "dubai-arabian-ranches-2-azalea"
    }, {
        n: "Jumeirah 1",
        s: "dubai-jumeirah-jumeirah-1"
    }, {
        n: "Jumeira Beach Road",
        s: "dubai-jumeirah-jumeira-beach-road"
    }, {
        n: "Sondos Orchid",
        s: "dubai-dubai-residence-complex-sondos-orchid"
    }, {
        n: "Ajmal Sarah Tower",
        s: "dubai-dubai-residence-complex-ajmal-sarah-tower"
    }, {
        n: "Nilona Residence",
        s: "dubai-dubai-residence-complex-nilona-residence"
    }, {
        n: "Al Maha Residence",
        s: "dubai-dubai-residence-complex-al-maha-residence"
    }, {
        n: "Munira Residence",
        s: "dubai-dubai-residence-complex-munira-residence"
    }, {
        n: "Sondos Lily",
        s: "dubai-dubai-residence-complex-sondos-lily"
    }, {
        n: "K1",
        s: "dubai-dubai-residence-complex-k1"
    }, {
        n: "Cinderella",
        s: "dubai-living-legends-cinderella"
    }, {
        n: "Cleopatra",
        s: "dubai-living-legends-cleopatra"
    }, {
        n: "A Villas",
        s: "dubai-living-legends-a-villas"
    }, {
        n: "Al Rais Building",
        s: "dubai-karama-al-rais-building"
    }, {
        n: "Shaikh Hamdan Colony",
        s: "dubai-karama-shaikh-hamdan-colony"
    }, {
        n: "Mattar Al Tayer Building 2",
        s: "dubai-karama-mattar-al-tayer-building-2"
    }, {
        n: "City Tower 1 Karama",
        s: "dubai-karama-city-tower-1-karama"
    }, {
        n: "wasl Pearl",
        s: "dubai-karama-wasl-pearl"
    }, {
        n: "Al Habbai Karama Building",
        s: "dubai-karama-al-habbai-karama-building"
    }, {
        n: "Umm Hurair 1",
        s: "dubai-umm-hurair-umm-hurair-1"
    }, {
        n: "Sondos Zinnia",
        s: "dubai-dubai-residence-complex-sondos-zinnia"
    }, {
        n: "Blue Waves Tower",
        s: "dubai-dubai-residence-complex-blue-waves-tower"
    }, {
        n: "Desert Sun",
        s: "dubai-dubai-residence-complex-desert-sun"
    }, {
        n: "Safeer Residence 2",
        s: "dubai-dubai-residence-complex-safeer-residence-2"
    }, {
        n: "Indigo Tower",
        s: "dubai-dubai-residence-complex-indigo-tower"
    }, {
        n: "Solitaire Cascades",
        s: "dubai-dubai-residence-complex-solitaire-cascades"
    }, {
        n: "Al Manzel",
        s: "dubai-karama-al-manzel"
    }, {
        n: "Al Abbas Building",
        s: "dubai-karama-al-abbas-building"
    }, {
        n: "Al Khaleej Building",
        s: "dubai-karama-al-khaleej-building"
    }, {
        n: "Mattar Al Tayer Building 3",
        s: "dubai-karama-mattar-al-tayer-building-3"
    }, {
        n: "Wasl Onyx",
        s: "dubai-karama-wasl-onyx"
    }, {
        n: "Wasl Hub",
        s: "dubai-karama-wasl-hub"
    }, {
        n: "wasl Amber",
        s: "dubai-karama-wasl-amber"
    }, {
        n: "Arif and Bintoak Building",
        s: "dubai-karama-arif-and-bintoak-building"
    }, {
        n: "CBZ",
        s: "dubai-karama-cbz"
    }, {
        n: "City Apartments",
        s: "dubai-karama-city-apartments"
    }, {
        n: "Sheikh Ahmed Square",
        s: "dubai-al-jaddaf-sheikh-ahmed-square"
    }, {
        n: "Marriott Executive Apartments",
        s: "dubai-al-jaddaf-marriott-executive-apartments"
    }, {
        n: "Wasl1",
        s: "dubai-al-kifaf-wasl1"
    }, {
        n: "Eye Park Tower 1",
        s: "dubai-eye-park-eye-park-tower-1"
    }, {
        n: "Eye Park Tower 6",
        s: "dubai-eye-park-eye-park-tower-6"
    }, {
        n: "Wind",
        s: "dubai-jumeirah-golf-estates-wind"
    }, {
        n: "Asmaran",
        s: "dubai-bawadi-asmaran"
    }, {
        n: "The Icon Hotel",
        s: "dubai-dubai-promenade-the-icon-hotel"
    }, {
        n: "Sector H",
        s: "dubai-emirates-hills-sector-h"
    }, {
        n: "Sector R",
        s: "dubai-emirates-hills-sector-r"
    }, {
        n: "Sector P",
        s: "dubai-emirates-hills-sector-p"
    }, {
        n: "wasl port views",
        s: "dubai-al-mina-wasl-port-views"
    }, {
        n: "Veneto",
        s: "dubai-dubai-waterfront-veneto"
    }, {
        n: "Ferretti Tower",
        s: "dubai-dubai-waterfront-ferretti-tower"
    }, {
        n: "Madinat Al Arab",
        s: "dubai-dubai-waterfront-madinat-al-arab"
    }, {
        n: "Orchid Residence",
        s: "dubai-dubai-science-park-orchid-residence"
    }, {
        n: "Janusia",
        s: "dubai-the-roots-akoya-oxygen-janusia"
    }, {
        n: "Golf Apartments",
        s: "ras-al-khaimah-al-hamra-village-golf-apartments"
    }, {
        n: "Freish Al Siyabi Building",
        s: "ras-al-khaimah-mina-al-arab-freish-al-siyabi-building"
    }, {
        n: "Al Durah Building",
        s: "dubai-karama-al-durah-building"
    }, {
        n: "Karama Gold Building",
        s: "dubai-karama-karama-gold-building"
    }, {
        n: "Karama Park Area",
        s: "dubai-karama-karama-park-area"
    }, {
        n: "wasl Zircon",
        s: "dubai-karama-wasl-zircon"
    }, {
        n: "Mattar Al Tayer Building 1",
        s: "dubai-karama-mattar-al-tayer-building-1"
    }, {
        n: "Al Sharafi Building",
        s: "dubai-karama-al-sharafi-building"
    }, {
        n: "Wasl Quartz",
        s: "dubai-karama-wasl-quartz"
    }, {
        n: "Emerald Karama",
        s: "dubai-karama-emerald-karama"
    }, {
        n: "Al Reyami Building",
        s: "dubai-karama-al-reyami-building"
    }, {
        n: "Noor Al Safa Building",
        s: "dubai-al-jaddaf-noor-al-safa-building"
    }, {
        n: "Bayt Al Safa",
        s: "dubai-al-jaddaf-bayt-al-safa"
    }, {
        n: "Eye Park Tower 2",
        s: "dubai-eye-park-eye-park-tower-2"
    }, {
        n: "Eye Park Tower 4",
        s: "dubai-eye-park-eye-park-tower-4"
    }, {
        n: "Hillside at Jumeirah Golf Estates",
        s: "dubai-jumeirah-golf-estates-hillside-at-jumeirah-golf-estates"
    }, {
        n: "Teema",
        s: "dubai-bawadi-teema"
    }, {
        n: "Emirates Hills Villas",
        s: "dubai-emirates-hills-emirates-hills-villas"
    }, {
        n: "Sector HT",
        s: "dubai-emirates-hills-sector-ht"
    }, {
        n: "Caesars Bluewaters Dubai",
        s: "dubai-bluewaters-caesars-bluewaters-dubai"
    }, {
        n: "The Waves",
        s: "dubai-dubai-waterfront-the-waves"
    }, {
        n: "Uptown Waterfront",
        s: "dubai-dubai-waterfront-uptown-waterfront"
    }, {
        n: "The Boulevard",
        s: "dubai-dubai-waterfront-the-boulevard"
    }, {
        n: "Joy",
        s: "dubai-arabian-ranches-3-joy"
    }, {
        n: "Zinnia",
        s: "dubai-the-roots-akoya-oxygen-zinnia"
    }, {
        n: "Northbay Residences",
        s: "ras-al-khaimah-mina-al-arab-northbay-residences"
    }, {
        n: "Malibu",
        s: "ras-al-khaimah-mina-al-arab-malibu"
    }, {
        n: "Flamingo Villas",
        s: "ras-al-khaimah-mina-al-arab-flamingo-villas"
    }, {
        n: "Orchid Residence",
        s: "dubai-al-jaddaf-orchid-residence"
    }, {
        n: "The District",
        s: "dubai-al-jaddaf-the-district"
    }, {
        n: "Binghatti Gateway",
        s: "dubai-al-jaddaf-binghatti-gateway"
    }, {
        n: "Emerald Jadaf 1",
        s: "dubai-al-jaddaf-emerald-jadaf-1"
    }, {
        n: "Dragon Towers",
        s: "dubai-dragon-city-dragon-towers"
    }, {
        n: "Eye Park Tower 5",
        s: "dubai-eye-park-eye-park-tower-5"
    }, {
        n: "Al Andalus",
        s: "dubai-jumeirah-golf-estates-al-andalus"
    }, {
        n: "Earth",
        s: "dubai-jumeirah-golf-estates-earth"
    }, {
        n: "The Boutique Hotel",
        s: "dubai-dubai-promenade-the-boutique-hotel"
    }, {
        n: "Sector E",
        s: "dubai-emirates-hills-sector-e"
    }, {
        n: "Bluewaters Residences",
        s: "dubai-bluewaters-bluewaters-residences"
    }, {
        n: "Aquarius Gate Tower",
        s: "dubai-dubai-waterfront-aquarius-gate-tower"
    }, {
        n: "Beachfront Living",
        s: "dubai-dubai-waterfront-beachfront-living"
    }, {
        n: "Ruba",
        s: "dubai-arabian-ranches-3-ruba"
    }, {
        n: "Trixis",
        s: "dubai-the-roots-akoya-oxygen-trixis"
    }, {
        n: "Dukes The Palm",
        s: "dubai-palm-jumeirah-dukes-the-palm"
    }, {
        n: "Serenia Residences The Palm",
        s: "dubai-palm-jumeirah-serenia-residences-the-palm"
    }, {
        n: "Gateway Towers",
        s: "dubai-palm-jumeirah-gateway-towers"
    }, {
        n: "Viceroy",
        s: "dubai-palm-jumeirah-viceroy"
    }, {
        n: "Garden Homes",
        s: "dubai-palm-jumeirah-garden-homes"
    }, {
        n: "Canal Cove Villas",
        s: "dubai-palm-jumeirah-canal-cove-villas"
    }, {
        n: "Tiara Residences",
        s: "dubai-palm-jumeirah-tiara-residences"
    }, {
        n: "Dubai Trade Centre District",
        s: "dubai-world-trade-center-dubai-trade-centre-district"
    }, {
        n: "The Centro",
        s: "dubai-the-villa-the-centro"
    }, {
        n: "Al Muheirbi Building",
        s: "dubai-al-garhoud-al-muheirbi-building"
    }, {
        n: "Business Park",
        s: "ras-al-khaimah-rak-ftz-business-park"
    }, {
        n: "Reflections Hotel",
        s: "dubai-al-jaddaf-reflections-hotel"
    }, {
        n: "Al Mizhar 1",
        s: "dubai-al-mizhar-al-mizhar-1"
    }, {
        n: "Jumeirah Luxury",
        s: "dubai-jumeirah-golf-estates-jumeirah-luxury"
    }, {
        n: "Fire",
        s: "dubai-jumeirah-golf-estates-fire"
    }, {
        n: "The Marina Towers",
        s: "dubai-dubai-promenade-the-marina-towers"
    }, {
        n: "Sector J",
        s: "dubai-emirates-hills-sector-j"
    }, {
        n: "Al Suhail Tower",
        s: "dubai-dubai-waterfront-al-suhail-tower"
    }, {
        n: "Dream Harbour Tower",
        s: "dubai-dubai-waterfront-dream-harbour-tower"
    }, {
        n: "Spring",
        s: "dubai-arabian-ranches-3-spring"
    }, {
        n: "Acuna",
        s: "dubai-the-roots-akoya-oxygen-acuna"
    }, {
        n: "Signature Villas",
        s: "dubai-palm-jumeirah-signature-villas"
    }, {
        n: "Oceana",
        s: "dubai-palm-jumeirah-oceana"
    }, {
        n: "The Fairmont Palm Residences",
        s: "dubai-palm-jumeirah-the-fairmont-palm-residences"
    }, {
        n: "Palme Couture",
        s: "dubai-palm-jumeirah-palme-couture"
    }, {
        n: "Jumeirah Pointe",
        s: "dubai-palm-jumeirah-jumeirah-pointe"
    }, {
        n: "Kingdom of Sheba",
        s: "dubai-palm-jumeirah-kingdom-of-sheba"
    }, {
        n: "The Palm Tower",
        s: "dubai-palm-jumeirah-the-palm-tower"
    }, {
        n: "Palm Views",
        s: "dubai-palm-jumeirah-palm-views"
    }, {
        n: "Bella Casa",
        s: "dubai-serena-bella-casa"
    }, {
        n: "Elan",
        s: "dubai-tilal-al-ghaf-elan"
    }, {
        n: "World Trade Centre Residence",
        s: "dubai-world-trade-center-world-trade-centre-residence"
    }, {
        n: "Mayan 5",
        s: "abu-dhabi-yas-island-mayan-mayan-5"
    }, {
        n: "Al Mizhar 2",
        s: "dubai-al-mizhar-al-mizhar-2"
    }, {
        n: "Eye Park Tower 3",
        s: "dubai-eye-park-eye-park-tower-3"
    }, {
        n: "Royal Golf Villas",
        s: "dubai-jumeirah-golf-estates-royal-golf-villas"
    }, {
        n: "The Office Tower",
        s: "dubai-dubai-promenade-the-office-tower"
    }, {
        n: "The Beach Towers",
        s: "dubai-dubai-promenade-the-beach-towers"
    }, {
        n: "Sector S",
        s: "dubai-emirates-hills-sector-s"
    }, {
        n: "Sector L",
        s: "dubai-emirates-hills-sector-l"
    }, {
        n: "Riviera",
        s: "dubai-dubai-waterfront-riviera"
    }, {
        n: "The Pixel Tower",
        s: "dubai-dubai-waterfront-the-pixel-tower"
    }, {
        n: "Badrah",
        s: "dubai-dubai-waterfront-badrah"
    }, {
        n: "Aston Towers",
        s: "dubai-dubai-science-park-aston-towers"
    }, {
        n: "Sun",
        s: "dubai-arabian-ranches-3-sun"
    }, {
        n: "FIVE Palm Jumeirah",
        s: "dubai-palm-jumeirah-five-palm-jumeirah"
    }, {
        n: "Centaury",
        s: "dubai-the-roots-akoya-oxygen-centaury"
    }, {
        n: "The Harbour",
        s: "ras-al-khaimah-mina-al-arab-the-harbour"
    }, {
        n: "Azure Residences",
        s: "dubai-palm-jumeirah-azure-residences"
    }, {
        n: "One at Palm Jumeirah",
        s: "dubai-palm-jumeirah-one-at-palm-jumeirah"
    }, {
        n: "Water Villas",
        s: "dubai-palm-jumeirah-water-villas"
    }, {
        n: "Anantara Residences",
        s: "dubai-palm-jumeirah-anantara-residences"
    }, {
        n: "Club Vista Mare",
        s: "dubai-palm-jumeirah-club-vista-mare"
    }, {
        n: "Shoreline Apartments",
        s: "dubai-palm-jumeirah-shoreline-apartments"
    }, {
        n: "Harmony",
        s: "dubai-tilal-al-ghaf-harmony"
    }, {
        n: "The Apartments Dubai World Trade Centre",
        s: "dubai-world-trade-center-the-apartments-dubai-world-trade-centre"
    }, {
        n: "Ponderosa",
        s: "dubai-the-villa-ponderosa"
    }, {
        n: "Al Garhoud Star",
        s: "dubai-al-garhoud-al-garhoud-star"
    }, {
        n: "SBL Building 295",
        s: "dubai-al-garhoud-sbl-building-295"
    }, {
        n: "Al Hilal Building",
        s: "dubai-al-garhoud-al-hilal-building"
    }, {
        n: "Garhoud Views",
        s: "dubai-al-garhoud-garhoud-views"
    }, {
        n: "Grand Mercure Dubai Airport",
        s: "dubai-al-garhoud-grand-mercure-dubai-airport"
    }, {
        n: "Building 4B",
        s: "dubai-city-walk-building-4b"
    }, {
        n: "Industrial Park",
        s: "ras-al-khaimah-rak-ftz-industrial-park"
    }, {
        n: "Solarim Building",
        s: "dubai-dubai-silicon-oasis-solarim-building"
    }, {
        n: "Andaz Dubai The Palm",
        s: "dubai-palm-jumeirah-andaz-dubai-the-palm"
    }, {
        n: "Four Pearls",
        s: "dubai-palm-jumeirah-four-pearls"
    }, {
        n: "Atlantis The Palm",
        s: "dubai-palm-jumeirah-atlantis-the-palm"
    }, {
        n: "The Royal Amwaj",
        s: "dubai-palm-jumeirah-the-royal-amwaj"
    }, {
        n: "Golden Mile",
        s: "dubai-palm-jumeirah-golden-mile"
    }, {
        n: "XXII Carat",
        s: "dubai-palm-jumeirah-xxii-carat"
    }, {
        n: "Casa Viva",
        s: "dubai-serena-casa-viva"
    }, {
        n: "Serenity",
        s: "dubai-tilal-al-ghaf-serenity"
    }, {
        n: "One Central",
        s: "dubai-world-trade-center-one-central"
    }, {
        n: "The Aldea",
        s: "dubai-the-villa-the-aldea"
    }, {
        n: "Manazel Garhoud",
        s: "dubai-al-garhoud-manazel-garhoud"
    }, {
        n: "Rashid Al Majid Building",
        s: "dubai-al-garhoud-rashid-al-majid-building"
    }, {
        n: "Transemirates Building",
        s: "dubai-al-garhoud-transemirates-building"
    }, {
        n: "Al Fattan Plaza",
        s: "dubai-al-garhoud-al-fattan-plaza"
    }, {
        n: "Falcon Villas",
        s: "dubai-al-garhoud-falcon-villas"
    }, {
        n: "La Ville Hotel & Suites Autograph Collection",
        s: "dubai-city-walk-la-ville-hotel-suites-autograph-collection"
    }, {
        n: "Building 24",
        s: "dubai-city-walk-building-24"
    }, {
        n: "Building 23A",
        s: "dubai-city-walk-building-23a"
    }, {
        n: "Building 4A",
        s: "dubai-city-walk-building-4a"
    }, {
        n: "Building 25",
        s: "dubai-city-walk-building-25"
    }, {
        n: "Building 13B",
        s: "dubai-city-walk-building-13b"
    }, {
        n: "Building 20",
        s: "dubai-city-walk-building-20"
    }, {
        n: "Building 11B",
        s: "dubai-city-walk-building-11b"
    }, {
        n: "Dubai Creek Golf and Yacht Club Residences",
        s: "dubai-al-garhoud-dubai-creek-golf-and-yacht-club-residences"
    }, {
        n: "Al Masoud Tower",
        s: "dubai-al-garhoud-al-masoud-tower"
    }, {
        n: "Al Ashram Building",
        s: "dubai-al-garhoud-al-ashram-building"
    }, {
        n: "Essa Hussain Al Yousifi",
        s: "dubai-al-garhoud-essa-hussain-al-yousifi"
    }, {
        n: "Building 22",
        s: "dubai-city-walk-building-22"
    }, {
        n: "Building 18A",
        s: "dubai-city-walk-building-18a"
    }, {
        n: "Building 1",
        s: "dubai-city-walk-building-1"
    }, {
        n: "Building 6A",
        s: "dubai-city-walk-building-6a"
    }, {
        n: "Springs 3",
        s: "dubai-the-springs-springs-3"
    }, {
        n: "Springs 6",
        s: "dubai-the-springs-springs-6"
    }, {
        n: "Siraj Tower",
        s: "dubai-arjan-siraj-tower"
    }, {
        n: "Diamond Business Center",
        s: "dubai-arjan-diamond-business-center"
    }, {
        n: "Vincitore Benessere",
        s: "dubai-arjan-vincitore-benessere"
    }, {
        n: "Vincitore Palacio",
        s: "dubai-arjan-vincitore-palacio"
    }, {
        n: "Lincoln Park",
        s: "dubai-arjan-lincoln-park"
    }, {
        n: "Samana Greens",
        s: "dubai-arjan-samana-greens"
    }, {
        n: "Genesis by Meraki",
        s: "dubai-arjan-genesis-by-meraki"
    }, {
        n: "Islamic Clusters",
        s: "dubai-jumeirah-islands-islamic-clusters"
    }, {
        n: "Tropical Clusters",
        s: "dubai-jumeirah-islands-tropical-clusters"
    }, {
        n: "Cluster 11",
        s: "dubai-jumeirah-islands-cluster-11"
    }, {
        n: "Cluster 28",
        s: "dubai-jumeirah-islands-cluster-28"
    }, {
        n: "Arabian Crown",
        s: "dubai-falcon-city-of-wonders-arabian-crown"
    }, {
        n: "Emitac Building",
        s: "dubai-al-garhoud-emitac-building"
    }, {
        n: "Building 21B",
        s: "dubai-city-walk-building-21b"
    }, {
        n: "Central Park at City Walk",
        s: "dubai-city-walk-central-park-at-city-walk"
    }, {
        n: "Building 17",
        s: "dubai-city-walk-building-17"
    }, {
        n: "Building 6B",
        s: "dubai-city-walk-building-6b"
    }, {
        n: "Building 23B",
        s: "dubai-city-walk-building-23b"
    }, {
        n: "Building 2B",
        s: "dubai-city-walk-building-2b"
    }, {
        n: "Building 18B",
        s: "dubai-city-walk-building-18b"
    }, {
        n: "Building 19",
        s: "dubai-city-walk-building-19"
    }, {
        n: "Building 11A",
        s: "dubai-city-walk-building-11a"
    }, {
        n: "Springs 10",
        s: "dubai-the-springs-springs-10"
    }, {
        n: "Springs 8",
        s: "dubai-the-springs-springs-8"
    }, {
        n: "Priva Living",
        s: "dubai-arjan-priva-living"
    }, {
        n: "Syann Park",
        s: "dubai-arjan-syann-park"
    }, {
        n: "Q Gardens Boutique Residences",
        s: "dubai-arjan-q-gardens-boutique-residences"
    }, {
        n: "Al Fahad  4 Tower",
        s: "dubai-arjan-al-fahad-4-tower"
    }, {
        n: "Miracle Residence",
        s: "dubai-arjan-miracle-residence"
    }, {
        n: "Jewelz by Danube",
        s: "dubai-arjan-jewelz-by-danube"
    }, {
        n: "Building 3B",
        s: "dubai-city-walk-building-3b"
    }, {
        n: "Building 8",
        s: "dubai-city-walk-building-8"
    }, {
        n: "Building 15",
        s: "dubai-city-walk-building-15"
    }, {
        n: "Building 9",
        s: "dubai-city-walk-building-9"
    }, {
        n: "Building 3A",
        s: "dubai-city-walk-building-3a"
    }, {
        n: "Building 16",
        s: "dubai-city-walk-building-16"
    }, {
        n: "Springs 1",
        s: "dubai-the-springs-springs-1"
    }, {
        n: "Springs 12",
        s: "dubai-the-springs-springs-12"
    }, {
        n: "Aryene Greens",
        s: "dubai-arjan-aryene-greens"
    }, {
        n: "Vincitore Boulevard",
        s: "dubai-arjan-vincitore-boulevard"
    }, {
        n: "Samana Hills",
        s: "dubai-arjan-samana-hills"
    }, {
        n: "Resortz by Danube",
        s: "dubai-arjan-resortz-by-danube"
    }, {
        n: "The Light Tower",
        s: "dubai-arjan-the-light-tower"
    }, {
        n: "Central Towers",
        s: "dubai-arjan-central-towers"
    }, {
        n: "European Clusters",
        s: "dubai-jumeirah-islands-european-clusters"
    }, {
        n: "Cluster 10",
        s: "dubai-jumeirah-islands-cluster-10"
    }, {
        n: "Cluster 42",
        s: "dubai-jumeirah-islands-cluster-42"
    }, {
        n: "Cluster 40",
        s: "dubai-jumeirah-islands-cluster-40"
    }, {
        n: "C3 Tower",
        s: "abu-dhabi-al-reem-island-city-of-lights-c3-tower"
    }, {
        n: "Solaris Towers",
        s: "abu-dhabi-al-reem-island-city-of-lights-solaris-towers"
    }, {
        n: "Ocean Pearl",
        s: "abu-dhabi-al-reem-island-city-of-lights-ocean-pearl"
    }, {
        n: "Building 2A",
        s: "dubai-city-walk-building-2a"
    }, {
        n: "Rove City Walk",
        s: "dubai-city-walk-rove-city-walk"
    }, {
        n: "Building 5",
        s: "dubai-city-walk-building-5"
    }, {
        n: "Building 13A",
        s: "dubai-city-walk-building-13a"
    }, {
        n: "Building 21A",
        s: "dubai-city-walk-building-21a"
    }, {
        n: "Building 7",
        s: "dubai-city-walk-building-7"
    }, {
        n: "Springs 5",
        s: "dubai-the-springs-springs-5"
    }, {
        n: "Springs 9",
        s: "dubai-the-springs-springs-9"
    }, {
        n: "La Fontana",
        s: "dubai-arjan-la-fontana"
    }, {
        n: "Green Diamond",
        s: "dubai-arjan-green-diamond"
    }, {
        n: "Geepas Tower",
        s: "dubai-arjan-geepas-tower"
    }, {
        n: "Burj View Residence",
        s: "dubai-arjan-burj-view-residence"
    }, {
        n: "Al Sayyah Residence",
        s: "dubai-arjan-al-sayyah-residence"
    }, {
        n: "Al Dhabi Tower",
        s: "dubai-arjan-al-dhabi-tower"
    }, {
        n: "Cluster 48",
        s: "dubai-jumeirah-islands-cluster-48"
    }, {
        n: "Cluster 32",
        s: "dubai-jumeirah-islands-cluster-32"
    }, {
        n: "Cluster 34",
        s: "dubai-jumeirah-islands-cluster-34"
    }, {
        n: "Cluster 14",
        s: "dubai-jumeirah-islands-cluster-14"
    }, {
        n: "Cluster 35",
        s: "dubai-jumeirah-islands-cluster-35"
    }, {
        n: "Cluster 45",
        s: "dubai-jumeirah-islands-cluster-45"
    }, {
        n: "Costa Del Sol",
        s: "dubai-jumeirah-islands-costa-del-sol"
    }, {
        n: "Springs 14",
        s: "dubai-the-springs-springs-14"
    }, {
        n: "Springs 15",
        s: "dubai-the-springs-springs-15"
    }, {
        n: "Springs 7",
        s: "dubai-the-springs-springs-7"
    }, {
        n: "Platinum One",
        s: "dubai-arjan-platinum-one"
    }, {
        n: "Elz by Danube",
        s: "dubai-arjan-elz-by-danube"
    }, {
        n: "2020 Marquis",
        s: "dubai-arjan-2020-marquis"
    }, {
        n: "The Lily",
        s: "dubai-arjan-the-lily"
    }, {
        n: "Ahmed Al Abdulla Residence",
        s: "dubai-arjan-ahmed-al-abdulla-residence"
    }, {
        n: "Miraclz Tower by Danube",
        s: "dubai-arjan-miraclz-tower-by-danube"
    }, {
        n: "The Wings",
        s: "dubai-arjan-the-wings"
    }, {
        n: "Oasis Clusters",
        s: "dubai-jumeirah-islands-oasis-clusters"
    }, {
        n: "Cluster 12",
        s: "dubai-jumeirah-islands-cluster-12"
    }, {
        n: "Cluster 41",
        s: "dubai-jumeirah-islands-cluster-41"
    }, {
        n: "Cluster 33",
        s: "dubai-jumeirah-islands-cluster-33"
    }, {
        n: "Cluster 17",
        s: "dubai-jumeirah-islands-cluster-17"
    }, {
        n: "Cluster 18",
        s: "dubai-jumeirah-islands-cluster-18"
    }, {
        n: "Cluster 15",
        s: "dubai-jumeirah-islands-cluster-15"
    }, {
        n: "Cluster 44",
        s: "dubai-jumeirah-islands-cluster-44"
    }, {
        n: "Cluster 47",
        s: "dubai-jumeirah-islands-cluster-47"
    }, {
        n: "La Maison",
        s: "dubai-bur-dubai-mankhool-la-maison"
    }, {
        n: "Park Residence",
        s: "dubai-bur-dubai-mankhool-park-residence"
    }, {
        n: "Emerald Mankhool 1",
        s: "dubai-bur-dubai-mankhool-emerald-mankhool-1"
    }, {
        n: "Afaq Building",
        s: "dubai-bur-dubai-mankhool-afaq-building"
    }, {
        n: "Eden",
        s: "dubai-the-valley-eden"
    }, {
        n: "Jumeirah Islands Townhouses",
        s: "dubai-jumeirah-islands-jumeirah-islands-townhouses"
    }, {
        n: "Cluster 39",
        s: "dubai-jumeirah-islands-cluster-39"
    }, {
        n: "Cluster 43",
        s: "dubai-jumeirah-islands-cluster-43"
    }, {
        n: "Cluster 23",
        s: "dubai-jumeirah-islands-cluster-23"
    }, {
        n: "Cluster 24",
        s: "dubai-jumeirah-islands-cluster-24"
    }, {
        n: "Cluster 09",
        s: "dubai-jumeirah-islands-cluster-09"
    }, {
        n: "Cluster 20",
        s: "dubai-jumeirah-islands-cluster-20"
    }, {
        n: "Cluster 36",
        s: "dubai-jumeirah-islands-cluster-36"
    }, {
        n: "Al Razi Building",
        s: "dubai-dubai-healthcare-city-al-razi-building"
    }, {
        n: "Building 72",
        s: "dubai-dubai-healthcare-city-building-72"
    }, {
        n: "Dunes Retreat",
        s: "ras-al-khaimah-mina-al-arab-dunes-retreat"
    }, {
        n: "La Hoya Bay",
        s: "ras-al-khaimah-al-marjan-island-la-hoya-bay"
    }, {
        n: "Cluster 22",
        s: "dubai-jumeirah-islands-cluster-22"
    }, {
        n: "Cluster 19",
        s: "dubai-jumeirah-islands-cluster-19"
    }, {
        n: "Cluster 31",
        s: "dubai-jumeirah-islands-cluster-31"
    }, {
        n: "Cluster 06",
        s: "dubai-jumeirah-islands-cluster-06"
    }, {
        n: "The Mansions",
        s: "dubai-jumeirah-islands-the-mansions"
    }, {
        n: "Mediterranean Clusters",
        s: "dubai-jumeirah-islands-mediterranean-clusters"
    }, {
        n: "Cluster 37",
        s: "dubai-jumeirah-islands-cluster-37"
    }, {
        n: "Vida Hotel",
        s: "dubai-the-hills-vida-hotel"
    }, {
        n: "Grand Hyatt Dubai",
        s: "dubai-dubai-healthcare-city-grand-hyatt-dubai"
    }, {
        n: "Hyatt Regency Creek Heights Residences",
        s: "dubai-dubai-healthcare-city-hyatt-regency-creek-heights-residences"
    }, {
        n: "Building 40",
        s: "dubai-dubai-healthcare-city-building-40"
    }, {
        n: "Al Sufouh 2",
        s: "dubai-al-sufouh-al-sufouh-2"
    }, {
        n: "Chemicals Zone",
        s: "dubai-dubai-industrial-park-chemicals-zone"
    }, {
        n: "Al Sondos Residences 1",
        s: "dubai-dubai-industrial-park-al-sondos-residences-1"
    }, {
        n: "Sirdhana",
        s: "dubai-mina-rashid-sirdhana"
    }, {
        n: "Oasis Tower",
        s: "dubai-sheikh-zayed-road-oasis-tower"
    }, {
        n: "Oasis Center",
        s: "dubai-sheikh-zayed-road-oasis-center"
    }, {
        n: "Emaar Business Park",
        s: "dubai-sheikh-zayed-road-emaar-business-park"
    }, {
        n: "UP Tower",
        s: "dubai-sheikh-zayed-road-up-tower"
    }, {
        n: "Ruby Tower 2",
        s: "dubai-sheikh-zayed-road-ruby-tower-2"
    }, {
        n: "Park Place Tower",
        s: "dubai-sheikh-zayed-road-park-place-tower"
    }, {
        n: "Emirates Tower",
        s: "dubai-sheikh-zayed-road-emirates-tower"
    }, {
        n: "Indigo Central 5",
        s: "dubai-sheikh-zayed-road-indigo-central-5"
    }, {
        n: "Cluster 21",
        s: "dubai-jumeirah-islands-cluster-21"
    }, {
        n: "Cluster 05",
        s: "dubai-jumeirah-islands-cluster-05"
    }, {
        n: "Cluster 08",
        s: "dubai-jumeirah-islands-cluster-08"
    }, {
        n: "Cluster 13",
        s: "dubai-jumeirah-islands-cluster-13"
    }, {
        n: "Cluster 50",
        s: "dubai-jumeirah-islands-cluster-50"
    }, {
        n: "Vida Residence",
        s: "dubai-the-hills-vida-residence"
    }, {
        n: "Sofitel Dubai Wafi",
        s: "dubai-dubai-healthcare-city-sofitel-dubai-wafi"
    }, {
        n: "Azizi Fawad Residence",
        s: "dubai-dubai-healthcare-city-azizi-fawad-residence"
    }, {
        n: "Ibn Sina Building",
        s: "dubai-dubai-healthcare-city-ibn-sina-building"
    }, {
        n: "Farhad Azizi Residence",
        s: "dubai-dubai-healthcare-city-farhad-azizi-residence"
    }, {
        n: "Building 47",
        s: "dubai-dubai-healthcare-city-building-47"
    }, {
        n: "Acacia Avenues",
        s: "dubai-al-sufouh-acacia-avenues"
    }, {
        n: "Sunbeam Homes",
        s: "dubai-dubai-industrial-park-sunbeam-homes"
    }, {
        n: "Sahara Meadows 2",
        s: "dubai-dubai-industrial-park-sahara-meadows-2"
    }, {
        n: "Sahara Meadows",
        s: "dubai-dubai-industrial-park-sahara-meadows"
    }, {
        n: "Al Sondos Residences 3",
        s: "dubai-dubai-industrial-park-al-sondos-residences-3"
    }, {
        n: "Al Attar Tower",
        s: "dubai-sheikh-zayed-road-al-attar-tower"
    }, {
        n: "Monarch Office Tower",
        s: "dubai-sheikh-zayed-road-monarch-office-tower"
    }, {
        n: "Emirates Grand Hotel",
        s: "dubai-sheikh-zayed-road-emirates-grand-hotel"
    }, {
        n: "Julphar Towers",
        s: "ras-al-khaimah-al-nakheel-julphar-towers"
    }, {
        n: "Reehan 7",
        s: "dubai-old-town-reehan-reehan-7"
    }, {
        n: "Miska 2",
        s: "dubai-old-town-miska-miska-2"
    }, {
        n: "Zaafaran 2",
        s: "dubai-old-town-zaafaran-zaafaran-2"
    }, {
        n: "Kamoon 3",
        s: "dubai-old-town-kamoon-kamoon-3"
    }, {
        n: "Zanzebeel 3",
        s: "dubai-old-town-zanzebeel-zanzebeel-3"
    }, {
        n: "Al Khaleej Building",
        s: "dubai-umm-hurair-umm-hurair-1-al-khaleej-building"
    }, {
        n: "Cluster 30",
        s: "dubai-jumeirah-islands-cluster-30"
    }, {
        n: "Cluster 38",
        s: "dubai-jumeirah-islands-cluster-38"
    }, {
        n: "The Hills B",
        s: "dubai-the-hills-the-hills-b"
    }, {
        n: "The Hills C",
        s: "dubai-the-hills-the-hills-c"
    }, {
        n: "Building 25",
        s: "dubai-dubai-healthcare-city-building-25"
    }, {
        n: "Building 53",
        s: "dubai-dubai-healthcare-city-building-53"
    }, {
        n: "Health care City",
        s: "dubai-dubai-healthcare-city-health-care-city"
    }, {
        n: "Building 24",
        s: "dubai-dubai-healthcare-city-building-24"
    }, {
        n: "Al Bahia",
        s: "dubai-al-sufouh-al-bahia"
    }, {
        n: "Base Metal Zone",
        s: "dubai-dubai-industrial-park-base-metal-zone"
    }, {
        n: "Arjumand Offices and Retail",
        s: "dubai-dubai-industrial-park-arjumand-offices-and-retail"
    }, {
        n: "Sahara Living",
        s: "dubai-dubai-industrial-park-sahara-living"
    }, {
        n: "Mineral Products Zone",
        s: "dubai-dubai-industrial-park-mineral-products-zone"
    }, {
        n: "Mazaya Centre",
        s: "dubai-sheikh-zayed-road-mazaya-centre"
    }, {
        n: "Rolex Tower",
        s: "dubai-sheikh-zayed-road-rolex-tower"
    }, {
        n: "Sofitel Hotel",
        s: "dubai-sheikh-zayed-road-sofitel-hotel"
    }, {
        n: "Sheraton Grand Hotel",
        s: "dubai-sheikh-zayed-road-sheraton-grand-hotel"
    }, {
        n: "Brashy Building",
        s: "dubai-sheikh-zayed-road-brashy-building"
    }, {
        n: "Sheikh Rashid Building",
        s: "dubai-sheikh-zayed-road-sheikh-rashid-building"
    }, {
        n: "World Trade Center -commercial",
        s: "dubai-sheikh-zayed-road-world-trade-center-commercial"
    }, {
        n: "Miska 3",
        s: "dubai-old-town-miska-miska-3"
    }, {
        n: "Zaafaran 5",
        s: "dubai-old-town-zaafaran-zaafaran-5"
    }, {
        n: "Kamoon 1",
        s: "dubai-old-town-kamoon-kamoon-1"
    }, {
        n: "Dragon Tower B",
        s: "dubai-dragon-city-dragon-towers-dragon-tower-b"
    }, {
        n: "The Hills A",
        s: "dubai-the-hills-the-hills-a"
    }, {
        n: "Building 26",
        s: "dubai-dubai-healthcare-city-building-26"
    }, {
        n: "Azizi Aliyah",
        s: "dubai-dubai-healthcare-city-azizi-aliyah"
    }, {
        n: "Al Sufouh 1",
        s: "dubai-al-sufouh-al-sufouh-1"
    }, {
        n: "Al Sufouh Road",
        s: "dubai-al-sufouh-al-sufouh-road"
    }, {
        n: "Al Haseen Residences",
        s: "dubai-dubai-industrial-park-al-haseen-residences"
    }, {
        n: "Al Sondos Residences 2",
        s: "dubai-dubai-industrial-park-al-sondos-residences-2"
    }, {
        n: "Machinery & Mechanical Equipment Zone",
        s: "dubai-dubai-industrial-park-machinery-mechanical-equipment-zone"
    }, {
        n: "Capricorn Tower",
        s: "dubai-sheikh-zayed-road-capricorn-tower"
    }, {
        n: "Aspin Tower",
        s: "dubai-sheikh-zayed-road-aspin-tower"
    }, {
        n: "Number One Tower Suites Dubai",
        s: "dubai-sheikh-zayed-road-number-one-tower-suites-dubai"
    }, {
        n: "Eiffel I Building",
        s: "dubai-sheikh-zayed-road-eiffel-i-building"
    }, {
        n: "Single Business Tower",
        s: "dubai-sheikh-zayed-road-single-business-tower"
    }, {
        n: "Saeed Towers",
        s: "dubai-sheikh-zayed-road-saeed-towers"
    }, {
        n: "Indigo Star",
        s: "dubai-sheikh-zayed-road-indigo-star"
    }, {
        n: "Al Moosa Towers",
        s: "dubai-sheikh-zayed-road-al-moosa-towers"
    }, {
        n: "Commercial Bank of Dubai",
        s: "dubai-sheikh-zayed-road-commercial-bank-of-dubai"
    }, {
        n: "The H Hotel",
        s: "dubai-sheikh-zayed-road-the-h-hotel"
    }, {
        n: "Indigo Central 8",
        s: "dubai-sheikh-zayed-road-indigo-central-8"
    }, {
        n: "Millennium Tower",
        s: "dubai-sheikh-zayed-road-millennium-tower"
    }, {
        n: "White Crown Building",
        s: "dubai-sheikh-zayed-road-white-crown-building"
    }, {
        n: "Conrad Hotel",
        s: "dubai-sheikh-zayed-road-conrad-hotel"
    }, {
        n: "Latifa Tower",
        s: "dubai-sheikh-zayed-road-latifa-tower"
    }, {
        n: "21st Century Tower",
        s: "dubai-sheikh-zayed-road-21st-century-tower"
    }, {
        n: "Industrial Zone",
        s: "dubai-dubai-industrial-park-industrial-zone"
    }, {
        n: "Food and Beverage Zone",
        s: "dubai-dubai-industrial-park-food-and-beverage-zone"
    }, {
        n: "The Spanish Villas",
        s: "dubai-dubai-industrial-park-the-spanish-villas"
    }, {
        n: "Transport & Equipment Zone",
        s: "dubai-dubai-industrial-park-transport-equipment-zone"
    }, {
        n: "Al Attar Business Tower",
        s: "dubai-sheikh-zayed-road-al-attar-business-tower"
    }, {
        n: "Voco Dubai",
        s: "dubai-sheikh-zayed-road-voco-dubai"
    }, {
        n: "Maze Tower",
        s: "dubai-sheikh-zayed-road-maze-tower"
    }, {
        n: "Al Manal Tower",
        s: "dubai-sheikh-zayed-road-al-manal-tower"
    }, {
        n: "RP One Tower",
        s: "dubai-sheikh-zayed-road-rp-one-tower"
    }, {
        n: "Al Qasim Building",
        s: "dubai-sheikh-zayed-road-al-qasim-building"
    }, {
        n: "Building 2020",
        s: "dubai-sheikh-zayed-road-building-2020"
    }, {
        n: "Al Salam Hotel Suites",
        s: "dubai-sheikh-zayed-road-al-salam-hotel-suites"
    }, {
        n: "Al Sayyah Building",
        s: "dubai-sheikh-zayed-road-al-sayyah-building"
    }, {
        n: "Shangri-La Hotel",
        s: "dubai-sheikh-zayed-road-shangri-la-hotel"
    }, {
        n: "Al Rostomani Towers",
        s: "dubai-sheikh-zayed-road-al-rostomani-towers"
    }, {
        n: "Conrad Commercial Tower",
        s: "dubai-sheikh-zayed-road-conrad-commercial-tower"
    }, {
        n: "Liberty 1 Building",
        s: "dubai-sheikh-zayed-road-liberty-1-building"
    }, {
        n: "Gevora Hotel",
        s: "dubai-sheikh-zayed-road-gevora-hotel"
    }, {
        n: "Falcon Tower",
        s: "dubai-sheikh-zayed-road-falcon-tower"
    }, {
        n: "Zabeel Tower",
        s: "dubai-sheikh-zayed-road-zabeel-tower"
    }, {
        n: "Concorde Building 2",
        s: "ras-al-khaimah-al-mamourah-concorde-building-2"
    }, {
        n: "Al Wasl Tower",
        s: "dubai-sheikh-zayed-road-al-wasl-tower"
    }, {
        n: "Al Shafar Building",
        s: "dubai-sheikh-zayed-road-al-shafar-building"
    }, {
        n: "Ascott Park Place",
        s: "dubai-sheikh-zayed-road-ascott-park-place"
    }, {
        n: "Al Kawakeb Buildings",
        s: "dubai-sheikh-zayed-road-al-kawakeb-buildings"
    }, {
        n: "Eiffel II Building",
        s: "dubai-sheikh-zayed-road-eiffel-ii-building"
    }, {
        n: "Vakson building",
        s: "dubai-sheikh-zayed-road-vakson-building"
    }, {
        n: "Dubai National Insurance Building",
        s: "dubai-sheikh-zayed-road-dubai-national-insurance-building"
    }, {
        n: "Emirates Towers",
        s: "dubai-sheikh-zayed-road-emirates-towers"
    }, {
        n: "Indigo Central 2",
        s: "dubai-sheikh-zayed-road-indigo-central-2"
    }, {
        n: "Entisar Tower",
        s: "dubai-sheikh-zayed-road-entisar-tower"
    }, {
        n: "API Trio Towers",
        s: "dubai-sheikh-zayed-road-api-trio-towers"
    }, {
        n: "Rahala Residences",
        s: "dubai-sheikh-zayed-road-rahala-residences"
    }, {
        n: "Ghaya Residence",
        s: "dubai-sheikh-zayed-road-ghaya-residence"
    }, {
        n: "Duja Tower",
        s: "dubai-sheikh-zayed-road-duja-tower"
    }, {
        n: "City Towers",
        s: "dubai-sheikh-zayed-road-city-towers"
    }, {
        n: "The Carlton Downtown Hotel",
        s: "dubai-sheikh-zayed-road-the-carlton-downtown-hotel"
    }, {
        n: "Al Yaqoub Tower",
        s: "dubai-sheikh-zayed-road-al-yaqoub-tower"
    }, {
        n: "Nassima Tower",
        s: "dubai-sheikh-zayed-road-nassima-tower"
    }, {
        n: "MAG 5",
        s: "dubai-dubai-south-dubai-world-central-mag-5"
    }, {
        n: "Celestia",
        s: "dubai-dubai-south-dubai-world-central-celestia"
    }, {
        n: "DAMAC Maison de Ville Tenora",
        s: "dubai-dubai-south-dubai-world-central-damac-maison-de-ville-tenora"
    }, {
        n: "Dream Island",
        s: "ras-al-khaimah-al-marjan-island-dream-island"
    }, {
        n: "Breeze Island",
        s: "ras-al-khaimah-al-marjan-island-breeze-island"
    }, {
        n: "Global Sea View",
        s: "ras-al-khaimah-al-marjan-island-global-sea-view"
    }, {
        n: "Terrace Apartments",
        s: "ras-al-khaimah-yasmin-village-terrace-apartments"
    }, {
        n: "Display Centre",
        s: "dubai-sheikh-zayed-road-display-centre"
    }, {
        n: "The Tower",
        s: "dubai-sheikh-zayed-road-the-tower"
    }, {
        n: "Damas Tower",
        s: "dubai-sheikh-zayed-road-damas-tower"
    }, {
        n: "Dusit Hotel",
        s: "dubai-sheikh-zayed-road-dusit-hotel"
    }, {
        n: "Indigo Sky",
        s: "dubai-sheikh-zayed-road-indigo-sky"
    }, {
        n: "Sheikh Eissa Tower",
        s: "dubai-sheikh-zayed-road-sheikh-eissa-tower"
    }, {
        n: "Ibri House",
        s: "dubai-sheikh-zayed-road-ibri-house"
    }, {
        n: "Indigo Central 6",
        s: "dubai-sheikh-zayed-road-indigo-central-6"
    }, {
        n: "Burj Al Salam",
        s: "dubai-sheikh-zayed-road-burj-al-salam"
    }, {
        n: "Sky Tower",
        s: "dubai-sheikh-zayed-road-sky-tower"
    }, {
        n: "API World Tower",
        s: "dubai-sheikh-zayed-road-api-world-tower"
    }, {
        n: "Al Safa Tower",
        s: "dubai-sheikh-zayed-road-al-safa-tower"
    }, {
        n: "Sama Tower",
        s: "dubai-sheikh-zayed-road-sama-tower"
    }, {
        n: "Radisson Royal Hotel",
        s: "dubai-sheikh-zayed-road-radisson-royal-hotel"
    }, {
        n: "Crown Plaza",
        s: "dubai-sheikh-zayed-road-crown-plaza"
    }, {
        n: "The Rubix",
        s: "dubai-dubai-south-dubai-world-central-the-rubix"
    }, {
        n: "Amber Residence",
        s: "dubai-dubai-south-dubai-world-central-amber-residence"
    }, {
        n: "29 Dubai South",
        s: "dubai-dubai-south-dubai-world-central-29-dubai-south"
    }, {
        n: "Kappa Acca 1",
        s: "dubai-dubai-south-dubai-world-central-kappa-acca-1"
    }, {
        n: "Hayat Boulevard",
        s: "dubai-town-square-hayat-boulevard"
    }, {
        n: "Jenna Main Square",
        s: "dubai-town-square-jenna-main-square"
    }, {
        n: "Al Qudra 3",
        s: "dubai-town-square-al-qudra-3"
    }, {
        n: "Warda Apartments",
        s: "dubai-town-square-warda-apartments"
    }, {
        n: "Umm Al Sheif Commercial Building",
        s: "dubai-umm-al-sheif-umm-al-sheif-commercial-building"
    }, {
        n: "Marbella Bay",
        s: "ras-al-khaimah-al-marjan-island-marbella-bay"
    }, {
        n: "Marjan Island Resort and Spa",
        s: "ras-al-khaimah-al-marjan-island-marjan-island-resort-and-spa"
    }, {
        n: "building  7",
        s: "dubai-dubai-waterfront-badrah-building-7"
    }, {
        n: "Aston Tower A",
        s: "dubai-dubai-science-park-aston-towers-aston-tower-a"
    }, {
        n: "Signature Villas Frond H",
        s: "dubai-palm-jumeirah-signature-villas-signature-villas-frond-h"
    }, {
        n: "Al Sondos Tower",
        s: "dubai-sheikh-zayed-road-al-sondos-tower"
    }, {
        n: "Indigo Central 7",
        s: "dubai-sheikh-zayed-road-indigo-central-7"
    }, {
        n: "Al Attar Business Avenue",
        s: "dubai-sheikh-zayed-road-al-attar-business-avenue"
    }, {
        n: "Al Murooj Rotana Hotel",
        s: "dubai-sheikh-zayed-road-al-murooj-rotana-hotel"
    }, {
        n: "Etihad Airways Travel Mall",
        s: "dubai-sheikh-zayed-road-etihad-airways-travel-mall"
    }, {
        n: "Al Tayer Building",
        s: "dubai-sheikh-zayed-road-al-tayer-building"
    }, {
        n: "Four Points by Sheraton",
        s: "dubai-sheikh-zayed-road-four-points-by-sheraton"
    }, {
        n: "Al Saqr Business Tower",
        s: "dubai-sheikh-zayed-road-al-saqr-business-tower"
    }, {
        n: "Al Hawai Tower",
        s: "dubai-sheikh-zayed-road-al-hawai-tower"
    }, {
        n: "Al Manara Building",
        s: "dubai-sheikh-zayed-road-al-manara-building"
    }, {
        n: "Residential District",
        s: "dubai-dubai-south-dubai-world-central-residential-district"
    }, {
        n: "Exhibition District",
        s: "dubai-dubai-south-dubai-world-central-exhibition-district"
    }, {
        n: "The Pulse",
        s: "dubai-dubai-south-dubai-world-central-the-pulse"
    }, {
        n: "Logistics District",
        s: "dubai-dubai-south-dubai-world-central-logistics-district"
    }, {
        n: "Kappa Acca 3",
        s: "dubai-dubai-south-dubai-world-central-kappa-acca-3"
    }, {
        n: "Zahra Townhouses",
        s: "dubai-town-square-zahra-townhouses"
    }, {
        n: "Al Qudra 4",
        s: "dubai-town-square-al-qudra-4"
    }, {
        n: "Naseem Townhouses",
        s: "dubai-town-square-naseem-townhouses"
    }, {
        n: "IST Plaza",
        s: "dubai-umm-al-sheif-ist-plaza"
    }, {
        n: "API 1000",
        s: "dubai-umm-al-sheif-api-1000"
    }, {
        n: "Lamborghini Building",
        s: "dubai-sheikh-zayed-road-lamborghini-building"
    }, {
        n: "Sahara Tower",
        s: "dubai-sheikh-zayed-road-sahara-tower"
    }, {
        n: "Golf District",
        s: "dubai-dubai-south-dubai-world-central-golf-district"
    }, {
        n: "Parklane",
        s: "dubai-dubai-south-dubai-world-central-parklane"
    }, {
        n: "Mag 5 Boulevard",
        s: "dubai-dubai-south-dubai-world-central-mag-5-boulevard"
    }, {
        n: "EMAAR South",
        s: "dubai-dubai-south-dubai-world-central-emaar-south"
    }, {
        n: "Zahra Apartments",
        s: "dubai-town-square-zahra-apartments"
    }, {
        n: "Safi Townhouses",
        s: "dubai-town-square-safi-townhouses"
    }, {
        n: "Sama Townhouses",
        s: "dubai-town-square-sama-townhouses"
    }, {
        n: "Rawda Apartments",
        s: "dubai-town-square-rawda-apartments"
    }, {
        n: "Umm Al Sheif Villas",
        s: "dubai-umm-al-sheif-umm-al-sheif-villas"
    }, {
        n: "Eco Lodge",
        s: "ras-al-khaimah-mina-al-arab-eco-lodge"
    }, {
        n: "Bab Al Bahar",
        s: "ras-al-khaimah-al-marjan-island-bab-al-bahar"
    }, {
        n: "Al Raas 1",
        s: "umm-al-quwain-al-raas-al-raas-1"
    }, {
        n: "Mistral",
        s: "umm-al-quwain-umm-al-quwain-marina-mistral"
    }, {
        n: "DXB Tower",
        s: "dubai-sheikh-zayed-road-dxb-tower"
    }, {
        n: "Trade Center Hotel Apartments",
        s: "dubai-sheikh-zayed-road-trade-center-hotel-apartments"
    }, {
        n: "Aviation District",
        s: "dubai-dubai-south-dubai-world-central-aviation-district"
    }, {
        n: "Commercial District",
        s: "dubai-dubai-south-dubai-world-central-commercial-district"
    }, {
        n: "Majestique Residence 1",
        s: "dubai-dubai-south-dubai-world-central-majestique-residence-1"
    }, {
        n: "Zahra Breeze Apartments",
        s: "dubai-town-square-zahra-breeze-apartments"
    }, {
        n: "Noor Townhouses",
        s: "dubai-town-square-noor-townhouses"
    }, {
        n: "UNA Apartments",
        s: "dubai-town-square-una-apartments"
    }, {
        n: "SKB Plaza",
        s: "dubai-umm-al-sheif-skb-plaza"
    }, {
        n: "Building 367-646",
        s: "dubai-umm-al-sheif-building-367-646"
    }, {
        n: "Um Ghaffa",
        s: "al-ain-um-ghaffa"
    }, {
        n: "Zakher",
        s: "al-ain-zakher"
    }, {
        n: "Al Mutawaa",
        s: "al-ain-al-mutawaa"
    }, {
        n: "Al Maqam",
        s: "al-ain-al-maqam"
    }, {
        n: "Maqam",
        s: "al-ain-al-maqam"
    }, {
        n: "Hayat Townhouses",
        s: "dubai-town-square-hayat-townhouses"
    }, {
        n: "Safi",
        s: "dubai-town-square-safi"
    }, {
        n: "EMAAR Beachfront",
        s: "dubai-dubai-harbour-emaar-beachfront"
    }, {
        n: "Al Raas 2",
        s: "umm-al-quwain-al-raas-al-raas-2"
    }, {
        n: "Central District",
        s: "al-ain-central-district"
    }, {
        n: "Al Hamra Village Villas",
        s: "ras-al-khaimah-al-hamra-village-al-hamra-village-villas"
    }, {
        n: "The Townhouses at Al Hamra Village",
        s: "ras-al-khaimah-al-hamra-village-the-townhouses-at-al-hamra-village"
    }, {
        n: "Al Hamra Fort Hotel",
        s: "ras-al-khaimah-al-hamra-village-al-hamra-fort-hotel"
    }, {
        n: "Blue Mirage",
        s: "ras-al-khaimah-al-marjan-island-blue-mirage"
    }, {
        n: "Al Raas 3",
        s: "umm-al-quwain-al-raas-al-raas-3"
    }, {
        n: "Al Dhahir",
        s: "al-ain-al-dhahir"
    }, {
        n: "Al Waha",
        s: "umm-al-quwain-al-salam-city-al-waha"
    }, {
        n: "Falcon Island",
        s: "ras-al-khaimah-al-hamra-village-falcon-island"
    }, {
        n: "Al Hamra Marina Residences",
        s: "ras-al-khaimah-al-hamra-village-al-hamra-marina-residences"
    }, {
        n: "Bayti Townhouses",
        s: "ras-al-khaimah-al-hamra-village-bayti-townhouses"
    }, {
        n: "Al Hamra Golf Resort",
        s: "ras-al-khaimah-al-hamra-village-al-hamra-golf-resort"
    }, {
        n: "Oasis Walk",
        s: "ras-al-khaimah-mina-al-arab-oasis-walk"
    }, {
        n: "Khalfan Al Fandi Building",
        s: "ras-al-khaimah-mina-al-arab-khalfan-al-fandi-building"
    }, {
        n: "Hayat Island",
        s: "ras-al-khaimah-mina-al-arab-hayat-island"
    }, {
        n: "Jannah Resort & Villas Ras Al Khaimah",
        s: "ras-al-khaimah-mina-al-arab-jannah-resort-villas-ras-al-khaimah"
    }, {
        n: "Bermuda",
        s: "ras-al-khaimah-mina-al-arab-bermuda"
    }, {
        n: "Treasure Island",
        s: "ras-al-khaimah-al-marjan-island-treasure-island"
    }, {
        n: "Soho Palm Jumeirah",
        s: "dubai-palm-jumeirah-soho-palm-jumeirah"
    }, {
        n: "Royal Bay",
        s: "dubai-palm-jumeirah-royal-bay"
    }, {
        n: "Palma Residences",
        s: "dubai-palm-jumeirah-palma-residences"
    }, {
        n: "W Residences",
        s: "dubai-palm-jumeirah-w-residences"
    }, {
        n: "MINA by Azizi",
        s: "dubai-palm-jumeirah-mina-by-azizi"
    }, {
        n: "Seven Palm",
        s: "dubai-palm-jumeirah-seven-palm"
    }, {
        n: "Casa Dora",
        s: "dubai-serena-casa-dora"
    }, {
        n: "KOA Canvas",
        s: "dubai-mohammad-bin-rashid-gardens-koa-canvas"
    }, {
        n: "Hacienda",
        s: "dubai-the-villa-hacienda"
    }, {
        n: "Dubai Autism Center",
        s: "dubai-al-garhoud-dubai-autism-center"
    }, {
        n: "Dana Al Garhoud",
        s: "dubai-al-garhoud-dana-al-garhoud"
    }, {
        n: "Sarah Building",
        s: "dubai-al-garhoud-sarah-building"
    }, {
        n: "Bin Sardah Villas 2",
        s: "dubai-al-garhoud-bin-sardah-villas-2"
    }, {
        n: "Royal Breeze",
        s: "ras-al-khaimah-al-hamra-village-royal-breeze"
    }, {
        n: "Industrial Area 2",
        s: "umm-al-quwain-emirates-modern-industrial-industrial-area-2"
    }, {
        n: "Al Hamra Views",
        s: "ras-al-khaimah-al-hamra-village-al-hamra-views"
    }, {
        n: "Ocean Breeze",
        s: "ras-al-khaimah-al-hamra-village-ocean-breeze"
    }, {
        n: "Gateway Residences",
        s: "ras-al-khaimah-mina-al-arab-gateway-residences"
    }, {
        n: "The Cove Rotana",
        s: "ras-al-khaimah-ras-al-khaimah-waterfront-the-cove-rotana"
    }, {
        n: "Al Dhafrah 1",
        s: "abu-dhabi-al-dhafrah-al-dhafrah-1"
    }, {
        n: "Al Ras Al Akhdar",
        s: "abu-dhabi-al-khalidiya-al-ras-al-akhdar"
    }, {
        n: "Concorde Building 1",
        s: "ras-al-khaimah-al-mamourah-concorde-building-1"
    }, {
        n: "City Stay Beach Hotel Apartment",
        s: "ras-al-khaimah-al-marjan-island-city-stay-beach-hotel-apartment"
    }, {
        n: "Pacific",
        s: "ras-al-khaimah-al-marjan-island-pacific"
    }, {
        n: "Yasmin Tower",
        s: "ras-al-khaimah-yasmin-village-yasmin-tower"
    }, {
        n: "Commercial Centre",
        s: "ras-al-khaimah-city-downtown-commercial-centre"
    }, {
        n: "Rose Building",
        s: "ras-al-khaimah-al-qurm-rose-building"
    }, {
        n: "Sharq building",
        s: "ras-al-khaimah-al-seer-sharq-building"
    }, {
        n: "Tulip Inn Ras Al Khaimah Hotel",
        s: "ras-al-khaimah-al-seer-tulip-inn-ras-al-khaimah-hotel"
    }, {
        n: "Jamal Tower",
        s: "abu-dhabi-al-nahyan-camp-jamal-tower"
    }, {
        n: "Al Raha Beach Hotel",
        s: "abu-dhabi-al-raha-beach-al-raha-beach-hotel"
    }, {
        n: "Al Amirah Building",
        s: "abu-dhabi-al-raha-beach-al-amirah-building"
    }, {
        n: "Al Zeina",
        s: "abu-dhabi-al-raha-beach-al-zeina"
    }, {
        n: "Najmat Abu Dhabi",
        s: "abu-dhabi-al-reem-island-najmat-abu-dhabi"
    }, {
        n: "Al Bustan Complex",
        s: "abu-dhabi-al-aman-al-bustan-complex"
    }, {
        n: "Al Alka",
        s: "dubai-greens-al-alka"
    }, {
        n: "Al seer",
        s: "ras-al-khaimah-city-downtown-al-seer"
    }, {
        n: "Academy Zone",
        s: "ras-al-khaimah-rak-ftz-academy-zone"
    }, {
        n: "EXPO Building",
        s: "ras-al-khaimah-al-seer-expo-building"
    }, {
        n: "Union Tower",
        s: "ras-al-khaimah-al-seer-union-tower"
    }, {
        n: "Al Nahyan Villa Compound",
        s: "abu-dhabi-al-nahyan-camp-al-nahyan-villa-compound"
    }, {
        n: "Al Shaheen Tower",
        s: "abu-dhabi-al-khalidiya-al-shaheen-tower"
    }, {
        n: "Al Bateen Villas",
        s: "abu-dhabi-al-bateen-al-bateen-villas"
    }, {
        n: "Al Seef",
        s: "abu-dhabi-al-raha-beach-al-seef"
    }, {
        n: "Saadiyat Cultural District",
        s: "abu-dhabi-saadiyat-island-saadiyat-cultural-district"
    }, {
        n: "Yas Marina",
        s: "abu-dhabi-yas-island-yas-marina"
    }, {
        n: "Sultan Bin Zayed the First Street",
        s: "abu-dhabi-muroor-area-sultan-bin-zayed-the-first-street"
    }, {
        n: "Technology Park",
        s: "ras-al-khaimah-rak-ftz-technology-park"
    }, {
        n: "Al Dhait North",
        s: "ras-al-khaimah-al-dhait-al-dhait-north"
    }, {
        n: "RAK Tower",
        s: "ras-al-khaimah-al-seer-rak-tower"
    }, {
        n: "Al Qurm Compound",
        s: "abu-dhabi-al-qurm-al-qurm-compound"
    }, {
        n: "First Gulf Bank Building",
        s: "abu-dhabi-al-khalidiya-first-gulf-bank-building"
    }, {
        n: "Sas Al Nakheel Village",
        s: "abu-dhabi-sas-al-nakheel-sas-al-nakheel-village"
    }, {
        n: "Mina Road",
        s: "abu-dhabi-tourist-club-area-mina-road"
    }, {
        n: "HIDD Al Saadiyat",
        s: "abu-dhabi-saadiyat-island-hidd-al-saadiyat"
    }, {
        n: "Jawaher Saadiyat",
        s: "abu-dhabi-saadiyat-island-jawaher-saadiyat"
    }, {
        n: "Qaryat Al Beri",
        s: "abu-dhabi-al-maqtaa-qaryat-al-beri"
    }, {
        n: "Hills Abu Dhabi",
        s: "abu-dhabi-al-maqtaa-hills-abu-dhabi"
    }, {
        n: "Al Seef Village",
        s: "abu-dhabi-al-salam-street-al-seef-village"
    }, {
        n: "Al Nasr Street",
        s: "abu-dhabi-al-najda-street-al-nasr-street"
    }, {
        n: "Khalid Khalifa Hamdan Al Mansour Building",
        s: "abu-dhabi-al-nahyan-khalid-khalifa-hamdan-al-mansour-building"
    }, {
        n: "Al Shuwaihean",
        s: "sharjah-al-gharb-al-shuwaihean"
    }, {
        n: "Khalidiya Palace Rayhaan",
        s: "abu-dhabi-al-khalidiya-khalidiya-palace-rayhaan"
    }, {
        n: "Mushrif Gardens",
        s: "abu-dhabi-al-mushrif-mushrif-gardens"
    }, {
        n: "Baniyas North",
        s: "abu-dhabi-baniyas-baniyas-north"
    }, {
        n: "Al Dana Tower",
        s: "abu-dhabi-al-wahda-al-dana-tower"
    }, {
        n: "Al Jurf",
        s: "abu-dhabi-ghantoot-al-jurf"
    }, {
        n: "Capital Plaza",
        s: "abu-dhabi-corniche-road-capital-plaza"
    }, {
        n: "Al Shamiya Building",
        s: "ras-al-khaimah-al-seer-al-shamiya-building"
    }, {
        n: "Al Hosn",
        s: "abu-dhabi-al-khalidiya-al-hosn"
    }, {
        n: "Bloom Marina",
        s: "abu-dhabi-al-bateen-bloom-marina"
    }, {
        n: "Al Qubaisat",
        s: "abu-dhabi-al-mushrif-al-qubaisat"
    }, {
        n: "Khalifa City A",
        s: "abu-dhabi-khalifa-city-khalifa-city-a"
    }, {
        n: "Al Maqtaa Villas",
        s: "abu-dhabi-mohamed-bin-zayed-city-al-maqtaa-villas"
    }, {
        n: "Al Mamoura",
        s: "abu-dhabi-muroor-area-al-mamoura"
    }, {
        n: "Hamad Bin Al Jesrain Villas",
        s: "abu-dhabi-al-maqtaa-hamad-bin-al-jesrain-villas"
    }, {
        n: "Humaid Gate Tower",
        s: "ajman-al-humaid-city-humaid-gate-tower"
    }, {
        n: "Gateway",
        s: "ajman-al-zorah-gateway"
    }, {
        n: "Al Helio 1",
        s: "ajman-al-helio-al-helio-1"
    }, {
        n: "Corniche Tower",
        s: "ajman-ajman-corniche-road-corniche-tower"
    }, {
        n: "Sharqan",
        s: "sharjah-al-heerah-sharqan"
    }, {
        n: "Saheel",
        s: "dubai-arabian-ranches-saheel"
    }, {
        n: "Muzoon Building",
        s: "abu-dhabi-al-raha-beach-muzoon-building"
    }, {
        n: "Grand Millennium Al Wahda Hotel",
        s: "abu-dhabi-al-wahda-grand-millennium-al-wahda-hotel"
    }, {
        n: "City Of Lights",
        s: "abu-dhabi-al-reem-island-city-of-lights"
    }, {
        n: "City Center Building",
        s: "abu-dhabi-hamdan-street-city-center-building"
    }, {
        n: "Hadbat Al Zafranah",
        s: "abu-dhabi-muroor-area-hadbat-al-zafranah"
    }, {
        n: "Madinat Zayed Tower",
        s: "abu-dhabi-muroor-area-madinat-zayed-tower"
    }, {
        n: "Khalifa Park",
        s: "abu-dhabi-eastern-road-khalifa-park"
    }, {
        n: "Al Maqtaa Residence Building",
        s: "abu-dhabi-muroor-area-al-maqtaa-residence-building"
    }, {
        n: "Al Dhafra Building",
        s: "abu-dhabi-muroor-area-al-dhafra-building"
    }, {
        n: "Khalifa Park Tower",
        s: "abu-dhabi-al-salam-street-khalifa-park-tower"
    }, {
        n: "Falcon Tower",
        s: "abu-dhabi-corniche-road-falcon-tower"
    }, {
        n: "Al Mahra Residence",
        s: "abu-dhabi-masdar-city-al-mahra-residence"
    }, {
        n: "Al Khail Towers",
        s: "ajman-ajman-downtown-al-khail-towers"
    }, {
        n: "A&F Tower",
        s: "ajman-al-naemiyah-a-f-tower"
    }, {
        n: "Al Khan Lagoon",
        s: "sharjah-al-khan-al-khan-lagoon"
    }, {
        n: "Al Suyoh 4",
        s: "sharjah-al-suyoh-al-suyoh-4"
    }, {
        n: "Al Musalla",
        s: "sharjah-al-gharb-al-musalla"
    }, {
        n: "Muwailih Building",
        s: "sharjah-muwaileh-muwailih-building"
    }, {
        n: "Al Jaber Tower",
        s: "fujairah-sheikh-hamad-bin-abdullah-st-al-jaber-tower"
    }, {
        n: "TIME Opal Hotel Apartments",
        s: "dubai-al-barsha-time-opal-hotel-apartments"
    }, {
        n: "Al Furjan Grove",
        s: "dubai-al-furjan-al-furjan-grove"
    }, {
        n: "Al Khawaneej 1",
        s: "dubai-al-khawaneej-al-khawaneej-1"
    }, {
        n: "Al Safa 1",
        s: "dubai-al-safa-al-safa-1"
    }, {
        n: "Vision Twin Towers",
        s: "abu-dhabi-al-najda-street-vision-twin-towers"
    }, {
        n: "Global Tower",
        s: "abu-dhabi-electra-street-global-tower"
    }, {
        n: "The Cavendish Tower",
        s: "ajman-awali-city-the-cavendish-tower"
    }, {
        n: "Golf Community",
        s: "ajman-al-zorah-golf-community"
    }, {
        n: "Al Rahmaniya 10",
        s: "sharjah-al-rahmaniya-al-rahmaniya-10"
    }, {
        n: "Al Fayha",
        s: "sharjah-al-sharq-al-fayha"
    }, {
        n: "Al Manakh",
        s: "sharjah-al-qasemiya-al-manakh"
    }, {
        n: "Chelsea Plaza Hotel",
        s: "dubai-al-jafiliya-chelsea-plaza-hotel"
    }, {
        n: "Al Twar 2",
        s: "dubai-al-twar-al-twar-2"
    }, {
        n: "Al Warqa'a 4",
        s: "dubai-al-warqa-a-al-warqa-a-4"
    }, {
        n: "Damac Towers",
        s: "dubai-business-bay-damac-towers"
    }, {
        n: "Sobha Ivory Towers",
        s: "dubai-business-bay-sobha-ivory-towers"
    }, {
        n: "The Opus",
        s: "dubai-business-bay-the-opus"
    }, {
        n: "Landmark Tower",
        s: "dubai-dubai-marina-landmark-tower"
    }, {
        n: "The Gate",
        s: "abu-dhabi-masdar-city-the-gate"
    }, {
        n: "Oasis Residences",
        s: "abu-dhabi-masdar-city-oasis-residences"
    }, {
        n: "Gold Tower",
        s: "ajman-marmooka-city-gold-tower"
    }, {
        n: "Al Helio 2",
        s: "ajman-al-helio-al-helio-2"
    }, {
        n: "Industrial Area 16",
        s: "sharjah-sharjah-industrial-area-industrial-area-16"
    }, {
        n: "Al Barsha 3",
        s: "dubai-al-barsha-al-barsha-3"
    }, {
        n: "Al Quoz Industrial Area",
        s: "dubai-al-quoz-al-quoz-industrial-area"
    }, {
        n: "The Burlington",
        s: "dubai-business-bay-the-burlington"
    }, {
        n: "Rose Tower",
        s: "dubai-culture-village-rose-tower"
    }, {
        n: "Greens 22",
        s: "dubai-greens-greens-22"
    }, {
        n: "Al Jurf",
        s: "ajman-ajman-downtown-al-jurf"
    }, {
        n: "Savannah Tower",
        s: "ajman-emirates-city-savannah-tower"
    }, {
        n: "Al Rashid Towers",
        s: "ajman-al-humaid-city-al-rashid-towers"
    }, {
        n: "Al Maha Tower",
        s: "sharjah-al-majaz-al-maha-tower"
    }, {
        n: "Industrial Area 4",
        s: "sharjah-sharjah-industrial-area-industrial-area-4"
    }, {
        n: "Industrial Area 14",
        s: "sharjah-sharjah-industrial-area-industrial-area-14"
    }, {
        n: "Al Rahmaniya 9",
        s: "sharjah-al-rahmaniya-al-rahmaniya-9"
    }, {
        n: "Al Mansoura",
        s: "sharjah-al-sharq-al-mansoura"
    }, {
        n: "Al Khan 9 building",
        s: "sharjah-al-khan-al-khan-9-building"
    }, {
        n: "Al Suyoh 1",
        s: "sharjah-al-suyoh-al-suyoh-1"
    }, {
        n: "Al Mamsha",
        s: "sharjah-muwaileh-al-mamsha"
    }, {
        n: "Al Ramaqiya",
        s: "sharjah-wasit-al-ramaqiya"
    }, {
        n: "wadi al safa",
        s: "dubai-al-barari-wadi-al-safa"
    }, {
        n: "Arenco Villas",
        s: "dubai-al-badaa-arenco-villas"
    }, {
        n: "Al Mamzar",
        s: "sharjah-al-mamzar-sharjah-al-mamzar"
    }, {
        n: "Al Mamzar Tower",
        s: "sharjah-al-mamzar-sharjah-al-mamzar-tower"
    }, {
        n: "Al Goaz",
        s: "sharjah-wasit-al-goaz"
    }, {
        n: "Fujairah Beach",
        s: "fujairah-downtown-fujairah-fujairah-beach"
    }, {
        n: "dar wasl",
        s: "dubai-al-wasl-dar-wasl"
    }, {
        n: "Business Central",
        s: "dubai-business-bay-business-central"
    }, {
        n: "Al Asmawi",
        s: "dubai-international-city-al-asmawi"
    }, {
        n: "Al Qusais Industrial Area",
        s: "dubai-al-qusais-al-qusais-industrial-area"
    }, {
        n: "Al Safa 3",
        s: "dubai-al-safa-al-safa-3"
    }, {
        n: "Manazel Al Safa",
        s: "dubai-business-bay-manazel-al-safa"
    }, {
        n: "The Bay Residence",
        s: "dubai-business-bay-the-bay-residence"
    }, {
        n: "Hyatt Regency Dubai",
        s: "dubai-deira-hyatt-regency-dubai"
    }, {
        n: "The Residences",
        s: "dubai-downtown-dubai-the-residences"
    }, {
        n: "Durrat Al Marsa",
        s: "dubai-dubai-marina-durrat-al-marsa"
    }, {
        n: "Park Island",
        s: "dubai-dubai-marina-park-island"
    }, {
        n: "Oasis Star",
        s: "dubai-dubai-silicon-oasis-oasis-star"
    }, {
        n: "Platinum Residences 1",
        s: "dubai-dubai-silicon-oasis-platinum-residences-1"
    }, {
        n: "Radisson Blu Hotel Apartments",
        s: "dubai-dubai-silicon-oasis-radisson-blu-hotel-apartments"
    }, {
        n: "Square 334",
        s: "dubai-al-satwa-square-334"
    }, {
        n: "Al Warqa'a 3",
        s: "dubai-al-warqa-a-al-warqa-a-3"
    }, {
        n: "Marasi Riverside",
        s: "dubai-business-bay-marasi-riverside"
    }, {
        n: "Millennium Binghatti Residences",
        s: "dubai-business-bay-millennium-binghatti-residences"
    }, {
        n: "The Sanctuary",
        s: "dubai-business-bay-the-sanctuary"
    }, {
        n: "Dubai Wharf",
        s: "dubai-culture-village-dubai-wharf"
    }, {
        n: "Palazzo Versace",
        s: "dubai-culture-village-palazzo-versace"
    }, {
        n: "Emirates Financial Towers",
        s: "dubai-difc-emirates-financial-towers"
    }, {
        n: "32 Villas",
        s: "dubai-dubai-media-city-32-villas"
    }, {
        n: "Marina Wharf",
        s: "dubai-dubai-marina-marina-wharf"
    }, {
        n: "Reva Residences",
        s: "dubai-business-bay-reva-residences"
    }, {
        n: "One Business Bay",
        s: "dubai-business-bay-one-business-bay"
    }, {
        n: "Ritz Carlton",
        s: "dubai-difc-ritz-carlton"
    }, {
        n: "Burj Khalifa Area",
        s: "dubai-downtown-dubai-burj-khalifa-area"
    }, {
        n: "Emaar Square",
        s: "dubai-downtown-dubai-emaar-square"
    }, {
        n: "Palace Residences",
        s: "dubai-dubai-creek-harbour-the-lagoons-palace-residences"
    }, {
        n: "Al Thuraya Tower 1",
        s: "dubai-dubai-media-city-al-thuraya-tower-1"
    }, {
        n: "Yacht Bay",
        s: "dubai-dubai-marina-yacht-bay"
    }, {
        n: "MAG 218",
        s: "dubai-dubai-marina-mag-218"
    }, {
        n: "Binghatti Terraces",
        s: "dubai-dubai-silicon-oasis-binghatti-terraces"
    }, {
        n: "Atrium Gold Towers",
        s: "dubai-dubai-silicon-oasis-atrium-gold-towers"
    }, {
        n: "Al Nasser Building",
        s: "dubai-dubai-silicon-oasis-al-nasser-building"
    }, {
        n: "The Springs Tower",
        s: "dubai-dubai-silicon-oasis-the-springs-tower"
    }, {
        n: "Silicon Residence 1",
        s: "dubai-dubai-silicon-oasis-silicon-residence-1"
    }, {
        n: "Business Bay Mall",
        s: "dubai-business-bay-business-bay-mall"
    }, {
        n: "Emirates Park",
        s: "dubai-business-bay-emirates-park"
    }, {
        n: "Manazel Al Khor",
        s: "dubai-culture-village-manazel-al-khor"
    }, {
        n: "Naif",
        s: "dubai-deira-naif"
    }, {
        n: "Al Muraqqabat",
        s: "dubai-deira-al-muraqqabat"
    }, {
        n: "Arenco Offices",
        s: "dubai-dubai-investment-park-arenco-offices"
    }, {
        n: "Celestial Heights",
        s: "dubai-downtown-jebel-ali-celestial-heights"
    }, {
        n: "Layan Community",
        s: "dubai-dubai-land-layan-community"
    }, {
        n: "The Address Jumeirah Resort and Spa",
        s: "dubai-dubai-marina-the-address-jumeirah-resort-and-spa"
    }, {
        n: "Bayside Residence",
        s: "dubai-dubai-marina-bayside-residence"
    }, {
        n: "Al Mamzar",
        s: "dubai-deira-al-mamzar"
    }, {
        n: "Burj Park",
        s: "dubai-downtown-dubai-burj-park"
    }, {
        n: "Lotus Residence",
        s: "dubai-dubai-investment-park-lotus-residence"
    }, {
        n: "Grand Stores Warehouses",
        s: "dubai-dubai-investment-park-grand-stores-warehouses"
    }, {
        n: "Umm Suqeim 1",
        s: "dubai-umm-suqeim-umm-suqeim-1"
    }, {
        n: "Platinum Tower",
        s: "dubai-dubai-marina-platinum-tower"
    }, {
        n: "Emerald Residence",
        s: "dubai-dubai-marina-emerald-residence"
    }, {
        n: "Al Waleed Oasis",
        s: "dubai-dubai-silicon-oasis-al-waleed-oasis"
    }, {
        n: "Hanging Gardens of Babylon",
        s: "dubai-falcon-city-of-wonders-hanging-gardens-of-babylon"
    }, {
        n: "China Cluster",
        s: "dubai-international-city-china-cluster"
    }, {
        n: "Phase 3",
        s: "dubai-international-city-phase-3"
    }, {
        n: "Shakespeare Circus",
        s: "dubai-motor-city-shakespeare-circus"
    }, {
        n: "Burj Place",
        s: "dubai-downtown-dubai-burj-place"
    }, {
        n: "SBL Building",
        s: "dubai-dubai-investment-park-sbl-building"
    }, {
        n: "Harbour Views 2",
        s: "dubai-dubai-creek-harbour-the-lagoons-harbour-views-2"
    }, {
        n: "Marinascape",
        s: "dubai-dubai-marina-marinascape"
    }, {
        n: "The Atlantic",
        s: "dubai-dubai-marina-the-atlantic"
    }, {
        n: "Marina First Tower",
        s: "dubai-dubai-marina-marina-first-tower"
    }, {
        n: "Gulf National",
        s: "dubai-dubai-marina-gulf-national"
    }, {
        n: "Silicon Oasis Techno Hub",
        s: "dubai-dubai-silicon-oasis-silicon-oasis-techno-hub"
    }, {
        n: "Rescom Tower",
        s: "dubai-dubai-sports-city-rescom-tower"
    }, {
        n: "Park Avenue",
        s: "dubai-dubai-silicon-oasis-park-avenue"
    }, {
        n: "Crown Avenue",
        s: "dubai-dubai-sports-city-crown-avenue"
    }, {
        n: "Lake District",
        s: "dubai-international-city-lake-district"
    }, {
        n: "Phase 2",
        s: "dubai-international-city-phase-2"
    }, {
        n: "Jebel Ali Airport",
        s: "dubai-jebel-ali-jebel-ali-airport"
    }, {
        n: "Plazzo Heights",
        s: "dubai-jumeirah-village-circle-plazzo-heights"
    }, {
        n: "Garden Lane Villas",
        s: "dubai-jumeirah-village-circle-garden-lane-villas"
    }, {
        n: "Circle Villas",
        s: "dubai-jumeirah-village-circle-circle-villas"
    }, {
        n: "Oxford Villas",
        s: "dubai-jumeirah-village-circle-oxford-villas"
    }, {
        n: "District 9A",
        s: "dubai-jumeirah-village-triangle-district-9a"
    }, {
        n: "Norton Court",
        s: "dubai-motor-city-norton-court"
    }, {
        n: "Dickens Circus",
        s: "dubai-motor-city-dickens-circus"
    }, {
        n: "Saraya Building",
        s: "dubai-barsha-heights-tecom-saraya-building"
    }, {
        n: "Tower 7 Apartments",
        s: "dubai-barsha-heights-tecom-tower-7-apartments"
    }, {
        n: "Silicon Building",
        s: "dubai-dubai-silicon-oasis-silicon-building"
    }, {
        n: "Azizi Grand",
        s: "dubai-dubai-sports-city-azizi-grand"
    }, {
        n: "Zenith Twin Towers",
        s: "dubai-falcon-city-of-wonders-zenith-twin-towers"
    }, {
        n: "Al Jaz",
        s: "dubai-greens-al-jaz"
    }, {
        n: "Dutco Greens Residential",
        s: "dubai-greens-dutco-greens-residential"
    }, {
        n: "Spain Cluster",
        s: "dubai-international-city-spain-cluster"
    }, {
        n: "Jebel Ali Village",
        s: "dubai-jebel-ali-jebel-ali-village"
    }, {
        n: "Jebel Ali Freezone",
        s: "dubai-jebel-ali-jebel-ali-freezone"
    }, {
        n: "Lolena residence",
        s: "dubai-jumeirah-village-circle-lolena-residence"
    }, {
        n: "Green Court",
        s: "dubai-jumeirah-village-circle-green-court"
    }, {
        n: "Seasons Community",
        s: "dubai-jumeirah-village-circle-seasons-community"
    }, {
        n: "May Residence",
        s: "dubai-jumeirah-village-circle-may-residence"
    }, {
        n: "Reflections",
        s: "dubai-jumeirah-village-circle-reflections"
    }, {
        n: "Emirates Gardens 1",
        s: "dubai-jumeirah-village-circle-emirates-gardens-1"
    }, {
        n: "Arabian Townhouse",
        s: "dubai-jumeirah-village-triangle-arabian-townhouse"
    }, {
        n: "Green Community East",
        s: "dubai-green-community-green-community-east"
    }, {
        n: "Cluster C",
        s: "dubai-jumeirah-heights-cluster-c"
    }, {
        n: "Lake Allure",
        s: "dubai-jumeirah-lake-towers-lake-allure"
    }, {
        n: "Al Maali Complex",
        s: "dubai-jumeirah-village-circle-al-maali-complex"
    }, {
        n: "Continents Tower",
        s: "dubai-jumeirah-village-circle-continents-tower"
    }, {
        n: "New Bridge Hills",
        s: "dubai-motor-city-new-bridge-hills"
    }, {
        n: "Uptown Mirdif",
        s: "dubai-mirdif-uptown-mirdif"
    }, {
        n: "Mushrif Village",
        s: "dubai-mirdif-mushrif-village"
    }, {
        n: "Dragon Mart",
        s: "dubai-international-city-dragon-mart"
    }, {
        n: "Warsan Village",
        s: "dubai-international-city-warsan-village"
    }, {
        n: "JA Oasis Beach Tower",
        s: "dubai-jumeirah-beach-residence-ja-oasis-beach-tower"
    }, {
        n: "Lake Almas East",
        s: "dubai-jumeirah-lake-towers-lake-almas-east"
    }, {
        n: "Hydra Twin Towers",
        s: "dubai-jumeirah-village-circle-hydra-twin-towers"
    }, {
        n: "Valencia Park",
        s: "dubai-jumeirah-village-circle-valencia-park"
    }, {
        n: "The Residence VI",
        s: "dubai-jumeirah-village-circle-the-residence-vi"
    }, {
        n: "Signature Villas XIV",
        s: "dubai-jumeirah-village-circle-signature-villas-xiv"
    }, {
        n: "Meadows 8",
        s: "dubai-meadows-meadows-8"
    }, {
        n: "Richmond",
        s: "dubai-damac-hills-akoya-by-damac-richmond"
    }, {
        n: "Heritage",
        s: "dubai-jumeirah-park-heritage"
    }, {
        n: "Jumeirah Wave Tower",
        s: "dubai-jumeirah-village-circle-jumeirah-wave-tower"
    }, {
        n: "Knightsbridge Court",
        s: "dubai-jumeirah-village-circle-knightsbridge-court"
    }, {
        n: "Venus Residence",
        s: "dubai-jumeirah-village-circle-venus-residence"
    }, {
        n: "Mediterranean Village",
        s: "dubai-jumeirah-village-circle-mediterranean-village"
    }, {
        n: "District 1D",
        s: "dubai-jumeirah-village-triangle-district-1d"
    }, {
        n: "The Maritime Centre",
        s: "dubai-maritime-city-the-maritime-centre"
    }, {
        n: "Yas 1",
        s: "dubai-barsha-heights-tecom-yas-1"
    }, {
        n: "HOME TO HOME Hotel Apartments",
        s: "dubai-barsha-heights-tecom-home-to-home-hotel-apartments"
    }, {
        n: "Samaya Hotel 2",
        s: "dubai-majan-samaya-hotel-2"
    }, {
        n: "Navitas Hotel and Residences",
        s: "dubai-akoya-navitas-hotel-and-residences"
    }, {
        n: "Brookfield",
        s: "dubai-damac-hills-akoya-by-damac-brookfield"
    }, {
        n: "Mina Street",
        s: "dubai-bur-dubai-mina-street"
    }, {
        n: "Dubai Hills",
        s: "dubai-dubai-hills-estate-dubai-hills"
    }, {
        n: "Indigo Ville",
        s: "dubai-jumeirah-village-circle-indigo-ville"
    }, {
        n: "District 6A",
        s: "dubai-jumeirah-village-triangle-district-6a"
    }, {
        n: "Rahat",
        s: "dubai-mudon-rahat"
    }, {
        n: "I Rise Tower",
        s: "dubai-barsha-heights-tecom-i-rise-tower"
    }, {
        n: "Albizia",
        s: "dubai-akoya-albizia"
    }, {
        n: "Hawthorn",
        s: "dubai-akoya-hawthorn"
    }, {
        n: "Building 10",
        s: "dubai-city-walk-building-10"
    }, {
        n: "Dubai Autodrome and Business Park",
        s: "dubai-motor-city-dubai-autodrome-and-business-park"
    }, {
        n: "Shorooq",
        s: "dubai-mirdif-shorooq"
    }, {
        n: "Ghoroob",
        s: "dubai-mirdif-ghoroob"
    }, {
        n: "Meadows 1",
        s: "dubai-meadows-meadows-1"
    }, {
        n: "Building 14",
        s: "dubai-city-walk-building-14"
    }, {
        n: "Meadows 5",
        s: "dubai-meadows-meadows-5"
    }, {
        n: "Meadows 6",
        s: "dubai-meadows-meadows-6"
    }, {
        n: "Trump Estates",
        s: "dubai-damac-hills-akoya-by-damac-trump-estates"
    }, {
        n: "Dubai Hills View",
        s: "dubai-dubai-hills-estate-dubai-hills-view"
    }, {
        n: "Ras Al Khor Complex",
        s: "dubai-ras-al-khor-ras-al-khor-complex"
    }, {
        n: "Mulberry",
        s: "dubai-akoya-mulberry"
    }, {
        n: "Amaro",
        s: "dubai-akoya-amaro"
    }, {
        n: "Al Raffa",
        s: "dubai-bur-dubai-al-raffa"
    }, {
        n: "Dubai Pearl Hotel Towers",
        s: "dubai-dubai-pearl-dubai-pearl-hotel-towers"
    }, {
        n: "Park Heights 1",
        s: "dubai-dubai-hills-estate-park-heights-1"
    }, {
        n: "Park Ridge",
        s: "dubai-dubai-hills-estate-park-ridge"
    }, {
        n: "Zabeel Road",
        s: "dubai-zabeel-zabeel-road"
    }, {
        n: "Amro Building",
        s: "dubai-majan-amro-building"
    }, {
        n: "Samaya Hotel 1",
        s: "dubai-majan-samaya-hotel-1"
    }, {
        n: "Victoria",
        s: "dubai-akoya-victoria"
    }, {
        n: "Meena Bazaar",
        s: "dubai-bur-dubai-meena-bazaar"
    }, {
        n: "Dubai Hills Grove",
        s: "dubai-dubai-hills-estate-dubai-hills-grove"
    }, {
        n: "B Villas",
        s: "dubai-living-legends-b-villas"
    }, {
        n: "Al Waleed Garden",
        s: "dubai-al-jaddaf-al-waleed-garden"
    }, {
        n: "Orion",
        s: "dubai-arjan-orion"
    }, {
        n: "Flora",
        s: "dubai-damac-hills-akoya-by-damac-flora"
    }, {
        n: "Al Hamriya",
        s: "dubai-bur-dubai-al-hamriya"
    }, {
        n: "Golfville",
        s: "dubai-dubai-hills-estate-golfville"
    }, {
        n: "Executive Residences",
        s: "dubai-dubai-hills-estate-executive-residences"
    }, {
        n: "The Channel Towers",
        s: "dubai-dubai-promenade-the-channel-towers"
    }, {
        n: "Sector V",
        s: "dubai-emirates-hills-sector-v"
    }, {
        n: "Cluster 46",
        s: "dubai-jumeirah-islands-cluster-46"
    }, {
        n: "The Old Town Island",
        s: "dubai-old-town-the-old-town-island"
    }, {
        n: "Al Dashti Building No. 5",
        s: "dubai-karama-al-dashti-building-no-5"
    }, {
        n: "Al Jaddaf Residence",
        s: "dubai-al-jaddaf-al-jaddaf-residence"
    }, {
        n: "The Royal Atlantis Resort & Residences",
        s: "dubai-palm-jumeirah-the-royal-atlantis-resort-residences"
    }, {
        n: "Springs 2",
        s: "dubai-the-springs-springs-2"
    }, {
        n: "Springs 13",
        s: "dubai-the-springs-springs-13"
    }, {
        n: "Contemporary Cluster",
        s: "dubai-jumeirah-islands-contemporary-cluster"
    }, {
        n: "Building 49",
        s: "dubai-dubai-healthcare-city-building-49"
    }, {
        n: "Millennium Plaza Hotel & Commercial Tower",
        s: "dubai-sheikh-zayed-road-millennium-plaza-hotel-commercial-tower"
    }, {
        n: "Al Hudaiba Building",
        s: "ras-al-khaimah-al-hudaibah-al-hudaiba-building"
    }, {
        n: "Al Ettihad Tower 4",
        s: "abu-dhabi-al-bateen-al-ettihad-towers-al-ettihad-tower-4"
    }, {
        n: "C5 Tower",
        s: "abu-dhabi-al-bateen-six-towers-complex-al-bateen-c5-tower"
    }, {
        n: "Downtown Campus",
        s: "abu-dhabi-al-markaziyah-al-markaziyah-west-downtown-campus"
    }, {
        n: "Germany Island",
        s: "dubai-the-world-islands-germany-island"
    }, {
        n: "Karama Shopping Complex",
        s: "dubai-karama-karama-shopping-complex"
    }, {
        n: "wasl Aqua",
        s: "dubai-karama-wasl-aqua"
    }, {
        n: "Water",
        s: "dubai-jumeirah-golf-estates-water"
    }, {
        n: "Sector W",
        s: "dubai-emirates-hills-sector-w"
    }, {
        n: "Airport Road Area",
        s: "dubai-al-garhoud-airport-road-area"
    }, {
        n: "Al Garhoud Villas",
        s: "dubai-al-garhoud-al-garhoud-villas"
    }, {
        n: "Cluster 07",
        s: "dubai-jumeirah-islands-cluster-07"
    }, {
        n: "Cluster 25",
        s: "dubai-jumeirah-islands-cluster-25"
    }, {
        n: "Villas Area",
        s: "dubai-oud-al-muteena-villas-area"
    }, {
        n: "La Reserve Residences",
        s: "dubai-dubai-healthcare-city-la-reserve-residences"
    }, {
        n: "Fairmont Hotel",
        s: "dubai-sheikh-zayed-road-fairmont-hotel"
    }, {
        n: "Mall Of Emirates Area",
        s: "dubai-sheikh-zayed-road-mall-of-emirates-area"
    }, {
        n: "Al Samaya",
        s: "dubai-sheikh-zayed-road-al-samaya"
    }, {
        n: "Meydan Business Park",
        s: "dubai-meydan-meydan-business-park"
    }, {
        n: "Zabeel Plaza",
        s: "dubai-karama-zabeel-plaza"
    }, {
        n: "D Villas",
        s: "dubai-living-legends-d-villas"
    }, {
        n: "Ayedh Tower",
        s: "dubai-al-jaddaf-ayedh-tower"
    }, {
        n: "Park Gate Residences",
        s: "dubai-al-kifaf-park-gate-residences"
    }, {
        n: "The Crescent",
        s: "dubai-palm-jumeirah-the-crescent"
    }, {
        n: "Saraya Avenue",
        s: "dubai-al-garhoud-saraya-avenue"
    }, {
        n: "Al Nahda 2",
        s: "dubai-al-nahda-al-nahda-2"
    }, {
        n: "Jumeirah Bay Island",
        s: "dubai-jumeirah-jumeirah-bay-island"
    }, {
        n: "Sarai Apartments",
        s: "dubai-palm-jumeirah-sarai-apartments"
    }, {
        n: "Grandeur Residences",
        s: "dubai-palm-jumeirah-grandeur-residences"
    }, {
        n: "Al Nahda 1",
        s: "dubai-al-nahda-al-nahda-1"
    }, {
        n: "Marina Residences",
        s: "dubai-palm-jumeirah-marina-residences"
    }, {
        n: "Building 12",
        s: "dubai-city-walk-building-12"
    }, {
        n: "Springs 4",
        s: "dubai-the-springs-springs-4"
    }, {
        n: "Springs 11",
        s: "dubai-the-springs-springs-11"
    }, {
        n: "Cluster 16",
        s: "dubai-jumeirah-islands-cluster-16"
    }, {
        n: "Cluster 29",
        s: "dubai-jumeirah-islands-cluster-29"
    }, {
        n: "Building 27",
        s: "dubai-dubai-healthcare-city-building-27"
    }, {
        n: "Blue Tower",
        s: "dubai-sheikh-zayed-road-blue-tower"
    }, {
        n: "Court Villa",
        s: "ras-al-khaimah-dafan-al-khor-court-villa"
    }, {
        n: "Shareat Al Jimi",
        s: "al-ain-al-jimi-shareat-al-jimi"
    }, {
        n: "Al Khrais",
        s: "al-ain-al-jimi-al-khrais"
    }, {
        n: "Green Land Compound",
        s: "al-ain-al-markhaniya-green-land-compound"
    }, {
        n: "Al Ain Compound",
        s: "al-ain-bida-bin-ammar-al-ain-compound"
    }, {
        n: "Al Sarouj Street",
        s: "al-ain-central-district-al-sarouj-street"
    }, {
        n: "Al Mahattah",
        s: "al-ain-al-towayya-al-mahattah"
    }, {
        n: "Al Sidrah",
        s: "al-ain-al-khabisi-al-sidrah"
    }, {
        n: "Cluster 49",
        s: "dubai-jumeirah-islands-cluster-49"
    }, {
        n: "Building 52",
        s: "dubai-dubai-healthcare-city-building-52"
    }, {
        n: "Al Meraikhi Tower",
        s: "dubai-sheikh-zayed-road-al-meraikhi-tower"
    }, {
        n: "Paradise",
        s: "dubai-dubai-south-dubai-world-central-paradise"
    }, {
        n: "Al Dalah Building",
        s: "ras-al-khaimah-the-old-market-al-dalah-building"
    }, {
        n: "The Lagoons",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons"
    }, {
        n: "Al Khaleef",
        s: "al-ain-al-hili-al-khaleef"
    }, {
        n: "Al Mewiji",
        s: "al-ain-al-jimi-al-mewiji"
    }, {
        n: "Al Ain Ladies Club",
        s: "al-ain-al-markhaniya-al-ain-ladies-club"
    }, {
        n: "Al Shuaibah",
        s: "al-ain-al-rawdah-al-sharqiyah-al-shuaibah"
    }, {
        n: "Jafeer Obaid",
        s: "al-ain-al-towayya-jafeer-obaid"
    }, {
        n: "Eidan Al Ridda",
        s: "al-ain-al-towayya-eidan-al-ridda"
    }, {
        n: "Al Zaafaran",
        s: "al-ain-al-khabisi-al-zaafaran"
    }, {
        n: "Cityland Mall",
        s: "dubai-wadi-al-safa-3-cityland-mall"
    }, {
        n: "Tecom Tower",
        s: "dubai-sheikh-zayed-road-tecom-tower"
    }, {
        n: "Al Hamra Lagoon",
        s: "ras-al-khaimah-al-hamra-village-al-hamra-lagoon"
    }, {
        n: "Granada",
        s: "ras-al-khaimah-mina-al-arab-granada"
    }, {
        n: "Ugdat Al Ameriya",
        s: "al-ain-al-jimi-ugdat-al-ameriya"
    }, {
        n: "Ramlat Zakher",
        s: "al-ain-zakher-ramlat-zakher"
    }, {
        n: "Phase Two",
        s: "al-ain-wahat-al-zaweya-phase-two"
    }, {
        n: "Khalifa Street",
        s: "al-ain-central-district-khalifa-street"
    }, {
        n: "Al Ain Square",
        s: "al-ain-al-towayya-al-ain-square"
    }, {
        n: "Khalidiya Tower A",
        s: "abu-dhabi-al-khalidiya-khalidiya-twin-towers-khalidiya-tower-a"
    }, {
        n: "X Panasonic Showroom",
        s: "abu-dhabi-al-khalidiya-al-istiqlal-street-x-panasonic-showroom"
    }, {
        n: "Amena 5 Building",
        s: "abu-dhabi-al-khalidiya-al-hosn-amena-5-building"
    }, {
        n: "Skyline Residence",
        s: "dubai-sheikh-zayed-road-skyline-residence"
    }, {
        n: "Dubai Logistics City",
        s: "dubai-dubai-south-dubai-world-central-dubai-logistics-city"
    }, {
        n: "Al Hamra Palace Beach Resort",
        s: "ras-al-khaimah-al-hamra-village-al-hamra-palace-beach-resort"
    }, {
        n: "Ramlat Aawad Shaheen",
        s: "al-ain-al-jimi-ramlat-aawad-shaheen"
    }, {
        n: "Hai Al Wadi",
        s: "al-ain-al-jimi-hai-al-wadi"
    }, {
        n: "Gafat Al Nayyar",
        s: "al-ain-zakher-gafat-al-nayyar"
    }, {
        n: "Phase Three",
        s: "al-ain-wahat-al-zaweya-phase-three"
    }, {
        n: "Waha Al Amirah",
        s: "al-ain-al-yahar-waha-al-amirah"
    }, {
        n: "Maadhi",
        s: "al-ain-al-towayya-maadhi"
    }, {
        n: "Um Al Sidir",
        s: "al-ain-al-towayya-um-al-sidir"
    }, {
        n: "Al Naseriyya",
        s: "al-ain-al-khabisi-al-naseriyya"
    }, {
        n: "Al Jaber Building",
        s: "abu-dhabi-al-khalidiya-al-hosn-al-jaber-building"
    }, {
        n: "Dana Plaza",
        s: "abu-dhabi-al-khalidiya-zayed-the-first-street-dana-plaza"
    }, {
        n: "17 Villas Project",
        s: "abu-dhabi-al-khalidiya-khalidiya-street-17-villas-project"
    }, {
        n: "Montazah Tower",
        s: "abu-dhabi-al-khalidiya-khalidiya-street-montazah-tower"
    }, {
        n: "Al Ettihad Tower 1",
        s: "abu-dhabi-al-bateen-al-ettihad-towers-al-ettihad-tower-1"
    }, {
        n: "Rawdha Center",
        s: "abu-dhabi-al-manhal-mubarak-bin-mohammed-street-rawdha-center"
    }, {
        n: "C2 Tower",
        s: "abu-dhabi-al-bateen-six-towers-complex-al-bateen-c2-tower"
    }, {
        n: "NBAD Tower",
        s: "abu-dhabi-al-bateen-al-khaleej-al-arabi-street-nbad-tower"
    }, {
        n: "Liwa Tower",
        s: "abu-dhabi-al-bateen-al-khaleej-al-arabi-street-liwa-tower"
    }, {
        n: "Al Naseem Residences B",
        s: "abu-dhabi-al-raha-beach-al-bandar-al-naseem-residences-b"
    }, {
        n: "Building D",
        s: "abu-dhabi-al-raha-beach-al-zeina-building-d"
    }, {
        n: "Business Park",
        s: "dubai-dubai-south-dubai-world-central-business-park"
    }, {
        n: "Al Hamra Residences",
        s: "ras-al-khaimah-al-hamra-village-al-hamra-residences"
    }, {
        n: "The Mangroves",
        s: "ras-al-khaimah-al-qurm-the-mangroves"
    }, {
        n: "Slemi",
        s: "al-ain-al-jimi-slemi"
    }, {
        n: "Al Mraijeb",
        s: "al-ain-al-jimi-al-mraijeb"
    }, {
        n: "Shaab Al Askar",
        s: "al-ain-zakher-shaab-al-askar"
    }, {
        n: "Phase One",
        s: "al-ain-wahat-al-zaweya-phase-one"
    }, {
        n: "Al Kewaitat",
        s: "al-ain-central-district-al-kewaitat"
    }, {
        n: "Wadi Al Shami",
        s: "al-ain-al-towayya-wadi-al-shami"
    }, {
        n: "Al Meryal",
        s: "al-ain-al-khabisi-al-meryal"
    }, {
        n: "Shabhanat Al Khabisi",
        s: "al-ain-al-khabisi-shabhanat-al-khabisi"
    }, {
        n: "Arenco Buildings",
        s: "umm-al-quwain-al-madar-al-madar-2-arenco-buildings"
    }, {
        n: "Khalidiya Tower B",
        s: "abu-dhabi-al-khalidiya-khalidiya-twin-towers-khalidiya-tower-b"
    }, {
        n: "Ghalia Tower",
        s: "abu-dhabi-al-khalidiya-al-hosn-ghalia-tower"
    }, {
        n: "Crescent Tower 2",
        s: "abu-dhabi-al-khalidiya-crescent-towers-crescent-tower-2"
    }, {
        n: "ZADCO Complex Building C",
        s: "abu-dhabi-al-khalidiya-zadco-complex-zadco-complex-building-c"
    }, {
        n: "Sheikh Saif Tower",
        s: "abu-dhabi-al-khalidiya-zayed-the-first-street-sheikh-saif-tower"
    }, {
        n: "Khalidiya Tower",
        s: "abu-dhabi-al-khalidiya-khalidiya-street-khalidiya-tower"
    }, {
        n: "Danat Al Khan Tower",
        s: "sharjah-al-khan-al-khan-lagoon-danat-al-khan-tower"
    }, {
        n: "The Beachfront",
        s: "ras-al-khaimah-mina-al-arab-the-beachfront"
    }, {
        n: "View Island",
        s: "ras-al-khaimah-al-marjan-island-view-island"
    }, {
        n: "Hili Tower",
        s: "abu-dhabi-al-khalidiya-al-hosn-hili-tower"
    }, {
        n: "Waqf Sheikh Zayed Residential Building",
        s: "abu-dhabi-al-khalidiya-zayed-the-first-street-waqf-sheikh-zayed-residential-building"
    }, {
        n: "Sheikha Salama Tower",
        s: "abu-dhabi-al-khalidiya-khalidiya-street-sheikha-salama-tower"
    }, {
        n: "Golden Tower",
        s: "abu-dhabi-al-khalidiya-khalidiya-street-golden-tower"
    }, {
        n: "CI Tower",
        s: "abu-dhabi-al-khalidiya-cornich-al-khalidiya-ci-tower"
    }, {
        n: "Al Ettihad Tower 3",
        s: "abu-dhabi-al-bateen-al-ettihad-towers-al-ettihad-tower-3"
    }, {
        n: "C6 Tower",
        s: "abu-dhabi-al-bateen-six-towers-complex-al-bateen-c6-tower"
    }, {
        n: "Capital Tower",
        s: "abu-dhabi-al-bateen-al-khaleej-al-arabi-street-capital-tower"
    }, {
        n: "Khalifa Bin Shakhbout Street",
        s: "abu-dhabi-al-bateen-al-khaleej-al-arabi-street-khalifa-bin-shakhbout-street"
    }, {
        n: "Al Naseem Residences A",
        s: "abu-dhabi-al-raha-beach-al-bandar-al-naseem-residences-a"
    }, {
        n: "Al Muneera Townhouses-Mainland",
        s: "abu-dhabi-al-raha-beach-al-muneera-al-muneera-townhouses-mainland"
    }, {
        n: "Al Muneera island villas",
        s: "abu-dhabi-al-raha-beach-al-muneera-al-muneera-island-villas"
    }, {
        n: "Mira Residence 4",
        s: "abu-dhabi-al-raha-beach-mira-residences-mira-residence-4"
    }, {
        n: "Building F",
        s: "abu-dhabi-al-raha-beach-al-zeina-building-f"
    }, {
        n: "Building A",
        s: "abu-dhabi-al-raha-beach-al-zeina-building-a"
    }, {
        n: "Marbella",
        s: "ras-al-khaimah-mina-al-arab-marbella"
    }, {
        n: "Al Mahra Resort",
        s: "ras-al-khaimah-al-marjan-island-al-mahra-resort"
    }, {
        n: "Al Dhait South",
        s: "ras-al-khaimah-al-dhait-al-dhait-south"
    }, {
        n: "DoubleTree by Hilton Ras Al Khaimah",
        s: "ras-al-khaimah-dafan-al-khor-double-tree-by-hilton-ras-al-khaimah"
    }, {
        n: "Al Obaidy Tower",
        s: "abu-dhabi-al-khalidiya-al-istiqlal-street-al-obaidy-tower"
    }, {
        n: "Al Nasr Building",
        s: "abu-dhabi-al-khalidiya-al-hosn-al-nasr-building"
    }, {
        n: "Souror Building",
        s: "abu-dhabi-al-khalidiya-al-hosn-souror-building"
    }, {
        n: "Crescent Tower 1",
        s: "abu-dhabi-al-khalidiya-crescent-towers-crescent-tower-1"
    }, {
        n: "ZADCO Complex Building D",
        s: "abu-dhabi-al-khalidiya-zadco-complex-zadco-complex-building-d"
    }, {
        n: "Bin Fardan Building",
        s: "abu-dhabi-al-khalidiya-zayed-the-first-street-bin-fardan-building"
    }, {
        n: "21 Villas Project",
        s: "abu-dhabi-al-khalidiya-khalidiya-street-21-villas-project"
    }, {
        n: "12 Villas Project",
        s: "abu-dhabi-al-khalidiya-khalidiya-street-12-villas-project"
    }, {
        n: "Khalidiya Centre",
        s: "abu-dhabi-al-khalidiya-cornich-al-khalidiya-khalidiya-centre"
    }, {
        n: "Al Ettihad Tower 2",
        s: "abu-dhabi-al-bateen-al-ettihad-towers-al-ettihad-tower-2"
    }, {
        n: "C1 Tower",
        s: "abu-dhabi-al-bateen-six-towers-complex-al-bateen-c1-tower"
    }, {
        n: "Al Nasser Tower 1",
        s: "abu-dhabi-al-markaziyah-al-markaziyah-west-al-nasser-tower-1"
    }, {
        n: "Al Gurm Centre",
        s: "abu-dhabi-al-bateen-al-khaleej-al-arabi-street-al-gurm-centre"
    }, {
        n: "Sheikh Abdulla Building",
        s: "abu-dhabi-al-khalidiya-al-hosn-sheikh-abdulla-building"
    }, {
        n: "ZADCO Complex Building A",
        s: "abu-dhabi-al-khalidiya-zadco-complex-zadco-complex-building-a"
    }, {
        n: "Areej Tower",
        s: "abu-dhabi-al-khalidiya-zayed-the-first-street-areej-tower"
    }, {
        n: "Al Safa Tower",
        s: "abu-dhabi-al-khalidiya-khalidiya-street-al-safa-tower"
    }, {
        n: "Al Ain Tower",
        s: "abu-dhabi-al-khalidiya-khalidiya-street-al-ain-tower"
    }, {
        n: "Al Ettihad Tower 5",
        s: "abu-dhabi-al-bateen-al-ettihad-towers-al-ettihad-tower-5"
    }, {
        n: "C3 Tower",
        s: "abu-dhabi-al-bateen-six-towers-complex-al-bateen-c3-tower"
    }, {
        n: "C4 Tower",
        s: "abu-dhabi-al-bateen-six-towers-complex-al-bateen-c4-tower"
    }, {
        n: "Abu Dhabi National Exhibition Centre",
        s: "abu-dhabi-al-bateen-al-khaleej-al-arabi-street-abu-dhabi-national-exhibition-centre"
    }, {
        n: "Palm Oasis villas",
        s: "abu-dhabi-al-mushrif-palm-oasis-palm-oasis-villas"
    }, {
        n: "Al Naseem Residences C",
        s: "abu-dhabi-al-raha-beach-al-bandar-al-naseem-residences-c"
    }, {
        n: "Aldar HQ",
        s: "abu-dhabi-al-raha-beach-khor-al-raha-aldar-hq"
    }, {
        n: "Al Muneera Townhouses-Island",
        s: "abu-dhabi-al-raha-beach-al-muneera-al-muneera-townhouses-island"
    }, {
        n: "Mira Residence 1",
        s: "abu-dhabi-al-raha-beach-mira-residences-mira-residence-1"
    }, {
        n: "Mushrif Heights",
        s: "abu-dhabi-al-mushrif-mushrif-park-mushrif-heights"
    }, {
        n: "Al Barza",
        s: "abu-dhabi-al-raha-beach-al-bandar-al-barza"
    }, {
        n: "Al Sana 1",
        s: "abu-dhabi-al-raha-beach-al-muneera-al-sana-1"
    }, {
        n: "Al Nada",
        s: "abu-dhabi-al-raha-beach-al-muneera-al-nada"
    }, {
        n: "Al Rahba",
        s: "abu-dhabi-al-raha-beach-al-muneera-al-rahba"
    }, {
        n: "Al Zeina Sky Villas",
        s: "abu-dhabi-al-raha-beach-al-zeina-al-zeina-sky-villas"
    }, {
        n: "Commercial Tower",
        s: "abu-dhabi-al-wahda-al-wahda-city-towers-commercial-tower"
    }, {
        n: "The Wings",
        s: "abu-dhabi-al-reem-island-najmat-abu-dhabi-the-wings"
    }, {
        n: "Residential Marina-The Marinas",
        s: "abu-dhabi-al-reem-island-najmat-abu-dhabi-residential-marina-the-marinas"
    }, {
        n: "Beach Towers",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-beach-towers"
    }, {
        n: "Creek Tower",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-creek-tower"
    }, {
        n: "Tameer Towers",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-tameer-towers"
    }, {
        n: "Atlantis Towers",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-atlantis-towers"
    }, {
        n: "Al Wifaq Tower",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-al-wifaq-tower"
    }, {
        n: "Z7 Tower",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-z7-tower"
    }, {
        n: "The Gate Tower 1",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-the-gate-tower-1"
    }, {
        n: "The Gate Tower 2",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-the-gate-tower-2"
    }, {
        n: "Tala Tower",
        s: "abu-dhabi-al-reem-island-marina-square-tala-tower"
    }, {
        n: "Al Nada 1",
        s: "abu-dhabi-al-raha-beach-al-muneera-al-nada-1"
    }, {
        n: "Mira Residence 3",
        s: "abu-dhabi-al-raha-beach-mira-residences-mira-residence-3"
    }, {
        n: "Building G",
        s: "abu-dhabi-al-raha-beach-al-zeina-building-g"
    }, {
        n: "Building C",
        s: "abu-dhabi-al-raha-beach-al-zeina-building-c"
    }, {
        n: "Al Markaziya",
        s: "abu-dhabi-city-downtown-cbd-central-business-district-al-markaziya"
    }, {
        n: "Residential Tower",
        s: "abu-dhabi-al-wahda-al-wahda-city-towers-residential-tower"
    }, {
        n: "Leaf Tower",
        s: "abu-dhabi-al-reem-island-tamouh-leaf-tower"
    }, {
        n: "Marina Bay by DAMAC",
        s: "abu-dhabi-al-reem-island-najmat-abu-dhabi-marina-bay-by-damac"
    }, {
        n: "Baheen Tower",
        s: "abu-dhabi-al-reem-island-najmat-abu-dhabi-baheen-tower"
    }, {
        n: "Solitaire Tower",
        s: "abu-dhabi-al-reem-island-najmat-abu-dhabi-solitaire-tower"
    }, {
        n: "Pearl Bay",
        s: "abu-dhabi-al-reem-island-najmat-abu-dhabi-pearl-bay"
    }, {
        n: "Time Tower",
        s: "abu-dhabi-al-reem-island-najmat-abu-dhabi-time-tower"
    }, {
        n: "Nalaya Villas",
        s: "abu-dhabi-al-reem-island-najmat-abu-dhabi-nalaya-villas"
    }, {
        n: "Mangrove Place",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-mangrove-place"
    }, {
        n: "Reflection",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-reflection"
    }, {
        n: "The Boardwalk Residence",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-the-boardwalk-residence"
    }, {
        n: "Lime Tower",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-lime-tower"
    }, {
        n: "Lilac Tower",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-lilac-tower"
    }, {
        n: "Sea View Tower",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-sea-view-tower"
    }, {
        n: "Dari Towers",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-dari-towers"
    }, {
        n: "Amaya Towers",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-amaya-towers"
    }, {
        n: "Sea Side Tower",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-sea-side-tower"
    }, {
        n: "Al Nada 2",
        s: "abu-dhabi-al-raha-beach-al-muneera-al-nada-2"
    }, {
        n: "Al Maha",
        s: "abu-dhabi-al-raha-beach-al-muneera-al-maha"
    }, {
        n: "Mira Residence 2",
        s: "abu-dhabi-al-raha-beach-mira-residences-mira-residence-2"
    }, {
        n: "Building E",
        s: "abu-dhabi-al-raha-beach-al-zeina-building-e"
    }, {
        n: "Al Rahma Tower",
        s: "abu-dhabi-city-downtown-cbd-central-business-district-al-rahma-tower"
    }, {
        n: "Al Marjan Tower",
        s: "abu-dhabi-city-downtown-al-falah-street-al-marjan-tower"
    }, {
        n: "Al Muhaimat Tower",
        s: "abu-dhabi-al-reem-island-najmat-abu-dhabi-al-muhaimat-tower"
    }, {
        n: "Bay Centre Marina-The Marinas",
        s: "abu-dhabi-al-reem-island-najmat-abu-dhabi-bay-centre-marina-the-marinas"
    }, {
        n: "RDK Towers",
        s: "abu-dhabi-al-reem-island-najmat-abu-dhabi-rdk-towers"
    }, {
        n: "Park View",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-park-view"
    }, {
        n: "Sun Tower",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-sun-tower"
    }, {
        n: "X2 Tower",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-x2-tower"
    }, {
        n: "X1 Tower",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-x1-tower"
    }, {
        n: "The Bridges",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-the-bridges"
    }, {
        n: "Burj Al Shams",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-burj-al-shams"
    }, {
        n: "Mangrove Residence",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-mangrove-residence"
    }, {
        n: "The Gate Tower 5A",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-the-gate-tower-5a"
    }, {
        n: "The ARC",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-the-arc"
    }, {
        n: "Al Qurm View",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-al-qurm-view"
    }, {
        n: "Marina Blue Tower",
        s: "abu-dhabi-al-reem-island-marina-square-marina-blue-tower"
    }, {
        n: "Ocean Terrace",
        s: "abu-dhabi-al-reem-island-marina-square-ocean-terrace"
    }, {
        n: "C5 Tower",
        s: "abu-dhabi-al-reem-island-city-of-lights-c5-tower"
    }, {
        n: "C2 Mohamad Al Meheir",
        s: "abu-dhabi-city-downtown-al-falah-street-c2-mohamad-al-meheir"
    }, {
        n: "Rresort Marina-The Marinas",
        s: "abu-dhabi-al-reem-island-najmat-abu-dhabi-rresort-marina-the-marinas"
    }, {
        n: "Wafra Commercial Tower",
        s: "abu-dhabi-al-reem-island-najmat-abu-dhabi-wafra-commercial-tower"
    }, {
        n: "Oceanscape",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-oceanscape"
    }, {
        n: "X3 Tower",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-x3-tower"
    }, {
        n: "Empire Tower",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-empire-tower"
    }, {
        n: "Amber Tower",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-amber-tower"
    }, {
        n: "Hydra Square Tower",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-hydra-square-tower"
    }, {
        n: "Reem Diamond",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-reem-diamond"
    }, {
        n: "Morina Residence",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-morina-residence"
    }, {
        n: "Aurora Towers",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-aurora-towers"
    }, {
        n: "The Gate Tower 5C",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-the-gate-tower-5c"
    }, {
        n: "The Kite Residences",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-the-kite-residences"
    }, {
        n: "RAK Tower",
        s: "abu-dhabi-al-reem-island-marina-square-rak-tower"
    }, {
        n: "MAG 5",
        s: "abu-dhabi-al-reem-island-marina-square-mag-5"
    }, {
        n: "Bay View Tower",
        s: "abu-dhabi-al-reem-island-marina-square-bay-view-tower"
    }, {
        n: "Marina Heights 2",
        s: "abu-dhabi-al-reem-island-marina-square-marina-heights-2"
    }, {
        n: "Marina Spirit",
        s: "abu-dhabi-al-reem-island-city-of-lights-marina-spirit"
    }, {
        n: "Harbour Heights",
        s: "abu-dhabi-al-reem-island-city-of-lights-harbour-heights"
    }, {
        n: "M-6",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-6"
    }, {
        n: "Marafid Tower",
        s: "abu-dhabi-al-reem-island-najmat-abu-dhabi-marafid-tower"
    }, {
        n: "MEERA Shams",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-meera-shams"
    }, {
        n: "Meera 1",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-meera-1"
    }, {
        n: "Y5 Tower",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-y5-tower"
    }, {
        n: "Y6 Tower",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-y6-tower"
    }, {
        n: "Meera 2",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-meera-2"
    }, {
        n: "Azure",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-azure"
    }, {
        n: "The Gate Tower 3",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-the-gate-tower-3"
    }, {
        n: "Sea Face Tower",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-sea-face-tower"
    }, {
        n: "Parkside Residence",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-parkside-residence"
    }, {
        n: "A3 Tower",
        s: "abu-dhabi-al-reem-island-marina-square-a3-tower"
    }, {
        n: "Marina Heights",
        s: "abu-dhabi-al-reem-island-marina-square-marina-heights"
    }, {
        n: "Omega Towers",
        s: "abu-dhabi-al-reem-island-city-of-lights-omega-towers"
    }, {
        n: "Burooj Terraces",
        s: "abu-dhabi-al-reem-island-city-of-lights-burooj-terraces"
    }, {
        n: "Sky Garden",
        s: "abu-dhabi-al-reem-island-city-of-lights-sky-garden"
    }, {
        n: "Horizon Tower B",
        s: "abu-dhabi-al-reem-island-city-of-lights-horizon-tower-b"
    }, {
        n: "Hydra 55 Towers",
        s: "abu-dhabi-al-reem-island-city-of-lights-hydra-55-towers"
    }, {
        n: "Maryah Plaza 1",
        s: "abu-dhabi-al-maryah-maryah-plaza-maryah-plaza-1"
    }, {
        n: "Sowwah Square Tower 3",
        s: "abu-dhabi-al-maryah-sowwah-square-sowwah-square-tower-3"
    }, {
        n: "Mazyad Mall Tower 2",
        s: "abu-dhabi-mohamed-bin-zayed-city-mazyad-mall-mazyad-mall-tower-2"
    }, {
        n: "M-38",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-38"
    }, {
        n: "M-24",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-24"
    }, {
        n: "Al Fahim Building",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-al-fahim-building"
    }, {
        n: "M-20",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-20"
    }, {
        n: "Al Maha Tower",
        s: "abu-dhabi-al-reem-island-marina-square-al-maha-tower"
    }, {
        n: "One Marina Square",
        s: "abu-dhabi-al-reem-island-marina-square-one-marina-square"
    }, {
        n: "Al Durrah Tower",
        s: "abu-dhabi-al-reem-island-marina-square-al-durrah-tower"
    }, {
        n: "C4 Tower",
        s: "abu-dhabi-al-reem-island-city-of-lights-c4-tower"
    }, {
        n: "Sigma Towers",
        s: "abu-dhabi-al-reem-island-city-of-lights-sigma-towers"
    }, {
        n: "Addax port office tower",
        s: "abu-dhabi-al-reem-island-city-of-lights-addax-port-office-tower"
    }, {
        n: "C2 Tower",
        s: "abu-dhabi-al-reem-island-city-of-lights-c2-tower"
    }, {
        n: "Burooj Crystal",
        s: "abu-dhabi-al-reem-island-city-of-lights-burooj-crystal"
    }, {
        n: "Hydra Hollywood Tower",
        s: "abu-dhabi-al-reem-island-city-of-lights-hydra-hollywood-tower"
    }, {
        n: "Maryah Plaza 2",
        s: "abu-dhabi-al-maryah-maryah-plaza-maryah-plaza-2"
    }, {
        n: "Sowwah Square Tower 1",
        s: "abu-dhabi-al-maryah-sowwah-square-sowwah-square-tower-1"
    }, {
        n: "Mazyad Mall Tower 3",
        s: "abu-dhabi-mohamed-bin-zayed-city-mazyad-mall-mazyad-mall-tower-3"
    }, {
        n: "MW-5",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-mw-5"
    }, {
        n: "M-11",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-11"
    }, {
        n: "MN-3",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-mn-3"
    }, {
        n: "M-32",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-32"
    }, {
        n: "MN-2",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-mn-2"
    }, {
        n: "MN-5",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-mn-5"
    }, {
        n: "M-33",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-33"
    }, {
        n: "M-8",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-8"
    }, {
        n: "M-14",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-14"
    }, {
        n: "M-19",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-19"
    }, {
        n: "Zayed Military City Tower 8",
        s: "abu-dhabi-zayed-military-city-zayed-military-city-towers-zayed-military-city-tower-8"
    }, {
        n: "Zone C Retail",
        s: "abu-dhabi-al-reem-island-marina-square-zone-c-retail"
    }, {
        n: "Tamouh Tower",
        s: "abu-dhabi-al-reem-island-marina-square-tamouh-tower"
    }, {
        n: "Synergy Towers",
        s: "abu-dhabi-al-reem-island-city-of-lights-synergy-towers"
    }, {
        n: "Sedrawan Tower",
        s: "abu-dhabi-al-reem-island-city-of-lights-sedrawan-tower"
    }, {
        n: "C6 Tower",
        s: "abu-dhabi-al-reem-island-city-of-lights-c6-tower"
    }, {
        n: "Hydra Avenue Towers",
        s: "abu-dhabi-al-reem-island-city-of-lights-hydra-avenue-towers"
    }, {
        n: "Hydra Executive Towers",
        s: "abu-dhabi-al-reem-island-city-of-lights-hydra-executive-towers"
    }, {
        n: "Maryah Plaza 3",
        s: "abu-dhabi-al-maryah-maryah-plaza-maryah-plaza-3"
    }, {
        n: "Abu Dhabi Global Market (ADGM)",
        s: "abu-dhabi-al-maryah-sowwah-square-abu-dhabi-global-market-adgm"
    }, {
        n: "Mazyad Mall Tower 1",
        s: "abu-dhabi-mohamed-bin-zayed-city-mazyad-mall-mazyad-mall-tower-1"
    }, {
        n: "M-43",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-43"
    }, {
        n: "M-17",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-17"
    }, {
        n: "Mohammad Al Muhairy Building",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-mohammad-al-muhairy-building"
    }, {
        n: "M-45",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-45"
    }, {
        n: "M-37",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-37"
    }, {
        n: "M-36",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-36"
    }, {
        n: "M-25",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-25"
    }, {
        n: "Beachfront Seaside Estate",
        s: "abu-dhabi-nurai-island-beachfront-residence-beachfront-seaside-estate"
    }, {
        n: "Zayed Military City Tower 4",
        s: "abu-dhabi-zayed-military-city-zayed-military-city-towers-zayed-military-city-tower-4"
    }, {
        n: "Zayed Military City Tower 7",
        s: "abu-dhabi-zayed-military-city-zayed-military-city-towers-zayed-military-city-tower-7"
    }, {
        n: "St. Regis",
        s: "abu-dhabi-saadiyat-island-saadiyat-beach-st-regis"
    }, {
        n: "Mediterranean Villas",
        s: "abu-dhabi-saadiyat-island-saadiyat-beach-mediterranean-villas"
    }, {
        n: "Mayan 2",
        s: "abu-dhabi-yas-island-mayan-mayan-2"
    }, {
        n: "The Cedars",
        s: "abu-dhabi-yas-island-yas-acres-the-cedars"
    }, {
        n: "Bloom Central Commercial",
        s: "abu-dhabi-al-tibbiya-bloom-central-bloom-central-commercial"
    }, {
        n: "Hydra Corporate Towers",
        s: "abu-dhabi-al-reem-island-city-of-lights-hydra-corporate-towers"
    }, {
        n: "Maryah Plaza 4",
        s: "abu-dhabi-al-maryah-maryah-plaza-maryah-plaza-4"
    }, {
        n: "Sowwah Square Tower 4",
        s: "abu-dhabi-al-maryah-sowwah-square-sowwah-square-tower-4"
    }, {
        n: "Prestige Tower 17",
        s: "abu-dhabi-mohamed-bin-zayed-city-prestige-towers-prestige-tower-17"
    }, {
        n: "M-3",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-3"
    }, {
        n: "MN-1",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-mn-1"
    }, {
        n: "M-21",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-21"
    }, {
        n: "M-15",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-15"
    }, {
        n: "M-13",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-13"
    }, {
        n: "M-4",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-4"
    }, {
        n: "M-39",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-39"
    }, {
        n: "MW-4",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-mw-4"
    }, {
        n: "Beachfront Shoreline Estate",
        s: "abu-dhabi-nurai-island-beachfront-residence-beachfront-shoreline-estate"
    }, {
        n: "Zayed Military City Tower 9",
        s: "abu-dhabi-zayed-military-city-zayed-military-city-towers-zayed-military-city-tower-9"
    }, {
        n: "Zayed Military City Tower 3",
        s: "abu-dhabi-zayed-military-city-zayed-military-city-towers-zayed-military-city-tower-3"
    }, {
        n: "Saadiyat Beach Golf Views",
        s: "abu-dhabi-saadiyat-island-saadiyat-beach-saadiyat-beach-golf-views"
    }, {
        n: "Mayan 7",
        s: "abu-dhabi-yas-island-mayan-mayan-7"
    }, {
        n: "Liwa Village Villas",
        s: "abu-dhabi-al-ghadeer-liwa-village-liwa-village-villas"
    }, {
        n: "Elite Building",
        s: "abu-dhabi-al-manaseer-khalifa-bin-shakhbout-street-elite-building"
    }, {
        n: "Obaid Al Jabel",
        s: "abu-dhabi-al-manaseer-khalifa-bin-shakhbout-street-obaid-al-jabel"
    }, {
        n: "Park Residences by Rotana",
        s: "abu-dhabi-eastern-road-khalifa-park-park-residences-by-rotana"
    }, {
        n: "Paradise Lakes Tower B5",
        s: "ajman-emirates-city-paradise-lakes-towers-paradise-lakes-tower-b5"
    }, {
        n: "Pixel",
        s: "abu-dhabi-al-reem-island-makers-district-pixel"
    }, {
        n: "Sowwah Square Tower 2",
        s: "abu-dhabi-al-maryah-sowwah-square-sowwah-square-tower-2"
    }, {
        n: "Rosewood Abu Dhabi",
        s: "abu-dhabi-al-maryah-sowwah-square-rosewood-abu-dhabi"
    }, {
        n: "M-9",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-9"
    }, {
        n: "M-5",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-5"
    }, {
        n: "Cubes Park",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-cubes-park"
    }, {
        n: "M-10",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-10"
    }, {
        n: "M-42",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-42"
    }, {
        n: "M-44",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-44"
    }, {
        n: "M-46",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-46"
    }, {
        n: "M-7",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-7"
    }, {
        n: "M-16",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-16"
    }, {
        n: "M-23",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-23"
    }, {
        n: "Mina Tower",
        s: "abu-dhabi-tourist-club-area-mina-road-mina-tower"
    }, {
        n: "Zayed Military City Tower 1",
        s: "abu-dhabi-zayed-military-city-zayed-military-city-towers-zayed-military-city-tower-1"
    }, {
        n: "Zayed Military City Tower 5",
        s: "abu-dhabi-zayed-military-city-zayed-military-city-towers-zayed-military-city-tower-5"
    }, {
        n: "Mayan 1",
        s: "abu-dhabi-yas-island-mayan-mayan-1"
    }, {
        n: "MN-6",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-mn-6"
    }, {
        n: "Jannah Burj Al Sarab",
        s: "abu-dhabi-tourist-club-area-mina-road-jannah-burj-al-sarab"
    }, {
        n: "Zayed Military City Tower 2",
        s: "abu-dhabi-zayed-military-city-zayed-military-city-towers-zayed-military-city-tower-2"
    }, {
        n: "Nudra",
        s: "abu-dhabi-saadiyat-island-saadiyat-cultural-district-nudra"
    }, {
        n: "Contemporary Villas",
        s: "abu-dhabi-saadiyat-island-saadiyat-beach-contemporary-villas"
    }, {
        n: "Mayan 6",
        s: "abu-dhabi-yas-island-mayan-mayan-6"
    }, {
        n: "Mayan 3",
        s: "abu-dhabi-yas-island-mayan-mayan-3"
    }, {
        n: "Tower 1",
        s: "abu-dhabi-al-aman-al-bustan-complex-tower-1"
    }, {
        n: "Mohammed Salem Mansoor",
        s: "abu-dhabi-al-manaseer-khalifa-bin-shakhbout-street-mohammed-salem-mansoor"
    }, {
        n: "Lulu Tower B",
        s: "abu-dhabi-khalifa-street-lulu-towers-lulu-tower-b"
    }, {
        n: "Liwa Village",
        s: "abu-dhabi-al-karama-al-musalla-area-liwa-village"
    }, {
        n: "Danat Tower A",
        s: "abu-dhabi-muroor-area-danat-towers-danat-tower-a"
    }, {
        n: "C105",
        s: "abu-dhabi-rawdhat-abu-dhabi-sheikh-rashid-bin-saeed-street-c105"
    }, {
        n: "Capital Plaza Tower A",
        s: "abu-dhabi-corniche-road-capital-plaza-capital-plaza-tower-a"
    }, {
        n: "Etihad Tower 5",
        s: "abu-dhabi-corniche-road-etihad-towers-etihad-tower-5"
    }, {
        n: "Faya at Bloom Gardens",
        s: "abu-dhabi-al-salam-street-bloom-gardens-faya-at-bloom-gardens"
    }, {
        n: "Tower 33",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-33"
    }, {
        n: "Tower 32",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-32"
    }, {
        n: "Tower 20",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-20"
    }, {
        n: "Tower 16",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-16"
    }, {
        n: "Tower 22",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-22"
    }, {
        n: "Tower 24",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-24"
    }, {
        n: "Tower 4",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-4"
    }, {
        n: "Business Tower C",
        s: "abu-dhabi-electra-street-business-towers-business-tower-c"
    }, {
        n: "Arabian Villas",
        s: "abu-dhabi-saadiyat-island-saadiyat-beach-arabian-villas"
    }, {
        n: "Mayan 4",
        s: "abu-dhabi-yas-island-mayan-mayan-4"
    }, {
        n: "Lea",
        s: "abu-dhabi-yas-island-yas-acres-lea"
    }, {
        n: "Bloom Central Residential",
        s: "abu-dhabi-al-tibbiya-bloom-central-bloom-central-residential"
    }, {
        n: "Al Qubaisi Compound",
        s: "abu-dhabi-al-manaseer-khalifa-bin-shakhbout-street-al-qubaisi-compound"
    }, {
        n: "Mangrove One",
        s: "abu-dhabi-eastern-road-eastern-mangroves-complex-mangrove-one"
    }, {
        n: "Liwa Centre Tower 3",
        s: "abu-dhabi-hamdan-street-liwa-centre-towers-liwa-centre-tower-3"
    }, {
        n: "Danat Tower B",
        s: "abu-dhabi-muroor-area-danat-towers-danat-tower-b"
    }, {
        n: "Shangri-La Hotel",
        s: "abu-dhabi-al-maqtaa-qaryat-al-beri-shangri-la-hotel"
    }, {
        n: "Etihad Tower 2",
        s: "abu-dhabi-corniche-road-etihad-towers-etihad-tower-2"
    }, {
        n: "Al Sahel Tower 2",
        s: "abu-dhabi-corniche-road-al-sahel-towers-al-sahel-tower-2"
    }, {
        n: "Al Seef Village Mall",
        s: "abu-dhabi-al-salam-street-al-seef-village-al-seef-village-mall"
    }, {
        n: "Tower 18",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-18"
    }, {
        n: "Tower 36",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-36"
    }, {
        n: "Tower 17",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-17"
    }, {
        n: "Tower 46",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-46"
    }, {
        n: "Tower 26",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-26"
    }, {
        n: "Tower 10",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-10"
    }, {
        n: "Tower 42",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-42"
    }, {
        n: "Tower 5",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-5"
    }, {
        n: "Desert Style",
        s: "abu-dhabi-al-reef-al-reef-villas-desert-style"
    }, {
        n: "Al Masaood Building",
        s: "abu-dhabi-al-manaseer-khalifa-bin-shakhbout-street-al-masaood-building"
    }, {
        n: "Lulu Tower A",
        s: "abu-dhabi-khalifa-street-lulu-towers-lulu-tower-a"
    }, {
        n: "Al Dhabi Residence complex",
        s: "abu-dhabi-eastern-road-khalifa-park-al-dhabi-residence-complex"
    }, {
        n: "Hamdan Tower",
        s: "abu-dhabi-hamdan-street-east-corniche-road-hamdan-tower"
    }, {
        n: "Al Muroor Building",
        s: "abu-dhabi-muroor-area-sultan-bin-zayed-the-first-street-al-muroor-building"
    }, {
        n: "Capital Plaza Tower C",
        s: "abu-dhabi-corniche-road-capital-plaza-capital-plaza-tower-c"
    }, {
        n: "AL Diar Tower 2",
        s: "abu-dhabi-corniche-road-al-diar-towers-al-diar-tower-2"
    }, {
        n: "Tower 15",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-15"
    }, {
        n: "Tower 27",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-27"
    }, {
        n: "Tower 44",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-44"
    }, {
        n: "Tower 13",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-13"
    }, {
        n: "Tower 45",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-45"
    }, {
        n: "Tower 43",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-43"
    }, {
        n: "Tower 3",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-3"
    }, {
        n: "Arabian Style",
        s: "abu-dhabi-al-reef-al-reef-villas-arabian-style"
    }, {
        n: "Business Tower B",
        s: "abu-dhabi-electra-street-business-towers-business-tower-b"
    }, {
        n: "Ajman One Tower 7",
        s: "ajman-ajman-downtown-ajman-one-ajman-one-tower-7"
    }, {
        n: "Falcon Tower 5",
        s: "ajman-ajman-downtown-falcon-towers-falcon-tower-5"
    }, {
        n: "Al Khor Tower A0",
        s: "ajman-ajman-downtown-al-khor-towers-al-khor-tower-a0"
    }, {
        n: "Al Khor Tower B5",
        s: "ajman-ajman-downtown-al-khor-towers-al-khor-tower-b5"
    }, {
        n: "Al Khor Tower B3",
        s: "ajman-ajman-downtown-al-khail-towers-al-khor-tower-b3"
    }, {
        n: "Tower A2",
        s: "ajman-ajman-downtown-ajman-pearl-towers-tower-a2"
    }, {
        n: "Tower A1",
        s: "ajman-ajman-downtown-ajman-pearl-towers-tower-a1"
    }, {
        n: "AL Wazzan Building B",
        s: "sharjah-al-majaz-al-majaz-3-al-wazzan-building-b"
    }, {
        n: "Liwa Centre Tower 2",
        s: "abu-dhabi-hamdan-street-liwa-centre-towers-liwa-centre-tower-2"
    }, {
        n: "Al Maqtaa Tower 1",
        s: "abu-dhabi-muroor-area-hadbat-al-zafranah-al-maqtaa-tower-1"
    }, {
        n: "Capital Plaza Office Tower",
        s: "abu-dhabi-corniche-road-capital-plaza-capital-plaza-office-tower"
    }, {
        n: "Etihad Tower 4",
        s: "abu-dhabi-corniche-road-etihad-towers-etihad-tower-4"
    }, {
        n: "Al Sahel Tower 1",
        s: "abu-dhabi-corniche-road-al-sahel-towers-al-sahel-tower-1"
    }, {
        n: "Tower 39",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-39"
    }, {
        n: "Tower 40",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-40"
    }, {
        n: "Tower 19",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-19"
    }, {
        n: "Tower 38",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-38"
    }, {
        n: "Tower 41",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-41"
    }, {
        n: "Tower 29",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-29"
    }, {
        n: "Tower 28",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-28"
    }, {
        n: "Tower 21",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-21"
    }, {
        n: "Tower 30",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-30"
    }, {
        n: "Tower 8",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-8"
    }, {
        n: "Mediterranean Style",
        s: "abu-dhabi-al-reef-al-reef-villas-mediterranean-style"
    }, {
        n: "Business Tower A",
        s: "abu-dhabi-electra-street-business-towers-business-tower-a"
    }, {
        n: "Ajman One Tower 10",
        s: "ajman-ajman-downtown-ajman-one-ajman-one-tower-10"
    }, {
        n: "Ajman One Tower 9",
        s: "ajman-ajman-downtown-ajman-one-ajman-one-tower-9"
    }, {
        n: "Ajman One Tower 2",
        s: "ajman-ajman-downtown-ajman-one-ajman-one-tower-2"
    }, {
        n: "Falcon Tower 6",
        s: "ajman-ajman-downtown-falcon-towers-falcon-tower-6"
    }, {
        n: "Oasis 1",
        s: "abu-dhabi-masdar-city-oasis-residences-oasis-1"
    }, {
        n: "Al Khor Tower A3",
        s: "ajman-ajman-downtown-al-khor-towers-al-khor-tower-a3"
    }, {
        n: "Pearl MAAM Residence",
        s: "abu-dhabi-muroor-area-sultan-bin-zayed-the-first-street-pearl-maam-residence"
    }, {
        n: "Capital Plaza Tower B",
        s: "abu-dhabi-corniche-road-capital-plaza-capital-plaza-tower-b"
    }, {
        n: "Etihad Tower 3",
        s: "abu-dhabi-corniche-road-etihad-towers-etihad-tower-3"
    }, {
        n: "AL Diar Tower 1",
        s: "abu-dhabi-corniche-road-al-diar-towers-al-diar-tower-1"
    }, {
        n: "Tower 34",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-34"
    }, {
        n: "Tower 23",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-23"
    }, {
        n: "Tower 11",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-11"
    }, {
        n: "Tower 12",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-12"
    }, {
        n: "Tower 35",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-35"
    }, {
        n: "Tower 14",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-14"
    }, {
        n: "Tower 9",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-9"
    }, {
        n: "Tower 31",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-31"
    }, {
        n: "Tower 37",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-37"
    }, {
        n: "Business Tower D",
        s: "abu-dhabi-electra-street-business-towers-business-tower-d"
    }, {
        n: "Ajman One Tower 8",
        s: "ajman-ajman-downtown-ajman-one-ajman-one-tower-8"
    }, {
        n: "Ajman One Tower 4",
        s: "ajman-ajman-downtown-ajman-one-ajman-one-tower-4"
    }, {
        n: "Ajman One Tower 12",
        s: "ajman-ajman-downtown-ajman-one-ajman-one-tower-12"
    }, {
        n: "Falcon Tower 4",
        s: "ajman-ajman-downtown-falcon-towers-falcon-tower-4"
    }, {
        n: "Oasis 2",
        s: "abu-dhabi-masdar-city-oasis-residences-oasis-2"
    }, {
        n: "Al Khor Tower A5",
        s: "ajman-ajman-downtown-al-khor-towers-al-khor-tower-a5"
    }, {
        n: "Al Khor Tower B2",
        s: "ajman-ajman-downtown-al-khor-towers-al-khor-tower-b2"
    }, {
        n: "Contemporary Style",
        s: "abu-dhabi-al-reef-al-reef-villas-contemporary-style"
    }, {
        n: "Ajman One Tower 1",
        s: "ajman-ajman-downtown-ajman-one-ajman-one-tower-1"
    }, {
        n: "Ajman One Tower 11",
        s: "ajman-ajman-downtown-ajman-one-ajman-one-tower-11"
    }, {
        n: "Ajman One Tower 5",
        s: "ajman-ajman-downtown-ajman-one-ajman-one-tower-5"
    }, {
        n: "Falcon Tower 2",
        s: "ajman-ajman-downtown-falcon-towers-falcon-tower-2"
    }, {
        n: "Al Khor Tower B4",
        s: "ajman-ajman-downtown-al-khor-towers-al-khor-tower-b4"
    }, {
        n: "Al Khor Tower A6",
        s: "ajman-ajman-downtown-al-khor-towers-al-khor-tower-a6"
    }, {
        n: "Al Khor Tower B1",
        s: "ajman-ajman-downtown-al-khor-towers-al-khor-tower-b1"
    }, {
        n: "Al Jurf 2",
        s: "ajman-ajman-downtown-al-jurf-al-jurf-2"
    }, {
        n: "Tower B3",
        s: "ajman-ajman-downtown-ajman-pearl-towers-tower-b3"
    }, {
        n: "Tower C1",
        s: "ajman-ajman-downtown-ajman-pearl-towers-tower-c1"
    }, {
        n: "Lake View Tower 3",
        s: "ajman-emirates-city-lake-view-towers-lake-view-tower-3"
    }, {
        n: "Paradise Lakes Tower B3",
        s: "ajman-emirates-city-paradise-lakes-towers-paradise-lakes-tower-b3"
    }, {
        n: "Emirates Lake Tower 2",
        s: "ajman-emirates-city-emirates-lake-towers-emirates-lake-tower-2"
    }, {
        n: "Green Lake Tower 1",
        s: "ajman-emirates-city-green-lake-towers-green-lake-tower-1"
    }, {
        n: "Rainbow Tower 3",
        s: "ajman-emirates-city-rainbow-towers-rainbow-tower-3"
    }, {
        n: "Al Rawda 2 Villas",
        s: "ajman-al-rawda-al-rawda-2-al-rawda-2-villas"
    }, {
        n: "Ideal 1",
        s: "ajman-al-rawda-al-rawda-3-ideal-1"
    }, {
        n: "Erica 2H",
        s: "ajman-ajman-uptown-ajman-uptown-villas-erica-2h"
    }, {
        n: "Shami Garden Tower I",
        s: "ajman-marmooka-city-shami-garden-towers-shami-garden-tower-i"
    }, {
        n: "Al Rashed 1",
        s: "ajman-al-humaid-city-al-rashid-towers-al-rashed-1"
    }, {
        n: "Savannah Heights Tower 1",
        s: "ajman-al-humaid-city-savannah-heights-savannah-heights-tower-1"
    }, {
        n: "Century Tower 2",
        s: "ajman-park-view-city-century-towers-century-tower-2"
    }, {
        n: "Al Naemiya Tower 2",
        s: "ajman-al-naemiyah-al-naemiya-towers-al-naemiya-tower-2"
    }, {
        n: "Al Marwa Tower 3",
        s: "sharjah-al-khan-al-marwa-towers-al-marwa-tower-3"
    }, {
        n: "Style Tower",
        s: "sharjah-al-khan-al-khan-lagoon-style-tower"
    }, {
        n: "Beach Tower 1",
        s: "sharjah-al-khan-al-khan-lagoon-beach-tower-1"
    }, {
        n: "Ameer Bu Khamseen Tower",
        s: "sharjah-al-majaz-al-majaz-3-ameer-bu-khamseen-tower"
    }, {
        n: "Falcon Tower 3",
        s: "ajman-ajman-downtown-falcon-towers-falcon-tower-3"
    }, {
        n: "Al Khor Tower A4",
        s: "ajman-ajman-downtown-al-khor-towers-al-khor-tower-a4"
    }, {
        n: "Al Khor Tower A8",
        s: "ajman-ajman-downtown-al-khor-towers-al-khor-tower-a8"
    }, {
        n: "Al Khor Tower B6",
        s: "ajman-ajman-downtown-al-khor-towers-al-khor-tower-b6"
    }, {
        n: "Al Khail Tower 1",
        s: "ajman-ajman-downtown-al-khail-towers-al-khail-tower-1"
    }, {
        n: "Tower B1",
        s: "ajman-ajman-downtown-ajman-pearl-towers-tower-b1"
    }, {
        n: "Tower A3",
        s: "ajman-ajman-downtown-ajman-pearl-towers-tower-a3"
    }, {
        n: "Lake View Tower 4",
        s: "ajman-emirates-city-lake-view-towers-lake-view-tower-4"
    }, {
        n: "Paradise Lakes Tower B6",
        s: "ajman-emirates-city-paradise-lakes-towers-paradise-lakes-tower-b6"
    }, {
        n: "Emirates Lake Tower 1",
        s: "ajman-emirates-city-emirates-lake-towers-emirates-lake-tower-1"
    }, {
        n: "Green Lake Tower 2",
        s: "ajman-emirates-city-green-lake-towers-green-lake-tower-2"
    }, {
        n: "Goldcrest Dreams 4",
        s: "ajman-emirates-city-goldcrest-dreams-goldcrest-dreams-4"
    }, {
        n: "Rainbow Tower 2",
        s: "ajman-emirates-city-rainbow-towers-rainbow-tower-2"
    }, {
        n: "Orient Tower 2",
        s: "ajman-al-bustan-orient-towers-orient-tower-2"
    }, {
        n: "Al Rawda 3 Villas",
        s: "ajman-al-rawda-al-rawda-3-al-rawda-3-villas"
    }, {
        n: "Camellia",
        s: "ajman-ajman-uptown-ajman-uptown-villas-camellia"
    }, {
        n: "Begonia",
        s: "ajman-ajman-uptown-ajman-uptown-villas-begonia"
    }, {
        n: "Al Rashid 5",
        s: "ajman-al-humaid-city-al-rashid-towers-al-rashid-5"
    }, {
        n: "Al Rashid 7",
        s: "ajman-al-humaid-city-al-rashid-towers-al-rashid-7"
    }, {
        n: "Century Tower 1",
        s: "ajman-park-view-city-century-towers-century-tower-1"
    }, {
        n: "Al Naemiya Tower 1",
        s: "ajman-al-naemiyah-al-naemiya-towers-al-naemiya-tower-1"
    }, {
        n: "Al Marwa Tower 2",
        s: "sharjah-al-khan-al-marwa-towers-al-marwa-tower-2"
    }, {
        n: "Narjes Tower",
        s: "sharjah-al-khan-al-khan-lagoon-narjes-tower"
    }, {
        n: "Al Hilal Tower",
        s: "sharjah-al-khan-al-khan-lagoon-al-hilal-tower"
    }, {
        n: "Al Jurf 3",
        s: "ajman-ajman-downtown-al-jurf-al-jurf-3"
    }, {
        n: "Tower C2",
        s: "ajman-ajman-downtown-ajman-pearl-towers-tower-c2"
    }, {
        n: "Lake View Tower 2",
        s: "ajman-emirates-city-lake-view-towers-lake-view-tower-2"
    }, {
        n: "Paradise Lakes Tower B8",
        s: "ajman-emirates-city-paradise-lakes-towers-paradise-lakes-tower-b8"
    }, {
        n: "Paradise Lakes Tower B7",
        s: "ajman-emirates-city-paradise-lakes-towers-paradise-lakes-tower-b7"
    }, {
        n: "Shami Tower 2",
        s: "ajman-emirates-city-shami-twin-towers-shami-tower-2"
    }, {
        n: "Goldcrest Dreams 1",
        s: "ajman-emirates-city-goldcrest-dreams-goldcrest-dreams-1"
    }, {
        n: "Rainbow Tower 1",
        s: "ajman-emirates-city-rainbow-towers-rainbow-tower-1"
    }, {
        n: "Oasis Tower",
        s: "ajman-al-rashidiya-al-rashidiya-1-oasis-tower"
    }, {
        n: "Al Nafoora 1 building",
        s: "ajman-al-rawda-al-rawda-2-al-nafoora-1-building"
    }, {
        n: "Kentia",
        s: "ajman-ajman-uptown-ajman-uptown-villas-kentia"
    }, {
        n: "Erica 1",
        s: "ajman-ajman-uptown-ajman-uptown-villas-erica-1"
    }, {
        n: "Blossom Tower 1",
        s: "ajman-al-humaid-city-blossom-towers-blossom-tower-1"
    }, {
        n: "Al Rashid 8",
        s: "ajman-al-humaid-city-al-rashid-towers-al-rashid-8"
    }, {
        n: "ICT Tower 1",
        s: "ajman-park-view-city-ict-towers-ict-tower-1"
    }, {
        n: "Sheikh Jaber Al Sabah Street",
        s: "ajman-al-naemiyah-al-naimiya-sheikh-jaber-al-sabah-street"
    }, {
        n: "Abna Saqer Building",
        s: "ajman-al-hamidiya-al-hamidiya-1-abna-saqer-building"
    }, {
        n: "Al Shahd Tower",
        s: "sharjah-al-khan-al-khan-lagoon-al-shahd-tower"
    }, {
        n: "Al Ghazal Tower",
        s: "sharjah-al-khan-al-khan-lagoon-al-ghazal-tower"
    }, {
        n: "Al Salam Tower",
        s: "sharjah-al-khan-al-khan-lagoon-al-salam-tower"
    }, {
        n: "Al Anwar Tower",
        s: "sharjah-al-khan-al-khan-lagoon-al-anwar-tower"
    }, {
        n: "Rose Tower 2",
        s: "sharjah-al-khan-rose-tower-rose-tower-2"
    }, {
        n: "Al Kaloti Tower",
        s: "sharjah-al-majaz-al-majaz-2-al-kaloti-tower"
    }, {
        n: "Canal Star Tower",
        s: "sharjah-al-majaz-al-majaz-3-canal-star-tower"
    }, {
        n: "Al Jurf 1",
        s: "ajman-ajman-downtown-al-jurf-al-jurf-1"
    }, {
        n: "Tower B2",
        s: "ajman-ajman-downtown-ajman-pearl-towers-tower-b2"
    }, {
        n: "Lake View Tower 1",
        s: "ajman-emirates-city-lake-view-towers-lake-view-tower-1"
    }, {
        n: "Paradise Lakes Tower B9",
        s: "ajman-emirates-city-paradise-lakes-towers-paradise-lakes-tower-b9"
    }, {
        n: "Paradise Lakes Tower B4",
        s: "ajman-emirates-city-paradise-lakes-towers-paradise-lakes-tower-b4"
    }, {
        n: "Shami Tower 1",
        s: "ajman-emirates-city-shami-twin-towers-shami-tower-1"
    }, {
        n: "Goldcrest Dreams 2",
        s: "ajman-emirates-city-goldcrest-dreams-goldcrest-dreams-2"
    }, {
        n: "Rainbow Tower 4",
        s: "ajman-emirates-city-rainbow-towers-rainbow-tower-4"
    }, {
        n: "Orient Tower 1",
        s: "ajman-al-bustan-orient-towers-orient-tower-1"
    }, {
        n: "Al Mina Building",
        s: "ajman-al-rawda-al-rawda-2-al-mina-building"
    }, {
        n: "Erica",
        s: "ajman-ajman-uptown-ajman-uptown-villas-erica"
    }, {
        n: "Acacia",
        s: "ajman-ajman-uptown-ajman-uptown-villas-acacia"
    }, {
        n: "Blossom Tower 2",
        s: "ajman-al-humaid-city-blossom-towers-blossom-tower-2"
    }, {
        n: "Al Rashid 6",
        s: "ajman-al-humaid-city-al-rashid-towers-al-rashid-6"
    }, {
        n: "ICT Tower 2",
        s: "ajman-park-view-city-ict-towers-ict-tower-2"
    }, {
        n: "Al Naemiya Tower 3",
        s: "ajman-al-naemiyah-al-naemiya-towers-al-naemiya-tower-3"
    }, {
        n: "Al Marwa Tower 1",
        s: "sharjah-al-khan-al-marwa-towers-al-marwa-tower-1"
    }, {
        n: "Al Burj Tower",
        s: "sharjah-al-khan-al-khan-lagoon-al-burj-tower"
    }, {
        n: "SIB Tower",
        s: "sharjah-al-khan-al-khan-lagoon-sib-tower"
    }, {
        n: "Al Khan Lagoon Tower",
        s: "sharjah-al-khan-al-khan-lagoon-al-khan-lagoon-tower"
    }, {
        n: "Beach Tower 2",
        s: "sharjah-al-khan-al-khan-lagoon-beach-tower-2"
    }, {
        n: "Al Taawoon Tower 2",
        s: "sharjah-al-khan-al-taawoon-towers-al-taawoon-tower-2"
    }, {
        n: "Rose Tower 1",
        s: "sharjah-al-khan-rose-tower-rose-tower-1"
    }, {
        n: "Riviera Tower",
        s: "sharjah-al-khan-al-khan-corniche-riviera-tower"
    }, {
        n: "Ibtikar 1",
        s: "sharjah-al-majaz-al-majaz-2-ibtikar-1"
    }, {
        n: "Al Majaz Pearl",
        s: "sharjah-al-majaz-al-majaz-2-al-majaz-pearl"
    }, {
        n: "Goldcrest Dreams 3",
        s: "ajman-emirates-city-goldcrest-dreams-goldcrest-dreams-3"
    }, {
        n: "Dahlia",
        s: "ajman-ajman-uptown-ajman-uptown-villas-dahlia"
    }, {
        n: "Erica 2V",
        s: "ajman-ajman-uptown-ajman-uptown-villas-erica-2v"
    }, {
        n: "Shami Garden Tower II",
        s: "ajman-marmooka-city-shami-garden-towers-shami-garden-tower-ii"
    }, {
        n: "Al Rashed 2",
        s: "ajman-al-humaid-city-al-rashid-towers-al-rashed-2"
    }, {
        n: "Savannah Heights Tower 2",
        s: "ajman-al-humaid-city-savannah-heights-savannah-heights-tower-2"
    }, {
        n: "Rawan Building",
        s: "ajman-al-naemiyah-al-naimiya-rawan-building"
    }, {
        n: "Ajman 44 building",
        s: "ajman-al-hamidiya-al-hamidiya-1-ajman-44-building"
    }, {
        n: "Al Rund Tower",
        s: "sharjah-al-khan-al-khan-lagoon-al-rund-tower"
    }, {
        n: "Asas Tower",
        s: "sharjah-al-khan-al-khan-lagoon-asas-tower"
    }, {
        n: "Al Sondos Tower",
        s: "sharjah-al-khan-al-khan-lagoon-al-sondos-tower"
    }, {
        n: "Al Taawoon Tower 3",
        s: "sharjah-al-khan-al-taawoon-towers-al-taawoon-tower-3"
    }, {
        n: "Al Bandary Tower A",
        s: "sharjah-al-khan-al-bandary-twin-towers-al-bandary-tower-a"
    }, {
        n: "Al Ferasa Tower",
        s: "sharjah-al-majaz-al-majaz-1-al-ferasa-tower"
    }, {
        n: "Sarkua Tower",
        s: "sharjah-al-majaz-al-majaz-2-sarkua-tower"
    }, {
        n: "Kaluti Building",
        s: "sharjah-al-majaz-al-majaz-2-kaluti-building"
    }, {
        n: "Al Fardan Centre",
        s: "sharjah-al-majaz-al-majaz-3-al-fardan-centre"
    }, {
        n: "City Gate",
        s: "sharjah-al-majaz-al-majaz-3-city-gate"
    }, {
        n: "Al Ghanem Business Center",
        s: "sharjah-al-majaz-al-majaz-3-al-ghanem-business-center"
    }, {
        n: "Sarab Tower",
        s: "sharjah-al-majaz-al-majaz-3-sarab-tower"
    }, {
        n: "Sahara Tower 1",
        s: "sharjah-al-nahda-sahara-complex-sahara-tower-1"
    }, {
        n: "Al Yasmin building",
        s: "sharjah-sharjah-industrial-area-industrial-area-6-al-yasmin-building"
    }, {
        n: "Al Yasmin 2 Building",
        s: "sharjah-sharjah-industrial-area-industrial-area-6-al-yasmin-2-building"
    }, {
        n: "Manazil Tower 5",
        s: "sharjah-al-taawun-al-taawun-street-manazil-tower-5"
    }, {
        n: "Terhab Hotel & Residence",
        s: "sharjah-al-taawun-al-taawun-street-terhab-hotel-residence"
    }, {
        n: "Bin Ham Tower C",
        s: "sharjah-al-taawun-bin-ham-towers-bin-ham-tower-c"
    }, {
        n: "Zakhir Tower 2",
        s: "sharjah-al-taawun-zakhir-towers-zakhir-tower-2"
    }, {
        n: "Hoshi 2",
        s: "sharjah-al-badie-hoshi-hoshi-2"
    }, {
        n: "Al Taawoon Tower 1",
        s: "sharjah-al-khan-al-taawoon-towers-al-taawoon-tower-1"
    }, {
        n: "Al Bandary Tower B",
        s: "sharjah-al-khan-al-bandary-twin-towers-al-bandary-tower-b"
    }, {
        n: "Al Mabrooka Tower",
        s: "sharjah-al-majaz-al-majaz-1-al-mabrooka-tower"
    }, {
        n: "Dhubian Tower",
        s: "sharjah-al-majaz-al-majaz-2-dhubian-tower"
    }, {
        n: "Al Wafaa building",
        s: "sharjah-al-majaz-al-majaz-2-al-wafaa-building"
    }, {
        n: "Al Mawarid Tower",
        s: "sharjah-al-majaz-al-majaz-3-al-mawarid-tower"
    }, {
        n: "Sarh Al Emarat Tower",
        s: "sharjah-al-majaz-al-majaz-3-sarh-al-emarat-tower"
    }, {
        n: "Blue Tower",
        s: "sharjah-al-majaz-al-majaz-3-blue-tower"
    }, {
        n: "Palm Tower 3",
        s: "sharjah-al-majaz-palm-towers-palm-tower-3"
    }, {
        n: "Moon Tower 1",
        s: "sharjah-al-nahda-moon-towers-moon-tower-1"
    }, {
        n: "Sahara Tower 2",
        s: "sharjah-al-nahda-sahara-complex-sahara-tower-2"
    }, {
        n: "Block B",
        s: "sharjah-al-nahda-al-nadha-jam-residential-complex-block-b"
    }, {
        n: "Al Mozon Building 1",
        s: "sharjah-sharjah-industrial-area-industrial-area-6-al-mozon-building-1"
    }, {
        n: "SG 13 Building",
        s: "sharjah-sharjah-industrial-area-industrial-area-13-sg-13-building"
    }, {
        n: "Hend Tower",
        s: "sharjah-al-taawun-al-taawun-street-hend-tower"
    }, {
        n: "Tiger 2 Building",
        s: "sharjah-al-taawun-al-taawun-street-tiger-2-building"
    }, {
        n: "Zakhir Tower 3",
        s: "sharjah-al-taawun-zakhir-towers-zakhir-tower-3"
    }, {
        n: "The Chedi Al Bait Sharjah Hotel",
        s: "sharjah-al-gharb-al-mareija-the-chedi-al-bait-sharjah-hotel"
    }, {
        n: "Al Falah Residential",
        s: "sharjah-al-badie-al-falah-al-falah-residential"
    }, {
        n: "Al Marzouqi Tower B",
        s: "sharjah-al-qasemiya-al-marzouqi-towers-al-marzouqi-tower-b"
    }, {
        n: "Budaniq 9 Building",
        s: "sharjah-al-qasemiya-budaniq-budaniq-9-building"
    }, {
        n: "Al Marwa Tower 1",
        s: "sharjah-cornich-al-buhaira-al-marwa-towers-al-marwa-tower-1"
    }, {
        n: "Al Zahia 2",
        s: "sharjah-muwaileh-commercial-al-zahia-al-zahia-2"
    }, {
        n: "Camellia",
        s: "dubai-al-barari-al-barari-villas-camellia"
    }, {
        n: "Acacia",
        s: "dubai-al-barari-al-barari-villas-acacia"
    }, {
        n: "Desert Leaf 1",
        s: "dubai-al-barari-desert-leaf-desert-leaf-1"
    }, {
        n: "Silk Leaf 7",
        s: "dubai-al-barari-silk-leaf-silk-leaf-7"
    }, {
        n: "Al Telal Building",
        s: "sharjah-al-majaz-al-majaz-2-al-telal-building"
    }, {
        n: "Hamza Al Khatib Tower",
        s: "sharjah-al-majaz-al-majaz-2-hamza-al-khatib-tower"
    }, {
        n: "Thuraya Al Majaz Building",
        s: "sharjah-al-majaz-al-majaz-3-thuraya-al-majaz-building"
    }, {
        n: "585 Building",
        s: "sharjah-al-majaz-al-majaz-3-585-building"
    }, {
        n: "Sahara Tower 5",
        s: "sharjah-al-nahda-sahara-complex-sahara-tower-5"
    }, {
        n: "SG 10 Building",
        s: "sharjah-sharjah-industrial-area-industrial-area-10-sg-10-building"
    }, {
        n: "Majestic Tower",
        s: "sharjah-al-taawun-al-taawun-street-majestic-tower"
    }, {
        n: "Al Mamzar Plaza",
        s: "sharjah-al-taawun-al-taawun-street-al-mamzar-plaza"
    }, {
        n: "Al Nabaa Building",
        s: "sharjah-al-sharq-al-naba-ah-al-nabaa-building"
    }, {
        n: "Hoshi 1",
        s: "sharjah-al-badie-hoshi-hoshi-1"
    }, {
        n: "CG Mall",
        s: "sharjah-al-qasemiya-al-marzouqi-towers-cg-mall"
    }, {
        n: "Abu shagara Building 2",
        s: "sharjah-al-qasemiya-budaniq-abu-shagara-building-2"
    }, {
        n: "Al Ramla West",
        s: "sharjah-halwan-al-ramla-al-ramla-west"
    }, {
        n: "Desert Leaf 4",
        s: "dubai-al-barari-desert-leaf-desert-leaf-4"
    }, {
        n: "Jasmine Leaf 8",
        s: "dubai-al-barari-jasmine-leaf-jasmine-leaf-8"
    }, {
        n: "Jasmine Leaf 5",
        s: "dubai-al-barari-jasmine-leaf-jasmine-leaf-5"
    }, {
        n: "I Rise Office Tower",
        s: "dubai-al-barsha-al-barsha-2-i-rise-office-tower"
    }, {
        n: "Rasis Business Centre",
        s: "dubai-al-barsha-al-barsha-1-rasis-business-centre"
    }, {
        n: "Nopoli Tower",
        s: "dubai-al-barsha-al-barsha-1-nopoli-tower"
    }, {
        n: "AL Wazzan Building A",
        s: "sharjah-al-majaz-al-majaz-3-al-wazzan-building-a"
    }, {
        n: "Safia Tower",
        s: "sharjah-al-majaz-al-majaz-3-safia-tower"
    }, {
        n: "Palm Tower 1",
        s: "sharjah-al-majaz-palm-towers-palm-tower-1"
    }, {
        n: "Sahara Tower 6",
        s: "sharjah-al-nahda-sahara-complex-sahara-tower-6"
    }, {
        n: "Sahara Tower 3",
        s: "sharjah-al-nahda-sahara-complex-sahara-tower-3"
    }, {
        n: "Al Mozon Building 3",
        s: "sharjah-sharjah-industrial-area-industrial-area-6-al-mozon-building-3"
    }, {
        n: "Al Yasmin 3 Building",
        s: "sharjah-sharjah-industrial-area-industrial-area-6-al-yasmin-3-building"
    }, {
        n: "Industrial Building 13",
        s: "sharjah-sharjah-industrial-area-industrial-area-13-industrial-building-13"
    }, {
        n: "Manazil Tower 2",
        s: "sharjah-al-taawun-al-taawun-street-manazil-tower-2"
    }, {
        n: "Bin Ham Tower B",
        s: "sharjah-al-taawun-bin-ham-towers-bin-ham-tower-b"
    }, {
        n: "Zakhir Tower 1",
        s: "sharjah-al-taawun-zakhir-towers-zakhir-tower-1"
    }, {
        n: "Al Falah Agricultural",
        s: "sharjah-al-badie-al-falah-al-falah-agricultural"
    }, {
        n: "Al Ateek Tower 1",
        s: "sharjah-al-gharb-al-shuwaihean-al-ateek-tower-1"
    }, {
        n: "Al Marzouqi Tower C",
        s: "sharjah-al-qasemiya-al-marzouqi-towers-al-marzouqi-tower-c"
    }, {
        n: "Budaniq 7 Building",
        s: "sharjah-al-qasemiya-budaniq-budaniq-7-building"
    }, {
        n: "Al Marwa Tower 3",
        s: "sharjah-cornich-al-buhaira-al-marwa-towers-al-marwa-tower-3"
    }, {
        n: "Al Fanar 2",
        s: "fujairah-downtown-fujairah-al-fanar-towers-al-fanar-2"
    }, {
        n: "Bromellia",
        s: "dubai-al-barari-al-barari-villas-bromellia"
    }, {
        n: "Desert Leaf 5",
        s: "dubai-al-barari-desert-leaf-desert-leaf-5"
    }, {
        n: "Desert Leaf 2",
        s: "dubai-al-barari-desert-leaf-desert-leaf-2"
    }, {
        n: "Silk Leaf 5",
        s: "dubai-al-barari-silk-leaf-silk-leaf-5"
    }, {
        n: "Jasmine Leaf 2",
        s: "dubai-al-barari-jasmine-leaf-jasmine-leaf-2"
    }, {
        n: "Jasmine Leaf 9",
        s: "dubai-al-barari-jasmine-leaf-jasmine-leaf-9"
    }, {
        n: "Street 5B",
        s: "dubai-al-barsha-al-barsha-2-street-5b"
    }, {
        n: "Grand Midwest View Hotel Apartments",
        s: "dubai-al-barsha-al-barsha-1-grand-midwest-view-hotel-apartments"
    }, {
        n: "Casablanca 1",
        s: "dubai-al-barsha-al-barsha-1-casablanca-1"
    }, {
        n: "Bayti 33",
        s: "dubai-al-barsha-al-barsha-1-bayti-33"
    }, {
        n: "Palm Tower 2",
        s: "sharjah-al-majaz-palm-towers-palm-tower-2"
    }, {
        n: "Moon Tower 2",
        s: "sharjah-al-nahda-moon-towers-moon-tower-2"
    }, {
        n: "Sahara Tower 4",
        s: "sharjah-al-nahda-sahara-complex-sahara-tower-4"
    }, {
        n: "Al Mozon Building 2",
        s: "sharjah-sharjah-industrial-area-industrial-area-6-al-mozon-building-2"
    }, {
        n: "Magestic Tower",
        s: "sharjah-al-mamzar-sharjah-al-mamzar-magestic-tower"
    }, {
        n: "Al Waha Residence",
        s: "sharjah-al-taawun-al-taawun-street-al-waha-residence"
    }, {
        n: "Al Hafeet Tower",
        s: "sharjah-al-taawun-al-taawun-street-al-hafeet-tower"
    }, {
        n: "Bin Ham Tower A",
        s: "sharjah-al-taawun-bin-ham-towers-bin-ham-tower-a"
    }, {
        n: "Al Naba'ah 8 Building",
        s: "sharjah-al-sharq-al-naba-ah-al-naba-ah-8-building"
    }, {
        n: "Al Hooshi Villas",
        s: "sharjah-al-badie-hoshi-al-hooshi-villas"
    }, {
        n: "Al Marzouqi Tower A",
        s: "sharjah-al-qasemiya-al-marzouqi-towers-al-marzouqi-tower-a"
    }, {
        n: "White Tower",
        s: "sharjah-al-qasemiya-al-nad-white-tower"
    }, {
        n: "Al Marwa Tower 2",
        s: "sharjah-cornich-al-buhaira-al-marwa-towers-al-marwa-tower-2"
    }, {
        n: "Al Zahia 3",
        s: "sharjah-muwaileh-commercial-al-zahia-al-zahia-3"
    }, {
        n: "Al Fanar 1",
        s: "fujairah-downtown-fujairah-al-fanar-towers-al-fanar-1"
    }, {
        n: "Dahlia",
        s: "dubai-al-barari-al-barari-villas-dahlia"
    }, {
        n: "Desert Leaf 3",
        s: "dubai-al-barari-desert-leaf-desert-leaf-3"
    }, {
        n: "Silk Leaf 3",
        s: "dubai-al-barari-silk-leaf-silk-leaf-3"
    }, {
        n: "Jasmine Leaf 3",
        s: "dubai-al-barari-jasmine-leaf-jasmine-leaf-3"
    }, {
        n: "Jasmine Leaf 1",
        s: "dubai-al-barari-jasmine-leaf-jasmine-leaf-1"
    }, {
        n: "93-94 Avenues",
        s: "dubai-al-barsha-al-barsha-2-93-94-avenues"
    }, {
        n: "Villa Lantana 2",
        s: "dubai-al-barsha-villa-lantana-villa-lantana-2"
    }, {
        n: "Dusseldorf Business Point",
        s: "dubai-al-barsha-al-barsha-1-dusseldorf-business-point"
    }, {
        n: "La Residence",
        s: "dubai-al-barsha-al-barsha-1-la-residence"
    }, {
        n: "Zumurud Building",
        s: "dubai-al-barsha-al-barsha-1-zumurud-building"
    }, {
        n: "Al Reem Residence",
        s: "dubai-al-barsha-al-barsha-1-al-reem-residence"
    }, {
        n: "Habib Al Mulla Building",
        s: "dubai-al-barsha-al-barsha-1-habib-al-mulla-building"
    }, {
        n: "Al Zahia 1",
        s: "sharjah-muwaileh-commercial-al-zahia-al-zahia-1"
    }, {
        n: "Desert Leaf 6",
        s: "dubai-al-barari-desert-leaf-desert-leaf-6"
    }, {
        n: "Silk Leaf 4",
        s: "dubai-al-barari-silk-leaf-silk-leaf-4"
    }, {
        n: "Jasmine Leaf 7",
        s: "dubai-al-barari-jasmine-leaf-jasmine-leaf-7"
    }, {
        n: "Street 7B",
        s: "dubai-al-barsha-al-barsha-2-street-7b"
    }, {
        n: "City House 2",
        s: "dubai-al-barsha-al-barsha-1-city-house-2"
    }, {
        n: "Casablanca 2",
        s: "dubai-al-barsha-al-barsha-1-casablanca-2"
    }, {
        n: "City Stay Pearl Hotel Apartment",
        s: "dubai-al-barsha-al-barsha-1-city-stay-pearl-hotel-apartment"
    }, {
        n: "Ivory Grand Hotel Apartments",
        s: "dubai-al-barsha-al-barsha-1-ivory-grand-hotel-apartments"
    }, {
        n: "City Stay Prime Hotel Apartment",
        s: "dubai-al-barsha-al-barsha-1-city-stay-prime-hotel-apartment"
    }, {
        n: "Emerald Barsha 1",
        s: "dubai-al-barsha-al-barsha-1-emerald-barsha-1"
    }, {
        n: "Al Barsha Business Center",
        s: "dubai-al-barsha-al-barsha-1-al-barsha-business-center"
    }, {
        n: "Lulu Building",
        s: "dubai-al-barsha-al-barsha-1-lulu-building"
    }, {
        n: "D2 Tower",
        s: "dubai-al-barsha-al-barsha-1-d2-tower"
    }, {
        n: "Al Attar Business Centre",
        s: "dubai-al-barsha-al-barsha-1-al-attar-business-centre"
    }, {
        n: "Al Telal 11",
        s: "dubai-al-barsha-al-barsha-1-al-telal-11"
    }, {
        n: "La Villa Najd Hotel Apartment",
        s: "dubai-al-barsha-al-barsha-1-la-villa-najd-hotel-apartment"
    }, {
        n: "API Business Suites",
        s: "dubai-al-barsha-al-barsha-1-api-business-suites"
    }, {
        n: "Arabian Gulf Hotel Apartments",
        s: "dubai-al-barsha-al-barsha-1-arabian-gulf-hotel-apartments"
    }, {
        n: "Oak Villas",
        s: "dubai-al-barsha-al-barsha-1-oak-villas"
    }, {
        n: "Al Maktab Tower",
        s: "dubai-al-barsha-al-barsha-1-al-maktab-tower"
    }, {
        n: "SBL Building 361",
        s: "dubai-al-barsha-al-barsha-1-sbl-building-361"
    }, {
        n: "Al Sayegh Building",
        s: "dubai-al-barsha-al-barsha-1-al-sayegh-building"
    }, {
        n: "Millennium Al Barsha",
        s: "dubai-al-barsha-al-barsha-1-millennium-al-barsha"
    }, {
        n: "DoubleTree by Hilton Hotel",
        s: "dubai-al-barsha-al-barsha-1-double-tree-by-hilton-hotel"
    }, {
        n: "Al Barsha 2 Villas",
        s: "dubai-al-barsha-al-barsha-2-al-barsha-2-villas"
    }, {
        n: "Trio Building",
        s: "dubai-al-barsha-al-barsha-1-trio-building"
    }, {
        n: "Code Business Tower",
        s: "dubai-al-barsha-al-barsha-1-code-business-tower"
    }, {
        n: "Casablanca 4",
        s: "dubai-al-barsha-al-barsha-1-casablanca-4"
    }, {
        n: "Barsha Valley",
        s: "dubai-al-barsha-al-barsha-1-barsha-valley"
    }, {
        n: "Sand Dunes",
        s: "dubai-al-barsha-al-barsha-1-sand-dunes"
    }, {
        n: "Dawoud Abdulrehman Al Barsha 1",
        s: "dubai-al-barsha-al-barsha-1-dawoud-abdulrehman-al-barsha-1"
    }, {
        n: "Opera Compound",
        s: "dubai-al-barsha-al-barsha-1-opera-compound"
    }, {
        n: "Al Hawai Barsha Building 2",
        s: "dubai-al-barsha-al-barsha-1-al-hawai-barsha-building-2"
    }, {
        n: "Barsha Modern",
        s: "dubai-al-barsha-al-barsha-1-barsha-modern"
    }, {
        n: "Hadia Tower",
        s: "dubai-al-barsha-al-barsha-1-hadia-tower"
    }, {
        n: "Al Sarraf 2 Building",
        s: "dubai-al-barsha-al-barsha-1-al-sarraf-2-building"
    }, {
        n: "Zee Zee Tower",
        s: "dubai-al-barsha-al-barsha-1-zee-zee-tower"
    }, {
        n: "Al Adiyat Residence 2",
        s: "dubai-al-barsha-al-barsha-1-al-adiyat-residence-2"
    }, {
        n: "Boutique Hotel Apartments",
        s: "dubai-al-barsha-al-barsha-1-boutique-hotel-apartments"
    }, {
        n: "Bahwan Tower Al Barsha",
        s: "dubai-al-barsha-al-barsha-1-bahwan-tower-al-barsha"
    }, {
        n: "Green Barsha Complex",
        s: "dubai-al-barsha-al-barsha-1-green-barsha-complex"
    }, {
        n: "Rabwah 1",
        s: "dubai-al-barsha-al-barsha-1-rabwah-1"
    }, {
        n: "Rose 2",
        s: "dubai-al-barsha-al-barsha-1-rose-2"
    }, {
        n: "Bayti 20",
        s: "dubai-al-barsha-al-barsha-1-bayti-20"
    }, {
        n: "Marmara Hotel Apartments",
        s: "dubai-al-barsha-al-barsha-1-marmara-hotel-apartments"
    }, {
        n: "Montrose A",
        s: "dubai-al-barsha-al-barsha-south-montrose-a"
    }, {
        n: "Al Rostamani Building",
        s: "dubai-al-quoz-al-quoz-4-al-rostamani-building"
    }, {
        n: "City House 1",
        s: "dubai-al-barsha-al-barsha-1-city-house-1"
    }, {
        n: "Casablanca 3",
        s: "dubai-al-barsha-al-barsha-1-casablanca-3"
    }, {
        n: "Al Habbai Building",
        s: "dubai-al-barsha-al-barsha-1-al-habbai-building"
    }, {
        n: "Al Khoory Hotel Apartments",
        s: "dubai-al-barsha-al-barsha-1-al-khoory-hotel-apartments"
    }, {
        n: "Yes Business Centre",
        s: "dubai-al-barsha-al-barsha-1-yes-business-centre"
    }, {
        n: "City Stay Hotel",
        s: "dubai-al-barsha-al-barsha-1-city-stay-hotel"
    }, {
        n: "Abidos Hotel Apartment",
        s: "dubai-al-barsha-al-barsha-1-abidos-hotel-apartment"
    }, {
        n: "Elite Commercial Tower",
        s: "dubai-al-barsha-al-barsha-1-elite-commercial-tower"
    }, {
        n: "SBL Building 365",
        s: "dubai-al-barsha-al-barsha-1-sbl-building-365"
    }, {
        n: "Sama Building",
        s: "dubai-al-barsha-al-barsha-1-sama-building"
    }, {
        n: "Emerald Court",
        s: "dubai-al-barsha-al-barsha-1-emerald-court"
    }, {
        n: "Larimar Apartments",
        s: "dubai-al-barsha-al-barsha-1-larimar-apartments"
    }, {
        n: "Al Barsha 1 Villas",
        s: "dubai-al-barsha-al-barsha-1-al-barsha-1-villas"
    }, {
        n: "Al Mashroom 4",
        s: "dubai-al-barsha-al-barsha-1-al-mashroom-4"
    }, {
        n: "Horizon Building",
        s: "dubai-al-barsha-al-barsha-1-horizon-building"
    }, {
        n: "SBL Building 341",
        s: "dubai-al-barsha-al-barsha-1-sbl-building-341"
    }, {
        n: "Metro Building",
        s: "dubai-al-barsha-al-barsha-1-metro-building"
    }, {
        n: "R445",
        s: "dubai-al-barsha-al-barsha-1-r445"
    }, {
        n: "Al Nasser Compound",
        s: "dubai-al-barsha-al-barsha-1-al-nasser-compound"
    }, {
        n: "Grand Excelsior Hotel",
        s: "dubai-al-barsha-al-barsha-1-grand-excelsior-hotel"
    }, {
        n: "Rose Palace",
        s: "dubai-al-barsha-al-barsha-south-rose-palace"
    }, {
        n: "Avenue Residence 2",
        s: "dubai-al-furjan-avenue-residence-avenue-residence-2"
    }, {
        n: "Azizi Liatris",
        s: "dubai-al-furjan-azizi-residence-azizi-liatris"
    }, {
        n: "Dubai Style",
        s: "dubai-al-furjan-north-village-dubai-style"
    }, {
        n: "Murano Residences 5",
        s: "dubai-al-furjan-murano-residences-murano-residences-5"
    }, {
        n: "Almasa",
        s: "dubai-al-furjan-phase-1-almasa"
    }, {
        n: "Al Murad Tower",
        s: "dubai-al-barsha-al-barsha-1-al-murad-tower"
    }, {
        n: "Dunes Hotel Apartments",
        s: "dubai-al-barsha-al-barsha-1-dunes-hotel-apartments"
    }, {
        n: "Moosawi 2 Building",
        s: "dubai-al-barsha-al-barsha-1-moosawi-2-building"
    }, {
        n: "Al Telal 5",
        s: "dubai-al-barsha-al-barsha-1-al-telal-5"
    }, {
        n: "Al Bader Building",
        s: "dubai-al-barsha-al-barsha-1-al-bader-building"
    }, {
        n: "Desert Group 1",
        s: "dubai-al-barsha-al-barsha-1-desert-group-1"
    }, {
        n: "Bin Khalid Building",
        s: "dubai-al-barsha-al-barsha-1-bin-khalid-building"
    }, {
        n: "Saratoga Complex",
        s: "dubai-al-barsha-al-barsha-1-saratoga-complex"
    }, {
        n: "Al Janahi Building",
        s: "dubai-al-barsha-al-barsha-1-al-janahi-building"
    }, {
        n: "Deyaar building",
        s: "dubai-al-barsha-al-barsha-1-deyaar-building"
    }, {
        n: "Al Shaya Building",
        s: "dubai-al-barsha-al-barsha-1-al-shaya-building"
    }, {
        n: "Faraidooni Building",
        s: "dubai-al-barsha-al-barsha-1-faraidooni-building"
    }, {
        n: "Wasl R441",
        s: "dubai-al-barsha-al-barsha-1-wasl-r441"
    }, {
        n: "Al Salam Tower",
        s: "dubai-al-barsha-al-barsha-1-al-salam-tower"
    }, {
        n: "Rabwah 2",
        s: "dubai-al-barsha-al-barsha-1-rabwah-2"
    }, {
        n: "Al Barsha 3 Villas",
        s: "dubai-al-barsha-al-barsha-3-al-barsha-3-villas"
    }, {
        n: "Al Barsha South 3",
        s: "dubai-al-barsha-al-barsha-south-al-barsha-south-3"
    }, {
        n: "Bella Rose",
        s: "dubai-al-barsha-al-barsha-south-bella-rose"
    }, {
        n: "Avenue Residence 1",
        s: "dubai-al-furjan-avenue-residence-avenue-residence-1"
    }, {
        n: "Iris",
        s: "dubai-al-furjan-azizi-residence-iris"
    }, {
        n: "Daisy",
        s: "dubai-al-furjan-azizi-residence-daisy"
    }, {
        n: "Quortaj",
        s: "dubai-al-furjan-north-village-quortaj"
    }, {
        n: "Murano Residences 1",
        s: "dubai-al-furjan-murano-residences-murano-residences-1"
    }, {
        n: "Al Quoz 4 Villas",
        s: "dubai-al-quoz-al-quoz-4-al-quoz-4-villas"
    }, {
        n: "MALIA",
        s: "dubai-al-quoz-al-quoz-industrial-area-malia"
    }, {
        n: "Sunrise Building",
        s: "dubai-al-barsha-al-barsha-1-sunrise-building"
    }, {
        n: "Al Zarooni Building",
        s: "dubai-al-barsha-al-barsha-1-al-zarooni-building"
    }, {
        n: "Al Noon Residence",
        s: "dubai-al-barsha-al-barsha-1-al-noon-residence"
    }, {
        n: "Heritage Building",
        s: "dubai-al-barsha-al-barsha-1-heritage-building"
    }, {
        n: "AL Zarouni Business Center",
        s: "dubai-al-barsha-al-barsha-1-al-zarouni-business-center"
    }, {
        n: "Boca Residence",
        s: "dubai-al-barsha-al-barsha-1-boca-residence"
    }, {
        n: "Bait Aseel 2",
        s: "dubai-al-barsha-al-barsha-1-bait-aseel-2"
    }, {
        n: "Al Maha Tower A",
        s: "dubai-al-barsha-al-barsha-1-al-maha-tower-a"
    }, {
        n: "Golden Sands Tower",
        s: "dubai-al-barsha-al-barsha-1-golden-sands-tower"
    }, {
        n: "Al Barsha South 4",
        s: "dubai-al-barsha-al-barsha-south-al-barsha-south-4"
    }, {
        n: "Glamz by Danube",
        s: "dubai-al-furjan-glamz-glamz-by-danube"
    }, {
        n: "Feirouz",
        s: "dubai-al-furjan-azizi-residence-feirouz"
    }, {
        n: "Tulip",
        s: "dubai-al-furjan-azizi-residence-tulip"
    }, {
        n: "Masakin Al Furjan",
        s: "dubai-al-furjan-south-village-masakin-al-furjan"
    }, {
        n: "Murano Residences 4",
        s: "dubai-al-furjan-murano-residences-murano-residences-4"
    }, {
        n: "Yasamine",
        s: "dubai-al-furjan-phase-1-yasamine"
    }, {
        n: "Al Faris Mall",
        s: "dubai-al-quoz-al-quoz-1-al-faris-mall"
    }, {
        n: "Al Quoz Industrial Area 4",
        s: "dubai-al-quoz-al-quoz-industrial-area-al-quoz-industrial-area-4"
    }, {
        n: "TAQ",
        s: "dubai-al-quoz-al-quoz-industrial-area-taq"
    }, {
        n: "DIB",
        s: "dubai-al-quoz-al-quoz-industrial-area-dib"
    }, {
        n: "JAQ",
        s: "dubai-al-quoz-al-quoz-industrial-area-jaq"
    }, {
        n: "PAQ",
        s: "dubai-al-quoz-al-quoz-industrial-area-paq"
    }, {
        n: "Al Qusias Industrial Area 4",
        s: "dubai-al-qusais-al-qusais-industrial-area-al-qusias-industrial-area-4"
    }, {
        n: "Mona Residence",
        s: "dubai-al-qusais-al-qusais-industrial-area-mona-residence"
    }, {
        n: "Al Madan B2",
        s: "dubai-al-qusais-al-qusais-residential-area-al-madan-b2"
    }, {
        n: "Al Maha Tower B",
        s: "dubai-al-barsha-al-barsha-1-al-maha-tower-b"
    }, {
        n: "Al Barsha South 2",
        s: "dubai-al-barsha-al-barsha-south-al-barsha-south-2"
    }, {
        n: "Montrose B",
        s: "dubai-al-barsha-al-barsha-south-montrose-b"
    }, {
        n: "Yasmine",
        s: "dubai-al-furjan-azizi-residence-yasmine"
    }, {
        n: "Freesia",
        s: "dubai-al-furjan-azizi-residence-freesia"
    }, {
        n: "Farishta",
        s: "dubai-al-furjan-azizi-residence-farishta"
    }, {
        n: "Murano Residences 3",
        s: "dubai-al-furjan-murano-residences-murano-residences-3"
    }, {
        n: "The Estate Residence",
        s: "dubai-al-furjan-phase-1-the-estate-residence"
    }, {
        n: "Al Quoz 1 Villas",
        s: "dubai-al-quoz-al-quoz-1-al-quoz-1-villas"
    }, {
        n: "IAQ",
        s: "dubai-al-quoz-al-quoz-industrial-area-iaq"
    }, {
        n: "VAQ",
        s: "dubai-al-quoz-al-quoz-industrial-area-vaq"
    }, {
        n: "WAQ",
        s: "dubai-al-quoz-al-quoz-industrial-area-waq"
    }, {
        n: "Al Quoz Industrial Area 2",
        s: "dubai-al-quoz-al-quoz-industrial-area-al-quoz-industrial-area-2"
    }, {
        n: "BCL",
        s: "dubai-al-quoz-al-quoz-industrial-area-bcl"
    }, {
        n: "Hamad Khamis Al Ghuwais Building",
        s: "dubai-al-qusais-al-qusais-industrial-area-hamad-khamis-al-ghuwais-building"
    }, {
        n: "Gold and Diamond Park Building 4",
        s: "dubai-al-quoz-al-quoz-3-gold-and-diamond-park-building-4"
    }, {
        n: "Manazel Deira Building",
        s: "dubai-al-qusais-al-qusais-residential-area-manazel-deira-building"
    }, {
        n: "Barsha South Villas",
        s: "dubai-al-barsha-al-barsha-south-barsha-south-villas"
    }, {
        n: "Candace Acacia",
        s: "dubai-al-furjan-azizi-residence-candace-acacia"
    }, {
        n: "Orchid",
        s: "dubai-al-furjan-azizi-residence-orchid"
    }, {
        n: "Candace Aster",
        s: "dubai-al-furjan-azizi-residence-candace-aster"
    }, {
        n: "Murano Residences 2",
        s: "dubai-al-furjan-murano-residences-murano-residences-2"
    }, {
        n: "Al Hejaz",
        s: "dubai-al-furjan-phase-1-al-hejaz"
    }, {
        n: "Time Machine Office Block",
        s: "dubai-al-quoz-al-quoz-1-time-machine-office-block"
    }, {
        n: "LAQ",
        s: "dubai-al-quoz-al-quoz-industrial-area-laq"
    }, {
        n: "Rawabeh Building",
        s: "dubai-al-quoz-al-quoz-industrial-area-rawabeh-building"
    }, {
        n: "KAQ",
        s: "dubai-al-quoz-al-quoz-industrial-area-kaq"
    }, {
        n: "Lotus Plaza",
        s: "dubai-al-quoz-al-quoz-industrial-area-lotus-plaza"
    }, {
        n: "Al Quoz Industrial Area 3",
        s: "dubai-al-quoz-al-quoz-industrial-area-al-quoz-industrial-area-3"
    }, {
        n: "Tasaheel building",
        s: "dubai-al-qusais-al-qusais-industrial-area-tasaheel-building"
    }, {
        n: "The Curve",
        s: "dubai-al-quoz-al-quoz-3-the-curve"
    }, {
        n: "Al Khail Gate",
        s: "dubai-al-quoz-al-quoz-2-al-khail-gate"
    }, {
        n: "Al Madan B3",
        s: "dubai-al-qusais-al-qusais-residential-area-al-madan-b3"
    }, {
        n: "Rahaf Tower",
        s: "dubai-al-qusais-al-qusais-residential-area-rahaf-tower"
    }, {
        n: "Block G",
        s: "dubai-al-wasl-dar-wasl-block-g"
    }, {
        n: "Block B",
        s: "dubai-al-wasl-dar-wasl-block-b"
    }, {
        n: "Al Telal 6",
        s: "dubai-al-satwa-al-hudaiba-al-telal-6"
    }, {
        n: "Al Dhiyafa Centre",
        s: "dubai-al-satwa-al-diyafah-al-dhiyafa-centre"
    }, {
        n: "HAQ",
        s: "dubai-al-quoz-al-quoz-industrial-area-haq"
    }, {
        n: "wasl Crystal I",
        s: "dubai-al-quoz-al-quoz-industrial-area-wasl-crystal-i"
    }, {
        n: "MAQ",
        s: "dubai-al-quoz-al-quoz-industrial-area-maq"
    }, {
        n: "wasl Crystal III",
        s: "dubai-al-quoz-al-quoz-industrial-area-wasl-crystal-iii"
    }, {
        n: "QSI",
        s: "dubai-al-qusais-al-qusais-industrial-area-qsi"
    }, {
        n: "Gold and Diamond Park Building 6",
        s: "dubai-al-quoz-al-quoz-3-gold-and-diamond-park-building-6"
    }, {
        n: "Al Qusais 1",
        s: "dubai-al-qusais-al-qusais-residential-area-al-qusais-1"
    }, {
        n: "Al Madan B5",
        s: "dubai-al-qusais-al-qusais-residential-area-al-madan-b5"
    }, {
        n: "Zahrat Al Madan B1",
        s: "dubai-al-qusais-al-qusais-residential-area-zahrat-al-madan-b1"
    }, {
        n: "Danat Al Warqa'a",
        s: "dubai-al-warqa-a-al-warqa-a-1-danat-al-warqa-a"
    }, {
        n: "Baiti 1",
        s: "dubai-al-warqa-a-al-warqa-a-1-baiti-1"
    }, {
        n: "Al Telal 8",
        s: "dubai-al-warqa-a-al-warqa-a-1-al-telal-8"
    }, {
        n: "Al Blouki Building",
        s: "dubai-al-warqa-a-al-warqa-a-1-al-blouki-building"
    }, {
        n: "Alma 1",
        s: "dubai-arabian-ranches-alma-alma-1"
    }, {
        n: "Alma 2",
        s: "dubai-arabian-ranches-alma-alma-2"
    }, {
        n: "Alvorada 2",
        s: "dubai-arabian-ranches-alvorada-alvorada-2"
    }, {
        n: "Alvorada 1",
        s: "dubai-arabian-ranches-alvorada-alvorada-1"
    }, {
        n: "Centrium Tower 1",
        s: "dubai-dubai-production-city-impz-centrium-towers-centrium-tower-1"
    }, {
        n: "DAQ",
        s: "dubai-al-quoz-al-quoz-industrial-area-daq"
    }, {
        n: "STL",
        s: "dubai-al-quoz-al-quoz-industrial-area-stl"
    }, {
        n: "Al Qusias Industrial Area 2",
        s: "dubai-al-qusais-al-qusais-industrial-area-al-qusias-industrial-area-2"
    }, {
        n: "Al Qusias Industrial Area 5",
        s: "dubai-al-qusais-al-qusais-industrial-area-al-qusias-industrial-area-5"
    }, {
        n: "Gold&Diamond park",
        s: "dubai-al-quoz-al-quoz-3-gold-diamond-park"
    }, {
        n: "Gold and Diamond Park Building 7",
        s: "dubai-al-quoz-al-quoz-3-gold-and-diamond-park-building-7"
    }, {
        n: "Al Madan B7",
        s: "dubai-al-qusais-al-qusais-residential-area-al-madan-b7"
    }, {
        n: "Seagull Building",
        s: "dubai-al-qusais-al-qusais-residential-area-seagull-building"
    }, {
        n: "Al Madan B6",
        s: "dubai-al-qusais-al-qusais-residential-area-al-madan-b6"
    }, {
        n: "Al Jabri Tower 4",
        s: "dubai-al-qusais-al-qusais-residential-area-al-jabri-tower-4"
    }, {
        n: "Splendour Villas",
        s: "dubai-al-safa-al-safa-1-splendour-villas"
    }, {
        n: "Al Wasl Villas",
        s: "dubai-al-wasl-al-wasl-road-al-wasl-villas"
    }, {
        n: "Block D",
        s: "dubai-al-wasl-dar-wasl-block-d"
    }, {
        n: "Block E",
        s: "dubai-al-wasl-dar-wasl-block-e"
    }, {
        n: "Dune Building",
        s: "dubai-al-satwa-al-diyafah-dune-building"
    }, {
        n: "Al Mashroom 1",
        s: "dubai-al-warqa-a-al-warqa-a-1-al-mashroom-1"
    }, {
        n: "Desert Group 5",
        s: "dubai-al-warqa-a-al-warqa-a-1-desert-group-5"
    }, {
        n: "Al Warqa'a 1 Villas",
        s: "dubai-al-warqa-a-al-warqa-a-1-al-warqa-a-1-villas"
    }, {
        n: "Al Shirawi Building",
        s: "dubai-al-warqa-a-al-warqa-a-1-al-shirawi-building"
    }, {
        n: "Al Reem 1",
        s: "dubai-arabian-ranches-al-reem-al-reem-1"
    }, {
        n: "Al Madan B4",
        s: "dubai-al-qusais-al-qusais-residential-area-al-madan-b4"
    }, {
        n: "Hamda 4 Building",
        s: "dubai-al-qusais-al-qusais-residential-area-hamda-4-building"
    }, {
        n: "Al Dalal Building",
        s: "dubai-al-qusais-al-qusais-residential-area-al-dalal-building"
    }, {
        n: "Al Safa 2 Villas",
        s: "dubai-al-safa-al-safa-2-al-safa-2-villas"
    }, {
        n: "Al Samaya Building",
        s: "dubai-al-wasl-al-wasl-road-al-samaya-building"
    }, {
        n: "Block H",
        s: "dubai-al-wasl-dar-wasl-block-h"
    }, {
        n: "Al Telal 9",
        s: "dubai-al-warqa-a-al-warqa-a-1-al-telal-9"
    }, {
        n: "M A O Building",
        s: "dubai-al-warqa-a-al-warqa-a-1-m-a-o-building"
    }, {
        n: "Makeen Residence 1",
        s: "dubai-al-warqa-a-al-warqa-a-1-makeen-residence-1"
    }, {
        n: "Alvorada 4",
        s: "dubai-arabian-ranches-alvorada-alvorada-4"
    }, {
        n: "La Avenida 2",
        s: "dubai-arabian-ranches-la-avenida-la-avenida-2"
    }, {
        n: "Bay Square Building 10",
        s: "dubai-business-bay-bay-square-bay-square-building-10"
    }, {
        n: "Bay Square Building 2",
        s: "dubai-business-bay-bay-square-bay-square-building-2"
    }, {
        n: "The Corner",
        s: "dubai-business-bay-damac-towers-the-corner"
    }, {
        n: "La Residence Tower 2",
        s: "dubai-business-bay-lotus-la-residence-tower-2"
    }, {
        n: "Tasameem Tower 2",
        s: "dubai-business-bay-tasameem-towers-tasameem-tower-2"
    }, {
        n: "Al Rubaya Building",
        s: "dubai-al-qusais-al-qusais-residential-area-al-rubaya-building"
    }, {
        n: "Al Khayat Villas Compund",
        s: "dubai-al-safa-al-safa-1-al-khayat-villas-compund"
    }, {
        n: "Block F",
        s: "dubai-al-wasl-dar-wasl-block-f"
    }, {
        n: "Block C",
        s: "dubai-al-wasl-dar-wasl-block-c"
    }, {
        n: "H1 Building",
        s: "dubai-al-satwa-al-hudaiba-h1-building"
    }, {
        n: "Al Meena Residence",
        s: "dubai-al-satwa-al-diyafah-al-meena-residence"
    }, {
        n: "Desert Group 11",
        s: "dubai-al-warqa-a-al-warqa-a-1-desert-group-11"
    }, {
        n: "Al Mashroom 2",
        s: "dubai-al-warqa-a-al-warqa-a-1-al-mashroom-2"
    }, {
        n: "Desert Group 6",
        s: "dubai-al-warqa-a-al-warqa-a-1-desert-group-6"
    }, {
        n: "Warqa Blue",
        s: "dubai-al-warqa-a-al-warqa-a-1-warqa-blue"
    }, {
        n: "Al Reem 2",
        s: "dubai-arabian-ranches-al-reem-al-reem-2"
    }, {
        n: "La Avenida 1",
        s: "dubai-arabian-ranches-la-avenida-la-avenida-1"
    }, {
        n: "Palmera 2",
        s: "dubai-arabian-ranches-palmera-palmera-2"
    }, {
        n: "Meera",
        s: "dubai-business-bay-al-habtoor-city-meera"
    }, {
        n: "Capital Bay Tower A",
        s: "dubai-business-bay-capital-bay-capital-bay-tower-a"
    }, {
        n: "Bay Square Building 8",
        s: "dubai-business-bay-bay-square-bay-square-building-8"
    }, {
        n: "Bay Square Building 5",
        s: "dubai-business-bay-bay-square-bay-square-building-5"
    }, {
        n: "Marinascape Oceanic",
        s: "dubai-dubai-marina-marinascape-marinascape-oceanic"
    }, {
        n: "Matloob A",
        s: "dubai-al-safa-al-safa-1-matloob-a"
    }, {
        n: "Block A",
        s: "dubai-al-wasl-dar-wasl-block-a"
    }, {
        n: "Building 55",
        s: "dubai-al-satwa-al-hudaiba-building-55"
    }, {
        n: "Miracle Building",
        s: "dubai-al-satwa-al-diyafah-miracle-building"
    }, {
        n: "Al Warqa'a 2 Villas",
        s: "dubai-al-warqa-a-al-warqa-a-2-al-warqa-a-2-villas"
    }, {
        n: "Al Mashroom 3",
        s: "dubai-al-warqa-a-al-warqa-a-1-al-mashroom-3"
    }, {
        n: "Al Dana Building",
        s: "dubai-al-warqa-a-al-warqa-a-1-al-dana-building"
    }, {
        n: "Al Warqaa Residence",
        s: "dubai-al-warqa-a-al-warqa-a-1-al-warqaa-residence"
    }, {
        n: "Dream Tower 1",
        s: "dubai-dubai-marina-dream-towers-dream-tower-1"
    }, {
        n: "Al Safa 1 Villas",
        s: "dubai-al-safa-al-safa-1-al-safa-1-villas"
    }, {
        n: "Mirador La Coleccion 2",
        s: "dubai-arabian-ranches-mirador-la-coleccion-mirador-la-coleccion-2"
    }, {
        n: "Amna",
        s: "dubai-business-bay-al-habtoor-city-amna"
    }, {
        n: "Saheel 2",
        s: "dubai-arabian-ranches-saheel-saheel-2"
    }, {
        n: "Bay Square Building 4",
        s: "dubai-business-bay-bay-square-bay-square-building-4"
    }, {
        n: "Bay Square Building 13",
        s: "dubai-business-bay-bay-square-bay-square-building-13"
    }, {
        n: "Empire Heights 2",
        s: "dubai-business-bay-empire-heights-empire-heights-2"
    }, {
        n: "Tower A",
        s: "dubai-business-bay-damac-towers-by-paramount-tower-a"
    }, {
        n: "J ONE Tower B",
        s: "dubai-business-bay-j-one-j-one-tower-b"
    }, {
        n: "Hydra Tower 2",
        s: "dubai-business-bay-hydra-twin-towers-hydra-tower-2"
    }, {
        n: "Signature Tower Office",
        s: "dubai-business-bay-signature-towers-signature-tower-office"
    }, {
        n: "Sobha Ivory Tower 1",
        s: "dubai-business-bay-sobha-ivory-towers-sobha-ivory-tower-1"
    }, {
        n: "Executive Tower B",
        s: "dubai-business-bay-executive-towers-executive-tower-b"
    }, {
        n: "Executive Tower D (Aspect Tower)",
        s: "dubai-business-bay-executive-towers-executive-tower-d-aspect-tower"
    }, {
        n: "Executive Tower F",
        s: "dubai-business-bay-executive-towers-executive-tower-f"
    }, {
        n: "Majestic Tower",
        s: "dubai-business-bay-al-abraj-street-majestic-tower"
    }, {
        n: "Emirates Park Tower 1",
        s: "dubai-business-bay-emirates-park-emirates-park-tower-1"
    }, {
        n: "Sky Tower 1",
        s: "dubai-business-bay-sky-towers-sky-tower-1"
    }, {
        n: "Al Muhairi Plaza",
        s: "dubai-deira-al-khabisi-al-muhairi-plaza"
    }, {
        n: "Beauport Tower",
        s: "dubai-dubai-marina-marina-promenade-beauport-tower"
    }, {
        n: "Marina Quay East",
        s: "dubai-dubai-marina-marina-quays-marina-quay-east"
    }, {
        n: "C-06",
        s: "dubai-international-city-cbd-central-business-district-c-06"
    }, {
        n: "Mirador La Coleccion 1",
        s: "dubai-arabian-ranches-mirador-la-coleccion-mirador-la-coleccion-1"
    }, {
        n: "Palmera 4",
        s: "dubai-arabian-ranches-palmera-palmera-4"
    }, {
        n: "Saheel 1",
        s: "dubai-arabian-ranches-saheel-saheel-1"
    }, {
        n: "Bay Square Building 7",
        s: "dubai-business-bay-bay-square-bay-square-building-7"
    }, {
        n: "Bay Square Building 11",
        s: "dubai-business-bay-bay-square-bay-square-building-11"
    }, {
        n: "Bay Square Building 12",
        s: "dubai-business-bay-bay-square-bay-square-building-12"
    }, {
        n: "Empire Heights 1",
        s: "dubai-business-bay-empire-heights-empire-heights-1"
    }, {
        n: "Tower D",
        s: "dubai-business-bay-damac-towers-by-paramount-tower-d"
    }, {
        n: "La Residence Tower 1",
        s: "dubai-business-bay-lotus-la-residence-tower-1"
    }, {
        n: "Hydra Tower 1",
        s: "dubai-business-bay-hydra-twin-towers-hydra-tower-1"
    }, {
        n: "Safeer Tower 2",
        s: "dubai-business-bay-safeer-towers-safeer-tower-2"
    }, {
        n: "Westburry Tower 2",
        s: "dubai-business-bay-westburry-square-westburry-tower-2"
    }, {
        n: "Executive Tower J",
        s: "dubai-business-bay-executive-towers-executive-tower-j"
    }, {
        n: "Executive Tower K",
        s: "dubai-business-bay-executive-towers-executive-tower-k"
    }, {
        n: "B-02",
        s: "dubai-international-city-cbd-central-business-district-b-02"
    }, {
        n: "Ubora Tower 1",
        s: "dubai-business-bay-ubora-towers-ubora-tower-1"
    }, {
        n: "Tower B",
        s: "dubai-business-bay-damac-towers-by-paramount-tower-b"
    }, {
        n: "Tasameem Tower 1",
        s: "dubai-business-bay-tasameem-towers-tasameem-tower-1"
    }, {
        n: "Starhill Towers & Gallery 2",
        s: "dubai-business-bay-starhill-towers-gallery-starhill-towers-gallery-2"
    }, {
        n: "Signature Tower Hotel",
        s: "dubai-business-bay-signature-towers-signature-tower-hotel"
    }, {
        n: "Niki Lauda Tower A",
        s: "dubai-business-bay-niki-lauda-twin-towers-niki-lauda-tower-a"
    }, {
        n: "Executive Tower E",
        s: "dubai-business-bay-executive-towers-executive-tower-e"
    }, {
        n: "Executive Tower A",
        s: "dubai-business-bay-executive-towers-executive-tower-a"
    }, {
        n: "Executive Tower M",
        s: "dubai-business-bay-executive-towers-executive-tower-m"
    }, {
        n: "Executive Bay A",
        s: "dubai-business-bay-executive-bay-executive-bay-a"
    }, {
        n: "Bristol Tower 1",
        s: "dubai-business-bay-bristol-towers-bristol-tower-1"
    }, {
        n: "Santeview 1",
        s: "dubai-culture-village-santeview-santeview-1"
    }, {
        n: "Murjanet Al Khaleej",
        s: "dubai-deira-abu-baker-al-siddique-road-murjanet-al-khaleej"
    }, {
        n: "Baniyas Centre",
        s: "dubai-deira-al-riqqa-baniyas-centre"
    }, {
        n: "Abu Hail Road",
        s: "dubai-deira-abu-hail-abu-hail-road"
    }, {
        n: "The Square",
        s: "dubai-deira-al-mamzar-the-square"
    }, {
        n: "Wasl District",
        s: "dubai-deira-naif-wasl-district"
    }, {
        n: "Naif Road",
        s: "dubai-deira-naif-naif-road"
    }, {
        n: "A1 Building",
        s: "dubai-deira-hor-al-anz-a1-building"
    }, {
        n: "Zubaidi Building",
        s: "dubai-deira-hor-al-anz-zubaidi-building"
    }, {
        n: "Al Handabi Building",
        s: "dubai-deira-hor-al-anz-al-handabi-building"
    }, {
        n: "Royal House",
        s: "dubai-deira-hor-al-anz-royal-house"
    }, {
        n: "Niki Lauda Tower B",
        s: "dubai-business-bay-niki-lauda-twin-towers-niki-lauda-tower-b"
    }, {
        n: "Executive Tower G",
        s: "dubai-business-bay-executive-towers-executive-tower-g"
    }, {
        n: "Executive Tower I",
        s: "dubai-business-bay-executive-towers-executive-tower-i"
    }, {
        n: "Executive Bay B",
        s: "dubai-business-bay-executive-bay-executive-bay-b"
    }, {
        n: "The Sterling East",
        s: "dubai-business-bay-the-sterling-the-sterling-east"
    }, {
        n: "Al Bakhit Centre",
        s: "dubai-deira-al-khabisi-al-bakhit-centre"
    }, {
        n: "Hamriya Oasis",
        s: "dubai-deira-abu-baker-al-siddique-road-hamriya-oasis"
    }, {
        n: "Al Khaleej Hotel Building",
        s: "dubai-deira-al-riqqa-al-khaleej-hotel-building"
    }, {
        n: "Office Building",
        s: "dubai-deira-al-riqqa-office-building"
    }, {
        n: "Al Fajer Complex",
        s: "dubai-deira-al-mamzar-al-fajer-complex"
    }, {
        n: "Nasr Square",
        s: "dubai-deira-naif-nasr-square"
    }, {
        n: "Al Musallah Road",
        s: "dubai-deira-naif-al-musallah-road"
    }, {
        n: "Hor Al Anz Street",
        s: "dubai-deira-hor-al-anz-hor-al-anz-street"
    }, {
        n: "Al Tahadi",
        s: "dubai-deira-hor-al-anz-al-tahadi"
    }, {
        n: "Silver Building",
        s: "dubai-deira-hor-al-anz-silver-building"
    }, {
        n: "Al Canna Building",
        s: "dubai-deira-hor-al-anz-al-canna-building"
    }, {
        n: "Al Meraikhi Tower",
        s: "dubai-deira-corniche-deira-al-meraikhi-tower"
    }, {
        n: "Ramada Deira Hotel",
        s: "dubai-deira-salah-al-din-street-ramada-deira-hotel"
    }, {
        n: "Abdul Rahim Mohd Al Zarooni Building",
        s: "dubai-deira-al-ras-abdul-rahim-mohd-al-zarooni-building"
    }, {
        n: "Al Ras Street",
        s: "dubai-deira-al-ras-al-ras-street"
    }, {
        n: "Emirates Park Tower 2",
        s: "dubai-business-bay-emirates-park-emirates-park-tower-2"
    }, {
        n: "The Sterling West",
        s: "dubai-business-bay-the-sterling-the-sterling-west"
    }, {
        n: "Afra Building",
        s: "dubai-deira-al-khabisi-afra-building"
    }, {
        n: "Century 21",
        s: "dubai-deira-abu-baker-al-siddique-road-century-21"
    }, {
        n: "JRA Building",
        s: "dubai-deira-al-riqqa-jra-building"
    }, {
        n: "Al Masaood Building",
        s: "dubai-deira-riggat-al-buteen-al-masaood-building"
    }, {
        n: "Al Mamzer Lagoon",
        s: "dubai-deira-al-mamzar-al-mamzer-lagoon"
    }, {
        n: "Al Nakheel Building",
        s: "dubai-deira-naif-al-nakheel-building"
    }, {
        n: "Commercial and Residential Building",
        s: "dubai-deira-naif-commercial-and-residential-building"
    }, {
        n: "Hor Al Anz East",
        s: "dubai-deira-hor-al-anz-hor-al-anz-east"
    }, {
        n: "Al Mamzar Centre",
        s: "dubai-deira-hor-al-anz-al-mamzar-centre"
    }, {
        n: "Al Rasheed Road",
        s: "dubai-deira-hor-al-anz-al-rasheed-road"
    }, {
        n: "Dar Al Aman Building",
        s: "dubai-deira-hor-al-anz-dar-al-aman-building"
    }, {
        n: "Al Gurg Tower",
        s: "dubai-deira-corniche-deira-al-gurg-tower"
    }, {
        n: "UNB",
        s: "dubai-deira-al-sabkha-unb"
    }, {
        n: "New Deira Building",
        s: "dubai-deira-al-ras-new-deira-building"
    }, {
        n: "Al Khaleej Road",
        s: "dubai-deira-al-ras-al-khaleej-road"
    }, {
        n: "Business Point",
        s: "dubai-deira-port-saeed-business-point"
    }, {
        n: "Al Dana Building",
        s: "dubai-deira-al-riqqa-al-dana-building"
    }, {
        n: "Al Ghurair Apartments",
        s: "dubai-deira-al-riqqa-al-ghurair-apartments"
    }, {
        n: "Dream Tower",
        s: "dubai-deira-al-mamzar-dream-tower"
    }, {
        n: "Naif Oasis",
        s: "dubai-deira-naif-naif-oasis"
    }, {
        n: "Al Maktoum Hospital RD Building",
        s: "dubai-deira-naif-al-maktoum-hospital-rd-building"
    }, {
        n: "DM Building",
        s: "dubai-deira-hor-al-anz-dm-building"
    }, {
        n: "AbdulRahim Mohammed AlZarooni Building",
        s: "dubai-deira-hor-al-anz-abdul-rahim-mohammed-al-zarooni-building"
    }, {
        n: "Grand Avenue",
        s: "dubai-deira-hor-al-anz-grand-avenue"
    }, {
        n: "Hamda 3 Building",
        s: "dubai-deira-hor-al-anz-hamda-3-building"
    }, {
        n: "Creek Tower",
        s: "dubai-deira-corniche-deira-creek-tower"
    }, {
        n: "Radisson Blu Hotel",
        s: "dubai-deira-corniche-deira-radisson-blu-hotel"
    }, {
        n: "Al Sabkha Building",
        s: "dubai-deira-al-sabkha-al-sabkha-building"
    }, {
        n: "Gold Souk Area",
        s: "dubai-deira-al-ras-gold-souk-area"
    }, {
        n: "Frij Al Murar",
        s: "dubai-deira-al-ras-frij-al-murar"
    }, {
        n: "Souk Deira Street",
        s: "dubai-deira-al-ras-souk-deira-street"
    }, {
        n: "Habbai Building",
        s: "dubai-deira-port-saeed-habbai-building"
    }, {
        n: "Business Village",
        s: "dubai-deira-port-saeed-business-village"
    }, {
        n: "Bin Qatame Building",
        s: "dubai-deira-port-saeed-bin-qatame-building"
    }, {
        n: "Best Western Plus Pearl Creek Hotel",
        s: "dubai-deira-baniyas-road-best-western-plus-pearl-creek-hotel"
    }, {
        n: "Twin Tower",
        s: "dubai-deira-baniyas-road-twin-tower"
    }, {
        n: "Clock Plaza Building",
        s: "dubai-deira-al-muraqqabat-clock-plaza-building"
    }, {
        n: "Abdul Rahim Mohd Al Zarooni Building",
        s: "dubai-deira-al-muraqqabat-abdul-rahim-mohd-al-zarooni-building"
    }, {
        n: "E-05",
        s: "dubai-international-city-cbd-central-business-district-e-05"
    }, {
        n: "Union Square Building",
        s: "dubai-deira-al-riqqa-union-square-building"
    }, {
        n: "DAMAS Tower",
        s: "dubai-deira-riggat-al-buteen-damas-tower"
    }, {
        n: "Al Mamzar Villas",
        s: "dubai-deira-al-mamzar-al-mamzar-villas"
    }, {
        n: "KFI",
        s: "dubai-deira-naif-kfi"
    }, {
        n: "Al Burj Street",
        s: "dubai-deira-naif-al-burj-street"
    }, {
        n: "Al Shaab",
        s: "dubai-deira-hor-al-anz-al-shaab"
    }, {
        n: "MRM Building",
        s: "dubai-deira-hor-al-anz-mrm-building"
    }, {
        n: "Studio M Arabian Plaza",
        s: "dubai-deira-hor-al-anz-studio-m-arabian-plaza"
    }, {
        n: "Al Shaali Building",
        s: "dubai-deira-hor-al-anz-al-shaali-building"
    }, {
        n: "Al Mashriq Building",
        s: "dubai-deira-hor-al-anz-al-mashriq-building"
    }, {
        n: "Al Meraikhi Tower 2",
        s: "dubai-deira-corniche-deira-al-meraikhi-tower-2"
    }, {
        n: "Abraj Center",
        s: "dubai-deira-al-sabkha-abraj-center"
    }, {
        n: "Ayal Nasir",
        s: "dubai-deira-al-ras-ayal-nasir"
    }, {
        n: "Al Ras Centre Building",
        s: "dubai-deira-al-ras-al-ras-centre-building"
    }, {
        n: "Sikkat Al Khail Road",
        s: "dubai-deira-al-ras-sikkat-al-khail-road"
    }, {
        n: "Centurion Star Tower",
        s: "dubai-deira-port-saeed-centurion-star-tower"
    }, {
        n: "GSA",
        s: "dubai-deira-al-sabkha-gsa"
    }, {
        n: "Al Khor Street",
        s: "dubai-deira-al-ras-al-khor-street"
    }, {
        n: "Al Buteen",
        s: "dubai-deira-al-ras-al-buteen"
    }, {
        n: "Creekside Residence",
        s: "dubai-deira-port-saeed-creekside-residence"
    }, {
        n: "Al Rostamani Building",
        s: "dubai-deira-port-saeed-al-rostamani-building"
    }, {
        n: "Waterview Executive Apartments",
        s: "dubai-deira-port-saeed-waterview-executive-apartments"
    }, {
        n: "Sheikha Mariam Building",
        s: "dubai-deira-baniyas-road-sheikha-mariam-building"
    }, {
        n: "Warba Center",
        s: "dubai-deira-al-muraqqabat-warba-center"
    }, {
        n: "Juma Al Majid Building",
        s: "dubai-deira-al-muraqqabat-juma-al-majid-building"
    }, {
        n: "Mia Casa Hotel Apartments",
        s: "dubai-deira-al-muraqqabat-mia-casa-hotel-apartments"
    }, {
        n: "Emaar Tower B",
        s: "dubai-deira-emaar-towers-emaar-tower-b"
    }, {
        n: "City Avenue Building",
        s: "dubai-deira-maktoum-road-city-avenue-building"
    }, {
        n: "YHM",
        s: "dubai-deira-al-murar-yhm"
    }, {
        n: "Park Tower A",
        s: "dubai-difc-park-towers-park-tower-a"
    }, {
        n: "Mamoora 18 Building",
        s: "dubai-deira-al-muteena-mamoora-18-building"
    }, {
        n: "The Gate Precinct 3",
        s: "dubai-difc-the-gate-precinct-the-gate-precinct-3"
    }, {
        n: "Gate Village 05",
        s: "dubai-difc-gate-village-gate-village-05"
    }, {
        n: "Gate Village 03",
        s: "dubai-difc-gate-village-gate-village-03"
    }, {
        n: "Gate Village 10",
        s: "dubai-difc-gate-village-gate-village-10"
    }, {
        n: "Central Park Office Tower",
        s: "dubai-difc-central-park-tower-central-park-office-tower"
    }, {
        n: "Building 230 to Building 263",
        s: "dubai-discovery-gardens-cactus-building-230-to-building-263"
    }, {
        n: "The Signature",
        s: "dubai-downtown-dubai-burj-khalifa-area-the-signature"
    }, {
        n: "Building 1 to Building 37",
        s: "dubai-discovery-gardens-zen-cluster-building-1-to-building-37"
    }, {
        n: "The Residences 7",
        s: "dubai-downtown-dubai-the-residences-the-residences-7"
    }, {
        n: "Sapphire Tower",
        s: "dubai-deira-port-saeed-sapphire-tower"
    }, {
        n: "Zeenah Building",
        s: "dubai-deira-port-saeed-zeenah-building"
    }, {
        n: "Al Masaood Tower",
        s: "dubai-deira-port-saeed-al-masaood-tower"
    }, {
        n: "Al Bandar Rotana - Creek",
        s: "dubai-deira-baniyas-road-al-bandar-rotana-creek"
    }, {
        n: "Al Muraqqabat 596",
        s: "dubai-deira-al-muraqqabat-al-muraqqabat-596"
    }, {
        n: "Al Hathboor Building",
        s: "dubai-deira-al-muraqqabat-al-hathboor-building"
    }, {
        n: "BCO",
        s: "dubai-deira-al-nakhal-road-bco"
    }, {
        n: "Falcon Tower",
        s: "dubai-deira-maktoum-road-falcon-tower"
    }, {
        n: "South Tower",
        s: "dubai-difc-emirates-financial-towers-south-tower"
    }, {
        n: "Burj Nahar Building",
        s: "dubai-deira-al-muteena-burj-nahar-building"
    }, {
        n: "JAM Building",
        s: "dubai-deira-al-muteena-jam-building"
    }, {
        n: "The Gate Precinct 6",
        s: "dubai-difc-the-gate-precinct-the-gate-precinct-6"
    }, {
        n: "Gate Village 02",
        s: "dubai-difc-gate-village-gate-village-02"
    }, {
        n: "Gate Village 07",
        s: "dubai-difc-gate-village-gate-village-07"
    }, {
        n: "DIFC Tower 2",
        s: "dubai-difc-difc-tower-difc-tower-2"
    }, {
        n: "Building 203 to Building 229",
        s: "dubai-discovery-gardens-mesoamerican-building-203-to-building-229"
    }, {
        n: "Building 38 to Building 107",
        s: "dubai-discovery-gardens-mediterranean-cluster-building-38-to-building-107"
    }, {
        n: "AMA Building",
        s: "dubai-deira-port-saeed-ama-building"
    }, {
        n: "Golden Building",
        s: "dubai-deira-baniyas-road-golden-building"
    }, {
        n: "Al Anfal Building",
        s: "dubai-deira-al-muraqqabat-al-anfal-building"
    }, {
        n: "Al Muraqqabat 133",
        s: "dubai-deira-al-muraqqabat-al-muraqqabat-133"
    }, {
        n: "Al Ashrafiya Building",
        s: "dubai-deira-al-muraqqabat-al-ashrafiya-building"
    }, {
        n: "Prestige Building",
        s: "dubai-deira-al-nakhal-road-prestige-building"
    }, {
        n: "Makataeb",
        s: "dubai-deira-port-saeed-makataeb"
    }, {
        n: "Dubai National Insurance Building",
        s: "dubai-deira-baniyas-road-dubai-national-insurance-building"
    }, {
        n: "Al Jazeira Road",
        s: "dubai-deira-al-muraqqabat-al-jazeira-road"
    }, {
        n: "wasl Trio",
        s: "dubai-deira-al-muraqqabat-wasl-trio"
    }, {
        n: "New Gargash Building",
        s: "dubai-deira-al-muraqqabat-new-gargash-building"
    }, {
        n: "Green Tower",
        s: "dubai-deira-maktoum-road-green-tower"
    }, {
        n: "Saraya House",
        s: "dubai-deira-al-murar-saraya-house"
    }, {
        n: "Park Towers Podium",
        s: "dubai-difc-park-towers-park-towers-podium"
    }, {
        n: "BAS Al Muteena Building",
        s: "dubai-deira-al-muteena-bas-al-muteena-building"
    }, {
        n: "Currency House Residences",
        s: "dubai-difc-currency-house-currency-house-residences"
    }, {
        n: "The Gate Precinct 1",
        s: "dubai-difc-the-gate-precinct-the-gate-precinct-1"
    }, {
        n: "Gate Village 09",
        s: "dubai-difc-gate-village-gate-village-09"
    }, {
        n: "Gate Village 04",
        s: "dubai-difc-gate-village-gate-village-04"
    }, {
        n: "Central Park Residential Tower",
        s: "dubai-difc-central-park-tower-central-park-residential-tower"
    }, {
        n: "Terra Del Sol 1",
        s: "dubai-discovery-gardens-terra-del-sol-terra-del-sol-1"
    }, {
        n: "Armani Residence",
        s: "dubai-downtown-dubai-burj-khalifa-area-armani-residence"
    }, {
        n: "The Residences 3",
        s: "dubai-downtown-dubai-the-residences-the-residences-3"
    }, {
        n: "The Residences 9",
        s: "dubai-downtown-dubai-the-residences-the-residences-9"
    }, {
        n: "48 Burj gate",
        s: "dubai-downtown-dubai-burj-place-48-burj-gate"
    }, {
        n: "Burj Park 1",
        s: "dubai-downtown-dubai-burj-park-burj-park-1"
    }, {
        n: "Building 5",
        s: "dubai-downtown-dubai-emaar-square-building-5"
    }, {
        n: "Alliance Business Center",
        s: "dubai-deira-maktoum-road-alliance-business-center"
    }, {
        n: "Eid Mohamed Rashid Building",
        s: "dubai-deira-al-murar-eid-mohamed-rashid-building"
    }, {
        n: "Park Tower B",
        s: "dubai-difc-park-towers-park-tower-b"
    }, {
        n: "Al Muteena 226",
        s: "dubai-deira-al-muteena-al-muteena-226"
    }, {
        n: "The Gate Precinct 4",
        s: "dubai-difc-the-gate-precinct-the-gate-precinct-4"
    }, {
        n: "Gate Village 08",
        s: "dubai-difc-gate-village-gate-village-08"
    }, {
        n: "Gate Village 11",
        s: "dubai-difc-gate-village-gate-village-11"
    }, {
        n: "Central Park Retail",
        s: "dubai-difc-central-park-tower-central-park-retail"
    }, {
        n: "Building 148 to Building 202",
        s: "dubai-discovery-gardens-mogul-cluster-building-148-to-building-202"
    }, {
        n: "Opera Grand",
        s: "dubai-downtown-dubai-burj-khalifa-area-opera-grand"
    }, {
        n: "Marquise Square",
        s: "dubai-downtown-dubai-burj-khalifa-area-marquise-square"
    }, {
        n: "The Residences 4",
        s: "dubai-downtown-dubai-the-residences-the-residences-4"
    }, {
        n: "The Residences 6",
        s: "dubai-downtown-dubai-the-residences-the-residences-6"
    }, {
        n: "Claren Tower 1",
        s: "dubai-downtown-dubai-claren-towers-claren-tower-1"
    }, {
        n: "Building 2",
        s: "dubai-downtown-dubai-emaar-square-building-2"
    }, {
        n: "The Address Sky View Tower 2",
        s: "dubai-downtown-dubai-the-address-sky-view-towers-the-address-sky-view-tower-2"
    }, {
        n: "South Ridge 6",
        s: "dubai-downtown-dubai-south-ridge-south-ridge-6"
    }, {
        n: "South Ridge 3",
        s: "dubai-downtown-dubai-south-ridge-south-ridge-3"
    }, {
        n: "Boulevard Plaza 1",
        s: "dubai-downtown-dubai-boulevard-plaza-towers-boulevard-plaza-1"
    }, {
        n: "Grande",
        s: "dubai-downtown-dubai-opera-district-grande"
    }, {
        n: "Bellevue Tower 1",
        s: "dubai-downtown-dubai-bellevue-towers-bellevue-tower-1"
    }, {
        n: "C-04",
        s: "dubai-international-city-cbd-central-business-district-c-04"
    }, {
        n: "North Tower",
        s: "dubai-difc-emirates-financial-towers-north-tower"
    }, {
        n: "Al Muteena Building",
        s: "dubai-deira-al-muteena-al-muteena-building"
    }, {
        n: "Currency House Offices",
        s: "dubai-difc-currency-house-currency-house-offices"
    }, {
        n: "The Gate Precinct 2",
        s: "dubai-difc-the-gate-precinct-the-gate-precinct-2"
    }, {
        n: "Gate Village 01",
        s: "dubai-difc-gate-village-gate-village-01"
    }, {
        n: "Gate Village 06",
        s: "dubai-difc-gate-village-gate-village-06"
    }, {
        n: "DIFC Tower 1",
        s: "dubai-difc-difc-tower-difc-tower-1"
    }, {
        n: "Terra Del Sol 2",
        s: "dubai-discovery-gardens-terra-del-sol-terra-del-sol-2"
    }, {
        n: "Armani Hotel Dubai",
        s: "dubai-downtown-dubai-burj-khalifa-area-armani-hotel-dubai"
    }, {
        n: "The Residences 8",
        s: "dubai-downtown-dubai-the-residences-the-residences-8"
    }, {
        n: "Burj Place Tower 1",
        s: "dubai-downtown-dubai-burj-place-burj-place-tower-1"
    }, {
        n: "Burj Park 3",
        s: "dubai-downtown-dubai-burj-park-burj-park-3"
    }, {
        n: "Burj Park 4",
        s: "dubai-downtown-dubai-burj-park-burj-park-4"
    }, {
        n: "Burj Park 5",
        s: "dubai-downtown-dubai-burj-park-burj-park-5"
    }, {
        n: "Building 6",
        s: "dubai-downtown-dubai-emaar-square-building-6"
    }, {
        n: "Building 4",
        s: "dubai-downtown-dubai-emaar-square-building-4"
    }, {
        n: "Standpoint Tower 1",
        s: "dubai-downtown-dubai-standpoint-towers-standpoint-tower-1"
    }, {
        n: "The Address Sky View Tower 1",
        s: "dubai-downtown-dubai-the-address-sky-view-towers-the-address-sky-view-tower-1"
    }, {
        n: "South Ridge 2",
        s: "dubai-downtown-dubai-south-ridge-south-ridge-2"
    }, {
        n: "South Ridge 4",
        s: "dubai-downtown-dubai-south-ridge-south-ridge-4"
    }, {
        n: "The Grand Boulevard Tower 1",
        s: "dubai-downtown-dubai-the-grand-boulevard-the-grand-boulevard-tower-1"
    }, {
        n: "Act One",
        s: "dubai-downtown-dubai-opera-district-act-one"
    }, {
        n: "Ariana Tower",
        s: "dubai-dubai-marina-marina-view-ariana-tower"
    }, {
        n: "The Residence Villas",
        s: "dubai-downtown-dubai-the-residences-the-residence-villas"
    }, {
        n: "Burj Place Tower 2",
        s: "dubai-downtown-dubai-burj-place-burj-place-tower-2"
    }, {
        n: "Claren Tower 2",
        s: "dubai-downtown-dubai-claren-towers-claren-tower-2"
    }, {
        n: "Burj Park 2",
        s: "dubai-downtown-dubai-burj-park-burj-park-2"
    }, {
        n: "Burj Views A",
        s: "dubai-downtown-dubai-burj-views-burj-views-a"
    }, {
        n: "Standpoint Tower 2",
        s: "dubai-downtown-dubai-standpoint-towers-standpoint-tower-2"
    }, {
        n: "Boulevard Crescent 1",
        s: "dubai-downtown-dubai-blvd-crescent-boulevard-crescent-1"
    }, {
        n: "Forte 2",
        s: "dubai-downtown-dubai-forte-forte-2"
    }, {
        n: "Boulevard Plaza 2",
        s: "dubai-downtown-dubai-boulevard-plaza-towers-boulevard-plaza-2"
    }, {
        n: "Act Two",
        s: "dubai-downtown-dubai-opera-district-act-two"
    }, {
        n: "Bellevue Tower 2",
        s: "dubai-downtown-dubai-bellevue-towers-bellevue-tower-2"
    }, {
        n: "Burj Vista 1",
        s: "dubai-downtown-dubai-burj-vista-burj-vista-1"
    }, {
        n: "The Address Residence Fountain Views 1",
        s: "dubai-downtown-dubai-the-address-residence-fountain-views-the-address-residence-fountain-views-1"
    }, {
        n: "The Address Residence Fountain Views Sky Collection 2",
        s: "dubai-downtown-dubai-the-address-residence-fountain-views-the-address-residence-fountain-views-sky-collection-2"
    }, {
        n: "8 Boulevard Walk",
        s: "dubai-downtown-dubai-mohammad-bin-rashid-boulevard-8-boulevard-walk"
    }, {
        n: "Al Sondos Apartments Block D",
        s: "dubai-dubai-investment-park-al-sondos-apartments-al-sondos-apartments-block-d"
    }, {
        n: "Al Narah Apartments B",
        s: "dubai-dubai-investment-park-al-narah-apartments-al-narah-apartments-b"
    }, {
        n: "Suburbia Tower 2",
        s: "dubai-downtown-jebel-ali-suburbia-suburbia-tower-2"
    }, {
        n: "KPM Tower 1",
        s: "dubai-downtown-jebel-ali-kpm-twin-towers-kpm-tower-1"
    }, {
        n: "Image Residence Tower A",
        s: "dubai-downtown-jebel-ali-image-residences-image-residence-tower-a"
    }, {
        n: "The Galleries 3",
        s: "dubai-downtown-jebel-ali-the-galleries-the-galleries-3"
    }, {
        n: "Skycourts Tower A",
        s: "dubai-dubai-land-skycourts-towers-skycourts-tower-a"
    }, {
        n: "Vida Residences Creek Beach",
        s: "dubai-dubai-creek-harbour-the-lagoons-creek-beach-vida-residences-creek-beach"
    }, {
        n: "Claren Podium",
        s: "dubai-downtown-dubai-claren-towers-claren-podium"
    }, {
        n: "Building 1",
        s: "dubai-downtown-dubai-emaar-square-building-1"
    }, {
        n: "Burj Views B",
        s: "dubai-downtown-dubai-burj-views-burj-views-b"
    }, {
        n: "The Address Sky View Sky Collection Tower 1",
        s: "dubai-downtown-dubai-the-address-sky-view-towers-the-address-sky-view-sky-collection-tower-1"
    }, {
        n: "Boulevard Crescent 2",
        s: "dubai-downtown-dubai-blvd-crescent-boulevard-crescent-2"
    }, {
        n: "South Ridge 1",
        s: "dubai-downtown-dubai-south-ridge-south-ridge-1"
    }, {
        n: "Forte 1",
        s: "dubai-downtown-dubai-forte-forte-1"
    }, {
        n: "Act One | Act Two towers",
        s: "dubai-downtown-dubai-opera-district-act-one-act-two-towers"
    }, {
        n: "Boulevard Central Tower 2",
        s: "dubai-downtown-dubai-boulevard-central-towers-boulevard-central-tower-2"
    }, {
        n: "The Lofts East",
        s: "dubai-downtown-dubai-the-lofts-the-lofts-east"
    }, {
        n: "Burj Vista 2",
        s: "dubai-downtown-dubai-burj-vista-burj-vista-2"
    }, {
        n: "29 Burj Boulevard Tower 1",
        s: "dubai-downtown-dubai-29-burj-boulevard-29-burj-boulevard-tower-1"
    }, {
        n: "The Address Residence Fountain Views 3",
        s: "dubai-downtown-dubai-the-address-residence-fountain-views-the-address-residence-fountain-views-3"
    }, {
        n: "118 Downtown",
        s: "dubai-downtown-dubai-mohammad-bin-rashid-boulevard-118-downtown"
    }, {
        n: "Four Seasons Hotel",
        s: "dubai-dubai-festival-city-four-seasons-four-seasons-hotel"
    }, {
        n: "Al Sondos Apartments Block E",
        s: "dubai-dubai-investment-park-al-sondos-apartments-al-sondos-apartments-block-e"
    }, {
        n: "Suburbia Tower 1",
        s: "dubai-downtown-jebel-ali-suburbia-suburbia-tower-1"
    }, {
        n: "Capella Tower",
        s: "dubai-downtown-jebel-ali-celestial-heights-capella-tower"
    }, {
        n: "Parkside Tower B",
        s: "dubai-downtown-jebel-ali-parkside-towers-parkside-tower-b"
    }, {
        n: "Deyaar Park Tower A",
        s: "dubai-downtown-jebel-ali-deyaar-park-deyaar-park-tower-a"
    }, {
        n: "The Galleries 2",
        s: "dubai-downtown-jebel-ali-the-galleries-the-galleries-2"
    }, {
        n: "Skycourts Tower F",
        s: "dubai-dubai-land-skycourts-towers-skycourts-tower-f"
    }, {
        n: "Shams 3",
        s: "dubai-jumeirah-beach-residence-shams-shams-3"
    }, {
        n: "Burj Views C",
        s: "dubai-downtown-dubai-burj-views-burj-views-c"
    }, {
        n: "The Address Sky View Sky Collection Tower 2",
        s: "dubai-downtown-dubai-the-address-sky-view-towers-the-address-sky-view-sky-collection-tower-2"
    }, {
        n: "South Ridge 5",
        s: "dubai-downtown-dubai-south-ridge-south-ridge-5"
    }, {
        n: "The Grand Boulevard Tower 2",
        s: "dubai-downtown-dubai-the-grand-boulevard-the-grand-boulevard-tower-2"
    }, {
        n: "IL Primo",
        s: "dubai-downtown-dubai-opera-district-il-primo"
    }, {
        n: "Boulevard Central Podium",
        s: "dubai-downtown-dubai-boulevard-central-towers-boulevard-central-podium"
    }, {
        n: "The Lofts Podium",
        s: "dubai-downtown-dubai-the-lofts-the-lofts-podium"
    }, {
        n: "BLVD Heights Podium",
        s: "dubai-downtown-dubai-blvd-heights-blvd-heights-podium"
    }, {
        n: "29 Burj Boulevard Tower 2",
        s: "dubai-downtown-dubai-29-burj-boulevard-29-burj-boulevard-tower-2"
    }, {
        n: "The Address Residence Fountain Views Sky Collection 1",
        s: "dubai-downtown-dubai-the-address-residence-fountain-views-the-address-residence-fountain-views-sky-collection-1"
    }, {
        n: "Ramada Downtown Hotel",
        s: "dubai-downtown-dubai-mohammad-bin-rashid-boulevard-ramada-downtown-hotel"
    }, {
        n: "Al Sondos Apartments Block A",
        s: "dubai-dubai-investment-park-al-sondos-apartments-al-sondos-apartments-block-a"
    }, {
        n: "Ewan Residence 1",
        s: "dubai-dubai-investment-park-ewan-residences-ewan-residence-1"
    }, {
        n: "Suburbia Townhouses",
        s: "dubai-downtown-jebel-ali-suburbia-suburbia-townhouses"
    }, {
        n: "Orion Tower",
        s: "dubai-downtown-jebel-ali-celestial-heights-orion-tower"
    }, {
        n: "Sienna Square Residence Tower",
        s: "dubai-downtown-jebel-ali-sienna-square-sienna-square-residence-tower"
    }, {
        n: "Deyaar Park Tower B",
        s: "dubai-downtown-jebel-ali-deyaar-park-deyaar-park-tower-b"
    }, {
        n: "Creekside 18 B",
        s: "dubai-dubai-creek-harbour-the-lagoons-creekside-18-creekside-18-b"
    }, {
        n: "Skycourts Tower D",
        s: "dubai-dubai-land-skycourts-towers-skycourts-tower-d"
    }, {
        n: "Bayshore",
        s: "dubai-dubai-creek-harbour-the-lagoons-creek-beach-bayshore"
    }, {
        n: "Sunset at Creek Beach",
        s: "dubai-dubai-creek-harbour-the-lagoons-creek-beach-sunset-at-creek-beach"
    }, {
        n: "Q-Line",
        s: "dubai-dubai-land-liwan-q-line"
    }, {
        n: "La Rosa",
        s: "dubai-dubai-land-villanova-la-rosa"
    }, {
        n: "The Lofts Central",
        s: "dubai-downtown-dubai-the-lofts-the-lofts-central"
    }, {
        n: "29 Burj Boulevard Podium",
        s: "dubai-downtown-dubai-29-burj-boulevard-29-burj-boulevard-podium"
    }, {
        n: "The Address Residence Fountain Views 2",
        s: "dubai-downtown-dubai-the-address-residence-fountain-views-the-address-residence-fountain-views-2"
    }, {
        n: "18 Burj Boulevard",
        s: "dubai-downtown-dubai-mohammad-bin-rashid-boulevard-18-burj-boulevard"
    }, {
        n: "Al Sondos Apartments Block C",
        s: "dubai-dubai-investment-park-al-sondos-apartments-al-sondos-apartments-block-c"
    }, {
        n: "Ewan Residence 2",
        s: "dubai-dubai-investment-park-ewan-residences-ewan-residence-2"
    }, {
        n: "Suburbia Podium",
        s: "dubai-downtown-jebel-ali-suburbia-suburbia-podium"
    }, {
        n: "KPM Tower 2",
        s: "dubai-downtown-jebel-ali-kpm-twin-towers-kpm-tower-2"
    }, {
        n: "Sienna Square Business Tower",
        s: "dubai-downtown-jebel-ali-sienna-square-sienna-square-business-tower"
    }, {
        n: "The Galleries 1",
        s: "dubai-downtown-jebel-ali-the-galleries-the-galleries-1"
    }, {
        n: "Creekside 18 A",
        s: "dubai-dubai-creek-harbour-the-lagoons-creekside-18-creekside-18-a"
    }, {
        n: "Skycourts Tower E",
        s: "dubai-dubai-land-skycourts-towers-skycourts-tower-e"
    }, {
        n: "Summer",
        s: "dubai-dubai-creek-harbour-the-lagoons-creek-beach-summer"
    }, {
        n: "Breeze",
        s: "dubai-dubai-creek-harbour-the-lagoons-creek-beach-breeze"
    }, {
        n: "Q Zone",
        s: "dubai-dubai-land-liwan-q-zone"
    }, {
        n: "Farah Tower 4",
        s: "dubai-dubai-land-queue-point-farah-tower-4"
    }, {
        n: "Mazaya 17",
        s: "dubai-dubai-land-queue-point-mazaya-17"
    }, {
        n: "Mazaya 10B",
        s: "dubai-dubai-land-queue-point-mazaya-10b"
    }, {
        n: "Farah Tower 2",
        s: "dubai-dubai-land-queue-point-farah-tower-2"
    }, {
        n: "Ghanima",
        s: "dubai-dubai-land-queue-point-ghanima"
    }, {
        n: "Mazaya 19",
        s: "dubai-dubai-land-queue-point-mazaya-19"
    }, {
        n: "Mazaya 3",
        s: "dubai-dubai-land-queue-point-mazaya-3"
    }, {
        n: "Mazaya 30",
        s: "dubai-dubai-land-queue-point-mazaya-30"
    }, {
        n: "Shams",
        s: "dubai-dubai-land-queue-point-shams"
    }, {
        n: "Business Central Tower B",
        s: "dubai-dubai-media-city-business-central-business-central-tower-b"
    }, {
        n: "Al Ramth 44",
        s: "dubai-remraam-al-ramth-al-ramth-44"
    }, {
        n: "The Address Residence Fountain Views Sky Collection 3",
        s: "dubai-downtown-dubai-the-address-residence-fountain-views-the-address-residence-fountain-views-sky-collection-3"
    }, {
        n: "Four Seasons Residence",
        s: "dubai-dubai-festival-city-four-seasons-four-seasons-residence"
    }, {
        n: "Al Sondos Apartments Block B",
        s: "dubai-dubai-investment-park-al-sondos-apartments-al-sondos-apartments-block-b"
    }, {
        n: "Al Narah Apartments A",
        s: "dubai-dubai-investment-park-al-narah-apartments-al-narah-apartments-a"
    }, {
        n: "Polaris Tower",
        s: "dubai-downtown-jebel-ali-celestial-heights-polaris-tower"
    }, {
        n: "Parkside Tower A",
        s: "dubai-downtown-jebel-ali-parkside-towers-parkside-tower-a"
    }, {
        n: "Image Residence Tower B",
        s: "dubai-downtown-jebel-ali-image-residences-image-residence-tower-b"
    }, {
        n: "The Galleries 4",
        s: "dubai-downtown-jebel-ali-the-galleries-the-galleries-4"
    }, {
        n: "Skycourts Tower B",
        s: "dubai-dubai-land-skycourts-towers-skycourts-tower-b"
    }, {
        n: "Sunrise Building",
        s: "dubai-dubai-land-liwan-sunrise-building"
    }, {
        n: "Mazaya 20",
        s: "dubai-dubai-land-queue-point-mazaya-20"
    }, {
        n: "Mazaya 11",
        s: "dubai-dubai-land-queue-point-mazaya-11"
    }, {
        n: "Mazaya 4",
        s: "dubai-dubai-land-queue-point-mazaya-4"
    }, {
        n: "Mazaya 23",
        s: "dubai-dubai-land-queue-point-mazaya-23"
    }, {
        n: "Mazaya 18",
        s: "dubai-dubai-land-queue-point-mazaya-18"
    }, {
        n: "Maysan Tower 3",
        s: "dubai-dubai-land-maysan-towers-maysan-tower-3"
    }, {
        n: "The LOFT Office 2",
        s: "dubai-dubai-media-city-the-loft-offices-the-loft-office-2"
    }, {
        n: "Lincoln Gardens",
        s: "dubai-umm-suqeim-umm-suqeim-1-lincoln-gardens"
    }, {
        n: "Blue Reef building",
        s: "dubai-umm-suqeim-umm-suqeim-2-blue-reef-building"
    }, {
        n: "Al Sufouh Tower 1",
        s: "dubai-dubai-media-city-al-sufouh-towers-al-sufouh-tower-1"
    }, {
        n: "Al Manara Village",
        s: "dubai-umm-suqeim-umm-suqeim-3-al-manara-village"
    }, {
        n: "The Jewel Tower B",
        s: "dubai-dubai-marina-the-jewels-the-jewel-tower-b"
    }, {
        n: "Al Majara 6",
        s: "dubai-dubai-marina-al-majara-al-majara-6"
    }, {
        n: "DEC Tower 2",
        s: "dubai-dubai-marina-dec-towers-dec-tower-2"
    }, {
        n: "Bay Central East",
        s: "dubai-dubai-marina-bay-central-bay-central-east"
    }, {
        n: "Marina Diamond 2",
        s: "dubai-dubai-marina-marina-diamonds-marina-diamond-2"
    }, {
        n: "Surf",
        s: "dubai-dubai-creek-harbour-the-lagoons-creek-beach-surf"
    }, {
        n: "Wavez Residence",
        s: "dubai-dubai-land-liwan-wavez-residence"
    }, {
        n: "Mazaya 22",
        s: "dubai-dubai-land-queue-point-mazaya-22"
    }, {
        n: "Mazaya 7",
        s: "dubai-dubai-land-queue-point-mazaya-7"
    }, {
        n: "Mazaya 29",
        s: "dubai-dubai-land-queue-point-mazaya-29"
    }, {
        n: "Mazaya 24",
        s: "dubai-dubai-land-queue-point-mazaya-24"
    }, {
        n: "Al Yarmouk",
        s: "dubai-dubai-land-queue-point-al-yarmouk"
    }, {
        n: "Mazaya 6",
        s: "dubai-dubai-land-queue-point-mazaya-6"
    }, {
        n: "Mazaya 25",
        s: "dubai-dubai-land-queue-point-mazaya-25"
    }, {
        n: "Farah Tower 5",
        s: "dubai-dubai-land-queue-point-farah-tower-5"
    }, {
        n: "Maysan Tower 2",
        s: "dubai-dubai-land-maysan-towers-maysan-tower-2"
    }, {
        n: "The LOFT Office 3",
        s: "dubai-dubai-media-city-the-loft-offices-the-loft-office-3"
    }, {
        n: "Roda Boutique Villas",
        s: "dubai-umm-suqeim-umm-suqeim-1-roda-boutique-villas"
    }, {
        n: "Sidra Village",
        s: "dubai-umm-suqeim-umm-suqeim-2-sidra-village"
    }, {
        n: "Al Sufouh Tower 2",
        s: "dubai-dubai-media-city-al-sufouh-towers-al-sufouh-tower-2"
    }, {
        n: "The Waves Tower A",
        s: "dubai-dubai-marina-the-waves-the-waves-tower-a"
    }, {
        n: "Al Majara 3",
        s: "dubai-dubai-marina-al-majara-al-majara-3"
    }, {
        n: "DEC Towers Podium",
        s: "dubai-dubai-marina-dec-towers-dec-towers-podium"
    }, {
        n: "Bay Central West",
        s: "dubai-dubai-marina-bay-central-bay-central-west"
    }, {
        n: "The Royal Oceanic",
        s: "dubai-dubai-marina-oceanic-the-royal-oceanic"
    }, {
        n: "Dream Tower 2",
        s: "dubai-dubai-marina-dream-towers-dream-tower-2"
    }, {
        n: "Paloma Tower",
        s: "dubai-dubai-marina-marina-promenade-paloma-tower"
    }, {
        n: "Marina Quays Villas",
        s: "dubai-dubai-marina-marina-quays-marina-quays-villas"
    }, {
        n: "Marina View Tower B",
        s: "dubai-dubai-marina-marina-view-marina-view-tower-b"
    }, {
        n: "Marina Diamond 3",
        s: "dubai-dubai-marina-marina-diamonds-marina-diamond-3"
    }, {
        n: "Al Mass Tower",
        s: "dubai-dubai-marina-emaar-6-towers-al-mass-tower"
    }, {
        n: "Al Yass Tower",
        s: "dubai-dubai-marina-emaar-6-towers-al-yass-tower"
    }, {
        n: "Amaranta",
        s: "dubai-dubai-land-villanova-amaranta"
    }, {
        n: "Mazaya 12",
        s: "dubai-dubai-land-queue-point-mazaya-12"
    }, {
        n: "Tala 2",
        s: "dubai-dubai-land-queue-point-tala-2"
    }, {
        n: "Mazaya 28",
        s: "dubai-dubai-land-queue-point-mazaya-28"
    }, {
        n: "Monaco Residency",
        s: "dubai-dubai-land-queue-point-monaco-residency"
    }, {
        n: "Tala 1",
        s: "dubai-dubai-land-queue-point-tala-1"
    }, {
        n: "Mazaya 31",
        s: "dubai-dubai-land-queue-point-mazaya-31"
    }, {
        n: "Mazaya 27",
        s: "dubai-dubai-land-queue-point-mazaya-27"
    }, {
        n: "Mazaya 13",
        s: "dubai-dubai-land-queue-point-mazaya-13"
    }, {
        n: "Business Central Tower A",
        s: "dubai-dubai-media-city-business-central-business-central-tower-a"
    }, {
        n: "Al Kazim Tower 2",
        s: "dubai-dubai-media-city-al-kazim-towers-al-kazim-tower-2"
    }, {
        n: "Rahaal",
        s: "dubai-umm-suqeim-madinat-jumeirah-living-rahaal"
    }, {
        n: "Al Majara 2",
        s: "dubai-dubai-marina-al-majara-al-majara-2"
    }, {
        n: "DEC Tower 1",
        s: "dubai-dubai-marina-dec-towers-dec-tower-1"
    }, {
        n: "Marina Residence B",
        s: "dubai-dubai-marina-marina-residence-marina-residence-b"
    }, {
        n: "Delphine Tower",
        s: "dubai-dubai-marina-marina-promenade-delphine-tower"
    }, {
        n: "Marina Quay West",
        s: "dubai-dubai-marina-marina-quays-marina-quay-west"
    }, {
        n: "Marina Diamond 6",
        s: "dubai-dubai-marina-marina-diamonds-marina-diamond-6"
    }, {
        n: "Al Anbar Tower",
        s: "dubai-dubai-marina-emaar-6-towers-al-anbar-tower"
    }, {
        n: "Jumeirah Living Marina Gate",
        s: "dubai-dubai-marina-marina-gate-jumeirah-living-marina-gate"
    }, {
        n: "The Residences",
        s: "dubai-dubai-marina-marina-gate-the-residences"
    }, {
        n: "Al Sahab 2",
        s: "dubai-dubai-marina-al-sahab-al-sahab-2"
    }, {
        n: "Al Atina Tower A",
        s: "dubai-dubai-marina-al-atina-al-atina-tower-a"
    }, {
        n: "Sparkle Tower 1",
        s: "dubai-dubai-marina-sparkle-towers-sparkle-tower-1"
    }, {
        n: "Sparkle Tower 2",
        s: "dubai-dubai-marina-sparkle-towers-sparkle-tower-2"
    }, {
        n: "Mazaya 32",
        s: "dubai-dubai-land-queue-point-mazaya-32"
    }, {
        n: "Mazaya 15",
        s: "dubai-dubai-land-queue-point-mazaya-15"
    }, {
        n: "Mazaya 21",
        s: "dubai-dubai-land-queue-point-mazaya-21"
    }, {
        n: "Mazaya 1",
        s: "dubai-dubai-land-queue-point-mazaya-1"
    }, {
        n: "Mazaya 8",
        s: "dubai-dubai-land-queue-point-mazaya-8"
    }, {
        n: "Mazaya 9",
        s: "dubai-dubai-land-queue-point-mazaya-9"
    }, {
        n: "The LOFT Office 1",
        s: "dubai-dubai-media-city-the-loft-offices-the-loft-office-1"
    }, {
        n: "Umm Suqeim 1 Villas",
        s: "dubai-umm-suqeim-umm-suqeim-1-umm-suqeim-1-villas"
    }, {
        n: "Courtyard 29 Villas",
        s: "dubai-umm-suqeim-umm-suqeim-2-courtyard-29-villas"
    }, {
        n: "Al Maha Villas",
        s: "dubai-umm-suqeim-umm-suqeim-3-al-maha-villas"
    }, {
        n: "The Jewel Tower A",
        s: "dubai-dubai-marina-the-jewels-the-jewel-tower-a"
    }, {
        n: "Al Majara 4",
        s: "dubai-dubai-marina-al-majara-al-majara-4"
    }, {
        n: "Al Majara 5",
        s: "dubai-dubai-marina-al-majara-al-majara-5"
    }, {
        n: "Marinascape Avant",
        s: "dubai-dubai-marina-marinascape-marinascape-avant"
    }, {
        n: "Attessa Tower",
        s: "dubai-dubai-marina-marina-promenade-attessa-tower"
    }, {
        n: "Shemara Tower",
        s: "dubai-dubai-marina-marina-promenade-shemara-tower"
    }, {
        n: "Marina View Tower A",
        s: "dubai-dubai-marina-marina-view-marina-view-tower-a"
    }, {
        n: "Marina Diamond 1",
        s: "dubai-dubai-marina-marina-diamonds-marina-diamond-1"
    }, {
        n: "Marina Diamond 5",
        s: "dubai-dubai-marina-marina-diamonds-marina-diamond-5"
    }, {
        n: "Murjan Tower",
        s: "dubai-dubai-marina-emaar-6-towers-murjan-tower"
    }, {
        n: "Marina Gate 1",
        s: "dubai-dubai-marina-marina-gate-marina-gate-1"
    }, {
        n: "Marina Gate 2",
        s: "dubai-dubai-marina-marina-gate-marina-gate-2"
    }, {
        n: "Silverene Tower B",
        s: "dubai-dubai-marina-silverene-silverene-tower-b"
    }, {
        n: "Al Sahab 1",
        s: "dubai-dubai-marina-al-sahab-al-sahab-1"
    }, {
        n: "Al Atina Tower B",
        s: "dubai-dubai-marina-al-atina-al-atina-tower-b"
    }, {
        n: "Al Ramth 20",
        s: "dubai-remraam-al-ramth-al-ramth-20"
    }, {
        n: "Umm Suqeim 2 Villas",
        s: "dubai-umm-suqeim-umm-suqeim-2-umm-suqeim-2-villas"
    }, {
        n: "Umm Suqeim 3 Villas",
        s: "dubai-umm-suqeim-umm-suqeim-3-umm-suqeim-3-villas"
    }, {
        n: "Lamtara",
        s: "dubai-umm-suqeim-madinat-jumeirah-living-lamtara"
    }, {
        n: "The Waves Tower B",
        s: "dubai-dubai-marina-the-waves-the-waves-tower-b"
    }, {
        n: "Marinascape Marina Homes",
        s: "dubai-dubai-marina-marinascape-marinascape-marina-homes"
    }, {
        n: "Trident Oceanic",
        s: "dubai-dubai-marina-oceanic-trident-oceanic"
    }, {
        n: "Marina Hotel Apartments",
        s: "dubai-dubai-marina-dubai-marina-walk-marina-hotel-apartments"
    }, {
        n: "Aurora Tower",
        s: "dubai-dubai-marina-marina-promenade-aurora-tower"
    }, {
        n: "Marina Quay North",
        s: "dubai-dubai-marina-marina-quays-marina-quay-north"
    }, {
        n: "Marina Diamond 7",
        s: "dubai-dubai-marina-marina-diamonds-marina-diamond-7"
    }, {
        n: "Marina Diamond 4",
        s: "dubai-dubai-marina-marina-diamonds-marina-diamond-4"
    }, {
        n: "Al Mesk Tower",
        s: "dubai-dubai-marina-emaar-6-towers-al-mesk-tower"
    }, {
        n: "Silverene Tower A",
        s: "dubai-dubai-marina-silverene-silverene-tower-a"
    }, {
        n: "Sparkle Tower 3",
        s: "dubai-dubai-marina-sparkle-towers-sparkle-tower-3"
    }, {
        n: "Marina Wharf 2",
        s: "dubai-dubai-marina-marina-wharf-marina-wharf-2"
    }, {
        n: "Marina Wharf 1",
        s: "dubai-dubai-marina-marina-wharf-marina-wharf-1"
    }, {
        n: "Sanibel Tower",
        s: "dubai-dubai-marina-park-island-sanibel-tower"
    }, {
        n: "Bonaire Tower",
        s: "dubai-dubai-marina-park-island-bonaire-tower"
    }, {
        n: "Palmera 1",
        s: "dubai-arabian-ranches-palmera-palmera-1"
    }, {
        n: "Noora",
        s: "dubai-business-bay-al-habtoor-city-noora"
    }, {
        n: "Capital Bay Tower B",
        s: "dubai-business-bay-capital-bay-capital-bay-tower-b"
    }, {
        n: "Bay Square Building 3",
        s: "dubai-business-bay-bay-square-bay-square-building-3"
    }, {
        n: "Bay Square Building 6",
        s: "dubai-business-bay-bay-square-bay-square-building-6"
    }, {
        n: "Bay Square Building 1",
        s: "dubai-business-bay-bay-square-bay-square-building-1"
    }, {
        n: "Ubora Tower 2",
        s: "dubai-business-bay-ubora-towers-ubora-tower-2"
    }, {
        n: "Tower C",
        s: "dubai-business-bay-damac-towers-by-paramount-tower-c"
    }, {
        n: "J ONE Tower A",
        s: "dubai-business-bay-j-one-j-one-tower-a"
    }, {
        n: "Starhill Towers & Gallery 1",
        s: "dubai-business-bay-starhill-towers-gallery-starhill-towers-gallery-1"
    }, {
        n: "Signature Tower Residential",
        s: "dubai-business-bay-signature-towers-signature-tower-residential"
    }, {
        n: "Sobha Ivory Tower 2",
        s: "dubai-business-bay-sobha-ivory-towers-sobha-ivory-tower-2"
    }, {
        n: "Executive Tower C",
        s: "dubai-business-bay-executive-towers-executive-tower-c"
    }, {
        n: "Executive Tower Villas",
        s: "dubai-business-bay-executive-towers-executive-tower-villas"
    }, {
        n: "Executive Tower L",
        s: "dubai-business-bay-executive-towers-executive-tower-l"
    }, {
        n: "Art Tower XV",
        s: "dubai-business-bay-al-abraj-street-art-tower-xv"
    }, {
        n: "Bristol Tower 2",
        s: "dubai-business-bay-bristol-towers-bristol-tower-2"
    }, {
        n: "Sky Tower 2",
        s: "dubai-business-bay-sky-towers-sky-tower-2"
    }, {
        n: "Cadi Residence 4",
        s: "dubai-jumeirah-village-circle-cadi-residences-cadi-residence-4"
    }, {
        n: "La Vista Residence 5",
        s: "dubai-dubai-silicon-oasis-la-vista-residence-la-vista-residence-5"
    }, {
        n: "Palace Tower 2",
        s: "dubai-dubai-silicon-oasis-palace-towers-palace-tower-2"
    }, {
        n: "City Oasis 2",
        s: "dubai-dubai-silicon-oasis-city-oasis-city-oasis-2"
    }, {
        n: "Axis Residence 5",
        s: "dubai-dubai-silicon-oasis-axis-residence-axis-residence-5"
    }, {
        n: "Axis Residence 7",
        s: "dubai-dubai-silicon-oasis-axis-residence-axis-residence-7"
    }, {
        n: "Park Avenue Residence",
        s: "dubai-dubai-silicon-oasis-park-avenue-park-avenue-residence"
    }, {
        n: "Elite Sports Residence 5",
        s: "dubai-dubai-sports-city-elite-sports-residence-elite-sports-residence-5"
    }, {
        n: "Elite Sports Residence 10",
        s: "dubai-dubai-sports-city-elite-sports-residence-elite-sports-residence-10"
    }, {
        n: "Olympic Park 2",
        s: "dubai-dubai-sports-city-olympic-park-towers-olympic-park-2"
    }, {
        n: "Carmen",
        s: "dubai-dubai-sports-city-victory-heights-carmen"
    }, {
        n: "The Moon Villas",
        s: "dubai-dubai-sports-city-victory-heights-the-moon-villas"
    }, {
        n: "Marbella Village",
        s: "dubai-dubai-sports-city-victory-heights-marbella-village"
    }, {
        n: "Rufi Waterfront Tower",
        s: "dubai-dubai-sports-city-rufy-residency-rufi-waterfront-tower"
    }, {
        n: "Venetian",
        s: "dubai-dubai-sports-city-canal-residence-venetian"
    }, {
        n: "Arena Apartments",
        s: "dubai-dubai-sports-city-the-arena-apartments-arena-apartments"
    }, {
        n: "German Sports Tower 2",
        s: "dubai-dubai-sports-city-german-sports-towers-german-sports-tower-2"
    }, {
        n: "Champions Tower 2",
        s: "dubai-dubai-sports-city-champions-towers-champions-tower-2"
    }, {
        n: "Zenith A3 Tower",
        s: "dubai-dubai-sports-city-zenith-towers-zenith-a3-tower"
    }, {
        n: "Al Alka 1",
        s: "dubai-greens-al-alka-al-alka-1"
    }, {
        n: "Al Thayyal 2",
        s: "dubai-greens-al-thayyal-al-thayyal-2"
    }, {
        n: "Al Samar 2",
        s: "dubai-greens-al-samar-al-samar-2"
    }, {
        n: "La Vista Residence 2",
        s: "dubai-dubai-silicon-oasis-la-vista-residence-la-vista-residence-2"
    }, {
        n: "City Oasis 1",
        s: "dubai-dubai-silicon-oasis-city-oasis-city-oasis-1"
    }, {
        n: "Axis Residence 6",
        s: "dubai-dubai-silicon-oasis-axis-residence-axis-residence-6"
    }, {
        n: "Park Avenue Commercial Tower",
        s: "dubai-dubai-silicon-oasis-park-avenue-park-avenue-commercial-tower"
    }, {
        n: "Elite Sports Residence 7",
        s: "dubai-dubai-sports-city-elite-sports-residence-elite-sports-residence-7"
    }, {
        n: "Elite Sports Residence 9",
        s: "dubai-dubai-sports-city-elite-sports-residence-elite-sports-residence-9"
    }, {
        n: "Fortuna Village",
        s: "dubai-dubai-sports-city-victory-heights-fortuna-village"
    }, {
        n: "European",
        s: "dubai-dubai-sports-city-canal-residence-european"
    }, {
        n: "German Sports Tower 1",
        s: "dubai-dubai-sports-city-german-sports-towers-german-sports-tower-1"
    }, {
        n: "Champions Tower 3",
        s: "dubai-dubai-sports-city-champions-towers-champions-tower-3"
    }, {
        n: "Al Alka 4",
        s: "dubai-greens-al-alka-al-alka-4"
    }, {
        n: "Al Thayyal 3",
        s: "dubai-greens-al-thayyal-al-thayyal-3"
    }, {
        n: "Al Samar 4",
        s: "dubai-greens-al-samar-al-samar-4"
    }, {
        n: "Marwa Homes",
        s: "dubai-jumeirah-village-circle-district-12-marwa-homes"
    }, {
        n: "Nibras Tower",
        s: "dubai-dubai-silicon-oasis-le-presidium-nibras-tower"
    }, {
        n: "La Vista Residence 7",
        s: "dubai-dubai-silicon-oasis-la-vista-residence-la-vista-residence-7"
    }, {
        n: "Axis Residence 8",
        s: "dubai-dubai-silicon-oasis-axis-residence-axis-residence-8"
    }, {
        n: "City Oasis 3",
        s: "dubai-dubai-silicon-oasis-city-oasis-city-oasis-3"
    }, {
        n: "Elite Sports Residence 1",
        s: "dubai-dubai-sports-city-elite-sports-residence-elite-sports-residence-1"
    }, {
        n: "Olympic Park 3",
        s: "dubai-dubai-sports-city-olympic-park-towers-olympic-park-3"
    }, {
        n: "Hub Canal 1",
        s: "dubai-dubai-sports-city-hub-golf-towers-hub-canal-1"
    }, {
        n: "Zenith A2 Tower",
        s: "dubai-dubai-sports-city-zenith-towers-zenith-a2-tower"
    }, {
        n: "Al Alka 2",
        s: "dubai-greens-al-alka-al-alka-2"
    }, {
        n: "Al Muhra 1",
        s: "dubai-jumeirah-village-circle-al-muhra-al-muhra-1"
    }, {
        n: "Palace Tower 1",
        s: "dubai-dubai-silicon-oasis-palace-towers-palace-tower-1"
    }, {
        n: "Silicon Oasis Techno Hub 1",
        s: "dubai-dubai-silicon-oasis-silicon-oasis-techno-hub-silicon-oasis-techno-hub-1"
    }, {
        n: "Axis Residence 4",
        s: "dubai-dubai-silicon-oasis-axis-residence-axis-residence-4"
    }, {
        n: "Axis Residence 3",
        s: "dubai-dubai-silicon-oasis-axis-residence-axis-residence-3"
    }, {
        n: "Silicon Heights 1",
        s: "dubai-dubai-silicon-oasis-silicon-heights-silicon-heights-1"
    }, {
        n: "Elite Sports Residence 4",
        s: "dubai-dubai-sports-city-elite-sports-residence-elite-sports-residence-4"
    }, {
        n: "Elite Sports Residence 3",
        s: "dubai-dubai-sports-city-elite-sports-residence-elite-sports-residence-3"
    }, {
        n: "Olympic Park 1",
        s: "dubai-dubai-sports-city-olympic-park-towers-olympic-park-1"
    }, {
        n: "Hub Canal 2",
        s: "dubai-dubai-sports-city-hub-golf-towers-hub-canal-2"
    }, {
        n: "Morella",
        s: "dubai-dubai-sports-city-victory-heights-morella"
    }, {
        n: "Calida",
        s: "dubai-dubai-sports-city-victory-heights-calida"
    }, {
        n: "Rufi Rose Gardens",
        s: "dubai-dubai-sports-city-rufy-residency-rufi-rose-gardens"
    }, {
        n: "Mediterranean",
        s: "dubai-dubai-sports-city-canal-residence-mediterranean"
    }, {
        n: "Grand Horizon 2",
        s: "dubai-dubai-sports-city-grand-horizon-grand-horizon-2"
    }, {
        n: "Royal Residence 2",
        s: "dubai-dubai-sports-city-royal-residence-royal-residence-2"
    }, {
        n: "Zenith A1 Tower",
        s: "dubai-dubai-sports-city-zenith-towers-zenith-a1-tower"
    }, {
        n: "Al Alka 3",
        s: "dubai-greens-al-alka-al-alka-3"
    }, {
        n: "Al Samar 1",
        s: "dubai-greens-al-samar-al-samar-1"
    }, {
        n: "Al Dhafra 1",
        s: "dubai-greens-al-dhafra-al-dhafra-1"
    }, {
        n: "Al Nakheel 3",
        s: "dubai-greens-al-nakheel-al-nakheel-3"
    }, {
        n: "La Vista Residence 3",
        s: "dubai-dubai-silicon-oasis-la-vista-residence-la-vista-residence-3"
    }, {
        n: "Axis Residence 1",
        s: "dubai-dubai-silicon-oasis-axis-residence-axis-residence-1"
    }, {
        n: "Axis Residence 9",
        s: "dubai-dubai-silicon-oasis-axis-residence-axis-residence-9"
    }, {
        n: "Silicon Heights 2",
        s: "dubai-dubai-silicon-oasis-silicon-heights-silicon-heights-2"
    }, {
        n: "Elite Sports Residence 8",
        s: "dubai-dubai-sports-city-elite-sports-residence-elite-sports-residence-8"
    }, {
        n: "Elite Sports Residence 6",
        s: "dubai-dubai-sports-city-elite-sports-residence-elite-sports-residence-6"
    }, {
        n: "Olympic Park 4",
        s: "dubai-dubai-sports-city-olympic-park-towers-olympic-park-4"
    }, {
        n: "Bloomingdale Townhouses",
        s: "dubai-dubai-sports-city-bloomingdale-bloomingdale-townhouses"
    }, {
        n: "Gallery Villas",
        s: "dubai-dubai-sports-city-victory-heights-gallery-villas"
    }, {
        n: "Rufi Twin Towers",
        s: "dubai-dubai-sports-city-rufy-residency-rufi-twin-towers"
    }, {
        n: "Spanish Andalusian",
        s: "dubai-dubai-sports-city-canal-residence-spanish-andalusian"
    }, {
        n: "Najma Tower A",
        s: "dubai-dubai-sports-city-najma-towers-najma-tower-a"
    }, {
        n: "Champions Tower 1",
        s: "dubai-dubai-sports-city-champions-towers-champions-tower-1"
    }, {
        n: "Arabian",
        s: "dubai-dubai-sports-city-canal-residence-arabian"
    }, {
        n: "Grand Horizon 1",
        s: "dubai-dubai-sports-city-grand-horizon-grand-horizon-1"
    }, {
        n: "Royal Residence 1",
        s: "dubai-dubai-sports-city-royal-residence-royal-residence-1"
    }, {
        n: "Champions Tower 4",
        s: "dubai-dubai-sports-city-champions-towers-champions-tower-4"
    }, {
        n: "Al Thayyal 1",
        s: "dubai-greens-al-thayyal-al-thayyal-1"
    }, {
        n: "Al Ghaf 1",
        s: "dubai-greens-al-ghaf-al-ghaf-1"
    }, {
        n: "Al Dhafra 2",
        s: "dubai-greens-al-dhafra-al-dhafra-2"
    }, {
        n: "Al Nakheel 4",
        s: "dubai-greens-al-nakheel-al-nakheel-4"
    }, {
        n: "Al Ghozlan 2",
        s: "dubai-greens-al-ghozlan-al-ghozlan-2"
    }, {
        n: "The Onyx Tower 2",
        s: "dubai-greens-the-onyx-towers-the-onyx-tower-2"
    }, {
        n: "Al Sidir 1",
        s: "dubai-greens-al-sidir-al-sidir-1"
    }, {
        n: "Northwest Garden Apartments",
        s: "dubai-green-community-green-community-west-northwest-garden-apartments"
    }, {
        n: "Bungalows Area",
        s: "dubai-green-community-green-community-west-bungalows-area"
    }, {
        n: "Building F",
        s: "dubai-green-community-terrace-apartments-building-f"
    }, {
        n: "Building C",
        s: "dubai-green-community-garden-east-apartments-building-c"
    }, {
        n: "Lago Vista A",
        s: "dubai-dubai-production-city-impz-lago-vista-lago-vista-a"
    }, {
        n: "Lago Vista C",
        s: "dubai-dubai-production-city-impz-lago-vista-lago-vista-c"
    }, {
        n: "Lakeside Tower A",
        s: "dubai-dubai-production-city-impz-lakeside-residence-lakeside-tower-a"
    }, {
        n: "Al Ghaf 2",
        s: "dubai-greens-al-ghaf-al-ghaf-2"
    }, {
        n: "Al Ghozlan 4",
        s: "dubai-greens-al-ghozlan-al-ghozlan-4"
    }, {
        n: "Al Arta 2",
        s: "dubai-greens-al-arta-al-arta-2"
    }, {
        n: "Al Sidir 2",
        s: "dubai-greens-al-sidir-al-sidir-2"
    }, {
        n: "West Phase III",
        s: "dubai-green-community-green-community-west-west-phase-iii"
    }, {
        n: "Building A",
        s: "dubai-green-community-terrace-apartments-building-a"
    }, {
        n: "Lake Apartments D",
        s: "dubai-green-community-lake-apartments-lake-apartments-d"
    }, {
        n: "Building I",
        s: "dubai-green-community-garden-east-apartments-building-i"
    }, {
        n: "Lakeside Tower C",
        s: "dubai-dubai-production-city-impz-lakeside-residence-lakeside-tower-c"
    }, {
        n: "Family Villa Area",
        s: "dubai-green-community-green-community-east-family-villa-area"
    }, {
        n: "Building F",
        s: "dubai-green-community-garden-west-apartments-building-f"
    }, {
        n: "Building C",
        s: "dubai-green-community-garden-west-apartments-building-c"
    }, {
        n: "The Dania District 5",
        s: "dubai-dubai-production-city-impz-midtown-the-dania-district-5"
    }, {
        n: "Afnan 7",
        s: "dubai-dubai-production-city-impz-midtown-afnan-7"
    }, {
        n: "The Dania District 1",
        s: "dubai-dubai-production-city-impz-midtown-the-dania-district-1"
    }, {
        n: "Block B",
        s: "dubai-dubai-production-city-impz-jil-building-block-b"
    }, {
        n: "Indigo Spectrum 2",
        s: "dubai-international-city-indigo-towers-indigo-spectrum-2"
    }, {
        n: "Prime Residency 1",
        s: "dubai-international-city-prime-residency-prime-residency-1"
    }, {
        n: "Supreme Residency",
        s: "dubai-international-city-cbd-central-business-district-supreme-residency"
    }, {
        n: "E-06",
        s: "dubai-international-city-cbd-central-business-district-e-06"
    }, {
        n: "D-05",
        s: "dubai-international-city-cbd-central-business-district-d-05"
    }, {
        n: "Al Dhafra 3",
        s: "dubai-greens-al-dhafra-al-dhafra-3"
    }, {
        n: "Al Nakheel 1",
        s: "dubai-greens-al-nakheel-al-nakheel-1"
    }, {
        n: "Al Ghozlan 3",
        s: "dubai-greens-al-ghozlan-al-ghozlan-3"
    }, {
        n: "Al Arta 3",
        s: "dubai-greens-al-arta-al-arta-3"
    }, {
        n: "Al Sidir 3",
        s: "dubai-greens-al-sidir-al-sidir-3"
    }, {
        n: "Southwest Apartments",
        s: "dubai-green-community-green-community-west-southwest-apartments"
    }, {
        n: "Building E",
        s: "dubai-green-community-terrace-apartments-building-e"
    }, {
        n: "Building D",
        s: "dubai-green-community-terrace-apartments-building-d"
    }, {
        n: "Lake Apartments B",
        s: "dubai-green-community-lake-apartments-lake-apartments-b"
    }, {
        n: "Building A",
        s: "dubai-green-community-garden-east-apartments-building-a"
    }, {
        n: "Townhouses Area",
        s: "dubai-green-community-green-community-east-townhouses-area"
    }, {
        n: "Building E",
        s: "dubai-green-community-garden-west-apartments-building-e"
    }, {
        n: "Building I",
        s: "dubai-green-community-garden-west-apartments-building-i"
    }, {
        n: "Afnan 6",
        s: "dubai-dubai-production-city-impz-midtown-afnan-6"
    }, {
        n: "Afnan 4",
        s: "dubai-dubai-production-city-impz-midtown-afnan-4"
    }, {
        n: "The Dania District 2",
        s: "dubai-dubai-production-city-impz-midtown-the-dania-district-2"
    }, {
        n: "Centrium Tower 2",
        s: "dubai-dubai-production-city-impz-centrium-towers-centrium-tower-2"
    }, {
        n: "The Crescent A",
        s: "dubai-dubai-production-city-impz-the-crescent-the-crescent-a"
    }, {
        n: "HDS Sunstar II",
        s: "dubai-international-city-cbd-central-business-district-hds-sunstar-ii"
    }, {
        n: "Classic Apartments",
        s: "dubai-international-city-cbd-central-business-district-classic-apartments"
    }, {
        n: "D-02",
        s: "dubai-international-city-cbd-central-business-district-d-02"
    }, {
        n: "E-01",
        s: "dubai-international-city-cbd-central-business-district-e-01"
    }, {
        n: "Al Nakheel 2",
        s: "dubai-greens-al-nakheel-al-nakheel-2"
    }, {
        n: "Al Jaz 1",
        s: "dubai-greens-al-jaz-al-jaz-1"
    }, {
        n: "Al Ghozlan 1",
        s: "dubai-greens-al-ghozlan-al-ghozlan-1"
    }, {
        n: "Al Arta 1",
        s: "dubai-greens-al-arta-al-arta-1"
    }, {
        n: "Al Sidir 4",
        s: "dubai-greens-al-sidir-al-sidir-4"
    }, {
        n: "Townhouses Area",
        s: "dubai-green-community-green-community-west-townhouses-area"
    }, {
        n: "Building G",
        s: "dubai-green-community-terrace-apartments-building-g"
    }, {
        n: "Lake Apartments A",
        s: "dubai-green-community-lake-apartments-lake-apartments-a"
    }, {
        n: "Building F",
        s: "dubai-green-community-garden-east-apartments-building-f"
    }, {
        n: "Building D",
        s: "dubai-green-community-garden-east-apartments-building-d"
    }, {
        n: "Building H",
        s: "dubai-green-community-garden-east-apartments-building-h"
    }, {
        n: "Lago Vista B",
        s: "dubai-dubai-production-city-impz-lago-vista-lago-vista-b"
    }, {
        n: "Lakeside Tower D",
        s: "dubai-dubai-production-city-impz-lakeside-residence-lakeside-tower-d"
    }, {
        n: "Lakeview Apartments",
        s: "dubai-green-community-green-community-east-lakeview-apartments"
    }, {
        n: "Building G",
        s: "dubai-green-community-garden-west-apartments-building-g"
    }, {
        n: "Building J",
        s: "dubai-green-community-garden-west-apartments-building-j"
    }, {
        n: "Afnan 1",
        s: "dubai-dubai-production-city-impz-midtown-afnan-1"
    }, {
        n: "The Dania District 3",
        s: "dubai-dubai-production-city-impz-midtown-the-dania-district-3"
    }, {
        n: "The Dania District 4",
        s: "dubai-dubai-production-city-impz-midtown-the-dania-district-4"
    }, {
        n: "Block C",
        s: "dubai-dubai-production-city-impz-jil-building-block-c"
    }, {
        n: "The Onyx Tower 1",
        s: "dubai-greens-the-onyx-towers-the-onyx-tower-1"
    }, {
        n: "Luxury Villas Area",
        s: "dubai-green-community-green-community-west-luxury-villas-area"
    }, {
        n: "Family Villas",
        s: "dubai-green-community-green-community-west-family-villas"
    }, {
        n: "Building C",
        s: "dubai-green-community-terrace-apartments-building-c"
    }, {
        n: "Lake Apartments E",
        s: "dubai-green-community-lake-apartments-lake-apartments-e"
    }, {
        n: "Lake Apartments C",
        s: "dubai-green-community-lake-apartments-lake-apartments-c"
    }, {
        n: "Building E",
        s: "dubai-green-community-garden-east-apartments-building-e"
    }, {
        n: "Building G",
        s: "dubai-green-community-garden-east-apartments-building-g"
    }, {
        n: "Building J",
        s: "dubai-green-community-garden-east-apartments-building-j"
    }, {
        n: "Lakeside Tower B",
        s: "dubai-dubai-production-city-impz-lakeside-residence-lakeside-tower-b"
    }, {
        n: "Luxury Villa Area",
        s: "dubai-green-community-green-community-east-luxury-villa-area"
    }, {
        n: "Building H",
        s: "dubai-green-community-garden-west-apartments-building-h"
    }, {
        n: "Building B",
        s: "dubai-green-community-garden-west-apartments-building-b"
    }, {
        n: "Afnan 5",
        s: "dubai-dubai-production-city-impz-midtown-afnan-5"
    }, {
        n: "The Dania District 6",
        s: "dubai-dubai-production-city-impz-midtown-the-dania-district-6"
    }, {
        n: "Centrium Tower 3",
        s: "dubai-dubai-production-city-impz-centrium-towers-centrium-tower-3"
    }, {
        n: "Indigo Capital",
        s: "dubai-international-city-indigo-towers-indigo-capital"
    }, {
        n: "Prime Residency 2",
        s: "dubai-international-city-prime-residency-prime-residency-2"
    }, {
        n: "B-01",
        s: "dubai-international-city-cbd-central-business-district-b-01"
    }, {
        n: "F-01",
        s: "dubai-international-city-cbd-central-business-district-f-01"
    }, {
        n: "D-03",
        s: "dubai-international-city-cbd-central-business-district-d-03"
    }, {
        n: "D-01",
        s: "dubai-international-city-cbd-central-business-district-d-01"
    }, {
        n: "Rufi Prime View",
        s: "dubai-international-city-cbd-central-business-district-rufi-prime-view"
    }, {
        n: "Al Fattan Hotel Apartment",
        s: "dubai-jumeirah-beach-residence-al-fattan-marine-towers-al-fattan-hotel-apartment"
    }, {
        n: "The Crescent B",
        s: "dubai-dubai-production-city-impz-the-crescent-the-crescent-b"
    }, {
        n: "Indigo Spectrum 1",
        s: "dubai-international-city-indigo-towers-indigo-spectrum-1"
    }, {
        n: "Al Dana 2",
        s: "dubai-international-city-al-dana-al-dana-2"
    }, {
        n: "Trafalgar Executive",
        s: "dubai-international-city-cbd-central-business-district-trafalgar-executive"
    }, {
        n: "F-02",
        s: "dubai-international-city-cbd-central-business-district-f-02"
    }, {
        n: "C-03",
        s: "dubai-international-city-cbd-central-business-district-c-03"
    }, {
        n: "D-04",
        s: "dubai-international-city-cbd-central-business-district-d-04"
    }, {
        n: "Lady Ratan Manor",
        s: "dubai-international-city-cbd-central-business-district-lady-ratan-manor"
    }, {
        n: "C-05",
        s: "dubai-international-city-cbd-central-business-district-c-05"
    }, {
        n: "E-02",
        s: "dubai-international-city-cbd-central-business-district-e-02"
    }, {
        n: "F-05",
        s: "dubai-international-city-cbd-central-business-district-f-05"
    }, {
        n: "B-05",
        s: "dubai-international-city-cbd-central-business-district-b-05"
    }, {
        n: "Dragon Mart 2",
        s: "dubai-international-city-dragon-mart-dragon-mart-2"
    }, {
        n: "Al Muntazah",
        s: "dubai-jebel-ali-jebel-ali-village-al-muntazah"
    }, {
        n: "Jebel Ali Industrial 3",
        s: "dubai-jebel-ali-jebel-ali-industrial-jebel-ali-industrial-3"
    }, {
        n: "Jebel Ali Port",
        s: "dubai-jebel-ali-jebel-ali-freezone-jebel-ali-port"
    }, {
        n: "Roda Amwaj Suites",
        s: "dubai-jumeirah-beach-residence-amwaj-roda-amwaj-suites"
    }, {
        n: "Bahar 3",
        s: "dubai-jumeirah-beach-residence-bahar-bahar-3"
    }, {
        n: "Bahar 7",
        s: "dubai-jumeirah-beach-residence-bahar-bahar-7"
    }, {
        n: "Rimal 5",
        s: "dubai-jumeirah-beach-residence-rimal-rimal-5"
    }, {
        n: "Indigo Optima",
        s: "dubai-international-city-indigo-towers-indigo-optima"
    }, {
        n: "Solanki Palacio",
        s: "dubai-international-city-cbd-central-business-district-solanki-palacio"
    }, {
        n: "E-03",
        s: "dubai-international-city-cbd-central-business-district-e-03"
    }, {
        n: "B-03",
        s: "dubai-international-city-cbd-central-business-district-b-03"
    }, {
        n: "E-04",
        s: "dubai-international-city-cbd-central-business-district-e-04"
    }, {
        n: "Ritz Residences",
        s: "dubai-international-city-cbd-central-business-district-ritz-residences"
    }, {
        n: "Trafalgar Tower",
        s: "dubai-international-city-cbd-central-business-district-trafalgar-tower"
    }, {
        n: "C-07",
        s: "dubai-international-city-cbd-central-business-district-c-07"
    }, {
        n: "SP Residence",
        s: "dubai-international-city-phase-3-sp-residence"
    }, {
        n: "B-04",
        s: "dubai-international-city-cbd-central-business-district-b-04"
    }, {
        n: "Dragon Mart 1",
        s: "dubai-international-city-dragon-mart-dragon-mart-1"
    }, {
        n: "Al Muntazah Complex",
        s: "dubai-jebel-ali-jebel-ali-village-al-muntazah-complex"
    }, {
        n: "Jebel Ali Industrial 2",
        s: "dubai-jebel-ali-jebel-ali-industrial-jebel-ali-industrial-2"
    }, {
        n: "Jebel Ali Industrial 4",
        s: "dubai-jebel-ali-jebel-ali-industrial-jebel-ali-industrial-4"
    }, {
        n: "Amwaj 2",
        s: "dubai-jumeirah-beach-residence-amwaj-amwaj-2"
    }, {
        n: "Bahar 2",
        s: "dubai-jumeirah-beach-residence-bahar-bahar-2"
    }, {
        n: "Bahar 5",
        s: "dubai-jumeirah-beach-residence-bahar-bahar-5"
    }, {
        n: "Rimal 3",
        s: "dubai-jumeirah-beach-residence-rimal-rimal-3"
    }, {
        n: "Shams 4",
        s: "dubai-jumeirah-beach-residence-shams-shams-4"
    }, {
        n: "Al Fattan Marine Tower",
        s: "dubai-jumeirah-beach-residence-al-fattan-marine-towers-al-fattan-marine-tower"
    }, {
        n: "Murjan 4",
        s: "dubai-jumeirah-beach-residence-murjan-murjan-4"
    }, {
        n: "Suncity Homes",
        s: "dubai-international-city-phase-3-suncity-homes"
    }, {
        n: "Easy18",
        s: "dubai-international-city-phase-2-easy18"
    }, {
        n: "Jebel Ali Village Villas",
        s: "dubai-jebel-ali-jebel-ali-village-jebel-ali-village-villas"
    }, {
        n: "Nuzul Camp",
        s: "dubai-jebel-ali-jebel-ali-industrial-nuzul-camp"
    }, {
        n: "Freezone North",
        s: "dubai-jebel-ali-jebel-ali-freezone-freezone-north"
    }, {
        n: "Amwaj 1",
        s: "dubai-jumeirah-beach-residence-amwaj-amwaj-1"
    }, {
        n: "Bahar 6",
        s: "dubai-jumeirah-beach-residence-bahar-bahar-6"
    }, {
        n: "Rimal 1",
        s: "dubai-jumeirah-beach-residence-rimal-rimal-1"
    }, {
        n: "Shams 1",
        s: "dubai-jumeirah-beach-residence-shams-shams-1"
    }, {
        n: "Murjan 6",
        s: "dubai-jumeirah-beach-residence-murjan-murjan-6"
    }, {
        n: "Sadaf 3",
        s: "dubai-jumeirah-beach-residence-sadaf-sadaf-3"
    }, {
        n: "West Cluster",
        s: "dubai-jumeirah-heights-loft-cluster-west-cluster"
    }, {
        n: "Anantara Hotel & Spa",
        s: "dubai-jumeirah-lake-towers-lake-allure-anantara-hotel-spa"
    }, {
        n: "Tamweel Tower",
        s: "dubai-jumeirah-lake-towers-lake-allure-tamweel-tower"
    }, {
        n: "Tower A",
        s: "dubai-jumeirah-heights-the-frond-tower-a"
    }, {
        n: "Lake City Tower",
        s: "dubai-jumeirah-lake-towers-lake-almas-east-lake-city-tower"
    }, {
        n: "Saba Tower 3",
        s: "dubai-jumeirah-lake-towers-saba-towers-saba-tower-3"
    }, {
        n: "Green Lake Tower 2",
        s: "dubai-jumeirah-lake-towers-green-lake-towers-green-lake-tower-2"
    }, {
        n: "Armada Tower 2",
        s: "dubai-jumeirah-lake-towers-lake-elucio-armada-tower-2"
    }, {
        n: "Vue De Lac 1",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-vue-de-lac-1"
    }, {
        n: "Lake View Tower",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-lake-view-tower"
    }, {
        n: "Wind Tower 1",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-wind-tower-1"
    }, {
        n: "F49 Avenue Building",
        s: "dubai-international-city-phase-3-f49-avenue-building"
    }, {
        n: "Modelux Tower 1",
        s: "dubai-international-city-phase-2-modelux-tower-1"
    }, {
        n: "Tulip Oasis 5",
        s: "dubai-jebel-ali-jebel-ali-village-tulip-oasis-5"
    }, {
        n: "Freezone South",
        s: "dubai-jebel-ali-jebel-ali-freezone-freezone-south"
    }, {
        n: "Amwaj 5",
        s: "dubai-jumeirah-beach-residence-amwaj-amwaj-5"
    }, {
        n: "Bahar 1",
        s: "dubai-jumeirah-beach-residence-bahar-bahar-1"
    }, {
        n: "Rimal 6",
        s: "dubai-jumeirah-beach-residence-rimal-rimal-6"
    }, {
        n: "Murjan 3",
        s: "dubai-jumeirah-beach-residence-murjan-murjan-3"
    }, {
        n: "Sadaf 6",
        s: "dubai-jumeirah-beach-residence-sadaf-sadaf-6"
    }, {
        n: "Jumeirah Business Centre 5",
        s: "dubai-jumeirah-lake-towers-lake-allure-jumeirah-business-centre-5"
    }, {
        n: "Pullman Jumeirah Lakes Towers Hotel and Residence",
        s: "dubai-jumeirah-lake-towers-lake-allure-pullman-jumeirah-lakes-towers-hotel-and-residence"
    }, {
        n: "Jumeirah Business Centre 2",
        s: "dubai-jumeirah-lake-towers-lake-allure-jumeirah-business-centre-2"
    }, {
        n: "Tower E",
        s: "dubai-jumeirah-heights-the-frond-tower-e"
    }, {
        n: "Tower C",
        s: "dubai-jumeirah-heights-the-frond-tower-c"
    }, {
        n: "Platinum Tower (Pt Tower)",
        s: "dubai-jumeirah-lake-towers-lake-almas-east-platinum-tower-pt-tower"
    }, {
        n: "Global Lake View",
        s: "dubai-jumeirah-lake-towers-lake-almas-east-global-lake-view"
    }, {
        n: "Saba Tower 4",
        s: "dubai-jumeirah-lake-towers-saba-towers-saba-tower-4"
    }, {
        n: "Jumeirah Bay X2",
        s: "dubai-jumeirah-lake-towers-jumeirah-bay-towers-jumeirah-bay-x2"
    }, {
        n: "Green Lake Tower 3",
        s: "dubai-jumeirah-lake-towers-green-lake-towers-green-lake-tower-3"
    }, {
        n: "Al Waleed Paradise",
        s: "dubai-jumeirah-lake-towers-lake-elucio-al-waleed-paradise"
    }, {
        n: "Al Ramth 10",
        s: "dubai-remraam-al-ramth-al-ramth-10"
    }, {
        n: "Lake District Apartments",
        s: "dubai-international-city-lake-district-lake-district-apartments"
    }, {
        n: "Lootah Residence 2",
        s: "dubai-international-city-phase-2-lootah-residence-2"
    }, {
        n: "Jebel Ali Industrial 1",
        s: "dubai-jebel-ali-jebel-ali-industrial-jebel-ali-industrial-1"
    }, {
        n: "Amwaj 4",
        s: "dubai-jumeirah-beach-residence-amwaj-amwaj-4"
    }, {
        n: "Amwaj 3",
        s: "dubai-jumeirah-beach-residence-amwaj-amwaj-3"
    }, {
        n: "Bahar 4",
        s: "dubai-jumeirah-beach-residence-bahar-bahar-4"
    }, {
        n: "Rimal 2",
        s: "dubai-jumeirah-beach-residence-rimal-rimal-2"
    }, {
        n: "Rimal 4",
        s: "dubai-jumeirah-beach-residence-rimal-rimal-4"
    }, {
        n: "Shams 2",
        s: "dubai-jumeirah-beach-residence-shams-shams-2"
    }, {
        n: "Murjan 2",
        s: "dubai-jumeirah-beach-residence-murjan-murjan-2"
    }, {
        n: "Murjan 1",
        s: "dubai-jumeirah-beach-residence-murjan-murjan-1"
    }, {
        n: "Al Bateen Residence",
        s: "dubai-jumeirah-beach-residence-the-walk-al-bateen-residence"
    }, {
        n: "Sadaf 5",
        s: "dubai-jumeirah-beach-residence-sadaf-sadaf-5"
    }, {
        n: "Sadaf 8",
        s: "dubai-jumeirah-beach-residence-sadaf-sadaf-8"
    }, {
        n: "Jumeirah Business Centre 1",
        s: "dubai-jumeirah-lake-towers-lake-allure-jumeirah-business-centre-1"
    }, {
        n: "Oaks Liwa Heights",
        s: "dubai-jumeirah-lake-towers-lake-allure-oaks-liwa-heights"
    }, {
        n: "Laguna Movenpick",
        s: "dubai-jumeirah-lake-towers-lake-allure-laguna-movenpick"
    }, {
        n: "Jumeirah Lake Offices",
        s: "dubai-jumeirah-lake-towers-lake-allure-jumeirah-lake-offices"
    }, {
        n: "Swiss Tower",
        s: "dubai-jumeirah-lake-towers-lake-allure-swiss-tower"
    }, {
        n: "Almas Tower",
        s: "dubai-jumeirah-lake-towers-lake-almas-east-almas-tower"
    }, {
        n: "Sadaf 2",
        s: "dubai-jumeirah-beach-residence-sadaf-sadaf-2"
    }, {
        n: "Sadaf 1",
        s: "dubai-jumeirah-beach-residence-sadaf-sadaf-1"
    }, {
        n: "East Cluster",
        s: "dubai-jumeirah-heights-loft-cluster-east-cluster"
    }, {
        n: "Fortune Executive",
        s: "dubai-jumeirah-lake-towers-lake-allure-fortune-executive"
    }, {
        n: "Lake Shore Tower",
        s: "dubai-jumeirah-lake-towers-lake-allure-lake-shore-tower"
    }, {
        n: "One Lake Plaza",
        s: "dubai-jumeirah-lake-towers-lake-allure-one-lake-plaza"
    }, {
        n: "Tiffany Tower",
        s: "dubai-jumeirah-lake-towers-lake-allure-tiffany-tower"
    }, {
        n: "Jumeirah Business Centre 3",
        s: "dubai-jumeirah-lake-towers-lake-allure-jumeirah-business-centre-3"
    }, {
        n: "Tower D",
        s: "dubai-jumeirah-heights-the-frond-tower-d"
    }, {
        n: "Concorde Tower",
        s: "dubai-jumeirah-lake-towers-lake-almas-east-concorde-tower"
    }, {
        n: "Indigo Icon",
        s: "dubai-jumeirah-lake-towers-lake-almas-east-indigo-icon"
    }, {
        n: "Saba Tower 2",
        s: "dubai-jumeirah-lake-towers-saba-towers-saba-tower-2"
    }, {
        n: "Bobyan Tower",
        s: "dubai-jumeirah-lake-towers-jumeirah-bay-towers-bobyan-tower"
    }, {
        n: "Green Lake Tower 1",
        s: "dubai-jumeirah-lake-towers-green-lake-towers-green-lake-tower-1"
    }, {
        n: "Armada Tower 3",
        s: "dubai-jumeirah-lake-towers-lake-elucio-armada-tower-3"
    }, {
        n: "O2 Residence",
        s: "dubai-jumeirah-lake-towers-lake-elucio-o2-residence"
    }, {
        n: "New Dubai Gate 1",
        s: "dubai-jumeirah-lake-towers-lake-elucio-new-dubai-gate-1"
    }, {
        n: "Al Ramth 61",
        s: "dubai-remraam-al-ramth-al-ramth-61"
    }, {
        n: "Royal Beach Residence",
        s: "dubai-jumeirah-beach-residence-the-walk-royal-beach-residence"
    }, {
        n: "Sadaf 4",
        s: "dubai-jumeirah-beach-residence-sadaf-sadaf-4"
    }, {
        n: "Jumeirah Business Centre 4",
        s: "dubai-jumeirah-lake-towers-lake-allure-jumeirah-business-centre-4"
    }, {
        n: "V3 Tower",
        s: "dubai-jumeirah-lake-towers-lake-allure-v3-tower"
    }, {
        n: "Jumeirah Lake Apartments",
        s: "dubai-jumeirah-lake-towers-lake-allure-jumeirah-lake-apartments"
    }, {
        n: "Jumeirah Business Centre 6",
        s: "dubai-jumeirah-lake-towers-lake-allure-jumeirah-business-centre-6"
    }, {
        n: "Goldcrest Views 1",
        s: "dubai-jumeirah-lake-towers-lake-allure-goldcrest-views-1"
    }, {
        n: "Tower B",
        s: "dubai-jumeirah-heights-the-frond-tower-b"
    }, {
        n: "Indigo Tower",
        s: "dubai-jumeirah-lake-towers-lake-almas-east-indigo-tower"
    }, {
        n: "Al Sheraa Tower",
        s: "dubai-jumeirah-lake-towers-lake-almas-east-al-sheraa-tower"
    }, {
        n: "Lake Terrace",
        s: "dubai-jumeirah-lake-towers-lake-almas-east-lake-terrace"
    }, {
        n: "Saba Tower 1",
        s: "dubai-jumeirah-lake-towers-saba-towers-saba-tower-1"
    }, {
        n: "Fancy Rose Tower",
        s: "dubai-jumeirah-lake-towers-fancy-rose-fancy-rose-tower"
    }, {
        n: "Jumeirah Bay X1",
        s: "dubai-jumeirah-lake-towers-jumeirah-bay-towers-jumeirah-bay-x1"
    }, {
        n: "Reef Tower",
        s: "dubai-jumeirah-lake-towers-lake-elucio-reef-tower"
    }, {
        n: "MAG 214",
        s: "dubai-jumeirah-lake-towers-lake-elucio-mag-214"
    }, {
        n: "Al Sakran Tower",
        s: "dubai-jumeirah-lake-towers-lake-elucio-al-sakran-tower"
    }, {
        n: "Al Seef Tower 3",
        s: "dubai-jumeirah-lake-towers-al-seef-towers-al-seef-tower-3"
    }, {
        n: "Lakeside Residence",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-lakeside-residence"
    }, {
        n: "Icon Tower 2",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-icon-tower-2"
    }, {
        n: "Vue De Lac 3",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-vue-de-lac-3"
    }, {
        n: "Lake Point Tower",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-lake-point-tower"
    }, {
        n: "HDS Tower",
        s: "dubai-jumeirah-lake-towers-lake-almas-east-hds-tower"
    }, {
        n: "Dubai Arch",
        s: "dubai-jumeirah-lake-towers-lake-almas-east-dubai-arch"
    }, {
        n: "Fancy Rose Apartments",
        s: "dubai-jumeirah-lake-towers-fancy-rose-fancy-rose-apartments"
    }, {
        n: "Jumeirah Bay X3",
        s: "dubai-jumeirah-lake-towers-jumeirah-bay-towers-jumeirah-bay-x3"
    }, {
        n: "New Dubai Gate 2",
        s: "dubai-jumeirah-lake-towers-lake-elucio-new-dubai-gate-2"
    }, {
        n: "Madina Tower",
        s: "dubai-jumeirah-lake-towers-lake-elucio-madina-tower"
    }, {
        n: "Al Seef Tower 2",
        s: "dubai-jumeirah-lake-towers-al-seef-towers-al-seef-tower-2"
    }, {
        n: "Laguna Tower",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-laguna-tower"
    }, {
        n: "Icon Tower 1",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-icon-tower-1"
    }, {
        n: "Vue De Lac 2",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-vue-de-lac-2"
    }, {
        n: "Bonnington Tower",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-bonnington-tower"
    }, {
        n: "Mohammed Ibrahim Tower (J2 Tower)",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-mohammed-ibrahim-tower-j2-tower"
    }, {
        n: "Mazaya Business Avenue BB1",
        s: "dubai-jumeirah-lake-towers-mazaya-business-avenue-mazaya-business-avenue-bb1"
    }, {
        n: "Al Thamam 45",
        s: "dubai-remraam-al-thamam-al-thamam-45"
    }, {
        n: "Al Thamam 16",
        s: "dubai-remraam-al-thamam-al-thamam-16"
    }, {
        n: "Al Thamam 15",
        s: "dubai-remraam-al-thamam-al-thamam-15"
    }, {
        n: "Al Thamam 33",
        s: "dubai-remraam-al-thamam-al-thamam-33"
    }, {
        n: "Al Thamam 04",
        s: "dubai-remraam-al-thamam-al-thamam-04"
    }, {
        n: "Al Thamam 36",
        s: "dubai-remraam-al-thamam-al-thamam-36"
    }, {
        n: "Al Thamam 22",
        s: "dubai-remraam-al-thamam-al-thamam-22"
    }, {
        n: "Al Thamam 24",
        s: "dubai-remraam-al-thamam-al-thamam-24"
    }, {
        n: "Al Thamam 63",
        s: "dubai-remraam-al-thamam-al-thamam-63"
    }, {
        n: "Al Ramth 37",
        s: "dubai-remraam-al-ramth-al-ramth-37"
    }, {
        n: "Al Ramth 16",
        s: "dubai-remraam-al-ramth-al-ramth-16"
    }, {
        n: "Al Ramth 47",
        s: "dubai-remraam-al-ramth-al-ramth-47"
    }, {
        n: "Al Ramth 55",
        s: "dubai-remraam-al-ramth-al-ramth-55"
    }, {
        n: "Goldcrest Executive",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-goldcrest-executive"
    }, {
        n: "Fortune Tower",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-fortune-tower"
    }, {
        n: "Al Thamam 46",
        s: "dubai-remraam-al-thamam-al-thamam-46"
    }, {
        n: "Al Thamam 20",
        s: "dubai-remraam-al-thamam-al-thamam-20"
    }, {
        n: "Al Thamam 07",
        s: "dubai-remraam-al-thamam-al-thamam-07"
    }, {
        n: "Al Thamam 61",
        s: "dubai-remraam-al-thamam-al-thamam-61"
    }, {
        n: "Al Thamam 38",
        s: "dubai-remraam-al-thamam-al-thamam-38"
    }, {
        n: "Al Thamam 26",
        s: "dubai-remraam-al-thamam-al-thamam-26"
    }, {
        n: "Al Thamam 57",
        s: "dubai-remraam-al-thamam-al-thamam-57"
    }, {
        n: "Al Thamam 17",
        s: "dubai-remraam-al-thamam-al-thamam-17"
    }, {
        n: "Al Thamam 28",
        s: "dubai-remraam-al-thamam-al-thamam-28"
    }, {
        n: "Al Thamam 49",
        s: "dubai-remraam-al-thamam-al-thamam-49"
    }, {
        n: "Al Ramth 63",
        s: "dubai-remraam-al-ramth-al-ramth-63"
    }, {
        n: "Al Ramth 53",
        s: "dubai-remraam-al-ramth-al-ramth-53"
    }, {
        n: "Al Ramth 59",
        s: "dubai-remraam-al-ramth-al-ramth-59"
    }, {
        n: "Al Ramth 03",
        s: "dubai-remraam-al-ramth-al-ramth-03"
    }, {
        n: "Al Ramth 15",
        s: "dubai-remraam-al-ramth-al-ramth-15"
    }, {
        n: "Al Ramth 40",
        s: "dubai-remraam-al-ramth-al-ramth-40"
    }, {
        n: "Al Ramth 35",
        s: "dubai-remraam-al-ramth-al-ramth-35"
    }, {
        n: "Al Ramth 06",
        s: "dubai-remraam-al-ramth-al-ramth-06"
    }, {
        n: "Al Ramth 32",
        s: "dubai-remraam-al-ramth-al-ramth-32"
    }, {
        n: "Cadi Residence 5",
        s: "dubai-jumeirah-village-circle-cadi-residences-cadi-residence-5"
    }, {
        n: "Al Yousuf Towers",
        s: "dubai-jumeirah-village-circle-district-12-al-yousuf-towers"
    }, {
        n: "Jouri 4 Tower B",
        s: "dubai-jumeirah-village-circle-jouri-residence-4-jouri-4-tower-b"
    }, {
        n: "Quattro Q2",
        s: "dubai-jumeirah-village-circle-quattro-quattro-q2"
    }, {
        n: "Siena 2",
        s: "dubai-jumeirah-village-circle-tuscan-residences-siena-2"
    }, {
        n: "Gardenia Residency 2",
        s: "dubai-jumeirah-village-circle-gardenia-residency-gardenia-residency-2"
    }, {
        n: "Serena 2",
        s: "dubai-jumeirah-village-circle-serena-residence-serena-2"
    }, {
        n: "Summer",
        s: "dubai-jumeirah-village-circle-seasons-community-summer"
    }, {
        n: "Wind Tower 2",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-wind-tower-2"
    }, {
        n: "HDS Business Centre",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-hds-business-centre"
    }, {
        n: "Preatoni Tower",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-preatoni-tower"
    }, {
        n: "Goldcrest Views 2",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-goldcrest-views-2"
    }, {
        n: "Al Thamam 37",
        s: "dubai-remraam-al-thamam-al-thamam-37"
    }, {
        n: "Al Thamam 30",
        s: "dubai-remraam-al-thamam-al-thamam-30"
    }, {
        n: "Al Thamam 08",
        s: "dubai-remraam-al-thamam-al-thamam-08"
    }, {
        n: "Al Thamam 41",
        s: "dubai-remraam-al-thamam-al-thamam-41"
    }, {
        n: "Al Thamam 35",
        s: "dubai-remraam-al-thamam-al-thamam-35"
    }, {
        n: "Al Thamam 02",
        s: "dubai-remraam-al-thamam-al-thamam-02"
    }, {
        n: "Al Thamam 10",
        s: "dubai-remraam-al-thamam-al-thamam-10"
    }, {
        n: "Al Thamam 42",
        s: "dubai-remraam-al-thamam-al-thamam-42"
    }, {
        n: "Al Thamam 43",
        s: "dubai-remraam-al-thamam-al-thamam-43"
    }, {
        n: "Al Thamam 06",
        s: "dubai-remraam-al-thamam-al-thamam-06"
    }, {
        n: "Al Ramth 28",
        s: "dubai-remraam-al-ramth-al-ramth-28"
    }, {
        n: "Al Ramth 57",
        s: "dubai-remraam-al-ramth-al-ramth-57"
    }, {
        n: "Al Ramth 29",
        s: "dubai-remraam-al-ramth-al-ramth-29"
    }, {
        n: "Al Ramth 51",
        s: "dubai-remraam-al-ramth-al-ramth-51"
    }, {
        n: "Al Ramth 65",
        s: "dubai-remraam-al-ramth-al-ramth-65"
    }, {
        n: "Al Ramth 21",
        s: "dubai-remraam-al-ramth-al-ramth-21"
    }, {
        n: "Al Ramth 05",
        s: "dubai-remraam-al-ramth-al-ramth-05"
    }, {
        n: "Al Ramth 04",
        s: "dubai-remraam-al-ramth-al-ramth-04"
    }, {
        n: "Al Ramth 08",
        s: "dubai-remraam-al-ramth-al-ramth-08"
    }, {
        n: "Al Ramth 30",
        s: "dubai-remraam-al-ramth-al-ramth-30"
    }, {
        n: "Cadi Residence 3",
        s: "dubai-jumeirah-village-circle-cadi-residences-cadi-residence-3"
    }, {
        n: "Judi Palace B",
        s: "dubai-jumeirah-village-circle-judi-judi-palace-b"
    }, {
        n: "Magnolia 1",
        s: "dubai-jumeirah-village-circle-emirates-gardens-2-magnolia-1"
    }, {
        n: "Mulberry 1",
        s: "dubai-jumeirah-village-circle-emirates-gardens-2-mulberry-1"
    }, {
        n: "Belgravia 2",
        s: "dubai-jumeirah-village-circle-belgravia-belgravia-2"
    }, {
        n: "Jouri 5 Tower C",
        s: "dubai-jumeirah-village-circle-jouri-residence-5-jouri-5-tower-c"
    }, {
        n: "Florence 1",
        s: "dubai-jumeirah-village-circle-tuscan-residences-florence-1"
    }, {
        n: "Al Thamam 55",
        s: "dubai-remraam-al-thamam-al-thamam-55"
    }, {
        n: "Al Thamam 34",
        s: "dubai-remraam-al-thamam-al-thamam-34"
    }, {
        n: "Al Thamam 59",
        s: "dubai-remraam-al-thamam-al-thamam-59"
    }, {
        n: "Al Thamam 32",
        s: "dubai-remraam-al-thamam-al-thamam-32"
    }, {
        n: "Al Thamam 51",
        s: "dubai-remraam-al-thamam-al-thamam-51"
    }, {
        n: "Al Thamam 05",
        s: "dubai-remraam-al-thamam-al-thamam-05"
    }, {
        n: "Al Thamam 12",
        s: "dubai-remraam-al-thamam-al-thamam-12"
    }, {
        n: "Al Thamam 65",
        s: "dubai-remraam-al-thamam-al-thamam-65"
    }, {
        n: "Al Thamam 18",
        s: "dubai-remraam-al-thamam-al-thamam-18"
    }, {
        n: "Al Thamam 23",
        s: "dubai-remraam-al-thamam-al-thamam-23"
    }, {
        n: "Al Thamam 47",
        s: "dubai-remraam-al-thamam-al-thamam-47"
    }, {
        n: "Al Ramth 39",
        s: "dubai-remraam-al-ramth-al-ramth-39"
    }, {
        n: "Al Ramth 43",
        s: "dubai-remraam-al-ramth-al-ramth-43"
    }, {
        n: "Al Ramth 45",
        s: "dubai-remraam-al-ramth-al-ramth-45"
    }, {
        n: "Al Ramth 31",
        s: "dubai-remraam-al-ramth-al-ramth-31"
    }, {
        n: "Al Ramth 23",
        s: "dubai-remraam-al-ramth-al-ramth-23"
    }, {
        n: "Al Ramth 09",
        s: "dubai-remraam-al-ramth-al-ramth-09"
    }, {
        n: "Al Ramth 07",
        s: "dubai-remraam-al-ramth-al-ramth-07"
    }, {
        n: "Al Ramth 02",
        s: "dubai-remraam-al-ramth-al-ramth-02"
    }, {
        n: "Al Ramth 11",
        s: "dubai-remraam-al-ramth-al-ramth-11"
    }, {
        n: "Cadi Residence 1",
        s: "dubai-jumeirah-village-circle-cadi-residences-cadi-residence-1"
    }, {
        n: "Mulberry 2",
        s: "dubai-jumeirah-village-circle-emirates-gardens-2-mulberry-2"
    }, {
        n: "Belgravia 3",
        s: "dubai-jumeirah-village-circle-belgravia-belgravia-3"
    }, {
        n: "Jouri 5 Tower A",
        s: "dubai-jumeirah-village-circle-jouri-residence-5-jouri-5-tower-a"
    }, {
        n: "Arezzo 1",
        s: "dubai-jumeirah-village-circle-tuscan-residences-arezzo-1"
    }, {
        n: "Al Muhra 2",
        s: "dubai-jumeirah-village-circle-al-muhra-al-muhra-2"
    }, {
        n: "Diamond Arch 2",
        s: "dubai-jumeirah-village-circle-diamond-arch-diamond-arch-2"
    }, {
        n: "Ghalia",
        s: "dubai-jumeirah-village-circle-district-18-ghalia"
    }, {
        n: "Noora Residence 2",
        s: "dubai-jumeirah-village-circle-noora-residence-noora-residence-2"
    }, {
        n: "The Lawns 1",
        s: "dubai-jumeirah-village-circle-the-lawns-the-lawns-1"
    }, {
        n: "Casa Royale I",
        s: "dubai-jumeirah-village-circle-casa-royale-casa-royale-i"
    }, {
        n: "Grand Paradise II",
        s: "dubai-jumeirah-village-circle-grand-paradise-grand-paradise-ii"
    }, {
        n: "Al Thamam 13",
        s: "dubai-remraam-al-thamam-al-thamam-13"
    }, {
        n: "Al Thamam 09",
        s: "dubai-remraam-al-thamam-al-thamam-09"
    }, {
        n: "Al Thamam 11",
        s: "dubai-remraam-al-thamam-al-thamam-11"
    }, {
        n: "Al Thamam 25",
        s: "dubai-remraam-al-thamam-al-thamam-25"
    }, {
        n: "Al Thamam 14",
        s: "dubai-remraam-al-thamam-al-thamam-14"
    }, {
        n: "Al Thamam 03",
        s: "dubai-remraam-al-thamam-al-thamam-03"
    }, {
        n: "Al Thamam 39",
        s: "dubai-remraam-al-thamam-al-thamam-39"
    }, {
        n: "Al Thamam 53",
        s: "dubai-remraam-al-thamam-al-thamam-53"
    }, {
        n: "Al Thamam 01",
        s: "dubai-remraam-al-thamam-al-thamam-01"
    }, {
        n: "Al Ramth 41",
        s: "dubai-remraam-al-ramth-al-ramth-41"
    }, {
        n: "Al Ramth 33",
        s: "dubai-remraam-al-ramth-al-ramth-33"
    }, {
        n: "Al Ramth 01",
        s: "dubai-remraam-al-ramth-al-ramth-01"
    }, {
        n: "Al Ramth 22",
        s: "dubai-remraam-al-ramth-al-ramth-22"
    }, {
        n: "Al Ramth 26",
        s: "dubai-remraam-al-ramth-al-ramth-26"
    }, {
        n: "Al Ramth 27",
        s: "dubai-remraam-al-ramth-al-ramth-27"
    }, {
        n: "Al Ramth 67",
        s: "dubai-remraam-al-ramth-al-ramth-67"
    }, {
        n: "Al Ramth 19",
        s: "dubai-remraam-al-ramth-al-ramth-19"
    }, {
        n: "Cadi Residence 2",
        s: "dubai-jumeirah-village-circle-cadi-residences-cadi-residence-2"
    }, {
        n: "Judi Palace A",
        s: "dubai-jumeirah-village-circle-judi-judi-palace-a"
    }, {
        n: "Magnolia 2",
        s: "dubai-jumeirah-village-circle-emirates-gardens-2-magnolia-2"
    }, {
        n: "Jouri 4 Tower A",
        s: "dubai-jumeirah-village-circle-jouri-residence-4-jouri-4-tower-a"
    }, {
        n: "Belgravia 1",
        s: "dubai-jumeirah-village-circle-belgravia-belgravia-1"
    }, {
        n: "Jouri 5 Tower B",
        s: "dubai-jumeirah-village-circle-jouri-residence-5-jouri-5-tower-b"
    }, {
        n: "Siena 1",
        s: "dubai-jumeirah-village-circle-tuscan-residences-siena-1"
    }, {
        n: "Gardenia Residency 1",
        s: "dubai-jumeirah-village-circle-gardenia-residency-gardenia-residency-1"
    }, {
        n: "Metropolis Lofts 2",
        s: "dubai-jumeirah-village-circle-metropolis-lofts-metropolis-lofts-2"
    }, {
        n: "Eden Residence 2",
        s: "dubai-jumeirah-village-circle-eden-residence-eden-residence-2"
    }, {
        n: "The Lawns 2",
        s: "dubai-jumeirah-village-circle-the-lawns-the-lawns-2"
    }, {
        n: "Casa Royale II",
        s: "dubai-jumeirah-village-circle-casa-royale-casa-royale-ii"
    }, {
        n: "Jouri 3 Tower A",
        s: "dubai-jumeirah-village-circle-jouri-residence-3-jouri-3-tower-a"
    }, {
        n: "Jouri 1 Tower C",
        s: "dubai-jumeirah-village-circle-jouri-residence-1-jouri-1-tower-c"
    }, {
        n: "Winter",
        s: "dubai-jumeirah-village-circle-seasons-community-winter"
    }, {
        n: "Jehaan Residence 7",
        s: "dubai-jumeirah-village-circle-jehaan-residences-jehaan-residence-7"
    }, {
        n: "Jehaan Residence 9",
        s: "dubai-jumeirah-village-circle-jehaan-residences-jehaan-residence-9"
    }, {
        n: "Jouri 4 Tower C",
        s: "dubai-jumeirah-village-circle-jouri-residence-4-jouri-4-tower-c"
    }, {
        n: "Quattro Hotel & Business Park",
        s: "dubai-jumeirah-village-circle-quattro-quattro-hotel-business-park"
    }, {
        n: "Florence 2",
        s: "dubai-jumeirah-village-circle-tuscan-residences-florence-2"
    }, {
        n: "Arezzo 2",
        s: "dubai-jumeirah-village-circle-tuscan-residences-arezzo-2"
    }, {
        n: "Diamond Arch 1",
        s: "dubai-jumeirah-village-circle-diamond-arch-diamond-arch-1"
    }, {
        n: "Serena 1",
        s: "dubai-jumeirah-village-circle-serena-residence-serena-1"
    }, {
        n: "Eden Residence 1",
        s: "dubai-jumeirah-village-circle-eden-residence-eden-residence-1"
    }, {
        n: "The Lawns 4",
        s: "dubai-jumeirah-village-circle-the-lawns-the-lawns-4"
    }, {
        n: "Grand Paradise I",
        s: "dubai-jumeirah-village-circle-grand-paradise-grand-paradise-i"
    }, {
        n: "Spring",
        s: "dubai-jumeirah-village-circle-seasons-community-spring"
    }, {
        n: "Jouri 2 Tower A",
        s: "dubai-jumeirah-village-circle-jouri-residence-2-jouri-2-tower-a"
    }, {
        n: "Jehaan Residence 11",
        s: "dubai-jumeirah-village-circle-jehaan-residences-jehaan-residence-11"
    }, {
        n: "Jehaan Residence 12",
        s: "dubai-jumeirah-village-circle-jehaan-residences-jehaan-residence-12"
    }, {
        n: "Jouri 6 Tower B",
        s: "dubai-jumeirah-village-circle-jouri-residence-6-jouri-6-tower-b"
    }, {
        n: "Mirabella 2",
        s: "dubai-jumeirah-village-circle-mirabella-mirabella-2"
    }, {
        n: "Mirabella 4",
        s: "dubai-jumeirah-village-circle-mirabella-mirabella-4"
    }, {
        n: "Reliance 2",
        s: "dubai-jumeirah-village-circle-reliance-towers-reliance-2"
    }, {
        n: "Rose 1",
        s: "dubai-jumeirah-village-circle-emirates-gardens-1-rose-1"
    }, {
        n: "Gardenia 1",
        s: "dubai-jumeirah-village-circle-emirates-gardens-1-gardenia-1"
    }, {
        n: "Indigo Ville 3",
        s: "dubai-jumeirah-village-circle-indigo-ville-indigo-ville-3"
    }, {
        n: "Silver Stallion Towers 2",
        s: "dubai-jumeirah-village-triangle-silver-stallion-towers-silver-stallion-towers-2"
    }, {
        n: "Arabella Townhouses 2",
        s: "dubai-mudon-arabella-townhouses-arabella-townhouses-2"
    }, {
        n: "Iris Mist",
        s: "dubai-maritime-city-harbour-residences-iris-mist"
    }, {
        n: "Foxhill 5",
        s: "dubai-motor-city-foxhill-foxhill-5"
    }, {
        n: "Norton Court 1",
        s: "dubai-motor-city-norton-court-norton-court-1"
    }, {
        n: "Noora Residence 1",
        s: "dubai-jumeirah-village-circle-noora-residence-noora-residence-1"
    }, {
        n: "The Lawns 5",
        s: "dubai-jumeirah-village-circle-the-lawns-the-lawns-5"
    }, {
        n: "Jouri 3 Tower B",
        s: "dubai-jumeirah-village-circle-jouri-residence-3-jouri-3-tower-b"
    }, {
        n: "Jouri 1 Tower A",
        s: "dubai-jumeirah-village-circle-jouri-residence-1-jouri-1-tower-a"
    }, {
        n: "Jehaan Residence 8",
        s: "dubai-jumeirah-village-circle-jehaan-residences-jehaan-residence-8"
    }, {
        n: "Reef Residence",
        s: "dubai-jumeirah-village-circle-district-13-reef-residence"
    }, {
        n: "Mirabella 1",
        s: "dubai-jumeirah-village-circle-mirabella-mirabella-1"
    }, {
        n: "Mirabella 5",
        s: "dubai-jumeirah-village-circle-mirabella-mirabella-5"
    }, {
        n: "Saleh Bin Lahej Building Block A",
        s: "dubai-jumeirah-village-circle-saleh-bin-lahej-building-saleh-bin-lahej-building-block-a"
    }, {
        n: "Reliance 12",
        s: "dubai-jumeirah-village-circle-reliance-towers-reliance-12"
    }, {
        n: "Lavender 1",
        s: "dubai-jumeirah-village-circle-emirates-gardens-1-lavender-1"
    }, {
        n: "Diamond Views 2",
        s: "dubai-jumeirah-village-circle-diamond-views-diamond-views-2"
    }, {
        n: "Indigo Ville 4",
        s: "dubai-jumeirah-village-circle-indigo-ville-indigo-ville-4"
    }, {
        n: "Silver Stallion Towers 1",
        s: "dubai-jumeirah-village-triangle-silver-stallion-towers-silver-stallion-towers-1"
    }, {
        n: "Arabella Townhouses 3",
        s: "dubai-mudon-arabella-townhouses-arabella-townhouses-3"
    }, {
        n: "Verde Tower",
        s: "dubai-maritime-city-harbour-residences-verde-tower"
    }, {
        n: "Foxhill 2",
        s: "dubai-motor-city-foxhill-foxhill-2"
    }, {
        n: "Foxhill 9",
        s: "dubai-motor-city-foxhill-foxhill-9"
    }, {
        n: "Norton Court 4",
        s: "dubai-motor-city-norton-court-norton-court-4"
    }, {
        n: "Shakespeare Circus 2",
        s: "dubai-motor-city-shakespeare-circus-shakespeare-circus-2"
    }, {
        n: "Al Mashroom 5",
        s: "dubai-al-muhaisnah-al-muhaisnah-4-al-mashroom-5"
    }, {
        n: "Jouri 3 Tower C",
        s: "dubai-jumeirah-village-circle-jouri-residence-3-jouri-3-tower-c"
    }, {
        n: "Jouri 1 Tower B",
        s: "dubai-jumeirah-village-circle-jouri-residence-1-jouri-1-tower-b"
    }, {
        n: "Jouri 2 Tower C",
        s: "dubai-jumeirah-village-circle-jouri-residence-2-jouri-2-tower-c"
    }, {
        n: "Jehaan Residence 1",
        s: "dubai-jumeirah-village-circle-jehaan-residences-jehaan-residence-1"
    }, {
        n: "Jehaan Residence 6",
        s: "dubai-jumeirah-village-circle-jehaan-residences-jehaan-residence-6"
    }, {
        n: "Jehaan Residence 2",
        s: "dubai-jumeirah-village-circle-jehaan-residences-jehaan-residence-2"
    }, {
        n: "Jouri 6 Tower A",
        s: "dubai-jumeirah-village-circle-jouri-residence-6-jouri-6-tower-a"
    }, {
        n: "La Riviera Estate A",
        s: "dubai-jumeirah-village-circle-la-riviera-estate-la-riviera-estate-a"
    }, {
        n: "Reliance 8",
        s: "dubai-jumeirah-village-circle-reliance-towers-reliance-8"
    }, {
        n: "Lavender 2",
        s: "dubai-jumeirah-village-circle-emirates-gardens-1-lavender-2"
    }, {
        n: "Indigo Ville 6",
        s: "dubai-jumeirah-village-circle-indigo-ville-indigo-ville-6"
    }, {
        n: "Indigo Ville 8",
        s: "dubai-jumeirah-village-circle-indigo-ville-indigo-ville-8"
    }, {
        n: "The Imperial Residence A",
        s: "dubai-jumeirah-village-triangle-the-imperial-residence-the-imperial-residence-a"
    }, {
        n: "Paramount Hotel",
        s: "dubai-maritime-city-jumeirah-waterfront-paramount-hotel"
    }, {
        n: "Widcombe House 4",
        s: "dubai-motor-city-widcombe-house-widcombe-house-4"
    }, {
        n: "Business Park Motor City",
        s: "dubai-motor-city-dubai-autodrome-and-business-park-business-park-motor-city"
    }, {
        n: "Apex Atrium",
        s: "dubai-motor-city-dubai-autodrome-and-business-park-apex-atrium"
    }, {
        n: "Dubai Autodrome Retail Plaza",
        s: "dubai-motor-city-dubai-autodrome-and-business-park-dubai-autodrome-retail-plaza"
    }, {
        n: "Jehaan Residence 3",
        s: "dubai-jumeirah-village-circle-jehaan-residences-jehaan-residence-3"
    }, {
        n: "Jehaan Residence 10",
        s: "dubai-jumeirah-village-circle-jehaan-residences-jehaan-residence-10"
    }, {
        n: "Jehaan Residence 5",
        s: "dubai-jumeirah-village-circle-jehaan-residences-jehaan-residence-5"
    }, {
        n: "Mirabella 3",
        s: "dubai-jumeirah-village-circle-mirabella-mirabella-3"
    }, {
        n: "La Riviera Estate B",
        s: "dubai-jumeirah-village-circle-la-riviera-estate-la-riviera-estate-b"
    }, {
        n: "Reliance 7",
        s: "dubai-jumeirah-village-circle-reliance-towers-reliance-7"
    }, {
        n: "Rose 2",
        s: "dubai-jumeirah-village-circle-emirates-gardens-1-rose-2"
    }, {
        n: "Indigo Ville 1",
        s: "dubai-jumeirah-village-circle-indigo-ville-indigo-ville-1"
    }, {
        n: "Indigo Ville 7",
        s: "dubai-jumeirah-village-circle-indigo-ville-indigo-ville-7"
    }, {
        n: "The Imperial Residence C",
        s: "dubai-jumeirah-village-triangle-the-imperial-residence-the-imperial-residence-c"
    }, {
        n: "Arabella Townhouses 1",
        s: "dubai-mudon-arabella-townhouses-arabella-townhouses-1"
    }, {
        n: "Amwaj Tower",
        s: "dubai-maritime-city-harbour-residences-amwaj-tower"
    }, {
        n: "Foxhill 4",
        s: "dubai-motor-city-foxhill-foxhill-4"
    }, {
        n: "Foxhill 3",
        s: "dubai-motor-city-foxhill-foxhill-3"
    }, {
        n: "Norton Court 2",
        s: "dubai-motor-city-norton-court-norton-court-2"
    }, {
        n: "Shakespeare Circus 1",
        s: "dubai-motor-city-shakespeare-circus-shakespeare-circus-1"
    }, {
        n: "Townhouses",
        s: "dubai-motor-city-green-community-motor-city-townhouses"
    }, {
        n: "Casa Flores",
        s: "dubai-motor-city-green-community-motor-city-casa-flores"
    }, {
        n: "Mirabella 8",
        s: "dubai-jumeirah-village-circle-mirabella-mirabella-8"
    }, {
        n: "Mirabella 7",
        s: "dubai-jumeirah-village-circle-mirabella-mirabella-7"
    }, {
        n: "Reliance 6",
        s: "dubai-jumeirah-village-circle-reliance-towers-reliance-6"
    }, {
        n: "Gardenia 2",
        s: "dubai-jumeirah-village-circle-emirates-gardens-1-gardenia-2"
    }, {
        n: "Diamond Views 1",
        s: "dubai-jumeirah-village-circle-diamond-views-diamond-views-1"
    }, {
        n: "Indigo Ville 5",
        s: "dubai-jumeirah-village-circle-indigo-ville-indigo-ville-5"
    }, {
        n: "Indigo Ville 2",
        s: "dubai-jumeirah-village-circle-indigo-ville-indigo-ville-2"
    }, {
        n: "The Imperial Residence B",
        s: "dubai-jumeirah-village-triangle-the-imperial-residence-the-imperial-residence-b"
    }, {
        n: "Kensington Krystal",
        s: "dubai-maritime-city-harbour-residences-kensington-krystal"
    }, {
        n: "Foxhill 1",
        s: "dubai-motor-city-foxhill-foxhill-1"
    }, {
        n: "Foxhill 8",
        s: "dubai-motor-city-foxhill-foxhill-8"
    }, {
        n: "Norton Court 3",
        s: "dubai-motor-city-norton-court-norton-court-3"
    }, {
        n: "Regent House 2",
        s: "dubai-motor-city-regent-house-regent-house-2"
    }, {
        n: "Widcombe House 2",
        s: "dubai-motor-city-widcombe-house-widcombe-house-2"
    }, {
        n: "Eastern Court",
        s: "dubai-motor-city-uptown-motorcity-eastern-court"
    }, {
        n: "Bungalow Area",
        s: "dubai-motor-city-green-community-motor-city-bungalow-area"
    }, {
        n: "Casa Familia",
        s: "dubai-motor-city-green-community-motor-city-casa-familia"
    }, {
        n: "Bennett House 1",
        s: "dubai-motor-city-bennett-house-bennett-house-1"
    }, {
        n: "Weston Court 2",
        s: "dubai-motor-city-weston-court-weston-court-2"
    }, {
        n: "Barton House 1",
        s: "dubai-motor-city-barton-house-barton-house-1"
    }, {
        n: "Golf Vista 1",
        s: "dubai-damac-hills-akoya-by-damac-golf-vista-golf-vista-1"
    }, {
        n: "Foxhill 7",
        s: "dubai-motor-city-foxhill-foxhill-7"
    }, {
        n: "Foxhill 6",
        s: "dubai-motor-city-foxhill-foxhill-6"
    }, {
        n: "Regent House 1",
        s: "dubai-motor-city-regent-house-regent-house-1"
    }, {
        n: "Widcombe House 1",
        s: "dubai-motor-city-widcombe-house-widcombe-house-1"
    }, {
        n: "Windsor Crescent",
        s: "dubai-motor-city-uptown-motorcity-windsor-crescent"
    }, {
        n: "Daytona House",
        s: "dubai-motor-city-green-community-motor-city-daytona-house"
    }, {
        n: "Luxury Villa",
        s: "dubai-motor-city-green-community-motor-city-luxury-villa"
    }, {
        n: "Sherlock House 2",
        s: "dubai-motor-city-sherlock-house-sherlock-house-2"
    }, {
        n: "Loreto 1 B",
        s: "dubai-damac-hills-akoya-by-damac-loreto-loreto-1-b"
    }, {
        n: "Sherlock House 3",
        s: "dubai-motor-city-sherlock-house-sherlock-house-3"
    }, {
        n: "Marlowe House 2",
        s: "dubai-motor-city-marlowe-house-marlowe-house-2"
    }, {
        n: "New Bridge Hills 3",
        s: "dubai-motor-city-new-bridge-hills-new-bridge-hills-3"
    }, {
        n: "Abbey Crescent 2",
        s: "dubai-motor-city-abbey-crescent-abbey-crescent-2"
    }, {
        n: "Sherlock Circus 3",
        s: "dubai-motor-city-sherlock-circus-sherlock-circus-3"
    }, {
        n: "Tecom Tower 2",
        s: "dubai-barsha-heights-tecom-tecom-two-towers-tecom-tower-2"
    }, {
        n: "Green View 2",
        s: "dubai-barsha-heights-tecom-green-view-green-view-2"
    }, {
        n: "Art Tower 4",
        s: "dubai-barsha-heights-tecom-art-towers-art-tower-4"
    }, {
        n: "Al Fahad Tower 2",
        s: "dubai-barsha-heights-tecom-al-fahad-towers-al-fahad-tower-2"
    }, {
        n: "Linear Gardens",
        s: "dubai-mirdif-mirdif-hills-linear-gardens"
    }, {
        n: "Ras Al Khor Industrial 2",
        s: "dubai-ras-al-khor-ras-al-khor-industrial-ras-al-khor-industrial-2"
    }, {
        n: "Arno A",
        s: "dubai-the-views-arno-arno-a"
    }, {
        n: "Panorama at the Views Tower 1",
        s: "dubai-the-views-panorama-at-the-views-panorama-at-the-views-tower-1"
    }, {
        n: "The Links Golf Apartments",
        s: "dubai-the-views-the-links-the-links-golf-apartments"
    }, {
        n: "Glitz 2",
        s: "dubai-dubai-studio-city-glitz-glitz-2"
    }, {
        n: "District One Villas",
        s: "dubai-mohammed-bin-rashid-city-district-one-district-one-villas"
    }, {
        n: "Quad Homes",
        s: "dubai-mohammed-bin-rashid-city-sobha-hartland-quad-homes"
    }, {
        n: "The Hartland Villas",
        s: "dubai-mohammed-bin-rashid-city-sobha-hartland-the-hartland-villas"
    }, {
        n: "Frond E",
        s: "dubai-palm-jebel-ali-signature-villas-frond-e"
    }, {
        n: "Loreto 3 A",
        s: "dubai-damac-hills-akoya-by-damac-loreto-loreto-3-a"
    }, {
        n: "Bennett House 2",
        s: "dubai-motor-city-bennett-house-bennett-house-2"
    }, {
        n: "Weston Court 1",
        s: "dubai-motor-city-weston-court-weston-court-1"
    }, {
        n: "New Bridge Hills 2",
        s: "dubai-motor-city-new-bridge-hills-new-bridge-hills-2"
    }, {
        n: "Abbey Crescent 4",
        s: "dubai-motor-city-abbey-crescent-abbey-crescent-4"
    }, {
        n: "Manazel Nad Al Shiba",
        s: "dubai-nadd-al-sheba-nad-al-sheba-1-manazel-nad-al-shiba"
    }, {
        n: "Tower A",
        s: "dubai-barsha-heights-tecom-two-towers-tower-a"
    }, {
        n: "Gate Apartments",
        s: "dubai-mirdif-uptown-mirdif-gate-apartments"
    }, {
        n: "The Vertical Villas",
        s: "dubai-mirdif-mirdif-hills-the-vertical-villas"
    }, {
        n: "Noor Plaza",
        s: "dubai-ras-al-khor-ras-al-khor-industrial-noor-plaza"
    }, {
        n: "Arno B",
        s: "dubai-the-views-arno-arno-b"
    }, {
        n: "Golf Tower 3",
        s: "dubai-the-views-golf-towers-golf-tower-3"
    }, {
        n: "Panorama at the Views Tower 2",
        s: "dubai-the-views-panorama-at-the-views-panorama-at-the-views-tower-2"
    }, {
        n: "The Links Villas",
        s: "dubai-the-views-the-links-the-links-villas"
    }, {
        n: "Una Riverside Residence",
        s: "dubai-the-views-una-una-riverside-residence"
    }, {
        n: "Glitz 3",
        s: "dubai-dubai-studio-city-glitz-glitz-3"
    }, {
        n: "District One Mansions",
        s: "dubai-mohammed-bin-rashid-city-district-one-district-one-mansions"
    }, {
        n: "Hartland Garden Apartments",
        s: "dubai-mohammed-bin-rashid-city-sobha-hartland-hartland-garden-apartments"
    }, {
        n: "Golf Promenade 5A",
        s: "dubai-damac-hills-akoya-by-damac-golf-promenade-golf-promenade-5a"
    }, {
        n: "Marlowe House 1",
        s: "dubai-motor-city-marlowe-house-marlowe-house-1"
    }, {
        n: "New Bridge Hills 4",
        s: "dubai-motor-city-new-bridge-hills-new-bridge-hills-4"
    }, {
        n: "Abbey Crescent 3",
        s: "dubai-motor-city-abbey-crescent-abbey-crescent-3"
    }, {
        n: "Sherlock Circus 2",
        s: "dubai-motor-city-sherlock-circus-sherlock-circus-2"
    }, {
        n: "Al Thuraya Tower 2",
        s: "dubai-barsha-heights-tecom-al-thuraya-towers-al-thuraya-tower-2"
    }, {
        n: "Media Rotana Hotel Tower 2",
        s: "dubai-barsha-heights-tecom-media-rotana-hotel-towers-media-rotana-hotel-tower-2"
    }, {
        n: "Garden Apartments",
        s: "dubai-mirdif-uptown-mirdif-garden-apartments"
    }, {
        n: "Al Multaqa Avenue",
        s: "dubai-mirdif-mirdif-hills-al-multaqa-avenue"
    }, {
        n: "Mirdif 35",
        s: "dubai-mirdif-mushraif-mirdif-35"
    }, {
        n: "Samari Residences",
        s: "dubai-ras-al-khor-ras-al-khor-industrial-samari-residences"
    }, {
        n: "The Fresh Market",
        s: "dubai-ras-al-khor-ras-al-khor-industrial-the-fresh-market"
    }, {
        n: "Golf Tower 2",
        s: "dubai-the-views-golf-towers-golf-tower-2"
    }, {
        n: "Travo Tower A",
        s: "dubai-the-views-travo-travo-tower-a"
    }, {
        n: "The Links West Tower",
        s: "dubai-the-views-the-links-the-links-west-tower"
    }, {
        n: "Al Fattan Sky Tower 1",
        s: "dubai-umm-ramool-al-fattan-sky-towers-al-fattan-sky-tower-1"
    }, {
        n: "The Fairways East",
        s: "dubai-the-views-the-fairways-the-fairways-east"
    }, {
        n: "Olivara Residences 1",
        s: "dubai-dubai-studio-city-olivara-residences-olivara-residences-1"
    }, {
        n: "Turia Tower B",
        s: "dubai-the-views-turia-turia-tower-b"
    }, {
        n: "The Terraces",
        s: "dubai-mohammed-bin-rashid-city-sobha-hartland-the-terraces"
    }, {
        n: "MAG Eye",
        s: "dubai-mohammed-bin-rashid-city-district-7-mag-eye"
    }, {
        n: "Barton House 2",
        s: "dubai-motor-city-barton-house-barton-house-2"
    }, {
        n: "New Bridge Hills 1",
        s: "dubai-motor-city-new-bridge-hills-new-bridge-hills-1"
    }, {
        n: "Claverton House 1",
        s: "dubai-motor-city-claverton-house-claverton-house-1"
    }, {
        n: "Al Noor 1",
        s: "dubai-barsha-heights-tecom-al-noor-towers-al-noor-1"
    }, {
        n: "Tower B",
        s: "dubai-barsha-heights-tecom-two-towers-tower-b"
    }, {
        n: "Art Tower 3",
        s: "dubai-barsha-heights-tecom-art-towers-art-tower-3"
    }, {
        n: "Al Fahad Tower 1",
        s: "dubai-barsha-heights-tecom-al-fahad-towers-al-fahad-tower-1"
    }, {
        n: "Terrace Apartments",
        s: "dubai-mirdif-uptown-mirdif-terrace-apartments"
    }, {
        n: "Nasayem Avenue",
        s: "dubai-mirdif-mirdif-hills-nasayem-avenue"
    }, {
        n: "Mushraif",
        s: "dubai-mirdif-mushrif-village-mushraif"
    }, {
        n: "Ras Al Khor Industrial 1",
        s: "dubai-ras-al-khor-ras-al-khor-industrial-ras-al-khor-industrial-1"
    }, {
        n: "Grand Residence",
        s: "dubai-ras-al-khor-ras-al-khor-industrial-grand-residence"
    }, {
        n: "Golf Tower 1",
        s: "dubai-the-views-golf-towers-golf-tower-1"
    }, {
        n: "Panorama at the Views Tower 3",
        s: "dubai-the-views-panorama-at-the-views-panorama-at-the-views-tower-3"
    }, {
        n: "The Links Canal Apartments",
        s: "dubai-the-views-the-links-the-links-canal-apartments"
    }, {
        n: "Al Fattan Sky Tower 2",
        s: "dubai-umm-ramool-al-fattan-sky-towers-al-fattan-sky-tower-2"
    }, {
        n: "The Fairways West",
        s: "dubai-the-views-the-fairways-the-fairways-west"
    }, {
        n: "Khafajy Building",
        s: "dubai-zabeel-zabeel-2-khafajy-building"
    }, {
        n: "Hartland Greens",
        s: "dubai-mohammed-bin-rashid-city-sobha-hartland-hartland-greens"
    }, {
        n: "One Park Avenue",
        s: "dubai-mohammed-bin-rashid-city-sobha-hartland-one-park-avenue"
    }, {
        n: "AZIZI Victoria at District 7",
        s: "dubai-mohammed-bin-rashid-city-district-7-azizi-victoria-at-district-7"
    }, {
        n: "Claverton House 2",
        s: "dubai-motor-city-claverton-house-claverton-house-2"
    }, {
        n: "Green View 1",
        s: "dubai-barsha-heights-tecom-green-view-green-view-1"
    }, {
        n: "Media Rotana Hotel Tower 1",
        s: "dubai-barsha-heights-tecom-media-rotana-hotel-towers-media-rotana-hotel-tower-1"
    }, {
        n: "Mirdif Tulip",
        s: "dubai-mirdif-uptown-mirdif-mirdif-tulip"
    }, {
        n: "Courtyard Apartments",
        s: "dubai-mirdif-uptown-mirdif-courtyard-apartments"
    }, {
        n: "Janayen Avenue",
        s: "dubai-mirdif-mirdif-hills-janayen-avenue"
    }, {
        n: "Ras Al Khor Industrial 3",
        s: "dubai-ras-al-khor-ras-al-khor-industrial-ras-al-khor-industrial-3"
    }, {
        n: "Samari Retail",
        s: "dubai-ras-al-khor-ras-al-khor-industrial-samari-retail"
    }, {
        n: "Mosela Waterside Residences",
        s: "dubai-the-views-mosela-mosela-waterside-residences"
    }, {
        n: "Travo Tower B",
        s: "dubai-the-views-travo-travo-tower-b"
    }, {
        n: "Panorama at the Views Tower 4",
        s: "dubai-the-views-panorama-at-the-views-panorama-at-the-views-tower-4"
    }, {
        n: "The Links East Tower",
        s: "dubai-the-views-the-links-the-links-east-tower"
    }, {
        n: "The Fairways North",
        s: "dubai-the-views-the-fairways-the-fairways-north"
    }, {
        n: "Glitz 1",
        s: "dubai-dubai-studio-city-glitz-glitz-1"
    }, {
        n: "Turia Tower A",
        s: "dubai-the-views-turia-turia-tower-a"
    }, {
        n: "Sobha Creek Vistas",
        s: "dubai-mohammed-bin-rashid-city-sobha-hartland-sobha-creek-vistas"
    }, {
        n: "Hartland Gardenia",
        s: "dubai-mohammed-bin-rashid-city-sobha-hartland-hartland-gardenia"
    }, {
        n: "Frond C",
        s: "dubai-palm-jebel-ali-signature-villas-frond-c"
    }, {
        n: "Millennium Square",
        s: "dubai-mohammed-bin-rashid-city-district-11-millennium-square"
    }, {
        n: "Frond C",
        s: "dubai-palm-jebel-ali-water-homes-frond-c"
    }, {
        n: "Deema 1",
        s: "dubai-the-lakes-deema-deema-1"
    }, {
        n: "Phase 2",
        s: "dubai-emirates-golf-club-emirates-golf-club-residences-phase-2"
    }, {
        n: "Frond A",
        s: "dubai-palm-jebel-ali-signature-villas-frond-a"
    }, {
        n: "Frond D",
        s: "dubai-palm-jebel-ali-signature-villas-frond-d"
    }, {
        n: "The Fields",
        s: "dubai-mohammed-bin-rashid-city-district-11-the-fields"
    }, {
        n: "Frond A",
        s: "dubai-palm-jebel-ali-water-homes-frond-a"
    }, {
        n: "Frond C",
        s: "dubai-palm-jebel-ali-garden-homes-frond-c"
    }, {
        n: "Ghadeer 2",
        s: "dubai-the-lakes-ghadeer-ghadeer-2"
    }, {
        n: "Maeen 3",
        s: "dubai-the-lakes-maeen-maeen-3"
    }, {
        n: "Mira 1",
        s: "dubai-reem-mira-mira-1"
    }, {
        n: "Sahara Villas",
        s: "dubai-akoya-avencia-sahara-villas"
    }, {
        n: "Casablanca Boutique Villas",
        s: "dubai-akoya-claret-casablanca-boutique-villas"
    }, {
        n: "Casablanca Boutique Villas",
        s: "dubai-akoya-zinnia-casablanca-boutique-villas"
    }, {
        n: "Aknan Villas",
        s: "dubai-akoya-vardon-aknan-villas"
    }, {
        n: "Casablanca Boutique Villas",
        s: "dubai-akoya-pacifica-casablanca-boutique-villas"
    }, {
        n: "Sawan Premium Stone Villas",
        s: "dubai-akoya-victoria-sawan-premium-stone-villas"
    }, {
        n: "Brookfield 1",
        s: "dubai-damac-hills-akoya-by-damac-brookfield-brookfield-1"
    }, {
        n: "Cascades",
        s: "dubai-akoya-amaro-cascades"
    }, {
        n: "Veneto Villas",
        s: "dubai-damac-hills-akoya-by-damac-trevi-veneto-villas"
    }, {
        n: "Pelham",
        s: "dubai-damac-hills-akoya-by-damac-akoya-park-pelham"
    }, {
        n: "Cassia at the Fields",
        s: "dubai-mohammed-bin-rashid-city-district-11-cassia-at-the-fields"
    }, {
        n: "Frond D",
        s: "dubai-palm-jebel-ali-water-homes-frond-d"
    }, {
        n: "Frond M",
        s: "dubai-palm-jebel-ali-water-homes-frond-m"
    }, {
        n: "Deema 2",
        s: "dubai-the-lakes-deema-deema-2"
    }, {
        n: "Frond M",
        s: "dubai-palm-jebel-ali-garden-homes-frond-m"
    }, {
        n: "Hattan 1",
        s: "dubai-the-lakes-hattan-hattan-1"
    }, {
        n: "Ghadeer 1",
        s: "dubai-the-lakes-ghadeer-ghadeer-1"
    }, {
        n: "Zulal 3",
        s: "dubai-the-lakes-zulal-zulal-3"
    }, {
        n: "Maeen 2",
        s: "dubai-the-lakes-maeen-maeen-2"
    }, {
        n: "Nirvana",
        s: "dubai-golf-city-zen-by-indigo-nirvana"
    }, {
        n: "Mira 3",
        s: "dubai-reem-mira-mira-3"
    }, {
        n: "Mira Oasis 2",
        s: "dubai-reem-mira-oasis-mira-oasis-2"
    }, {
        n: "Bahya Villas",
        s: "dubai-akoya-avencia-bahya-villas"
    }, {
        n: "Just Cavalli Villas",
        s: "dubai-akoya-aquilegia-just-cavalli-villas"
    }, {
        n: "Aurum Villas",
        s: "dubai-akoya-zinnia-aurum-villas"
    }, {
        n: "Aurum Villas",
        s: "dubai-akoya-sycamore-aurum-villas"
    }, {
        n: "Casablanca Boutique Villas",
        s: "dubai-akoya-coursetia-casablanca-boutique-villas"
    }, {
        n: "Casablanca Boutique Villas",
        s: "dubai-akoya-mulberry-casablanca-boutique-villas"
    }, {
        n: "Casablanca Boutique Villas",
        s: "dubai-akoya-juniper-casablanca-boutique-villas"
    }, {
        n: "Orchid A",
        s: "dubai-damac-hills-akoya-by-damac-orchid-orchid-a"
    }, {
        n: "Artesia E",
        s: "dubai-damac-hills-akoya-by-damac-artesia-artesia-e"
    }, {
        n: "Carson",
        s: "dubai-damac-hills-akoya-by-damac-the-drive-carson"
    }, {
        n: "Golf Promenade 1B",
        s: "dubai-damac-hills-akoya-by-damac-golf-promenade-golf-promenade-1b"
    }, {
        n: "Deema 3",
        s: "dubai-the-lakes-deema-deema-3"
    }, {
        n: "Frond F",
        s: "dubai-palm-jebel-ali-garden-homes-frond-f"
    }, {
        n: "Zulal 1",
        s: "dubai-the-lakes-zulal-zulal-1"
    }, {
        n: "Heaven",
        s: "dubai-golf-city-zen-by-indigo-heaven"
    }, {
        n: "Bliss",
        s: "dubai-golf-city-zen-by-indigo-bliss"
    }, {
        n: "Mira 2",
        s: "dubai-reem-mira-mira-2"
    }, {
        n: "Aurum Villas",
        s: "dubai-akoya-claret-aurum-villas"
    }, {
        n: "Aurum Villas",
        s: "dubai-akoya-odora-aurum-villas"
    }, {
        n: "Aurum Villas",
        s: "dubai-akoya-coursetia-aurum-villas"
    }, {
        n: "Aknan Villas",
        s: "dubai-akoya-victoria-aknan-villas"
    }, {
        n: "Brookfield 2",
        s: "dubai-damac-hills-akoya-by-damac-brookfield-brookfield-2"
    }, {
        n: "Artesia B",
        s: "dubai-damac-hills-akoya-by-damac-artesia-artesia-b"
    }, {
        n: "Whitefield 1",
        s: "dubai-damac-hills-akoya-by-damac-whitefield-whitefield-1"
    }, {
        n: "Loreto 3 B",
        s: "dubai-damac-hills-akoya-by-damac-loreto-loreto-3-b"
    }, {
        n: "Golf Promenade 4A",
        s: "dubai-damac-hills-akoya-by-damac-golf-promenade-golf-promenade-4a"
    }, {
        n: "Golf Promenade 5B",
        s: "dubai-damac-hills-akoya-by-damac-golf-promenade-golf-promenade-5b"
    }, {
        n: "Raf Raf Building",
        s: "dubai-al-muhaisnah-al-muhaisnah-4-raf-raf-building"
    }, {
        n: "wasl Oasis II",
        s: "dubai-al-muhaisnah-al-muhaisnah-4-wasl-oasis-ii"
    }, {
        n: "Omega Building",
        s: "dubai-bur-dubai-al-hamriya-omega-building"
    }, {
        n: "Al Hamriya Residence 2",
        s: "dubai-bur-dubai-al-hamriya-al-hamriya-residence-2"
    }, {
        n: "Sultan Building",
        s: "dubai-bur-dubai-mankhool-sultan-building"
    }, {
        n: "Viridian at the Fields",
        s: "dubai-mohammed-bin-rashid-city-district-11-viridian-at-the-fields"
    }, {
        n: "Frond E",
        s: "dubai-palm-jebel-ali-water-homes-frond-e"
    }, {
        n: "Frond F",
        s: "dubai-palm-jebel-ali-water-homes-frond-f"
    }, {
        n: "Deema 4",
        s: "dubai-the-lakes-deema-deema-4"
    }, {
        n: "Frond D",
        s: "dubai-palm-jebel-ali-garden-homes-frond-d"
    }, {
        n: "Hattan 2",
        s: "dubai-the-lakes-hattan-hattan-2"
    }, {
        n: "Zulal 4",
        s: "dubai-the-lakes-zulal-zulal-4"
    }, {
        n: "Maeen 4",
        s: "dubai-the-lakes-maeen-maeen-4"
    }, {
        n: "Maeen 5",
        s: "dubai-the-lakes-maeen-maeen-5"
    }, {
        n: "Aura",
        s: "dubai-golf-city-zen-by-indigo-aura"
    }, {
        n: "Mira 5",
        s: "dubai-reem-mira-mira-5"
    }, {
        n: "Mira Oasis 1",
        s: "dubai-reem-mira-oasis-mira-oasis-1"
    }, {
        n: "Aknan Villas",
        s: "dubai-akoya-avencia-aknan-villas"
    }, {
        n: "Aknan Villas",
        s: "dubai-akoya-amazonia-aknan-villas"
    }, {
        n: "Aurum Villas",
        s: "dubai-akoya-sanctnary-aurum-villas"
    }, {
        n: "Casablanca Boutique Villas",
        s: "dubai-akoya-aster-casablanca-boutique-villas"
    }, {
        n: "Bait Al Aseel",
        s: "dubai-akoya-victoria-bait-al-aseel"
    }, {
        n: "Aurum Villas",
        s: "dubai-akoya-mulberry-aurum-villas"
    }, {
        n: "Aurum Villas",
        s: "dubai-akoya-juniper-aurum-villas"
    }, {
        n: "Orchid B",
        s: "dubai-damac-hills-akoya-by-damac-orchid-orchid-b"
    }, {
        n: "Artesia C",
        s: "dubai-damac-hills-akoya-by-damac-artesia-artesia-c"
    }, {
        n: "Golf Vita A",
        s: "dubai-damac-hills-akoya-by-damac-golf-vita-golf-vita-a"
    }, {
        n: "Golf Terrace B",
        s: "dubai-damac-hills-akoya-by-damac-naia-golf-terrace-at-akoya-golf-terrace-b"
    }, {
        n: "Loreto 1 A",
        s: "dubai-damac-hills-akoya-by-damac-loreto-loreto-1-a"
    }, {
        n: "Frond A",
        s: "dubai-palm-jebel-ali-garden-homes-frond-a"
    }, {
        n: "Hattan 3",
        s: "dubai-the-lakes-hattan-hattan-3"
    }, {
        n: "Zulal 2",
        s: "dubai-the-lakes-zulal-zulal-2"
    }, {
        n: "Maeen 1",
        s: "dubai-the-lakes-maeen-maeen-1"
    }, {
        n: "Serene",
        s: "dubai-golf-city-zen-by-indigo-serene"
    }, {
        n: "Eternity",
        s: "dubai-golf-city-zen-by-indigo-eternity"
    }, {
        n: "Mira 4",
        s: "dubai-reem-mira-mira-4"
    }, {
        n: "Mira Oasis 3",
        s: "dubai-reem-mira-oasis-mira-oasis-3"
    }, {
        n: "Casablanca Boutique Villas",
        s: "dubai-akoya-amazonia-casablanca-boutique-villas"
    }, {
        n: "Casablanca Boutique Villas",
        s: "dubai-akoya-sanctnary-casablanca-boutique-villas"
    }, {
        n: "Aurum Villas",
        s: "dubai-akoya-aster-aurum-villas"
    }, {
        n: "Hajar Stone Villas",
        s: "dubai-akoya-victoria-hajar-stone-villas"
    }, {
        n: "Mega Villas",
        s: "dubai-akoya-amaro-mega-villas"
    }, {
        n: "Artesia D",
        s: "dubai-damac-hills-akoya-by-damac-artesia-artesia-d"
    }, {
        n: "Artesia A",
        s: "dubai-damac-hills-akoya-by-damac-artesia-artesia-a"
    }, {
        n: "Golf Vita B",
        s: "dubai-damac-hills-akoya-by-damac-golf-vita-golf-vita-b"
    }, {
        n: "Golf Terrace A",
        s: "dubai-damac-hills-akoya-by-damac-naia-golf-terrace-at-akoya-golf-terrace-a"
    }, {
        n: "Loreto 2 A",
        s: "dubai-damac-hills-akoya-by-damac-loreto-loreto-2-a"
    }, {
        n: "Golf Promenade 3A",
        s: "dubai-damac-hills-akoya-by-damac-golf-promenade-golf-promenade-3a"
    }, {
        n: "Golf Promenade 4B",
        s: "dubai-damac-hills-akoya-by-damac-golf-promenade-golf-promenade-4b"
    }, {
        n: "Golf Vista 2",
        s: "dubai-damac-hills-akoya-by-damac-golf-vista-golf-vista-2"
    }, {
        n: "Loreto 2 B",
        s: "dubai-damac-hills-akoya-by-damac-loreto-loreto-2-b"
    }, {
        n: "Golf Promenade 2B",
        s: "dubai-damac-hills-akoya-by-damac-golf-promenade-golf-promenade-2b"
    }, {
        n: "Golf Promenade 2A",
        s: "dubai-damac-hills-akoya-by-damac-golf-promenade-golf-promenade-2a"
    }, {
        n: "Al Mezan Building",
        s: "dubai-al-muhaisnah-al-muhaisnah-4-al-mezan-building"
    }, {
        n: "wasl Oasis III",
        s: "dubai-al-muhaisnah-al-muhaisnah-4-wasl-oasis-iii"
    }, {
        n: "Muhaisnah Plaza",
        s: "dubai-al-muhaisnah-al-muhaisnah-4-muhaisnah-plaza"
    }, {
        n: "Rose 3",
        s: "dubai-bur-dubai-al-hamriya-rose-3"
    }, {
        n: "Al Rostamani Building",
        s: "dubai-bur-dubai-al-hamriya-al-rostamani-building"
    }, {
        n: "Al Hamriya Residence 1",
        s: "dubai-bur-dubai-al-hamriya-al-hamriya-residence-1"
    }, {
        n: "Al Jawhara Building",
        s: "dubai-bur-dubai-oud-metha-al-jawhara-building"
    }, {
        n: "Roda Metha Suites",
        s: "dubai-bur-dubai-oud-metha-roda-metha-suites"
    }, {
        n: "Gulf Tower A",
        s: "dubai-bur-dubai-oud-metha-gulf-tower-a"
    }, {
        n: "Al Menhali Building",
        s: "dubai-bur-dubai-oud-metha-al-menhali-building"
    }, {
        n: "Gulf Tower B",
        s: "dubai-bur-dubai-oud-metha-gulf-tower-b"
    }, {
        n: "Desert Group 12",
        s: "dubai-bur-dubai-al-raffa-desert-group-12"
    }, {
        n: "DotCom A",
        s: "dubai-bur-dubai-al-raffa-dot-com-a"
    }, {
        n: "Savoy Central Hotel Apartments",
        s: "dubai-bur-dubai-al-raffa-savoy-central-hotel-apartments"
    }, {
        n: "Art 2",
        s: "dubai-bur-dubai-al-raffa-art-2"
    }, {
        n: "Al Watan Building",
        s: "dubai-bur-dubai-mankhool-al-watan-building"
    }, {
        n: "Golden Sands 4",
        s: "dubai-bur-dubai-mankhool-golden-sands-4"
    }, {
        n: "Phase 1",
        s: "dubai-emirates-golf-club-emirates-golf-club-residences-phase-1"
    }, {
        n: "Al Abbas Building",
        s: "dubai-bur-dubai-al-hamriya-al-abbas-building"
    }, {
        n: "Al Khayat Building",
        s: "dubai-bur-dubai-al-hamriya-al-khayat-building"
    }, {
        n: "SBL Building 339",
        s: "dubai-bur-dubai-oud-metha-sbl-building-339"
    }, {
        n: "SBL Building 338",
        s: "dubai-bur-dubai-oud-metha-sbl-building-338"
    }, {
        n: "Dunes Hotel Apartments",
        s: "dubai-bur-dubai-oud-metha-dunes-hotel-apartments"
    }, {
        n: "Emerald Oud Metha",
        s: "dubai-bur-dubai-oud-metha-emerald-oud-metha"
    }, {
        n: "Ramee Hotel Apartment",
        s: "dubai-bur-dubai-al-raffa-ramee-hotel-apartment"
    }, {
        n: "Art 1",
        s: "dubai-bur-dubai-al-raffa-art-1"
    }, {
        n: "Sunshine Building",
        s: "dubai-bur-dubai-mankhool-sunshine-building"
    }, {
        n: "Fatimah Saleh Building",
        s: "dubai-al-muhaisnah-al-muhaisnah-4-fatimah-saleh-building"
    }, {
        n: "Concord 3",
        s: "dubai-al-muhaisnah-al-muhaisnah-4-concord-3"
    }, {
        n: "Dunes Hotel Apartment",
        s: "dubai-al-muhaisnah-al-muhaisnah-1-dunes-hotel-apartment"
    }, {
        n: "NML Creek Residence",
        s: "dubai-bur-dubai-al-hamriya-nml-creek-residence"
    }, {
        n: "Al Wuheida Road",
        s: "dubai-bur-dubai-al-hamriya-al-wuheida-road"
    }, {
        n: "Arzco Hamriya Building",
        s: "dubai-bur-dubai-al-hamriya-arzco-hamriya-building"
    }, {
        n: "Faisal Building",
        s: "dubai-bur-dubai-oud-metha-faisal-building"
    }, {
        n: "Al Waleed Palace Hotel Apartments",
        s: "dubai-bur-dubai-oud-metha-al-waleed-palace-hotel-apartments"
    }, {
        n: "Desert Home Residence",
        s: "dubai-bur-dubai-oud-metha-desert-home-residence"
    }, {
        n: "Moosawi Building",
        s: "dubai-bur-dubai-oud-metha-moosawi-building"
    }, {
        n: "Hamad Rashid Hamad Al Khatabi",
        s: "dubai-bur-dubai-oud-metha-hamad-rashid-hamad-al-khatabi"
    }, {
        n: "Al Telal 4",
        s: "dubai-bur-dubai-al-raffa-al-telal-4"
    }, {
        n: "Al Attar Escala Building",
        s: "dubai-bur-dubai-al-raffa-al-attar-escala-building"
    }, {
        n: "Maples 2",
        s: "dubai-bur-dubai-al-raffa-maples-2"
    }, {
        n: "Silver Sands 2",
        s: "dubai-bur-dubai-mankhool-silver-sands-2"
    }, {
        n: "Al Bafta Plaza",
        s: "dubai-bur-dubai-mankhool-al-bafta-plaza"
    }, {
        n: "Manazel",
        s: "dubai-bur-dubai-mankhool-manazel"
    }, {
        n: "API Centrepoint Apartments",
        s: "dubai-bur-dubai-mankhool-api-centrepoint-apartments"
    }, {
        n: "Al Wuheida",
        s: "dubai-bur-dubai-al-hamriya-al-wuheida"
    }, {
        n: "Ahmad Creek Residency",
        s: "dubai-bur-dubai-al-hamriya-ahmad-creek-residency"
    }, {
        n: "Sophia",
        s: "dubai-bur-dubai-al-hamriya-sophia"
    }, {
        n: "Al Jawharah Building",
        s: "dubai-bur-dubai-al-hamriya-al-jawharah-building"
    }, {
        n: "Sultan Business Center",
        s: "dubai-bur-dubai-oud-metha-sultan-business-center"
    }, {
        n: "Mandarin Building",
        s: "dubai-bur-dubai-oud-metha-mandarin-building"
    }, {
        n: "Business Atrium Building",
        s: "dubai-bur-dubai-oud-metha-business-atrium-building"
    }, {
        n: "Pyramid Centre",
        s: "dubai-bur-dubai-oud-metha-pyramid-centre"
    }, {
        n: "ABC Arabian Suites",
        s: "dubai-bur-dubai-al-raffa-abc-arabian-suites"
    }, {
        n: "Rola Road",
        s: "dubai-bur-dubai-al-raffa-rola-road"
    }, {
        n: "Shipping Tower",
        s: "dubai-bur-dubai-al-raffa-shipping-tower"
    }, {
        n: "DotCom B",
        s: "dubai-bur-dubai-al-raffa-dot-com-b"
    }, {
        n: "Al Baha Building",
        s: "dubai-bur-dubai-mankhool-al-baha-building"
    }, {
        n: "Al Bafta Grand",
        s: "dubai-bur-dubai-mankhool-al-bafta-grand"
    }, {
        n: "Emerald Mankhool 2",
        s: "dubai-bur-dubai-mankhool-emerald-mankhool-2"
    }, {
        n: "BurJuman Business Tower",
        s: "dubai-bur-dubai-mankhool-bur-juman-business-tower"
    }, {
        n: "Golden Sand Building",
        s: "dubai-bur-dubai-mankhool-golden-sand-building"
    }, {
        n: "Al Nada 1",
        s: "dubai-bur-dubai-mankhool-al-nada-1"
    }, {
        n: "Golden Sands 3 Hotel Apartments",
        s: "dubai-bur-dubai-mankhool-golden-sands-3-hotel-apartments"
    }, {
        n: "Afnan Building",
        s: "dubai-bur-dubai-mankhool-afnan-building"
    }, {
        n: "Golden Sands 10 Hotel Apartments",
        s: "dubai-bur-dubai-mankhool-golden-sands-10-hotel-apartments"
    }, {
        n: "Silver Sands 1",
        s: "dubai-bur-dubai-mankhool-silver-sands-1"
    }, {
        n: "Savoy Crest Exclusive Hotel Apartment",
        s: "dubai-bur-dubai-mankhool-savoy-crest-exclusive-hotel-apartment"
    }, {
        n: "Sunrise Building",
        s: "dubai-bur-dubai-oud-metha-sunrise-building"
    }, {
        n: "Spectrum Building",
        s: "dubai-bur-dubai-oud-metha-spectrum-building"
    }, {
        n: "Hessa Homes Building",
        s: "dubai-bur-dubai-oud-metha-hessa-homes-building"
    }, {
        n: "Ahli Home",
        s: "dubai-bur-dubai-oud-metha-ahli-home"
    }, {
        n: "Al Khaleej Centre Area",
        s: "dubai-bur-dubai-al-raffa-al-khaleej-centre-area"
    }, {
        n: "Art 3",
        s: "dubai-bur-dubai-al-raffa-art-3"
    }, {
        n: "Jumeirah Star",
        s: "dubai-bur-dubai-mankhool-jumeirah-star"
    }, {
        n: "Al Baker Plaza",
        s: "dubai-bur-dubai-mankhool-al-baker-plaza"
    }, {
        n: "Al Tawi Building 3",
        s: "dubai-bur-dubai-mankhool-al-tawi-building-3"
    }, {
        n: "Bin Hendi Tower",
        s: "dubai-bur-dubai-mankhool-bin-hendi-tower"
    }, {
        n: "Andalus Plaza 4",
        s: "dubai-bur-dubai-mankhool-andalus-plaza-4"
    }, {
        n: "OPAL 4",
        s: "dubai-bur-dubai-mankhool-opal-4"
    }, {
        n: "AFAF",
        s: "dubai-bur-dubai-mankhool-afaf"
    }, {
        n: "Bisharah Building",
        s: "dubai-bur-dubai-mankhool-bisharah-building"
    }, {
        n: "Golden Sands 1",
        s: "dubai-bur-dubai-mankhool-golden-sands-1"
    }, {
        n: "Siraj Building",
        s: "dubai-bur-dubai-mankhool-siraj-building"
    }, {
        n: "Golden Sands 2",
        s: "dubai-bur-dubai-mankhool-golden-sands-2"
    }, {
        n: "Golden Sands 11",
        s: "dubai-bur-dubai-mankhool-golden-sands-11"
    }, {
        n: "Golden Sands 9",
        s: "dubai-bur-dubai-mankhool-golden-sands-9"
    }, {
        n: "OTL",
        s: "dubai-bur-dubai-khalid-bin-al-waleed-road-otl"
    }, {
        n: "Al Abra Street",
        s: "dubai-bur-dubai-al-souk-al-kabeer-al-abra-street"
    }, {
        n: "Toronto Building",
        s: "dubai-bur-dubai-al-souk-al-kabeer-toronto-building"
    }, {
        n: "Mirar Residence Tower 2",
        s: "dubai-du-biotech-mirar-residences-mirar-residence-tower-2"
    }, {
        n: "Hotel Tower 1",
        s: "dubai-dubai-pearl-dubai-pearl-hotel-towers-hotel-tower-1"
    }, {
        n: "Sallal 17",
        s: "dubai-forbidden-city-sallal-sallal-17"
    }, {
        n: "Sidra Villas I",
        s: "dubai-dubai-hills-estate-sidra-villas-sidra-villas-i"
    }, {
        n: "Maple 1",
        s: "dubai-dubai-hills-estate-maple-at-dubai-hills-estate-maple-1"
    }, {
        n: "Yansoon 5",
        s: "dubai-old-town-yansoon-yansoon-5"
    }, {
        n: "Reehan 6",
        s: "dubai-old-town-reehan-reehan-6"
    }, {
        n: "Reehan 4",
        s: "dubai-old-town-reehan-reehan-4"
    }, {
        n: "Liwa Building",
        s: "dubai-bur-dubai-mankhool-liwa-building"
    }, {
        n: "Yahya Residence",
        s: "dubai-bur-dubai-mankhool-yahya-residence"
    }, {
        n: "Etoile Building",
        s: "dubai-bur-dubai-mankhool-etoile-building"
    }, {
        n: "BurJuman Residence",
        s: "dubai-bur-dubai-mankhool-bur-juman-residence"
    }, {
        n: "Al Noor building",
        s: "dubai-bur-dubai-mankhool-al-noor-building"
    }, {
        n: "Abdul Rahim Mohd Al Zarooni Building",
        s: "dubai-bur-dubai-mankhool-abdul-rahim-mohd-al-zarooni-building"
    }, {
        n: "Al Nada 2",
        s: "dubai-bur-dubai-mankhool-al-nada-2"
    }, {
        n: "Bait Al Jabal",
        s: "dubai-bur-dubai-mankhool-bait-al-jabal"
    }, {
        n: "Golden Sands 7",
        s: "dubai-bur-dubai-mankhool-golden-sands-7"
    }, {
        n: "Atrium Centre",
        s: "dubai-bur-dubai-khalid-bin-al-waleed-road-atrium-centre"
    }, {
        n: "Al Zahra Building",
        s: "dubai-bur-dubai-al-souk-al-kabeer-al-zahra-building"
    }, {
        n: "Al Khayat Building - Musallah",
        s: "dubai-bur-dubai-al-souk-al-kabeer-al-khayat-building-musallah"
    }, {
        n: "Mirar Residence Tower 1",
        s: "dubai-du-biotech-mirar-residences-mirar-residence-tower-1"
    }, {
        n: "Lulwa Tower",
        s: "dubai-dubai-pearl-pearl-residences-lulwa-tower"
    }, {
        n: "Sallal 3",
        s: "dubai-forbidden-city-sallal-sallal-3"
    }, {
        n: "Sidra Villas III",
        s: "dubai-dubai-hills-estate-sidra-villas-sidra-villas-iii"
    }, {
        n: "Yansoon 8",
        s: "dubai-old-town-yansoon-yansoon-8"
    }, {
        n: "Yansoon 6",
        s: "dubai-old-town-yansoon-yansoon-6"
    }, {
        n: "Reehan 8",
        s: "dubai-old-town-reehan-reehan-8"
    }, {
        n: "Reehan 5",
        s: "dubai-old-town-reehan-reehan-5"
    }, {
        n: "Miska 4",
        s: "dubai-old-town-miska-miska-4"
    }, {
        n: "Zaafaran 4",
        s: "dubai-old-town-zaafaran-zaafaran-4"
    }, {
        n: "Kamoon 2",
        s: "dubai-old-town-kamoon-kamoon-2"
    }, {
        n: "Zanzebeel 2",
        s: "dubai-old-town-zanzebeel-zanzebeel-2"
    }, {
        n: "Al Bahar Residences",
        s: "dubai-old-town-the-old-town-island-al-bahar-residences"
    }, {
        n: "Tropical Island",
        s: "dubai-the-world-islands-coral-island-tropical-island"
    }, {
        n: "Farah Residence",
        s: "dubai-al-warsan-al-warsan-4-farah-residence"
    }, {
        n: "Rivington Heights",
        s: "dubai-al-warsan-al-warsan-4-rivington-heights"
    }, {
        n: "The Derby Residences 1",
        s: "dubai-meydan-the-derby-residences-the-derby-residences-1"
    }, {
        n: "Grand Views",
        s: "dubai-meydan-meydan-gated-community-grand-views"
    }, {
        n: "Prime Views by Prescott",
        s: "dubai-meydan-meydan-avenue-prime-views-by-prescott"
    }, {
        n: "Royal Pearls",
        s: "dubai-meydan-meydan-business-park-royal-pearls"
    }, {
        n: "Al Mashrabiya Building",
        s: "dubai-bur-dubai-mankhool-al-mashrabiya-building"
    }, {
        n: "Al Waha Building",
        s: "dubai-bur-dubai-mankhool-al-waha-building"
    }, {
        n: "Blue Diamond Building",
        s: "dubai-bur-dubai-mankhool-blue-diamond-building"
    }, {
        n: "Ajdan Residence",
        s: "dubai-bur-dubai-mankhool-ajdan-residence"
    }, {
        n: "Jauan Salem Building",
        s: "dubai-bur-dubai-mankhool-jauan-salem-building"
    }, {
        n: "Savoy Suites Hotel Apartments",
        s: "dubai-bur-dubai-mankhool-savoy-suites-hotel-apartments"
    }, {
        n: "Savoy Park Hotel Apartments",
        s: "dubai-bur-dubai-mankhool-savoy-park-hotel-apartments"
    }, {
        n: "Jamal Al Ghurair Building",
        s: "dubai-bur-dubai-mankhool-jamal-al-ghurair-building"
    }, {
        n: "Royal Ascot Hotel",
        s: "dubai-bur-dubai-al-ghubaiba-area-royal-ascot-hotel"
    }, {
        n: "The Business Center",
        s: "dubai-bur-dubai-khalid-bin-al-waleed-road-the-business-center"
    }, {
        n: "Abdul Rahim Al Zarooni Building",
        s: "dubai-bur-dubai-al-souk-al-kabeer-abdul-rahim-al-zarooni-building"
    }, {
        n: "Al Souk Al Kabeer Street",
        s: "dubai-bur-dubai-al-souk-al-kabeer-al-souk-al-kabeer-street"
    }, {
        n: "NCC Harmony Tower 2",
        s: "dubai-du-biotech-ncc-harmony-twin-tower-ncc-harmony-tower-2"
    }, {
        n: "Sallal 13",
        s: "dubai-forbidden-city-sallal-sallal-13"
    }, {
        n: "Golf Suites",
        s: "dubai-dubai-hills-estate-dubai-hills-golf-suites"
    }, {
        n: "Maple 2",
        s: "dubai-dubai-hills-estate-maple-at-dubai-hills-estate-maple-2"
    }, {
        n: "Yansoon 2",
        s: "dubai-old-town-yansoon-yansoon-2"
    }, {
        n: "Yansoon 3",
        s: "dubai-old-town-yansoon-yansoon-3"
    }, {
        n: "Reehan 1",
        s: "dubai-old-town-reehan-reehan-1"
    }, {
        n: "Miska 1",
        s: "dubai-old-town-miska-miska-1"
    }, {
        n: "Zaafaran 3",
        s: "dubai-old-town-zaafaran-zaafaran-3"
    }, {
        n: "Mulberry",
        s: "dubai-dubai-hills-estate-park-heights-mulberry"
    }, {
        n: "Kamoon 4",
        s: "dubai-old-town-kamoon-kamoon-4"
    }, {
        n: "Umm Hurair Residence 2",
        s: "dubai-umm-hurair-umm-hurair-1-umm-hurair-residence-2"
    }, {
        n: "Mankhool 455",
        s: "dubai-bur-dubai-mankhool-mankhool-455"
    }, {
        n: "The Bricks",
        s: "dubai-bur-dubai-mankhool-the-bricks"
    }, {
        n: "Style Building",
        s: "dubai-bur-dubai-mankhool-style-building"
    }, {
        n: "Winchester Hotel Apartments",
        s: "dubai-bur-dubai-mankhool-winchester-hotel-apartments"
    }, {
        n: "Al Salam Residence",
        s: "dubai-bur-dubai-mankhool-al-salam-residence"
    }, {
        n: "Al Rais Building 6",
        s: "dubai-bur-dubai-mankhool-al-rais-building-6"
    }, {
        n: "AMA",
        s: "dubai-bur-dubai-khalid-bin-al-waleed-road-ama"
    }, {
        n: "Darwish Building",
        s: "dubai-bur-dubai-al-souk-al-kabeer-darwish-building"
    }, {
        n: "Al Fahidi Street",
        s: "dubai-bur-dubai-al-souk-al-kabeer-al-fahidi-street"
    }, {
        n: "Mirar Residence Tower 3",
        s: "dubai-du-biotech-mirar-residences-mirar-residence-tower-3"
    }, {
        n: "Dana Tower",
        s: "dubai-dubai-pearl-pearl-residences-dana-tower"
    }, {
        n: "Sidra Villas II",
        s: "dubai-dubai-hills-estate-sidra-villas-sidra-villas-ii"
    }, {
        n: "Yansoon 1",
        s: "dubai-old-town-yansoon-yansoon-1"
    }, {
        n: "Yansoon 4",
        s: "dubai-old-town-yansoon-yansoon-4"
    }, {
        n: "Reehan 3",
        s: "dubai-old-town-reehan-reehan-3"
    }, {
        n: "Continental 4",
        s: "dubai-bur-dubai-mankhool-continental-4"
    }, {
        n: "Bank Street Building",
        s: "dubai-bur-dubai-mankhool-bank-street-building"
    }, {
        n: "HHH",
        s: "dubai-bur-dubai-khalid-bin-al-waleed-road-hhh"
    }, {
        n: "Al Mussalla",
        s: "dubai-bur-dubai-al-souk-al-kabeer-al-mussalla"
    }, {
        n: "Al Fahidi Building",
        s: "dubai-bur-dubai-al-souk-al-kabeer-al-fahidi-building"
    }, {
        n: "AL Mina Building",
        s: "dubai-bur-dubai-mina-street-al-mina-building"
    }, {
        n: "NCC Harmony Tower 1",
        s: "dubai-du-biotech-ncc-harmony-twin-tower-ncc-harmony-tower-1"
    }, {
        n: "Sallal 6",
        s: "dubai-forbidden-city-sallal-sallal-6"
    }, {
        n: "Maple 3",
        s: "dubai-dubai-hills-estate-maple-at-dubai-hills-estate-maple-3"
    }, {
        n: "Yansoon 7",
        s: "dubai-old-town-yansoon-yansoon-7"
    }, {
        n: "Yansoon 9",
        s: "dubai-old-town-yansoon-yansoon-9"
    }, {
        n: "Reehan 2",
        s: "dubai-old-town-reehan-reehan-2"
    }, {
        n: "Miska 5",
        s: "dubai-old-town-miska-miska-5"
    }, {
        n: "Zaafaran 1",
        s: "dubai-old-town-zaafaran-zaafaran-1"
    }, {
        n: "Acacia",
        s: "dubai-dubai-hills-estate-park-heights-acacia"
    }, {
        n: "Zanzebeel 1",
        s: "dubai-old-town-zanzebeel-zanzebeel-1"
    }, {
        n: "Mont Royal Hotel",
        s: "dubai-the-world-islands-europe-mont-royal-hotel"
    }, {
        n: "Lulu Centre",
        s: "dubai-karama-karama-park-area-lulu-centre"
    }, {
        n: "Cote D' Azur Hotel",
        s: "dubai-the-world-islands-the-heart-of-europe-cote-d-azur-hotel"
    }, {
        n: "Al Hikma Building",
        s: "dubai-al-warsan-al-warsan-4-al-hikma-building"
    }, {
        n: "Aamna Residency",
        s: "dubai-al-warsan-al-warsan-4-aamna-residency"
    }, {
        n: "The Derby Residences 3",
        s: "dubai-meydan-the-derby-residences-the-derby-residences-3"
    }, {
        n: "Millennium Estates",
        s: "dubai-meydan-meydan-gated-community-millennium-estates"
    }, {
        n: "Bright Corner",
        s: "dubai-meydan-meydan-avenue-bright-corner"
    }, {
        n: "Rosebay Living",
        s: "dubai-meydan-meydan-avenue-rosebay-living"
    }, {
        n: "AZIZI Riviera 6",
        s: "dubai-meydan-meydan-one-azizi-riviera-6"
    }, {
        n: "AZIZI Riviera 29",
        s: "dubai-meydan-meydan-one-azizi-riviera-29"
    }, {
        n: "AZIZI Riviera 48",
        s: "dubai-meydan-meydan-one-azizi-riviera-48"
    }, {
        n: "AZIZI Riviera 2",
        s: "dubai-meydan-meydan-one-azizi-riviera-2"
    }, {
        n: "AZIZI Riviera 32",
        s: "dubai-meydan-meydan-one-azizi-riviera-32"
    }, {
        n: "AZIZI Riviera 14",
        s: "dubai-meydan-meydan-one-azizi-riviera-14"
    }, {
        n: "M.M.Towers",
        s: "dubai-jumeirah-jumeirah-1-m-m-towers"
    }, {
        n: "Wasl Vita",
        s: "dubai-jumeirah-jumeirah-1-wasl-vita"
    }, {
        n: "Palm Strip Mall",
        s: "dubai-jumeirah-jumeirah-1-palm-strip-mall"
    }, {
        n: "Roda Beach Resort Villas",
        s: "dubai-jumeirah-jumeirah-3-roda-beach-resort-villas"
    }, {
        n: "Oud Metha Tower",
        s: "dubai-umm-hurair-umm-hurair-2-oud-metha-tower"
    }, {
        n: "Al Attar Centre",
        s: "dubai-karama-karama-park-area-al-attar-centre"
    }, {
        n: "Portfolio Hotel",
        s: "dubai-the-world-islands-the-heart-of-europe-portfolio-hotel"
    }, {
        n: "Hala Residence",
        s: "dubai-al-warsan-al-warsan-4-hala-residence"
    }, {
        n: "Benaa G10",
        s: "dubai-al-warsan-al-warsan-4-benaa-g10"
    }, {
        n: "AZIZI Riviera 1",
        s: "dubai-meydan-meydan-one-azizi-riviera-1"
    }, {
        n: "AZIZI Riviera 37",
        s: "dubai-meydan-meydan-one-azizi-riviera-37"
    }, {
        n: "AZIZI Riviera 38",
        s: "dubai-meydan-meydan-one-azizi-riviera-38"
    }, {
        n: "AZIZI Riviera 12",
        s: "dubai-meydan-meydan-one-azizi-riviera-12"
    }, {
        n: "AZIZI Riviera 13",
        s: "dubai-meydan-meydan-one-azizi-riviera-13"
    }, {
        n: "AZIZI Riviera 4",
        s: "dubai-meydan-meydan-one-azizi-riviera-4"
    }, {
        n: "AZIZI Riviera 27",
        s: "dubai-meydan-meydan-one-azizi-riviera-27"
    }, {
        n: "AZIZI Riviera 34",
        s: "dubai-meydan-meydan-one-azizi-riviera-34"
    }, {
        n: "Azizi Riviera 22",
        s: "dubai-meydan-meydan-one-azizi-riviera-22"
    }, {
        n: "Red Building",
        s: "dubai-karama-shaikh-hamdan-colony-red-building"
    }, {
        n: "Al Kazim Building",
        s: "dubai-karama-karama-park-area-al-kazim-building"
    }, {
        n: "Cartel 114",
        s: "dubai-al-warsan-al-warsan-4-cartel-114"
    }, {
        n: "SAS 1 Building",
        s: "dubai-al-warsan-al-warsan-4-sas-1-building"
    }, {
        n: "W20 Building",
        s: "dubai-al-warsan-al-warsan-4-w20-building"
    }, {
        n: "The Polo Townhouses",
        s: "dubai-meydan-meydan-gated-community-the-polo-townhouses"
    }, {
        n: "Royal Manor",
        s: "dubai-meydan-meydan-avenue-royal-manor"
    }, {
        n: "The Galleries at Meydan Avenue",
        s: "dubai-meydan-meydan-avenue-the-galleries-at-meydan-avenue"
    }, {
        n: "AZIZI Riviera 17",
        s: "dubai-meydan-meydan-one-azizi-riviera-17"
    }, {
        n: "AZIZI Riviera 3",
        s: "dubai-meydan-meydan-one-azizi-riviera-3"
    }, {
        n: "AZIZI Riviera 9",
        s: "dubai-meydan-meydan-one-azizi-riviera-9"
    }, {
        n: "AZIZI Riviera 15",
        s: "dubai-meydan-meydan-one-azizi-riviera-15"
    }, {
        n: "Azizi Riviera 20",
        s: "dubai-meydan-meydan-one-azizi-riviera-20"
    }, {
        n: "AZIZI RIviera 18",
        s: "dubai-meydan-meydan-one-azizi-r-iviera-18"
    }, {
        n: "Jumeirah Apartments",
        s: "dubai-jumeirah-jumeirah-1-jumeirah-apartments"
    }, {
        n: "Beach Resident 1",
        s: "dubai-jumeirah-jumeirah-1-beach-resident-1"
    }, {
        n: "The Court",
        s: "dubai-jumeirah-jumeirah-1-the-court"
    }, {
        n: "Arenco Villas 23",
        s: "dubai-jumeirah-jumeirah-3-arenco-villas-23"
    }, {
        n: "Port de La Mer",
        s: "dubai-jumeirah-la-mer-port-de-la-mer"
    }, {
        n: "Beach Park Plaza Centre",
        s: "dubai-jumeirah-jumeirah-2-beach-park-plaza-centre"
    }, {
        n: "NB Residences",
        s: "dubai-jumeirah-pearl-jumeirah-nb-residences"
    }, {
        n: "Park Ville 07",
        s: "dubai-al-warsan-al-warsan-4-park-ville-07"
    }, {
        n: "Warsan Akasya",
        s: "dubai-al-warsan-al-warsan-4-warsan-akasya"
    }, {
        n: "The Derby Residences 2",
        s: "dubai-meydan-the-derby-residences-the-derby-residences-2"
    }, {
        n: "The Polo Residence",
        s: "dubai-meydan-meydan-avenue-the-polo-residence"
    }, {
        n: "Manazel Meydan",
        s: "dubai-meydan-meydan-avenue-manazel-meydan"
    }, {
        n: "Tonino Lamborghini Residences",
        s: "dubai-meydan-meydan-business-park-tonino-lamborghini-residences"
    }, {
        n: "AZIZI Riviera 26",
        s: "dubai-meydan-meydan-one-azizi-riviera-26"
    }, {
        n: "AZIZI Riviera 35",
        s: "dubai-meydan-meydan-one-azizi-riviera-35"
    }, {
        n: "AZIZI Riviera 10",
        s: "dubai-meydan-meydan-one-azizi-riviera-10"
    }, {
        n: "AZIZI Riviera 16",
        s: "dubai-meydan-meydan-one-azizi-riviera-16"
    }, {
        n: "Azizi Riviera 19",
        s: "dubai-meydan-meydan-one-azizi-riviera-19"
    }, {
        n: "AZIZI Riviera 8",
        s: "dubai-meydan-meydan-one-azizi-riviera-8"
    }, {
        n: "Al Telal 12",
        s: "dubai-jumeirah-jumeirah-1-al-telal-12"
    }, {
        n: "Al Wasl Building",
        s: "dubai-jumeirah-jumeirah-1-al-wasl-building"
    }, {
        n: "Sunset Mall",
        s: "dubai-jumeirah-jumeirah-3-sunset-mall"
    }, {
        n: "Bulgari Resort & Residences",
        s: "dubai-jumeirah-jumeirah-bay-island-bulgari-resort-residences"
    }, {
        n: "La Plage Complex",
        s: "dubai-jumeirah-jumeira-beach-road-la-plage-complex"
    }, {
        n: "Al Hashimi Umm Hirair",
        s: "dubai-umm-hurair-umm-hurair-1-al-hashimi-umm-hirair"
    }, {
        n: "Yellow Building",
        s: "dubai-karama-shaikh-hamdan-colony-yellow-building"
    }, {
        n: "Karama Centre",
        s: "dubai-karama-karama-park-area-karama-centre"
    }, {
        n: "Diamond Business Park",
        s: "dubai-meydan-meydan-gated-community-diamond-business-park"
    }, {
        n: "Azizi Gardens",
        s: "dubai-meydan-meydan-avenue-azizi-gardens"
    }, {
        n: "Residence 1",
        s: "dubai-meydan-meydan-avenue-residence-1"
    }, {
        n: "AZIZI Riviera 5",
        s: "dubai-meydan-meydan-one-azizi-riviera-5"
    }, {
        n: "Azizi Riviera 21",
        s: "dubai-meydan-meydan-one-azizi-riviera-21"
    }, {
        n: "Kar Commercial Building",
        s: "dubai-jumeirah-jumeirah-1-kar-commercial-building"
    }, {
        n: "Al Maskan 1",
        s: "dubai-jumeirah-jumeirah-1-al-maskan-1"
    }, {
        n: "Arenco Villas 7",
        s: "dubai-jumeirah-jumeirah-1-arenco-villas-7"
    }, {
        n: "Marsa Al Arab",
        s: "dubai-jumeirah-jumeirah-3-marsa-al-arab"
    }, {
        n: "Arenco Villas 32",
        s: "dubai-jumeirah-jumeirah-3-arenco-villas-32"
    }, {
        n: "Villa Amalfi",
        s: "dubai-jumeirah-jumeirah-bay-island-villa-amalfi"
    }, {
        n: "La Mer South Island",
        s: "dubai-jumeirah-la-mer-la-mer-south-island"
    }, {
        n: "Nikki Beach Resort and Spa Dubai",
        s: "dubai-jumeirah-pearl-jumeirah-nikki-beach-resort-and-spa-dubai"
    }, {
        n: "Lulu Building 2",
        s: "dubai-umm-hurair-umm-hurair-1-lulu-building-2"
    }, {
        n: "Umm Hurair Residence 1",
        s: "dubai-umm-hurair-umm-hurair-1-umm-hurair-residence-1"
    }, {
        n: "Blue Building",
        s: "dubai-karama-shaikh-hamdan-colony-blue-building"
    }, {
        n: "Al Khazna Centre",
        s: "dubai-karama-karama-park-area-al-khazna-centre"
    }, {
        n: "Dragon Tower A",
        s: "dubai-dragon-city-dragon-towers-dragon-tower-a"
    }, {
        n: "Whispering Pines",
        s: "dubai-jumeirah-golf-estates-earth-whispering-pines"
    }, {
        n: "Safeena",
        s: "dubai-dubai-promenade-the-marina-towers-safeena"
    }, {
        n: "Suheili",
        s: "dubai-dubai-promenade-the-channel-towers-suheili"
    }, {
        n: "The Residences at Caesars Resort",
        s: "dubai-bluewaters-caesars-bluewaters-dubai-the-residences-at-caesars-resort"
    }, {
        n: "Apartment Building 6",
        s: "dubai-bluewaters-bluewaters-residences-apartment-building-6"
    }, {
        n: "Apartment Building 8",
        s: "dubai-bluewaters-bluewaters-residences-apartment-building-8"
    }, {
        n: "building  4",
        s: "dubai-dubai-waterfront-badrah-building-4"
    }, {
        n: "Green Desert Villas",
        s: "dubai-jumeirah-jumeirah-1-green-desert-villas"
    }, {
        n: "API Jumeirah Villas",
        s: "dubai-jumeirah-jumeirah-1-api-jumeirah-villas"
    }, {
        n: "wasl 51",
        s: "dubai-jumeirah-jumeirah-1-wasl-51"
    }, {
        n: "Jumeirah 1 Villas",
        s: "dubai-jumeirah-jumeirah-1-jumeirah-1-villas"
    }, {
        n: "Sunset Residence",
        s: "dubai-jumeirah-jumeirah-3-sunset-residence"
    }, {
        n: "Al Tarad Street",
        s: "dubai-jumeirah-jumeirah-3-al-tarad-street"
    }, {
        n: "La Mer North Island",
        s: "dubai-jumeirah-la-mer-la-mer-north-island"
    }, {
        n: "Al Sayegh Building",
        s: "dubai-umm-hurair-umm-hurair-1-al-sayegh-building"
    }, {
        n: "Sur La Mer",
        s: "dubai-jumeirah-la-mer-sur-la-mer"
    }, {
        n: "Pearl Jumeirah Villas",
        s: "dubai-jumeirah-pearl-jumeirah-pearl-jumeirah-villas"
    }, {
        n: "Noora",
        s: "dubai-umm-hurair-umm-hurair-1-noora"
    }, {
        n: "Wafi Residence",
        s: "dubai-umm-hurair-umm-hurair-2-wafi-residence"
    }, {
        n: "Green Building",
        s: "dubai-karama-shaikh-hamdan-colony-green-building"
    }, {
        n: "Wildflower",
        s: "dubai-jumeirah-golf-estates-earth-wildflower"
    }, {
        n: "Juniper Way",
        s: "dubai-jumeirah-golf-estates-fire-juniper-way"
    }, {
        n: "Redwood Avenue",
        s: "dubai-jumeirah-golf-estates-fire-redwood-avenue"
    }, {
        n: "Fireside",
        s: "dubai-jumeirah-golf-estates-fire-fireside"
    }, {
        n: "Sienna Lakes",
        s: "dubai-jumeirah-golf-estates-fire-sienna-lakes"
    }, {
        n: "Sarieh",
        s: "dubai-dubai-promenade-the-marina-towers-sarieh"
    }, {
        n: "Al Shimal",
        s: "dubai-dubai-promenade-the-channel-towers-al-shimal"
    }, {
        n: "The Residences at Caesars Palace",
        s: "dubai-bluewaters-caesars-bluewaters-dubai-the-residences-at-caesars-palace"
    }, {
        n: "Apartment Building 3",
        s: "dubai-bluewaters-bluewaters-residences-apartment-building-3"
    }, {
        n: "Apartment Building 2",
        s: "dubai-bluewaters-bluewaters-residences-apartment-building-2"
    }, {
        n: "building 2",
        s: "dubai-dubai-waterfront-badrah-building-2"
    }, {
        n: "Diaa",
        s: "dubai-dubai-waterfront-badrah-diaa"
    }, {
        n: "building 3",
        s: "dubai-dubai-waterfront-badrah-building-3"
    }, {
        n: "Signature Villas Frond B",
        s: "dubai-palm-jumeirah-signature-villas-signature-villas-frond-b"
    }, {
        n: "Signature Villas Frond E",
        s: "dubai-palm-jumeirah-signature-villas-signature-villas-frond-e"
    }, {
        n: "Signature Villas Frond I",
        s: "dubai-palm-jumeirah-signature-villas-signature-villas-frond-i"
    }, {
        n: "Maurya",
        s: "dubai-palm-jumeirah-grandeur-residences-maurya"
    }, {
        n: "Oceana Southern",
        s: "dubai-palm-jumeirah-oceana-oceana-southern"
    }, {
        n: "The Fairmont Palm Residence North",
        s: "dubai-palm-jumeirah-the-fairmont-palm-residences-the-fairmont-palm-residence-north"
    }, {
        n: "Marina Residences 5",
        s: "dubai-palm-jumeirah-marina-residences-marina-residences-5"
    }, {
        n: "Atlantis Tower 1",
        s: "dubai-palm-jumeirah-atlantis-the-palm-atlantis-tower-1"
    }, {
        n: "Gateway Tower 3",
        s: "dubai-palm-jumeirah-gateway-towers-gateway-tower-3"
    }, {
        n: "Redwood Park",
        s: "dubai-jumeirah-golf-estates-fire-redwood-park"
    }, {
        n: "Flame Tree Ridge",
        s: "dubai-jumeirah-golf-estates-fire-flame-tree-ridge"
    }, {
        n: "Douj",
        s: "dubai-dubai-promenade-the-beach-towers-douj"
    }, {
        n: "Signature Villas",
        s: "dubai-emirates-hills-emirates-hills-villas-signature-villas"
    }, {
        n: "Wave Residence 2",
        s: "dubai-dubai-waterfront-the-waves-wave-residence-2"
    }, {
        n: "Apartment Building 7",
        s: "dubai-bluewaters-bluewaters-residences-apartment-building-7"
    }, {
        n: "Apartment Building 5",
        s: "dubai-bluewaters-bluewaters-residences-apartment-building-5"
    }, {
        n: "building  1",
        s: "dubai-dubai-waterfront-badrah-building-1"
    }, {
        n: "Manara",
        s: "dubai-dubai-waterfront-badrah-manara"
    }, {
        n: "Aston Tower B",
        s: "dubai-dubai-science-park-aston-towers-aston-tower-b"
    }, {
        n: "Signature Villas Frond A",
        s: "dubai-palm-jumeirah-signature-villas-signature-villas-frond-a"
    }, {
        n: "Signature Villas Frond D",
        s: "dubai-palm-jumeirah-signature-villas-signature-villas-frond-d"
    }, {
        n: "Signature Villas Frond C",
        s: "dubai-palm-jumeirah-signature-villas-signature-villas-frond-c"
    }, {
        n: "Oceana Adriatic",
        s: "dubai-palm-jumeirah-oceana-oceana-adriatic"
    }, {
        n: "Oceana Caribbean",
        s: "dubai-palm-jumeirah-oceana-oceana-caribbean"
    }, {
        n: "Mansion 6",
        s: "dubai-palm-jumeirah-w-residences-mansion-6"
    }, {
        n: "Valencia Grove",
        s: "dubai-jumeirah-golf-estates-fire-valencia-grove"
    }, {
        n: "Heirrat",
        s: "dubai-dubai-promenade-the-beach-towers-heirrat"
    }, {
        n: "Montgomerie Maisonettes",
        s: "dubai-emirates-hills-emirates-hills-villas-montgomerie-maisonettes"
    }, {
        n: "Wave Residence 1",
        s: "dubai-dubai-waterfront-the-waves-wave-residence-1"
    }, {
        n: "Apartment Building 9",
        s: "dubai-bluewaters-bluewaters-residences-apartment-building-9"
    }, {
        n: "Apartment Building 1",
        s: "dubai-bluewaters-bluewaters-residences-apartment-building-1"
    }, {
        n: "building  6",
        s: "dubai-dubai-waterfront-badrah-building-6"
    }, {
        n: "building  5",
        s: "dubai-dubai-waterfront-badrah-building-5"
    }, {
        n: "Signature Villas Frond J",
        s: "dubai-palm-jumeirah-signature-villas-signature-villas-frond-j"
    }, {
        n: "Signature Villas Frond P",
        s: "dubai-palm-jumeirah-signature-villas-signature-villas-frond-p"
    }, {
        n: "Signature Villas Frond N",
        s: "dubai-palm-jumeirah-signature-villas-signature-villas-frond-n"
    }, {
        n: "Signature Villas Frond O",
        s: "dubai-palm-jumeirah-signature-villas-signature-villas-frond-o"
    }, {
        n: "Oceana Pacific",
        s: "dubai-palm-jumeirah-oceana-oceana-pacific"
    }, {
        n: "The Fairmont Palm Hotel & Resort",
        s: "dubai-palm-jumeirah-the-fairmont-palm-residences-the-fairmont-palm-hotel-resort"
    }, {
        n: "Serenia Residences North",
        s: "dubai-palm-jumeirah-serenia-residences-the-palm-serenia-residences-north"
    }, {
        n: "Anantara Residences - South",
        s: "dubai-palm-jumeirah-anantara-residences-anantara-residences-south"
    }, {
        n: "Mansion 2",
        s: "dubai-palm-jumeirah-w-residences-mansion-2"
    }, {
        n: "Airport Road",
        s: "dubai-al-garhoud-airport-road-area-airport-road"
    }, {
        n: "Al Gharbi",
        s: "dubai-dubai-promenade-the-channel-towers-al-gharbi"
    }, {
        n: "Al Hambra Villas",
        s: "dubai-emirates-hills-emirates-hills-villas-al-hambra-villas"
    }, {
        n: "Apartment Building 4",
        s: "dubai-bluewaters-bluewaters-residences-apartment-building-4"
    }, {
        n: "Apartment Building 10",
        s: "dubai-bluewaters-bluewaters-residences-apartment-building-10"
    }, {
        n: "Townhouses",
        s: "dubai-bluewaters-bluewaters-residences-townhouses"
    }, {
        n: "building  8",
        s: "dubai-dubai-waterfront-badrah-building-8"
    }, {
        n: "Badrah Townhouses",
        s: "dubai-dubai-waterfront-badrah-badrah-townhouses"
    }, {
        n: "Signature Villas Frond K",
        s: "dubai-palm-jumeirah-signature-villas-signature-villas-frond-k"
    }, {
        n: "Signature Villas Frond F",
        s: "dubai-palm-jumeirah-signature-villas-signature-villas-frond-f"
    }, {
        n: "Signature Villas Frond G",
        s: "dubai-palm-jumeirah-signature-villas-signature-villas-frond-g"
    }, {
        n: "Mughal",
        s: "dubai-palm-jumeirah-grandeur-residences-mughal"
    }, {
        n: "Oceana Atlantic",
        s: "dubai-palm-jumeirah-oceana-oceana-atlantic"
    }, {
        n: "The Fairmont Palm Residence South",
        s: "dubai-palm-jumeirah-the-fairmont-palm-residences-the-fairmont-palm-residence-south"
    }, {
        n: "Serenia Residences East",
        s: "dubai-palm-jumeirah-serenia-residences-the-palm-serenia-residences-east"
    }, {
        n: "Gateway Tower 1",
        s: "dubai-palm-jumeirah-gateway-towers-gateway-tower-1"
    }, {
        n: "Mansion 5",
        s: "dubai-palm-jumeirah-w-residences-mansion-5"
    }, {
        n: "Mansion 1",
        s: "dubai-palm-jumeirah-w-residences-mansion-1"
    }, {
        n: "Garden Homes Frond I",
        s: "dubai-palm-jumeirah-garden-homes-garden-homes-frond-i"
    }, {
        n: "Garden Homes Frond A",
        s: "dubai-palm-jumeirah-garden-homes-garden-homes-frond-a"
    }, {
        n: "Garden Homes Frond C",
        s: "dubai-palm-jumeirah-garden-homes-garden-homes-frond-c"
    }, {
        n: "Golden Mile 5",
        s: "dubai-palm-jumeirah-golden-mile-golden-mile-5"
    }, {
        n: "Signature Villas Frond M",
        s: "dubai-palm-jumeirah-signature-villas-signature-villas-frond-m"
    }, {
        n: "Oceana Aegean",
        s: "dubai-palm-jumeirah-oceana-oceana-aegean"
    }, {
        n: "Oceana Baltic",
        s: "dubai-palm-jumeirah-oceana-oceana-baltic"
    }, {
        n: "Marina Residences 6",
        s: "dubai-palm-jumeirah-marina-residences-marina-residences-6"
    }, {
        n: "Atlantis Tower 2",
        s: "dubai-palm-jumeirah-atlantis-the-palm-atlantis-tower-2"
    }, {
        n: "Gateway Tower 2",
        s: "dubai-palm-jumeirah-gateway-towers-gateway-tower-2"
    }, {
        n: "Mansion 4",
        s: "dubai-palm-jumeirah-w-residences-mansion-4"
    }, {
        n: "Garden Homes Frond H",
        s: "dubai-palm-jumeirah-garden-homes-garden-homes-frond-h"
    }, {
        n: "Garden Homes Frond L",
        s: "dubai-palm-jumeirah-garden-homes-garden-homes-frond-l"
    }, {
        n: "Garden Homes Frond B",
        s: "dubai-palm-jumeirah-garden-homes-garden-homes-frond-b"
    }, {
        n: "Garden Homes Frond J",
        s: "dubai-palm-jumeirah-garden-homes-garden-homes-frond-j"
    }, {
        n: "Golden Mile 6",
        s: "dubai-palm-jumeirah-golden-mile-golden-mile-6"
    }, {
        n: "Golden Mile 8",
        s: "dubai-palm-jumeirah-golden-mile-golden-mile-8"
    }, {
        n: "Al Khushkar",
        s: "dubai-palm-jumeirah-shoreline-apartments-al-khushkar"
    }, {
        n: "Abu Keibal",
        s: "dubai-palm-jumeirah-shoreline-apartments-abu-keibal"
    }, {
        n: "Al Nabat",
        s: "dubai-palm-jumeirah-shoreline-apartments-al-nabat"
    }, {
        n: "Al Sultana",
        s: "dubai-palm-jumeirah-shoreline-apartments-al-sultana"
    }, {
        n: "Serenia Residences West",
        s: "dubai-palm-jumeirah-serenia-residences-the-palm-serenia-residences-west"
    }, {
        n: "Anantara Residences - North",
        s: "dubai-palm-jumeirah-anantara-residences-anantara-residences-north"
    }, {
        n: "Balqis Residences",
        s: "dubai-palm-jumeirah-kingdom-of-sheba-balqis-residences"
    }, {
        n: "Mansion 3",
        s: "dubai-palm-jumeirah-w-residences-mansion-3"
    }, {
        n: "Garden Homes Frond P",
        s: "dubai-palm-jumeirah-garden-homes-garden-homes-frond-p"
    }, {
        n: "Garden Homes Frond N",
        s: "dubai-palm-jumeirah-garden-homes-garden-homes-frond-n"
    }, {
        n: "Garden Homes Frond O",
        s: "dubai-palm-jumeirah-garden-homes-garden-homes-frond-o"
    }, {
        n: "Golden Mile 1",
        s: "dubai-palm-jumeirah-golden-mile-golden-mile-1"
    }, {
        n: "Golden Mile 7",
        s: "dubai-palm-jumeirah-golden-mile-golden-mile-7"
    }, {
        n: "Al Khudrawi",
        s: "dubai-palm-jumeirah-shoreline-apartments-al-khudrawi"
    }, {
        n: "Al Habool",
        s: "dubai-palm-jumeirah-shoreline-apartments-al-habool"
    }, {
        n: "Al Das",
        s: "dubai-palm-jumeirah-shoreline-apartments-al-das"
    }, {
        n: "Canal Cove Frond A",
        s: "dubai-palm-jumeirah-canal-cove-villas-canal-cove-frond-a"
    }, {
        n: "Canal Cove Frond P",
        s: "dubai-palm-jumeirah-canal-cove-villas-canal-cove-frond-p"
    }, {
        n: "Al Fattan Palm Resort",
        s: "dubai-palm-jumeirah-the-crescent-al-fattan-palm-resort"
    }, {
        n: "Kempinski Emerald Palace Hotel",
        s: "dubai-palm-jumeirah-the-crescent-kempinski-emerald-palace-hotel"
    }, {
        n: "Palm Views West",
        s: "dubai-palm-jumeirah-palm-views-palm-views-west"
    }, {
        n: "The Apartments Dubai World Trade Centre A",
        s: "dubai-world-trade-center-the-apartments-dubai-world-trade-centre-the-apartments-dubai-world-trade-centre-a"
    }, {
        n: "The Offices 4",
        s: "dubai-world-trade-center-one-central-the-offices-4"
    }, {
        n: "Garden Homes Frond E",
        s: "dubai-palm-jumeirah-garden-homes-garden-homes-frond-e"
    }, {
        n: "Garden Homes Frond K",
        s: "dubai-palm-jumeirah-garden-homes-garden-homes-frond-k"
    }, {
        n: "Garden Homes Frond G",
        s: "dubai-palm-jumeirah-garden-homes-garden-homes-frond-g"
    }, {
        n: "Golden Mile 10",
        s: "dubai-palm-jumeirah-golden-mile-golden-mile-10"
    }, {
        n: "Golden Mile 3",
        s: "dubai-palm-jumeirah-golden-mile-golden-mile-3"
    }, {
        n: "Golden Mile 4",
        s: "dubai-palm-jumeirah-golden-mile-golden-mile-4"
    }, {
        n: "Al Shahla",
        s: "dubai-palm-jumeirah-shoreline-apartments-al-shahla"
    }, {
        n: "Al Hamri",
        s: "dubai-palm-jumeirah-shoreline-apartments-al-hamri"
    }, {
        n: "Jash Hamad",
        s: "dubai-palm-jumeirah-shoreline-apartments-jash-hamad"
    }, {
        n: "Canal Cove Frond C",
        s: "dubai-palm-jumeirah-canal-cove-villas-canal-cove-frond-c"
    }, {
        n: "Canal Cove Frond F",
        s: "dubai-palm-jumeirah-canal-cove-villas-canal-cove-frond-f"
    }, {
        n: "Canal Cove Frond E",
        s: "dubai-palm-jumeirah-canal-cove-villas-canal-cove-frond-e"
    }, {
        n: "Canal Cove Frond M",
        s: "dubai-palm-jumeirah-canal-cove-villas-canal-cove-frond-m"
    }, {
        n: "Delano Dubai",
        s: "dubai-palm-jumeirah-the-crescent-delano-dubai"
    }, {
        n: "Dream Palm Residence",
        s: "dubai-palm-jumeirah-the-crescent-dream-palm-residence"
    }, {
        n: "Sapphire",
        s: "dubai-palm-jumeirah-tiara-residences-sapphire"
    }, {
        n: "Emerald",
        s: "dubai-palm-jumeirah-tiara-residences-emerald"
    }, {
        n: "The Apartments Dubai World Trade Centre C",
        s: "dubai-world-trade-center-the-apartments-dubai-world-trade-centre-the-apartments-dubai-world-trade-centre-c"
    }, {
        n: "The Offices 5",
        s: "dubai-world-trade-center-one-central-the-offices-5"
    }, {
        n: "Golf Course View Villas",
        s: "dubai-al-garhoud-dubai-creek-golf-and-yacht-club-residences-golf-course-view-villas"
    }, {
        n: "Al Nahda Building",
        s: "dubai-al-nahda-al-nahda-1-al-nahda-building"
    }, {
        n: "Garden Homes Frond F",
        s: "dubai-palm-jumeirah-garden-homes-garden-homes-frond-f"
    }, {
        n: "Garden Homes Frond D",
        s: "dubai-palm-jumeirah-garden-homes-garden-homes-frond-d"
    }, {
        n: "Garden Homes Frond M",
        s: "dubai-palm-jumeirah-garden-homes-garden-homes-frond-m"
    }, {
        n: "Golden Mile 9",
        s: "dubai-palm-jumeirah-golden-mile-golden-mile-9"
    }, {
        n: "Al Anbara",
        s: "dubai-palm-jumeirah-shoreline-apartments-al-anbara"
    }, {
        n: "Al Sarrood",
        s: "dubai-palm-jumeirah-shoreline-apartments-al-sarrood"
    }, {
        n: "Canal Cove Frond N",
        s: "dubai-palm-jumeirah-canal-cove-villas-canal-cove-frond-n"
    }, {
        n: "Canal Cove Frond L",
        s: "dubai-palm-jumeirah-canal-cove-villas-canal-cove-frond-l"
    }, {
        n: "Canal Cove Frond H",
        s: "dubai-palm-jumeirah-canal-cove-villas-canal-cove-frond-h"
    }, {
        n: "Taj Exotica Resort & Spa",
        s: "dubai-palm-jumeirah-the-crescent-taj-exotica-resort-spa"
    }, {
        n: "Rixos The Palm",
        s: "dubai-palm-jumeirah-the-crescent-rixos-the-palm"
    }, {
        n: "Palm Views East",
        s: "dubai-palm-jumeirah-palm-views-palm-views-east"
    }, {
        n: "Ruby",
        s: "dubai-palm-jumeirah-tiara-residences-ruby"
    }, {
        n: "The Apartments Dubai World Trade Centre B",
        s: "dubai-world-trade-center-the-apartments-dubai-world-trade-centre-the-apartments-dubai-world-trade-centre-b"
    }, {
        n: "The Offices 1",
        s: "dubai-world-trade-center-one-central-the-offices-1"
    }, {
        n: "Luxury Homes",
        s: "dubai-world-trade-center-world-trade-centre-residence-luxury-homes"
    }, {
        n: "Makeen Building",
        s: "dubai-al-garhoud-airport-road-area-makeen-building"
    }, {
        n: "Sama Residence",
        s: "dubai-al-nahda-al-nahda-1-sama-residence"
    }, {
        n: "Alyaa Tower",
        s: "dubai-al-nahda-al-nahda-1-alyaa-tower"
    }, {
        n: "Bin Juma 5",
        s: "dubai-al-nahda-al-nahda-1-bin-juma-5"
    }, {
        n: "Al Basri",
        s: "dubai-palm-jumeirah-shoreline-apartments-al-basri"
    }, {
        n: "Al Dabas",
        s: "dubai-palm-jumeirah-shoreline-apartments-al-dabas"
    }, {
        n: "Al Hatimi",
        s: "dubai-palm-jumeirah-shoreline-apartments-al-hatimi"
    }, {
        n: "Al Hallawi",
        s: "dubai-palm-jumeirah-shoreline-apartments-al-hallawi"
    }, {
        n: "Canal Cove Frond B",
        s: "dubai-palm-jumeirah-canal-cove-villas-canal-cove-frond-b"
    }, {
        n: "Canal Cove Frond K",
        s: "dubai-palm-jumeirah-canal-cove-villas-canal-cove-frond-k"
    }, {
        n: "Canal Cove Frond J",
        s: "dubai-palm-jumeirah-canal-cove-villas-canal-cove-frond-j"
    }, {
        n: "Canal Cove Frond O",
        s: "dubai-palm-jumeirah-canal-cove-villas-canal-cove-frond-o"
    }, {
        n: "Muraba Residence",
        s: "dubai-palm-jumeirah-the-crescent-muraba-residence"
    }, {
        n: "The 8",
        s: "dubai-palm-jumeirah-the-crescent-the-8"
    }, {
        n: "Amber",
        s: "dubai-palm-jumeirah-tiara-residences-amber"
    }, {
        n: "Tanzanite",
        s: "dubai-palm-jumeirah-tiara-residences-tanzanite"
    }, {
        n: "Building C1",
        s: "dubai-world-trade-center-dubai-trade-centre-district-building-c1"
    }, {
        n: "The Offices 3",
        s: "dubai-world-trade-center-one-central-the-offices-3"
    }, {
        n: "Creek View Villas",
        s: "dubai-al-garhoud-dubai-creek-golf-and-yacht-club-residences-creek-view-villas"
    }, {
        n: "Al Kaabi Building",
        s: "dubai-al-nahda-al-nahda-1-al-kaabi-building"
    }, {
        n: "Canal Cove Frond G",
        s: "dubai-palm-jumeirah-canal-cove-villas-canal-cove-frond-g"
    }, {
        n: "Canal Cove Frond I",
        s: "dubai-palm-jumeirah-canal-cove-villas-canal-cove-frond-i"
    }, {
        n: "Canal Cove Frond D",
        s: "dubai-palm-jumeirah-canal-cove-villas-canal-cove-frond-d"
    }, {
        n: "Kempinski Palm Residence",
        s: "dubai-palm-jumeirah-the-crescent-kempinski-palm-residence"
    }, {
        n: "Jumeirah Zabeel Saray",
        s: "dubai-palm-jumeirah-the-crescent-jumeirah-zabeel-saray"
    }, {
        n: "Aquamarine",
        s: "dubai-palm-jumeirah-tiara-residences-aquamarine"
    }, {
        n: "Diamond",
        s: "dubai-palm-jumeirah-tiara-residences-diamond"
    }, {
        n: "The Offices 2",
        s: "dubai-world-trade-center-one-central-the-offices-2"
    }, {
        n: "Jumeirah Living",
        s: "dubai-world-trade-center-world-trade-centre-residence-jumeirah-living"
    }, {
        n: "Lake View Villas",
        s: "dubai-al-garhoud-dubai-creek-golf-and-yacht-club-residences-lake-view-villas"
    }, {
        n: "Al Waleed Building",
        s: "dubai-al-nahda-al-nahda-1-al-waleed-building"
    }, {
        n: "Afnan Square",
        s: "dubai-al-nahda-al-nahda-1-afnan-square"
    }, {
        n: "Droor Residency 2",
        s: "dubai-al-nahda-al-nahda-1-droor-residency-2"
    }, {
        n: "Royal House Building",
        s: "dubai-al-nahda-al-nahda-1-royal-house-building"
    }, {
        n: "Al Sabah Tower",
        s: "dubai-al-nahda-al-nahda-2-al-sabah-tower"
    }, {
        n: "Hilly Tower",
        s: "dubai-al-nahda-al-nahda-2-hilly-tower"
    }, {
        n: "Bait Aseel",
        s: "dubai-al-nahda-al-nahda-2-bait-aseel"
    }, {
        n: "Al Noor Tower",
        s: "dubai-al-nahda-al-nahda-2-al-noor-tower"
    }, {
        n: "Al Ahli House 2",
        s: "dubai-al-nahda-al-nahda-2-al-ahli-house-2"
    }, {
        n: "Syann Park 3",
        s: "dubai-arjan-syann-park-syann-park-3"
    }, {
        n: "HEMSAM Building",
        s: "dubai-al-nahda-al-nahda-1-hemsam-building"
    }, {
        n: "Droor Residency 1",
        s: "dubai-al-nahda-al-nahda-1-droor-residency-1"
    }, {
        n: "Al Shamsi Building",
        s: "dubai-al-nahda-al-nahda-1-al-shamsi-building"
    }, {
        n: "Sunrise Building",
        s: "dubai-al-nahda-al-nahda-2-sunrise-building"
    }, {
        n: "Desert Group 4",
        s: "dubai-al-nahda-al-nahda-2-desert-group-4"
    }, {
        n: "SS Lootah Building",
        s: "dubai-al-nahda-al-nahda-2-ss-lootah-building"
    }, {
        n: "Al Shaya Building",
        s: "dubai-al-nahda-al-nahda-2-al-shaya-building"
    }, {
        n: "Syann Park 1",
        s: "dubai-arjan-syann-park-syann-park-1"
    }, {
        n: "Green Diamond 2",
        s: "dubai-arjan-green-diamond-green-diamond-2"
    }, {
        n: "Central Towers A",
        s: "dubai-arjan-central-towers-central-towers-a"
    }, {
        n: "Entertainment Foyer",
        s: "dubai-jumeirah-islands-european-clusters-entertainment-foyer"
    }, {
        n: "Master View",
        s: "dubai-jumeirah-islands-oasis-clusters-master-view"
    }, {
        n: "Master View",
        s: "dubai-jumeirah-islands-contemporary-cluster-master-view"
    }, {
        n: "Tower B1",
        s: "dubai-the-hills-vida-hotel-tower-b1"
    }, {
        n: "Vida Residence 2",
        s: "dubai-the-hills-vida-residence-vida-residence-2"
    }, {
        n: "Design House",
        s: "dubai-al-sufouh-al-sufouh-1-design-house"
    }, {
        n: "Al Wahaibi Building",
        s: "dubai-al-sufouh-al-sufouh-1-al-wahaibi-building"
    }, {
        n: "Cloud 9",
        s: "dubai-al-sufouh-acacia-avenues-cloud-9"
    }, {
        n: "AK Residence",
        s: "dubai-al-sufouh-acacia-avenues-ak-residence"
    }, {
        n: "Innovation Hub",
        s: "dubai-al-sufouh-al-sufouh-2-innovation-hub"
    }, {
        n: "Sahara Meadows 2",
        s: "dubai-dubai-industrial-park-sahara-meadows-sahara-meadows-2"
    }, {
        n: "Saeed Tower 1",
        s: "dubai-sheikh-zayed-road-saeed-towers-saeed-tower-1"
    }, {
        n: "Al Kawakeb Building A",
        s: "dubai-sheikh-zayed-road-al-kawakeb-buildings-al-kawakeb-building-a"
    }, {
        n: "Tower A",
        s: "dubai-sheikh-zayed-road-api-trio-towers-tower-a"
    }, {
        n: "Parklane Residence",
        s: "dubai-dubai-south-dubai-world-central-parklane-parklane-residence"
    }, {
        n: "Al Nawras Hotel Apartments",
        s: "dubai-al-nahda-al-nahda-1-al-nawras-hotel-apartments"
    }, {
        n: "Saeed Al Ketbi Tower",
        s: "dubai-al-nahda-al-nahda-2-saeed-al-ketbi-tower"
    }, {
        n: "Al Gurg Twin Towers",
        s: "dubai-al-nahda-al-nahda-2-al-gurg-twin-towers"
    }, {
        n: "Central Towers B",
        s: "dubai-arjan-central-towers-central-towers-b"
    }, {
        n: "Master View",
        s: "dubai-jumeirah-islands-european-clusters-master-view"
    }, {
        n: "Hili Rayhaan by Rotana",
        s: "al-ain-al-hili-hili-rayhaan-by-rotana"
    }, {
        n: "Mirage Tower",
        s: "dubai-al-nahda-al-nahda-2-mirage-tower"
    }, {
        n: "Al Nahda 2 Tower",
        s: "dubai-al-nahda-al-nahda-2-al-nahda-2-tower"
    }, {
        n: "Manara Residence",
        s: "dubai-al-nahda-al-nahda-2-manara-residence"
    }, {
        n: "Art 5",
        s: "dubai-al-nahda-al-nahda-2-art-5"
    }, {
        n: "Syann Park 2",
        s: "dubai-arjan-syann-park-syann-park-2"
    }, {
        n: "Green Diamond 3",
        s: "dubai-arjan-green-diamond-green-diamond-3"
    }, {
        n: "Master View",
        s: "dubai-jumeirah-islands-islamic-clusters-master-view"
    }, {
        n: "Garden Hall",
        s: "dubai-jumeirah-islands-european-clusters-garden-hall"
    }, {
        n: "Garden Hall",
        s: "dubai-jumeirah-islands-oasis-clusters-garden-hall"
    }, {
        n: "Entertainment Foyer",
        s: "dubai-jumeirah-islands-contemporary-cluster-entertainment-foyer"
    }, {
        n: "Tower B2",
        s: "dubai-the-hills-vida-hotel-tower-b2"
    }, {
        n: "C1",
        s: "dubai-the-hills-the-hills-c-c1"
    }, {
        n: "Al Sufouh Villas by Meraas",
        s: "dubai-al-sufouh-al-sufouh-1-al-sufouh-villas-by-meraas"
    }, {
        n: "Al Bahia 1",
        s: "dubai-al-sufouh-al-bahia-al-bahia-1"
    }, {
        n: "Fulva Villas",
        s: "dubai-al-sufouh-acacia-avenues-fulva-villas"
    }, {
        n: "Dhalia",
        s: "dubai-al-sufouh-acacia-avenues-dhalia"
    }, {
        n: "DPG Building",
        s: "dubai-al-sufouh-al-sufouh-2-dpg-building"
    }, {
        n: "Emaar Business Park Building 2",
        s: "dubai-sheikh-zayed-road-emaar-business-park-emaar-business-park-building-2"
    }, {
        n: "Beda Bint Soud",
        s: "al-ain-al-hili-beda-bint-soud"
    }, {
        n: "Ali Nasser Lootah Building",
        s: "dubai-al-nahda-al-nahda-2-ali-nasser-lootah-building"
    }, {
        n: "Abdul Rasool Building",
        s: "dubai-al-nahda-al-nahda-2-abdul-rasool-building"
    }, {
        n: "Al Hawai Twin Towers",
        s: "dubai-al-nahda-al-nahda-2-al-hawai-twin-towers"
    }, {
        n: "Al Habbai Building",
        s: "dubai-al-nahda-al-nahda-2-al-habbai-building"
    }, {
        n: "Green Diamond 5",
        s: "dubai-arjan-green-diamond-green-diamond-5"
    }, {
        n: "Green Diamond 4",
        s: "dubai-arjan-green-diamond-green-diamond-4"
    }, {
        n: "Garden Hall",
        s: "dubai-jumeirah-islands-tropical-clusters-garden-hall"
    }, {
        n: "Entertainment Foyer",
        s: "dubai-jumeirah-islands-mediterranean-clusters-entertainment-foyer"
    }, {
        n: "B1",
        s: "dubai-the-hills-the-hills-b-b1"
    }, {
        n: "Vida Residence 3",
        s: "dubai-the-hills-vida-residence-vida-residence-3"
    }, {
        n: "A2",
        s: "dubai-the-hills-the-hills-a-a2"
    }, {
        n: "Pearl Residence",
        s: "dubai-al-sufouh-al-sufouh-1-pearl-residence"
    }, {
        n: "Hilliana Tower",
        s: "dubai-al-sufouh-acacia-avenues-hilliana-tower"
    }, {
        n: "Bahia Residence",
        s: "dubai-al-sufouh-acacia-avenues-bahia-residence"
    }, {
        n: "J5",
        s: "dubai-al-sufouh-al-sufouh-2-j5"
    }, {
        n: "Sahara Meadows",
        s: "dubai-dubai-industrial-park-sahara-meadows-2-sahara-meadows"
    }, {
        n: "Emaar Business Park Building 4",
        s: "dubai-sheikh-zayed-road-emaar-business-park-emaar-business-park-building-4"
    }, {
        n: "Al Kawakeb Building E",
        s: "dubai-sheikh-zayed-road-al-kawakeb-buildings-al-kawakeb-building-e"
    }, {
        n: "Al Moosa Tower 2",
        s: "dubai-sheikh-zayed-road-al-moosa-towers-al-moosa-tower-2"
    }, {
        n: "Tower C",
        s: "dubai-sheikh-zayed-road-api-trio-towers-tower-c"
    }, {
        n: "Parklane Townhouses",
        s: "dubai-dubai-south-dubai-world-central-parklane-parklane-townhouses"
    }, {
        n: "The Rubix 2",
        s: "dubai-dubai-south-dubai-world-central-the-rubix-the-rubix-2"
    }, {
        n: "Entertainment Foyer",
        s: "dubai-jumeirah-islands-islamic-clusters-entertainment-foyer"
    }, {
        n: "Master View",
        s: "dubai-jumeirah-islands-tropical-clusters-master-view"
    }, {
        n: "Master View",
        s: "dubai-jumeirah-islands-mediterranean-clusters-master-view"
    }, {
        n: "Vida Residence 1",
        s: "dubai-the-hills-vida-residence-vida-residence-1"
    }, {
        n: "C2",
        s: "dubai-the-hills-the-hills-c-c2"
    }, {
        n: "Al Sofouh Suites",
        s: "dubai-al-sufouh-al-sufouh-1-al-sofouh-suites"
    }, {
        n: "Al Bahia 2",
        s: "dubai-al-sufouh-al-bahia-al-bahia-2"
    }, {
        n: "Decora Villas",
        s: "dubai-al-sufouh-acacia-avenues-decora-villas"
    }, {
        n: "Tamani Hotel Marina",
        s: "dubai-al-sufouh-al-sufouh-road-tamani-hotel-marina"
    }, {
        n: "Arenco Villas",
        s: "dubai-al-sufouh-al-sufouh-2-arenco-villas"
    }, {
        n: "Emaar Business Park Building 3",
        s: "dubai-sheikh-zayed-road-emaar-business-park-emaar-business-park-building-3"
    }, {
        n: "Al Kawakeb Building D",
        s: "dubai-sheikh-zayed-road-al-kawakeb-buildings-al-kawakeb-building-d"
    }, {
        n: "Al Moosa Tower 1",
        s: "dubai-sheikh-zayed-road-al-moosa-towers-al-moosa-tower-1"
    }, {
        n: "Al Rostamani Tower A",
        s: "dubai-sheikh-zayed-road-al-rostomani-towers-al-rostamani-tower-a"
    }, {
        n: "City Tower 2",
        s: "dubai-sheikh-zayed-road-city-towers-city-tower-2"
    }, {
        n: "The Rubix 3",
        s: "dubai-dubai-south-dubai-world-central-the-rubix-the-rubix-3"
    }, {
        n: "MAG 565",
        s: "dubai-dubai-south-dubai-world-central-mag-5-mag-565"
    }, {
        n: "MAG 550",
        s: "dubai-dubai-south-dubai-world-central-mag-5-boulevard-mag-550"
    }, {
        n: "Golf Links",
        s: "dubai-dubai-south-dubai-world-central-emaar-south-golf-links"
    }, {
        n: "Zahra Breeze Apartments 1A",
        s: "dubai-town-square-zahra-breeze-apartments-zahra-breeze-apartments-1a"
    }, {
        n: "B2",
        s: "dubai-the-hills-the-hills-b-b2"
    }, {
        n: "Vida Residence 4",
        s: "dubai-the-hills-vida-residence-vida-residence-4"
    }, {
        n: "A1",
        s: "dubai-the-hills-the-hills-a-a1"
    }, {
        n: "J8",
        s: "dubai-al-sufouh-al-sufouh-1-j8"
    }, {
        n: "Abyaar Tower",
        s: "dubai-al-sufouh-acacia-avenues-abyaar-tower"
    }, {
        n: "Olgana Tower",
        s: "dubai-al-sufouh-acacia-avenues-olgana-tower"
    }, {
        n: "Sajwani Building",
        s: "dubai-al-sufouh-al-sufouh-2-sajwani-building"
    }, {
        n: "Sahara Meadows 1",
        s: "dubai-dubai-industrial-park-sahara-meadows-sahara-meadows-1"
    }, {
        n: "Emaar Business Park Building 1",
        s: "dubai-sheikh-zayed-road-emaar-business-park-emaar-business-park-building-1"
    }, {
        n: "Al Kawakeb Building B",
        s: "dubai-sheikh-zayed-road-al-kawakeb-buildings-al-kawakeb-building-b"
    }, {
        n: "Emirates Office Tower",
        s: "dubai-sheikh-zayed-road-emirates-towers-emirates-office-tower"
    }, {
        n: "Tower B",
        s: "dubai-sheikh-zayed-road-api-trio-towers-tower-b"
    }, {
        n: "Parklane Views",
        s: "dubai-dubai-south-dubai-world-central-parklane-parklane-views"
    }, {
        n: "MAG 510",
        s: "dubai-dubai-south-dubai-world-central-mag-5-mag-510"
    }, {
        n: "MAG 520",
        s: "dubai-dubai-south-dubai-world-central-mag-5-mag-520"
    }, {
        n: "Saffron",
        s: "dubai-dubai-south-dubai-world-central-emaar-south-saffron"
    }, {
        n: "Zahra Breeze Apartments 2A",
        s: "dubai-town-square-zahra-breeze-apartments-zahra-breeze-apartments-2a"
    }, {
        n: "Jenna Main Square 1",
        s: "dubai-town-square-jenna-main-square-jenna-main-square-1"
    }, {
        n: "Zahra Apartments 2A",
        s: "dubai-town-square-zahra-apartments-zahra-apartments-2a"
    }, {
        n: "Safi I",
        s: "dubai-town-square-safi-safi-i"
    }, {
        n: "Beach Vista",
        s: "dubai-dubai-harbour-emaar-beachfront-beach-vista"
    }, {
        n: "Al Misbah",
        s: "al-ain-al-hili-al-misbah"
    }, {
        n: "Hazza Bin Zayed Stadium",
        s: "al-ain-al-jimi-hazza-bin-zayed-stadium"
    }, {
        n: "Al Kawakeb Building C",
        s: "dubai-sheikh-zayed-road-al-kawakeb-buildings-al-kawakeb-building-c"
    }, {
        n: "Al Rostamani Tower B",
        s: "dubai-sheikh-zayed-road-al-rostomani-towers-al-rostamani-tower-b"
    }, {
        n: "City Tower 1",
        s: "dubai-sheikh-zayed-road-city-towers-city-tower-1"
    }, {
        n: "The Rubix 1",
        s: "dubai-dubai-south-dubai-world-central-the-rubix-the-rubix-1"
    }, {
        n: "MAG 560",
        s: "dubai-dubai-south-dubai-world-central-mag-5-mag-560"
    }, {
        n: "MAG 525",
        s: "dubai-dubai-south-dubai-world-central-mag-5-boulevard-mag-525"
    }, {
        n: "MAG 535",
        s: "dubai-dubai-south-dubai-world-central-mag-5-boulevard-mag-535"
    }, {
        n: "Zahra Breeze Apartments 3B",
        s: "dubai-town-square-zahra-breeze-apartments-zahra-breeze-apartments-3b"
    }, {
        n: "Zahra Apartments 1A",
        s: "dubai-town-square-zahra-apartments-zahra-apartments-1a"
    }, {
        n: "Warda Apartments 2B",
        s: "dubai-town-square-warda-apartments-warda-apartments-2b"
    }, {
        n: "Sunrise Bay",
        s: "dubai-dubai-harbour-emaar-beachfront-sunrise-bay"
    }, {
        n: "Beach Homes",
        s: "ras-al-khaimah-al-hamra-village-falcon-island-beach-homes"
    }, {
        n: "Marina Apartments C",
        s: "ras-al-khaimah-al-hamra-village-al-hamra-marina-residences-marina-apartments-c"
    }, {
        n: "Marina Apartments F",
        s: "ras-al-khaimah-al-hamra-village-al-hamra-marina-residences-marina-apartments-f"
    }, {
        n: "Royal Breeze 5",
        s: "ras-al-khaimah-al-hamra-village-royal-breeze-royal-breeze-5"
    }, {
        n: "Royal Breeze 7",
        s: "ras-al-khaimah-al-hamra-village-royal-breeze-royal-breeze-7"
    }, {
        n: "Lagoon B3",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b3"
    }, {
        n: "Lagoon B10",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b10"
    }, {
        n: "Al Ameriya",
        s: "al-ain-al-jimi-al-ameriya"
    }, {
        n: "Raha Building",
        s: "abu-dhabi-al-khalidiya-al-istiqlal-street-raha-building"
    }, {
        n: "MAG 555",
        s: "dubai-dubai-south-dubai-world-central-mag-5-mag-555"
    }, {
        n: "MAG 545",
        s: "dubai-dubai-south-dubai-world-central-mag-5-boulevard-mag-545"
    }, {
        n: "Expo Golf Villas",
        s: "dubai-dubai-south-dubai-world-central-emaar-south-expo-golf-villas"
    }, {
        n: "Zahra Breeze Apartments 1B",
        s: "dubai-town-square-zahra-breeze-apartments-zahra-breeze-apartments-1b"
    }, {
        n: "Jenna Main Square 2",
        s: "dubai-town-square-jenna-main-square-jenna-main-square-2"
    }, {
        n: "Warda Apartments 1A",
        s: "dubai-town-square-warda-apartments-warda-apartments-1a"
    }, {
        n: "Safi II",
        s: "dubai-town-square-safi-safi-ii"
    }, {
        n: "Beach Isle",
        s: "dubai-dubai-harbour-emaar-beachfront-beach-isle"
    }, {
        n: "Marina Apartments A",
        s: "ras-al-khaimah-al-hamra-village-al-hamra-marina-residences-marina-apartments-a"
    }, {
        n: "Marina Apartments E",
        s: "ras-al-khaimah-al-hamra-village-al-hamra-marina-residences-marina-apartments-e"
    }, {
        n: "Royal Breeze 1",
        s: "ras-al-khaimah-al-hamra-village-royal-breeze-royal-breeze-1"
    }, {
        n: "Royal Breeze 6",
        s: "ras-al-khaimah-al-hamra-village-royal-breeze-royal-breeze-6"
    }, {
        n: "Lagoon B2",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b2"
    }, {
        n: "Lagoon B9",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b9"
    }, {
        n: "Lagoon B20B",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b20b"
    }, {
        n: "Lagoon B20A",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b20a"
    }, {
        n: "Lagoon B13",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b13"
    }, {
        n: "Lagoon B5",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b5"
    }, {
        n: "Terrapin",
        s: "ras-al-khaimah-al-marjan-island-la-hoya-bay-terrapin"
    }, {
        n: "Pacific Samoa",
        s: "ras-al-khaimah-al-marjan-island-pacific-pacific-samoa"
    }, {
        n: "Al Ragayeb",
        s: "al-ain-al-towayya-al-ragayeb"
    }, {
        n: "MAG 530",
        s: "dubai-dubai-south-dubai-world-central-mag-5-boulevard-mag-530"
    }, {
        n: "Golf Views",
        s: "dubai-dubai-south-dubai-world-central-emaar-south-golf-views"
    }, {
        n: "Zahra Breeze Apartments 3A",
        s: "dubai-town-square-zahra-breeze-apartments-zahra-breeze-apartments-3a"
    }, {
        n: "Zahra Apartments 1B",
        s: "dubai-town-square-zahra-apartments-zahra-apartments-1b"
    }, {
        n: "Warda Apartments 2A",
        s: "dubai-town-square-warda-apartments-warda-apartments-2a"
    }, {
        n: "Marina Vista",
        s: "dubai-dubai-harbour-emaar-beachfront-marina-vista"
    }, {
        n: "The Mansions on Falcon Island",
        s: "ras-al-khaimah-al-hamra-village-falcon-island-the-mansions-on-falcon-island"
    }, {
        n: "Marina Apartments D",
        s: "ras-al-khaimah-al-hamra-village-al-hamra-marina-residences-marina-apartments-d"
    }, {
        n: "Marina Apartments",
        s: "ras-al-khaimah-al-hamra-village-al-hamra-marina-residences-marina-apartments"
    }, {
        n: "Royal breeze 3",
        s: "ras-al-khaimah-al-hamra-village-royal-breeze-royal-breeze-3"
    }, {
        n: "VERDE",
        s: "ras-al-khaimah-mina-al-arab-hayat-island-verde"
    }, {
        n: "Lagoon B6",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b6"
    }, {
        n: "Lagoon B14",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b14"
    }, {
        n: "Lagoon B17",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b17"
    }, {
        n: "Lagoon B15",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b15"
    }, {
        n: "Lagoon B12",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b12"
    }, {
        n: "Flamingo",
        s: "ras-al-khaimah-al-marjan-island-la-hoya-bay-flamingo"
    }, {
        n: "Yakout",
        s: "ras-al-khaimah-al-marjan-island-bab-al-bahar-yakout"
    }, {
        n: "Pacific Tahiti",
        s: "ras-al-khaimah-al-marjan-island-pacific-pacific-tahiti"
    }, {
        n: "Al Nayfa",
        s: "al-ain-al-hili-al-nayfa"
    }, {
        n: "Civic Center",
        s: "al-ain-al-jimi-civic-center"
    }, {
        n: "Al Nudood",
        s: "al-ain-al-jimi-al-nudood"
    }, {
        n: "Blue Tower A",
        s: "abu-dhabi-al-dhafrah-blue-towers-blue-tower-a"
    }, {
        n: "ZADCO Complex Building B",
        s: "abu-dhabi-al-khalidiya-zadco-complex-zadco-complex-building-b"
    }, {
        n: "Lamar Residences",
        s: "abu-dhabi-al-raha-beach-al-seef-lamar-residences"
    }, {
        n: "Al Khor Tower A2",
        s: "ajman-ajman-downtown-al-khor-towers-al-khor-tower-a2"
    }, {
        n: "Zahra Apartments 2B",
        s: "dubai-town-square-zahra-apartments-zahra-apartments-2b"
    }, {
        n: "Warda Apartments 1B",
        s: "dubai-town-square-warda-apartments-warda-apartments-1b"
    }, {
        n: "Grand Bleu Tower",
        s: "dubai-dubai-harbour-emaar-beachfront-grand-bleu-tower"
    }, {
        n: "Canal Homes",
        s: "ras-al-khaimah-al-hamra-village-falcon-island-canal-homes"
    }, {
        n: "Marina Apartments G",
        s: "ras-al-khaimah-al-hamra-village-al-hamra-marina-residences-marina-apartments-g"
    }, {
        n: "Royal Breeze 4",
        s: "ras-al-khaimah-al-hamra-village-royal-breeze-royal-breeze-4"
    }, {
        n: "Royal Breeze Townhouses",
        s: "ras-al-khaimah-al-hamra-village-royal-breeze-royal-breeze-townhouses"
    }, {
        n: "Lagoon B4",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b4"
    }, {
        n: "Lagoon B11",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b11"
    }, {
        n: "Lagoon B21A",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b21a"
    }, {
        n: "Lagoon B7",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b7"
    }, {
        n: "Lagoon B22",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b22"
    }, {
        n: "Santorini",
        s: "ras-al-khaimah-al-marjan-island-la-hoya-bay-santorini"
    }, {
        n: "Kahraman",
        s: "ras-al-khaimah-al-marjan-island-bab-al-bahar-kahraman"
    }, {
        n: "Julphar Commercial Tower",
        s: "ras-al-khaimah-al-nakheel-julphar-towers-julphar-commercial-tower"
    }, {
        n: "Pacific Tonga",
        s: "ras-al-khaimah-al-marjan-island-pacific-pacific-tonga"
    }, {
        n: "Blue Tower B",
        s: "abu-dhabi-al-dhafrah-blue-towers-blue-tower-b"
    }, {
        n: "Al Sana 2",
        s: "abu-dhabi-al-raha-beach-al-muneera-al-sana-2"
    }, {
        n: "The Wave",
        s: "abu-dhabi-al-reem-island-najmat-abu-dhabi-the-wave"
    }, {
        n: "Tahous Rashid Al Naemi",
        s: "abu-dhabi-al-manaseer-khalifa-bin-shakhbout-street-tahous-rashid-al-naemi"
    }, {
        n: "Ministries Complex",
        s: "abu-dhabi-eastern-road-khalifa-park-ministries-complex"
    }, {
        n: "Al Rashidiya Towers",
        s: "ajman-ajman-downtown-al-rashidiya-al-rashidiya-towers"
    }, {
        n: "Al Khail Tower 2",
        s: "ajman-ajman-downtown-al-khail-towers-al-khail-tower-2"
    }, {
        n: "Crystal Plaza",
        s: "sharjah-al-majaz-al-majaz-1-crystal-plaza"
    }, {
        n: "Ibtikar 4",
        s: "sharjah-al-majaz-al-majaz-2-ibtikar-4"
    }, {
        n: "Riviera Tower",
        s: "sharjah-al-majaz-al-majaz-3-riviera-tower"
    }, {
        n: "Oceana Apartments",
        s: "ras-al-khaimah-al-hamra-village-ocean-breeze-oceana-apartments"
    }, {
        n: "Royal breeze 2",
        s: "ras-al-khaimah-al-hamra-village-royal-breeze-royal-breeze-2"
    }, {
        n: "Lagoon B1",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b1"
    }, {
        n: "Lagoon B8",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b8"
    }, {
        n: "Lagoon B20",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b20"
    }, {
        n: "Lagoon B19",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b19"
    }, {
        n: "Lagoon B21B",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b21b"
    }, {
        n: "Lagoon B18",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b18"
    }, {
        n: "Starfish",
        s: "ras-al-khaimah-al-marjan-island-la-hoya-bay-starfish"
    }, {
        n: "Fayrouz",
        s: "ras-al-khaimah-al-marjan-island-bab-al-bahar-fayrouz"
    }, {
        n: "Pacific Polynesia",
        s: "ras-al-khaimah-al-marjan-island-pacific-pacific-polynesia"
    }, {
        n: "Mermaid Building",
        s: "abu-dhabi-al-khalidiya-khalidiya-street-mermaid-building"
    }, {
        n: "Salama Residence",
        s: "abu-dhabi-al-khalidiya-cornich-al-khalidiya-salama-residence"
    }, {
        n: "Al Bateen Park",
        s: "abu-dhabi-al-bateen-al-khaleej-al-arabi-street-al-bateen-park"
    }, {
        n: "Al Raha Mall",
        s: "abu-dhabi-al-raha-beach-khor-al-raha-al-raha-mall"
    }, {
        n: "Bawabat Al Sharq",
        s: "abu-dhabi-baniyas-baniyas-east-bawabat-al-sharq"
    }, {
        n: "M-40",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-40"
    }, {
        n: "Tower 2",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-2"
    }, {
        n: "Ajman One Tower 3",
        s: "ajman-ajman-downtown-ajman-one-ajman-one-tower-3"
    }, {
        n: "Falcon Tower 1",
        s: "ajman-ajman-downtown-falcon-towers-falcon-tower-1"
    }, {
        n: "Crown Palace Hotel",
        s: "ajman-al-rashidiya-al-rashidiya-1-crown-palace-hotel"
    }, {
        n: "Babel Towers",
        s: "sharjah-al-majaz-al-majaz-3-babel-towers"
    }, {
        n: "Bukhatir Tower",
        s: "sharjah-al-majaz-al-majaz-3-bukhatir-tower"
    }, {
        n: "Jehaan Residence 4",
        s: "dubai-jumeirah-village-circle-jehaan-residences-jehaan-residence-4"
    }, {
        n: "Lagoon B24",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b24"
    }, {
        n: "Lagoon B23",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b23"
    }, {
        n: "Lagoon B16",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b16"
    }, {
        n: "Marmar",
        s: "ras-al-khaimah-al-marjan-island-bab-al-bahar-marmar"
    }, {
        n: "Julphar Residential Tower",
        s: "ras-al-khaimah-al-nakheel-julphar-towers-julphar-residential-tower"
    }, {
        n: "Pacific Bora Bora",
        s: "ras-al-khaimah-al-marjan-island-pacific-pacific-bora-bora"
    }, {
        n: "Al Heel Tower",
        s: "abu-dhabi-al-khalidiya-mubarak-bin-mohammed-street-al-heel-tower"
    }, {
        n: "Al Remah Tower",
        s: "abu-dhabi-al-khalidiya-al-hosn-al-remah-tower"
    }, {
        n: "Al Hadeel",
        s: "abu-dhabi-al-raha-beach-al-bandar-al-hadeel"
    }, {
        n: "Al Manara",
        s: "abu-dhabi-al-raha-beach-al-bandar-al-manara"
    }, {
        n: "Burooj Views",
        s: "abu-dhabi-al-reem-island-marina-square-burooj-views"
    }, {
        n: "Marina Bay",
        s: "abu-dhabi-al-reem-island-city-of-lights-marina-bay"
    }, {
        n: "Horizon Tower A",
        s: "abu-dhabi-al-reem-island-city-of-lights-horizon-tower-a"
    }, {
        n: "Beach Villas",
        s: "abu-dhabi-al-raha-beach-al-zeina-beach-villas"
    }, {
        n: "Jannah Place City Center",
        s: "abu-dhabi-city-downtown-al-falah-street-jannah-place-city-center"
    }, {
        n: "Sky Tower",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-sky-tower"
    }, {
        n: "Julfar Residence",
        s: "abu-dhabi-al-reem-island-city-of-lights-julfar-residence"
    }, {
        n: "Saadiyat Beach Villas",
        s: "abu-dhabi-saadiyat-island-saadiyat-beach-saadiyat-beach-villas"
    }, {
        n: "Tower 2",
        s: "abu-dhabi-al-aman-al-bustan-complex-tower-2"
    }, {
        n: "Etihad Tower 1",
        s: "abu-dhabi-corniche-road-etihad-towers-etihad-tower-1"
    }, {
        n: "Al Khail Tower 3",
        s: "ajman-ajman-downtown-al-khail-towers-al-khail-tower-3"
    }, {
        n: "Sharjah 555 Tower",
        s: "sharjah-al-khan-al-khan-corniche-sharjah-555-tower"
    }, {
        n: "Jasmine Leaf 4",
        s: "dubai-al-barari-jasmine-leaf-jasmine-leaf-4"
    }, {
        n: "Sajaya Plaza",
        s: "dubai-al-barsha-al-barsha-3-sajaya-plaza"
    }, {
        n: "Hadaeq Mohammed Bin Rashid",
        s: "dubai-al-quoz-al-quoz-industrial-area-hadaeq-mohammed-bin-rashid"
    }, {
        n: "Boulevard Central Tower 1",
        s: "dubai-downtown-dubai-boulevard-central-towers-boulevard-central-tower-1"
    }, {
        n: "Building B",
        s: "abu-dhabi-al-raha-beach-al-zeina-building-b"
    }, {
        n: "Y Tower",
        s: "abu-dhabi-city-downtown-cbd-central-business-district-y-tower"
    }, {
        n: "Al Sharq Towers",
        s: "abu-dhabi-al-reem-island-najmat-abu-dhabi-al-sharq-towers"
    }, {
        n: "Oasis Residences",
        s: "abu-dhabi-al-reem-island-shams-abu-dhabi-oasis-residences"
    }, {
        n: "M-1",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-1"
    }, {
        n: "M-2",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-2"
    }, {
        n: "Zayed Military City Tower 6",
        s: "abu-dhabi-zayed-military-city-zayed-military-city-towers-zayed-military-city-tower-6"
    }, {
        n: "Mamsha Al Saadiyat",
        s: "abu-dhabi-saadiyat-island-saadiyat-cultural-district-mamsha-al-saadiyat"
    }, {
        n: "Tower 7",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-7"
    }, {
        n: "Tower 1",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-1"
    }, {
        n: "Tower 25",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-25"
    }, {
        n: "Ajman One Tower 6",
        s: "ajman-ajman-downtown-ajman-one-ajman-one-tower-6"
    }, {
        n: "Al Khor Tower B7",
        s: "ajman-ajman-downtown-al-khor-towers-al-khor-tower-b7"
    }, {
        n: "M-26",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-26"
    }, {
        n: "M-12",
        s: "abu-dhabi-mussafah-mussafah-industrial-area-m-12"
    }, {
        n: "Saadiyat Beach Residences",
        s: "abu-dhabi-saadiyat-island-saadiyat-beach-saadiyat-beach-residences"
    }, {
        n: "Liwa Centre Tower 1",
        s: "abu-dhabi-hamdan-street-liwa-centre-towers-liwa-centre-tower-1"
    }, {
        n: "Tower 6",
        s: "abu-dhabi-al-reef-al-reef-downtown-tower-6"
    }, {
        n: "Al Khor Tower A7",
        s: "ajman-ajman-downtown-al-khor-towers-al-khor-tower-a7"
    }, {
        n: "Al Khor Tower A1",
        s: "ajman-ajman-downtown-al-khor-towers-al-khor-tower-a1"
    }, {
        n: "Paradise Lakes Tower B2",
        s: "ajman-emirates-city-paradise-lakes-towers-paradise-lakes-tower-b2"
    }, {
        n: "Palm Tower",
        s: "sharjah-al-khan-al-khan-lagoon-palm-tower"
    }, {
        n: "Al Kanana Building",
        s: "sharjah-al-khan-al-khan-lagoon-al-kanana-building"
    }, {
        n: "Dar Al Majaz",
        s: "sharjah-al-majaz-jamal-abdul-nasser-street-dar-al-majaz"
    }, {
        n: "Al Zahia 4",
        s: "sharjah-muwaileh-commercial-al-zahia-al-zahia-4"
    }, {
        n: "Jasmine Leaf 6",
        s: "dubai-al-barari-jasmine-leaf-jasmine-leaf-6"
    }, {
        n: "Villa Lantana 1",
        s: "dubai-al-barsha-villa-lantana-villa-lantana-1"
    }, {
        n: "Desert Group 2",
        s: "dubai-al-barsha-al-barsha-1-desert-group-2"
    }, {
        n: "Jouri 6 Tower C",
        s: "dubai-jumeirah-village-circle-jouri-residence-6-jouri-6-tower-c"
    }, {
        n: "Ibtikar 2",
        s: "sharjah-al-majaz-al-majaz-2-ibtikar-2"
    }, {
        n: "SG Al Soor Building",
        s: "sharjah-al-qasemiya-al-soor-sg-al-soor-building"
    }, {
        n: "Al Ramla East",
        s: "sharjah-halwan-al-ramla-al-ramla-east"
    }, {
        n: "Emerald Barsha 2",
        s: "dubai-al-barsha-al-barsha-1-emerald-barsha-2"
    }, {
        n: "Cayan Cantara by Rotana",
        s: "dubai-al-barsha-al-barsha-south-cayan-cantara-by-rotana"
    }, {
        n: "SAQ",
        s: "dubai-al-quoz-al-quoz-industrial-area-saq"
    }, {
        n: "Al Qusais Building",
        s: "dubai-al-qusais-al-qusais-residential-area-al-qusais-building"
    }, {
        n: "Al Hudaiba Building",
        s: "dubai-al-satwa-al-diyafah-al-hudaiba-building"
    }, {
        n: "Executive Tower H",
        s: "dubai-business-bay-executive-towers-executive-tower-h"
    }, {
        n: "Churchill Executive Tower",
        s: "dubai-business-bay-churchill-towers-churchill-executive-tower"
    }, {
        n: "Al Muteena Technic Building",
        s: "dubai-deira-salah-al-din-street-al-muteena-technic-building"
    }, {
        n: "Al Kunooz Business Center",
        s: "dubai-deira-port-saeed-al-kunooz-business-center"
    }, {
        n: "Building 108 to Building 137",
        s: "dubai-discovery-gardens-contemporary-cluster-building-108-to-building-137"
    }, {
        n: "The Residences 1",
        s: "dubai-downtown-dubai-the-residences-the-residences-1"
    }, {
        n: "Maysan Tower 1",
        s: "dubai-dubai-land-maysan-towers-maysan-tower-1"
    }, {
        n: "Asayel",
        s: "dubai-umm-suqeim-madinat-jumeirah-living-asayel"
    }, {
        n: "Block A",
        s: "sharjah-al-nahda-al-nadha-jam-residential-complex-block-a"
    }, {
        n: "Liwa Tower",
        s: "sharjah-al-qasemiya-al-nad-liwa-tower"
    }, {
        n: "Al Qusias Industrial Area 1",
        s: "dubai-al-qusais-al-qusais-industrial-area-al-qusias-industrial-area-1"
    }, {
        n: "Gold and Diamond Park Building 5",
        s: "dubai-al-quoz-al-quoz-3-gold-and-diamond-park-building-5"
    }, {
        n: "Al Qusais 2",
        s: "dubai-al-qusais-al-qusais-residential-area-al-qusais-2"
    }, {
        n: "Al Rostamani Business Center",
        s: "dubai-al-safa-al-safa-2-al-rostamani-business-center"
    }, {
        n: "Vita Building",
        s: "dubai-al-wasl-al-wasl-road-vita-building"
    }, {
        n: "Desert Palm",
        s: "dubai-al-aweer-al-aweer-village-desert-palm"
    }, {
        n: "Al Waleed Palace Hotel Apartment Al Barsha",
        s: "dubai-al-barsha-al-barsha-1-al-waleed-palace-hotel-apartment-al-barsha"
    }, {
        n: "Barsha Business Square",
        s: "dubai-al-barsha-al-barsha-1-barsha-business-square"
    }, {
        n: "The Courtyard",
        s: "dubai-al-quoz-al-quoz-1-the-courtyard"
    }, {
        n: "Savannah 2",
        s: "dubai-arabian-ranches-savannah-savannah-2"
    }, {
        n: "Al Meraikhi Tower 3",
        s: "dubai-deira-riggat-al-buteen-al-meraikhi-tower-3"
    }, {
        n: "Cornich road",
        s: "dubai-deira-corniche-deira-cornich-road"
    }, {
        n: "Al Soor Street",
        s: "dubai-deira-al-ras-al-soor-street"
    }, {
        n: "BLVD Heights Tower 2",
        s: "dubai-downtown-dubai-blvd-heights-blvd-heights-tower-2"
    }, {
        n: "Mazaya 2",
        s: "dubai-dubai-land-queue-point-mazaya-2"
    }, {
        n: "Farah Tower 1",
        s: "dubai-dubai-land-queue-point-farah-tower-1"
    }, {
        n: "Al Thanya",
        s: "dubai-umm-suqeim-umm-suqeim-2-al-thanya"
    }, {
        n: "Al Majara 1",
        s: "dubai-dubai-marina-al-majara-al-majara-1"
    }, {
        n: "Marina Residence A",
        s: "dubai-dubai-marina-marina-residence-marina-residence-a"
    }, {
        n: "Palacio Residence",
        s: "dubai-al-barsha-al-barsha-1-palacio-residence"
    }, {
        n: "Pearl Coast Premier Hotel Apartments",
        s: "dubai-al-barsha-al-barsha-1-pearl-coast-premier-hotel-apartments"
    }, {
        n: "Al Barsha South 1",
        s: "dubai-al-barsha-al-barsha-south-al-barsha-south-1"
    }, {
        n: "Intercoil Business Center",
        s: "dubai-al-quoz-al-quoz-industrial-area-intercoil-business-center"
    }, {
        n: "Al Quoz Industrial Area 1",
        s: "dubai-al-quoz-al-quoz-industrial-area-al-quoz-industrial-area-1"
    }, {
        n: "Al Qusias Industrial Area 3",
        s: "dubai-al-qusais-al-qusais-industrial-area-al-qusias-industrial-area-3"
    }, {
        n: "Al Bustan Centre & Residence",
        s: "dubai-al-qusais-al-qusais-residential-area-al-bustan-centre-residence"
    }, {
        n: "Alvorada 3",
        s: "dubai-arabian-ranches-alvorada-alvorada-3"
    }, {
        n: "Al Reem 3",
        s: "dubai-arabian-ranches-al-reem-al-reem-3"
    }, {
        n: "Bay Square Building 9",
        s: "dubai-business-bay-bay-square-bay-square-building-9"
    }, {
        n: "Safeer Tower 1",
        s: "dubai-business-bay-safeer-towers-safeer-tower-1"
    }, {
        n: "Westburry Tower 1",
        s: "dubai-business-bay-westburry-square-westburry-tower-1"
    }, {
        n: "Churchill Residency Tower",
        s: "dubai-business-bay-churchill-towers-churchill-residency-tower"
    }, {
        n: "Al Owais Building",
        s: "dubai-deira-al-khabisi-al-owais-building"
    }, {
        n: "Al Wahda Building",
        s: "dubai-deira-port-saeed-al-wahda-building"
    }, {
        n: "Skycourts Tower C",
        s: "dubai-dubai-land-skycourts-towers-skycourts-tower-c"
    }, {
        n: "wasl Crystal II",
        s: "dubai-al-quoz-al-quoz-industrial-area-wasl-crystal-ii"
    }, {
        n: "Al Qusais 3",
        s: "dubai-al-qusais-al-qusais-residential-area-al-qusais-3"
    }, {
        n: "The Warqaa Courtyard Building",
        s: "dubai-al-warqa-a-al-warqa-a-2-the-warqaa-courtyard-building"
    }, {
        n: "Savannah 1",
        s: "dubai-arabian-ranches-savannah-savannah-1"
    }, {
        n: "Saheel 3",
        s: "dubai-arabian-ranches-saheel-saheel-3"
    }, {
        n: "Al Ghurair Center",
        s: "dubai-deira-al-riqqa-al-ghurair-center"
    }, {
        n: "Emaar Tower A",
        s: "dubai-deira-emaar-towers-emaar-tower-a"
    }, {
        n: "Airport Free Zone",
        s: "dubai-deira-international-airport-area-airport-free-zone"
    }, {
        n: "The Residences 5",
        s: "dubai-downtown-dubai-the-residences-the-residences-5"
    }, {
        n: "Mazaya 10A",
        s: "dubai-dubai-land-queue-point-mazaya-10a"
    }, {
        n: "Port Saeed Building",
        s: "dubai-al-qusais-al-qusais-residential-area-port-saeed-building"
    }, {
        n: "Palmera 3",
        s: "dubai-arabian-ranches-palmera-palmera-3"
    }, {
        n: "The Residences 2",
        s: "dubai-downtown-dubai-the-residences-the-residences-2"
    }, {
        n: "Burj Views podium",
        s: "dubai-downtown-dubai-burj-views-burj-views-podium"
    }, {
        n: "Rigga Road",
        s: "dubai-deira-al-riqqa-rigga-road"
    }, {
        n: "Danat Al Mamzar",
        s: "dubai-deira-hor-al-anz-danat-al-mamzar"
    }, {
        n: "Al Zafarana",
        s: "dubai-deira-hor-al-anz-al-zafarana"
    }, {
        n: "Al Daghaya Street",
        s: "dubai-deira-al-ras-al-daghaya-street"
    }, {
        n: "Emirates National Investment Tower",
        s: "dubai-deira-port-saeed-emirates-national-investment-tower"
    }, {
        n: "The Centre Residence",
        s: "dubai-deira-al-muraqqabat-the-centre-residence"
    }, {
        n: "The Gate Precinct 5",
        s: "dubai-difc-the-gate-precinct-the-gate-precinct-5"
    }, {
        n: "Maram Residence",
        s: "dubai-downtown-dubai-burj-khalifa-area-maram-residence"
    }, {
        n: "Building 3",
        s: "dubai-downtown-dubai-emaar-square-building-3"
    }, {
        n: "Podium Villas",
        s: "dubai-downtown-dubai-south-ridge-podium-villas"
    }, {
        n: "Al Sabkha",
        s: "dubai-deira-al-ras-al-sabkha"
    }, {
        n: "Al Khaimah Building",
        s: "dubai-deira-port-saeed-al-khaimah-building"
    }, {
        n: "Burj Khalifa",
        s: "dubai-downtown-dubai-burj-khalifa-area-burj-khalifa"
    }, {
        n: "Central Tower",
        s: "dubai-dubai-marina-bay-central-central-tower"
    }, {
        n: "Le Presidium 1",
        s: "dubai-dubai-silicon-oasis-le-presidium-le-presidium-1"
    }, {
        n: "Al Thayyal 4",
        s: "dubai-greens-al-thayyal-al-thayyal-4"
    }, {
        n: "Al Ghaf 3",
        s: "dubai-greens-al-ghaf-al-ghaf-3"
    }, {
        n: "Al Jaz 3",
        s: "dubai-greens-al-jaz-al-jaz-3"
    }, {
        n: "Building B",
        s: "dubai-green-community-terrace-apartments-building-b"
    }, {
        n: "Building B",
        s: "dubai-green-community-garden-east-apartments-building-b"
    }, {
        n: "Block A",
        s: "dubai-dubai-production-city-impz-jil-building-block-a"
    }, {
        n: "City Tower",
        s: "dubai-deira-port-saeed-city-tower"
    }, {
        n: "The Lofts West",
        s: "dubai-downtown-dubai-the-lofts-the-lofts-west"
    }, {
        n: "BLVD Heights Tower 1",
        s: "dubai-downtown-dubai-blvd-heights-blvd-heights-tower-1"
    }, {
        n: "Binghatti Jewels",
        s: "dubai-dubai-land-liwan-binghatti-jewels"
    }, {
        n: "La Quinta",
        s: "dubai-dubai-land-villanova-la-quinta"
    }, {
        n: "Al Kazim Tower 1",
        s: "dubai-dubai-media-city-al-kazim-towers-al-kazim-tower-1"
    }, {
        n: "Al Fairooz Tower",
        s: "dubai-dubai-marina-emaar-6-towers-al-fairooz-tower"
    }, {
        n: "Silicon Gates 3",
        s: "dubai-dubai-silicon-oasis-silicon-gates-silicon-gates-3"
    }, {
        n: "Le Presidium 2",
        s: "dubai-dubai-silicon-oasis-le-presidium-le-presidium-2"
    }, {
        n: "Donna Tower 2",
        s: "dubai-dubai-silicon-oasis-donna-towers-donna-tower-2"
    }, {
        n: "Oliva",
        s: "dubai-dubai-sports-city-victory-heights-oliva"
    }, {
        n: "Al Samar 3",
        s: "dubai-greens-al-samar-al-samar-3"
    }, {
        n: "Al Ghaf 4",
        s: "dubai-greens-al-ghaf-al-ghaf-4"
    }, {
        n: "Trafalgar Central",
        s: "dubai-international-city-cbd-central-business-district-trafalgar-central"
    }, {
        n: "Murjan 5",
        s: "dubai-jumeirah-beach-residence-murjan-murjan-5"
    }, {
        n: "Ramada Hotel and Suites by Wyndham Dubai JBR",
        s: "dubai-jumeirah-beach-residence-the-walk-ramada-hotel-and-suites-by-wyndham-dubai-jbr"
    }, {
        n: "Silver Tower (Ag Tower)",
        s: "dubai-jumeirah-lake-towers-lake-almas-east-silver-tower-ag-tower"
    }, {
        n: "Armada Tower 1",
        s: "dubai-jumeirah-lake-towers-lake-elucio-armada-tower-1"
    }, {
        n: "Mazaya Business Avenue AA1",
        s: "dubai-jumeirah-lake-towers-mazaya-business-avenue-mazaya-business-avenue-aa1"
    }, {
        n: "Al Ramth 48",
        s: "dubai-remraam-al-ramth-al-ramth-48"
    }, {
        n: "Jouri 2 Tower B",
        s: "dubai-jumeirah-village-circle-jouri-residence-2-jouri-2-tower-b"
    }, {
        n: "Blakely Tower",
        s: "dubai-dubai-marina-park-island-blakely-tower"
    }, {
        n: "Park Island Villas",
        s: "dubai-dubai-marina-park-island-park-island-villas"
    }, {
        n: "La Vista Residence 6",
        s: "dubai-dubai-silicon-oasis-la-vista-residence-la-vista-residence-6"
    }, {
        n: "Axis Residence 2",
        s: "dubai-dubai-silicon-oasis-axis-residence-axis-residence-2"
    }, {
        n: "Elite Sports Residence 2",
        s: "dubai-dubai-sports-city-elite-sports-residence-elite-sports-residence-2"
    }, {
        n: "Rufi Golf",
        s: "dubai-dubai-sports-city-rufy-residency-rufi-golf"
    }, {
        n: "Afnan 2",
        s: "dubai-dubai-production-city-impz-midtown-afnan-2"
    }, {
        n: "Global Green View II",
        s: "dubai-international-city-cbd-central-business-district-global-green-view-ii"
    }, {
        n: "C-08",
        s: "dubai-international-city-cbd-central-business-district-c-08"
    }, {
        n: "Royal Residence",
        s: "dubai-international-city-cbd-central-business-district-royal-residence"
    }, {
        n: "Sadaf 7",
        s: "dubai-jumeirah-beach-residence-sadaf-sadaf-7"
    }, {
        n: "The Dome",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-the-dome"
    }, {
        n: "Paradise One",
        s: "dubai-jumeirah-village-circle-district-4-paradise-one"
    }, {
        n: "The Lawns 3",
        s: "dubai-jumeirah-village-circle-the-lawns-the-lawns-3"
    }, {
        n: "Autumn",
        s: "dubai-jumeirah-village-circle-seasons-community-autumn"
    }, {
        n: "Mirabella 6",
        s: "dubai-jumeirah-village-circle-mirabella-mirabella-6"
    }, {
        n: "Terraced Apartments",
        s: "dubai-motor-city-green-community-motor-city-terraced-apartments"
    }, {
        n: "Tecom Tower 1",
        s: "dubai-barsha-heights-tecom-tecom-two-towers-tecom-tower-1"
    }, {
        n: "Portofino Hotel",
        s: "dubai-the-world-islands-the-heart-of-europe-portofino-hotel"
    }, {
        n: "Fairfield Tower",
        s: "dubai-dubai-marina-park-island-fairfield-tower"
    }, {
        n: "Silicon Gates 1",
        s: "dubai-dubai-silicon-oasis-silicon-gates-silicon-gates-1"
    }, {
        n: "Silicon Oasis Techno Hub 2",
        s: "dubai-dubai-silicon-oasis-silicon-oasis-techno-hub-silicon-oasis-techno-hub-2"
    }, {
        n: "Esmeralda",
        s: "dubai-dubai-sports-city-victory-heights-esmeralda"
    }, {
        n: "Al Arta 4",
        s: "dubai-greens-al-arta-al-arta-4"
    }, {
        n: "Building D",
        s: "dubai-green-community-garden-west-apartments-building-d"
    }, {
        n: "Centrium Tower 4",
        s: "dubai-dubai-production-city-impz-centrium-towers-centrium-tower-4"
    }, {
        n: "The Crescent Tower C",
        s: "dubai-dubai-production-city-impz-the-crescent-the-crescent-tower-c"
    }, {
        n: "Ramada Plaza Hotel",
        s: "dubai-jumeirah-beach-residence-the-walk-ramada-plaza-hotel"
    }, {
        n: "Gold Tower (Au Tower)",
        s: "dubai-jumeirah-lake-towers-lake-almas-east-gold-tower-au-tower"
    }, {
        n: "Al Seef Tower 1",
        s: "dubai-jumeirah-lake-towers-al-seef-towers-al-seef-tower-1"
    }, {
        n: "The Palladium",
        s: "dubai-jumeirah-lake-towers-lake-almas-west-the-palladium"
    }, {
        n: "Saleh Bin Lahej Building Block B",
        s: "dubai-jumeirah-village-circle-saleh-bin-lahej-building-saleh-bin-lahej-building-block-b"
    }, {
        n: "Silicon Gates 4",
        s: "dubai-dubai-silicon-oasis-silicon-gates-silicon-gates-4"
    }, {
        n: "Silicon Gates 2",
        s: "dubai-dubai-silicon-oasis-silicon-gates-silicon-gates-2"
    }, {
        n: "La Vista Residence 4",
        s: "dubai-dubai-silicon-oasis-la-vista-residence-la-vista-residence-4"
    }, {
        n: "La Vista Residence 1",
        s: "dubai-dubai-silicon-oasis-la-vista-residence-la-vista-residence-1"
    }, {
        n: "Donna Tower 1",
        s: "dubai-dubai-silicon-oasis-donna-towers-donna-tower-1"
    }, {
        n: "Estella",
        s: "dubai-dubai-sports-city-victory-heights-estella"
    }, {
        n: "Novelia",
        s: "dubai-dubai-sports-city-victory-heights-novelia"
    }, {
        n: "Al Dhafra 4",
        s: "dubai-greens-al-dhafra-al-dhafra-4"
    }, {
        n: "Rufi Gardens",
        s: "dubai-international-city-cbd-central-business-district-rufi-gardens"
    }, {
        n: "Al Jaz 4",
        s: "dubai-greens-al-jaz-al-jaz-4"
    }, {
        n: "Al Jaz 2",
        s: "dubai-greens-al-jaz-al-jaz-2"
    }, {
        n: "Al Dana 1",
        s: "dubai-international-city-al-dana-al-dana-1"
    }, {
        n: "Mazaya Business Avenue BB2",
        s: "dubai-jumeirah-lake-towers-mazaya-business-avenue-mazaya-business-avenue-bb2"
    }, {
        n: "Maple 1",
        s: "dubai-jumeirah-village-circle-emirates-gardens-2-maple-1"
    }, {
        n: "Shaiba Tower 1",
        s: "dubai-barsha-heights-tecom-al-shaiba-towers-shaiba-tower-1"
    }, {
        n: "Jade at the Fields",
        s: "dubai-mohammed-bin-rashid-city-district-11-jade-at-the-fields"
    }, {
        n: "Frond B",
        s: "dubai-palm-jebel-ali-water-homes-frond-b"
    }, {
        n: "Biela Villas",
        s: "dubai-akoya-avencia-biela-villas"
    }, {
        n: "Whitefield 2",
        s: "dubai-damac-hills-akoya-by-damac-whitefield-whitefield-2"
    }, {
        n: "Golf Promenade 3B",
        s: "dubai-damac-hills-akoya-by-damac-golf-promenade-golf-promenade-3b"
    }, {
        n: "Golden Sands 5 Hotel Apartments",
        s: "dubai-bur-dubai-mankhool-golden-sands-5-hotel-apartments"
    }, {
        n: "Tajer Residences",
        s: "dubai-old-town-the-old-town-island-tajer-residences"
    }, {
        n: "AZIZI Riviera",
        s: "dubai-meydan-meydan-one-azizi-riviera"
    }, {
        n: "Garden View Villas",
        s: "dubai-jebel-ali-jebel-ali-village-garden-view-villas"
    }, {
        n: "Hilton Dubai The Walk",
        s: "dubai-jumeirah-beach-residence-the-walk-hilton-dubai-the-walk"
    }, {
        n: "Maple 2",
        s: "dubai-jumeirah-village-circle-emirates-gardens-2-maple-2"
    }, {
        n: "Metropolis Lofts 1",
        s: "dubai-jumeirah-village-circle-metropolis-lofts-metropolis-lofts-1"
    }, {
        n: "Shakespeare Circus 3",
        s: "dubai-motor-city-shakespeare-circus-shakespeare-circus-3"
    }, {
        n: "Widcombe House 3",
        s: "dubai-motor-city-widcombe-house-widcombe-house-3"
    }, {
        n: "Dickens Circus 2",
        s: "dubai-motor-city-dickens-circus-dickens-circus-2"
    }, {
        n: "Abbey Crescent 1",
        s: "dubai-motor-city-abbey-crescent-abbey-crescent-1"
    }, {
        n: "Frond E",
        s: "dubai-palm-jebel-ali-garden-homes-frond-e"
    }, {
        n: "Brookfield 3",
        s: "dubai-damac-hills-akoya-by-damac-brookfield-brookfield-3"
    }, {
        n: "wasl Link",
        s: "dubai-al-muhaisnah-al-muhaisnah-4-wasl-link"
    }, {
        n: "Oud Metha Building",
        s: "dubai-bur-dubai-oud-metha-oud-metha-building"
    }, {
        n: "Saleh Bin Lahej Building",
        s: "dubai-bur-dubai-oud-metha-saleh-bin-lahej-building"
    }, {
        n: "M Residences",
        s: "dubai-bur-dubai-mankhool-m-residences"
    }, {
        n: "Jash Falqa",
        s: "dubai-palm-jumeirah-shoreline-apartments-jash-falqa"
    }, {
        n: "Diamond Views 3",
        s: "dubai-jumeirah-village-circle-diamond-views-diamond-views-3"
    }, {
        n: "Diamond Views 5",
        s: "dubai-jumeirah-village-circle-diamond-views-diamond-views-5"
    }, {
        n: "Dickens Circus 1",
        s: "dubai-motor-city-dickens-circus-dickens-circus-1"
    }, {
        n: "Sherlock Circus 1",
        s: "dubai-motor-city-sherlock-circus-sherlock-circus-1"
    }, {
        n: "Shaiba Tower 2",
        s: "dubai-barsha-heights-tecom-al-shaiba-towers-shaiba-tower-2"
    }, {
        n: "Al Noor 2",
        s: "dubai-barsha-heights-tecom-al-noor-towers-al-noor-2"
    }, {
        n: "Waterfront Villas",
        s: "dubai-mohammed-bin-rashid-city-sobha-hartland-waterfront-villas"
    }, {
        n: "Frond B",
        s: "dubai-palm-jebel-ali-signature-villas-frond-b"
    }, {
        n: "Frond M",
        s: "dubai-palm-jebel-ali-signature-villas-frond-m"
    }, {
        n: "Frond B",
        s: "dubai-palm-jebel-ali-garden-homes-frond-b"
    }, {
        n: "Hessa Building",
        s: "dubai-al-muhaisnah-al-muhaisnah-4-hessa-building"
    }, {
        n: "Al Telal 2",
        s: "dubai-bur-dubai-al-hamriya-al-telal-2"
    }, {
        n: "AZIZI Riviera 40",
        s: "dubai-meydan-meydan-one-azizi-riviera-40"
    }, {
        n: "Canary Building",
        s: "dubai-al-nahda-al-nahda-1-canary-building"
    }, {
        n: "Diamond Views 4",
        s: "dubai-jumeirah-village-circle-diamond-views-diamond-views-4"
    }, {
        n: "I Dubai",
        s: "dubai-maritime-city-harbour-residences-i-dubai"
    }, {
        n: "Family Villa",
        s: "dubai-motor-city-green-community-motor-city-family-villa"
    }, {
        n: "Sherlock House 1",
        s: "dubai-motor-city-sherlock-house-sherlock-house-1"
    }, {
        n: "Dickens Circus 3",
        s: "dubai-motor-city-dickens-circus-dickens-circus-3"
    }, {
        n: "Grenland Residence",
        s: "dubai-mohammed-bin-rashid-city-district-11-grenland-residence"
    }, {
        n: "Golf Promenade 1A",
        s: "dubai-damac-hills-akoya-by-damac-golf-promenade-golf-promenade-1a"
    }, {
        n: "Al Hamriya 640",
        s: "dubai-bur-dubai-al-hamriya-al-hamriya-640"
    }, {
        n: "AZIZI Riviera 11",
        s: "dubai-meydan-meydan-one-azizi-riviera-11"
    }, {
        n: "Nad Al Sheba Gardens",
        s: "dubai-nadd-al-sheba-nad-al-sheba-1-nad-al-sheba-gardens"
    }, {
        n: "Frond F",
        s: "dubai-palm-jebel-ali-signature-villas-frond-f"
    }, {
        n: "Park Hotel Apartments",
        s: "dubai-bur-dubai-oud-metha-park-hotel-apartments"
    }, {
        n: "Al Tawi Building 2",
        s: "dubai-bur-dubai-mankhool-al-tawi-building-2"
    }, {
        n: "M Square",
        s: "dubai-bur-dubai-mankhool-m-square"
    }, {
        n: "Badri Building",
        s: "dubai-bur-dubai-mankhool-badri-building"
    }, {
        n: "Souk Al Bahar",
        s: "dubai-old-town-the-old-town-island-souk-al-bahar"
    }, {
        n: "AZIZI Riviera 24",
        s: "dubai-meydan-meydan-one-azizi-riviera-24"
    }, {
        n: "AZIZI Riviera 7",
        s: "dubai-meydan-meydan-one-azizi-riviera-7"
    }, {
        n: "Sienna Views",
        s: "dubai-jumeirah-golf-estates-fire-sienna-views"
    }, {
        n: "Mansion 7",
        s: "dubai-palm-jumeirah-w-residences-mansion-7"
    }, {
        n: "Latifa Tower",
        s: "dubai-al-nahda-al-nahda-1-latifa-tower"
    }, {
        n: "Al Hamriya 431",
        s: "dubai-bur-dubai-al-hamriya-al-hamriya-431"
    }, {
        n: "Al Raffa Residence",
        s: "dubai-bur-dubai-al-raffa-al-raffa-residence"
    }, {
        n: "Wahat Al Nakheel",
        s: "dubai-bur-dubai-mankhool-wahat-al-nakheel"
    }, {
        n: "Dubai Textile City",
        s: "dubai-al-warsan-al-warsan-1-dubai-textile-city"
    }, {
        n: "Century Plaza",
        s: "dubai-jumeirah-jumeirah-1-century-plaza"
    }, {
        n: "Olive Point",
        s: "dubai-jumeirah-golf-estates-earth-olive-point"
    }, {
        n: "Signature Villas Frond L",
        s: "dubai-palm-jumeirah-signature-villas-signature-villas-frond-l"
    }, {
        n: "Bahwan Tower Al Nahda",
        s: "dubai-al-nahda-al-nahda-1-bahwan-tower-al-nahda"
    }, {
        n: "Al Raffa Tower",
        s: "dubai-bur-dubai-al-raffa-al-raffa-tower"
    }, {
        n: "The OPAL",
        s: "dubai-bur-dubai-mankhool-the-opal"
    }, {
        n: "Golden Sands 8",
        s: "dubai-bur-dubai-mankhool-golden-sands-8"
    }, {
        n: "Fairway Vistas",
        s: "dubai-dubai-hills-estate-dubai-hills-fairway-vistas"
    }, {
        n: "Zanzebeel 4",
        s: "dubai-old-town-zanzebeel-zanzebeel-4"
    }, {
        n: "Attareen Residences",
        s: "dubai-old-town-the-old-town-island-attareen-residences"
    }, {
        n: "The Parkway at Dubai Hills",
        s: "dubai-dubai-hills-estate-dubai-hills-the-parkway-at-dubai-hills"
    }, {
        n: "Al Wasl House",
        s: "dubai-jumeirah-jumeirah-1-al-wasl-house"
    }, {
        n: "Sanctuary Falls",
        s: "dubai-jumeirah-golf-estates-earth-sanctuary-falls"
    }, {
        n: "Orange Lake",
        s: "dubai-jumeirah-golf-estates-fire-orange-lake"
    }, {
        n: "Marina Residences 3",
        s: "dubai-palm-jumeirah-marina-residences-marina-residences-3"
    }, {
        n: "Al Haseer",
        s: "dubai-palm-jumeirah-shoreline-apartments-al-haseer"
    }, {
        n: "Luxury Living Villas",
        s: "ras-al-khaimah-al-hamra-village-falcon-island-luxury-living-villas"
    }, {
        n: "Berlin Building",
        s: "dubai-al-warsan-al-warsan-4-berlin-building"
    }, {
        n: "Azizi Riviera 23",
        s: "dubai-meydan-meydan-one-azizi-riviera-23"
    }, {
        n: "Marina Apartments H",
        s: "ras-al-khaimah-al-hamra-village-al-hamra-marina-residences-marina-apartments-h"
    }, {
        n: "Jumeirah 3 Villas",
        s: "dubai-jumeirah-jumeirah-3-jumeirah-3-villas"
    }, {
        n: "Orange Grove",
        s: "dubai-jumeirah-golf-estates-fire-orange-grove"
    }, {
        n: "Marina Residences 4",
        s: "dubai-palm-jumeirah-marina-residences-marina-residences-4"
    }, {
        n: "Mansion 8",
        s: "dubai-palm-jumeirah-w-residences-mansion-8"
    }, {
        n: "Lagoon B21",
        s: "ras-al-khaimah-mina-al-arab-the-lagoons-lagoon-b21"
    }, {
        n: "Jumeirah 2 Villas",
        s: "dubai-jumeirah-jumeirah-2-jumeirah-2-villas"
    }, {
        n: "The Sundials",
        s: "dubai-jumeirah-golf-estates-earth-the-sundials"
    }, {
        n: "Lime Tree Valley",
        s: "dubai-jumeirah-golf-estates-earth-lime-tree-valley"
    }, {
        n: "Marina Residences 2",
        s: "dubai-palm-jumeirah-marina-residences-marina-residences-2"
    }, {
        n: "Golden Mile 2",
        s: "dubai-palm-jumeirah-golden-mile-golden-mile-2"
    }, {
        n: "Al Msalli",
        s: "dubai-palm-jumeirah-shoreline-apartments-al-msalli"
    }, {
        n: "Al Tamr",
        s: "dubai-palm-jumeirah-shoreline-apartments-al-tamr"
    }, {
        n: "Sofitel Dubai The Palm",
        s: "dubai-palm-jumeirah-the-crescent-sofitel-dubai-the-palm"
    }, {
        n: "Al Mulla Plaza",
        s: "dubai-al-nahda-al-nahda-1-al-mulla-plaza"
    }, {
        n: "Emirates Stars Hotel Apartments Dubai",
        s: "dubai-al-nahda-al-nahda-1-emirates-stars-hotel-apartments-dubai"
    }, {
        n: "Blue 3 Building",
        s: "dubai-al-nahda-al-nahda-2-blue-3-building"
    }, {
        n: "Green Diamond 1",
        s: "dubai-arjan-green-diamond-green-diamond-1"
    }, {
        n: "Garden Hall",
        s: "dubai-jumeirah-islands-contemporary-cluster-garden-hall"
    }, {
        n: "MAG 515",
        s: "dubai-dubai-south-dubai-world-central-mag-5-mag-515"
    }, {
        n: "Urbana",
        s: "dubai-dubai-south-dubai-world-central-emaar-south-urbana"
    }, {
        n: "Marina Residences 1",
        s: "dubai-palm-jumeirah-marina-residences-marina-residences-1"
    }, {
        n: "Hessa Tower",
        s: "dubai-al-nahda-al-nahda-1-hessa-tower"
    }, {
        n: "Blue 1",
        s: "dubai-al-nahda-al-nahda-2-blue-1"
    }, {
        n: "Entertainment Foyer",
        s: "dubai-jumeirah-islands-oasis-clusters-entertainment-foyer"
    }, {
        n: "Garden Hall",
        s: "dubai-jumeirah-islands-mediterranean-clusters-garden-hall"
    }, {
        n: "MAG 505",
        s: "dubai-dubai-south-dubai-world-central-mag-5-mag-505"
    }, {
        n: "Zahra Breeze Apartments 2B",
        s: "dubai-town-square-zahra-breeze-apartments-zahra-breeze-apartments-2b"
    }, {
        n: "Al Nahda Tower",
        s: "dubai-al-nahda-al-nahda-2-al-nahda-tower"
    }, {
        n: "Saeed Tower 2",
        s: "dubai-sheikh-zayed-road-saeed-towers-saeed-tower-2"
    }, {
        n: "Jumeirah Emirates Tower",
        s: "dubai-sheikh-zayed-road-emirates-towers-jumeirah-emirates-tower"
    }, {
        n: "MAG 540",
        s: "dubai-dubai-south-dubai-world-central-mag-5-boulevard-mag-540"
    }, {
        n: "Amwaj",
        s: "ras-al-khaimah-al-marjan-island-bab-al-bahar-amwaj"
    }, {
        n: "Garden Hall",
        s: "dubai-jumeirah-islands-islamic-clusters-garden-hall"
    }, {
        n: "Marina Apartments B",
        s: "ras-al-khaimah-al-hamra-village-al-hamra-marina-residences-marina-apartments-b"
    }, {
        n: "Zahra Breeze Apartments 4A",
        s: "dubai-town-square-zahra-breeze-apartments-zahra-breeze-apartments-4a"
    }, {
        n: "Park Homes",
        s: "ras-al-khaimah-al-hamra-village-falcon-island-park-homes"
    }, {
        n: "Mazaya 5",
        s: "dubai-dubai-land-liwan-q-line-mazaya-5"
    }
];