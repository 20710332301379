import { get, download } from '../baseApi';

export const getPropertyUserActionsCount = async (params) => {
    const res = await get('/properties/v1/superAdmin/propertyUserActionsRoutes/count', params);
    return res;
};

export const downloadPropertyUserActionsCSVByAgencySlug = async (slug) => {
    return download('/properties/v1/superAdmin/propertyUserActionsRoutes/csv', {
        slug,
    });
};
