import React, { useState, useEffect } from "react";
import { MyPZContainer, MyPZButton, MyPZTable } from "@mypz/react-kit";
import moment from "moment";

import "./SitemapsPage.scss";
import {
  startGeneration,
  listSitemaps,
  sitemapURLs,
} from "../../api/sitemaps/sitemapsApi";

const SitemapsPage = () => {
  const [data, setData] = useState([]);
  const [sitemaps, setSitemaps] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async (pageRequest) => {
    setLoading(true);
    try {
      const res = await listSitemaps(pageRequest);
      const resSitemapURLs = await sitemapURLs(pageRequest);

      setSitemaps(resSitemapURLs);
      setData(res.data.sort((a, b) => b.id - a.id));
    } catch (e) {
      console.log("====================================");
      console.log(e);
      console.log("====================================");
    }
    setLoading(false);
  };

  const renderList = () => {
    return (
      <>
        {data.length > 0 ? (
          <MyPZTable
            data={data}
            headRenders={[
              () => <div>Id</div>,
              () => <div>Status</div>,
              () => <div>Created</div>,
              () => <div>Updated</div>,
            ]}
            lineRenders={[
              (it) => <div>{it.id}</div>,
              (it) => <div>{it.status}</div>,
              (it) => <div>{moment(it.createdAt).fromNow()}</div>,
              (it) => <div>{moment(it.updatedAt).fromNow()}</div>,
            ]}
          />
        ) : (
          <p>No data</p>
        )}
      </>
    );
  };
  const renderSitemapURLsList = () => {
    return (
      <>
        {sitemaps.length > 0 ? (
          <MyPZTable
            data={sitemaps}
            headRenders={[
              () => <div>Id</div>,
              () => <div>Url</div>,
              () => <div>Type</div>,
              () => <div>Created</div>,
              () => <div>Updated</div>,
            ]}
            lineRenders={[
              (it) => <div>{it.id}</div>,
              (it) => <div>{it.url}</div>,
              (it) => <div>{it.type}</div>,
              (it) => <div>{moment(it.createdAt).fromNow()}</div>,
              (it) => <div>{moment(it.updatedAt).fromNow()}</div>,
            ]}
          />
        ) : (
          <p>No data</p>
        )}
      </>
    );
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await startGeneration();
      await fetchList();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="page-tracking-import-list">
      {loading ? (
        <p> loading ...</p>
      ) : (
        <MyPZContainer>
          <form
            className="page-tracking-import-list__form"
            onSubmit={onFormSubmit}
          >
            <div>
              <MyPZButton type="submit">Generate Sitemaps</MyPZButton>
            </div>
          </form>
          <hr />
          <h1>Sitemap Tracker</h1>
          <div className="page-tracking-import-list__content">
            {renderList()}
          </div>
          <hr />
          <h1>Website's sitemaps</h1>
          <div className="page-tracking-import-list__content">
            {renderSitemapURLsList()}
          </div>
        </MyPZContainer>
      )}
    </div>
  );
};

export default SitemapsPage;
