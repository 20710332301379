import React from "react";

const Pagination = ({ page, setPage, total, limit }) => {
  const totalPages = Math.ceil(total / limit);

  return (
    <div className="d-flex justify-content-center align-items-center gap-2">
      <button
        className="btn btn-outline-primary"
        onClick={() => setPage((page) => Math.max(page - 1, 1))}
      >
        Previous
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => setPage((page) => Math.min(page + 1, totalPages))}
      >
        Next
      </button>
      <div className="text-primary">
        Page {page} of {totalPages}
      </div>
    </div>
  );
};

export default Pagination;
