import React, { useState, useEffect } from 'react';

import './AdForm.scss';

import MyPZTextField from '@mypz/react-kit/components/inputs/textField/MyPZTextField';
import MyPZButton from '@mypz/react-kit/components/inputs/button/MyPZButton';

import ImageCrop from '../../imageCrop/ImageCrop';
import { adminListAgencies } from '../../../api/agencies/agenciesApi';
import moment from 'moment';
import { DatetimePicker, MyPZCheckbox, MyPZSelect } from '@mypz/react-kit';

const initialData = {
  enabled: false,
  name: '',
  agencyId: 0,
  imageId: null,
  imageUrl: null,
  redirectionUrl: '',
  showingUrl: '',
  startDate: moment(),
  endDate: moment().add(1, 'day'),
  displayCount: 0,
  clickCount: 0,
};

const AdForm = (props) => {
  const { ad, isProcessing, onSubmit, isUpdate } = props;
  const [formData, setFormData] = useState(initialData);
  const [agenciesOptions, setAgenciesOptions] = useState(null);
  const [agencyId, setAgencyId] = useState('');

  useEffect(() => {
    fetchAgenciesList();
  }, []);

  const fetchAgenciesList = async () => {
    const agenciesResult = await adminListAgencies();
    const agenciesTransformed = agenciesResult.agencies.map((a) => ({
      text: a.name,
      value: a.id,
    }));
    setAgenciesOptions(agenciesTransformed);
  };

  const handleTimeChange = (key) => (time) => {
    setFormData({ ...formData, [key]: time});
  };

  const handleInputChange = (key) => (e) => {
    let newValue = e.target.value;

    if (key === 'enabled') {
      newValue = e.target.checked;
    }
    setFormData({ ...formData, [key]: newValue });
  };

  useEffect(() => {
    if (ad) {
      setFormData({...initialData, ...ad});
      setAgencyId(ad.agencyId);
    }
  }, [ad]);

  const onGameImageUploaded = (imageId, imageUrl) => {
    setFormData({...formData, ...{imageId, imageUrl}});
  };

  const isValidForm = () => {
    return !!formData.name;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);

    if (!isValidForm()) {
      return;
    }

    onSubmit({
      ...formData,
      agencyId,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-ad__field">
          <MyPZCheckbox disabled={isProcessing} label="Enabled" onChange={handleInputChange('enabled')} checked={formData.enabled} />
        </div>
        <div className="form-ad__field">
          <MyPZTextField disabled={isProcessing} type="text" label="Name" placeholder="Ad name" onChange={handleInputChange('name')} value={formData.name} />
        </div>
        <div className="form-ad__field">
          <MyPZSelect options={agenciesOptions || []} value={agencyId} onChange={(e) => setAgencyId(e.target.value)} label="Agency" />
        </div>
        <div className="form-ad__field">
          <ImageCrop
            onImageUploaded={onGameImageUploaded}
            imageWidth={800}
            imageHeight={1200}
            uploadButtonLabel='Add Ad Image'
            imageUrl={formData.imageUrl}
          />
        </div>

        <div className="form-ad__field">
          <MyPZTextField disabled={isProcessing} type="text" label="Redirection Url (where the user is redirected after click)" placeholder="https://othercompany.com/website/article" onChange={handleInputChange('redirectionUrl')} value={formData.redirectionUrl} />
        </div>
        <div className="form-ad__field">
          <MyPZTextField disabled={isProcessing} type="text" label="Showing Url (where the user will see this ad appear)" placeholder="/en/rent/properties-for-rent" onChange={handleInputChange('showingUrl')} value={formData.showingUrl} />
        </div>
        <div className="form-ad__field">
          <DatetimePicker
            label="Start Date"
            value={formData.startDate}
            onChange={handleTimeChange('startDate')}
            />
        </div>
        <div className="form-ad__field">
          <DatetimePicker
            label="End Date"
            value={formData.endDate}
            onChange={handleTimeChange('endDate')}
            />
        </div>
        <div className="form-ad__field">
          <MyPZTextField disabled={true} type="number" label="Display Count" placeholder="0" value={formData.displayCount} />
        </div>
        <div className="form-ad__field">
          <MyPZTextField disabled={true} type="number" label="Click Count" placeholder="0" value={formData.clickCount} />
        </div>
        <MyPZButton disabled={isProcessing || !isValidForm()} onClick={handleSubmit}>{isUpdate ? 'Update' : 'Create'}</MyPZButton>
      </form>
    </>
  );
}

export default AdForm;
