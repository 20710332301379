import React, { useEffect, useState } from "react";
import Seo from "./Seo";
import SeoLocations from "./SeoLocations";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

function Tab({ children, currentTab, index, setTab }) {
  return (
    <div
      className={currentTab === index ? "active" : ""}
      onClick={() => {
        setTab(index);
      }}
    >
      {children}
    </div>
  );
}

function SeoManager() {
  const [currentTab, setTab] = useState(0);

  return (
    <div className="mt-3">
      <div className="tabs d-flex justify-content-center gap-2">
        <Tab currentTab={currentTab} index={0} setTab={setTab}>
          <button
            className={currentTab === 0 ? "btn btn-danger active" : "btn"}
          >
            Favorites
          </button>
        </Tab>
        <Tab currentTab={currentTab} index={1} setTab={setTab}>
          <button
            className={currentTab === 1 ? "btn btn-danger active" : "btn"}
          >
            Locations
          </button>
        </Tab>
      </div>
      <div className="content">
        {currentTab === 0 && <Seo />}
        {currentTab === 1 && <SeoLocations />}
      </div>
    </div>
  );
}

export default SeoManager;
