import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  MyPZContainer,
  MyPZTextField,
  MyPZButton,
  MyPZTable,
  MyPZPagination,
  MyPZSelect,
} from "@mypz/react-kit";

import "./LocationsListPage.scss";

import { adminListLocations, deleteLocation, downloadEnabledLocations } from "../../../api/locations/locationsApi";

const statusOptions = [
  {
    text: "All",
    value: "",
  },
  {
    text: "Draft (not visible by users and need to be managed)",
    value: "draft",
  },
  {
    text: "Ignored (not visible by users and managed)",
    value: "ignored",
  },
  {
    text: "Published (visible by users)",
    value: "published",
  },
];

const LoactionsListPage = () => {
  const [listValues, setListValues] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    status: "",
    page: 1,
  });

  const handleInputChange = (key) => (e) => {
    const newFormData = { ...formData, [key]: e.target.value };
    setFormData(newFormData);
  };

  const fetchList = async (params) => {
    const res = await adminListLocations(params);
    setListValues(res.locations);
    setTotalItems(res.stats.totalItems);
    setTotalPages(res.stats.totalPages);
  };

  useEffect(() => {
    fetchList(formData);
  }, [formData]);

  const handlePageChange = (_, value) => {
    setFormData({ ...formData, page: value });
  };

  const handleDeleteLocation = async (location) => {
    if (window.confirm(`Are you sure you want to delete ${location.name}?`)) {
      await deleteLocation(location.slug);
      fetchList(formData);
    }
  };

  const clickOnDownload = async () => {
    const res = await downloadEnabledLocations();

    const url = window.URL.createObjectURL(new Blob([JSON.stringify(res)]));
    const a = document.createElement('a');
    a.href = url;
    a.download = 'locations.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const renderList = () => {
    return (
      <MyPZTable
        data={listValues}
        headRenders={[
          () => <div>Id</div>,
          () => <div>Status</div>,
          () => <div>Name</div>,
          () => <div>Weight</div>,
          () => <div>Is Main Location</div>,
          () => <div>Created</div>,
          () => <div>Updated</div>,
          () => <div>Actions</div>,
        ]}
        lineRenders={[
          (l) => <div>{l.id}</div>,
          (l) => <div>{l.status}</div>,
          (l) => <div>{l.name}</div>,
          (l) => <div>{l.weight}</div>,
          (l) => <div>{l.mainLocationId === 0 ? "Yes": "No"}</div>,
          (l) => <div>{moment(l.createdAt).fromNow()}</div>,
          (l) => <div>{moment(l.updatedAt).fromNow()}</div>,
          (l) => (
            <div className="d-flex gap-3">
              <Link to={`/locations/update/${l.slug}`}>
                <button className="btn btn-success"> Update</button>
              </Link>
              <button
                className="btn btn-danger"
                onClick={() => handleDeleteLocation(l)}
              >
                Delete
              </button>
            </div>
          ),
        ]}
      />
    );
  };

  return (
    <div className="page-locations-list">
      <MyPZContainer>
        <h1>Locations list</h1>
        <div className="page-locations-list__actions">
          <div>
            <Link to="/locations/create">
              <MyPZButton>Create Location</MyPZButton>
            </Link>
          </div>
          <div>
            <div>
              <MyPZButton onClick={clickOnDownload}>Download all enabled locations</MyPZButton>
            </div>
          </div>
        </div>

        <div className="page-locations-list__search-form">
          <div className="page-locations-list__search-field">
            <MyPZTextField
              placeholder="Search by name"
              onChange={handleInputChange('name')}
              value={formData.name}
            />
          </div>
          <div className="page-locations-list__search-field">
            <MyPZSelect options={statusOptions} value={formData.status} onChange={handleInputChange('status')} label="Status" />
          </div>
        </div>

        <div>{renderList()}</div>
        <div className="page-locations-list__pagination-block">
          <div className="page-locations-list__pagination-block__total">
            {`${totalItems} Locations`}
          </div>
          <div className="page-locations-list__pagination-block__selector">
            <MyPZPagination
              maxPage={totalPages}
              currentPage={formData.page}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </MyPZContainer>
    </div>
  );
};

export default LoactionsListPage;
