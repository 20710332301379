import { post, get, put, deleteRequest } from "../baseApi";

export const createLocation = async (params) => {
  const res = await post("/locations/v1/superAdmin/locations", params);
  return res;
};

export const updateLocation = async (slug, params) => {
  const res = await put(`/locations/v1/superAdmin/locations/${slug}`, params);
  return res;
};

export const showLocation = async (slug) => {
  const res = await get(`/locations/v1/superAdmin/locations/${slug}`);
  return res;
};

export const getLocationById = async (id) => {
  const res = await get(`/locations/v1/superAdmin/locations/id/${id}`);
  return res;
};

export const disableLocation = async (slug) => {
  const res = await deleteRequest(
    `/locations/v1/superAdmin/locations/${slug}/disable`,
  );
  return res;
};

export const deleteLocation = async (slug) => {
  const res = await deleteRequest(`/locations/v1/superAdmin/locations/${slug}`);
  return res;
};

export const listLocations = async (params) => {
  const res = await get(`/locations/v1/public/locations`, params);
  return res;
};

export const adminListLocations = async (params) => {
  const res = await get(`/locations/v1/superAdmin/locations`, params);
  return res;
};

export const syncLocation = async (slug) => {
  const res = await post(`/locations/v1/superAdmin/locations/${slug}/sync`);
  return res;
};

export const downloadEnabledLocations = async () => {
  const res = await get(`/locations/v1/superAdmin/locations/download-enabled`);
  return res;
};

