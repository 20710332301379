import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  MyPZContainer,
  MyPZTextField,
  MyPZButton,
  MyPZTable,
  MyPZPagination,
  MyPZSelect,
} from "@mypz/react-kit";

import "./PropertiesListPage.scss";

import {
  deleteProperty,
  listProperties,
  syncProperty,
} from "../../../api/properties/propertiesApi";
import { adminListAgencies } from '../../../api/agencies/agenciesApi';

let delayer = null;

const statusOptions = [
  { text: "None", value: "" },
  { text: "Publish", value: "publish" },
  { text: "Removed", value: "removed" },
];

const PropertiesListPage = () => {
  const [searchValue, setSearchValue] = useState({});
  const [listValues, setListValues] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [agenciesOptions, setAgenciesOptions] = useState(null);

  useEffect(() => {
    fetchAgenciesList();
  }, []);

  const fetchAgenciesList = async () => {
    const agenciesResult = await adminListAgencies();
    const agenciesTransformed = agenciesResult.agencies.map((a) => ({
      text: a.name,
      value: a.id,
    }));
    setAgenciesOptions(agenciesTransformed);
  };

  const handleChangeValue = (key) => (e) => {
    setSearchValue({...searchValue, [key]: e.target.value});
  };

  const fetchList = async (params, pageRequest) => {
    const res = await listProperties({ ...params, page: pageRequest });
    setListValues(res.properties);
    setTotalItems(res.stats.totalItems);
    setTotalPages(res.stats.totalPages);
  };

  useEffect(() => {
    if (!searchValue) {
      fetchList(searchValue, page);
      return;
    }
    clearTimeout(delayer);
    delayer = setTimeout(() => {
      fetchList(searchValue, page);
    }, 500);
  }, [searchValue, page]);

  const handlePageChange = (_, value) => {
    setPage(value);
  };

  const handleDeleteProperty = async (property) => {
    if (!window.confirm("Are you sure you want to delete this property?")) {
      return;
    }

    try {
      await deleteProperty(property.slug);
      fetchList(searchValue, page);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSyncProperty = async (property) => {
    if (!window.confirm("Are you sure you want to sync this property?")) {
      return;
    }

    try {
      await syncProperty(property.id);
      fetchList(searchValue, page);
    } catch (err) {
      console.log(err);
    }
  };

  const renderList = () => {
    return (
      <MyPZTable
        data={listValues}
        headRenders={[
          () => <div>Id</div>,
          () => <div>Title</div>,
          () => <div>Reference</div>,
          () => <div>Score</div>,
          () => <div>Status</div>,
          () => <div>Created</div>,
          () => <div>Updated</div>,
          () => <div>Actions</div>,
        ]}
        lineRenders={[
          (l) => <div>{l.id}</div>,
          (l) => <div>{l.title}</div>,
          (l) => <div>{l.reference}</div>,
          (l) => <div>{l.score}</div>,
          (l) => <div>{l.status}</div>,
          (l) => <div>{moment(l.createdAt).fromNow()}</div>,
          (l) => <div>{moment(l.updatedAt).fromNow()}</div>,
          (l) => (
            <div className="d-flex gap-3">
              <Link to={`/properties/update/${l.slug}`}>
                <button className="btn btn-success">Update</button>
              </Link>
              <Link to={`https://zeekeez.com/en/properties/${l.slug}`} target="_blank">
                <button className="btn btn-success">View</button>
              </Link>
              <button
                className="btn btn-danger"
                onClick={() => handleDeleteProperty(l)}
              >
                Delete
              </button>
              <button
                className="btn btn-info"
                onClick={() => handleSyncProperty(l)}
              >
                Sync
              </button>
            </div>
          ),
        ]}
      />
    );
  };

  return (
    <div className="page-properties-list">
      <MyPZContainer>
        <h1>Properties list</h1>
        <div className="page-properties-list__create-new">
          <Link to="/properties/create">
            <MyPZButton>Create Property</MyPZButton>
          </Link>
        </div>
        <div className="page-properties-list__search-field">
          <MyPZTextField
            placeholder="Search by title"
            onChange={handleChangeValue("title")}
            value={searchValue.title}
          />
          <div className="page-properties-list__search-field">
            <MyPZSelect options={agenciesOptions || []} value={searchValue.agencyId} onChange={handleChangeValue("agencyId")} label="Agency" />
          </div>
          <div className="page-properties-list__search-field">
            <MyPZSelect options={statusOptions} value={searchValue.status} onChange={handleChangeValue("status")} label="Status" />
          </div>
        </div>
        <div>{renderList()}</div>
        <div className="page-properties-list__pagination-block">
          <div className="page-properties-list__pagination-block__total">
            {`${totalItems} Properties`}
          </div>
          <div className="page-properties-list__pagination-block__selector">
            <MyPZPagination
              maxPage={totalPages}
              currentPage={page}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </MyPZContainer>
    </div>
  );
};

export default PropertiesListPage;
