import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './UpdateLocationPage.scss';

import {
  MyPZContainer,
  MyPZAlert,
} from '@mypz/react-kit';

import LocationForm from '../../../components/form/location/LocationForm';

import { getLocationById, showLocation, updateLocation } from '../../../api/locations/locationsApi';

const UpdateLocationPage = () => {
  const history = useNavigate();
  const { slug } = useParams();

  const [isSuccess, setIsSuccess] = useState(false);
  const [errorAlert, setErrorAlert] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const [locationData, setLocationData] = useState(null);

  useEffect(() => {
    const callApi = async () => {
      try {
        const locationResult = await showLocation(slug);
        const location = locationResult.location;
        if (location.mainLocationId) {
          const mainLocation = await getLocationById(location.mainLocationId);
          location.mainLocationName = mainLocation.location.name;
        } else if (location.mainLocationId === 0) {
          location.mainLocationName = "Main Location";
        }
        setLocationData(location);
      } catch (error) {
        console.log('location get error:', error.message);
        setErrorAlert(error.message);
        history('/locations/create');
      }
    };
    callApi();
  }, [slug, history]);

  const handleSubmit = async (formData) => {
    const {
      parentId,
      path,
      country,
      name,
      nameAr,
      fullPathName,
      abbreviation,
      geom,
      slug,
      status,
      weight,
    } = formData;

    const params = {
      ...(parentId && locationData.parentId !== parentId && { parentId: parseInt(parentId) }),
      ...(path && locationData.path !== path && { path }),
      ...(country && locationData.country !== country && { country }),
      ...(name && locationData.name !== name && { name }),
      ...(nameAr && locationData.nameAr !== nameAr && { nameAr }),
      ...(fullPathName && locationData.fullPathName !== fullPathName && { fullPathName }),
      ...(abbreviation && locationData.abbreviation !== abbreviation && { abbreviation }),
      ...(geom && locationData.geom !== geom && { geom }),
      ...(slug && locationData.slug !== slug && { slug }),
      ...(status && locationData.status !== slug && { status }),
      ...(weight && locationData.weight !== weight && { weight: parseInt(weight) }),
      mainLocationId: formData.mainLocationId,
    };

    setIsProcessing(true);
    try {
      await updateLocation(locationData.slug, params);
      setIsSuccess(true);
      if (locationData.slug !== slug) {
        history(`/locations/update/${slug}`);
      }
    } catch (e) {
      console.log('location update error:', e.message);
      setErrorAlert(e.message);
    }
    setIsProcessing(false);
  };

  const renderAlert = () => {
    if (isSuccess) {
      return (<MyPZAlert>Location Updated</MyPZAlert>);
    }

    if (errorAlert) {
      return (<MyPZAlert type="error">{errorAlert}</MyPZAlert>);
    }

    return null;
  };

  return (
    <div className="page-update-location">
      <MyPZContainer>
        {renderAlert()}
        <h1>Update Location</h1>
        <LocationForm
          location={locationData}
          isProcessing={isProcessing}
          onSubmit={handleSubmit}
          isUpdate
        />
      </MyPZContainer>
    </div>
  );
}

export default UpdateLocationPage;
