import { MyPZContainer } from "@mypz/react-kit";
import React, { useEffect, useRef, useState } from "react";
import { seoMap } from "../../data/seo/seoUrlList";
import { CSVLink, CSVDownload } from "react-csv";
import propertyTypesConfig from "../../data/config/propertyTypesConfig";
import { locationList } from "../../data/config/locationsConfig";
import { get, post } from "../../api/baseApi";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import config from "../../config/Config";
import DnDTable from "../../components/dragdropdatatable";
import Pagination from "./Pagination";
import SearchInput from "../../components/inputs/SearchInput/SearchInput";

// import "antd/dist/antd.css";

// Allowed extensions for input file
const allowedExtensions = ["csv"];

export default function SeoLocations({ initialPage = 1, limit = 10 }) {
  const file = useRef();
  const editorBox = useRef();
  const path = useRef();
  const homepageSection = useRef();
  const homePageLink = useRef();
  const businessType = useRef();
  const propertyType = useRef();
  const location = useRef();
  const bedrooms = useRef();
  const minPrice = useRef();
  const maxPrice = useRef();
  const title = useRef();
  const h1 = useRef();
  const metadescription = useRef();
  const descriptionH1 = useRef();
  const keywords = useRef();
  const shortDesc = useRef();
  const isSeoLink = useRef();
  const [page, setPage] = useState(initialPage);
  const [total, setTotal] = useState(100);

  const fileName = "seo-detail";
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortedLocationList, setSortedLocationList] = useState([]);
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [resultData, setResultData] = useState([]);
  const [longDesc, setLongDesc] = useState([]);
  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [refreshState, setRefreshState] = useState(0);
  const [searchTitle, setSearchTitle] = useState("");
  const navigate = useNavigate();

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
    setProgress(0);
  };

  const getUserData = () => {
    setLoading(true);

    return fetch(
      config.baseApiUrl +
        "/properties/v1/public/properties/all-locations-seo-data" +
        "?page=" +
        page +
        "&limit=" +
        limit +
        "&search=" +
        searchTitle,
    )
      .then((res) => res.json())
      .then((res) => {
        setData(res.meta);
        setTotal(res.total);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getUserData();
    let data = locationList.sort(function (a, b) {
      return compareStrings(a.s, b.s);
    });
    setSortedLocationList(data);
  }, [page, limit]);

  const compareStrings = (a, b) => {
    a = a.toLowerCase();
    b = b.toLowerCase();

    return a < b ? -1 : a > b ? 1 : 0;
  };

  const headers = [
    { label: "id", key: "id" },
    { label: "order", key: "order" },
    { label: "path", key: "pu_path" },
    { label: "title", key: "title" },
    { label: "metadescription", key: "metadescription" },
    { label: "keywords", key: "keywords" },
    { label: "isSeoLink", key: "isSeoLink" },
    { label: "homepageSection", key: "homepageSection" },
    { label: "homePageLink", key: "homePageLink" },
    { label: "businessType", key: "businessType" },
    { label: "propertyType", key: "propertyType" },
    { label: "category", key: "category" },
    { label: "location", key: "location" },
    { label: "bedrooms", key: "bedrooms" },
    { label: "minPrice", key: "minPrice" },
    { label: "maxPrice", key: "maxPrice" },
    { label: "numberProperties", key: "numberProperties" },
    { label: "h1", key: "h1" },
    { label: "descriptionH1", key: "descriptionH1" },
    { label: "shortDesc", key: "shortDesc" },
    { label: "longDesc", key: "longDesc" },
  ];

  const handleClick = (e) => {
    let obj = data[e.target.id];

    title.current.value = obj.title ? obj.title : "";
    path.current.value = obj.pu_path;
    homepageSection.current.value = obj.homepageSection;
    homePageLink.current.value = obj.homePageLink;
    businessType.current.value = obj.businessType;
    propertyType.current.value = obj.propertyType;
    location.current.value = obj.location;
    bedrooms.current.value = obj.bedrooms;
    minPrice.current.value = obj.minPrice;
    maxPrice.current.value = obj.maxPrice;
    h1.current.value = obj.h1;
    metadescription.current.value = obj.metadescription;
    descriptionH1.current.value = obj.descriptionH1;
    keywords.current.value = obj.keywords;
    shortDesc.current.value = obj.shortDesc ? obj.shortDesc : null;
    setLongDesc(obj.longDesc ? obj.longDesc : "");
    isSeoLink.current.value = obj.isSeoLink;

    editorBox.current.style.display = "block";
  };

  const handleSave = () => {
    const dataPack = {
      location: location.current.value,
      homePageLink: homePageLink.current.value,
      maxPrice: maxPrice.current.value,
      descriptionH1: "-",
      h1: h1.current.value,
      propertyType: propertyType.current.value,
      path: path.current.value,
      businessType: businessType.current.value,
      metadescription: metadescription.current.value,
      bedrooms: bedrooms.current.value,
      keywords: keywords.current.value,
      category: "-",
      minPrice: minPrice.current.value,
      homepageSection: homepageSection.current.value,
      title: title.current.value,
      shortDesc: shortDesc.current.value,
      longDesc: longDesc,
      isSeoLink: isSeoLink.current.value,
    };
    putData(dataPack).then(() => {
      handleClose();
      getUserData();
    });
  };

  const putData = (dataPack) => {
    return fetch(
      config.baseApiUrl +
        "/properties/v1/public/properties/update-locations-seo-data",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataPack),
      },
    )
      .then((res) => res.json())
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    editorBox.current.style.display = "none";
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTitle(value);
    return fetch(
      config.baseApiUrl +
        "/properties/v1/public/properties/all-locations-seo-data" +
        "?page=" +
        page +
        "&limit=" +
        limit +
        "&search=" +
        value,
    )
      .then((res) => res.json())
      .then((res) => {
        setData(res.meta);
        setTotal(res.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpload = (file) => {
    if (file.name.endsWith(".csv")) {
      Papa.parse(selectedFile, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          results.data.map((d) => {
            console.log(d);
            putData(d);
          });
          alert("Uploaded!");
          console.log("Uploaded!");
          setTimeout(() => {
            window.location.reload();
          }, 500);
        },
      });
    } else {
      alert("File should be CSV!");
    }
  };

  const handleExportAll = async () => {
    setExportAllLoading(true);

    const headers = [
      "pu_path",
      "location",
      "homePageLink",
      "maxPrice",
      "descriptionH1",
      "h1",
      "propertyType",
      "businessType",
      "metadescription",
      "bedrooms",
      "keywords",
      "category",
      "minPrice",
      "homepageSection",
      "numberProperties",
      "title",
      "shortDesc",
      "longDesc",
    ];

    const data = [];
    data.push(headers);

    let response = await get("/properties/v1/public/properties/locations");
    let cities = response.result;

    cities.forEach(async (city) => {
      let temp = await get(
        "/properties/v1/public/properties/locations?cityid=" + city.id,
      );

      data.push([
        "/en/rent/" +
          city.name.toLowerCase().replace(/ /g, "-") +
          "/properties-for-rent",
      ]);
      data.push([
        "/en/sale/" +
          city.name.toLowerCase().replace(/ /g, "-") +
          "/properties-for-sale",
      ]);
      data.push([
        "/en/rent/" +
          city.name.toLowerCase().replace(/ /g, "-") +
          "/luxury-properties-for-rent",
      ]);
      data.push([
        "/en/sale/" +
          city.name.toLowerCase().replace(/ /g, "-") +
          "/luxury-properties-for-sale",
      ]);
      data.push([
        "/en/rent/" +
          city.name.toLowerCase().replace(/ /g, "-") +
          "/commercial-properties-for-rent",
      ]);
      data.push([
        "/en/sale/" +
          city.name.toLowerCase().replace(/ /g, "-") +
          "/commercial-properties-for-sale",
      ]);
      for (let index = 0; index < 6; index++) {
        data.push([
          "/en/rent/" +
            city.name.toLowerCase().replace(/ /g, "-") +
            "/" +
            (index + 1) +
            "-bedroom-properties-for-rent",
        ]);
        data.push([
          "/en/sale/" +
            city.name.toLowerCase().replace(/ /g, "-") +
            "/" +
            (index + 1) +
            "-bedroom-properties-for-sale",
        ]);
        data.push([
          "/en/rent/" +
            city.name.toLowerCase().replace(/ /g, "-") +
            "/" +
            (index + 1) +
            "-bedroom-luxury-properties-for-rent",
        ]);
        data.push([
          "/en/sale/" +
            city.name.toLowerCase().replace(/ /g, "-") +
            "/" +
            (index + 1) +
            "-bedroom-luxury-properties-for-sale",
        ]);
        data.push([
          "/en/rent/" +
            city.name.toLowerCase().replace(/ /g, "-") +
            "/" +
            (index + 1) +
            "-bedroom-commercial-properties-for-rent",
        ]);
        data.push([
          "/en/sale/" +
            city.name.toLowerCase().replace(/ /g, "-") +
            "/" +
            (index + 1) +
            "-bedroom-commercial-properties-for-sale",
        ]);
      }

      city.communities = temp.result;

      temp.result.forEach(async (community) => {
        data.push([
          "/en/rent/" +
            city.name.toLowerCase().replace(/ /g, "-") +
            "/" +
            community.name
              .toLowerCase()
              .replace(/ /g, "-")
              .replace("(", "")
              .replace(")", "") +
            "/properties-for-rent",
        ]);
        data.push([
          "/en/sale/" +
            city.name.toLowerCase().replace(/ /g, "-") +
            "/" +
            community.name
              .toLowerCase()
              .replace(/ /g, "-")
              .replace("(", "")
              .replace(")", "") +
            "/properties-for-sale",
        ]);
        data.push([
          "/en/rent/" +
            city.name.toLowerCase().replace(/ /g, "-") +
            "/" +
            community.name
              .toLowerCase()
              .replace(/ /g, "-")
              .replace("(", "")
              .replace(")", "") +
            "/luxury-properties-for-rent",
        ]);
        data.push([
          "/en/sale/" +
            city.name.toLowerCase().replace(/ /g, "-") +
            "/" +
            community.name
              .toLowerCase()
              .replace(/ /g, "-")
              .replace("(", "")
              .replace(")", "") +
            "/luxury-properties-for-sale",
        ]);
        data.push([
          "/en/rent/" +
            city.name.toLowerCase().replace(/ /g, "-") +
            "/" +
            community.name
              .toLowerCase()
              .replace(/ /g, "-")
              .replace("(", "")
              .replace(")", "") +
            "/commercial-properties-for-rent",
        ]);
        data.push([
          "/en/sale/" +
            city.name.toLowerCase().replace(/ /g, "-") +
            "/" +
            community.name
              .toLowerCase()
              .replace(/ /g, "-")
              .replace("(", "")
              .replace(")", "") +
            "/commercial-properties-for-sale",
        ]);
        for (let index = 0; index < 6; index++) {
          data.push([
            "/en/rent/" +
              city.name.toLowerCase().replace(/ /g, "-") +
              "/" +
              community.name
                .toLowerCase()
                .replace(/ /g, "-")
                .replace("(", "")
                .replace(")", "") +
              "/" +
              (index + 1) +
              "-bedroom-properties-for-rent",
          ]);
          data.push([
            "/en/sale/" +
              city.name.toLowerCase().replace(/ /g, "-") +
              "/" +
              community.name
                .toLowerCase()
                .replace(/ /g, "-")
                .replace("(", "")
                .replace(")", "") +
              "/" +
              (index + 1) +
              "-bedroom-properties-for-sale",
          ]);
          data.push([
            "/en/rent/" +
              city.name.toLowerCase().replace(/ /g, "-") +
              "/" +
              community.name
                .toLowerCase()
                .replace(/ /g, "-")
                .replace("(", "")
                .replace(")", "") +
              "/" +
              (index + 1) +
              "-bedroom-luxury-properties-for-rent",
          ]);
          data.push([
            "/en/sale/" +
              city.name.toLowerCase().replace(/ /g, "-") +
              "/" +
              community.name
                .toLowerCase()
                .replace(/ /g, "-")
                .replace("(", "")
                .replace(")", "") +
              "/" +
              (index + 1) +
              "-bedroom-luxury-properties-for-sale",
          ]);
          data.push([
            "/en/rent/" +
              city.name.toLowerCase().replace(/ /g, "-") +
              "/" +
              community.name
                .toLowerCase()
                .replace(/ /g, "-")
                .replace("(", "")
                .replace(")", "") +
              "/" +
              (index + 1) +
              "-bedroom-commercial-properties-for-rent",
          ]);
          data.push([
            "/en/sale/" +
              city.name.toLowerCase().replace(/ /g, "-") +
              "/" +
              community.name
                .toLowerCase()
                .replace(/ /g, "-")
                .replace("(", "")
                .replace(")", "") +
              "/" +
              (index + 1) +
              "-bedroom-commercial-properties-for-sale",
          ]);
        }
      });
    });

    setAllData(data);
  };

  const orderUp = (e) => {
    console.log(e.target.id);
  };

  const orderDown = (e) => {
    console.log(e.target.id);
  };

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <MyPZContainer>
          <div className="row">
            <div className="col-md-12">
              <h1>Update SEO Url's</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h4 className="m-1">Upload .XLSX </h4>
            </div>
            <div className="col-md-6">
              <input
                type="file"
                ref={file}
                className="form-control m-1"
                onChange={handleFileInput}
              />
            </div>
            <div className="col-md-6">
              <button
                type="button"
                onClick={() => {
                  handleUpload(selectedFile);
                }}
                className="btn btn-danger"
              >
                Import Data
              </button>
              &nbsp;
              <CSVLink
                headers={headers}
                data={data}
                filename={fileName}
                class="btn btn-danger m-1"
              >
                {loading ? "Loading csv..." : "Export Data"}
              </CSVLink>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleClick}
              >
                <strong>+</strong> Add Link
              </button>
            </div>
          </div>
          <hr />
          <SearchInput title={searchTitle} handleChange={handleSearchChange} />
          <hr />
          <table className="table table-responsive" id="dataTable">
            <thead>
              <tr>
                <th>Id</th>
                <th>Title</th>
                <th>Path</th>
                <th>Header</th>
                <th>Number Properties</th>
                <th>Options</th>
                {/* 
                            <th>Business Type</th>
                            <th>Property Type</th>
                            <th>Location</th>
                            <th>Bedrooms</th>
                            <th>MinPrice</th>
                            <th>MaxPrice</th>
                            <th>H1</th>
                            <th>metadescription</th>
                            <th>descriptionH1</th>
                            <th>keywords</th> 
                            */}
              </tr>
            </thead>
            <tbody>
              {data.map((page) => {
                return (
                  <tr>
                    <td>{page.id}</td>
                    <td>{page.title}</td>
                    <td>{page.pu_path}</td>
                    <td>{page.homepageSection}</td>
                    <td>{page.numberProperties}</td>
                    <td>
                      <button
                        className="btn btn-danger"
                        id={data.indexOf(page)}
                        onClick={handleClick}
                      >
                        Edit
                      </button>
                    </td>
                    {/* <td>
                                    <input type="text" defaultValue={page.businessType} />
                                </td>
                                <td>
                                    <input type="text" defaultValue={page.propertyType} />
                                </td>
                                <td>
                                    <input type="text" defaultValue={page.location} />
                                </td>
                                <td>
                                    <input type="number" defaultValue={page.bedrooms} />
                                </td>
                                <td>
                                    <input type="number" defaultValue={page.minPrice} />
                                </td>
                                <td>
                                    <input type="number" defaultValue={page.maxPrice} />
                                </td>
                                <td>
                                    <input type="text" defaultValue={page.h1} />
                                </td>
                                <td>
                                    <input type="text" defaultValue={page.metadescription} />
                                </td>
                                <td>
                                    <input type="text" defaultValue={page.descriptionH1} />
                                </td>
                                <td>
                                    <input type="text" defaultValue={page.keywords} />
                                </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            ref={editorBox}
            style={{
              display: "none",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "#0008",
            }}
          >
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col">
                <div className="card mt-5">
                  <div className="card-header">
                    <button
                      className="btn btn-secondary"
                      style={{ marginRight: "1rem" }}
                      onClick={handleClose}
                    >
                      X
                    </button>
                    Edit SEO Link
                  </div>
                  <div
                    className="card-body"
                    style={{ maxHeight: "700px", overflow: "auto" }}
                  >
                    <form className="row">
                      <div className="form-group mb-2 col-md-6">
                        <label>Title</label>
                        <input
                          className="form-control"
                          type="text"
                          ref={title}
                        />
                      </div>
                      <div className="form-group mb-2 col-md-6">
                        <label>H1</label>
                        <input className="form-control" type="text" ref={h1} />
                      </div>
                      <div className="form-group mb-2 col-md-6">
                        <label>Path</label>
                        <input
                          className="form-control"
                          type="text"
                          ref={path}
                        />
                      </div>
                      <div className="form-group mb-2 col-md-6">
                        <label>Home Page Section</label>
                        <input
                          className="form-control"
                          type="text"
                          ref={homepageSection}
                        />
                      </div>
                      <div className="form-group mb-2 col-md-6">
                        <label>Home Page Link Label</label>
                        <input
                          className="form-control"
                          type="text"
                          ref={homePageLink}
                        />
                      </div>
                      <div className="form-group mb-2 col-md-3">
                        <label>Business Type</label>
                        <select
                          className="form-control"
                          type="text"
                          ref={businessType}
                        >
                          <option value="rent">Rent</option>
                          <option value="sale">Sale</option>
                        </select>
                      </div>
                      <div className="form-group mb-2 col-md-3">
                        <label>Property Type</label>
                        <select
                          className="form-control"
                          type="text"
                          ref={propertyType}
                        >
                          {propertyTypesConfig.map((t) => {
                            return <option value={t.code}>{t.value}</option>;
                          })}
                        </select>
                      </div>
                      <div className="form-group mb-2 col-md-6">
                        <label>Location</label>
                        <select
                          className="form-control"
                          type="text"
                          ref={location}
                        >
                          {sortedLocationList.map((t) => {
                            return (
                              <option value={t.s}>
                                {t.n} ({t.s})
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group mb-2 col-md-2">
                        <label>Bedrooms</label>
                        <select
                          className="form-control"
                          type="text"
                          ref={bedrooms}
                        >
                          <option value="studio">Studio</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8+">8+</option>
                        </select>
                      </div>
                      <div className="form-group mb-2 col-md-2">
                        <label>Minimum Price</label>
                        <input
                          className="form-control"
                          type="number"
                          ref={minPrice}
                        />
                      </div>
                      <div className="form-group mb-2 col-md-2">
                        <label>Maximum Price</label>
                        <input
                          className="form-control"
                          type="number"
                          ref={maxPrice}
                        />
                      </div>
                      <div className="form-group mb-2 col-md-12">
                        <label>Meta Description</label>
                        <input
                          className="form-control"
                          type="text"
                          ref={metadescription}
                        />
                      </div>
                      <div className="form-group mb-2 col-md-12">
                        <label>Description H1</label>
                        <input
                          className="form-control"
                          type="text"
                          ref={descriptionH1}
                        />
                      </div>
                      <div className="form-group mb-2 col-md-12">
                        <label>Keywords</label>
                        <input
                          className="form-control"
                          type="text"
                          ref={keywords}
                        />
                      </div>
                      <div className="form-group mb-2 col-md-12">
                        <label>Short Desciption</label>
                        <textarea
                          className="form-control"
                          type="text"
                          ref={shortDesc}
                        ></textarea>
                      </div>
                      <div className="form-group mb-2 col-md-12">
                        <label>Long Desciption</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={longDesc}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setLongDesc(data);
                          }}
                          onBlur={(event, editor) => {}}
                          onFocus={(event, editor) => {}}
                        />
                      </div>
                      <div className="form-group mb-2 col-md-12">
                        <label>Is an SEO link?</label>
                        <select
                          className="form-control"
                          type="text"
                          ref={isSeoLink}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="form-group mb-2 col-md-12">
                        <button
                          className="btn btn-danger w-100"
                          type="button"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>{" "}
          <Pagination
            page={page}
            setPage={setPage}
            total={total}
            limit={limit}
          />
        </MyPZContainer>
      )}
    </>
  );
}
