import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

// Custom tick component
const CustomXAxisTick = ({ x, y, payload, data }) => {
  // Find the corresponding data entry
  const dataEntry = data.find((entry) => entry.date === payload.value);

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
      <text
        x={0}
        y={0}
        dy={33}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        View: {dataEntry.view_details || 0}
      </text>
      <text
        x={0}
        y={0}
        dy={50}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        Phone: {dataEntry.request_phone || 0}
      </text>
      <text
        x={0}
        y={0}
        dy={67}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        Email: {dataEntry.request_email || 0}
      </text>
      <text
        x={0}
        y={0}
        dy={67}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        Whatsapp: {dataEntry.request_whatsapp || 0}
      </text>
    </g>
  );
};

const UserInteractionsChart = ({ data }) => {
  const sortedData = [...data].sort(
    (a, b) =>
      new Date(a.date.replace(/-/g, "/")) - new Date(b.date.replace(/-/g, "/")),
  );

  return (
    <ResponsiveContainer width="100%" height={450}>
      <LineChart
        data={sortedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 40,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          height={70}
          tick={(props) => <CustomXAxisTick {...props} data={sortedData} />}
        />
        <YAxis />
        <Tooltip />
        <Legend verticalAlign="middle" align="right" layout="vertical" />
        <Line
          type="monotone"
          dataKey="view_details"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey="request_phone" stroke="#82ca9d" />
        <Line type="monotone" dataKey="request_email" stroke="#ffc658" />
        <Line type="monotone" dataKey="request_whatsapp" stroke="blue" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default UserInteractionsChart;
