import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './CreateLocationPage.scss';

import MyPZContainer from '@mypz/react-kit/components/container/MyPZContainer';
import MyPZAlert from '@mypz/react-kit/components/alert/MyPZAlert';
import { createLocation } from '../../../api/locations/locationsApi';
import LocationForm from '../../../components/form/location/LocationForm';

const CreatePostPage = () => {
  const history = useNavigate();

  const [errorAlert, setErrorAlert] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (formData) => {
    setIsProcessing(true);
    try {
      await createLocation(formData);
      history(`/locations/update/${formData.slug}`);
    } catch (e) {
      console.log('post create error:', e.message);
      setErrorAlert(e.message);
    }
    setIsProcessing(false);
  };

  const renderAlert = () => {
    if (errorAlert) {
      return (<MyPZAlert type="error">{errorAlert}</MyPZAlert>);
    }

    return null;
  };

  return (
    <div className="page-create-location">
      <MyPZContainer>
        {renderAlert()}
        <h1>Create Location</h1>
        <LocationForm isProcessing={isProcessing} onSubmit={handleSubmit} />
      </MyPZContainer>
    </div>
  );
}

export default CreatePostPage;
