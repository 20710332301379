import { get } from "../baseApi";

const baseURL = "/sitemap/v1/superAdmin/sitemap";

export const startGeneration = async () => {
  const res = await get(baseURL + "/start-locations");
  return res;
};

export const stopGeneration = async () => {
  const res = await get(baseURL + "/stop-locations");
  return res;
};

export const listSitemaps = async () => {
  const res = await get(baseURL + "/sitemap-tracks");
  return res;
};

export const sitemapURLs = async () => {
  const res = await get(baseURL + "/urls");
  return res;
};
