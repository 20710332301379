import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  MyPZContainer,
  MyPZTextField,
  MyPZButton,
  MyPZTable,
  MyPZPagination,
} from "@mypz/react-kit";

import "./AdsListPage.scss";

import { adminListAds, deleteAd } from "../../../api/ads/adsApi";

let delayer = null;

const AdsListPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const [listValues, setListValues] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const handleChangeValue = (e) => {
    setSearchValue(e.target.value);
  };

  const fetchList = async (name, pageRequest) => {
    const res = await adminListAds({ name, page: pageRequest });
    setListValues(res.ads);
    setTotalItems(res.stats.totalItems);
    setTotalPages(res.stats.totalPages);
  };

  useEffect(() => {
    if (!searchValue) {
      fetchList(searchValue, page);
      return;
    }
    clearTimeout(delayer);
    delayer = setTimeout(() => {
      fetchList(searchValue, page);
    }, 500);
  }, [searchValue, page]);

  const handlePageChange = (_, value) => {
    setPage(value);
  };

  const handleDeleteAd = async (ad) => {
    if (window.confirm(`Are you sure you want to delete ${ad.name}?`)) {
      await deleteAd(ad.id);
      fetchList(searchValue, page);
    }
  };

  const renderList = () => {
    return (
      <MyPZTable
        data={listValues}
        headRenders={[
          () => <div>Id</div>,
          () => <div>Name</div>,
          () => <div>Display Count</div>,
          () => <div>Click Count</div>,
          () => <div>End At</div>,
          () => <div>Created</div>,
          () => <div>Updated</div>,
          () => <div>Actions</div>,
        ]}
        lineRenders={[
          (l) => <div>{l.id}</div>,
          (l) => <div>{l.name}</div>,
          (l) => <div>{l.displayCount}</div>,
          (l) => <div>{l.clickCount}</div>,
          (l) => <div>{moment(l.endDate).fromNow()}</div>,
          (l) => <div>{moment(l.createdAt).fromNow()}</div>,
          (l) => <div>{moment(l.updatedAt).fromNow()}</div>,
          (l) => (
            <div className="d-flex gap-3">
              <Link to={`/ads/update/${l.id}`}>
                <button className="btn btn-success"> Update</button>
              </Link>
              <button
                className="btn btn-danger"
                onClick={() => handleDeleteAd(l)}
              >
                Delete
              </button>
            </div>
          ),
        ]}
      />
    );
  };

  return (
    <div className="page-ads-list">
      <MyPZContainer>
        <h1>Ads list</h1>
        <div className="page-ads-list__create-new">
          <Link to="/ads/create">
            <MyPZButton>Create Ad</MyPZButton>
          </Link>
        </div>
        <div className="page-ads-list__search-field">
          <MyPZTextField
            placeholder="Search by name"
            onChange={handleChangeValue}
            value={searchValue}
          />
        </div>
        <div>{renderList()}</div>
        <div className="page-ads-list__pagination-block">
          <div className="page-ads-list__pagination-block__total">
            {`${totalItems} Ads`}
          </div>
          <div className="page-ads-list__pagination-block__selector">
            <MyPZPagination
              maxPage={totalPages}
              currentPage={page}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </MyPZContainer>
    </div>
  );
};

export default AdsListPage;
