import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './CreateAdPage.scss';

import MyPZContainer from '@mypz/react-kit/components/container/MyPZContainer';
import MyPZAlert from '@mypz/react-kit/components/alert/MyPZAlert';
import AdForm from '../../../components/form/ad/AdForm';

import { createAd } from '../../../api/ads/adsApi';

const CreateAdPage = () => {
  const history = useNavigate();

  const [errorAlert, setErrorAlert] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (formData) => {
    delete formData.imageUrl;
    setIsProcessing(true);
    try {
      const adResult = await createAd(formData);
      history(`/ads/update/${adResult.ad.id}`);
    } catch (e) {
      console.log('ad create error:', e.message);
      setErrorAlert(e.message);
    }
    setIsProcessing(false);
  };

  const renderAlert = () => {
    if (errorAlert) {
      return (<MyPZAlert type="error">{errorAlert}</MyPZAlert>);
    }

    return null;
  };

  return (
    <div className="page-create-ad">
      <MyPZContainer>
        {renderAlert()}
        <h1>Create Ad</h1>
        <AdForm isProcessing={isProcessing} onSubmit={handleSubmit} />
      </MyPZContainer>
    </div>
  );
}

export default CreateAdPage;
