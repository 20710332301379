import React from 'react'
import { get } from '../../api/baseApi';

export default function DownloadAll() {



    const getData = async () => {
        const headers = [
            "pu_path",
            "location",
            "homePageLink",
            "maxPrice",
            "descriptionH1",
            "h1",
            "propertyType",
            "businessType",
            "metadescription",
            "bedrooms",
            "keywords",
            "category",
            "minPrice",
            "homepageSection",
            "numberProperties",
            "title",
            "shortDesc",
            "longDesc"
        ];

        const data = [];
        data.push(headers);

        await get("/properties/v1/public/properties/locations").then((response) => {
            let cities = response.result;

            cities.forEach(city => {
                get("/properties/v1/public/properties/locations?cityid=" + city.id).then(temp => {
                    data.push(["/en/rent/" + city.name.toLowerCase().replace(/ /g, "-") + "/properties-for-rent"]);
                    data.push(["/en/sale/" + city.name.toLowerCase().replace(/ /g, "-") + "/properties-for-sale"]);
                    data.push(["/en/rent/" + city.name.toLowerCase().replace(/ /g, "-") + "/luxury-properties-for-rent"]);
                    data.push(["/en/sale/" + city.name.toLowerCase().replace(/ /g, "-") + "/luxury-properties-for-sale"]);
                    data.push(["/en/rent/" + city.name.toLowerCase().replace(/ /g, "-") + "/commercial-properties-for-rent"]);
                    data.push(["/en/sale/" + city.name.toLowerCase().replace(/ /g, "-") + "/commercial-properties-for-sale"]);
                    for (let index = 0; index < 6; index++) {
                        data.push(["/en/rent/" + city.name.toLowerCase().replace(/ /g, "-") + "/" + (index + 1) + "-bedroom-properties-for-rent"]);
                        data.push(["/en/sale/" + city.name.toLowerCase().replace(/ /g, "-") + "/" + (index + 1) + "-bedroom-properties-for-sale"]);
                        data.push(["/en/rent/" + city.name.toLowerCase().replace(/ /g, "-") + "/" + (index + 1) + "-bedroom-luxury-properties-for-rent"]);
                        data.push(["/en/sale/" + city.name.toLowerCase().replace(/ /g, "-") + "/" + (index + 1) + "-bedroom-luxury-properties-for-sale"]);
                        data.push(["/en/rent/" + city.name.toLowerCase().replace(/ /g, "-") + "/" + (index + 1) + "-bedroom-commercial-properties-for-rent"]);
                        data.push(["/en/sale/" + city.name.toLowerCase().replace(/ /g, "-") + "/" + (index + 1) + "-bedroom-commercial-properties-for-sale"]);
                    }
                    city.communities = temp.result;

                    temp.result.forEach(async community => {
                        data.push(["/en/rent/" + city.name.toLowerCase().replace(/ /g, "-") + "/" + community.name.toLowerCase().replace(/ /g, "-").replace("(", "").replace(")", "") + "/properties-for-rent"]);
                        data.push(["/en/sale/" + city.name.toLowerCase().replace(/ /g, "-") + "/" + community.name.toLowerCase().replace(/ /g, "-").replace("(", "").replace(")", "") + "/properties-for-sale"]);
                        data.push(["/en/rent/" + city.name.toLowerCase().replace(/ /g, "-") + "/" + community.name.toLowerCase().replace(/ /g, "-").replace("(", "").replace(")", "") + "/luxury-properties-for-rent"]);
                        data.push(["/en/sale/" + city.name.toLowerCase().replace(/ /g, "-") + "/" + community.name.toLowerCase().replace(/ /g, "-").replace("(", "").replace(")", "") + "/luxury-properties-for-sale"]);
                        data.push(["/en/rent/" + city.name.toLowerCase().replace(/ /g, "-") + "/" + community.name.toLowerCase().replace(/ /g, "-").replace("(", "").replace(")", "") + "/commercial-properties-for-rent"]);
                        data.push(["/en/sale/" + city.name.toLowerCase().replace(/ /g, "-") + "/" + community.name.toLowerCase().replace(/ /g, "-").replace("(", "").replace(")", "") + "/commercial-properties-for-sale"]);
                        for (let index = 0; index < 6; index++) {
                            data.push(["/en/rent/" + city.name.toLowerCase().replace(/ /g, "-") + "/" + community.name.toLowerCase().replace(/ /g, "-").replace("(", "").replace(")", "") + "/" + (index + 1) + "-bedroom-properties-for-rent"]);
                            data.push(["/en/sale/" + city.name.toLowerCase().replace(/ /g, "-") + "/" + community.name.toLowerCase().replace(/ /g, "-").replace("(", "").replace(")", "") + "/" + (index + 1) + "-bedroom-properties-for-sale"]);
                            data.push(["/en/rent/" + city.name.toLowerCase().replace(/ /g, "-") + "/" + community.name.toLowerCase().replace(/ /g, "-").replace("(", "").replace(")", "") + "/" + (index + 1) + "-bedroom-luxury-properties-for-rent"]);
                            data.push(["/en/sale/" + city.name.toLowerCase().replace(/ /g, "-") + "/" + community.name.toLowerCase().replace(/ /g, "-").replace("(", "").replace(")", "") + "/" + (index + 1) + "-bedroom-luxury-properties-for-sale"]);
                            data.push(["/en/rent/" + city.name.toLowerCase().replace(/ /g, "-") + "/" + community.name.toLowerCase().replace(/ /g, "-").replace("(", "").replace(")", "") + "/" + (index + 1) + "-bedroom-commercial-properties-for-rent"]);
                            data.push(["/en/sale/" + city.name.toLowerCase().replace(/ /g, "-") + "/" + community.name.toLowerCase().replace(/ /g, "-").replace("(", "").replace(")", "") + "/" + (index + 1) + "-bedroom-commercial-properties-for-sale"]);
                        }
                    });

                });
            });
        });

        const out = data;
        const csvContent = "data:text/csv;charset=utf-8," + out.map(e => e.join(",")).join("\n");

        window.location.href = csvContent;
    }

    return (
        <>
            <div className='m-5'>
                <h2>Click to download</h2>
                <button className='btn btn-danger' onClick={() => { getData() }}>Download</button>
            </div>
        </>
    )
}
